import { ESportType } from "@sportaq/common/enums/sport-type";
import { HOT_PARTITIONS_COUNTRY_ID } from "@sportaq/common/consts/default-consts";
import { IdItem } from "@sportaq/common/types/types";
import { Partition } from "@sportaq/model/betting/events/event";

interface IndexedItem<T extends IdItem> {
    item: T;
    index: number;
}

export class PopularPartition implements IdItem {
    constructor (readonly partition: Partition, readonly priorityPartition: boolean) {
    }

    get id (): number {
        return this.partition.id;
    }
}

export class ItemOrderSupplier<T extends IdItem> {
    private readonly idIndexMap: Map<number, IndexedItem<T>> = new Map<number, IndexedItem<T>>();

    constructor (public readonly items: T[]) {
        for (let i = 0; i < items.length; i++) {
            this.idIndexMap.set(items[i].id, {
                item: items[i],
                index: i
            });
        }
    }

    getOrderIndex (id: number): number {
        return this.idIndexMap.get(id)?.index ?? Number.MAX_SAFE_INTEGER;
    }

    has (id: number): boolean {
        return this.idIndexMap.has(id);
    }
}

class CountryOrderSupplier {
    private map: Map<number, ItemOrderSupplier<IdItem>> = new Map<number, ItemOrderSupplier<IdItem>>();
    private _userCountryId: number = -1;

    set (userCountryId: number, map: Map<number, ItemOrderSupplier<IdItem>>) {
        this._userCountryId = userCountryId;
        this.map = map;
    }

    getOrderIndex (sportType: ESportType, value: number): number {
        if (value === HOT_PARTITIONS_COUNTRY_ID) {
            return -(Number.MAX_SAFE_INTEGER - 1);
        }
        return this.map.get(sportType)?.getOrderIndex(value) ?? Number.MAX_SAFE_INTEGER;
    }

    get userCountryId (): number {
        return this._userCountryId;
    }
}

class PartitionOrderSupplier {
    private _userCountryId: number = -1;
    private orderSupplier: ItemOrderSupplier<PopularPartition> = new ItemOrderSupplier([]);

    set (userCountryId: number, orderSupplier: ItemOrderSupplier<PopularPartition>) {
        this._userCountryId = userCountryId;
        this.orderSupplier = orderSupplier;
    }

    getOrderIndex (value: number): number {
        return this.orderSupplier.getOrderIndex(value);
    }

    has (value: number): boolean {
        return this.orderSupplier.has(value);
    }

    get userCountryId (): number {
        return this._userCountryId;
    }

    get source () {
        return this.orderSupplier.items;
    }
}

export const countriesOrder = new CountryOrderSupplier();
export const partitionsOrder = new PartitionOrderSupplier();
