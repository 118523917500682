import { eventDetailsInfoHolder } from "@sportaq/model/betting/view/event-details/event-details-info";
import { Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { BigDecimal, bigDecimalToStringWithVariableMantis } from "@sportaq/common/types/classes/bigdecimal";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { CardItem } from "@sportaq/model/cashier/card";
import { isHandicap } from "@sportaq/common/consts/quotation-consts";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import { QuotationKey } from "@sportaq/model/betting/events/quotation";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { appLogger } from "@sportaq/common/utils/logger";
import { Localization } from "@sportaq/i18n/interfaces/interfaces";
import { quotationTypes } from "@sportaq/model/consts/quotation-labels";

export function createQuotationTitleByQuotationId (i18n: Localization, sportType: ESportType, participantsSupplier: ParticipantsSupplier, quotationKey: QuotationKey): string {
    const quotationTitle = eventDetailsInfoHolder.getQuotationTitle(sportType, quotationKey);
    if (quotationTitle) {
        const participants = getParticipantList(participantsSupplier, quotationKey);
        return createQuotationTitle(i18n, quotationTitle, participants, quotationKey.p1);
    }
    return "";
}

export function createQuotationTitle (i18n: Localization, title: string, participants: Participant[], p1: BigDecimal | undefined): string {
    if (title) {
        return i18n.t(title, {
            team1: participants.length > 0 ? participants[0].name : "",
            team2: participants.length > 1 ? participants[1].name : "",
            p1: bigDecimalToStringWithVariableMantis(p1)
        });
    } else {
        appLogger.logger.error("TITLE IS EMPTY");
        return "";
    }
}

export function getLocalizedQuotationTitle (i18n: Localization, item: CardItem) {
    return createQuotationTitleByQuotationId(i18n, item.position.partition.sportTypeId, item, item.quotationKey) +
        " " + createQuotationLabel(item, item.quotationKey);
}

export function createQuotationLabel (participantsSupplier: ParticipantsSupplier, quotationKey: QuotationKey) {
    const label = quotationTypes.getById(quotationKey.quotationId);
    if (label) {
        const participants = getParticipantList(participantsSupplier, quotationKey);
        if (label.participantNumber) {
            return participants[label.participantNumber - 1].name;
        }
        if (label.template && label.label) {
            const templateVars = prepareTemplateVars(participants, quotationKey);
            return fillTemplate(label.label, templateVars);
        }
        return label.label;
    }
    return "";
}

export function formatQuotationCoef (quotationFormat: EQuotationFormat, decimal: number): string {
    if (quotationFormat === EQuotationFormat.Decimal) {
        return decimal.toFixed(BIG_DECIMAL_SCALES.BETTING_COEF);
    }
    if (quotationFormat === EQuotationFormat.American) {
        return decimal === 2
            ? "+100"
            : (decimal < 2
                ? "-" + (100 / (decimal - 1)).toFixed(0)
                : "+" + ((decimal - 1) * 100).toFixed(0));
    }
    if (quotationFormat === EQuotationFormat.English) {
        return formatEnglish(decimal);
    }
    return "";
}

function formatEnglish (decimal: number) {
    const decimals = [1.05, 1.1, 1.2, 1.25, 1.3, 1.375, 1.4, 1.5,
        1.6, 1.625, 1.7, 1.75, 1.8, 1.875, 1.9, 2,
        2.1, 2.125, 2.2, 2.25, 2.3, 2.375, 2.4, 2.5,
        2.6, 2.75, 2.8, 3, 3.2, 3.33333333333333, 3.5,
        3.6, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5,
        8, 8.5, 9, 10, 11, 12, 13, 14, 15,
        16, 17, 18, 19, 20, 22, 25, 28, 30,
        33, 40, 50, 60, 66, 70, 80, 90, 100, 101
    ];
    const fractions = ["21/20", "11/10", "6/5", "5/4", "13/10", "11/8",
        "7/5", "3/2", "8/5", "13/8", "17/10", "7/4", "9/5",
        "15/8", "19/10", "2/1", "21/10", "17/8", "11/5", "9/4",
        "23/10", "19/8", "12/5", "5/2", "13/5", "11/4", "14/5",
        "3/1", "16/5", "10/3", "7/2", "18/5", "4/1", "9/2",
        "5/1", "11/2", "6/1", "13/2", "7/1", "15/2", "8/1",
        "17/2", "9/1", "10/1", "11/1", "12/1", "13/1", "14/1",
        "15/1", "16/1", "17/1", "18/1", "19/1", "20/1", "22/1",
        "25/1", "28/1", "30/1", "33/1", "40/1", "50/1", "60/1",
        "66/1", "70/1", "80/1", "90/1", "100/1"];
    for (let i = 0; i < decimals.length; i++) {
        const diff = decimals[i] - decimal;
        if (diff > 0) {
            return fractions[i - 1];
        }
    }
    return "Unk";
}

interface TemplateVars {
    [key: string]: string,

    p1: string,
    p2: string
}

function prepareTemplateVars (participants: Participant[], quotationKey: QuotationKey): TemplateVars {
    const templateVars: TemplateVars = {
        p1: bigDecimalToStringWithVariableMantis(quotationKey.p1),
        p2: bigDecimalToStringWithVariableMantis(quotationKey.p2)
    };

    let counter = 1;
    for (const participant of participants) {
        templateVars["k" + counter] = participant.name;
        counter++;
    }

    if (isHandicap(quotationKey.quotationId)) {
        if (quotationKey.p1.numberValue > 0) {
            templateVars.p1 = "+" + templateVars.p1;
        }
    }
    if (templateVars.p1) {
        templateVars.p1 = "<span class='quotation-point'>" + templateVars.p1 + "</span>";
    }
    return templateVars;
}

const fillTemplate = function (templateString: string, templateVars: unknown) {
    // eslint-disable-next-line no-new-func
    return new Function("return `" + templateString + "`;").call(templateVars);
};
