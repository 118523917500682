export enum ESportType {
    Football = 1,
    Basketball = 2,
    Hockey = 3,
    Tennis = 4,
    Formula1 = 5,
    Volleyball = 6,
    Baseball = 7,
    Rugby = 8,
    AmericanFootball = 9,
    Boxing = 10,
    Keno = 11,
    SkiJumping = 12,
    Skeleton = 13,
    Luge = 14,
    Snowboard = 15,
    FreestyleSkiing = 16,
    FigureSkating = 17,
    ShortTrack = 18,
    Biathlon = 19,
    Bobsleigh = 20,
    AlpineSkiing = 21,
    Curling = 22,
    SpeedSkating = 23,
    NordicCombined = 24,
    CrossCountry = 25,
    SuperLotto = 26,
    WaterPolo = 27,
    Handball = 28,
    Bandy = 29,
    Swimming = 30,
    Chess = 31,
    Futsal = 32,
    Contest = 33,
    Policy = 35,
    OlympicGames = 36,
    FieldHockey = 37,
    AutoSport = 38,
    Motorsport = 39,
    Billiards = 40,
    Athletic = 41,
    Lotto3 = 42,
    Badminton = 43,
    TableTennis = 44,
    BeachSoccer = 45,
    BeachVolleyball = 46,
    ESports = 47
}

export function getNameBySportType (sportType: ESportType): string | undefined {
    const name = ESportType[sportType];
    return name?.toLowerCase();
}

export function getSportTypeByName (name: string): ESportType | undefined {
    for (const item in ESportType) {
        const number = Number(item);
        if (isNaN(number)) {
            break;
        }
        const sportName = getNameBySportType(number);
        if (name === sportName) {
            return number;
        }
    }
    return undefined;
}
