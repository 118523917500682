import { QuotationKey } from "@sportaq/model/betting/events/quotation";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import {
    getFloatAttribute,
    getIntAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";

export function parseQuotationKey (cardItemElement: Element): QuotationKey {
    const p1 = getFloatAttribute(cardItemElement, "p1");
    const p2 = getFloatAttribute(cardItemElement, "p2");
    const po = getFloatAttribute(cardItemElement, "po");
    const p1Id = getIntAttribute(cardItemElement, "p1Id");
    const p2Id = getIntAttribute(cardItemElement, "p2Id");
    return new QuotationKey(
        getRequiredIntAttribute(cardItemElement, "QTId"),
        p1 ? BigDecimal.fromNumber(p1, BIG_DECIMAL_SCALES.BETTING_KEY_P) : undefined,
        p2 ? BigDecimal.fromNumber(p2, BIG_DECIMAL_SCALES.BETTING_KEY_P) : undefined,
        po ? BigDecimal.fromNumber(po, BIG_DECIMAL_SCALES.BETTING_KEY_P) : undefined,
        p1Id && (p1Id !== 0) ? p1Id : undefined,
        p2Id && (p2Id !== 0) ? p2Id : undefined
    );
}
