<template>
    <div class="deposit-section">
        <div class="deposit-section-body">
            <div class="line" v-for="(session) in sessions" :key="session.id">
                <div class="line-total smaller">
                    <div class="name">{{$d(session.registerTime, 'long')}}</div>
                    <div class="value">{{session.ipAddress}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./session-list-component.ts" />
