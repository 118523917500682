import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { appLogger } from "@sportaq/common/utils/logger";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { Localization } from "@sportaq/i18n/interfaces/interfaces";

export function showError (i18n: Localization, e: Error, logDescription: string, closeHandle?: () => void) {
    if (e instanceof LocalizedError) {
        appLogger.logger.error(`${logDescription}: ${i18n.t(e.message)}`);
        showMessageBox(i18n.t(e.message), closeHandle);
    } else {
        appLogger.logger.error(logDescription, e as Error);
        showMessageBox(`Unexpected error: ${(e as Error).message}`, closeHandle);
    }
}
