import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import {
    EventSupplier,
    provideEventSupplier
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { onMounted, onUnmounted } from "vue";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import {
    provideInjectedScoreboardStore,
    provideScoreboardStore,
    ScoreboardStore
} from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import {
    BetSlipStore,
    provideBetSlipStore,
    provideInjectedBetSlipStore
} from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import {
    BalanceStore,
    provideBalanceStore,
    provideInjectedBalanceStore
} from "@sportaq/vuex/modules/betting/balance/balance-module";
import {
    createStreamingNetwork,
    StreamingNetwork
} from "@sportaq/services/streaming/streaming-network/streaming-network";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { RootStore } from "@sportaq/vuex/index";
import {
    provideClockProvider,
    provideGoogleTagService,
    provideInjectedClockProvider,
    provideInjectedGoogleTagService,
    provideInjectedMiscellaneousParamsProvider,
    provideInjectedStreamingRequestService,
    provideInjectedUUIDProvider,
    provideMiscellaneousParamsProvider,
    provideStreamingRequestService,
    provideStreamingService,
    provideUUIDProvider
} from "@sportaq/services/index";
import { MiscellaneousParamsProvider } from "@sportaq/common/utils/miscellaneous-params";
import { StreamingRequestService } from "@sportaq/services/streaming/streaming-request-service";
import {
    CasinoGamesSupplier,
    provideCasinoGamesSupplier
} from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import {
    CasinoGamesStore,
    provideCasinoGamesStore,
    provideInjectedCasinoGamesStore
} from "@sportaq/vuex/modules/games/casino-games-module";
import { provideBannersStore } from "@sportaq/vuex/modules/banners/banners-module";
import { GoogleTagService } from "@sportaq/services/google-tag/google-tag-service";

export interface SharedDependencyProvider {
    streamingNetwork: StreamingNetwork;
    streamingRequestService: StreamingRequestService,
    scoreboardStore: ScoreboardStore;
    betSlipStore: BetSlipStore;
    balanceStore: BalanceStore;
    clockProvider: ClockProvider;
    uuidProvider: UUIDProvider;
    miscellaneousParamsProvider: MiscellaneousParamsProvider;
    casinoGamesStore: CasinoGamesStore;
    googleTagService: GoogleTagService;
}

export function provideSharedServices (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined, rootStore: RootStore, settingsService: BaseSettingsService): ClockProvider {
    const clockProvider = injectClockProvider(injectedDependencyProvider);
    const miscellaneousParamsProvider = injectMiscellaneousParamsProvider(injectedDependencyProvider);
    const eventSupplier = provideEventSupplier(clockProvider, miscellaneousParamsProvider);
    const bettingStore = injectBettingStore(rootStore, eventSupplier, injectedDependencyProvider);
    const casinoGamesSupplier = provideCasinoGamesSupplier();
    const casinoGamesStore = injectCasinoGamesStore(injectedDependencyProvider, rootStore, casinoGamesSupplier, settingsService);

    const streamingNetwork = injectStreamingNetwork(injectedDependencyProvider, settingsService);
    const streamingRequestService = injectStreamingRequestService(injectedDependencyProvider, streamingNetwork);
    provideStreamingService(streamingNetwork, streamingRequestService, settingsService, clockProvider,
        eventSupplier, bettingStore, casinoGamesSupplier, casinoGamesStore);

    const betSlipStore = injectBetSlipStore(rootStore, eventSupplier, injectedDependencyProvider);
    injectBalanceStore(rootStore, injectedDependencyProvider);
    provideBannersStore(rootStore);
    injectGoogleTagService(injectedDependencyProvider);
    onMounted(() => {
        bettingStore.init();
        betSlipStore.init();
        casinoGamesStore.init();
    });
    onUnmounted(() => {
        bettingStore.destroy();
        betSlipStore.destroy();
        casinoGamesStore.destroy();
    });
    return clockProvider;
}

function injectBettingStore (rootStore: RootStore, eventSupplier: EventSupplier, injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): ScoreboardStore {
    if (!injectedDependencyProvider?.scoreboardStore) {
        return provideScoreboardStore(rootStore, eventSupplier);
    } else {
        const bettingStore = injectedDependencyProvider.scoreboardStore;
        provideInjectedScoreboardStore(bettingStore);
        return bettingStore;
    }
}

function injectBetSlipStore (rootStore: RootStore, eventSupplier: EventSupplier, injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): BetSlipStore {
    if (!injectedDependencyProvider?.betSlipStore) {
        return provideBetSlipStore(rootStore, eventSupplier);
    } else {
        const store = injectedDependencyProvider.betSlipStore;
        provideInjectedBetSlipStore(store);
        return store;
    }
}

function injectBalanceStore (rootStore: RootStore, injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): BalanceStore {
    if (!injectedDependencyProvider?.balanceStore) {
        return provideBalanceStore(rootStore);
    } else {
        const store = injectedDependencyProvider.balanceStore;
        provideInjectedBalanceStore(store);
        return store;
    }
}

function injectStreamingNetwork (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined, settingsService: BaseSettingsService): StreamingNetwork {
    return injectedDependencyProvider?.streamingNetwork ?? createStreamingNetwork(settingsService);
}

function injectStreamingRequestService (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined, streamingNetwork: StreamingNetwork): StreamingRequestService {
    if (!injectedDependencyProvider?.streamingRequestService) {
        return provideStreamingRequestService(streamingNetwork);
    } else {
        const service = injectedDependencyProvider.streamingRequestService;
        return provideInjectedStreamingRequestService(service);
    }
}

function injectGoogleTagService (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined) {
    if (!injectedDependencyProvider?.googleTagService) {
        return provideGoogleTagService();
    } else {
        provideInjectedGoogleTagService(injectedDependencyProvider.googleTagService);
    }
}

function injectClockProvider (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): ClockProvider {
    if (!injectedDependencyProvider?.clockProvider) {
        return provideClockProvider();
    } else {
        const clockProvider = injectedDependencyProvider.clockProvider;
        provideInjectedClockProvider(clockProvider);
        return clockProvider;
    }
}

export function injectUUIDProvider (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): UUIDProvider {
    if (!injectedDependencyProvider?.uuidProvider) {
        return provideUUIDProvider();
    } else {
        const uuidProvider = injectedDependencyProvider.uuidProvider;
        provideInjectedUUIDProvider(uuidProvider);
        return uuidProvider;
    }
}

export function injectMiscellaneousParamsProvider (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined): MiscellaneousParamsProvider {
    if (!injectedDependencyProvider?.miscellaneousParamsProvider) {
        return provideMiscellaneousParamsProvider();
    } else {
        const miscellaneousParamsProvider = injectedDependencyProvider.miscellaneousParamsProvider;
        provideInjectedMiscellaneousParamsProvider(miscellaneousParamsProvider);
        return miscellaneousParamsProvider;
    }
}

function injectCasinoGamesStore (injectedDependencyProvider: Partial<SharedDependencyProvider> | undefined, rootStore: RootStore, casinoGamesSupplier: CasinoGamesSupplier, settingsService: BaseSettingsService): CasinoGamesStore {
    if (!injectedDependencyProvider?.casinoGamesStore) {
        return provideCasinoGamesStore(rootStore, casinoGamesSupplier, settingsService);
    } else {
        const result = injectedDependencyProvider.casinoGamesStore;
        provideInjectedCasinoGamesStore(result);
        return result;
    }
}
