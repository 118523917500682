<template>
    <template v-if="isConfirm">
        <div class="icons">
            <img :src="require(`@assets/img/icon-eye-${isPasswordVisible?'off':'on'}.png`)" alt=""
                 @click.prevent="switchPasswordVisible">
        </div>
        <label for="confirmPassword" :class="{'label-error': errors.confirmPassword}">{{$t("webdesktop.registration.email.passwordConfirmLabel")}}</label>
        <Field id="confirmPassword" name="confirmPassword" :type="isPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:8|confirmed:@password|invalidCharacters"/>
        <div class="field-error" v-if="errors.confirmPassword">{{errors.confirmPassword}}</div>
    </template>
    <template v-else>
        <div class="icons">
            <img :src="require(`@assets/img/icon-eye-${isPasswordVisible?'off':'on'}.png`)" alt=""
                 @click.prevent="isPasswordVisible = !isPasswordVisible" >
        </div>
        <label for="password" :class="{'label-error': errors.password }">{{$t("webdesktop.registration.email.passwordLabel")}}</label>
        <Field id="password" name="password" :type="isPasswordVisible?'text':'password'" autocomplete="new-password" rules="min:8|required|invalidCharacters"/>
        <div class="field-error" v-if="errors.password">{{errors.password}}</div>
    </template>
</template>

<script src="./password-field-component.ts" />
