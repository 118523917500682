import QuotationView from "@sportaq/common/enums/quotation-views";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { computed } from "vue";
import EPeriodType from "@sportaq/common/enums/period-types";

export namespace ScoreboardLineComponent {
    export interface ExtraProps {
        quotationViews: QuotationView[];
    }

    export interface Props {
        source: PeriodSortInfo;
        extraProps: ExtraProps;
    }

    export function useScoreboardLineComponent (props: Props) {
        const isCategoryLine = computed(() => props.source.period === EPeriodType.CATEGORY);
        return {
            isCategoryLine
        };
    }
}
