import { onMounted, onUnmounted, ref } from "vue";
import { useWebRestService } from "@sportaq/services/web";
import { useHttpService } from "@sportaq/services/index";
import { useBannersStore } from "@sportaq/vuex/modules/banners/banners-module";
import { useRootStore } from "@sportaq/vuex/index";
import { resolveLanguage } from "@sportaq/i18n/index";

export function useLandingCommon (styles: string[]) {
    const isDevelopment = process.env.NODE_ENV !== "production";
    let bootstrapLink: HTMLLinkElement;
    const cssLinks: HTMLLinkElement[] = [];
    let scriptElement: HTMLScriptElement;
    const initialized = ref(false);
    const rootStore = useRootStore();
    const store = useBannersStore();

    onMounted(async () => {
        const restService = useWebRestService();
        const httpService = useHttpService();

        const countryId = await httpService.getUserCountryId();
        rootStore.setUserCountryIdByIP(countryId);
        restService.startApplication(countryId, resolveLanguage()).then((banners) => {
            initialized.value = true;
            store.banners = banners;
        });
        bootstrapLink = document.createElement("link");
        bootstrapLink.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css";
        bootstrapLink.rel = "stylesheet";
        bootstrapLink.integrity = "sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT";
        bootstrapLink.crossOrigin = "anonymous";
        document.head.appendChild(bootstrapLink);
        for (const style of styles) {
            const cssLink = document.createElement("link");
            cssLink.href = (isDevelopment ? "https://dev.www.tubetgol.com" : "") + style;
            cssLink.rel = "stylesheet";
            cssLink.crossOrigin = "anonymous";
            document.head.appendChild(cssLink);
            cssLinks.push(cssLink);
        }
        scriptElement = document.createElement("script");
        scriptElement.setAttribute("src", "https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/js/bootstrap.bundle.min.js");
        scriptElement.setAttribute("integrity", "sha384-u1OknCvxWvY5kfmNBILK2hRnQC3Pr17a+RTT6rIHI7NnikvbZlHgTPOOmMi466C8");
        scriptElement.setAttribute("crossorigin", "anonymous");
        document.head.appendChild(scriptElement);
    });

    onUnmounted(() => {
        bootstrapLink.remove();
        for (const cssLink of cssLinks) {
            cssLink.remove();
        }
        scriptElement.remove();
    });

    return {
        isDevelopment,
        initialized
    };
}
