import { binarySearchIndex, insertOrReplaceIntoSortedSet } from "@sportaq/common/utils/arrays";

abstract class BaseMapOfList<K, V> {
    private readonly map = new Map<K, V[]>();

    get (key: K): V[] | undefined {
        return this.map.get(key);
    }

    set (key: K, value: V) {
        let list = this.map.get(key);
        if (!list) {
            list = [];
            this.map.set(key, list);
        }
        this.addValueToList(list, value);
    }

    clear () {
        this.map.clear();
    }

    protected addValueToList (list: V[], value: V) {
        list.push(value);
    }
}

export class MapOfList<K, V> extends BaseMapOfList<K, V> {
    delete (key: K, predicate: (value: V) => boolean) {
        const list = this.get(key);
        if (list) {
            const index = list.findIndex(value => predicate(value));
            if (index >= 0) {
                list.splice(index, 1);
            }
        }
    }
}

export class MapOfSortedList<K, V, C> extends BaseMapOfList<K, V> {
    constructor (readonly addComparator: (a: V, b: V) => number,
                 readonly searchComparator: (a: V, b: C) => number) {
        super();
    }

    delete (key: K, value: C) {
        const list = this.get(key);
        if (list) {
            const index = binarySearchIndex(list, value, this.searchComparator);
            if (index >= 0) {
                list.splice(index, 1);
            }
        }
    }

    getItem (key: K, value: C): V | undefined {
        const list = this.get(key);
        if (list) {
            const index = binarySearchIndex(list, value, this.searchComparator);
            if (index >= 0) {
                return list[index];
            }
        }
        return undefined;
    }

    protected addValueToList (list: V[], value: V) {
        insertOrReplaceIntoSortedSet(list, value, this.addComparator);
    }
}
