import { computed, SetupContext } from "vue";
import { Field } from "vee-validate";
import { countries } from "@sportaq/model/consts/countries";

const event = "update:modelValue";

interface Props {
    modelValue: number;
}

export default {
    name: "CountrySelect",
    props: {
        modelValue: Number
    },
    emits: [event],
    components: {
        Field
    },

    setup (props: Props, context: SetupContext) {
        const countryList = countries.getList();

        const countryComputed = computed({
            get: () => {
                return props.modelValue;
            },
            set: (value: number) => {
                context.emit(event, value);
            }
        });

        if (props.modelValue === undefined) {
            countryComputed.value = countries.getList()[0].id;
        }
        const selectedCountryImage = computed(() => {
            return "/img/flags/" + countries.getFlag(props.modelValue) + ".png";
        });
        return {
            countryComputed,
            selectedCountryImage,
            countryList
        };
    }
};
