import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { PropType, SetupContext } from "vue";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { Point } from "@sportaq/model/web/point";
import { useExpressWithdrawalDialogCommon } from "@sportaq/vue/views/web/withdrawal/express-withdrawal-dialog-common";

export default {
    name: "ExpressWithdrawalDialog",
    emits: [CLOSE_EVENT],
    props: {
        pointList: {
            required: true,
            type: Object as PropType<Point>
        }
    },
    components: {
        ConfirmCodeDialog
    },
    setup (props: [], context: SetupContext) {
        return useExpressWithdrawalDialogCommon(context);
    }
};
