import {
    EventViewFilter,
    FilterChanger
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import {
    EScoreboardPageType,
    PeriodsController
} from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { ScoreboardSelection, UNSELECTED } from "@sportaq/model/betting/view/scoreboard-selection";
import { Module, Store } from "vuex";
import { RootState } from "@sportaq/vuex/index";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EventType, { getEventTypeName } from "@sportaq/common/enums/event-type";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { ScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { ScoreboardVuexActions } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex-actions";
import { ScoreboardVuexMutations } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex-mutations";
import { ScoreboardVuexGetters } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex-getters";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { IdCountItem, IdItem } from "@sportaq/common/types/types";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { CountryKey } from "@sportaq/model/types/types";
import { CountryCounter } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/misc/country-counter";
import { LiveEventResult } from "@sportaq/vuex/modules/betting/scoreboard/live-event-data";
import { ESportType, getNameBySportType } from "@sportaq/common/enums/sport-type";
import EDetailRow from "@sportaq/common/enums/detail-row";
import { supportedSportTypeIds } from "@sportaq/model/consts/sport-types";
import { Route } from "@sportaq/common/types/interfaces";

export namespace ScoreboardVuex {
    export const moduleName = "scoreboardStore";

    export interface ScoreboardStoreState {
        sportTypes: IdCountItem[];

        eventViewFilter: EventViewFilter;
        periodsController: PeriodsController;

        scoreboardSelection: ScoreboardSelection;

        allExpanded: boolean;
        detailTitlesExpanded: boolean;
        inverseExpandedPositionIds: number[];

        filterMenuActive: boolean;
        liveResults: LiveEventResult[];
        needLiveResults: boolean;

        totalPreMatchEvents: number;
        totalLiveEvents: number;
        pinnedDetailRows: Map<ESportType, EDetailRow[]>;
    }

    enum Getters {
        GET_SPORT_TYPES = "getSportTypes",

        GET_EVENTS_VIEW_FILTER = "getEventsViewFilter",
        GET_PERIODS_VIEW = "getPeriodsView",
        GET_GROUP_EVENTS_VIEW = "getGroupEventsView",
        GET_COUNTRY_MENU_ITEMS = "getCountryMenuItems",
        GET_SCOREBOARD_SELECTION = "getScoreboardSelection",
        IS_EXPANDED_POSITION_ID = "isExpandedPositionId",
        ALL_EXPANDED = "allExpanded",
        IS_LEAGUE_MODE = "isLeagueMode",
        IS_LIVE_REVERSE_TIME_SORTING = "isLiveReverseTimeSorting",
        IS_COUNTRY_FAVOURITE = "isCountryFavourite",
        IS_PARTITION_FAVOURITE = "isPartitionFavourite",
        IS_FAVOURITE = "isFavourite",
        GET_FAVOURITES_COUNT = "getFavouritesCount",
        GET_SCOREBOARD_PAGE_TYPE = "getScoreboardPageType",
        GET_DETAIL_TITLES_EXPANDED = "getDetailTitlesExpanded",
        GET_FILTER_MENU_ACTIVE = "getFilterMenuActive",
        GET_LIVE_EVENT_RESULTS = "getLiveResults",
        GET_TOTAL_PRE_MATCH_EVENTS = "getTotalPreMatchEvents",
        GET_TOTAL_LIVE_EVENTS = "getTotalLiveEvents",
        IS_DETAIL_ROW_PINNED = "isDetailRowPinned",
        GET_DETAIL_ROW_PINNED = "getDetailRowPinned"
    }

    export enum Mutations {
        SYNC_EVENTS_VIEW_WITH_STORE = "syncEventsViewWithStore",
        UPDATE_EVENTS_FILTER = "updateEventsFilter",
        CLEAR_EVENTS_FILTER = "clearEventsFilter",
        SET_SCOREBOARD_SELECTION = "setScoreboardSelection",
        TOGGLE_ALL_EXPANDED = "setAllExpanded",
        TOGGLE_EXPANDED = "toggleExpanded",
        SET_LEAGUE_MODE = "setLeagueMode",
        SET_LIVE_REVERSE_TIME_SORTING = "setLiveReverseTimeSorting",
        TOGGLE_FAVOURITE_COUNTRY = "toggleFavouriteCountry",
        TOGGLE_FAVOURITE_PARTITION = "toggleFavouritePartition",
        TOGGLE_FAVOURITE_ITEM = "toggleFavouriteItem",
        SET_SCOREBOARD_PAGE_TYPE = "setScoreboardPageType",
        REMOVE_COUNTRIES_OR_PARTITIONS = "removeCountriesOrPartitions",
        TOGGLE_DETAIL_TITLES_EXPANDED = "toggleDetailTitlesExpanded",
        SET_FILTER_MENU_ACTIVE = "setFilterMenuActive",
        SHIFT_LIVE_EVENTS = "shiftLiveEvents",
        SET_NEED_LIVE_RESULTS = "setNeedLiveResults",
        TOGGLE_DETAIL_ROW_PINNED = "toggleDetailRowPinned"
    }

    enum Actions {
        UPDATE_EVENTS = "updateEvents"
    }

    export const ScoreboardStoreModule: Module<ScoreboardStoreState, RootState> = {
        namespaced: true,
        state: {
            sportTypes: supportedSportTypeIds.map(value => ({
                id: value,
                count: 0
            })),

            eventViewFilter: new EventViewFilter(),
            periodsController: new PeriodsController(),

            scoreboardSelection: UNSELECTED,

            allExpanded: false,
            detailTitlesExpanded: true,
            inverseExpandedPositionIds: [],

            filterMenuActive: false,
            liveResults: [],
            needLiveResults: false,
            totalPreMatchEvents: 0,
            totalLiveEvents: 0,
            pinnedDetailRows: new Map<ESportType, EDetailRow[]>()
        },
        getters: {
            [Getters.GET_SPORT_TYPES]: state => state.sportTypes,

            [Getters.GET_EVENTS_VIEW_FILTER]: state => state.eventViewFilter,
            [Getters.GET_PERIODS_VIEW]: state => state.periodsController.periods,
            [Getters.GET_GROUP_EVENTS_VIEW]: state => state.periodsController.groupEvents,
            [Getters.GET_COUNTRY_MENU_ITEMS]: state => state.periodsController.countryMenuItems,
            [Getters.GET_SCOREBOARD_SELECTION]: state => state.scoreboardSelection,
            [Getters.IS_EXPANDED_POSITION_ID]: ScoreboardVuexGetters.isExpandedPositionId,
            [Getters.ALL_EXPANDED]: state => state.allExpanded,
            [Getters.IS_LEAGUE_MODE]: state => state.periodsController.leagueMode,
            [Getters.IS_LIVE_REVERSE_TIME_SORTING]: state => state.periodsController.reverseTimeSorting,
            [Getters.IS_COUNTRY_FAVOURITE]: ScoreboardVuexGetters.isCountryFavourite,
            [Getters.IS_PARTITION_FAVOURITE]: ScoreboardVuexGetters.isPartitionFavourite,
            [Getters.IS_FAVOURITE]: ScoreboardVuexGetters.isFavourite,
            [Getters.GET_FAVOURITES_COUNT]: state => state.periodsController.favouritesCount,
            [Getters.GET_SCOREBOARD_PAGE_TYPE]: state => state.periodsController.scoreboardPageType,
            [Getters.GET_DETAIL_TITLES_EXPANDED]: state => state.detailTitlesExpanded,
            [Getters.GET_FILTER_MENU_ACTIVE]: state => state.filterMenuActive,
            [Getters.GET_LIVE_EVENT_RESULTS]: state => state.liveResults,
            [Getters.GET_TOTAL_PRE_MATCH_EVENTS]: state => state.totalPreMatchEvents,
            [Getters.GET_TOTAL_LIVE_EVENTS]: state => state.totalLiveEvents,
            [Getters.IS_DETAIL_ROW_PINNED]: ScoreboardVuexGetters.isDetailRowPinned,
            [Getters.GET_DETAIL_ROW_PINNED]: ScoreboardVuexGetters.getDetailRowPinned
        },
        mutations: {
            [Mutations.SYNC_EVENTS_VIEW_WITH_STORE]: ScoreboardVuexMutations.mutationSyncPeriodsViewWithStore,
            [Mutations.UPDATE_EVENTS_FILTER]: ScoreboardVuexMutations.mutationUpdateEventsFilter,
            [Mutations.CLEAR_EVENTS_FILTER]: ScoreboardVuexMutations.mutationClearEventsFilter,
            [Mutations.SET_SCOREBOARD_SELECTION]: ScoreboardVuexMutations.mutationSetScoreboardSelection,
            [Mutations.TOGGLE_ALL_EXPANDED]: ScoreboardVuexMutations.mutationAllExpanded,
            [Mutations.TOGGLE_EXPANDED]: ScoreboardVuexMutations.mutationToggleExpanded,
            [Mutations.SET_LEAGUE_MODE]: ScoreboardVuexMutations.setLeagueMode,
            [Mutations.SET_LIVE_REVERSE_TIME_SORTING]: ScoreboardVuexMutations.setLiveReverseTimeSorting,
            [Mutations.TOGGLE_FAVOURITE_COUNTRY]: ScoreboardVuexMutations.toggleFavouriteCountry,
            [Mutations.TOGGLE_FAVOURITE_PARTITION]: ScoreboardVuexMutations.toggleFavouritePartition,
            [Mutations.TOGGLE_FAVOURITE_ITEM]: ScoreboardVuexMutations.toggleFavouriteItem,
            [Mutations.SET_SCOREBOARD_PAGE_TYPE]: ScoreboardVuexMutations.setScoreboardPageType,
            [Mutations.REMOVE_COUNTRIES_OR_PARTITIONS]: ScoreboardVuexMutations.removeCountriesOrPartitions,
            [Mutations.TOGGLE_DETAIL_TITLES_EXPANDED]: ScoreboardVuexMutations.toggleDetailTitlesExpanded,
            [Mutations.SET_FILTER_MENU_ACTIVE]: ScoreboardVuexMutations.mutationFilterMenuActive,
            [Mutations.SHIFT_LIVE_EVENTS]: ScoreboardVuexMutations.shiftLiveEvents,
            [Mutations.SET_NEED_LIVE_RESULTS]: ScoreboardVuexMutations.setNeedLiveResults,
            [Mutations.TOGGLE_DETAIL_ROW_PINNED]: ScoreboardVuexMutations.toggleDetailRowPinned
        },

        actions: {
            [Actions.UPDATE_EVENTS]: ScoreboardVuexActions.actionUpdateEvents
        }
    };

    export class ScoreboardStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements ScoreboardStore {
        constructor (store: Store<RootState>, private readonly eventSupplier: EventSupplier) {
            super(store, moduleName);
        }

        shiftLiveEvents (count: number) {
            this.mutate(Mutations.SHIFT_LIVE_EVENTS, count);
        }

        setNeedLiveResults (value: boolean) {
            this.mutate(Mutations.SET_NEED_LIVE_RESULTS, value);
        }

        getSportTypes (): IdCountItem[] {
            return this.get(Getters.GET_SPORT_TYPES);
        }

        getPeriodsView (): PeriodSortInfo[] {
            return this.get(Getters.GET_PERIODS_VIEW);
        }

        getGroupEventsView (): PeriodSortInfo[] {
            return this.get(Getters.GET_GROUP_EVENTS_VIEW);
        }

        getLiveResults (): LiveEventResult[] {
            return this.get(Getters.GET_LIVE_EVENT_RESULTS);
        }

        getCountryMenuItems (): CountryCounter[] {
            return this.get(Getters.GET_COUNTRY_MENU_ITEMS);
        }

        updateEventsFilters (changers: FilterChanger[]): void {
            this.mutate(Mutations.UPDATE_EVENTS_FILTER, {
                eventSupplier: this.eventSupplier,
                changers
            });
        }

        updateEventsFilter (changer: FilterChanger): void {
            this.updateEventsFilters([changer]);
        }

        clearEventsFilter (): void {
            this.mutate(Mutations.CLEAR_EVENTS_FILTER);
        }

        getEventsViewFilter (): EventViewFilter {
            return this.get(Getters.GET_EVENTS_VIEW_FILTER);
        }

        getScoreboardSelection (): ScoreboardSelection {
            return this.get(Getters.GET_SCOREBOARD_SELECTION);
        }

        setScoreboardSelection (route: Route, selection: ScoreboardSelection) {
            this.mutate(Mutations.SET_SCOREBOARD_SELECTION, { selected: selection });
            let path = route.path;
            if (selection !== UNSELECTED) {
                const event = this.eventSupplier.getEvent(selection.eventType, selection.positionId);
                const eventTypeName = getEventTypeName(event.eventType);
                const sportTypeName = getNameBySportType(event.sportTypeId);
                if (sportTypeName) {
                    path = `/${eventTypeName}/${sportTypeName}/${event.positionId}`;
                }
            }
            if (!window.location.href.endsWith(path)) {
                history.pushState({}, "", path);
            }
        }

        isExpandedEventId (eventId: number): boolean {
            const func = this.get(Getters.IS_EXPANDED_POSITION_ID);
            return func(eventId);
        }

        toggleAllExpanded (): void {
            this.mutate(Mutations.TOGGLE_ALL_EXPANDED, { eventSupplier: this.eventSupplier });
        }

        toggleExpanded (eventType: EventType, positionId: number): void {
            this.mutate(Mutations.TOGGLE_EXPANDED, {
                eventSupplier: this.eventSupplier,
                eventType,
                positionId
            });
        }

        isAllExpanded (): boolean {
            return this.get(Getters.ALL_EXPANDED);
        }

        get leagueMode (): boolean {
            return this.get(Getters.IS_LEAGUE_MODE);
        }

        set leagueMode (value: boolean) {
            this.mutate(Mutations.SET_LEAGUE_MODE, {
                eventSupplier: this.eventSupplier,
                value
            });
        }

        get liveReverseTimeSorting (): boolean {
            return this.get(Getters.IS_LIVE_REVERSE_TIME_SORTING);
        }

        set liveReverseTimeSorting (value: boolean) {
            this.mutate(Mutations.SET_LIVE_REVERSE_TIME_SORTING, {
                eventSupplier: this.eventSupplier,
                value
            });
        }

        get filterMenuActive (): boolean {
            return this.get(Getters.GET_FILTER_MENU_ACTIVE);
        }

        set filterMenuActive (value: boolean) {
            this.mutate(Mutations.SET_FILTER_MENU_ACTIVE, value);
        }

        getFavouritesCount (): number {
            return this.get(Getters.GET_FAVOURITES_COUNT);
        }

        // region Favourites
        isFavourite (positionId: number): void {
            const getter = this.get(Getters.IS_FAVOURITE);
            return getter(positionId);
        }

        toggleFavouriteItem (eventType: EventType, positionId: number): void {
            this.mutate(Mutations.TOGGLE_FAVOURITE_ITEM, {
                eventSupplier: this.eventSupplier,
                eventType,
                positionId
            });
        }

        get scoreboardPageType (): EScoreboardPageType {
            return this.get(Getters.GET_SCOREBOARD_PAGE_TYPE);
        }

        set scoreboardPageType (value: EScoreboardPageType) {
            this.mutate(Mutations.SET_SCOREBOARD_PAGE_TYPE, {
                eventSupplier: this.eventSupplier,
                value
            });
        }

        isCountryFavourite (key: CountryKey): boolean {
            const getter = this.get(Getters.IS_COUNTRY_FAVOURITE);
            return getter(key);
        }

        isPartitionFavourite (country: CountryKey, item: IdItem): boolean {
            const getter = this.get(Getters.IS_PARTITION_FAVOURITE);
            return getter(country, item);
        }

        toggleFavouriteCountry (item: CountryKey): void {
            this.mutate(Mutations.TOGGLE_FAVOURITE_COUNTRY, {
                eventSupplier: this.eventSupplier,
                item
            });
        }

        toggleFavouritePartition (country: CountryKey, item: IdItem): void {
            this.mutate(Mutations.TOGGLE_FAVOURITE_PARTITION, {
                eventSupplier: this.eventSupplier,
                country,
                item
            });
        }

        get detailTitlesExpanded () {
            return this.get(Getters.GET_DETAIL_TITLES_EXPANDED);
        }

        toggleDetailTitlesExpanded () {
            this.mutate(Mutations.TOGGLE_DETAIL_TITLES_EXPANDED);
        }

        // endregion

        get totalPreMatchEvents () {
            return this.get(Getters.GET_TOTAL_PRE_MATCH_EVENTS);
        }

        get totalLiveEvents () {
            return this.get(Getters.GET_TOTAL_LIVE_EVENTS);
        }

        isDetailRowPinned (sportType: ESportType, id: EDetailRow): boolean {
            const func = this.get(Getters.IS_DETAIL_ROW_PINNED);
            return func(sportType, id);
        }

        toggleDetailRowPinned (sportType: ESportType, id: EDetailRow): void {
            this.mutate(Mutations.TOGGLE_DETAIL_ROW_PINNED, {
                sportType,
                id
            });
        }

        getDetailRowPinned (sportType: ESportType): EDetailRow[] {
            const func = this.get(Getters.GET_DETAIL_ROW_PINNED);
            return func(sportType);
        }

        init () {
            this.eventSupplier.addUpdateListener(this.updateEventsHandle.bind(this));
            this.eventSupplier.addCountriesOrPartitionsRemovedListener(this.countriesOrPartitionsRemovedHandler.bind(this));
        }

        destroy () {
            this.eventSupplier.removeUpdateListener(this.updateEventsHandle);
            this.eventSupplier.removeCountriesOrPartitionsRemovedListener(this.countriesOrPartitionsRemovedHandler);
        }

        private updateEventsHandle (eventSupplier: EventSupplier, eventType: EventType, list: EventDiffsResponse[], nonConfirmedPositionIds: number[]): Promise<void> {
            return this.action(Actions.UPDATE_EVENTS, {
                eventSupplier,
                eventType,
                list,
                nonConfirmedPositionIds
            });
        }

        private countriesOrPartitionsRemovedHandler (countries: CountryKey[], partitionIds: number[]) {
            this.mutate(Mutations.REMOVE_COUNTRIES_OR_PARTITIONS, {
                countries,
                partitionIds
            });
        }
    }
}
