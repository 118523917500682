import { computed, onMounted, onUnmounted, PropType, SetupContext, shallowRef } from "vue";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { useCasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { updateGameUrl } from "@sportaq/vue/components/games/common/functions/common-games-functions";
import { useRoute } from "vue-router";

interface Props {
    item: CasinoGame;
    showDemo: boolean;

    close (): void;
}

const START_GAME_EVENT = "startGame";
const START_DEMO_EVENT = "startDemo";

export default {
    props: {
        item: {
            type: Object as PropType<CasinoGame>,
            required: true
        },
        showDemo: {
            required: false,
            type: Boolean,
            default: false
        },
        close: {
            type: Function,
            required: true
        }
    },
    emits: [START_GAME_EVENT, START_DEMO_EVENT],
    setup (props: Props, setupContext: SetupContext) {
        const store = useCasinoGamesStore();
        const supplier = useCasinoGamesSupplier();
        const nonReactiveGame = supplier.getGameWithType(props.item.gameType, props.item.id);
        const route = useRoute();

        const game = shallowRef(nonReactiveGame);
        const provider = computed(() => store.getProviders().find(value => value.id === game.value.provider)?.name);
        const showDemoSelector = computed(() => props.showDemo && game.value.hasDemo);

        function startGame () {
            props.close();
            setupContext.emit(START_GAME_EVENT, props.item);
        }

        function startDemo () {
            props.close();
            setupContext.emit(START_DEMO_EVENT, props.item);
        }

        onMounted(() => {
            updateGameUrl(route, nonReactiveGame.gameType, nonReactiveGame.id);
        });

        onUnmounted(() => {
            updateGameUrl(route, undefined, undefined);
        });

        return {
            game,

            provider,
            showDemoSelector,
            startGame,
            startDemo
        };
    }
};
