import { onMounted, onUnmounted, PropType } from "vue";

const SCROLL_DELTA = 100;

interface Props {
    bodyElement: Element;
}

export default {
    name: "ScrollButtons",
    // eslint-disable-next-line
    props: {
        bodyElement: { required: true, type: Object as PropType<Element> }
    },
    setup (props:Props) {
        let buttonPressed = false;
        let interval: ReturnType<typeof setInterval> | undefined;

        function scrollUp () {
            scroll(SCROLL_DELTA);
        }

        function scrollDown () {
            scroll(-SCROLL_DELTA);
        }

        function scroll (delta: number) {
            if (buttonPressed) {
                return;
            }
            if (props.bodyElement) {
                props.bodyElement.scrollTop += delta;
            }
            buttonPressed = true;
            setTimeout(() => {
                if (buttonPressed && interval === undefined) {
                    interval = setInterval(() => {
                        if (props.bodyElement) {
                            props.bodyElement.scrollTop += delta;
                        }
                    }, 20);
                }
            }, 500);
        }

        function release () {
            clearInterval(interval);
            interval = undefined;
            buttonPressed = false;
        }

        onMounted(() => {
            document.addEventListener("mouseup", release);
        });

        onUnmounted(() => {
            document.removeEventListener("mouseup", release);
        });
        return {
            scrollUp,
            scrollDown,
            release
        };
    }
};
