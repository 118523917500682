<template>
    <div class="filters">
        <div class="item" :class="{ active: allButtonActive }" @click="clearFilters" data-cy="clear-filters-btn">
            {{ $t("betting.countryMenu.all") }}
        </div>
        <template v-if="showDateFilter">
            <div class="item" v-for="button of buttons" :key="button.id" :class="{ active: button.id === selectedId }"
                 @click="select(button)" :data-cy="`time-filter-btn-${button.id}`">
                {{ button.name }}
            </div>
        </template>
    </div>
</template>

<script src="./betting-filter-menu-date-component.ts"/>
