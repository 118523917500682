<template>
    <div class="search" data-cy="event-search-dialog">
        <div class="search-head">
            <div class="search-head-title">{{ $t("betting.navigationSideBar.search") }}</div>
            <div class="search-close" @click="close" data-cy="close-btn"></div>
        </div>
        <div class="search-field">
            <input type="text" class="search-field-input"
                   :placeholder="$t('betting.searchDialog.placeholder')"
                   :value="searchText"
                   @input="updateSearch($event.target.value)"
                   ref="inputField"
                   data-cy="search-input"
            />
        </div>
        <div class="search-body" v-if="searchText">
            <div class="search-content" v-if="searchCompleted">
                <template v-if="searchResult.length > 0">
                    <template v-for="sport of filteredSearchResult" :key="sport.sportType">
                        <template v-for="partition of sport.partitions" :key="partition.partition.id">
                            <div class="search-block">
                                <div class="search-block-title"
                                     :data-cy="`search-result-data-category-${sport.sportType}-${partition.partition.id}`">
                                    <img class="icon"
                                         :src="require(`@assets/img/mobile/${imageThemePath}/sport-menu/${sport.image}.svg`)"
                                         alt=""/>
                                    <div class="name">{{ sport.name }}</div>
                                    <img :src="`/img/flags/${partition.flag}.png`" class="image" alt="">
                                    <div class="value">{{ partition.partition.name }}</div>
                                </div>
                                <div class="search-block-line" v-for="item of partition.items"
                                     :key="item.positionId"
                                     :data-cy="`search-result-data-item-${sport.sportType}-${partition.partition.id}-${item.positionId}`"
                                     @click="openEventDetails(item)">
                                    <div class="live" :data-cy="`search-result-data-item-${sport.sportType}-${partition.partition.id}-${item.positionId}-live`" v-if="item.liveBetting">LIVE</div>
                                    <div class="name" :data-cy="`search-result-data-item-${sport.sportType}-${partition.partition.id}-${item.positionId}-name`">{{ item.participants }}</div>
                                    <div class="date" :data-cy="`search-result-data-item-${sport.sportType}-${partition.partition.id}-${item.positionId}-date`">{{ item.startTimeStr }}</div>
                                </div>
                            </div>
                        </template>
                    </template>
                </template>
                <div class="empty-search-results" data-cy="empty-search-results" v-else>
                    {{ $t("betting.searchDialog.emptyResults") }}
                </div>
            </div>
            <div class="preloader" v-else>
                <div class="preloader-el"></div>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-event-search-dialog-component.ts"/>
