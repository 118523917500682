import { Path } from "vue-i18n";
import { WritableComputedRef } from "vue";

export enum ESupportedLocale {
    RU = "ru",
    ES = "es",
    EN = "en",
    PT = "pt"
}

export interface Localization {
    t (key: Path): string;

    t (key: Path, named: unknown): string;
}

export interface LocaleChanger extends Localization {
    locale: WritableComputedRef<string>;
}

export interface I18nSettings {
    disabledLanguages: string[];

    defaultLanguage: ESupportedLocale;
}
