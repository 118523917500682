import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";

export default {
    name: "BonusNotificationDialog",
    emits: { CLOSE_EVENT },
    setup (props: [], context: SetupContext) {
        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        return {
            onClose
        };
    }
};
