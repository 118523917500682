<template>
    <div class="popup active" id="change-phone-dialog">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.changePhoneDialog.caption")}}</div>
            <div class="popup-content">
                <Form @submit="changePhone" v-slot="{ errors, handleSubmit }">
                    <div class="form-line">
                        <label for="phone" :class="{'label-error': errors.phone}">{{$t("webdesktop.changePhoneDialog.phone")}}</label>
                        <Field id="phone" name="phone" type="text" rules="required|regex:^\+[0-9]+$" v-model="phone" :placeholder="phonePlaceholder" data-cy="phone"/>
                        <div class="field-error" v-if="errors.phone">{{errors.phone}}</div>
                    </div>
                    <div class="form-line">
                        <button id="submit-change-phone" class="button button-primary" @click.stop.prevent="handleSubmit(changePhone)" data-cy="submit-change-phone">{{$t("webdesktop.changePhoneDialog.submitButton")}}</button>
                    </div>
                    <div class="form-line">
                        <button class="button button-secondary" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="confirmDialogVisible" @close="onConfirmDialogClose" @success="onConfirmSuccess" />
</template>

<script src="./change-phone-dialog-component.ts" />
