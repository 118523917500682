import { ISubscription } from "rsocket-types";
import EventType, { getEventTypeName } from "@sportaq/common/enums/event-type";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { ScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { StreamingNetwork } from "@sportaq/services/streaming/streaming-network/streaming-network";
import { StreamingNetworkRoutes } from "@sportaq/services/streaming/streaming-network/streaming-network-routes";
import {
    EventDiffsListResponseDeserializer
} from "@sportaq/services/streaming/streaming-network/deserializers/event-diffs-list-response-deserializer";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { appLogger } from "@sportaq/common/utils/logger";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import {
    CasinoGameDiffsListResponseDeserializer
} from "@sportaq/services/streaming/streaming-network/deserializers/casino-game-diffs-list-response-deserializer";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { StreamingRequestService } from "@sportaq/services/streaming/streaming-request-service";
import { gameProviders } from "@sportaq/model/consts/game-providers";
import { CasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";

export class StreamingUpdater {
    private preMatchSubscription?: ISubscription;
    private liveSubscription?: ISubscription;
    private casinoGamesSubscription?: ISubscription;

    constructor (private readonly streamingNetwork: StreamingNetwork,
                 private readonly streamingRequestService: StreamingRequestService,
                 private readonly settingsService: BaseSettingsService,
                 private readonly clockProvider: ClockProvider,
                 private readonly eventSupplier: EventSupplier,
                 private readonly scoreboardStore: ScoreboardStore,
                 private readonly casinoGamesSupplier: CasinoGamesSupplier,
                 private readonly casinoGamesStore: CasinoGamesStore) {
    }

    async subscribeOnStreams (): Promise<void> {
        this.unSubscribeFromStreams();
        if (isServiceEnabled(this.settingsService, EGameService.Betting)) {
            await this.subscribeOnEventsStream(EventType.LIVE, this.clockProvider, () => this.liveSubscription, subscription => (this.liveSubscription = subscription));
            await this.subscribeOnEventsStream(EventType.PRE_MATCH, this.clockProvider, () => this.preMatchSubscription, subscription => (this.preMatchSubscription = subscription));
        }
        if (isServiceEnabled(this.settingsService, EGameService.Virtual)) {
            const providers = await this.streamingRequestService.getProviders(this.settingsService.pointSettings.brandId);
            gameProviders.setProviders(providers);
            this.casinoGamesStore.setProviders(providers);
            await this.subscribeOnCasinoGamesStream();
        }
    }

    unSubscribeFromStreams () {
        try {
            this.preMatchSubscription?.cancel();
        } catch (e) {
            // Ignored
        }
        this.preMatchSubscription = undefined;

        try {
            this.liveSubscription?.cancel();
        } catch (e) {
            // Ignored
        }
        this.liveSubscription = undefined;

        try {
            this.casinoGamesSubscription?.cancel();
        } catch (e) {
            // Ignored
        }
        this.casinoGamesSubscription = undefined;
    }

    private async subscribeOnEventsStream (eventType: EventType, clockProvider: ClockProvider, subscriptionSupplier: () => ISubscription | undefined, subscribeCallback: (subscription: ISubscription) => void): Promise<void> {
        const route = StreamingUpdater.resolveRoute(eventType);

        const eventDiffsMsgFlowable = await this.streamingNetwork.requestStream(route, undefined, EventDiffsListResponseDeserializer.deserializeBinary);
        // TODO remove
        // let packetCounter = 0;
        eventDiffsMsgFlowable.subscribe({
            onNext: value => {
                // TODO remove
                // performance.clearMarks();
                // performance.clearMeasures();
                // performance.mark(eventType + ". Before update event");

                // TODO remove
                // For debug purposes
                // const delimiter = false;
                // console.debug(eventType === EventType.PRE_MATCH ? `${delimiter ? "DELIMITER" : ""}PREMATCH ${++packetCounter}` : `${delimiter ? "DELIMITER" : ""}LIVE ${++packetCounter}`);
                // const values = value.filter(v => true);
                // if (values) {
                //     const delimiter = (values.findIndex(v => v.diffType === 4)) >= 0;
                //     console.debug(eventType === EventType.PRE_MATCH ? ` ${delimiter ? "DELIMITER" : ""} PREMATCH ${++packetCounter}` : `${delimiter ? "DELIMITER" : ""} LIVE ${++packetCounter}`);
                //     console.debug(JSON.stringify(values));
                // }

                this.eventSupplier.update(eventType, value, clockProvider, this.settingsService.pointSettings)
                    .then(() => {
                        // console.lo(eventType === EventType.PRE_MATCH ? `${delimiter ? "DELIMITER" : ""}PREMATCH ${packetCounter} IS HANDLED` : `${delimiter ? "DELIMITER" : ""}LIVE ${packetCounter} IS HANDLED`);
                        // TODO remove
                        /*
                        performance.mark(eventType + ". After update event");
                        performance.measure(eventType + ". Update event time", eventType + ". Before update event", eventType + ". After update event");
                        const performanceEntries = performance.getEntriesByName(eventType + ". Update event time", "measure");
                        if (performanceEntries.length > 0) {
                            const duration = performanceEntries[0].duration;
                            logDebug(`Handle time for ${eventTypeName(eventType)} was ${duration}`);
                        }
                        */
                        const subscription = subscriptionSupplier();
                        if (subscription) {
                            subscription.request(1);
                        }
                    });
            },
            onError: error => {
                appLogger.logger.error(`Error on getting ${getEventTypeName(eventType)} events`, error);
                // Unsubscribe on error
                this.unSubscribeFromStreams();
            },
            onSubscribe: subscription => {
                subscribeCallback(subscription);
                subscription.request(1);
            }
        });
    }

    private async subscribeOnCasinoGamesStream (): Promise<void> {
        const flow = await this.streamingNetwork.requestStream(StreamingNetworkRoutes.CASINO_GAMES_DIFFS_STREAM,
            undefined,
            msg => CasinoGameDiffsListResponseDeserializer.deserializeBinary(this.settingsService.pointSettings.brandId, msg));
        // TODO remove
        // let packetCounter = 0;
        flow.subscribe({
            onNext: value => {
                // TODO remove
                // performance.clearMarks();
                // performance.clearMeasures();
                // performance.mark(eventType + ". Before update event");

                // TODO remove
                // For debug purposes

                // const values = value.filter(v => v.diffType === 1);
                // if (values) {
                //    const delimiter = (values.findIndex(v => v.diffType === 4)) >= 0;
                //    console.log(` ${delimiter ? "DELIMITER" : ""} GAMES ${++packetCounter}`);
                //    console.log(JSON.stringify(values));
                // }

                this.casinoGamesSupplier.update(value)
                    .then(() => {
                        // console.lo(eventType === EventType.PRE_MATCH ? `${delimiter ? "DELIMITER" : ""}PREMATCH ${packetCounter} IS HANDLED` : `${delimiter ? "DELIMITER" : ""}LIVE ${packetCounter} IS HANDLED`);
                        // TODO remove
                        /*
                        performance.mark(eventType + ". After update event");
                        performance.measure(eventType + ". Update event time", eventType + ". Before update event", eventType + ". After update event");
                        const performanceEntries = performance.getEntriesByName(eventType + ". Update event time", "measure");
                        if (performanceEntries.length > 0) {
                            const duration = performanceEntries[0].duration;
                            logDebug(`Handle time for ${eventTypeName(eventType)} was ${duration}`);
                        }
                        */
                        this.casinoGamesSubscription?.request(1);
                    });
            },
            onError: error => {
                appLogger.logger.error("Error on getting casino games", error);
                // Unsubscribe on error
                this.unSubscribeFromStreams();
            },
            onSubscribe: subscription => {
                this.casinoGamesSubscription = subscription;
                subscription.request(1);
            }
        });
    }

    private static resolveRoute (eventType: EventType): string {
        switch (eventType) {
            case EventType.PRE_MATCH: {
                return StreamingNetworkRoutes.PRE_MATCH_EVENT_DIFFS_STREAM;
            }
            case EventType.LIVE: {
                return StreamingNetworkRoutes.LIVE_EVENT_DIFFS_STREAM;
            }
        }
    }
}
