export const COMMON_ROUTES = {
    SPORT: {
        name: "sport",
        path: "/sport"
    },
    LIVE: {
        name: "live",
        path: "/live"
    },
    NOTFOUND: {
        name: "notfound",
        path: "/notfound"
    },
    FAVOURITES: {
        name: "favourites",
        path: "/favourites"
    },
    RESULTS: {
        name: "results",
        path: "/results"
    },
    VIRTUAL: {
        name: "virtual",
        path: "/virtual"
    },
    CASINO: {
        name: "casino",
        path: "/casino"
    },
    HORSE: {
        name: "horse",
        path: "/horse"
    },
    AVIATOR: {
        name: "aviator",
        path: "/aviator"
    },
    TERMS: {
        name: "terms",
        path: "/terms"
    },
    AML: {
        name: "aml",
        path: "/aml"
    },
    PRIVACY_POLICY: {
        name: "privacy",
        path: "/privacy"
    },
    RESPONSIBLE_GAMING: {
        name: "respgaming",
        path: "/respgaming"
    },
    HOWTO: {
        name: "how-to",
        path: "/how-to"
    }
};
