import { ActionContext, Module, Store } from "vuex";
import { RootState } from "@sportaq/vuex/index";
import EventType from "@sportaq/common/enums/event-type";
import { Quotation } from "@sportaq/model/betting/events/quotation";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { nextTick } from "vue";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { BetSlipVuexMutations } from "@sportaq/vuex/modules/betting/bet-slip/internal/bet-slip-vuex-mutations";
import { BetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { PlaceBetDetailedError } from "@sportaq/model/types/place-bet-response";
import { CombinedStake, SimpleStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";

export namespace BetSlipVuex {
    export const moduleName = "betSlipStore";

    export interface BetSlipStoreState {
        simples: SimpleStake[];
        combined: CombinedStake[];
        isShowed: boolean;
        isConfirmed: boolean;
        fullChecking: boolean;
        topMessage?: string;
        isCombinedBlocked: boolean;
    }

    enum Getters {
        GET_SHOWED = "getShowed",
        GET_CONFIRMED = "getConfirmed",
        GET_SIMPLE_PRESENT = "getSimplePresent",
        GET_SIMPLES = "getSimples",
        GET_COMBINED = "getCombined",
        GET_TOP_MESSAGE = "getTopMessage",
        FULL_CHECKING_COEF = "getAcceptingWithChangedCoef",
        GET_COMBINED_BLOCKED = "getIsCombinedBlocked"
    }

    enum Mutations {
        SET_SHOWED = "setShowed",
        ADD_SIMPLE = "addSimple",
        SET_SIMPLE_AMOUNT = "setSimpleAmount",
        SET_COMBINED_AMOUNT = "setCombinedAmount",
        CONFIRM_SIMPLE = "confirmSimple",
        CONFIRM_ALL_SIMPLES = "confirmAllSimples",
        REMOVE_SIMPLE = "removeSimple",
        CLEAR_STAKES = "clearStakes",
        SYNC_SIMPLES_VIEW_WITH_STORE = "SYNC_SIMPLES_VIEW_WITH_STORE",
        HANDLE_PLACE_BET_ERRORS = "handlePlaceBetErrors",
        SET_ACCEPTING_WITH_CHANGED_COEF = "setAcceptingWithChangedCoef"
    }

    enum Actions {
        UPDATE_EVENTS = "updateEvents"
    }

    export const BetSlipStoreModule: Module<BetSlipStoreState, RootState> = {
        namespaced: true,
        state: {
            isShowed: false,
            isConfirmed: true,
            isCombinedBlocked: false,
            fullChecking: false,
            simples: [],
            combined: [],
            topMessage: undefined
        },
        getters: {
            [Getters.GET_SHOWED]: state => state.isShowed,
            [Getters.GET_CONFIRMED]: state => state.isConfirmed,
            [Getters.GET_SIMPLE_PRESENT]: getSimplePresent,
            [Getters.GET_SIMPLES]: state => state.simples,
            [Getters.GET_COMBINED]: state => state.combined,
            [Getters.GET_TOP_MESSAGE]: state => state.topMessage,
            [Getters.FULL_CHECKING_COEF]: state => state.fullChecking,
            [Getters.GET_COMBINED_BLOCKED]: state => state.isCombinedBlocked
        },

        mutations: {
            [Mutations.SET_SHOWED]: BetSlipVuexMutations.mutationSetShowed,
            [Mutations.ADD_SIMPLE]: BetSlipVuexMutations.mutationAddSimple,
            [Mutations.SET_SIMPLE_AMOUNT]: BetSlipVuexMutations.mutationSetSimpleAmount,
            [Mutations.SET_COMBINED_AMOUNT]: BetSlipVuexMutations.mutationSetCombinedAmount,
            [Mutations.CONFIRM_SIMPLE]: BetSlipVuexMutations.mutationConfirmSimple,
            [Mutations.REMOVE_SIMPLE]: BetSlipVuexMutations.mutationRemoveSimple,
            [Mutations.CLEAR_STAKES]: BetSlipVuexMutations.mutationClearStakes,
            [Mutations.SYNC_SIMPLES_VIEW_WITH_STORE]: BetSlipVuexMutations.mutationSyncSimplesWithStore,
            [Mutations.CONFIRM_ALL_SIMPLES]: BetSlipVuexMutations.mutationConfirmAllSimples,
            [Mutations.HANDLE_PLACE_BET_ERRORS]: BetSlipVuexMutations.mutationHandlePlaceBetErrors,
            [Mutations.SET_ACCEPTING_WITH_CHANGED_COEF]: BetSlipVuexMutations.mutationSetAcceptingWithChangedCoef
        },

        actions: {
            [Actions.UPDATE_EVENTS]: actionUpdateEvents
        }
    };

// region Getters
    function getSimplePresent (state: BetSlipStoreState) {
        return (eventType: EventType, quotation: Quotation) => {
            for (const q of state.simples) {
                if (q.equalsQuotation(eventType, quotation)) {
                    return true;
                }
            }
            return false;
        };
    }

// endregion

// region Actions
    async function actionUpdateEvents (context: ActionContext<BetSlipStoreState, RootState>, payload: { eventSupplier: EventSupplier; eventType: EventType; list: EventDiffsResponse[]; pointSettings: PointSettings }): Promise<void> {
        await nextTick(() => {
            context.commit(Mutations.SYNC_SIMPLES_VIEW_WITH_STORE, payload);
        });
    }

// endregion

    export class BetSlipStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements BetSlipStore {
        constructor (store: Store<RootState>, private readonly eventSupplier: EventSupplier) {
            super(store, moduleName);
        }

        get isShowed () {
            return this.get(Getters.GET_SHOWED);
        }

        set isShowed (value: boolean) {
            this.mutate(Mutations.SET_SHOWED, value);
        }

        get isConfirmed (): boolean {
            return this.get(Getters.GET_CONFIRMED);
        }

        get isCombinedBlocked (): boolean {
            return this.get(Getters.GET_COMBINED_BLOCKED);
        }

        get simples () {
            return this.get(Getters.GET_SIMPLES);
        }

        get combined () {
            return this.get(Getters.GET_COMBINED);
        }

        get topMessage (): string | undefined {
            return this.get(Getters.GET_TOP_MESSAGE);
        }

        addSimpleStake (event: BettingEvent, quotation: Quotation, settings: PointSettings) {
            this.mutate(Mutations.ADD_SIMPLE, {
                event,
                quotation,
                pointSettings: settings
            });
        }

        removeSimpleStake (eventType: EventType, positionId: number, id: number, settings: PointSettings) {
            this.mutate(Mutations.REMOVE_SIMPLE, {
                eventType,
                positionId,
                id,
                pointSettings: settings
            });
        }

        clear () {
            this.mutate(Mutations.CLEAR_STAKES);
        }

        isStakePresent (eventType: EventType, quotation: Quotation): boolean {
            const func = this.get(Getters.GET_SIMPLE_PRESENT);
            return func(eventType, quotation);
        }

        init () {
            this.eventSupplier.addUpdateListener(this.updateEventsHandle.bind(this));
        }

        destroy () {
            this.eventSupplier.removeUpdateListener(this.updateEventsHandle);
        }

        handlePlacesBetErrors (causes: PlaceBetDetailedError[], settings: PointSettings): void {
            this.mutate(Mutations.HANDLE_PLACE_BET_ERRORS, {
                causes,
                pointSettings: settings
            });
        }

        private updateEventsHandle (eventSupplier: EventSupplier, eventType: EventType, list: EventDiffsResponse[], nonConfirmedPositionIds: number[], settings: PointSettings): Promise<void> {
            return this.action(Actions.UPDATE_EVENTS, {
                eventSupplier,
                eventType,
                list,
                nonConfirmedPositionIds,
                pointSettings: settings
            });
        }

        setSimpleStakeAmount (eventType: EventType, positionId: number, id: number, amount?: number): void {
            this.mutate(Mutations.SET_SIMPLE_AMOUNT, {
                eventType,
                positionId,
                id,
                amount
            });
        }

        confirmSimpleStake (eventType: EventType, positionId: number, id: number): void {
            this.mutate(Mutations.CONFIRM_SIMPLE, {
                eventType,
                positionId,
                id
            });
        }

        confirmAllSimples (): void {
            this.mutate(Mutations.CONFIRM_ALL_SIMPLES);
        }

        setCombinedStakeAmount (base: number, bonus: boolean, amount?: number): void {
            this.mutate(Mutations.SET_COMBINED_AMOUNT, {
                base,
                bonus,
                amount
            });
        }

        get fullChecking (): boolean {
            return this.get(Getters.FULL_CHECKING_COEF);
        }

        set fullChecking (value: boolean) {
            this.mutate(Mutations.SET_ACCEPTING_WITH_CHANGED_COEF, value);
        }
    }
}
