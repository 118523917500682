/* eslint quote-props: ["error", "always"] */
export default {
    "detailInfo": {
        "groups": {
            "americanFootball": {
                "evenOddMatch": "Par ou ímpar",
                "evenOddMatchHalf1": "Par ou ímpar 1º meio tempo",
                "evenOddMatchQuarter1": "Par ou ímpar 1º quarto",
                "evenOddMatchQuarter2": "Par ou ímpar 2º quarto",
                "evenOddMatchQuarter3": "Par ou ímpar 3º quarto",
                "evenOddMatchQuarter4": "Par ou ímpar 4º quarto",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1º Meio tempo",
                "handicapHalf2": "Handicap 2º Meio tempo",
                "handicapQuarter1": "Handicap 1º quarto",
                "handicapQuarter2": "Handicap 2º quarto",
                "handicapQuarter3": "Handicap 3º quarto",
                "handicapQuarter4": "Handicap 4º quarto",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1º Meio tempo",
                "individualTotalTeam1Half2": "Total {team1} 2º Meio tempo",
                "individualTotalTeam1Quarter1": "Total {team1} 1º quarto",
                "individualTotalTeam1Quarter2": "Total {team1} 2º quarto",
                "individualTotalTeam1Quarter3": "Total {team1} 3º quarto",
                "individualTotalTeam1Quarter4": "Total {team1} 4º quarto",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1º meio tempo",
                "individualTotalTeam2Half2": "Total {team2} 2º meio tempo",
                "individualTotalTeam2Quarter1": "Total {team2} 1º quarto",
                "individualTotalTeam2Quarter2": "Total {team2} 2º quarto",
                "individualTotalTeam2Quarter3": "Total {team2} 3º quarto",
                "individualTotalTeam2Quarter4": "Total {team2} 4º quarto",
                "total": "Total",
                "totalHalf1": "Total 1º meio tempo",
                "totalHalf2": "Total 2º meio tempo",
                "totalQuarter1": "Total 1º quarto",
                "totalQuarter2": "Total 2º quarto",
                "totalQuarter3": "Total 3º quarto",
                "totalQuarter4": "Total 4º quarto",
                "x1_2": "Resultado da Partida",
                "x1x2Half1": "Resultado do 1º meio tempo",
                "x1x2Half2": "Resultado do 2º meio tempo",
                "x1x2Quarter1": "Resultado do 1º quarto",
                "x1x2Quarter2": "Resultado do 2º quarto",
                "x1x2Quarter3": "Resultado do 3º quarto",
                "x1x2Quarter4": "Resultado do 4º quarto"
            },
            "baseball": {
                "evenOddMatch": "Par ou ímpar",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 5 primeiras entradas",
                "handicapInning1": "Handicap 1ª entrada",
                "handicapInning2": "Handicap 2ª entrada",
                "handicapInning3": "Handicap 3ª entrada",
                "handicapInning4": "Handicap 4ª entrada",
                "handicapInning5": "Handicap 5ª entrada",
                "handicapInning6": "Handicap 6ª entrada",
                "handicapInning7": "Handicap 7ª entrada",
                "handicapInning8": "Handicap 8ª entrada",
                "handicapInning9": "Handicap 9ª entrada",
                "handicaps": "Handicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalHalf1": "Total 5 primeiras entradas",
                "totalInning1": "Total 1ª entrada",
                "totalInning2": "Total 2ª entrada",
                "totalInning3": "Total 3ª entrada",
                "totalInning4": "Total 4ª entrada",
                "totalInning5": "Total 5ª entrada",
                "totalInning6": "Total 6ª entrada",
                "totalInning7": "Total 7ª entrada",
                "totalInning8": "Total 8ª entrada",
                "totalInning9": "Total 9ª entrada",
                "x1_2": "Resultado da Partida",
                "x1x2Half1": "Resultado das 5 primeiras entradas",
                "x1x2Inning1": "Resultado da 1ª entrada",
                "x1x2Inning2": "Resultado da 2ª entrada",
                "x1x2Inning3": "Resultado da 3ª entrada",
                "x1x2Inning4": "Resultado da 4ª entrada",
                "x1x2Inning5": "Resultado da 5ª entrada",
                "x1x2Inning6": "Resultado da 6ª entrada",
                "x1x2Inning7": "Resultado da 7ª entrada",
                "x1x2Inning8": "Resultado da 8ª entrada",
                "x1x2Inning9": "Resultado da 9ª entrada"
            },
            "basketball": {
                "evenOddMatch": "Par ou ímpar",
                "evenOddMatchHalf1": "Par ou ímpar 1ª Metade",
                "evenOddMatchQuarter1": "Par ou ímpar 1º quarto",
                "evenOddMatchQuarter2": "Par ou ímpar 2º quarto",
                "evenOddMatchQuarter3": "Par ou ímpar 3º quarto",
                "evenOddMatchQuarter4": "Par ou ímpar 4º quarto",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1ª Metade",
                "handicapHalf2": "Handicap 2ª Metade",
                "handicapQuarter1": "Handicap 1º quarto",
                "handicapQuarter2": "Handicap 2º quarto",
                "handicapQuarter3": "Handicap 3º quarto",
                "handicapQuarter4": "Handicap 4º quarto",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1ª Metade",
                "individualTotalTeam1Half2": "Total {team1} 2ª Metade",
                "individualTotalTeam1Quarter1": "Total {team1} 1º quarto",
                "individualTotalTeam1Quarter2": "Total {team1} 2º quarto",
                "individualTotalTeam1Quarter3": "Total {team1} 3º quarto",
                "individualTotalTeam1Quarter4": "Total {team1} 4º quarto",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1ª Metade",
                "individualTotalTeam2Half2": "Total {team2} 2ª Metade",
                "individualTotalTeam2Quarter1": "Total {team2} 1º quarto",
                "individualTotalTeam2Quarter2": "Total {team2} 2º quarto",
                "individualTotalTeam2Quarter3": "Total {team2} 3º quarto",
                "individualTotalTeam2Quarter4": "Total {team2} 4º quarto",
                "total": "Total",
                "totalHalf1": "Total 1ª Metade",
                "totalHalf2": "Total 2ª Metade",
                "totalQuarter1": "Total 1º quarto",
                "totalQuarter2": "Total 2º quarto",
                "totalQuarter3": "Total 3º quarto",
                "totalQuarter4": "Total 4º quarto",
                "x1x2": "Resultado da Partida",
                "x1x2Half1": "Resultado da 1ª Metade",
                "x1x2Half2": "Resultado da 2ª Metade",
                "x1x2Quarter1": "Resultado do 1º quarto",
                "x1x2Quarter2": "Resultado do 2º quarto",
                "x1x2Quarter3": "Resultado do 3º quarto",
                "x1x2Quarter4": "Resultado do 4º quarto"
            },
            "football": {
                "12TotalUnder": "12 + Total Menos de ({p1})",
                "DrawTotalOver": "Empate + Total Mais de ({p1})",
                "DrawTotalUnder": "Empate + Total Menos de ({p1})",
                "atLeastOneWontScore12": "Pelo menos uma equipe não marca + 12",
                "atLeastOneWontScore1X": "Pelo menos uma equipe não marca + 1X",
                "atLeastOneWontScoreDraw": "Pelo menos uma equipe não marca + Empate",
                "atLeastOneWontScoreTeam1": "Pelo menos uma equipe não marca + {team1}",
                "atLeastOneWontScoreTeam2": "Pelo menos uma equipe não marca + {team2}",
                "atLeastOneWontScoreTotalOver": "Pelo menos uma  não marca + Total Mais de ({p1})",
                "atLeastOneWontScoreTotalUnder": "Pelo menos uma  não marca + Total Menos de ({p1})",
                "atLeastOneWontScoreX2": "Pelo menos uma equipe não marca + X2",
                "bothScoreTotalOver": "Ambos os times marcam + Total Mais de ({p1})",
                "bothScoreTotalUnder": "Ambos os times marcam + Total Menos de ({p1})",
                "dryWin": "To Win To Nil",
                "evenOddHalf1": "Par ou ímpar 1º tempo",
                "evenOddMatch": "Par ou ímpar",
                "firstGoalTeam": "Time a fazer gol 1",
                "goalBothHalves": "Gol em ambos os tempos",
                "goalHalf1": "Gol no 1º tempo",
                "goalHalf2": "Gol no 2º tempo",
                "goalIntervalTeam1x16x30": "Gol {team1} 15:01 - 30:00 min.",
                "goalIntervalTeam1x1x15": "Gol {team1} 00:01 - 15:00 min.",
                "goalIntervalTeam1x1x30": "Gol {team1} 00:01 - 30:00 min.",
                "goalIntervalTeam1x1x45": "Gol {team1} 00:01 - 45:00 min.",
                "goalIntervalTeam1x1x60": "Gol {team1} 00:01 - 60:00 min.",
                "goalIntervalTeam1x1x75": "Gol {team1} 00:01 - 75:00 min.",
                "goalIntervalTeam1x31x45": "Gol {team1} 30:01 - 45:00 min.",
                "goalIntervalTeam1x46x60": "Gol {team1} 45:01 - 60:00 min.",
                "goalIntervalTeam1x61x75": "Gol {team1} 60:01 - 75:00 min.",
                "goalIntervalTeam1x76x90": "Gol {team1} 75:01 - 90:00 min.",
                "goalIntervalTeam2x16x30": "Gol {team2} 15:01 - 30:00 min.",
                "goalIntervalTeam2x1x15": "Gol {team2} 00:01 - 15:00 min.",
                "goalIntervalTeam2x1x30": "Gol {team2} 00:01 - 30:00 min.",
                "goalIntervalTeam2x1x45": "Gol {team2} 00:01 - 45:00 min.",
                "goalIntervalTeam2x1x60": "Gol {team2} 00:01 - 60:00 min.",
                "goalIntervalTeam2x1x75": "Gol {team2} 00:01 - 75:00 min.",
                "goalIntervalTeam2x31x45": "Gol {team2} 30:01 - 45:00 min.",
                "goalIntervalTeam2x46x60": "Gol {team2} 45:01 - 60:00 min.",
                "goalIntervalTeam2x61x75": "Gol {team2} 60:01 - 75:00 min.",
                "goalIntervalTeam2x76x90": "Gol {team2} 75:01 - 90:00 min.",
                "goalIntervalx16x30": "Gol 15:01 - 30:00 min.",
                "goalIntervalx1x15": "Gol 00:01 - 15:00 min.",
                "goalIntervalx1x30": "Gol 00:01 - 30:00 min.",
                "goalIntervalx1x45": "Gol 00:01 - 45:00 min.",
                "goalIntervalx1x60": "Gol 00:01 - 60:00 min.",
                "goalIntervalx1x75": "Gol 00:01 - 75:00 min.",
                "goalIntervalx31x45": "Gol 30:01 - 45:00 min.",
                "goalIntervalx46x60": "Gol 45:01 - 60:00 min.",
                "goalIntervalx61x75": "Gol 60:01 - 75:00 min.",
                "goalIntervalx76x90": "Gol 75:01 - 90:00 min.",
                "goalTeam1BothHalves": "{team1} a fazer gol em ambos os tempos",
                "goalTeam1Half1": "{team1} a fazer gol em 1º tempo",
                "goalTeam1Half2": "{team1} a fazer gol em 2º tempo",
                "goalTeam2BothHalves": "{team2} a fazer gol em ambos os tempos",
                "goalTeam2Half1": "{team2} a fazer gol em 1º tempo",
                "goalTeam2Half2": "{team2} a fazer gol em 2º tempo",
                "goalWillScore1": "1º gol marcará",
                "goalWillScore10": "10º gol marcará",
                "goalWillScore2": "2º gol marcará",
                "goalWillScore3": "3º gol marcará",
                "goalWillScore4": "4º gol marcará",
                "goalWillScore5": "5º gol marcará",
                "goalWillScore6": "6º gol marcará",
                "goalWillScore7": "7º gol marcará",
                "goalWillScore8": "8º gol marcará",
                "goalWillScore9": "9º gol marcará",
                "halfMatch": "Primeiro tempo/Tempo integral",
                "halvesComparison": "Comparação de tempos",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1º tempo",
                "handicapHalf2": "Handicap 2º tempo",
                "handicapMatch": "Handicap",
                "individualTotalTeam1": "{team1} Total de Gols",
                "individualTotalTeam1Half1": "Total de Gols {team1} 1º tempo",
                "individualTotalTeam1Half2": "Total de Gols {team1} 2º tempo",
                "individualTotalTeam2": "{team2} Total de Gols",
                "individualTotalTeam2Half1": "Total de Gols {team2} 1º tempo",
                "individualTotalTeam2Half2": "Total de Gols {team2} 2º tempo",
                "lastGoalTeam": "Time que marca o último gol",
                "matchScore": "Resultado correto",
                "passage": "Avançar",
                "scoreBoth": "Ambos os times marcam",
                "scoreBoth12": "Ambos os times marcam + 12",
                "scoreBoth1X": "Ambos os times marcam + 1X",
                "scoreBothDraw": "Ambos os times marcam + Empate",
                "scoreBothHalf1": "Ambos os times marcam em 1º tempo",
                "scoreBothHalf2": "Ambos os times marcam em 2º tempo",
                "scoreBothTeam1": "Ambos os times marcam + {team1}",
                "scoreBothTeam2": "Ambos os times marcam + {team2}",
                "scoreBothX2": "Ambos os times marcam + X2",
                "scoreTeam1": "{team1} Marca",
                "scoreTeam2": "{team2} Marca",
                "strongWilledWin": "To Win From Behind",
                "team1Goal112": "{team1} marcará o primeiro gol + 12",
                "team1Goal11X": "{team1} marcará o primeiro gol + 1X",
                "team1Goal1Draw": "{team1} marcará o primeiro gol + Empate",
                "team1Goal1Team1": "{team1} marcará o primeiro gol + {team1}",
                "team1Goal1Team2": "{team1} marcará o primeiro gol + {team2}",
                "team1Goal1X2": "{team1} marcará o primeiro gol + X2",
                "team1TotalOver": "{team1} + Total Mais de ({p1})",
                "team1TotalUnder": "{team1} + Total Menos de ({p1})",
                "team2Goal112": "{team2} marcará o primeiro gol + 12",
                "team2Goal11X": "{team2} marcará o primeiro gol + 1X",
                "team2Goal1Draw": "{team2} marcará o primeiro gol + Empate",
                "team2Goal1Team1": "{team2} marcará o primeiro gol + {team1}",
                "team2Goal1Team2": "{team2} marcará o primeiro gol + {team2}",
                "team2Goal1X2": "A equipe 2 marcará o primeiro gol + X2",
                "team2TotalOver": "{team2} + Total Mais de ({p1})",
                "team2TotalUnder": "{team2} + Total Menos de ({p1})",
                "total": "Total",
                "totalBalls": "Total de gols",
                "totalHalf1": "1º tempo Total",
                "totalHalf2": "2º tempo Total",
                "winBothHalves": "Vencerá ambos os tempos",
                "x12TotalOver": "12 + Total Mais de ({p1})",
                "x1TotalOver": "1X + Total Mais de ({p1})",
                "x1TotalUnder": "1X + Total Menos de ({p1})",
                "x1x12x2": "Chance Dobrada",
                "x1x12x2Half1": "Chance Dobrada 1º tempo",
                "x1x12x2Half2": "Chance Dobrada 2º tempo",
                "x1x2": "Resultado da partida",
                "x1x2fHalf1": "Resultado do 1º tempo",
                "x1x2fHalf2": "Resultado do 2º tempo",
                "x2TotalOver": "X2 + Total Mais de ({p1})",
                "x2TotalUnder": "X2 + Total Menos de ({p1})"
            },
            "handball": {
                "evenOddHalf1": "Par ou ímpar 1º tempo",
                "evenOddMatch": "Par ou ímpar",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1º tempo",
                "handicapHalf2": "Handicap 2º tempo",
                "handicapMatch": "Handicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1º tempo",
                "individualTotalTeam1Half2": "Total {team1} 2º tempo",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1º tempo",
                "individualTotalTeam2Half2": "Total {team2} 2º tempo",
                "passage": "Avançar",
                "total": "Total",
                "totalHalf1": "Total 1º tempo",
                "totalHalf2": "Total 2º tempo",
                "x1x12x2": "Chance Dobrada",
                "x1x12x2Half1": "Chance Dobrada 1º tempo",
                "x1x12x2Half2": "Chance Dobrada 2º tempo",
                "x1x2": "Resultado da Partida",
                "x1x2fHalf1": "Resultado do 1º tempo",
                "x1x2fHalf2": "Resultado do 2º tempo"
            },
            "hockey": {
                "handicap": "Handicap",
                "handicapPeriod1": "Handicap 1º período",
                "handicapPeriod2": "Handicap 2º período",
                "handicapPeriod3": "Handicap 3º período",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalMatch": "Total",
                "totalPeriod1": "Total 1º período",
                "totalPeriod2": "Total 2º período",
                "totalPeriod3": "Total 3º período",
                "x1x12x2": "Chance Dobrada",
                "x1x12x2Period1": "Chance Dobrada 1º período",
                "x1x12x2Period2": "Chance Dobrada 2º período",
                "x1x12x2Period3": "Chance Dobrada 3º período",
                "x1x2": "Resultado da Partida",
                "x1x2Period1": "Resultado do 1º período",
                "x1x2Period2": "Resultado do 2º período",
                "x1x2Period3": "Resultado do 3º período"
            },
            "tableTennis": {
                "evenOddMatch": "Par ou ímpar",
                "evenOddSet1": "Par ou ímpar Set 1",
                "evenOddSet2": "Par ou ímpar Set 2",
                "evenOddSet3": "Par ou ímpar Set 3",
                "evenOddSet4": "Par ou ímpar Set 4",
                "evenOddSet5": "Par ou ímpar Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Resultado Final",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado da Partida",
                "x1_2Set1": "Resultado do Set 1",
                "x1_2Set2": "Resultado do Set 2",
                "x1_2Set3": "Resultado do Set 3",
                "x1_2Set4": "Resultado do Set 4",
                "x1_2Set5": "Resultado do Set 5"
            },
            "tennis": {
                "evenOddMatch": "Par ou ímpar",
                "evenOddSet1": "Par ou ímpar Set 1",
                "evenOddSet2": "Par ou ímpar Set 2",
                "evenOddSet3": "Par ou ímpar Set 3",
                "evenOddSet4": "Par ou ímpar Set 4",
                "evenOddSet5": "Par ou ímpar Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Resultado Final",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado da Partida",
                "x1_2Set1": "Resultado do Set 1",
                "x1_2Set2": "Resultado do Set 2",
                "x1_2Set3": "Resultado do Set 3",
                "x1_2Set4": "Resultado do Set 4",
                "x1_2Set5": "Resultado do Set 5"
            },
            "volleyball": {
                "evenOddMatch": "Par ou ímpar",
                "evenOddSet1": "Par ou ímpar Set 1",
                "evenOddSet2": "Par ou ímpar Set 2",
                "evenOddSet3": "Par ou ímpar Set 3",
                "evenOddSet4": "Par ou ímpar Set 4",
                "evenOddSet5": "Par ou ímpar Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Resultado Final (sets)",
                "scoreAfter2Games": "Resultado após 2 sets",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado da Partida",
                "x1_2Set1": "Resultado do Set 1",
                "x1_2Set2": "Resultado do Set 2",
                "x1_2Set3": "Resultado do Set 3",
                "x1_2Set4": "Resultado do Set 4",
                "x1_2Set5": "Resultado do Set 5"
            }
        },
        "tabs": {
            "americanFootball": {
                "all": "Tudo",
                "halves": "Meio tempo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "quarters": "Quartos",
                "totals": "Total"
            },
            "baseball": {
                "all": "Tudo",
                "halves": "Meio tempo",
                "handicap": "Handicap",
                "handicaps": "Handicap",
                "innings": "Entradas",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "basketball": {
                "all": "Tudo",
                "halves": "Meio tempo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "quarters": "Quartos",
                "totals": "Total"
            },
            "football": {
                "all": "Tudo",
                "goals": "Gols",
                "goalsPlusResult": "A marcar + Resultado",
                "goalsPlusTotal": "A marcar + Total",
                "halves": "Meio tempo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "resultPlusTotal": "Resultado + Total",
                "totals": "Total"
            },
            "handball": {
                "all": "Tudo",
                "half1": "1º período",
                "half2": "2º período",
                "handicap": "Handicap",
                "handicaps": "Handicap",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "hockey": {
                "all": "Tudo",
                "handicaps": "Handicap",
                "periods": "Periodos",
                "popular": "Popular",
                "totals": "Total"
            },
            "tableTennis": {
                "all": "Tudo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "tennis": {
                "all": "Tudo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "volleyball": {
                "all": "Tudo",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            }
        }
    }
};