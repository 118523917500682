import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getAmericanFootballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.popular",
        rows: [
            {
                id: EDetailRow.americanFootball_x1_2,
                title: "detailInfo.groups.americanFootball.x1_2",
                quotationIds: [
                    [7, 9]
                ]
            },
            {
                id: EDetailRow.americanFootball_total,
                title: "detailInfo.groups.americanFootball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicap,
                title: "detailInfo.groups.americanFootball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatch,
                title: "detailInfo.groups.americanFootball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1_2,
                title: "detailInfo.groups.americanFootball.x1_2",
                quotationIds: [
                    [7, 9]
                ]
            },
            {
                id: EDetailRow.americanFootball_total,
                title: "detailInfo.groups.americanFootball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicap,
                title: "detailInfo.groups.americanFootball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatch,
                title: "detailInfo.groups.americanFootball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter1,
                title: "detailInfo.groups.americanFootball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter1,
                title: "detailInfo.groups.americanFootball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter1,
                title: "detailInfo.groups.americanFootball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter1,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter1",
                quotationIds: [
                    [1015, 1014]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter2,
                title: "detailInfo.groups.americanFootball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter2,
                title: "detailInfo.groups.americanFootball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter2,
                title: "detailInfo.groups.americanFootball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter2,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter2",
                quotationIds: [
                    [1017, 1016]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter3,
                title: "detailInfo.groups.americanFootball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter3,
                title: "detailInfo.groups.americanFootball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter3,
                title: "detailInfo.groups.americanFootball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter3,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter3",
                quotationIds: [
                    [1019, 1018]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter4,
                title: "detailInfo.groups.americanFootball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter4,
                title: "detailInfo.groups.americanFootball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter4,
                title: "detailInfo.groups.americanFootball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter4,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1020]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Half1,
                title: "detailInfo.groups.americanFootball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf1,
                title: "detailInfo.groups.americanFootball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf1,
                title: "detailInfo.groups.americanFootball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchHalf1,
                title: "detailInfo.groups.americanFootball.evenOddMatchHalf1",
                quotationIds: [
                    [1013, 1012]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Half2,
                title: "detailInfo.groups.americanFootball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf2,
                title: "detailInfo.groups.americanFootball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf2,
                title: "detailInfo.groups.americanFootball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicap,
                title: "detailInfo.groups.americanFootball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter1,
                title: "detailInfo.groups.americanFootball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter2,
                title: "detailInfo.groups.americanFootball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter3,
                title: "detailInfo.groups.americanFootball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter4,
                title: "detailInfo.groups.americanFootball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf1,
                title: "detailInfo.groups.americanFootball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf2,
                title: "detailInfo.groups.americanFootball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_total,
                title: "detailInfo.groups.americanFootball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter1,
                title: "detailInfo.groups.americanFootball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter2,
                title: "detailInfo.groups.americanFootball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter3,
                title: "detailInfo.groups.americanFootball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter4,
                title: "detailInfo.groups.americanFootball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf1,
                title: "detailInfo.groups.americanFootball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf2,
                title: "detailInfo.groups.americanFootball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatch,
                title: "detailInfo.groups.americanFootball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter1,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter1",
                quotationIds: [
                    [1015, 1014]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter2,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter2",
                quotationIds: [
                    [1017, 1016]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter3,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter3",
                quotationIds: [
                    [1019, 1018]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter4,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1020]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchHalf1,
                title: "detailInfo.groups.americanFootball.evenOddMatchHalf1",
                quotationIds: [
                    [1013, 1012]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.americanFootball.quarters",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Quarter1,
                title: "detailInfo.groups.americanFootball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter1,
                title: "detailInfo.groups.americanFootball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter1,
                title: "detailInfo.groups.americanFootball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter1,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter1",
                quotationIds: [
                    [1015, 1014]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter2,
                title: "detailInfo.groups.americanFootball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter2,
                title: "detailInfo.groups.americanFootball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter2,
                title: "detailInfo.groups.americanFootball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter2,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter2",
                quotationIds: [
                    [1017, 1016]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter3,
                title: "detailInfo.groups.americanFootball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter3,
                title: "detailInfo.groups.americanFootball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter3,
                title: "detailInfo.groups.americanFootball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter3,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter3",
                quotationIds: [
                    [1019, 1018]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Quarter4,
                title: "detailInfo.groups.americanFootball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter4,
                title: "detailInfo.groups.americanFootball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter4,
                title: "detailInfo.groups.americanFootball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter4,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1020]
                ]
            }
        ]
    });
    result.push({
        id: 6,
        title: "detailInfo.tabs.americanFootball.halves",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Half1,
                title: "detailInfo.groups.americanFootball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf1,
                title: "detailInfo.groups.americanFootball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf1,
                title: "detailInfo.groups.americanFootball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchHalf1,
                title: "detailInfo.groups.americanFootball.evenOddMatchHalf1",
                quotationIds: [
                    [1013, 1012]
                ]
            },
            {
                id: EDetailRow.americanFootball_x1x2Half2,
                title: "detailInfo.groups.americanFootball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf2,
                title: "detailInfo.groups.americanFootball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf2,
                title: "detailInfo.groups.americanFootball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Quarter1,
                title: "detailInfo.groups.americanFootball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter1,
                title: "detailInfo.groups.americanFootball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter1,
                title: "detailInfo.groups.americanFootball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter1,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter1",
                quotationIds: [
                    [1015, 1014]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapQuarter1,
                title: "detailInfo.groups.americanFootball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalQuarter1,
                title: "detailInfo.groups.americanFootball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter1,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter1",
                quotationIds: [
                    [1015, 1014]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Quarter2,
                title: "detailInfo.groups.americanFootball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter2,
                title: "detailInfo.groups.americanFootball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter2,
                title: "detailInfo.groups.americanFootball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter2,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter2",
                quotationIds: [
                    [1017, 1016]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapQuarter2,
                title: "detailInfo.groups.americanFootball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalQuarter2,
                title: "detailInfo.groups.americanFootball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter2,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter2",
                quotationIds: [
                    [1017, 1016]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Quarter3,
                title: "detailInfo.groups.americanFootball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter3,
                title: "detailInfo.groups.americanFootball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter3,
                title: "detailInfo.groups.americanFootball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter3,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter3",
                quotationIds: [
                    [1019, 1018]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapQuarter3,
                title: "detailInfo.groups.americanFootball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalQuarter3,
                title: "detailInfo.groups.americanFootball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter3,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter3",
                quotationIds: [
                    [1019, 1018]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Quarter4,
                title: "detailInfo.groups.americanFootball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalQuarter4,
                title: "detailInfo.groups.americanFootball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapQuarter4,
                title: "detailInfo.groups.americanFootball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter4,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1020]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapQuarter4,
                title: "detailInfo.groups.americanFootball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalQuarter4,
                title: "detailInfo.groups.americanFootball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchQuarter4,
                title: "detailInfo.groups.americanFootball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1020]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballHalf1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Half1,
                title: "detailInfo.groups.americanFootball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf1,
                title: "detailInfo.groups.americanFootball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf1,
                title: "detailInfo.groups.americanFootball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchHalf1,
                title: "detailInfo.groups.americanFootball.evenOddMatchHalf1",
                quotationIds: [
                    [1013, 1012]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapHalf1,
                title: "detailInfo.groups.americanFootball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalHalf1,
                title: "detailInfo.groups.americanFootball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half1,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.americanFootball_evenOddMatchHalf1,
                title: "detailInfo.groups.americanFootball.evenOddMatchHalf1",
                quotationIds: [
                    [1013, 1012]
                ]
            }
        ]
    });
    return result;
}

export function getAmericanFootballHalf2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.americanFootball.all",
        rows: [
            {
                id: EDetailRow.americanFootball_x1x2Half2,
                title: "detailInfo.groups.americanFootball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.americanFootball_totalHalf2,
                title: "detailInfo.groups.americanFootball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.americanFootball_handicapHalf2,
                title: "detailInfo.groups.americanFootball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.americanFootball.handicaps",
        rows: [
            {
                id: EDetailRow.americanFootball_handicapHalf2,
                title: "detailInfo.groups.americanFootball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.americanFootball.totals",
        rows: [
            {
                id: EDetailRow.americanFootball_totalHalf2,
                title: "detailInfo.groups.americanFootball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam1Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.americanFootball_individualTotalTeam2Half2,
                title: "detailInfo.groups.americanFootball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    return result;
}
