import { useSettings } from "@sportaq/services/index";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

export function useBrand () {
    const settings = useSettings();
    const i18n = useI18n();

    onMounted(() => {
        refreshBrandType();
        eventBus.on(Events.APPLICATION_POINT_SETTINGS_CHANGED, refreshBrandType);
    });

    onUnmounted(() => {
        eventBus.off(Events.APPLICATION_POINT_SETTINGS_CHANGED, refreshBrandType);
    });

    function refreshBrandType () {
        setTitleByBrand();
    }

    function setTitleByBrand () {
        if (settings.pointSettings?.caption) {
            document.title = i18n.t(settings.pointSettings.caption);
        } else {
            switch (settings.brandType) {
                case ECompileBrandType.betgol: {
                    document.title = i18n.t("appTitle.brand.betgol");
                    break;
                }
                case ECompileBrandType.oncebet: {
                    document.title = i18n.t("appTitle.brand.oncebet");
                    break;
                }
                case ECompileBrandType.latinoApuesta: {
                    document.title = i18n.t("appTitle.brand.latino");
                    break;
                }
                case ECompileBrandType.ligabet: {
                    document.title = i18n.t("appTitle.brand.ligabet");
                    break;
                }
                case ECompileBrandType.columbiaApuesta: {
                    document.title = i18n.t("appTitle.brand.columbiaApuesta");
                    break;
                }
                case ECompileBrandType.certabet: {
                    document.title = i18n.t("appTitle.brand.certabet");
                    break;
                }
            }
        }
    }
}
