import { useRaceStakeListCommon } from "@sportaq/vue/views/web/history/race-stake-list-common";
import DateFilter from "@/views/stakes-view/date-filter/DateFilter.vue";
import { computed } from "vue";

export default {
    name: "RaceStakes",
    components: {
        DateFilter
    },
    setup () {
        const {
            HorseItemStatus,
            cardsPaged,
            cardsRef,
            requestPending,
            itemsPerPageRef,
            currentPage,
            dateChanged,
            itemsPerPageChanged,
            currentPageChanged
        } = useRaceStakeListCommon();
        const totalPages = computed(() => Math.ceil(cardsRef.value.length / itemsPerPageRef.value));
        return {
            HorseItemStatus,
            cardsPaged,
            cardsRef,
            requestPending,
            itemsPerPageRef,
            currentPage,
            totalPages,
            dateChanged,
            itemsPerPageChanged,
            currentPageChanged
        };
    }
};
