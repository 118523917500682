import { ActionContext } from "vuex";
import { RootState } from "@sportaq/vuex/index";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EventType from "@sportaq/common/enums/event-type";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { nextTick } from "vue";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";

export namespace ScoreboardVuexActions {
    export async function actionUpdateEvents (context: ActionContext<ScoreboardVuex.ScoreboardStoreState, RootState>, payload: { eventSupplier: EventSupplier; eventType: EventType; list: EventDiffsResponse[], nonConfirmedPositionIds: number[] }): Promise<void> {
        await nextTick(() => {
            context.commit(ScoreboardVuex.Mutations.SYNC_EVENTS_VIEW_WITH_STORE, payload);
        });
    }
}
