import { useWebRestService } from "@sportaq/services/web";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { User } from "@sportaq/model/common/user-accounts";
import { WithdrawalOrder } from "@sportaq/model/web/withdrawal-order";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useGoogleTagService } from "@sportaq/services/index";
import { useTextComponentCommon } from "@sportaq/vue/views/web/rules/components/text-component-common";

export function useEmailConfirmationCommon () {
    const route = useRoute();
    const router = useRouter();
    const i18n = useI18n();
    const restService = useWebRestService();
    const store = useWebStore();
    const user = ref<User>();
    const withdrawalOrder = ref<WithdrawalOrder>();
    const googleTagService = useGoogleTagService();
    const resetPasswordMessage = ref("");
    const { brandName } = useTextComponentCommon();
    if (route.query.code) {
        restService.emailConfirmation(route.query.code.toString()).then(confirmation => {
            if (confirmation.confirmationType === 1) {
                restService.requestUserInfo().then(resultUser => {
                    store.user = resultUser;
                    user.value = resultUser;
                    googleTagService.sendSuccessRegistrationEvent();
                    restService.updateCountryFilterFavourites(resultUser.countryId).then();
                });
            }
            if (confirmation.confirmationType === 3) {
                resetPasswordMessage.value = i18n.t("webdesktop.resetPasswordDialog.success");
            }
            if (confirmation.confirmationType === 2) {
                withdrawalOrder.value = confirmation.withdrawalOrder;
            }
        }).catch((error) => {
            showMessageBox(i18n.t(error.message), () => {
                router.push({ name: "home" }).then();
            });
        });
    }

    function onCloseClick () {
        router.push({ name: "home" }).then();
    }
    return {
        user,
        withdrawalOrder,
        resetPasswordMessage,
        brandName,
        isCodePresent: route.query.code !== undefined,
        onCloseClick
    };
}
