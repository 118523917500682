import {
    AcceptResult,
    EventViewFilter
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";

export enum EPeriodListResolverType {
    PLAIN, LEAGUE, FAVOURITES, FAVOURITES_WITH_LEAGUES
}

export interface PeriodListResolver<T extends PeriodSortInfo> {
    readonly type: EPeriodListResolverType;

    get batchRebuilder (): PeriodListRebuilder<T>;

    get batchUpdater (): PeriodListUpdater<T>;

    expandEvent (periods: T[], event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): T[];
}

export interface PeriodListRebuilder<T extends PeriodSortInfo> {
    begin (): void;

    addEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult;

    end (): EventListData<T>;
}

export interface PeriodListUpdater<T extends PeriodSortInfo> {
    begin (data: EventListData<T>): void;

    addOrUpdateEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult;

    deleteEvent (positionId: number): void;

    end (): EventListData<T>;
}

export interface EventListData<T extends PeriodSortInfo> {
    periods: T[];
    groupEvents: T[];
}
