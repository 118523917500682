import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { changeLanguage, getAvailableLocales, resolveLanguage } from "@sportaq/i18n/index";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { ThemeClass } from "@sportaq/common/enums/theme";
import { RootStore, useRootStore } from "@sportaq/vuex/index";
import { useClickOutside } from "@sportaq/vue/components/common/preferences-dialog/click-outside-common";

export function usePreferencesDialogCommon (settings: BaseSettingsService, reloadAppCallback: () => void) {
    const i18n = useI18n();
    const store = useRootStore();
    const themes = settings.pointSettings.themes;
    const quotationFormat = ref(settings.quotationFormat);
    const currentLang = ref(resolveLanguage());
    const view = ref(settings.bigButtons);

    function changeLang (lang: string) {
        changeLanguage(i18n, lang);
        currentLang.value = lang;
        reloadAppCallback();
    }

    const supportedLocales = computed(() => {
        return getAvailableLocales();
    });

    function onQuotationFormatChange (format: EQuotationFormat) {
        if (settings.quotationFormat !== format) {
            settings.quotationFormat = format;
            quotationFormat.value = format;
            eventBus.emit(Events.QUOTATION_FORMAT_CHANGED);
        }
    }

    function changeView (bigButtons: boolean) {
        if (settings.bigButtons !== bigButtons) {
            settings.bigButtons = bigButtons;
            view.value = bigButtons;
            eventBus.emit(Events.BIG_BUTTONS_CHANGED, bigButtons);
        }
    }

    function onThemeChange (theme: ThemeClass) {
        if (settings.currentTheme !== theme) {
            store.theme = theme;
            settings.currentTheme = theme;
        }
    }

    const {
        menuActive,
        setActive,
        onClickOutside
    } = useClickOutside();

    const theme = computed(() => store.theme);

    return {
        quotationFormat,
        currentLang,
        preferencesDialogActive: menuActive,
        supportedLocales,
        EQuotationFormat,
        view,
        theme,
        themes,
        changeView,
        changeLang,
        onQuotationFormatChange,
        setActive,
        onClickOutside,
        onThemeChange
    };
}

export function setTheme (settingsService: BaseSettingsService, store: RootStore) {
    if (settingsService.pointSettings.themes.length === 0) {
        return;
    }
    if (settingsService.currentTheme) {
        store.theme = settingsService.currentTheme;
    }
    if (!store.theme || store.theme === ThemeClass.Default || !settingsService.pointSettings.themes.find(t => t === store.theme)) {
        store.theme = settingsService.pointSettings.themes[settingsService.pointSettings.themes.length - 1];
    }
}
