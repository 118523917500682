import Axios, { AxiosRequestConfig } from "axios";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { MessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { EPaymentMethod } from "@sportaq/common/enums/bet-slip";
import { AST12PlaceBetMessageHandler } from "@sportaq/services/rest/messages/bet-slip/a_st_1_2_place-bet";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { PlaceBetResponse } from "@sportaq/model/types/place-bet-response";
import { QPi52RefreshBalanceMessageHandler } from "@sportaq/services/rest/messages/account/q_pi_5_2_refresh-balance";
import { appLogger } from "@sportaq/common/utils/logger";
import { resolveLanguage } from "@sportaq/i18n/index";
import { Card } from "@sportaq/model/cashier/card";
import { QSt100CardRequest } from "@sportaq/services/rest/messages/bet-slip/q_st_10_0_get_card";
import {
    QLE12PopularEventsMessageHandler
} from "@sportaq/services/rest/messages/events/q_le_1_2/q_le_1_2-popular-events-handler";
import { QRe11GetResults } from "@sportaq/services/rest/messages/events/q_re_1_1_get-results";
import { Sport } from "@sportaq/model/results/results";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";
import {
    AUs113SwitchAccountMessageHandler
} from "@sportaq/services/rest/messages/account/a_us_1_13_switch-account-message-handler";
import { ACs11GetGameFrameRequestHandler } from "@sportaq/services/rest/messages/games/a_cs_1_1-get-game-frame-request";
import {
    ACs12CloseGameFrameRequestHandler
} from "@sportaq/services/rest/messages/games/a_cs_1_2-close-game-frame-request";
import { StartGameFrameResponse } from "@sportaq/model/types/responses";
import { EVerifyField, User } from "@sportaq/model/common/user-accounts";
import { AUs111UserInfo } from "@sportaq/services/rest/messages/account/q_us_1_11_user-info";
import { AUr11StartRaceGameRequestHandler } from "@sportaq/services/rest/messages/games/race/a_ur_1_1_start-race-game";
import { QRs12GetRaceStakesHandler } from "@sportaq/services/rest/messages/games/race/q_rs_1_2_get-race-stakes";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { AUs17ChangeEmail } from "@sportaq/services/rest/messages/account/a_us_1_7_change-email";
import {
    ACn11EmailConfirmation,
    ConfirmationResult
} from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import {
    countriesOrder,
    ItemOrderSupplier,
    partitionsOrder,
    PopularPartition
} from "@sportaq/vuex/modules/betting/non-reactive-storage/dictionaries/item-order-supplier";
import {
    QLe111GetFavouritesPartitionsCountryFilterMessageHandler
} from "@sportaq/services/rest/messages/events/country-filter/q_le_1_11_get_favourites_partitions_country_filter";
import {
    QLe112GetCountriesOrderCountryFilter
} from "@sportaq/services/rest/messages/events/country-filter/q_le_1_12_get_countries_order_country_filter";
import {
    QSt106GetRaceByPayCodeHandler
} from "@sportaq/services/rest/messages/games/race/q_st_10_6_get-race-by-paycode";
import { AUs15ResetPassword } from "@sportaq/services/web/rest/messages/registration/a_us_1_5_reset_password";
import { AUs16ChangePassword } from "@sportaq/services/rest/messages/account/a_us_1_6_change-password";
import { QCD10CurrencyInfo } from "@sportaq/services/rest/messages/system/q_cd_1_0_currencyInfo";

export interface ErrorHandler {
    onLocalizedError (e: LocalizedError): void;

    onNotAuthorized (): void;
}

export interface NetworkErrorHandler {
    onNetworkError (code?: string): void;
}

export class SilentError extends Error {

}

export interface RestService {
    session?: string;
    errorHandler?: ErrorHandler;
    networkErrorHandler?: NetworkErrorHandler;

    changePassword (currentPassword: string, newPassword: string): Promise<boolean>;

    getPopularPrematchPositions (sportTypes: ESportType[], count: number): Promise<number[]>;

    placeBet (currency: number, simples: SimpleStakeDTO[], combined: CombinedStakeDTO[], acceptingWithChangedCoef: boolean, paymentMethod: EPaymentMethod): Promise<PlaceBetResponse>;

    getExtendedBalance (): Promise<ExtendedBalance>;

    findCardByPayCode (payCode: string, locale: string, pointInStation: boolean): Promise<Card>;

    getResults (startTime: Date, finishTime: Date): Promise<Sport[]>;

    switchToBonusAccount (): Promise<void>;

    startGameFrame (gameId: string, demo: boolean, isDesktop: boolean, withTransferMoney: boolean, hostUrl: string): Promise<StartGameFrameResponse>;

    closeGameFrame (gameId: string, withTransferMoney: boolean): Promise<void>;

    startRaceGame (isDesktop: boolean): Promise<StartGameFrameResponse>;

    changeEmail (email: string | undefined, phone: string | undefined): Promise<boolean>;

    emailConfirmation (code: string): Promise<ConfirmationResult>;

    updateCountryFilterFavourites (countryId: number | undefined): Promise<void>;

    getFavouritesPartitions (countryId: number | undefined): Promise<PopularPartition[]>;

    getRaceByPayCode (paycode: number): Promise<HorseWagerContainer>;

    resetPassword (data: string, confirmationMethod: EVerifyField): Promise<boolean>;

    getCurrencyInfo (): Promise<Map<number, number>>;
}

export interface E2eTestsRestServiceWrapper<T extends RestService> {
    realRestService: T;
}

export abstract class AbstractRestService<T extends BaseSettingsService> {
    session?: string;

    errorHandler?: ErrorHandler;
    networkErrorHandler?: NetworkErrorHandler;

    protected constructor (private readonly uuidProvider: UUIDProvider, protected readonly clockProvider: ClockProvider, protected readonly settingService: T) {
    }

    private createXmlRequest (): XmlRequest {
        const appCode = this.settingService.appCode;
        if (appCode == null) {
            throw new NotAuthorizedError("appcode is not initialized");
        }
        const locale = resolveLanguage();
        return new XmlRequest(appCode, this.clockProvider, this.session ? this.session : "", locale);
    }

    protected async executeRequest<T> (handler: MessageHandler<T>, request?: XmlRequest): Promise<T> {
        const xmlRequest = request || this.createXmlRequest();
        xmlRequest.mtlRequestType = handler.mtlRequestType;
        handler.buildRequest(xmlRequest);
        const startRequestMark = xmlRequest.mtlRequestType + ". Start";
        const executeRequestMark = xmlRequest.mtlRequestType + ". Execute";
        const parseResponseMark = xmlRequest.mtlRequestType + ". Parse";
        const executeMeasureName = "measure request executing";
        const parseMeasureName = "measure request parsing";
        const allMeasureName = "measure request all";
        if (xmlRequest.needDebugLog) {
            performance.clearMarks();
            performance.clearMeasures();
            performance.mark(startRequestMark);
        }
        const response = await this.sendRequest(xmlRequest);
        if (xmlRequest.needDebugLog) {
            performance.mark(executeRequestMark);
        }
        const result = handler.parseResponse(response);
        if (xmlRequest.needDebugLog) {
            result.then(() => {
                performance.mark(parseResponseMark);
                performance.measure(executeMeasureName, startRequestMark, executeRequestMark);
                performance.measure(parseMeasureName, executeRequestMark, parseResponseMark);
                performance.measure(allMeasureName, startRequestMark, parseResponseMark);
                appLogger.logger.info(`Request ${xmlRequest.mtlRequestType}:\t
                executing:${performance.getEntriesByName(executeMeasureName)[0].duration} ms\t
                parsing:${performance.getEntriesByName(parseMeasureName)[0].duration} ms\t
                all:${performance.getEntriesByName(allMeasureName)[0].duration} ms`
                );
            });
        }
        result.catch((e) => {
            if (e instanceof LocalizedError) {
                if (this.errorHandler) {
                    this.errorHandler.onLocalizedError(e);
                }
            }
            if (e instanceof NotAuthorizedError) {
                if (this.errorHandler) {
                    this.errorHandler.onNotAuthorized();
                }
            }
        });
        return result;
    }

    protected async sendRequest (request: XmlRequest): Promise<string> {
        const url = (this.settingService.useSSL ? "https://" : "http://") + this.settingService.restServerAddress;
        const config: AxiosRequestConfig = {
            baseURL: url,
            headers: { "Content-Type": "application/xml" },
            transformResponse: (res) => {
                return res;
            },
            responseType: "text"
        };
        if (request.needDebugLog) {
            appLogger.logger.info("SendRequest:", request.toString());
        }
        return Axios.post("/5.0/do.php?NOTGZ=1", request.toString(), config)
            .then(value => {
                if (request.needDebugLog) {
                    appLogger.logger.info("Response:", value.data);
                }
                return value.data;
            })
            .catch(reason => {
                if (this.networkErrorHandler) {
                    this.networkErrorHandler.onNetworkError(reason.code);
                }
                throw new Error(`MTL server have returned the error for request [${request.mtlRequestType}]: ${reason.message} (code: ${reason.code}, status: ${reason.status}). Server address: ${url}`);
            });
    }

    async changePassword (currentPassword: string, newPassword: string): Promise<boolean> {
        const handler = new AUs16ChangePassword(currentPassword, newPassword);
        this.session = await this.executeRequest(handler);
        return true;
    }

    async emailConfirmation (code: string): Promise<ConfirmationResult> {
        const handler = new ACn11EmailConfirmation(code);
        const result = await this.executeRequest(handler);
        if (result.confirmationType === 1 && result.sessionCode) {
            this.session = result.sessionCode;
        }
        return result;
    }

    async changeEmail (email: string | undefined, phone: string | undefined): Promise<boolean> {
        const handler = new AUs17ChangeEmail(email, phone);
        return this.executeRequest(handler);
    }

    async placeBet (currency: number, simples: SimpleStakeDTO[], combined: CombinedStakeDTO[], acceptingWithChangedCoef: boolean, paymentMethod: EPaymentMethod): Promise<PlaceBetResponse> {
        const handler = new AST12PlaceBetMessageHandler(this.uuidProvider, currency, simples, combined, acceptingWithChangedCoef, paymentMethod);
        return this.executeRequest(handler);
    }

    async getPopularPrematchPositions (sportTypes: ESportType[], count: number): Promise<number[]> {
        const handler = new QLE12PopularEventsMessageHandler(sportTypes, count);
        return this.executeRequest(handler);
    }

    async getExtendedBalance (): Promise<ExtendedBalance> {
        const handler = new QPi52RefreshBalanceMessageHandler();
        return this.executeRequest(handler);
    }

    async findCardByPayCode (payCode: string, locale: string, pointInStation: boolean): Promise<Card> {
        const handler = new QSt100CardRequest(payCode, locale,
            this.settingService.pointSettings.draftCoef, pointInStation);
        return this.executeRequest(handler)
            .then(value => {
                if (value && value.length > 0) {
                    return value[0];
                } else {
                    throw new LocalizedError("betting.couponVerification.cardNotFound");
                }
            });
    }

    async getResults (startTime: Date, finishTime: Date): Promise<Sport[]> {
        const handler = new QRe11GetResults(startTime, finishTime);
        return this.executeRequest(handler);
    }

    async startGameFrame (gameId: string, demo: boolean, isDesktop: boolean, withTransferMoney: boolean, hostUrl: string): Promise<StartGameFrameResponse> {
        const handler = new ACs11GetGameFrameRequestHandler(gameId, demo, isDesktop, withTransferMoney, hostUrl);
        return this.executeRequest(handler);
    }

    async closeGameFrame (gameId: string, withTransferMoney: boolean): Promise<void> {
        const handler = new ACs12CloseGameFrameRequestHandler(gameId, withTransferMoney);
        return this.executeRequest(handler);
    }

    async switchToBonusAccount (): Promise<void> {
        const handler = new AUs113SwitchAccountMessageHandler();
        return this.executeRequest(handler);
    }

    async requestUserInfo (): Promise<User> {
        const handler = new AUs111UserInfo();
        return this.executeRequest(handler);
    }

    async getRaceStakes (startDate: Date, endDate: Date | undefined, lastContainerId: number): Promise<HorseWagerContainer[]> {
        const handler = new QRs12GetRaceStakesHandler(true, startDate, endDate, lastContainerId);
        return this.executeRequest(handler);
    }

    async startRaceGame (isDesktop: boolean): Promise<StartGameFrameResponse> {
        const handler = new AUr11StartRaceGameRequestHandler(isDesktop);
        return this.executeRequest(handler);
    }

    async updateCountryFilterFavourites (countryId: number | undefined): Promise<void> {
        if (countryId) {
            if (partitionsOrder.userCountryId !== countryId) {
                const items = await this.getFavouritesPartitions(countryId);
                partitionsOrder.set(countryId, new ItemOrderSupplier(items));
            }
            if (countriesOrder.userCountryId !== countryId) {
                const handler = new QLe112GetCountriesOrderCountryFilter(countryId);
                const map = await this.executeRequest(handler);
                countriesOrder.set(countryId, map);
            }
        }
    }

    async getFavouritesPartitions (countryId: number | undefined): Promise<PopularPartition[]> {
        if (countryId) {
            const handler = new QLe111GetFavouritesPartitionsCountryFilterMessageHandler(countryId);
            return await this.executeRequest(handler);
        }
        return Promise.resolve([]);
    }

    async getRaceByPayCode (paycode: number): Promise<HorseWagerContainer> {
        const handler = new QSt106GetRaceByPayCodeHandler(paycode);
        return this.executeRequest(handler);
    }

    async resetPassword (data: string, confirmationMethod: EVerifyField): Promise<boolean> {
        const handler = new AUs15ResetPassword(data, confirmationMethod);
        return this.executeRequest(handler);
    }

    async getCurrencyInfo (): Promise<Map<number, number>> {
        const handler = new QCD10CurrencyInfo();
        return this.executeRequest(handler);
    }
}
