import { ROUTES } from "web_desktop/src/router/routes-consts";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useBannersStore } from "@sportaq/vuex/modules/banners/banners-module";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { Banner } from "@sportaq/model/common/banner";

export function useHomeViewCommon () {
    const store = useBannersStore();
    const banners = computed(() => {
        const path = APP_TYPE.value === EApplicationType.webMobile ? "https://m.tubetgol.com" : "https://tubetgol.com";
        return store.homeScreenBanners.map(b => {
            const result: Banner = {
                id: b.id,
                image: path + b.image,
                name: b.name,
                onclickUrl: b.onclickUrl,
                orderNum: b.orderNum,
                screenId: b.screenId
            };
            return result;
        });
    });
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
        if (route.hash.startsWith("#confirmation")) {
            const code = new URLSearchParams(route.hash.substring(route.hash.indexOf("?"))).get("code");
            router.push(ROUTES.EMAILCONFIRM.path + "?code=" + code);
        }
    });
    return {
        banners
    };
}
