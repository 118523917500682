import { PropType, SetupContext } from "vue";
import { IdItem } from "@sportaq/common/types/types";
import { SLIDER_SELECT_EVENT, useSlider } from "@sportaq/vue/components/common/item-slider/common/common-sliders";

interface Props {
    cssClass: string;
    autoSelect: boolean;
    selectedIndex?: number;
    items: IdItem[];
}

export default {
    props: {
        cssClass: { required: true, type: String },
        autoSelect: { required: false, type: Boolean, default: false },
        selectedIndex: { required: false, type: Number },
        items: { required: true, type: Object as PropType<IdItem[]> },
        testSelector: { required: false, type: String, default: "" }
    },
    emits: [SLIDER_SELECT_EVENT],
    setup (props: Props, context: SetupContext) {
        const {
            sliderItems,
            selectedId,

            itemSelected,
            scrollLeft,
            scrollRight
        } = useSlider(props, context);
        return {
            sliderItems,
            selectedId,

            itemSelected,
            scrollLeft,
            scrollRight
        };
    }
};
