import { PaymentSystemData } from "@sportaq/common/enums/payment-systems";

export interface UserAccounts {
    readonly accounts: UserAccount[];

    getPrimaryAccount (): UserAccount | undefined;
}

export class UserAccountsImpl implements UserAccounts {
    constructor (
        readonly accounts: UserAccount[]
    ) {
    }

    getPrimaryAccount (): UserAccount | undefined {
        for (const account of this.accounts) {
            if (account.accountMark === "USER_ACCOUNT_PRIMARY") {
                return account;
            }
        }
        return undefined;
    }
}

export interface UserSession {
    id: number;
    registerTime: Date;
    ipAddress: string;
}

export enum EUserRole {
    PLAYER = 13,
    EXPRESS = 14
}

export enum EVerifyField {
    phone= "phone", email = "email", firstname = "firstname", lastname = "lastname",
    identificationCode = "identificationCode", birthday = "birthday"
}

export interface User {
    userId: number;
    userNumber: string;
    firstName: string;
    lastName: string;
    middleName: string;
    birthday: string;
    address: string;
    phone: string;
    email: string;
    partnerId: number;
    login: string;
    IdentificationCode: string;
    registrationTime: Date | undefined;
    city: string | undefined;
    countryId: number;
    userAccounts: UserAccounts;
    sessions?: UserSession[];
    userRoles: EUserRole[];
    paymentSystems: PaymentSystemData[],
    verifiedFields: Set<EVerifyField>,
    verified:boolean
}

export function hasRole (user: User | undefined, role: EUserRole) {
    if (!user) {
        return false;
    }
    return user.userRoles.find(r => r === role) !== undefined;
}

export interface UserAccount {
    accountId: number;
    accountNumber: string;
    sumAccount: number;
    sumCaptive: number;
    sumStake?: number;
    currencyId: number;
    currencyMark: string;
    currencyValue?: number;
    isUsed: boolean;
    isBlocked: boolean;
    isBonus: boolean;
    accountMark?: string;
}
