/* eslint-disable no-template-curly-in-string */
import { resolveLanguage } from "@sportaq/i18n/index";

export interface QuotationLabel {
    template?: boolean;
    participantNumber?: number;
    label?: string;
}

class QuotationLabelHolder {
    private readonly indexById = new Map<number, QuotationLabel>();
    private locale = "_Undefined_";

    getById (quotationId: number): QuotationLabel | undefined {
        this.refreshByLocale();
        return this.indexById.get(quotationId);
    }

    private refreshByLocale () {
        const newLocale = resolveLanguage();
        if (newLocale !== this.locale) {
            this.rebuild(newLocale);
        }
    }

    private rebuild (locale: string) {
        this.indexById.clear();
        for (const item of rawQuotationLabelArray) {
            const label: QuotationLabel = {
                template: item.label.template,
                participantNumber: item.label.participantNumber,
                label: this.getName(item, locale)
            };
            this.indexById.set(item.id, label);
        }
        this.locale = locale;
    }

    private getName (quotationLabel: RawQuotationLabel, locale: string): string {
        switch (locale) {
            case "ru":
                return quotationLabel.label.names?.ru ?? "";
            case "en":
                return quotationLabel.label.names?.en ?? "";
            case "es":
                return quotationLabel.label.names?.es ?? "";
            case "pt":
                return quotationLabel.label.names?.pt ?? "";
            default: {
                throw new Error("Unsupported language: " + locale);
            }
        }
    }
}

export const quotationTypes = new QuotationLabelHolder();

interface RawQuotationLabel {
    id: number;
    label: {
        template?: boolean,
        participantNumber?: number,
        names?: {
            en: string;
            ru: string;
            es: string;
            pt: string;
        };
    };
}

const rawQuotationLabelArray: RawQuotationLabel[] = [
    {
        id: 3,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 4,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 6,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 5,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 8,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 7,
        label: {
            participantNumber: 1
        }
    }, {
        id: 9,
        label: {
            participantNumber: 2
        }
    }, {
        id: 11,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 10,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 12,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 15,
        label: {
            names: {
                en: "0:2",
                ru: "0:2",
                es: "0:2",
                pt: "0:2"
            }
        }
    }, {
        id: 16,
        label: {
            names: {
                en: "1:2",
                ru: "1:2",
                es: "1:2",
                pt: "1:2"
            }
        }
    }, {
        id: 13,
        label: {
            names: {
                en: "2:0",
                ru: "2:0",
                es: "2:0",
                pt: "2:0"
            }
        }
    }, {
        id: 14,
        label: {
            names: {
                en: "2:1",
                ru: "2:1",
                es: "2:1",
                pt: "2:1"
            }
        }
    }, {
        id: 20,
        label: {
            names: {
                en: "0:3",
                ru: "0:3",
                es: "0:3",
                pt: "0:3"
            }
        }
    }, {
        id: 21,
        label: {
            names: {
                en: "1:3",
                ru: "1:3",
                es: "1:3",
                pt: "1:3"
            }
        }
    }, {
        id: 22,
        label: {
            names: {
                en: "2:3",
                ru: "2:3",
                es: "2:3",
                pt: "2:3"
            }
        }
    }, {
        id: 23,
        label: {
            participantNumber: 1
        }
    }, {
        id: 24,
        label: {
            participantNumber: 2
        }
    }, {
        id: 17,
        label: {
            names: {
                en: "3:0",
                ru: "3:0",
                es: "3:0",
                pt: "3:0"
            }
        }
    }, {
        id: 18,
        label: {
            names: {
                en: "3:1",
                ru: "3:1",
                es: "3:1",
                pt: "3:1"
            }
        }
    }, {
        id: 19,
        label: {
            names: {
                en: "3:2",
                ru: "3:2",
                es: "3:2",
                pt: "3:2"
            }
        }
    }, {
        id: 109,
        label: {
            names: {
                en: "1H=2H",
                ru: "1т=2т",
                es: "1T=2T",
                pt: "1T=2T"
            }
        }
    }, {
        id: 25,
        label: {
            names: {
                en: "1H>2H",
                ru: "1т>2т",
                es: "1T>2T",
                pt: "1T>2T"
            }
        }
    }, {
        id: 26,
        label: {
            names: {
                en: "2H>1H",
                ru: "2т>1т",
                es: "2T>1T",
                pt: "2T>1T"
            }
        }
    }, {
        id: 37,
        label: {
            names: {
                en: "0:0",
                ru: "0:0",
                es: "0:0",
                pt: "0:0"
            }
        }
    }, {
        id: 43,
        label: {
            names: {
                en: "0:1",
                ru: "0:1",
                es: "0:1",
                pt: "0:1"
            }
        }
    }, {
        id: 1287,
        label: {
            names: {
                en: "0:10",
                ru: "0:10",
                es: "0:10",
                pt: "0:10"
            }
        }
    }, {
        id: 44,
        label: {
            names: {
                en: "0:2",
                ru: "0:2",
                es: "0:2",
                pt: "0:2"
            }
        }
    }, {
        id: 45,
        label: {
            names: {
                en: "0:3",
                ru: "0:3",
                es: "0:3",
                pt: "0:3"
            }
        }
    }, {
        id: 1248,
        label: {
            names: {
                en: "0:4",
                ru: "0:4",
                es: "0:4",
                pt: "0:4"
            }
        }
    }, {
        id: 1249,
        label: {
            names: {
                en: "0:5",
                ru: "0:5",
                es: "0:5",
                pt: "0:5"
            }
        }
    }, {
        id: 1252,
        label: {
            names: {
                en: "0:6",
                ru: "0:6",
                es: "0:6",
                pt: "0:6"
            }
        }
    }, {
        id: 1259,
        label: {
            names: {
                en: "0:7",
                ru: "0:7",
                es: "0:7",
                pt: "0:7"
            }
        }
    }, {
        id: 1267,
        label: {
            names: {
                en: "0:8",
                ru: "0:8",
                es: "0:8",
                pt: "0:8"
            }
        }
    }, {
        id: 1276,
        label: {
            names: {
                en: "0:9",
                ru: "0:9",
                es: "0:9",
                pt: "0:9"
            }
        }
    }, {
        id: 1428,
        label: {
            names: {
                en: "10:0",
                ru: "10:0",
                es: "10:0",
                pt: "10:0"
            }
        }
    }, {
        id: 1429,
        label: {
            names: {
                en: "10:1",
                ru: "10:1",
                es: "10:1",
                pt: "10:1"
            }
        }
    }, {
        id: 1297,
        label: {
            names: {
                en: "10:10",
                ru: "10:10",
                es: "10:10",
                pt: "10:10"
            }
        }
    }, {
        id: 1430,
        label: {
            names: {
                en: "10:2",
                ru: "10:2",
                es: "10:2",
                pt: "10:2"
            }
        }
    }, {
        id: 1431,
        label: {
            names: {
                en: "10:3",
                ru: "10:3",
                es: "10:3",
                pt: "10:3"
            }
        }
    }, {
        id: 1432,
        label: {
            names: {
                en: "10:4",
                ru: "10:4",
                es: "10:4",
                pt: "10:4"
            }
        }
    }, {
        id: 1433,
        label: {
            names: {
                en: "10:5",
                ru: "10:5",
                es: "10:5",
                pt: "10:5"
            }
        }
    }, {
        id: 1434,
        label: {
            names: {
                en: "10:6",
                ru: "10:6",
                es: "10:6",
                pt: "10:6"
            }
        }
    }, {
        id: 1435,
        label: {
            names: {
                en: "10:7",
                ru: "10:7",
                es: "10:7",
                pt: "10:7"
            }
        }
    }, {
        id: 1436,
        label: {
            names: {
                en: "10:8",
                ru: "10:8",
                es: "10:8",
                pt: "10:8"
            }
        }
    }, {
        id: 1437,
        label: {
            names: {
                en: "10:9",
                ru: "10:9",
                es: "10:9",
                pt: "10:9"
            }
        }
    }, {
        id: 40,
        label: {
            names: {
                en: "1:0",
                ru: "1:0",
                es: "1:0",
                pt: "1:0"
            }
        }
    }, {
        id: 38,
        label: {
            names: {
                en: "1:1",
                ru: "1:1",
                es: "1:1",
                pt: "1:1"
            }
        }
    }, {
        id: 1288,
        label: {
            names: {
                en: "1:10",
                ru: "1:10",
                es: "1:10",
                pt: "1:10"
            }
        }
    }, {
        id: 49,
        label: {
            names: {
                en: "1:2",
                ru: "1:2",
                es: "1:2",
                pt: "1:2"
            }
        }
    }, {
        id: 50,
        label: {
            names: {
                en: "1:3",
                ru: "1:3",
                es: "1:3",
                pt: "1:3"
            }
        }
    }, {
        id: 1236,
        label: {
            names: {
                en: "1:4",
                ru: "1:4",
                es: "1:4",
                pt: "1:4"
            }
        }
    }, {
        id: 1244,
        label: {
            names: {
                en: "1:5",
                ru: "1:5",
                es: "1:5",
                pt: "1:5"
            }
        }
    }, {
        id: 1253,
        label: {
            names: {
                en: "1:6",
                ru: "1:6",
                es: "1:6",
                pt: "1:6"
            }
        }
    }, {
        id: 1260,
        label: {
            names: {
                en: "1:7",
                ru: "1:7",
                es: "1:7",
                pt: "1:7"
            }
        }
    }, {
        id: 1268,
        label: {
            names: {
                en: "1:8",
                ru: "1:8",
                es: "1:8",
                pt: "1:8"
            }
        }
    }, {
        id: 1278,
        label: {
            names: {
                en: "1:9",
                ru: "1:9",
                es: "1:9",
                pt: "1:9"
            }
        }
    }, {
        id: 41,
        label: {
            names: {
                en: "2:0",
                ru: "2:0",
                es: "2:0",
                pt: "2:0"
            }
        }
    }, {
        id: 46,
        label: {
            names: {
                en: "2:1",
                ru: "2:1",
                es: "2:1",
                pt: "2:1"
            }
        }
    }, {
        id: 1289,
        label: {
            names: {
                en: "2:10",
                ru: "2:10",
                es: "2:10",
                pt: "2:10"
            }
        }
    }, {
        id: 39,
        label: {
            names: {
                en: "2:2",
                ru: "2:2",
                es: "2:2",
                pt: "2:2"
            }
        }
    }, {
        id: 51,
        label: {
            names: {
                en: "2:3",
                ru: "2:3",
                es: "2:3",
                pt: "2:3"
            }
        }
    }, {
        id: 1237,
        label: {
            names: {
                en: "2:4",
                ru: "2:4",
                es: "2:4",
                pt: "2:4"
            }
        }
    }, {
        id: 1245,
        label: {
            names: {
                en: "2:5",
                ru: "2:5",
                es: "2:5",
                pt: "2:5"
            }
        }
    }, {
        id: 1254,
        label: {
            names: {
                en: "2:6",
                ru: "2:6",
                es: "2:6",
                pt: "2:6"
            }
        }
    }, {
        id: 1261,
        label: {
            names: {
                en: "2:7",
                ru: "2:7",
                es: "2:7",
                pt: "2:7"
            }
        }
    }, {
        id: 1269,
        label: {
            names: {
                en: "2:8",
                ru: "2:8",
                es: "2:8",
                pt: "2:8"
            }
        }
    }, {
        id: 1279,
        label: {
            names: {
                en: "2:9",
                ru: "2:9",
                es: "2:9",
                pt: "2:9"
            }
        }
    }, {
        id: 42,
        label: {
            names: {
                en: "3:0",
                ru: "3:0",
                es: "3:0",
                pt: "3:0"
            }
        }
    }, {
        id: 47,
        label: {
            names: {
                en: "3:1",
                ru: "3:1",
                es: "3:1",
                pt: "3:1"
            }
        }
    }, {
        id: 1290,
        label: {
            names: {
                en: "3:10",
                ru: "3:10",
                es: "3:10",
                pt: "3:10"
            }
        }
    }, {
        id: 48,
        label: {
            names: {
                en: "3:2",
                ru: "3:2",
                es: "3:2",
                pt: "3:2"
            }
        }
    }, {
        id: 1231,
        label: {
            names: {
                en: "3:3",
                ru: "3:3",
                es: "3:3",
                pt: "3:3"
            }
        }
    }, {
        id: 1238,
        label: {
            names: {
                en: "3:4",
                ru: "3:4",
                es: "3:4",
                pt: "3:4"
            }
        }
    }, {
        id: 1246,
        label: {
            names: {
                en: "3:5",
                ru: "3:5",
                es: "3:5",
                pt: "3:5"
            }
        }
    }, {
        id: 1255,
        label: {
            names: {
                en: "3:6",
                ru: "3:6",
                es: "3:6",
                pt: "3:6"
            }
        }
    }, {
        id: 1262,
        label: {
            names: {
                en: "3:7",
                ru: "3:7",
                es: "3:7",
                pt: "3:7"
            }
        }
    }, {
        id: 1270,
        label: {
            names: {
                en: "3:8",
                ru: "3:8",
                es: "3:8",
                pt: "3:8"
            }
        }
    }, {
        id: 1280,
        label: {
            names: {
                en: "3:9",
                ru: "3:9",
                es: "3:9",
                pt: "3:9"
            }
        }
    }, {
        id: 1250,
        label: {
            names: {
                en: "4:0",
                ru: "4:0",
                es: "4:0",
                pt: "4:0"
            }
        }
    }, {
        id: 1232,
        label: {
            names: {
                en: "4:1",
                ru: "4:1",
                es: "4:1",
                pt: "4:1"
            }
        }
    }, {
        id: 1291,
        label: {
            names: {
                en: "4:10",
                ru: "4:10",
                es: "4:10",
                pt: "4:10"
            }
        }
    }, {
        id: 1233,
        label: {
            names: {
                en: "4:2",
                ru: "4:2",
                es: "4:2",
                pt: "4:2"
            }
        }
    }, {
        id: 1234,
        label: {
            names: {
                en: "4:3",
                ru: "4:3",
                es: "4:3",
                pt: "4:3"
            }
        }
    }, {
        id: 1235,
        label: {
            names: {
                en: "4:4",
                ru: "4:4",
                es: "4:4",
                pt: "4:4"
            }
        }
    }, {
        id: 1247,
        label: {
            names: {
                en: "4:5",
                ru: "4:5",
                es: "4:5",
                pt: "4:5"
            }
        }
    }, {
        id: 1256,
        label: {
            names: {
                en: "4:6",
                ru: "4:6",
                es: "4:6",
                pt: "4:6"
            }
        }
    }, {
        id: 1263,
        label: {
            names: {
                en: "4:7",
                ru: "4:7",
                es: "4:7",
                pt: "4:7"
            }
        }
    }, {
        id: 1271,
        label: {
            names: {
                en: "4:8",
                ru: "4:8",
                es: "4:8",
                pt: "4:8"
            }
        }
    }, {
        id: 1281,
        label: {
            names: {
                en: "4:9",
                ru: "4:9",
                es: "4:9",
                pt: "4:9"
            }
        }
    }, {
        id: 1251,
        label: {
            names: {
                en: "5:0",
                ru: "5:0",
                es: "5:0",
                pt: "5:0"
            }
        }
    }, {
        id: 1239,
        label: {
            names: {
                en: "5:1",
                ru: "5:1",
                es: "5:1",
                pt: "5:1"
            }
        }
    }, {
        id: 1292,
        label: {
            names: {
                en: "5:10",
                ru: "5:10",
                es: "5:10",
                pt: "5:10"
            }
        }
    }, {
        id: 1240,
        label: {
            names: {
                en: "5:2",
                ru: "5:2",
                es: "5:2",
                pt: "5:2"
            }
        }
    }, {
        id: 1241,
        label: {
            names: {
                en: "5:3",
                ru: "5:3",
                es: "5:3",
                pt: "5:3"
            }
        }
    }, {
        id: 1242,
        label: {
            names: {
                en: "5:4",
                ru: "5:4",
                es: "5:4",
                pt: "5:4"
            }
        }
    }, {
        id: 1243,
        label: {
            names: {
                en: "5:5",
                ru: "5:5",
                es: "5:5",
                pt: "5:5"
            }
        }
    }, {
        id: 1257,
        label: {
            names: {
                en: "5:6",
                ru: "5:6",
                es: "5:6",
                pt: "5:6"
            }
        }
    }, {
        id: 1264,
        label: {
            names: {
                en: "5:7",
                ru: "5:7",
                es: "5:7",
                pt: "5:7"
            }
        }
    }, {
        id: 1272,
        label: {
            names: {
                en: "5:8",
                ru: "5:8",
                es: "5:8",
                pt: "5:8"
            }
        }
    }, {
        id: 1282,
        label: {
            names: {
                en: "5:9",
                ru: "5:9",
                es: "5:9",
                pt: "5:9"
            }
        }
    }, {
        id: 1298,
        label: {
            names: {
                en: "6:0",
                ru: "6:0",
                es: "6:0",
                pt: "6:0"
            }
        }
    }, {
        id: 1299,
        label: {
            names: {
                en: "6:1",
                ru: "6:1",
                es: "6:1",
                pt: "6:1"
            }
        }
    }, {
        id: 1293,
        label: {
            names: {
                en: "6:10",
                ru: "6:10",
                es: "6:10",
                pt: "6:10"
            }
        }
    }, {
        id: 1400,
        label: {
            names: {
                en: "6:2",
                ru: "6:2",
                es: "6:2",
                pt: "6:2"
            }
        }
    }, {
        id: 1401,
        label: {
            names: {
                en: "6:3",
                ru: "6:3",
                es: "6:3",
                pt: "6:3"
            }
        }
    }, {
        id: 1402,
        label: {
            names: {
                en: "6:4",
                ru: "6:4",
                es: "6:4",
                pt: "6:4"
            }
        }
    }, {
        id: 1403,
        label: {
            names: {
                en: "6:5",
                ru: "6:5",
                es: "6:5",
                pt: "6:5"
            }
        }
    }, {
        id: 1258,
        label: {
            names: {
                en: "6:6",
                ru: "6:6",
                es: "6:6",
                pt: "6:6"
            }
        }
    }, {
        id: 1265,
        label: {
            names: {
                en: "6:7",
                ru: "6:7",
                es: "6:7",
                pt: "6:7"
            }
        }
    }, {
        id: 1273,
        label: {
            names: {
                en: "6:8",
                ru: "6:8",
                es: "6:8",
                pt: "6:8"
            }
        }
    }, {
        id: 1283,
        label: {
            names: {
                en: "6:9",
                ru: "6:9",
                es: "6:9",
                pt: "6:9"
            }
        }
    }, {
        id: 1404,
        label: {
            names: {
                en: "7:0",
                ru: "7:0",
                es: "7:0",
                pt: "7:0"
            }
        }
    }, {
        id: 1405,
        label: {
            names: {
                en: "7:1",
                ru: "7:1",
                es: "7:1",
                pt: "7:1"
            }
        }
    }, {
        id: 1294,
        label: {
            names: {
                en: "7:10",
                ru: "7:10",
                es: "7:10",
                pt: "7:10"
            }
        }
    }, {
        id: 1406,
        label: {
            names: {
                en: "7:2",
                ru: "7:2",
                es: "7:2",
                pt: "7:2"
            }
        }
    }, {
        id: 1407,
        label: {
            names: {
                en: "7:3",
                ru: "7:3",
                es: "7:3",
                pt: "7:3"
            }
        }
    }, {
        id: 1408,
        label: {
            names: {
                en: "7:4",
                ru: "7:4",
                es: "7:4",
                pt: "7:4"
            }
        }
    }, {
        id: 1409,
        label: {
            names: {
                en: "7:5",
                ru: "7:5",
                es: "7:5",
                pt: "7:5"
            }
        }
    }, {
        id: 1410,
        label: {
            names: {
                en: "7:6",
                ru: "7:6",
                es: "7:6",
                pt: "7:6"
            }
        }
    }, {
        id: 1266,
        label: {
            names: {
                en: "7:7",
                ru: "7:7",
                es: "7:7",
                pt: "7:7"
            }
        }
    }, {
        id: 1274,
        label: {
            names: {
                en: "7:8",
                ru: "7:8",
                es: "7:8",
                pt: "7:8"
            }
        }
    }, {
        id: 1284,
        label: {
            names: {
                en: "7:9",
                ru: "7:9",
                es: "7:9",
                pt: "7:9"
            }
        }
    }, {
        id: 1411,
        label: {
            names: {
                en: "8:0",
                ru: "8:0",
                es: "8:0",
                pt: "8:0"
            }
        }
    }, {
        id: 1412,
        label: {
            names: {
                en: "8:1",
                ru: "8:1",
                es: "8:1",
                pt: "8:1"
            }
        }
    }, {
        id: 1295,
        label: {
            names: {
                en: "8:10",
                ru: "8:10",
                es: "8:10",
                pt: "8:10"
            }
        }
    }, {
        id: 1413,
        label: {
            names: {
                en: "8:2",
                ru: "8:2",
                es: "8:2",
                pt: "8:2"
            }
        }
    }, {
        id: 1414,
        label: {
            names: {
                en: "8:3",
                ru: "8:3",
                es: "8:3",
                pt: "8:3"
            }
        }
    }, {
        id: 1415,
        label: {
            names: {
                en: "8:4",
                ru: "8:4",
                es: "8:4",
                pt: "8:4"
            }
        }
    }, {
        id: 1416,
        label: {
            names: {
                en: "8:5",
                ru: "8:5",
                es: "8:5",
                pt: "8:5"
            }
        }
    }, {
        id: 1417,
        label: {
            names: {
                en: "8:6",
                ru: "8:6",
                es: "8:6",
                pt: "8:6"
            }
        }
    }, {
        id: 1418,
        label: {
            names: {
                en: "8:7",
                ru: "8:7",
                es: "8:7",
                pt: "8:7"
            }
        }
    }, {
        id: 1275,
        label: {
            names: {
                en: "8:8",
                ru: "8:8",
                es: "8:8",
                pt: "8:8"
            }
        }
    }, {
        id: 1285,
        label: {
            names: {
                en: "8:9",
                ru: "8:9",
                es: "8:9",
                pt: "8:9"
            }
        }
    }, {
        id: 1419,
        label: {
            names: {
                en: "9:0",
                ru: "9:0",
                es: "9:0",
                pt: "9:0"
            }
        }
    }, {
        id: 1420,
        label: {
            names: {
                en: "9:1",
                ru: "9:1",
                es: "9:1",
                pt: "9:1"
            }
        }
    }, {
        id: 1296,
        label: {
            names: {
                en: "9:10",
                ru: "9:10",
                es: "9:10",
                pt: "9:10"
            }
        }
    }, {
        id: 1421,
        label: {
            names: {
                en: "9:2",
                ru: "9:2",
                es: "9:2",
                pt: "9:2"
            }
        }
    }, {
        id: 1422,
        label: {
            names: {
                en: "9:3",
                ru: "9:3",
                es: "9:3",
                pt: "9:3"
            }
        }
    }, {
        id: 1423,
        label: {
            names: {
                en: "9:4",
                ru: "9:4",
                es: "9:4",
                pt: "9:4"
            }
        }
    }, {
        id: 1424,
        label: {
            names: {
                en: "9:5",
                ru: "9:5",
                es: "9:5",
                pt: "9:5"
            }
        }
    }, {
        id: 1425,
        label: {
            names: {
                en: "9:6",
                ru: "9:6",
                es: "9:6",
                pt: "9:6"
            }
        }
    }, {
        id: 1426,
        label: {
            names: {
                en: "9:7",
                ru: "9:7",
                es: "9:7",
                pt: "9:7"
            }
        }
    }, {
        id: 1427,
        label: {
            names: {
                en: "9:8",
                ru: "9:8",
                es: "9:8",
                pt: "9:8"
            }
        }
    }, {
        id: 1286,
        label: {
            names: {
                en: "9:9",
                ru: "9:9",
                es: "9:9",
                pt: "9:9"
            }
        }
    }, {
        id: 52,
        label: {
            names: {
                en: "Any Unquoted",
                ru: "Другой счёт",
                es: "Otro resultado",
                pt: "Otro resultado"
            }
        }
    }, {
        id: 53,
        label: {
            names: {
                en: "1/1",
                ru: "1/1",
                es: "1/1",
                pt: "1/1"
            }
        }
    }, {
        id: 60,
        label: {
            names: {
                en: "1/2",
                ru: "1/2",
                es: "1/2",
                pt: "1/2"
            }
        }
    }, {
        id: 54,
        label: {
            names: {
                en: "1/X",
                ru: "1/X",
                es: "1/X",
                pt: "1/X"
            }
        }
    }, {
        id: 59,
        label: {
            names: {
                en: "2/1",
                ru: "2/1",
                es: "2/1",
                pt: "2/1"
            }
        }
    }, {
        id: 56,
        label: {
            names: {
                en: "2/2",
                ru: "2/2",
                es: "2/2",
                pt: "2/2"
            }
        }
    }, {
        id: 57,
        label: {
            names: {
                en: "2/X",
                ru: "2/X",
                es: "2/X",
                pt: "2/X"
            }
        }
    }, {
        id: 55,
        label: {
            names: {
                en: "X/1",
                ru: "X/1",
                es: "X/1",
                pt: "X/1"
            }
        }
    }, {
        id: 58,
        label: {
            names: {
                en: "X/2",
                ru: "X/2",
                es: "X/2",
                pt: "X/2"
            }
        }
    }, {
        id: 61,
        label: {
            names: {
                en: "X/X",
                ru: "X/X",
                es: "X/X",
                pt: "X/X"
            }
        }
    }, {
        id: 28,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 27,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 30,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 29,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 63,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 62,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 65,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 64,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 67,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 66,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 69,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 68,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 71,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 70,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 73,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 72,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 74,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 75,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 76,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 77,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 78,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 79,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 80,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 81,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 82,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 83,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 84,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1007,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 85,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 86,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 87,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 88,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 89,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 90,
        label: {
            participantNumber: 1
        }
    }, {
        id: 91,
        label: {
            participantNumber: 2
        }
    }, {
        id: 92,
        label: {
            participantNumber: 1
        }
    }, {
        id: 93,
        label: {
            participantNumber: 2
        }
    }, {
        id: 94,
        label: {
            participantNumber: 1
        }
    }, {
        id: 95,
        label: {
            participantNumber: 2
        }
    }, {
        id: 96,
        label: {
            participantNumber: 1
        }
    }, {
        id: 97,
        label: {
            participantNumber: 2
        }
    }, {
        id: 98,
        label: {
            names: {
                en: "1т>2т",
                ru: "1т>2т",
                es: "1т>2т",
                pt: "1т>2т"
            }
        }
    }, {
        id: 99,
        label: {
            names: {
                en: "2т>1т",
                ru: "2т>1т",
                es: "2т>1т",
                pt: "2т>1т"
            }
        }
    }, {
        id: 100,
        label: {
            names: {
                en: "1st half",
                ru: "1 тайм",
                es: "1er tiempo",
                pt: "1er tiempo"
            }
        }
    }, {
        id: 102,
        label: {
            names: {
                en: "2d half",
                ru: "2 тайм",
                es: "2er tiempo",
                pt: "2er tiempo"
            }
        }
    }, {
        id: 101,
        label: {
            names: {
                en: "HT",
                ru: "Перерыв",
                es: "Descanso",
                pt: "Descanso"
            }
        }
    }, {
        id: 103,
        label: {
            participantNumber: 1
        }
    }, {
        id: 104,
        label: {
            participantNumber: 2
        }
    }, {
        id: 106,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 105,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 108,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 107,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 110,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 111,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 31,
        label: {
            names: {
                en: "1p>2p",
                ru: "1п>2п",
                es: "1p>2p",
                pt: "1p>2p"
            }
        }
    }, {
        id: 33,
        label: {
            names: {
                en: "2p>1p",
                ru: "2п>1п",
                es: "2p>1p",
                pt: "2p>1p"
            }
        }
    }, {
        id: 32,
        label: {
            names: {
                en: "1p>3p",
                ru: "1п>3п",
                es: "1p>3p",
                pt: "1p>3p"
            }
        }
    }, {
        id: 35,
        label: {
            names: {
                en: "3p>1p",
                ru: "3п>1п",
                es: "3p>1p",
                pt: "3p>1p"
            }
        }
    }, {
        id: 34,
        label: {
            names: {
                en: "2p>3p",
                ru: "2п>3п",
                es: "2p>3p",
                pt: "2p>3p"
            }
        }
    }, {
        id: 36,
        label: {
            names: {
                en: "3p>2p",
                ru: "3п>2п",
                es: "3p>2p",
                pt: "3p>2p"
            }
        }
    }, {
        id: 120,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 121,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 114,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 115,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 122,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 123,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 116,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 117,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 124,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 125,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1153,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1154,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 195,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 127,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 126,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 235,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 234,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 302,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 301,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 306,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 305,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 304,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 303,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 136,
        label: {
            participantNumber: 1
        }
    }, {
        id: 137,
        label: {
            participantNumber: 2
        }
    }, {
        id: 138,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 139,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 206,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 205,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 142,
        label: {
            participantNumber: 1
        }
    }, {
        id: 151,
        label: {
            participantNumber: 1
        }
    }, {
        id: 152,
        label: {
            participantNumber: 1
        }
    }, {
        id: 153,
        label: {
            participantNumber: 1
        }
    }, {
        id: 144,
        label: {
            participantNumber: 1
        }
    }, {
        id: 145,
        label: {
            participantNumber: 1
        }
    }, {
        id: 146,
        label: {
            participantNumber: 1
        }
    }, {
        id: 147,
        label: {
            participantNumber: 1
        }
    }, {
        id: 148,
        label: {
            participantNumber: 1
        }
    }, {
        id: 149,
        label: {
            participantNumber: 1
        }
    }, {
        id: 150,
        label: {
            participantNumber: 1
        }
    }, {
        id: 143,
        label: {
            participantNumber: 1
        }
    }, {
        id: 154,
        label: {
            participantNumber: 2
        }
    }, {
        id: 163,
        label: {
            participantNumber: 2
        }
    }, {
        id: 164,
        label: {
            participantNumber: 2
        }
    }, {
        id: 165,
        label: {
            participantNumber: 2
        }
    }, {
        id: 156,
        label: {
            participantNumber: 2
        }
    }, {
        id: 157,
        label: {
            participantNumber: 2
        }
    }, {
        id: 158,
        label: {
            participantNumber: 2
        }
    }, {
        id: 159,
        label: {
            participantNumber: 2
        }
    }, {
        id: 160,
        label: {
            participantNumber: 2
        }
    }, {
        id: 161,
        label: {
            participantNumber: 2
        }
    }, {
        id: 162,
        label: {
            participantNumber: 2
        }
    }, {
        id: 155,
        label: {
            participantNumber: 2
        }
    }, {
        id: 167,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 168,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 170,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 169,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 172,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 171,
        label: {
            participantNumber: 1
        }
    }, {
        id: 173,
        label: {
            participantNumber: 2
        }
    }, {
        id: 175,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 174,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 177,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 176,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 179,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 178,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 180,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 188,
        label: {
            template: true,
            names: {
                en: "${this.k1} > ${this.k2}",
                ru: "${this.k1} > ${this.k2}",
                es: "${this.k1} > ${this.k2}",
                pt: "${this.k1} > ${this.k2}"
            }
        }
    }, {
        id: 189,
        label: {
            template: true,
            names: {
                en: "${this.k2} > ${this.k1}",
                ru: "${this.k2} > ${this.k1}",
                es: "${this.k2} > ${this.k1}",
                pt: "${this.k2} > ${this.k1}"
            }
        }
    }, {
        id: 233,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 232,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 242,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 240,
        label: {
            participantNumber: 1
        }
    }, {
        id: 241,
        label: {
            participantNumber: 2
        }
    }, {
        id: 246,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 245,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 247,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 253,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 252,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 254,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 257,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 256,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 258,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 261,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 260,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 262,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 265,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 264,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 266,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 269,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 268,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 271,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 270,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 273,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 272,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 275,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 274,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 276,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 281,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 280,
        label: {
            participantNumber: 1
        }
    }, {
        id: 282,
        label: {
            participantNumber: 2
        }
    }, {
        id: 283,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 288,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 287,
        label: {
            participantNumber: 1
        }
    }, {
        id: 289,
        label: {
            participantNumber: 2
        }
    }, {
        id: 255,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 259,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 263,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 267,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 277,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 284,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 291,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 290,
        label: {
            participantNumber: 1
        }
    }, {
        id: 292,
        label: {
            participantNumber: 2
        }
    }, {
        id: 293,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 294,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 296,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 295,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 297,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 298,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 237,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 236,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 300,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 299,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 318,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 317,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 320,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 319,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 312,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 311,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 314,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 313,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 308,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 307,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 310,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 309,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 758,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 757,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 756,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 755,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 322,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 321,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 324,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 323,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 326,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 325,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 328,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 327,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 330,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 329,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 331,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 332,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 333,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 937,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 338,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 337,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 340,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 339,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 341,
        label: {
            participantNumber: 1
        }
    }, {
        id: 342,
        label: {
            participantNumber: 2
        }
    }, {
        id: 344,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 343,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 346,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 345,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 348,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 347,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 350,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 349,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 361,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 362,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 365,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 364,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 367,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 366,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 369,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 368,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 371,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 370,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 373,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 372,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 375,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 374,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 377,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 376,
        label: {
            participantNumber: 1
        }
    }, {
        id: 378,
        label: {
            participantNumber: 2
        }
    }, {
        id: 380,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 379,
        label: {
            participantNumber: 1
        }
    }, {
        id: 381,
        label: {
            participantNumber: 2
        }
    }, {
        id: 383,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 382,
        label: {
            participantNumber: 1
        }
    }, {
        id: 384,
        label: {
            participantNumber: 2
        }
    }, {
        id: 386,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 385,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 388,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 387,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 390,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 389,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 392,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 391,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 394,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 393,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 396,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 395,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 398,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 397,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 400,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 399,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 402,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 401,
        label: {
            participantNumber: 1
        }
    }, {
        id: 403,
        label: {
            participantNumber: 2
        }
    }, {
        id: 407,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 406,
        label: {
            participantNumber: 1
        }
    }, {
        id: 408,
        label: {
            participantNumber: 2
        }
    }, {
        id: 405,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 404,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 410,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 409,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 412,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 411,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 414,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 413,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 424,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 423,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 425,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 427,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 426,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 428,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 416,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 415,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 420,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 419,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 418,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 417,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 422,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 421,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 430,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 429,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 431,
        label: {
            participantNumber: 1
        }
    }, {
        id: 433,
        label: {
            participantNumber: 2
        }
    }, {
        id: 434,
        label: {
            participantNumber: 1
        }
    }, {
        id: 435,
        label: {
            participantNumber: 2
        }
    }, {
        id: 436,
        label: {
            participantNumber: 1
        }
    }, {
        id: 437,
        label: {
            participantNumber: 2
        }
    }, {
        id: 439,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 438,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 441,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 440,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 443,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 442,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 445,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 444,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 447,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 446,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 449,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 448,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 451,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 450,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 453,
        label: {
            template: true,
            names: {
                en: "${this.k1} выше ${this.p1}",
                ru: "${this.k1} выше ${this.p1}",
                es: "${this.k1} выше ${this.p1}",
                pt: "${this.k1} выше ${this.p1}"
            }
        }
    }, {
        id: 452,
        label: {
            template: true,
            names: {
                en: "${this.k1} ниже ${this.p1}",
                ru: "${this.k1} ниже ${this.p1}",
                es: "${this.k1} ниже ${this.p1}",
                pt: "${this.k1} ниже ${this.p1}"
            }
        }
    }, {
        id: 455,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 454,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 457,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 456,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 459,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 458,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 461,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 460,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 463,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 462,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 465,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 464,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 467,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 466,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 469,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 468,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 471,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 470,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 473,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 472,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 475,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 474,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 476,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 477,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 479,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 478,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 481,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 480,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 491,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 490,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 485,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 484,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 487,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 486,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 483,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 482,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 493,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 492,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 489,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 488,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 495,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 494,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 499,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 498,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 501,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 500,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 535,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 536,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 553,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 554,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 555,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 556,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 557,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 558,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 559,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 560,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 561,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 562,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 563,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 564,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 565,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 566,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 567,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 568,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 569,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 570,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 571,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 572,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 574,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 573,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 576,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 575,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 577,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 578,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 579,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 580,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 581,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 582,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 583,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 584,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 585,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 586,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 587,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 588,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 589,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 590,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 591,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 592,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 617,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 618,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 619,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 620,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 621,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 622,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 623,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 624,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 609,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 610,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 611,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 612,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 613,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 614,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 615,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 616,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 593,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 594,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 595,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 597,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 598,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 599,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 600,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 601,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 602,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 603,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 604,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 605,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 606,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 607,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 608,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 537,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 538,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 539,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 540,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 541,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 542,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 543,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 544,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 545,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 546,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 548,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 547,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 549,
        label: {
            names: {
                en: "YC",
                ru: "ЖК",
                es: "TA",
                pt: "TA"
            }
        }
    }, {
        id: 550,
        label: {
            names: {
                en: "Goal",
                ru: "Гол",
                es: "Gol",
                pt: "Gol"
            }
        }
    }, {
        id: 627,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 628,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 630,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 629,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 632,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 631,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 634,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 633,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 636,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 635,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 638,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 637,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 640,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 639,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 644,
        label: {
            participantNumber: 1
        }
    }, {
        id: 645,
        label: {
            participantNumber: 2
        }
    }, {
        id: 646,
        label: {
            participantNumber: 1
        }
    }, {
        id: 647,
        label: {
            participantNumber: 2
        }
    }, {
        id: 649,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 648,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 651,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 650,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 653,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 652,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 655,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 654,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 656,
        label: {
            participantNumber: 1
        }
    }, {
        id: 657,
        label: {
            participantNumber: 2
        }
    }, {
        id: 658,
        label: {
            participantNumber: 1
        }
    }, {
        id: 659,
        label: {
            participantNumber: 2
        }
    }, {
        id: 660,
        label: {
            participantNumber: 1
        }
    }, {
        id: 661,
        label: {
            participantNumber: 2
        }
    }, {
        id: 662,
        label: {
            participantNumber: 1
        }
    }, {
        id: 663,
        label: {
            participantNumber: 2
        }
    }, {
        id: 665,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 664,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 667,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 666,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 669,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 668,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 671,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 670,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 673,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 672,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 675,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 674,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 677,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 676,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 679,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 678,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 680,
        label: {
            participantNumber: 1
        }
    }, {
        id: 681,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 682,
        label: {
            participantNumber: 2
        }
    }, {
        id: 683,
        label: {
            participantNumber: 1
        }
    }, {
        id: 684,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 685,
        label: {
            participantNumber: 2
        }
    }, {
        id: 686,
        label: {
            participantNumber: 1
        }
    }, {
        id: 687,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 688,
        label: {
            participantNumber: 2
        }
    }, {
        id: 689,
        label: {
            participantNumber: 1
        }
    }, {
        id: 691,
        label: {
            participantNumber: 2
        }
    }, {
        id: 690,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 693,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 692,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 694,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 696,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 695,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 697,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 699,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 698,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 700,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 704,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 703,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 705,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 707,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 706,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 708,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 709,
        label: {
            participantNumber: 1
        }
    }, {
        id: 710,
        label: {
            participantNumber: 2
        }
    }, {
        id: 711,
        label: {
            participantNumber: 1
        }
    }, {
        id: 712,
        label: {
            participantNumber: 2
        }
    }, {
        id: 713,
        label: {
            participantNumber: 1
        }
    }, {
        id: 714,
        label: {
            participantNumber: 2
        }
    }, {
        id: 715,
        label: {
            participantNumber: 1
        }
    }, {
        id: 716,
        label: {
            participantNumber: 2
        }
    }, {
        id: 717,
        label: {
            participantNumber: 1
        }
    }, {
        id: 718,
        label: {
            participantNumber: 2
        }
    }, {
        id: 726,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 725,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 728,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 727,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 730,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 729,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 732,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 731,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 734,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 733,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 736,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 735,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 738,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 737,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 740,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 739,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 742,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 741,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 744,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 743,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 746,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 745,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 748,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 747,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 750,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 749,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 752,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 751,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 754,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 753,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 760,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 759,
        label: {
            participantNumber: 1
        }
    }, {
        id: 761,
        label: {
            participantNumber: 2
        }
    }, {
        id: 763,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 762,
        label: {
            participantNumber: 1
        }
    }, {
        id: 764,
        label: {
            participantNumber: 2
        }
    }, {
        id: 766,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 765,
        label: {
            participantNumber: 1
        }
    }, {
        id: 767,
        label: {
            participantNumber: 2
        }
    }, {
        id: 769,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 768,
        label: {
            participantNumber: 1
        }
    }, {
        id: 770,
        label: {
            participantNumber: 2
        }
    }, {
        id: 772,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 771,
        label: {
            participantNumber: 1
        }
    }, {
        id: 773,
        label: {
            participantNumber: 2
        }
    }, {
        id: 775,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 774,
        label: {
            participantNumber: 1
        }
    }, {
        id: 776,
        label: {
            participantNumber: 2
        }
    }, {
        id: 778,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 777,
        label: {
            participantNumber: 1
        }
    }, {
        id: 779,
        label: {
            participantNumber: 2
        }
    }, {
        id: 781,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 780,
        label: {
            participantNumber: 1
        }
    }, {
        id: 782,
        label: {
            participantNumber: 2
        }
    }, {
        id: 784,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 783,
        label: {
            participantNumber: 1
        }
    }, {
        id: 785,
        label: {
            participantNumber: 2
        }
    }, {
        id: 787,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 786,
        label: {
            participantNumber: 1
        }
    }, {
        id: 788,
        label: {
            participantNumber: 2
        }
    }, {
        id: 790,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 789,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 792,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 791,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 794,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 793,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 796,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 795,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 798,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 797,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 800,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 799,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 802,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 801,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 804,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 803,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 806,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 805,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 808,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 807,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 810,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 809,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 812,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 811,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 814,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 813,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 816,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 815,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 818,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 817,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 820,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 819,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 822,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 821,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 824,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 823,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 826,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 825,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 828,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 827,
        label: {
            participantNumber: 1
        }
    }, {
        id: 829,
        label: {
            participantNumber: 2
        }
    }, {
        id: 831,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 830,
        label: {
            participantNumber: 1
        }
    }, {
        id: 832,
        label: {
            participantNumber: 2
        }
    }, {
        id: 834,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 833,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 836,
        label: {
            template: true,
            names: {
                en: "${this.k2} (${this.p1})",
                ru: "${this.k2} (${this.p1})",
                es: "${this.k2} (${this.p1})",
                pt: "${this.k2} (${this.p1})"
            }
        }
    }, {
        id: 835,
        label: {
            template: true,
            names: {
                en: "${this.k1} (${this.p1})",
                ru: "${this.k1} (${this.p1})",
                es: "${this.k1} (${this.p1})",
                pt: "${this.k1} (${this.p1})"
            }
        }
    }, {
        id: 838,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 837,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 840,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 839,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 842,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 841,
        label: {
            participantNumber: 1
        }
    }, {
        id: 843,
        label: {
            participantNumber: 2
        }
    }, {
        id: 845,
        label: {
            names: {
                en: "12",
                ru: "12",
                es: "12",
                pt: "12"
            }
        }
    }, {
        id: 844,
        label: {
            names: {
                en: "1Х",
                ru: "1Х",
                es: "1Х",
                pt: "1Х"
            }
        }
    }, {
        id: 846,
        label: {
            names: {
                en: "Х2",
                ru: "Х2",
                es: "Х2",
                pt: "Х2"
            }
        }
    }, {
        id: 848,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 847,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 850,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 849,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 851,
        label: {
            participantNumber: 1
        }
    }, {
        id: 852,
        label: {
            participantNumber: 2
        }
    }, {
        id: 853,
        label: {
            participantNumber: 1
        }
    }, {
        id: 854,
        label: {
            participantNumber: 2
        }
    }, {
        id: 855,
        label: {
            participantNumber: 1
        }
    }, {
        id: 856,
        label: {
            participantNumber: 2
        }
    }, {
        id: 858,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 857,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 860,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 859,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 862,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 861,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 864,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 863,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 866,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 865,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 868,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 867,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 870,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 869,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 872,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 871,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 874,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 873,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 876,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 875,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 878,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 877,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 880,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 879,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 882,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 881,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 884,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 883,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 886,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 885,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 888,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 887,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 889,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 892,
        label: {
            names: {
                en: "0:2",
                ru: "0:2",
                es: "0:2",
                pt: "0:2"
            }
        }
    }, {
        id: 891,
        label: {
            names: {
                en: "1:1",
                ru: "1:1",
                es: "1:1",
                pt: "1:1"
            }
        }
    }, {
        id: 890,
        label: {
            names: {
                en: "2:0",
                ru: "2:0",
                es: "2:0",
                pt: "2:0"
            }
        }
    }, {
        id: 894,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 893,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 896,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 895,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 897,
        label: {
            template: true,
            names: {
                en: "${this.p1}",
                ru: "${this.p1}",
                es: "${this.p1}",
                pt: "${this.p1}"
            }
        }
    }, {
        id: 898,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 899,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 900,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 901,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 902,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 907,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 903,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 904,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 905,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 906,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 908,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 909,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 910,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 911,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 912,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 913,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 914,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 915,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 916,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 917,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 918,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 919,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 920,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 921,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 922,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 923,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 935,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 936,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 334,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 938,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 962,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 963,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 973,
        label: {
            names: {
                en: "1/1",
                ru: "1/1",
                es: "1/1",
                pt: "1/1"
            }
        }
    }, {
        id: 976,
        label: {
            names: {
                en: "1/2",
                ru: "1/2",
                es: "1/2",
                pt: "1/2"
            }
        }
    }, {
        id: 975,
        label: {
            names: {
                en: "2/1",
                ru: "2/1",
                es: "2/1",
                pt: "2/1"
            }
        }
    }, {
        id: 974,
        label: {
            names: {
                en: "2/2",
                ru: "2/2",
                es: "2/2",
                pt: "2/2"
            }
        }
    }, {
        id: 980,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 979,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 978,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 977,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 984,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 983,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 1000,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 999,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 1001,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 1002,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 1004,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 1003,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 1006,
        label: {
            template: true,
            names: {
                en: "Over ${this.p1}",
                ru: "Больше ${this.p1}",
                es: "Más de ${this.p1}",
                pt: "Más de ${this.p1}"
            }
        }
    }, {
        id: 1005,
        label: {
            template: true,
            names: {
                en: "Under ${this.p1}",
                ru: "Меньше ${this.p1}",
                es: "Menos de ${this.p1}",
                pt: "Menos de ${this.p1}"
            }
        }
    }, {
        id: 1008,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1009,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1011,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1010,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1013,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1012,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1015,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1014,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1017,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1016,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1019,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1018,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1021,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1020,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1030,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1029,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1032,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1031,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1034,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1033,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1036,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1035,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1038,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1037,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1040,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1039,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1041,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1042,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1069,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1068,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 1133,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1131,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1134,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1132,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1075,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1076,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1087,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1089,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1109,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1110,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1111,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1112,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1113,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1114,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1115,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1116,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1117,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1118,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1119,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1120,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1129,
        label: {
            names: {
                en: "Draw",
                ru: "Ничья",
                es: "Empate",
                pt: "Empate"
            }
        }
    }, {
        id: 1125,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1127,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1126,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1128,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1301,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1302,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1303,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1328,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1329,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1330,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1304,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1305,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1306,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1307,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1308,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1309,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1310,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1311,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1312,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1313,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1314,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1315,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1316,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1317,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1318,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1319,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1320,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1321,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1322,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1323,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1324,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 1325,
        label: {
            participantNumber: 1
        }
    }, {
        id: 1326,
        label: {
            participantNumber: 2
        }
    }, {
        id: 1327,
        label: {
            names: {
                en: "No one will score",
                ru: "Не забьют",
                es: "Ninguno anotará",
                pt: "Ninguno anotará"
            }
        }
    }, {
        id: 129,
        label: {
            template: true,
            names: {
                en: "Goal total (${this.p1} - ${this.p2})",
                ru: "Тотал мячей (${this.p1} - ${this.p2})",
                es: "Total de goles (${this.p1} - ${this.p2})",
                pt: "Total de goles (${this.p1} - ${this.p2})"
            }
        }
    }, {
        id: 130,
        label: {
            template: true,
            names: {
                en: "Goal total (>=${this.p1})",
                ru: "Тотал мячей (>=${this.p1})",
                es: "Total de goles (>=${this.p1})",
                pt: "Total de goles (>=${this.p1})"
            }
        }
    }, {
        id: 359,
        label: {
            participantNumber: 1
        }
    }, {
        id: 360,
        label: {
            participantNumber: 2
        }
    }, {
        id: 335,
        label: {
            names: {
                en: "Even",
                ru: "Чет",
                es: "Par",
                pt: "Par"
            }
        }
    }, {
        id: 336,
        label: {
            names: {
                en: "Odd",
                ru: "Нечет",
                es: "Impar",
                pt: "Impar"
            }
        }
    }, {
        id: 1482,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1483,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1484,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1485,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1486,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1487,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1489,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1491,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1493,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1495,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1497,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1499,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1500,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1501,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1502,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1504,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1506,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1508,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1509,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1510,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1511,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1513,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1515,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1517,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1472,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1473,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1524,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1525,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1474,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1475,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1476,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1477,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1478,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1479,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1480,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1481,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1518,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1519,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1520,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1521,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }, {
        id: 1438,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1439,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1440,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1441,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1442,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1443,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1488,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1490,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1492,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1494,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1496,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1498,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1460,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1461,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1462,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1503,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1505,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1507,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1463,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1464,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1465,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1512,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1514,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1516,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 719,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 720,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 721,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 722,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1448,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1449,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1450,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1451,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1452,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1453,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1454,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1455,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1456,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1457,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1458,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 1459,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 112,
        label: {
            names: {
                en: "Yes",
                ru: "Да",
                es: "Si",
                pt: "Si"
            }
        }
    }, {
        id: 113,
        label: {
            names: {
                en: "No",
                ru: "Нет",
                es: "No",
                pt: "No"
            }
        }
    }];
