import { h } from "vue";
import App from "@/App.vue";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { WebDesktopSettingsServiceImpl, WebSettings } from "@sportaq/services/web/settings/settings-service";
import {
    injectUUIDProvider,
    provideSharedServices,
    SharedDependencyProvider
} from "@sportaq/vue/components/common/dependency-container/shared-dependency-container";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import {
    provideInjectedPrintService,
    provideSettings,
    provideHttpService,
    provideInjectedHttpService
} from "@sportaq/services/index";
import { WebDesktopRestService } from "@sportaq/services/web/rest/web-desktop-rest-service";
import {
    provideInjectedWebDesktopRestService,
    provideWebDesktopRestService
} from "@sportaq/services/web";
import { StubPrintServiceImpl } from "@sportaq/services/print/print-service";
import { E2eTestsRestServiceWrapper } from "@sportaq/services/rest/rest-service";
import { HttpService } from "@sportaq/services/http/http-service";
import { useRootStore } from "@sportaq/vuex/index";
import { PlaywrightWindow } from "@sportaq/vue/components/common/dependency-container/playwright-window";
import { PlaywrightStreamingNetwork } from "@sportaq/vue/__test__/playwright/streaming/playwright-streaming-network";
import {
    MockMiscellaneousParamsProvider
} from "@sportaq/vue/__test__/common/miscellaneous-params/mock-miscellaneous-params-provider";
import { CypressWindow } from "@sportaq/vue/components/common/dependency-container/cypress-window";
import { useWebEnvironment, WebEnvironmentSettings } from "@sportaq/services/web/environment/web-environment";

export interface DependencyProvider extends SharedDependencyProvider {
    settings: WebSettings;
    environment: WebEnvironmentSettings;
    restService: WebDesktopRestService;
    httpService: HttpService;
}

export default {
    name: "dependency-container",
    components: { App },
    setup () {
        const rootStore = useRootStore();
        const injectedDependencyProvider = resolveDependencyProvider();
        const uuidProvider = injectUUIDProvider(injectedDependencyProvider);
        const settingsService = injectSettingsService(uuidProvider, injectedDependencyProvider);
        const clockProvider = provideSharedServices(injectedDependencyProvider, rootStore, settingsService);
        injectWebDesktopRestService(clockProvider, uuidProvider, settingsService, injectedDependencyProvider);
        injectHttpService(injectedDependencyProvider);
        provideInjectedPrintService(new StubPrintServiceImpl());
        return () => h(App);
    }
};

function resolveDependencyProvider (): Partial<DependencyProvider> | undefined {
    if (window) {
        const cypressWindow = window as unknown as CypressWindow<DependencyProvider>;
        if (cypressWindow.Cypress) {
            return resolveCypressDependencyProvider(cypressWindow);
        }
        const playwrightWindow = window as unknown as PlaywrightWindow;
        if (playwrightWindow.playwright) {
            return resolvePlaywrightDependencyProvider(playwrightWindow);
        }
    }
    return undefined;
}

function resolveCypressDependencyProvider (cypressWindow: CypressWindow<DependencyProvider>): Partial<DependencyProvider> {
    return cypressWindow.dependencyProvider;
}

function resolvePlaywrightDependencyProvider (playwrightWindow: PlaywrightWindow): Partial<DependencyProvider> {
    const result: Partial<DependencyProvider> = {};
    const playwrightStreamingNetwork = new PlaywrightStreamingNetwork();
    result.streamingNetwork = playwrightStreamingNetwork;
    result.streamingRequestService = playwrightWindow.streamingRequestService;
    result.miscellaneousParamsProvider = playwrightWindow.miscellaneousParamsProvider || new MockMiscellaneousParamsProvider();

    result.clockProvider = playwrightWindow.clockProvider;
    result.uuidProvider = playwrightWindow.uuidProvider;

    playwrightWindow.testStreamingNetwork = playwrightStreamingNetwork;
    return result;
}

function injectHttpService (injectedDependencyProvider: Partial<DependencyProvider> | undefined) {
    if (!injectedDependencyProvider?.httpService) {
        provideHttpService();
    } else {
        provideInjectedHttpService(injectedDependencyProvider.httpService);
    }
}
function injectSettingsService (uuidProvider: UUIDProvider, injectedDependencyProvider: Partial<DependencyProvider> | undefined): WebSettings {
    if (!injectedDependencyProvider?.settings) {
        const environment = useWebEnvironment();
        const service = new WebDesktopSettingsServiceImpl(environment);
        return provideSettings(service) as WebSettings;
    } else {
        const settingsService = injectedDependencyProvider.settings;
        provideSettings(settingsService);
        return settingsService;
    }
}

function injectWebDesktopRestService (clockProvider: ClockProvider, uuidProvider: UUIDProvider, settingsService: WebSettings, injectedDependencyProvider: Partial<DependencyProvider> | undefined): WebDesktopRestService {
    if (!injectedDependencyProvider?.restService) {
        return provideWebDesktopRestService(clockProvider, uuidProvider, settingsService);
    } else {
        const restService = injectedDependencyProvider.restService;
        const realService = provideWebDesktopRestService(clockProvider, uuidProvider, settingsService);
        const wrapper = restService as unknown as E2eTestsRestServiceWrapper<WebDesktopRestService>;
        wrapper.realRestService = realService;
        provideInjectedWebDesktopRestService(restService);
        return restService;
    }
}
