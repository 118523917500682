import { BettingEvent } from "@sportaq/model/betting/events/event";
import EventType from "@sportaq/common/enums/event-type";
import { ESportType } from "@sportaq/common/enums/sport-type";
import {
    EventViewFilterUtils
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/country-filter/country-filter";
import { binarySearchIndex, nativeNumberComparator } from "@sportaq/common/utils/arrays";
import { handleValueChanger, ValueChanger } from "@sportaq/model/types/filter-value-checker";

interface EventViewFilterOptions {
    eventType?: EventType;
}

export interface AcceptResult {
    accept: boolean;
    acceptRegardlessCountryFilter: boolean;
}

export class EventViewFilter implements EventViewFilterOptions {
    eventType?: EventType;
    sportType?: ESportType;
    additionalSportTypes?: ESportType[];

    countryFilter: EventViewFilterUtils.CountryFilter = new EventViewFilterUtils.CountryFilter();

    timeInterval?: FilterTimeInterval;
    popular?: number[];

    clear () {
        this.eventType = undefined;
        this.sportType = undefined;
        this.additionalSportTypes = undefined;

        this.countryFilter.clear();

        this.timeInterval = undefined;
        this.popular = undefined;
    }

    tryAccept (event: BettingEvent, favouritesMode: boolean, options?: EventViewFilterOptions): AcceptResult {
        const o = this.concatOptions(options);
        let acceptRegardlessCountryFilter;
        if (!favouritesMode) {
            acceptRegardlessCountryFilter = this.filterByEventTypeAndSportType(o, event) &&
                this.filterByPopular(event) &&
                this.filterByTime(event, favouritesMode);
        } else {
            acceptRegardlessCountryFilter = this.filterByTime(event, favouritesMode);
        }
        const accept = acceptRegardlessCountryFilter && this.countryFilter.filter(event.partition.countryId, event.partition.id);
        return {
            accept,
            acceptRegardlessCountryFilter
        };
    }

    private filterByPopular (event: BettingEvent) {
        return !this.popular || event.eventType === EventType.LIVE || binarySearchIndex(this.popular, event.positionId, nativeNumberComparator) > -1;
    }

    private filterByTime (event: BettingEvent, favouritesMode: boolean) {
        return !this.timeInterval || (!favouritesMode && event.eventType === EventType.LIVE) || (event.startTime.getTime() >= this.timeInterval.start.getTime() && event.startTime.getTime() <= this.timeInterval.end.getTime());
    }

    private filterByEventTypeAndSportType (o: EventViewFilterOptions, event: BettingEvent) {
        return !!o.eventType && !!this.sportType &&
            event.eventType === o.eventType &&
            (
                (event.partition.sportTypeId === this.sportType || (!!this.additionalSportTypes && this.additionalSportTypes.includes(event.partition.sportTypeId)))
            );
    }

    change (changer: FilterChanger): boolean {
        return this.eventTypeChange(changer) ||
            this.sportTypeChange(changer) ||
            this.countryChange(changer) ||
            this.partitionChange(changer) ||
            this.timeIntervalChange(changer) ||
            this.popularChange(changer) ||
            this.forcedPartitionChange(changer) ||
            this.clearCountryAndTimeIntervalChange(changer) ||
            this.additionalSportTypesChange(changer);
    }

    isCountrySelected (countryId: number): boolean {
        return this.countryFilter.isCountrySelected(countryId);
    }

    isPartitionSelected (partitionId: number): boolean {
        return this.countryFilter.isPartitionSelected(partitionId);
    }

    private eventTypeChange (changer: FilterChanger): boolean {
        const result = handleValueChanger(changer.changeEventType, () => this.eventType, value => (this.eventType = value));
        if (result) {
            this.timeIntervalChange({ timeInterval: { type: "unset" } });
            this.popularChange({ popular: { type: "unset" } });
            this.additionalSportTypesChange({ additionalSportTypes: { type: "unset" } });
        }
        return result;
    }

    private sportTypeChange (changer: FilterChanger): boolean {
        const result = handleValueChanger(changer.changeSportTypeId, () => this.sportType, value => (this.sportType = value));
        if (result) {
            this.popularChange({ popular: { type: "unset" } });
            this.additionalSportTypesChange({ additionalSportTypes: { type: "unset" } });
        }
        return result;
    }

    private countryChange (changer: FilterChanger): boolean {
        if (changer.changeCountry) {
            switch (changer.changeCountry.type) {
                case "toggle": {
                    return this.countryFilter.toggleCountry(changer.changeCountry.value);
                }
            }
        }
        return false;
    }

    private partitionChange (changer: FilterChanger) {
        if (changer.changePartition) {
            switch (changer.changePartition.type) {
                case "toggle": {
                    return this.countryFilter.togglePartition(changer.changePartition.value);
                }
            }
        }
        return false;
    }

    private timeIntervalChange (changer: FilterChanger) {
        return handleValueChanger(changer.timeInterval, () => this.timeInterval, value => (this.timeInterval = value));
    }

    private popularChange (changer: FilterChanger) {
        return handleValueChanger(changer.popular, () => this.popular, value => (this.popular = value?.sort(nativeNumberComparator)));
    }

    private forcedPartitionChange (changer: FilterChanger) {
        if (changer) {
            if (changer.forcePartition) {
                switch (changer.forcePartition.type) {
                    case "set": {
                        this.countryFilter.setForcedPartition(changer.forcePartition.value);
                        return false;
                    }
                    case "toggle": {
                        this.countryFilter.setForcedPartition(undefined);
                        return false;
                    }
                    case "unset": {
                        this.countryFilter.setForcedPartition(undefined);
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private concatOptions (options?: EventViewFilterOptions): EventViewFilterOptions {
        if (!options) {
            return this;
        }
        return {
            eventType: options.eventType || this.eventType
        };
    }

    private clearCountryAndTimeIntervalChange (changer: FilterChanger) {
        if (changer) {
            if (changer.clearCountryAndTimeInterval) {
                let result = false;
                if (!this.countryFilter.isEmpty()) {
                    this.countryFilter.empty();
                    result = true;
                }
                return this.timeIntervalChange({ timeInterval: { type: "unset" } }) || result;
            }
        }
        return false;
    }

    private additionalSportTypesChange (changer: FilterChanger) {
        return handleValueChanger(changer.additionalSportTypes, () => this.additionalSportTypes, value => (this.additionalSportTypes = value));
    }
}

export interface FilterTimeInterval {
    buttonId: number;
    start: Date;
    end: Date;
}

export interface FilterChanger {
    changeSportTypeId?: ValueChanger<number>;
    changeEventType?: ValueChanger<EventType>;
    changeCountry?: ValueChanger<number>;
    changePartition?: ValueChanger<number>;
    timeInterval?: ValueChanger<FilterTimeInterval>;
    popular?: ValueChanger<number[]>;
    forcePartition?: ValueChanger<number>;
    clearCountryAndTimeInterval?: ValueChanger<void>;
    additionalSportTypes?: ValueChanger<ESportType[]>;
}
