import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getTableTennisMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.popular",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2,
                title: "detailInfo.groups.tableTennis.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.tableTennis_total,
                title: "detailInfo.groups.tableTennis.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicap,
                title: "detailInfo.groups.tableTennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam1,
                title: "detailInfo.groups.tableTennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam2,
                title: "detailInfo.groups.tableTennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddMatch,
                title: "detailInfo.groups.tableTennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tableTennis_matchScore,
                title: "detailInfo.groups.tableTennis.matchScore",
                quotationIds: [
                    [13, 15],
                    [14, 16],
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2,
                title: "detailInfo.groups.tableTennis.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.tableTennis_total,
                title: "detailInfo.groups.tableTennis.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicap,
                title: "detailInfo.groups.tableTennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam1,
                title: "detailInfo.groups.tableTennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam2,
                title: "detailInfo.groups.tableTennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddMatch,
                title: "detailInfo.groups.tableTennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set1,
                title: "detailInfo.groups.tableTennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet1,
                title: "detailInfo.groups.tableTennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet1,
                title: "detailInfo.groups.tableTennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet1,
                title: "detailInfo.groups.tableTennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set2,
                title: "detailInfo.groups.tableTennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet2,
                title: "detailInfo.groups.tableTennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet2,
                title: "detailInfo.groups.tableTennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet2,
                title: "detailInfo.groups.tableTennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set3,
                title: "detailInfo.groups.tableTennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet3,
                title: "detailInfo.groups.tableTennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet3,
                title: "detailInfo.groups.tableTennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet3,
                title: "detailInfo.groups.tableTennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set4,
                title: "detailInfo.groups.tableTennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet4,
                title: "detailInfo.groups.tableTennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet4,
                title: "detailInfo.groups.tableTennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet4,
                title: "detailInfo.groups.tableTennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set5,
                title: "detailInfo.groups.tableTennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet5,
                title: "detailInfo.groups.tableTennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet5,
                title: "detailInfo.groups.tableTennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet5,
                title: "detailInfo.groups.tableTennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            },
            {
                id: EDetailRow.tableTennis_matchScore,
                title: "detailInfo.groups.tableTennis.matchScore",
                quotationIds: [
                    [13, 15],
                    [14, 16],
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicap,
                title: "detailInfo.groups.tableTennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet1,
                title: "detailInfo.groups.tableTennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet2,
                title: "detailInfo.groups.tableTennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet3,
                title: "detailInfo.groups.tableTennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet4,
                title: "detailInfo.groups.tableTennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet5,
                title: "detailInfo.groups.tableTennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalMatch,
                title: "detailInfo.groups.tableTennis.totalMatch",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet1,
                title: "detailInfo.groups.tableTennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet2,
                title: "detailInfo.groups.tableTennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet3,
                title: "detailInfo.groups.tableTennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet4,
                title: "detailInfo.groups.tableTennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet5,
                title: "detailInfo.groups.tableTennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam1,
                title: "detailInfo.groups.tableTennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tableTennis_individualTotalTeam2,
                title: "detailInfo.groups.tableTennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddMatch,
                title: "detailInfo.groups.tableTennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet1,
                title: "detailInfo.groups.tableTennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet2,
                title: "detailInfo.groups.tableTennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet3,
                title: "detailInfo.groups.tableTennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet4,
                title: "detailInfo.groups.tableTennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet5,
                title: "detailInfo.groups.tableTennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.tableTennis.sets",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set1,
                title: "detailInfo.groups.tableTennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet1,
                title: "detailInfo.groups.tableTennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet1,
                title: "detailInfo.groups.tableTennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet1,
                title: "detailInfo.groups.tableTennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set2,
                title: "detailInfo.groups.tableTennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet2,
                title: "detailInfo.groups.tableTennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet2,
                title: "detailInfo.groups.tableTennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet2,
                title: "detailInfo.groups.tableTennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set3,
                title: "detailInfo.groups.tableTennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet3,
                title: "detailInfo.groups.tableTennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet3,
                title: "detailInfo.groups.tableTennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet3,
                title: "detailInfo.groups.tableTennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set4,
                title: "detailInfo.groups.tableTennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet4,
                title: "detailInfo.groups.tableTennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet4,
                title: "detailInfo.groups.tableTennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet4,
                title: "detailInfo.groups.tableTennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tableTennis_x1_2Set5,
                title: "detailInfo.groups.tableTennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet5,
                title: "detailInfo.groups.tableTennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet5,
                title: "detailInfo.groups.tableTennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet5,
                title: "detailInfo.groups.tableTennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}

export function getTableTennisPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set1,
                title: "detailInfo.groups.tableTennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet1,
                title: "detailInfo.groups.tableTennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet1,
                title: "detailInfo.groups.tableTennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet1,
                title: "detailInfo.groups.tableTennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicapSet1,
                title: "detailInfo.groups.tableTennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalSet1,
                title: "detailInfo.groups.tableTennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet1,
                title: "detailInfo.groups.tableTennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    return result;
}

export function getTableTennisPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set2,
                title: "detailInfo.groups.tableTennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet2,
                title: "detailInfo.groups.tableTennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet2,
                title: "detailInfo.groups.tableTennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet2,
                title: "detailInfo.groups.tableTennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicapSet2,
                title: "detailInfo.groups.tableTennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalSet2,
                title: "detailInfo.groups.tableTennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet2,
                title: "detailInfo.groups.tableTennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    return result;
}

export function getTableTennisPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set3,
                title: "detailInfo.groups.tableTennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet3,
                title: "detailInfo.groups.tableTennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet3,
                title: "detailInfo.groups.tableTennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet3,
                title: "detailInfo.groups.tableTennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicapSet3,
                title: "detailInfo.groups.tableTennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalSet3,
                title: "detailInfo.groups.tableTennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet3,
                title: "detailInfo.groups.tableTennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    return result;
}

export function getTableTennisPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set4,
                title: "detailInfo.groups.tableTennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet4,
                title: "detailInfo.groups.tableTennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet4,
                title: "detailInfo.groups.tableTennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet4,
                title: "detailInfo.groups.tableTennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicapSet4,
                title: "detailInfo.groups.tableTennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalSet4,
                title: "detailInfo.groups.tableTennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet4,
                title: "detailInfo.groups.tableTennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    return result;
}

export function getTableTennisPeriod5Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tableTennis.all",
        rows: [
            {
                id: EDetailRow.tableTennis_x1_2Set5,
                title: "detailInfo.groups.tableTennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tableTennis_totalSet5,
                title: "detailInfo.groups.tableTennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tableTennis_handicapSet5,
                title: "detailInfo.groups.tableTennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet5,
                title: "detailInfo.groups.tableTennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tableTennis.handicaps",
        rows: [
            {
                id: EDetailRow.tableTennis_handicapSet5,
                title: "detailInfo.groups.tableTennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tableTennis.totals",
        rows: [
            {
                id: EDetailRow.tableTennis_totalSet5,
                title: "detailInfo.groups.tableTennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tableTennis_evenOddSet5,
                title: "detailInfo.groups.tableTennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}
