import { StartGameInfo } from "@sportaq/model/games/start-game-info";
import GameFrame from "@sportaq/vue/components/games/common/game-frame/GameFrame.vue";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { useRouter } from "vue-router";

const GAME_ID = "AVI_aviator";
export default {
    name: "AviatorGameFrame",
    components: {
        GameFrame
    },
    setup () {
        const gameInfo: StartGameInfo = {
            gameType: ECasinoGameType.Virtual,
            gameId: GAME_ID,
            demo: false
        };
        const router = useRouter();

        function onStartGameError (code: string) {
            if (code === "8136") {
                router.push({ path: "/deposit" }).then();
            }
        }
        return {
            gameInfo,
            onStartGameError
        };
    }
};
