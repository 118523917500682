import { CountDictionaryItem, DictionaryItem, ImageDictionaryItem } from "@sportaq/common/types/types";
import { HOT_PARTITIONS_COUNTRY_ID, INTERNATIONAL_COUNTRY_ID } from "@sportaq/common/consts/default-consts";
import {
    deleteFromSortedArray,
    insertOrUpdateIntoSortedSet,
    insertUniqueIntoSortedArray,
    nativeNumberComparator
} from "@sportaq/common/utils/arrays";
import { EventViewFilter } from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { markRaw } from "vue";
import { CountryKey } from "@sportaq/model/types/types";
import EventType from "@sportaq/common/enums/event-type";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { Cloneable } from "@sportaq/common/types/interfaces";
import {
    countriesOrder,
    partitionsOrder
} from "@sportaq/vuex/modules/betting/non-reactive-storage/dictionaries/item-order-supplier";
import { countries } from "@sportaq/model/consts/countries";
import { sportTypes } from "@sportaq/model/consts/sport-types";

export enum ERefreshCountryMenuType {
    Disable, Refresh, ClearAndRefresh
}

export class CountryMenuResolver {
    private static readonly HOT_PARTITION_COUNTRY: ImageDictionaryItem = {
        id: HOT_PARTITIONS_COUNTRY_ID,
        name: "",
        image: ""
    };

    private refreshCountryMenuType: ERefreshCountryMenuType = ERefreshCountryMenuType.Refresh;

    countryMenuItems: CountryMenuItem[] = markRaw([]);

    startBatchUpdate (filter: EventViewFilter, refreshCountryMenuType: ERefreshCountryMenuType) {
        this.refreshCountryMenuType = refreshCountryMenuType;
        if (refreshCountryMenuType === ERefreshCountryMenuType.ClearAndRefresh) {
            this.clear(filter);
        }
    }

    addEvent (filter: EventViewFilter, event: BettingEvent, scoreboardPageType: EScoreboardPageType) {
        if (this.refreshCountryMenuType === ERefreshCountryMenuType.Disable) {
            return;
        }
        if (filter.eventType && filter.sportType) {
            let countryId = event.partition.countryId;
            if (countryId <= 0) {
                countryId = INTERNATIONAL_COUNTRY_ID;
            }
            const country = countries.getById(countryId);
            this.addOrUpdateCountryMenuItem(countryId, country, filter, event, scoreboardPageType);
            if (scoreboardPageType === EScoreboardPageType.EVENT_LIST && partitionsOrder.has(event.partition.id)) {
                this.addOrUpdateCountryMenuItem(
                    HOT_PARTITIONS_COUNTRY_ID,
                    CountryMenuResolver.HOT_PARTITION_COUNTRY,
                    filter,
                    event,
                    scoreboardPageType);
            }
        }
    }

    private addOrUpdateCountryMenuItem (countryId: number, country: ImageDictionaryItem | undefined, filter: EventViewFilter, event: BettingEvent, scoreboardPageType: EScoreboardPageType) {
        insertOrUpdateIntoSortedSet(this.countryMenuItems, country,
            this.countryComparator(filter.sportType!), a => {
                const menuItem = CountryMenuItem.create(filter.eventType!, filter.sportType!, countryId, a);
                filter.countryFilter.addCountryToState(countryId);
                this.addMenuPartition(menuItem, event, scoreboardPageType, filter);
                return menuItem;
            }, a => this.addMenuPartition(a, event, scoreboardPageType, filter)
        );
    }

    private addMenuPartition (country: CountryMenuItem, event: BettingEvent, scoreboardPageType: EScoreboardPageType, filter: EventViewFilter) {
        const menuPartition = CountryMenuResolver.createMenuPartition(event, scoreboardPageType);
        const addPosition = country.addPosition(menuPartition, event.positionId);
        if (addPosition.hierarchyChanged) {
            filter.countryFilter.addPartitionToState(country.id, event.partition.id);
        }
    }

    private countryComparator (sportType: ESportType) {
        return function (a: CountryMenuItem, b: ImageDictionaryItem | undefined) {
            let result = 0;
            if (b) {
                const aIndex = countriesOrder.getOrderIndex(a.sportType, a.id);
                const bIndex = countriesOrder.getOrderIndex(sportType, b.id);
                result = aIndex - bIndex;
            }
            return result !== 0 ? result : a.name.localeCompare(b?.name ?? "");
        };
    }

    private static createMenuPartition (event: BettingEvent, scoreboardPageType: EScoreboardPageType) {
        const menuPartition: MenuPartition = {
            id: event.partition.id,
            name: event.partition.name,
            sportTypeName: scoreboardPageType === EScoreboardPageType.FAVOURITES ? sportTypes.getById(event.sportTypeId)?.name ?? "" : ""
        };
        return menuPartition;
    }

    removePosition (filter: EventViewFilter, positionId: number) {
        if (this.refreshCountryMenuType === ERefreshCountryMenuType.Disable) {
            return;
        }
        // Remove from HOT PARTITIONS country item
        let index = this.countryMenuItems.findIndex(value => value.id === HOT_PARTITIONS_COUNTRY_ID);
        if (index >= 0) {
            this.removePositionFromCountry(filter, this.countryMenuItems[index], index, positionId);
        }
        index = this.countryMenuItems.length - 1;
        while (index >= 0) {
            const item = this.countryMenuItems[index];
            if (this.removePositionFromCountry(filter, item, index, positionId)) {
                return;
            }
            index--;
        }
    }

    private removePositionFromCountry (filter: EventViewFilter, country: CountryMenuItem, index: number, positionId: number): boolean {
        const removePositionResult = country.removePosition(positionId);
        if (removePositionResult.removed) {
            if (country.count <= 0) {
                this.countryMenuItems.splice(index, 1);
                filter.countryFilter.removeCountryFromState(country.id);
            } else if (removePositionResult.hierarchyChangedPartitionId > 0) {
                filter.countryFilter.removePartitionFromState(removePositionResult.hierarchyChangedPartitionId);
            }
            return true;
        }
        return false;
    }

    endBatchUpdate (filter: EventViewFilter) {
        if (this.refreshCountryMenuType === ERefreshCountryMenuType.Disable) {
            return;
        }

        this.countryMenuItems = markRaw(this.countryMenuItems.map(v => v.clone()));
        filter.countryFilter.synchronizeState();
    }

    private clear (filter: EventViewFilter) {
        this.countryMenuItems = markRaw([]);
        filter.countryFilter.clear();
    }
}

export class CountryMenuItem extends CountryKey implements Cloneable<CountryMenuItem> {
    readonly name: string;
    readonly image: string;
    readonly partitions: PartitionCounter[] = [];

    private _count: number = 0;

    private constructor (eventType: EventType, sportType: ESportType, id: number, name: string, image: string, partitions: PartitionCounter[], count: number) {
        super(eventType, sportType, id);
        this.name = name;
        this.image = image;
        this.partitions = partitions;
        this._count = count;
    }

    static create (eventType: EventType, sportType: ESportType, countryId: number, country?: ImageDictionaryItem): CountryMenuItem {
        return new CountryMenuItem(eventType, sportType, countryId, country?.name ?? "", country?.image ?? "", [], 0);
    }

    addPosition (partition: MenuPartition, positionId: number): { hierarchyChanged: boolean } {
        const hierarchyChanged = insertOrUpdateIntoSortedSet(this.partitions, partition, this.partitionComparator,
            (a) => {
                const result = PartitionCounter.create(a);
                if (result.addPosition(positionId)) {
                    this._count = this._count + 1;
                }
                return result;
            },
            (a: PartitionCounter) => {
                if (a.addPosition(positionId)) {
                    this._count = this._count + 1;
                }
            }
        );
        return { hierarchyChanged };
    }

    private partitionComparator (a: PartitionCounter, b: MenuPartition): number {
        let result = a.sportTypeName.localeCompare(b.sportTypeName);
        if (result === 0) {
            const aIndex = partitionsOrder.getOrderIndex(a.id);
            const bIndex = partitionsOrder.getOrderIndex(b.id);
            result = aIndex - bIndex;
        }
        return result === 0 ? a.name.localeCompare(b.name) : result;
    }

    removePosition (positionId: number): { removed: boolean, hierarchyChangedPartitionId: number } {
        let index = this.partitions.length - 1;
        while (index >= 0) {
            const p = this.partitions[index];
            if (p.removePosition(positionId)) {
                this._count = this._count - 1;
                if (p.count <= 0) {
                    this.partitions.splice(index, 1);
                    return {
                        removed: true,
                        hierarchyChangedPartitionId: p.id
                    };
                } else {
                    return {
                        removed: true,
                        hierarchyChangedPartitionId: -1
                    };
                }
            }
            index--;
        }
        return {
            removed: false,
            hierarchyChangedPartitionId: -1
        };
    }

    get count () {
        return this._count;
    }

    clone (): CountryMenuItem {
        return new CountryMenuItem(this.eventType, this.sportType, this.id, this.name, this.image, this.partitions.map(v => v.clone()), this._count);
    }
}

interface MenuPartition extends DictionaryItem {
    sportTypeName: string;
}

export class PartitionCounter implements CountDictionaryItem, Cloneable<PartitionCounter> {
    private constructor (readonly id: number, readonly name: string, readonly sportTypeName: string, public count: number, readonly positions: number[]) {
    }

    static create (partition: MenuPartition): PartitionCounter {
        return new PartitionCounter(partition.id, partition.name, partition.sportTypeName, 0, []);
    }

    addPosition (positionId: number): boolean {
        const result = insertUniqueIntoSortedArray(this.positions, positionId, nativeNumberComparator);
        if (result) {
            this.count++;
        }
        return result;
    }

    removePosition (positionId: number): boolean {
        const result = deleteFromSortedArray(this.positions, positionId, nativeNumberComparator);
        if (result) {
            this.count--;
        }
        return result;
    }

    clone (): PartitionCounter {
        return new PartitionCounter(this.id, this.name, this.sportTypeName, this.count, this.positions);
    }
}
