<template>
    <div class="header">
        <div class="header-left">
            <router-link :to="{name: ROUTES.HOME.name}" class="header-logo" data-cy="home-route"></router-link>
        </div>
        <div class="header-account" :class="{'header-account-loggedIn': isLoggedIn}">
            <template v-if="!isLoggedIn">
                <router-link :to="{name: ROUTES.REGISTRATION.name}" class="button button-general"
                             id="registration-button" v-if="isRegistrationEnabled">
                    {{ $t("routes.registration").toUpperCase() }}
                </router-link>
                <a href="" class="button button-primary" @click.prevent="onModalDialogOpen(ModalDialogs.LoginDialog)">
                    {{ $t("webdesktop.loginDialog.menuButton").toUpperCase() }}
                </a>
            </template>
            <template v-else>
                <router-link class="button button-general button-deposit" :to="{name: ROUTES.DEPOSIT.name}">
                    {{$t("appMenuBar.deposit").toUpperCase()}}
                </router-link>
                <router-link class="button button-secondary button-bigger hide-bonus" :to="{name: ROUTES.DEPOSIT_NAV.name}">
                    <balance-component></balance-component>
                </router-link>
            </template>
            <router-link :class="{'display-bonus': bonus > 0, active: activeAccount === AccountType.USER_ACCOUNT_BONUS}" class="button button-secondary button-icon" :to="{name: ROUTES.BONUSES.name}">
                <img :style="{ [bonus && bonus > 0 ? 'height' : null]: '4.4vw' }"
                     :src="require(`@assets/img/mobile/${theme}/icon-bonus-box${activeAccount === AccountType.USER_ACCOUNT_BONUS? '-active': ''}.svg`)" alt="">
                <span v-if="bonus && bonus > 0" :class="{active: activeAccount === AccountType.USER_ACCOUNT_BONUS}">
                        {{ bonus }}
                    </span>
            </router-link>
        </div>
        <div class="drop-menu">
            <div class="drop-menu-overlay" @click="hideMenu"></div>
            <div class="drop-menu-content">
                <div class="drop-menu-head" :class="{'drop-menu-head-user': user[0] || user[1]}" v-if="user[0] || user[1]">
                    <router-link class="drop-menu-user" :to="{name: ROUTES.PROFILE.name}">
                        <span>
                            <img class="mr-5" :src="require(`@assets/img/mobile/${theme}/icon-person.svg`)" alt="">
                        </span>
                        {{`${user?.[0]} ${user?.[1]}`}}
                    </router-link>
                    <div class="drop-menu-close-wrap">
                        <div class="drop-menu-close" @click="hideMenu"></div>
                    </div>
                    <a class="drop-menu-exit" @click.prevent="logout">
                        <img :src="require(`@assets/img/mobile/${theme}/icon-exit.svg`)" alt="">
                    </a>
                    <div class="drop-menu-dots"><img src="/img/icon-dots.svg"></div>
                </div>
                <div class="drop-menu-head" v-else>
                    <div class="drop-menu-close" @click="hideMenu"></div>
                    <div class="drop-menu-dots"><img src="/img/icon-dots.svg"></div>
                </div>
                <div class="drop-menu-nav">
                    <div class="wrapper">
                        <div class="item deportes"
                             v-for="(item) in menuItems" :key="item.id"
                             :data-cy="`${item.dataCy}-menu-route`" @click="select(item)">
                            <img :src="require(`@assets/img/mobile/${theme}/${item.iconName}`)" alt="">
                            <div class="text">{{ $t(item.i18nKey) }}</div>
                        </div>
                    </div>
                </div>
                <div class="drop-menu-actions">
                    <router-link :to="{path: ROUTES.BONUSES.path}" class="item">
                        {{ $t("webdesktop.footer.bonuses") }}
                    </router-link>
                    <router-link :to="{path: ROUTES.ALL_STAKES.path}" class="item">
                        {{ $t("webdesktop.userMenu.stakes") }}
                    </router-link>
                    <router-link :to="{path: COMMON_ROUTES.RESULTS.path}" class="item">
                        {{ $t("routes.results") }}
                    </router-link>
                    <router-link :to="{path: ROUTES.DEPOSIT.path}" class="item">
                        {{ $t("appMenuBar.deposit") }}
                    </router-link>
                    <router-link v-if="isLoggedIn" :to="{path: ROUTES.WITHDRAWAL.path}" class="item">
                        {{ $t("appMenuBar.withdrawal") }}
                    </router-link>
                    <router-link v-if="isLoggedIn" :to="{path: ROUTES.IN_PLAY.path}" class="item">
                        {{ $t("mobile.menuBar.bets") }}
                    </router-link>
                    <router-link v-if="isLoggedIn" :to="{path: ROUTES.WITHDRAWAL_ORDERS.path}" class="item">
                        {{ $t("webdesktop.userMenu.finance") }}
                    </router-link>
                    <router-link :to="{path: ROUTES.RULES.path}" class="item">
                        {{ $t("webdesktop.footer.rules") }}
                    </router-link>
                    <a href="" class="item" v-if="isInstallationAvailable" @click.prevent="installApplication">
                        {{ $t("mobile.menuBar.installApplication") }}
                    </a>
                </div>
                <div class="drop-menu-actions">
                    <template v-if="supportedLocales.length > 1">
                        <div class="item modified">
                            <div class="label">
                                {{ $t("webdesktop.preferences.languages") }}
                            </div>
                            <a href="" class="button button-primary" v-for="(locale) in supportedLocales"
                               :key="locale" :class="{active: locale === currentLang}" @click.prevent="changeLang(locale)">
                                {{ locale }}
                            </a>
                        </div>
                    </template>
                    <div class="item modified">
                        <div class="label">{{ $t("webdesktop.preferences.quotations.caption") }}</div>
                        <a href="" class="button button-primary" :class="{active: quotationFormat === EQuotationFormat.Decimal}" @click.prevent="onQuotationFormatChange(EQuotationFormat.Decimal)">
                            {{ $t("webdesktop.preferences.quotations.decimal") }}
                        </a>
                        <a href="" class="button button-primary" :class="{active: quotationFormat === EQuotationFormat.English}" @click.prevent="onQuotationFormatChange(EQuotationFormat.English)">
                            {{ $t("webdesktop.preferences.quotations.english") }}
                        </a>
                        <a href="" class="button button-primary" :class="{active: quotationFormat === EQuotationFormat.American}" @click.prevent="onQuotationFormatChange(EQuotationFormat.American)">
                            {{$t("webdesktop.preferences.quotations.american")}}</a>
                    </div>
                    <div class="item modified" v-if="themes.length > 1">
                        <div class="label">{{ $t("webdesktop.preferences.theme.caption") }}</div>
                        <a href="" class="button button-primary" :class="{ active: theme === themes[0] }" @click.prevent="onThemeChange(themes[0])">
                            {{ $t("webdesktop.preferences.theme.light") }}
                        </a>
                        <a href="" class="button button-primary" :class="{ active: theme === themes[1] }" @click.prevent="onThemeChange(themes[1])">
                            {{ $t("webdesktop.preferences.theme.dark") }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <component :is="activeDialog" @close="onModalDialogClose" @openDialog="onModalDialogOpen"
               @submit="onModalDialogClose"/>
</template>

<script src="./app-menu-bar-component.ts"/>
