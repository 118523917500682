import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { Field, Form } from "vee-validate";
import { useChangePasswordDialogCommon } from "@sportaq/vue/views/web/profile/change-password-dialog-common";
import PasswordField from "@sportaq/vue/components/web/password-field/PasswordField.vue";

export default {
    name: "ChangePasswordDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field, PasswordField
    },
    setup (props: unknown, context: SetupContext) {
        return useChangePasswordDialogCommon(context);
    }
};
