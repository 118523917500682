import { Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { computed, PropType } from "vue";
import { QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { createQuotationLabel } from "@sportaq/vue/components/betting/utils/quotation-strings";
import {
    ScoreboardLineQuotationComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/common-scoreboard-line-quotation-component";

export default {
    props: {
        source: {
            required: false,
            type: Object as PropType<QuotationWrapper>
        },
        participants: {
            required: true,
            type: Object as PropType<Participant[]>
        },
        eventBlocked: {
            required: true,
            type: Boolean
        }
    },
    setup (props: ScoreboardLineQuotationComponent.Props) {
        const {
            diffsClass,
            isLocked,
            isSelected,
            quotationPresent,
            selectQuotation,
            quotationFormattedCoef
        } = ScoreboardLineQuotationComponent.useScoreboardLineQuotationComponent(props);

        const quotationLabel = computed(() => {
            if (props.source) {
                const participantSupplier: ParticipantsSupplier = {
                    participants: props.participants
                };
                return createQuotationLabel(
                    participantSupplier,
                    props.source.quotation.key
                );
            }
            return "";
        });

        return {
            diffsClass,

            quotationPresent,
            quotationLabel,
            isSelected,
            isLocked,
            quotationFormattedCoef,

            selectQuotation
        };
    }
};
