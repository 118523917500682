import EventType from "@sportaq/common/enums/event-type";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import {
    binarySearchIndex,
    deleteFromArray,
    insertIntoSortedArray,
    nativeComparator
} from "@sportaq/common/utils/arrays";
import { markRaw } from "vue";
import { CountryKey } from "@sportaq/model/types/types";
import { IdItem } from "@sportaq/common/types/types";
import { Comparable } from "@sportaq/common/types/interfaces";
import { BettingEvent } from "@sportaq/model/betting/events/event";

type RemoveCallback = (items: FavouriteItem[]) => void;

export enum EFavouritesOperation {
    ADD = 1, REMOVE = 2
}

export interface FavouriteItem {
    readonly eventType: EventType;
    readonly positionId: number;
    readonly countryId: number;
    readonly partitionId: number;
}

export interface FavouritesResolver {
    get items (): FavouriteItem[];

    isCountryFavourite (item: CountryKey): boolean;

    isPartitionFavourite (country: CountryKey, item: IdItem): boolean;

    isFavourite (positionId: number): boolean;

    toggleCountry (eventSupplier: EventSupplier, item: CountryKey, removeCallback: RemoveCallback): void;

    togglePartition (eventSupplier: EventSupplier, country: CountryKey, item: IdItem, removeCallback: RemoveCallback): void;

    togglePosition (eventSupplier: EventSupplier, eventType: EventType, positionId: number, removeCallback: RemoveCallback): void;

    removePosition (positionId: number): void;

    removeCountriesOrPartitions (countries: CountryKey[], partitionIds: number[]): void;

    receiveNewEvent (eventSupplier: EventSupplier, event: BettingEvent): void;

    clear (): void;
}

export class FavouritesResolverImpl implements FavouritesResolver {
    private countries: CountryKey[] = [];
    private invertedPartitions: PartitionItem[] = []; // array of partition items, which state isn't inherited from parent country
    private favourites: FavouriteItem[] = [];

    isCountryFavourite (item: CountryKey): boolean {
        return binarySearchIndex(this.countries, item, nativeComparator) >= 0;
    }

    isPartitionFavourite (country: CountryKey, item: IdItem): boolean {
        const isInverted = binarySearchIndex(this.invertedPartitions, item.id, partitionComparator) >= 0;
        const countryFavourite = this.isCountryFavourite(country);
        return isInverted ? !countryFavourite : countryFavourite;
    }

    isFavourite (positionId: number): boolean {
        const index = binarySearchIndex(this.favourites, positionId, positionComparator);
        return index >= 0;
    }

    toggleCountry (eventSupplier: EventSupplier, item: CountryKey, removeCallback: RemoveCallback): void {
        this.removePartitionsByCountry(item);
        this.countries = FavouritesResolverImpl.toggleEntry(
            this.countries,
            list => FavouritesResolverImpl.addCountry(list, item),
            (list, index) => FavouritesResolverImpl.removeCountry(list, item, index),
            removeCallback);
        const positions = Array.from(this.favourites);
        if (this.isCountryFavourite(item)) {
            eventSupplier.forEachBySportType(item.eventType, item.sportType, event => {
                if (event.partition.countryId === item.id) {
                    FavouritesResolverImpl.addPosition(eventSupplier, positions, item.eventType, event.positionId, event);
                }
                return true;
            });
        } else {
            deleteFromArray(positions, value => value.countryId === item.id, false);
        }
        this.favourites = positions;
    }

    togglePartition (eventSupplier: EventSupplier, country: CountryKey, item: IdItem, removeCallback: RemoveCallback) {
        this.invertedPartitions = FavouritesResolverImpl.toggleEntry(this.invertedPartitions,
            list => FavouritesResolverImpl.addPartition(list, country, item.id),
            (list, index) => FavouritesResolverImpl.removePartition(list, item.id, index),
            removeCallback);
        const positions = Array.from(this.favourites);
        if (this.isPartitionFavourite(country, item)) {
            eventSupplier.forEachBySportType(country.eventType, country.sportType, event => {
                if (event.partition.id === item.id) {
                    FavouritesResolverImpl.addPosition(eventSupplier, positions, country.eventType, event.positionId, event);
                }
                return true;
            });
        } else {
            deleteFromArray(positions, value => value.partitionId === item.id, false);
        }
        this.favourites = positions;
    }

    togglePosition (eventSupplier: EventSupplier, eventType: EventType, positionId: number, removeCallback: RemoveCallback): void {
        this.favourites = FavouritesResolverImpl.toggleEntry<FavouriteItem>(this.favourites,
            list => FavouritesResolverImpl.addPosition(eventSupplier, list, eventType, positionId),
            (list, index) =>
                index !== undefined
                    ? FavouritesResolverImpl.removePositionByIndex(list, index)
                    : FavouritesResolverImpl.removePositionByPosition(list, positionId),
            removeCallback);
    }

    receiveNewEvent (eventSupplier: EventSupplier, event: BettingEvent) {
        const shadow = Array.from(this.favourites);
        const countryKey = new CountryKey(event.eventType, event.sportTypeId, event.partition.countryId);
        let modified = false;
        if (this.isPartitionFavourite(countryKey, event.partition)) {
            const addToSortedArrayResult = FavouritesResolverImpl.addPosition(eventSupplier, shadow, event.eventType, event.positionId, event);
            modified = addToSortedArrayResult.added;
        }
        if (modified) {
            this.favourites = shadow;
        }
    }

    removePosition (positionId: number): void {
        FavouritesResolverImpl.removePositionByPosition(this.favourites, positionId);
    }

    removeCountriesOrPartitions (countries: CountryKey[], partitionIds: number[]) {
        const shadowCountries = Array.from(this.countries);
        const shadowPartitions = Array.from(this.invertedPartitions);
        let countryModified = false;
        let partitionsModified = false;
        if (countries.length > 0) {
            countryModified = true;
            for (const country of countries) {
                if (deleteFromArray(shadowPartitions, value => value.country.equals(country), false)) {
                    partitionsModified = true;
                }
                FavouritesResolverImpl.removeCountry(shadowCountries, country);
            }
        }
        for (const partitionId of partitionIds) {
            if (deleteFromArray(shadowPartitions, value => value.partitionId === partitionId, false)) {
                partitionsModified = true;
            }
        }
        if (countryModified) {
            this.countries = shadowCountries;
        }
        if (partitionsModified) {
            this.invertedPartitions = shadowPartitions;
        }
    }

    clear (): void {
        this.favourites = [];
    }

    get items (): FavouriteItem[] {
        return this.favourites;
    }

    private removePartitionsByCountry (country: CountryKey) {
        const tmp = Array.from(this.invertedPartitions);
        const modified = deleteFromArray(tmp, value => value.country.equals(country), false);
        if (modified) {
            this.invertedPartitions = tmp;
        }
    }

    // region toggle country
    private static addCountry (list: CountryKey[], item: CountryKey): AddToSortedArrayResult {
        const index = binarySearchIndex(list, item, nativeComparator);
        if (index < 0) {
            const newItem: CountryKey = markRaw(CountryKey.clone(item));
            insertIntoSortedArray(list, newItem, nativeComparator);
            return {
                added: true,
                duplicateIndex: undefined
            };
        } else {
            return {
                added: false,
                duplicateIndex: index
            };
        }
    }

    private static removeCountry (list: CountryKey[], item: CountryKey, index?: number) {
        if (index === undefined) {
            index = binarySearchIndex(list, item, nativeComparator);
        }
        if (index >= 0) {
            list.splice(index, 1);
        }
        return [];
    }

    // endregion

    // region toggle partition
    private static addPartition (list: PartitionItem[], country: CountryKey, partitionId: number): AddToSortedArrayResult {
        const index = binarySearchIndex(list, partitionId, partitionComparator);
        if (index < 0) {
            const newItem: PartitionItem = markRaw(new PartitionItem(country, partitionId));
            insertIntoSortedArray(list, newItem, nativeComparator);
            return {
                added: true,
                duplicateIndex: undefined
            };
        } else {
            return {
                added: false,
                duplicateIndex: index
            };
        }
    }

    private static removePartition (list: PartitionItem[], partitionId: number, index?: number) {
        if (index === undefined) {
            index = binarySearchIndex(list, partitionId, partitionComparator);
        }
        if (index >= 0) {
            list.splice(index, 1);
        }
        return [];
    }

    // endregion

    // region toggle position
    private static addPosition (eventSupplier: EventSupplier, list: FavouriteItem[], eventType: EventType, positionId: number, event?: BettingEvent): AddToSortedArrayResult {
        const index = binarySearchIndex(list, positionId, positionComparator);
        if (index < 0) {
            const currentEvent = event || eventSupplier.getOptionalEvent(eventType, positionId);
            if (currentEvent) {
                const favouriteItem: FavouriteItem = markRaw({
                    eventType,
                    positionId,
                    eventId: currentEvent.eventId,
                    countryId: currentEvent.partition.countryId,
                    partitionId: currentEvent.partition.id
                });
                insertIntoSortedArray(list, favouriteItem, comparator);
                return {
                    added: true,
                    duplicateIndex: undefined
                };
            }
            return {
                added: false,
                duplicateIndex: undefined
            };
        } else {
            return {
                added: false,
                duplicateIndex: index
            };
        }
    }

    private static removePositionByIndex (list: FavouriteItem[], index: number): FavouriteItem[] {
        if (index >= 0) {
            return list.splice(index, 1);
        }
        return [];
    }

    private static removePositionByPosition (list: FavouriteItem[], positionId: number): FavouriteItem[] {
        const index = binarySearchIndex(list, positionId, positionComparator);
        return this.removePositionByIndex(list, index);
    }

    // endregion

    private static toggleEntry<T> (list: T[], add: (list: T[]) => AddToSortedArrayResult, remove: (list: T[], index?: number) => FavouriteItem[], removeFromPeriodsViewCallback: RemoveCallback): T[] {
        const result = Array.from(list);
        const itemsToRemove: FavouriteItem[] = [];
        const addResult = add(result);
        if (!addResult.added) {
            const rm: FavouriteItem[] = remove(result, addResult.duplicateIndex);
            rm.forEach(value => itemsToRemove.push(value));
        }

        if (itemsToRemove.length > 0) {
            removeFromPeriodsViewCallback(itemsToRemove);
        }
        return result;
    }
}

interface AddToSortedArrayResult {
    added: boolean;
    duplicateIndex?: number;
}

class PartitionItem implements Comparable<PartitionItem> {
    readonly country: CountryKey;

    constructor (country: CountryKey, readonly partitionId: number) {
        this.country = CountryKey.clone(country);
    }

    compare (o: PartitionItem): number {
        return this.partitionId - o.partitionId;
    }
}

const comparator = (a: FavouriteItem, b: FavouriteItem) => a.positionId - b.positionId;
const positionComparator = (a: FavouriteItem, positionId: number) => a.positionId - positionId;
const partitionComparator = (a: PartitionItem, partitionId: number) => a.partitionId - partitionId;
