import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { EGameTabs } from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import FooterGroup from "@sportaq/vue/components/web/footer/footer-group/FooterGroup.vue";
import { computed, onMounted, watch } from "vue";
import { useWebSettings } from "@sportaq/services/web";
import { useRoute, useRouter } from "vue-router";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { useWebEnvironment } from "@sportaq/services/web/environment/web-environment";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useRootStore } from "@sportaq/vuex/index";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";

export default {
    name: "Footer",
    components: {
        FooterGroup
    },
    setup () {
        const settings = useWebSettings();
        const environment = useWebEnvironment();
        const isAboutPagePresent = environment.isAboutPagePresent;
        const isCasinoEnabled = isServiceEnabled(settings, EGameService.Casino);
        const isVirtualEnabled = isServiceEnabled(settings, EGameService.Virtual);
        const store = useWebStore();
        const rootStore = useRootStore();
        const paymentSystemIcons = computed(() => {
            const countryId = rootStore.userSharedInfo.countryId;
            const ps = (store.user !== undefined ? store.user.paymentSystems : settings.pointSettings.paymentSystems).map(i => i.paymentSystemType);
            const result = new Set<string>();
            for (const p of ps) {
                switch (p) {
                    case PaymentSystems.DEPOSIT_MONNET_CARD: {
                        ["visa", "mastercard", "am-express"].forEach(s => result.add(s));
                        break;
                    }
                    case PaymentSystems.DEPOSIT_MONNET:
                    case PaymentSystems.DEPOSIT_MONNET_CASH: {
                        ((countryId === 62)
                            ? ["ec_bakan", "ec_bancobarrio", "ec_bancoguavaquil", "ec_mivecino", "ec_redactiva", "ec_tia"]
                            : ["bcp", "interbank", "safetypay", "bbva",
                                "scotiabank"]).forEach(s => result.add(s));
                        break;
                    }
                    case PaymentSystems.DEPOSIT_TUPAY: {
                        result.add("tupay");
                        break;
                    }
                    case PaymentSystems.DEPOSIT_METHOD_YAPE: {
                        result.add("yape");
                        break;
                    }
                    case PaymentSystems.DEPOSIT_METHOD_LUKITA: {
                        result.add("lukita");
                        break;
                    }
                    case PaymentSystems.DEPOSIT_METHOD_TUNKI: {
                        result.add("tunki");
                        break;
                    }
                    case PaymentSystems.DEPOSIT_METHOD_SYSTEM_CASH: {
                        result.add("i-pay");
                        break;
                    }
                }
            }
            return result;
        });
        const route = useRoute();
        const router = useRouter();
        let jdivElement: Element | null;

        if (isAboutPagePresent) {
            onMounted(() => {
                let tryCount = 0;
                const tryInitWidget = function () {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    // eslint-disable-next-line camelcase
                    if (typeof apg_f10d5009_4f22_46fb_8e0d_6678ac15550f === "undefined") {
                        tryCount++;
                        if (tryCount < 10) {
                            setTimeout(tryInitWidget, tryCount * 100);
                        }
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        // eslint-disable-next-line camelcase
                        (<unknown>apg_f10d5009_4f22_46fb_8e0d_6678ac15550f).init();
                    }
                };
                setTimeout(tryInitWidget, 10);
            });
        }
        if (environment.showChat === undefined || environment.showChat === "true") {
            const scriptElement = document.createElement("script");
            scriptElement.src = "//code.jivosite.com/widget/A3ncqpGGkA";
            scriptElement.async = true;
            document.head.appendChild(scriptElement);
            if (APP_TYPE.value === EApplicationType.webMobile) {
                const findJivo = function () {
                    const jdivs = document.body.getElementsByTagName("jdiv");
                    if (jdivs.length === 0) {
                        setTimeout(findJivo, 500);
                    } else {
                        jdivElement = jdivs.item(0);
                        changeJivoVisibility();
                    }
                };
                setTimeout(findJivo, 500);
            }
            function changeJivoVisibility () {
                if (jdivElement) {
                    if (route.name !== "home" && route.path.toString() !== "/" && route.path.toString() !== "") {
                        jdivElement.setAttribute("style", "display: none");
                    } else {
                        jdivElement.setAttribute("style", "");
                    }
                }
            }
            watch(router.currentRoute, changeJivoVisibility);
        }

        return {
            COMMON_ROUTES,
            EGameTabs,
            isCasinoEnabled,
            isVirtualEnabled,
            isAboutPagePresent,
            paymentSystemIcons,
            isFeedbackPresent: settings.brandType !== ECompileBrandType.certabet
        };
    }
};
