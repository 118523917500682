import { SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { useMiscellaneousParamsProvider, useSettings } from "@sportaq/services/index";
import {
    createQuotationLabel,
    createQuotationTitleByQuotationId,
    formatQuotationCoef
} from "@sportaq/vue/components/betting/utils/quotation-strings";
import EventType from "@sportaq/common/enums/event-type";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export namespace SimpleStakeRowComponent {
    export interface Props {
        stake: SimpleStakeDTO;
        standalone: boolean;
    }

    export function useSimpleStakeRowComponent (props: Props) {
        const i18n = useI18n();
        const dateTimeFormatter = useMiscellaneousParamsProvider().dateTimeFormatter;
        const quotationTitle = computed(() => {
            return createQuotationTitleByQuotationId(i18n, props.stake.event.sportType, props.stake, props.stake.key.quotationKey);
        });
        const settings = useSettings();
        const quotationFormat = settings.quotationFormat;
        const quotationLabel = computed(() => createQuotationLabel(props.stake, props.stake.key.quotationKey));
        const maxWin = computed(() => props.standalone ? (props.stake.coef * props.stake.amount) : undefined);
        const isLive = computed(() => props.stake.event.eventType === EventType.LIVE);
        const eventDate = computed(() => dateTimeFormatter.formatDate(props.stake.event.startTime, true));
        const eventTime = computed(() => dateTimeFormatter.formatTime(props.stake.event.startTime));
        const formattedCoef = computed(() => formatQuotationCoef(quotationFormat, props.stake.coef));
        return {
            quotationTitle,
            quotationLabel,
            maxWin,
            isLive,
            eventDate,
            eventTime,
            formattedCoef
        };
    }
}
