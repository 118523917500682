import { useCasinoStakeListCommon } from "@sportaq/vue/views/web/history/casino-stake-list-common";

export default {
    name: "CasinoStakeList",
    props: {
        onlyCalculated: { required: false, type: Boolean }
    },
    setup () {
        return useCasinoStakeListCommon();
    }
};
