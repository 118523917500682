export function roundAccurately (value: number, decimalPlaces: number) {
    return Number(Math.round(+(value + "e" + decimalPlaces)) + "e-" + decimalPlaces);
}

export function factorial (num: number): bigint {
    if (num === 0 || num === 1) {
        return BigInt(1);
    }
    let result = BigInt(num);
    let counter = BigInt(num - 1);
    while (counter > 0) {
        result = result * counter;
        counter--;
    }
    return result;
}

export function numberOfCombinations (n: number, k: number): number {
    const result = factorial(n) / (factorial(k) * factorial(n - k));
    return Number(result);
}

export function ceil (value: number, scale: number) {
    const pow = Math.pow(10, scale);
    return Math.ceil(value * pow) / pow;
}

export function floor (value: number, scale: number) {
    const pow = Math.pow(10, scale);
    return Math.floor(value * pow) / pow;
}

export function round (value: number, scale: number) {
    const pow = Math.pow(10, scale);
    return Math.round(value * pow) / pow;
}

export function formatVoucherNumber (str: string): string {
    return str.substr(0, 3) + " " + str.substr(3, 3) + " " +
        str.substr(6, 3) + " " + str.substr(9, 3);
}
