import { useGamesContent } from "@sportaq/vue/components/games/common/content-section/content-section-functions";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import {
    ConstantItemsPanelSelectedResult
} from "@sportaq/vue/components/common/constant-items-panel/constant-items-panel-component";
import { EGameTabs } from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";
import { SetupContext } from "vue";

export namespace VirtualGamesComponent {
    export function useVirtualGamesComponent (context: SetupContext) {
        const {
            store,
            selectedItem,
            currentGame,
            forceOpenSelectedTabByClick,

            isDirectLink,
            directLinkGame,

            setMaskFilter,
            commonSelectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        } = useGamesContent(ECasinoGameType.Virtual, context);

        async function selectTopMenu (result: ConstantItemsPanelSelectedResult, auto: boolean) {
            await commonSelectTopMenu(result, auto);
            const tab: EGameTabs = result.selectedId;

            switch (tab) {
                case EGameTabs.All: {
                    store.updateGameFilter({
                        changeClassifierMask: {
                            type: "unset"
                        }
                    });
                    break;
                }
                case EGameTabs.Popular: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.POPULAR);
                    break;
                }
                case EGameTabs.Favourites: {
                    store.favouriteMode = true;
                    break;
                }
                case EGameTabs.Football: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.FOOTBALL);
                    break;
                }
                case EGameTabs.Tennis: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.TENNIS);
                    break;
                }
                case EGameTabs.Horse: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.HORSE_RACING);
                    break;
                }
                case EGameTabs.Dogs: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.DOG_RACING);
                    break;
                }
                case EGameTabs.Moto: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.MOTOS);
                    break;
                }
                case EGameTabs.Lottery: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.LOTTERY);
                    break;
                }
                case EGameTabs.Fighting: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.FIGHTING);
                    break;
                }
                case EGameTabs.Casual: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.CASUAL);
                    break;
                }
                case EGameTabs.Crash: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.CRASH);
                    break;
                }
                case EGameTabs.Fishing: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.FISHING);
                    break;
                }
                case EGameTabs.Scratch: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.SCRATCH);
                    break;
                }
                case EGameTabs.Basketball: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.BASKETBALL);
                    break;
                }
                case EGameTabs.Keno: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.KENO);
                    break;
                }
                case EGameTabs.Bingo: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.BINGO);
                    break;
                }
                case EGameTabs.Hockey: {
                    setMaskFilter(ECasinoGameClassifier.VIRTUAL_GAME | ECasinoGameClassifier.HOCKEY);
                    break;
                }
                case EGameTabs.Providers: {
                    if (result.selectedSubId > 0) {
                        store.updateGameFilter({
                            changeProvider: {
                                type: "set",
                                value: result.selectedSubId
                            }
                        });
                    }
                }
            }
        }

        return {
            selectedItem,
            currentGame,
            forceOpenSelectedTabByClick,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        };
    }
}
