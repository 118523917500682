import { onUnmounted } from "vue";

interface KeyHandler {
    escKeyHandler: () => void,
    enterKeyHandler?: () => void
}

const handlers: KeyHandler[] = [];

export function useKeyHandler (escKeyHandler: () => void, enterKeyHandler?: () => void) {
    handlers.push({ escKeyHandler, enterKeyHandler });

    onUnmounted(() => {
        handlers.pop();
    });
}

const handler = (e: KeyboardEvent) => {
    if (handlers.length === 0) {
        return;
    }
    const h = handlers[handlers.length - 1];
    switch (e.key) {
        case "Escape": {
            h.escKeyHandler();
            e.preventDefault();
            break;
        }
        case "Enter": {
            if (h.enterKeyHandler) {
                h.enterKeyHandler();
            }
            e.preventDefault();
            break;
        }
    }
};

function backButtonHandler (e: Event) {
    if (handlers.length === 0) {
        return;
    }
    const h = handlers[handlers.length - 1];
    h.escKeyHandler();
    e.preventDefault();
}

document.addEventListener("keydown", handler);
document.addEventListener("backbutton", backButtonHandler);