<template>
    <recycle-scroller
        class="scoreboard-body"
        :key-field="'id'"
        :items="periods"
        :buffer="buffer"
        @resize="onResize"
        data-cy="scoreboard-body">
        <template #before>
            <scoreboard-group-events/>
        </template>
        <template v-slot="{ item }">
            <mobile-scoreboard-line :source="item" :extraProps="$props"/>
        </template>
    </recycle-scroller>
</template>

<script src="./mobile-scoreboard-body-component.ts"/>
