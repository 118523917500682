import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { computed, onMounted, PropType, ref, shallowRef, watch } from "vue";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { BettingEvent, Participant } from "@sportaq/model/betting/events/event";
import { LOCKED_QUOTATION, Quotation, QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import StandardScoreboardLineQuotation
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/standard/StandardScoreboardLineQuotation.vue";
import { useI18n } from "vue-i18n";
import { EQuotation } from "@sportaq/common/consts/quotation-consts";
import EventType from "@sportaq/common/enums/event-type";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { Localization } from "@sportaq/i18n/interfaces/interfaces";

interface Props {
    source: PeriodSortInfo;
}

const GROUP_LOCKED_QUOTATION_WRAPPER: QuotationWrapper = {
    eventType: EventType.PRE_MATCH,
    quotation: LOCKED_QUOTATION
};

export default {
    props: {
        source: {
            required: true,
            type: Object as PropType<PeriodSortInfo>
        }
    },
    components: {
        StandardScoreboardLineQuotation
    },
    setup (props: Props) {
        const i18n = useI18n();
        const scoreboardStore = useScoreboardStore();
        const eventSupplier = useEventSupplier();
        const nonReactiveEvent = eventSupplier.getEvent(props.source.eventType, props.source.positionId);
        const event = shallowRef(nonReactiveEvent);
        const groupTable = shallowRef<GroupTable>({
            rows: [],
            headers: []
        });
        const active = ref(true);

        const isFavorite = computed(() => scoreboardStore.isFavourite(props.source.positionId));
        const participants = computed(() => getParticipantList(event.value));

        function toggleActive () {
            active.value = !active.value;
        }

        function toggleFavorite () {
            scoreboardStore.toggleFavouriteItem(props.source.eventType, props.source.positionId);
        }

        function updateTable () {
            groupTable.value = buildGroupTable(i18n, event.value);
        }

        onMounted(() => {
            updateTable();
        });

        watch(() => props.source, (value) => {
            event.value = eventSupplier.getEvent(value.eventType, value.positionId);
            updateTable();
        });

        return {
            event,
            active,
            isFavorite,
            groupTable,
            participants,

            toggleActive,
            toggleFavorite
        };
    }
};

function buildGroupTable (i18n: Localization, event: BettingEvent): GroupTable {
    const result: GroupTable = {
        rows: [],
        headers: []
    };
    const participants = getParticipantList(event);
    for (const participant of participants) {
        const row = new GroupEventTableRow(participant);
        result.rows.push(row);
    }
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_LEAVE_THE_GROUP, i18n.t("betting.event.groupEvents.leaveTheGroup"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_STAY_THE_GROUP, i18n.t("betting.event.groupEvents.stayTheGroup"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_WINNER, i18n.t("betting.event.groupEvents.winner"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_FINISH_THE_RACE_YES, i18n.t("betting.event.groupEvents.finishTheRaceYes"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_FINISH_THE_RACE_NO, i18n.t("betting.event.groupEvents.finishTheRaceNo"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_FASTEST_LAP, i18n.t("betting.event.groupEvents.fastestLap"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_FIRST_PITSTOP, i18n.t("betting.event.groupEvents.firstPitstop"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_LAST_PLACE, i18n.t("betting.event.groupEvents.lastPlace"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_WILL_QUALIFY_TO_FINAL, i18n.t("betting.event.groupEvents.willQualifyToFinal"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_WILL_QUALIFY_TO_FINAL_1_2, i18n.t("betting.event.groupEvents.willQualifyToFinal1_2"));
    fillSingleQuotation(event, result, EQuotation.GROUP_EVENT_WILL_QUALIFY_TO_FINAL_1_4, i18n.t("betting.event.groupEvents.willQualifyToFinal1_4"));
    fillMultiplyQuotationsByP1(i18n, event, result, EQuotation.GROUP_EVENT_PLACE_IN_GROUP, "betting.event.groupEvents.placeInGroup");
    result.rows.sort((a, b) => {
        if ((a.quotations.length === 0) || (b.quotations.length === 0)) {
            return 0;
        }
        const value1 = a.quotations[0].quotation.id >= 0 ? a.quotations[0].quotation.coef.numberValue : Number.MAX_VALUE;
        const value2 = b.quotations[0].quotation.id >= 0 ? b.quotations[0].quotation.coef.numberValue : Number.MAX_VALUE;
        return value1 - value2;
    });
    return result;
}

function fillSingleQuotation (event: BettingEvent, groupTable: GroupTable, quotationId: EQuotation, header: string) {
    const quotations = event.quotations.getQuotationsByQuotationId(quotationId);
    addQuotationColumn(quotations, groupTable, header);
}

function fillMultiplyQuotationsByP1 (i18n: Localization, event: BettingEvent, groupTable: GroupTable, quotationId: EQuotation, header: string) {
    const quotations = event.quotations.getQuotationsByQuotationId(quotationId);
    const columnsByP1 = new Map<number, Quotation[]>();
    for (const quotation of quotations) {
        const p1 = quotation.key.p1.toInt();
        if (p1) {
            let list = columnsByP1.get(p1);
            if (!list) {
                list = [];
                columnsByP1.set(p1, list);
            }
            list.push(quotation);
        }
    }
    columnsByP1.forEach((value, key) => {
        addQuotationColumn(value, groupTable, i18n.t(header, { p1: key }));
    });
}

function addQuotationColumn (quotations: Quotation[], groupTable: GroupTable, header: string) {
    if (quotations.length > 0) {
        groupTable.headers.push(header);
        for (const row of groupTable.rows) {
            const quotation = quotations.find(value => value.key.p1Id === row.participant.id);
            if (quotation) {
                row.quotations.push({
                    eventType: EventType.PRE_MATCH,
                    quotation
                });
            } else {
                row.quotations.push(GROUP_LOCKED_QUOTATION_WRAPPER);
            }
        }
    }
}

interface GroupTable {
    headers: string[];
    rows: GroupEventTableRow[];
}

class GroupEventTableRow {
    public quotations: QuotationWrapper[] = [];

    constructor (readonly participant: Participant) {
    }
}
