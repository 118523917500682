import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { CountImageDictionaryItem, Ref } from "@sportaq/common/types/types";
import { ESportType, getNameBySportType, getSportTypeByName } from "@sportaq/common/enums/sport-type";
import { getEventTypeName } from "@sportaq/common/enums/event-type";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { onBeforeRouteLeave, onBeforeRouteUpdate, RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import { computed, ref, watch } from "vue";
import { sportTypes } from "@sportaq/model/consts/sport-types";
import { UNSELECTED } from "@sportaq/model/betting/view/scoreboard-selection";

export function useSportTypeFilter () {
    const bettingStore = useScoreboardStore();
    const router = useRouter();
    const route = useRoute();

    const sportTypeItems = computed(() => {
        return bettingStore.getSportTypes()
            .filter(value => value.count > 0)
            .map(value => {
                const sport = sportTypes.getById(value.id);
                if (sport) {
                    const countItem: CountImageDictionaryItem = {
                        id: sport.id,
                        name: sport.name,
                        image: sport.image,
                        count: value.count
                    };
                    return countItem;
                }
                throw new Error(`Sport type ${value.id} isn't supported`);
            });
    });
    const autoSelect = computed(() => bettingStore.scoreboardPageType === EScoreboardPageType.EVENT_LIST);

    const sportType = recognizeSportTypeByRoute(route);
    const selectedIndex: Ref<number | undefined> = ref(sportType);
    setSportTypeFilter(sportType);

    async function sportTypeSelected (sportType: ESportType) {
        const eventType = bettingStore.getEventsViewFilter().eventType;
        if (eventType) {
            const eventTypeName = getEventTypeName(eventType);
            const nameBySportType = getNameBySportType(sportType);
            if (nameBySportType) {
                bettingStore.setScoreboardSelection(route, UNSELECTED);
                await router.push({ path: `/${eventTypeName}/${nameBySportType}` });
            }
        }
    }

    function setSportTypeFilter (sportTypeId: ESportType | undefined) {
        if (sportTypeId && sportTypeId > 0) {
            bettingStore.scoreboardPageType = EScoreboardPageType.EVENT_LIST;
            bettingStore.updateEventsFilter(
                {
                    changeSportTypeId: {
                        type: "set",
                        value: sportTypeId
                    }
                }
            );
            selectedIndex.value = sportTypeId;
        } else {
            selectedIndex.value = -1;
            bettingStore.updateEventsFilter(
                {
                    changeSportTypeId: { type: "unset" }
                }
            );
        }
    }

    function setSportTypeByRoute (to: RouteLocationNormalized): boolean | { path: string } {
        const value = to.params.sportType;
        if (value) {
            const sportType = getSportTypeByName(value as string);
            setSportTypeFilter(sportType);
        } else {
            if (to.path.endsWith(COMMON_ROUTES.SPORT.path) || to.path.endsWith(COMMON_ROUTES.LIVE.path)) {
                const currentSportType = bettingStore.getEventsViewFilter().sportType;
                if (currentSportType && currentSportType > 0) {
                    const nameBySportType = getNameBySportType(currentSportType);
                    if (nameBySportType) {
                        return { path: `${to.path}/${nameBySportType}` };
                    }
                }
            }
        }
        return true;
    }

    onBeforeRouteUpdate(to => {
        return setSportTypeByRoute(to);
    });

    onBeforeRouteLeave(to => {
        return setSportTypeByRoute(to);
    });

    watch(() => bettingStore.scoreboardPageType, (value) => {
        if (value === EScoreboardPageType.FAVOURITES) {
            selectedIndex.value = -1;
        }
    });

    return {
        sportTypeItems,
        selectedIndex,
        autoSelect,

        sportTypeSelected
    };
}

function recognizeSportTypeByRoute (route: RouteLocationNormalized): ESportType | undefined {
    const sportTypeStr = route.params.sportType as string | undefined;
    if (sportTypeStr) {
        return getSportTypeByName(sportTypeStr);
    }
    return undefined;
}
