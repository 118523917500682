import MobileVirtualGames from "@/components/games/virtual/MobileVirtualGames.vue";
import { useCasinoPageTitle } from "@sportaq/vue/components/web/casino-page-title";
import { onMounted } from "vue";

export default {
    components: {
        MobileVirtualGames
    },
    setup () {
        const { updateTitle } = useCasinoPageTitle("casino");
        onMounted(() => {
            updateTitle();
        });
    }
};
