import { ROUTES } from "@/router/routes-consts";
import { useNavigationViewCommon } from "@sportaq/vue/views/web/navigation-view-common";

export default {
    name: "NavigationView",
    setup () {
        const {
            theme,
            sportBalance,
            isRaceAvailable,
            sportBalanceAvailable
        } = useNavigationViewCommon();
        return {
            theme,
            sportBalance,
            isRaceAvailable,
            sportBalanceAvailable,
            ROUTES
        };
    }
};
