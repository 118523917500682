import { useWebRestService } from "@sportaq/services/web";
import { computed, ref } from "vue";
import { EWithdrawalStatus, WithdrawalOrderEx } from "@sportaq/model/web/withdrawal-order";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { EUserRole, hasRole } from "@sportaq/model/common/user-accounts";

export function useWithdrawalOrdersCommon () {
    const restService = useWebRestService();
    const requestPending = ref(false);
    const withdrawalOrders = ref<WithdrawalOrderEx[]>([]);
    const guidToCancel = ref<string>();
    const i18n = useI18n();
    const itemsPerPageRef = ref<number>(10);
    const currentPage = ref<number>(1);
    const store = useWebStore();

    const withdrawalOrdersPaged = computed(() => {
        const offset = (currentPage.value - 1) * itemsPerPageRef.value;
        return withdrawalOrders.value.slice(offset).slice(0, itemsPerPageRef.value);
    });

    function itemsPerPageChanged (value: number) {
        itemsPerPageRef.value = value;
    }

    function currentPageChanged (value: number) {
        currentPage.value = value;
    }

    function dateChanged (startDate: Date, endDate: Date) {
        endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
        startDate.setHours(0, 0, 0, 0);
        requestPending.value = true;
        restService.getWithdrawalOrders(startDate, endDate).then(r => {
            withdrawalOrders.value = r;
            requestPending.value = false;
        });
    }

    function cancelOrder (orderGUID: string) {
        guidToCancel.value = orderGUID;
    }

    function onCancelConfirmed () {
        if (guidToCancel.value) {
            const g = guidToCancel.value;
            restService.cancelWithdrawal(g).then(() => {
                showMessageBox(i18n.t("webdesktop.withdrawalOrders.cancelSuccess"));
                const order = withdrawalOrders.value.find(i => i.GUID === g);
                if (order) {
                    order.status = EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_USER_CANCELLED;
                }
            });
            guidToCancel.value = undefined;
        }
    }

    function getDescription (status: EWithdrawalStatus) {
        return "webdesktop.withdrawalOrders.statusText." + status;
    }

    return {
        requestPending,
        withdrawalOrders,
        EWithdrawalStatus,
        guidToCancel,
        withdrawalOrdersPaged,
        itemsPerPageRef,
        currentPage,
        getDescription,
        itemsPerPageChanged,
        currentPageChanged,
        onCancelConfirmed,
        dateChanged,
        cancelOrder
    };
}
