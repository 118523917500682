export class CommonSettings {
    private _restServerAddress: string;
    private _appCode: string;

    constructor (
        appCode: string,
        readonly partnerCode: string,
        restServerAddress: string,
        readonly base64Logo: string) {
        this._restServerAddress = restServerAddress;
        this._appCode = appCode;
    }

    get restServerAddress (): string {
        return this._restServerAddress;
    }

    set restServerAddress (address: string) {
        this._restServerAddress = address;
    }

    get useSSL (): boolean {
        return true;
    }

    get appCode (): string {
        return this._appCode;
    }

    set appCode (value: string) {
        this._appCode = value;
    }

    getStreamServerAddress (locale: string): string {
        return this._restServerAddress + "/" + locale + "/service";
    }
}
