// Calculate System coef: n - elements count, k - count of selected. Example: from 3 to 2 (n = 3, k =2)
import { numberOfCombinations, round } from "@sportaq/common/utils/number-utils";
import { CoefHolder, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import { getParticipantList } from "@sportaq/model/common/participants-functions";

export function transformQuotationCoefByBonus (coefHolder: CoefHolder, bonus: number): CoefHolder {
    if (coefHolder.coef <= 1) {
        return { coef: 0 };
    }
    if (bonus === 1) {
        return coefHolder;
    }
    return { coef: round(1 + (coefHolder.coef - 1) * bonus, BIG_DECIMAL_SCALES.SYSTEM_CALCULATED_COEF) };
}

export function roundCoef (coef: number) : number {
    return round(coef, BIG_DECIMAL_SCALES.SYSTEM_CALCULATED_COEF);
}

export function calculateSystemCoef (ordinaryCoefList: CoefHolder[], k: number, bonus: number): number {
    const n = ordinaryCoefList.length;
    if (k > n) {
        throw new Error(`Can't group from ${n} to ${k} elements`);
    }
    const coefs = bonus !== 1 ? ordinaryCoefList.map(value => transformQuotationCoefByBonus(value, bonus)) : ordinaryCoefList;
    const countOfCombinations = numberOfCombinations(n, k);
    const maxEventIndex = n - 1;
    const maxExpressEventIndex = k - 1;
    let combination = 0;
    let currentCombination = [];
    for (let i = 0; i <= maxExpressEventIndex; i++) {
        currentCombination.push(i);
    }
    let combinationsCoefSum = 0;
    while (combination < countOfCombinations) {
        let combinationItemsCoef = 1;
        for (let i = 0; i < k; i++) {
            combinationItemsCoef *= coefs[currentCombination[i]].coef;
        }
        combinationsCoefSum += combinationItemsCoef;
        combination++;

        if (combination < countOfCombinations) {
            currentCombination = loadNewCombination(maxExpressEventIndex, maxEventIndex, currentCombination, maxExpressEventIndex);
        }
    }
    return combinationsCoefSum / countOfCombinations;
}

export function calculateExpressCoef (ordinaryCoefList: CoefHolder[], bonus: number): number {
    let result = 1;
    const coefs = bonus !== 1 ? ordinaryCoefList.map(value => transformQuotationCoefByBonus(value, bonus)) : ordinaryCoefList;
    for (const item of coefs) {
        result *= item.coef;
    }
    return result;
}

function calculateExpressMaxWin (expressCoef: number, amount: number, maxExpressCoef: number): number {
    const coef = Math.min(expressCoef, maxExpressCoef);
    return coef * amount;
}

function calculateSystemMaxWin (systemCoef: number, amount: number, maxSystemCoef: number): number {
    const coef = Math.min(systemCoef, maxSystemCoef);
    return coef * amount;
}

export function calculateCombinedMaxWin (express: boolean, coef: number, amount: number | undefined, maxCoef: number): number {
    if (amount) {
        if (express) {
            return calculateExpressMaxWin(coef, amount, maxCoef);
        } else {
            return calculateSystemMaxWin(coef, amount, maxCoef);
        }
    }
    return 0;
}

function loadNewCombination (i: number, maxEventIndex: number, combination: number[], maxExpressEventIndex: number): number[] {
    const currentCombination: number[] = Object.assign([], combination);
    if (currentCombination[i] >= maxEventIndex - (maxExpressEventIndex - i)) {
        return loadNewCombination(i - 1, maxEventIndex, currentCombination, maxExpressEventIndex);
    } else {
        currentCombination[i]++;
        if (i < maxExpressEventIndex) {
            let k = 1;
            for (let j = i + 1; j <= maxExpressEventIndex; j++, k++) {
                currentCombination[j] = currentCombination[i] + k;
            }
        }
    }
    return currentCombination;
}

export function simpleStakeDTOComparator (a: SimpleStakeDTO, b: SimpleStakeDTO) {
    let result = a.event.startTime.getTime() - b.event.startTime.getTime();
    if (result === 0) {
        result = a.event.partitionName.localeCompare(b.event.partitionName);
    }
    if (result === 0) {
        const aParticipantList = getParticipantList(a.event, a.key.quotationKey);
        const bParticipantList = getParticipantList(b.event, b.key.quotationKey);
        result = aParticipantList[0].name.localeCompare(bParticipantList[0].name);
    }
    return result;
}
