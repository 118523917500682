import { computed, onMounted, ref } from "vue";
import AppMenuBar from "@/components/layout/app-menu-bar/AppMenuBar.vue";
import AppBottomBar from "@/components/layout/app-bottom-bar/AppBottomBar.vue";
import WindowButtonsPanel from "@sportaq/vue/components/common/window-buttons-panel/WindowButtonsPanel.vue";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";
import ExpressChangePasswordDialog
    from "@/components/dialogs/express-change-password-dialog/ExpressChangePasswordDialog.vue";
import { LocalizedError, UnsuccessfulRestResponse } from "@sportaq/common/exceptions/localized-errors";
import { Ref } from "@sportaq/common/types/types";
import {
    showMessageBox,
    showMessageBoxEx
} from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { useStreamingService, useHttpService, useGoogleTagService } from "@sportaq/services/index";
import { appLogger } from "@sportaq/common/utils/logger";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useWebBalanceUpdate } from "@sportaq/vue/components/common/balance-component/web-balance-update";
import { changeLanguage, resolveLanguage } from "@sportaq/i18n/index";
import CommonFooter from "@sportaq/vue/components/web/footer/CommonFooter.vue";
import MobileBetSlip from "@/components/betting/betslip/MobileBetSlip.vue";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { useCommonAuthorization } from "@sportaq/vue/views/web/authorization/authorization-common";
import { useBannersStore } from "@sportaq/vuex/modules/banners/banners-module";
import { setTheme } from "@sportaq/vue/components/common/preferences-dialog/preferences-dialog-common";
import { useRoute, useRouter } from "vue-router";
import { ROUTES } from "@/router/routes-consts";
import InstallApplicationDialog from "@/components/dialogs/install-application-dialog/InstallApplicationDialog.vue";
import { isSportRoute, makeCasinoUserWatch } from "@sportaq/vue/components/web/web-virtual-games-lifecycle";
import { isForbiddenCountry } from "@sportaq/vue/views/web/main/forbidden-countries";
import { useAuthChecker } from "@sportaq/vue/views/web/main/auth-checker";
import { useHorseEnterGuard } from "@sportaq/vue/views/web/main/horse-enter-guard";
import { countries } from "@sportaq/model/consts/countries";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { logAnalyticsEvent } from "@sportaq/common/utils/analytics/webview-analytics";
import { FatalConnectionError } from "@sportaq/services/streaming/streaming-service";
import { SilentError } from "@sportaq/services/rest/rest-service";
import { useRootStore } from "@sportaq/vuex/index";
import { useWebDisconnectTimer } from "@sportaq/vue/components/web/disconnect-worker/idle-disconnect";

enum ApplicationStatus {
    CHECKING_UPDATES = 0,
    LOADING = 1,
    START = 2,
    ERROR_STARTING = 3,
    FORBIDDEN = 4
}

export default {
    components: {
        AppMenuBar,
        AppBottomBar,
        WindowButtonsPanel,
        CommonFooter,
        MobileBetSlip,
        LoginPopupDialog,
        ResetPasswordDialog,
        ExpressChangePasswordDialog,
        InstallApplicationDialog
    },
    setup () {
        logAnalyticsEvent("application_started", {});
        const i18n = useI18n();
        const restService = useWebRestService();
        const streamingService = useStreamingService();
        const settingsService = useWebSettings();
        const rootStore = useRootStore();
        const store = useWebStore();
        const httpService = useHttpService();
        const betSlipStore = useBetSlipStore();
        const balanceStore = useBalanceStore();
        const bannersStore = useBannersStore();
        const router = useRouter();
        const route = useRoute();
        useWebDisconnectTimer(settingsService, rootStore, store, streamingService);
        makeCasinoUserWatch();
        useHorseEnterGuard();
        const googleTag = useGoogleTagService();
        const {
            loginDialogVisible,
            resetPasswordDialogVisible,
            isLoggedIn,
            changePasswordDialogVisible,
            openResetPasswordDialog,
            onResetPasswordDialogClose,
            onLoginDialogClose,
            onChangePasswordDialogClose
        } = useCommonAuthorization();
        restService.errorHandler = {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            onLocalizedError (e: LocalizedError) {
                showMessageBoxEx(false, undefined, i18n.t(e.message));
            },
            onNotAuthorized () {
                showMessageBoxEx(false, undefined, i18n.t("errors.mtl.aPo512.code4301"));
                router.push({ name: ROUTES.HOME.name }).then();
            }
        };
        restService.networkErrorHandler = {
            onNetworkError (code?: string) {
                router.push({ path: ROUTES.NETWORK_ERROR.path + "/" + code }).then();
            }
        };
        const updateMessage = ref("");
        const appStatus: Ref<ApplicationStatus> = ref(ApplicationStatus.CHECKING_UPDATES);
        const checkingUpdates = computed(() => appStatus.value === ApplicationStatus.CHECKING_UPDATES);
        const appLoading = computed(() => appStatus.value === ApplicationStatus.LOADING);
        const appStarted = computed(() => appStatus.value === ApplicationStatus.START);
        const forbidden = computed(() => appStatus.value === ApplicationStatus.FORBIDDEN);
        const isBetSlipShowed = computed(() => betSlipStore.isShowed);
        const refreshBalanceFunction = useWebBalanceUpdate(restService, balanceStore, settingsService, {
            canSwitchAccounts: true,
            isLoggedIn: () => store.user !== undefined,
            balanceUpdateCallBack: balance => {
                if (balance.primaryAccount) {
                    store.updatePrimaryAccount(balance.primaryAccount);
                }
            }
        });
        useAuthChecker(appStarted);

        async function startApplication () {
            appStatus.value = ApplicationStatus.LOADING;
            try {
                const locationInfo = await httpService.getUserLocationInfo();
                const countryCode = locationInfo.country;
                if (isForbiddenCountry(countryCode, locationInfo.ipAddress)) {
                    appStatus.value = ApplicationStatus.FORBIDDEN;
                    return;
                }
                const localeParam = route.params.locale;
                const locale = localeParam ? localeParam.toString() : resolveLanguage();
                rootStore.setUserCountryIdByIP(countries.getByCode(countryCode)?.id);
                if (bannersStore.banners.length === 0) {
                    bannersStore.banners = await restService.startApplication(rootStore.userSharedInfo.countryId, locale);
                }
                setTheme(settingsService, rootStore);
                changeLanguage(i18n, locale);
                if (settingsService.sessionCode) {
                    restService.session = settingsService.sessionCode;
                    try {
                        store.user = await restService.requestUserInfo();
                        googleTag.sendSuccessLoginEvent();
                        logAnalyticsEvent("login_success", {
                            userNumber: store.user.userNumber,
                            date: new Date().toISOString()
                        });
                        await refreshBalanceFunction();
                    } catch (e) {
                        restService.session = "";
                        await restService.startApplication(rootStore.userSharedInfo.countryId, locale);
                    }
                }
                if (isSportRoute(route.path) || route.name === COMMON_ROUTES.CASINO.name || route.name === COMMON_ROUTES.VIRTUAL.name) {
                    await connectToStreamService(rootStore.userSharedInfo.countryId);
                } else {
                    connectToStreamService(rootStore.userSharedInfo.countryId).then();
                }
                appStatus.value = ApplicationStatus.START;
            } catch (e) {
                appStatus.value = ApplicationStatus.ERROR_STARTING;
                if (e instanceof UnsuccessfulRestResponse) {
                    if (e.code.startsWith("4")) {
                        appLogger.logger.error(i18n.t(e.message));
                        showMessageBox(i18n.t(e.message));
                    }
                } else if (e instanceof LocalizedError) {
                    appLogger.logger.error(i18n.t(e.message));
                    showMessageBox(i18n.t(e.message), () => startApplication());
                } else if (e instanceof FatalConnectionError) {
                    appLogger.logger.error(`Unsupported client protocol version. Processing server: ${settingsService.restServerAddress}. Stream server: ${settingsService.getStreamServerAddress(resolveLanguage())}`, e);
                    showMessageBoxEx(false, FatalConnectionError.ERROR_MESSAGE, e.message, () => () => startApplication());
                } else if (!(e instanceof SilentError)) {
                    appLogger.logger.error(`Unexpected error of connect. Processing server: ${settingsService.restServerAddress}, stream server: ${settingsService.getStreamServerAddress(resolveLanguage())}`, e as Error);
                    showMessageBoxEx(false, "The unexpected error of starting application:", (e as Error).message, () => startApplication());
                }
            }
        }

        async function connectToStreamService (countryId: number) {
            await streamingService.connect();
            const country = store.user ? store.user.countryId : countryId;
            await restService.updateCountryFilterFavourites(country);
        }

        onMounted(() => startApplication());

        return {
            updateMessage,
            checkingUpdates,
            appLoading,
            appStarted,
            isBetSlipShowed,
            loginDialogVisible,
            resetPasswordDialogVisible,
            changePasswordDialogVisible,
            isLoggedIn,
            forbidden,
            openResetPasswordDialog,
            onResetPasswordDialogClose,
            onLoginDialogClose,
            onChangePasswordDialogClose
        };
    }
};
