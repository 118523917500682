import { IdItem, Ref } from "@sportaq/common/types/types";
import { onMounted, ref, SetupContext, watch } from "vue";
import { timeout, TimeoutController } from "@sportaq/common/utils/time-utils";

export const SLIDER_SELECT_EVENT = "select";
const AUTO_SELECT_TIMEOUT = 200;

export interface SliderProps {
    items: IdItem[];
    selectedIndex?: number;
    autoSelect: boolean;
}

export interface IndexedItem extends IdItem {
    index: number;
}

export function useSlider (props: SliderProps, context: SetupContext, canUnSelect: boolean = false) {
    const items = buildSliderItems(props.items);
    const sliderItems = ref(items);
    const selectedId: Ref<number> = ref(props.selectedIndex ? props.selectedIndex : -1);

    function itemSelected (item?: IdItem) {
        if (item) {
            if (canUnSelect) {
                if (selectedId.value !== -1 && selectedId.value === item.id) {
                    setSelectedItem(context, selectedId, -1, true);
                    return;
                }
            }
            setSelectedItem(context, selectedId, item.id, true);
        } else {
            setSelectedItem(context, selectedId, -1, true);
        }
    }

    function scrollLeft () {
        const arr = sliderItems.value;
        if (arr.length > 1) {
            const right = arr.pop();
            if (right) {
                arr.unshift(right);
            }
        }
    }

    function scrollRight () {
        const arr = sliderItems.value;
        if (arr.length > 1) {
            const left = arr.shift();
            if (left) {
                arr.push(left);
            }
        }
    }

    onMounted(() => {
        if (props.autoSelect) {
            timeout(AUTO_SELECT_TIMEOUT).then(() => {
                if (items.length && selectedId.value === -1) {
                    setSelectedItem(context, selectedId, items[0].id, true);
                }
            });
        }
    });

    watch(() => props.items, value => {
        const items = buildSliderItems(value);
        let newValue = selectedId.value;
        sliderItems.value = items;
        if (newValue !== -1) {
            if (items.findIndex(v => v.id === newValue) === -1) {
                newValue = -1;
            }
        }
        if (newValue === -1 && items.length && props.autoSelect) {
            setSelectedItem(context, selectedId, items[0].id, true);
        } else {
            setSelectedItem(context, selectedId, newValue, false);
        }
    });

    const autoSelectTimeoutController = new TimeoutController();
    watch(() => props.autoSelect, value => {
        autoSelectTimeoutController.clear();
        if (value) {
            autoSelectTimeoutController.setTimeout(() => {
                if (selectedId.value < 0 && sliderItems.value.length) {
                    setSelectedItem(context, selectedId, sliderItems.value[0].id, true);
                }
            }, AUTO_SELECT_TIMEOUT);
        }
    });

    watch(() => props.selectedIndex, value => {
        if (value && value > 0) {
            const founded = sliderItems.value.find(v => v.id === value);
            if (founded) {
                selectedId.value = value;
                return;
            } else {
                if (sliderItems.value.length > 0) {
                    setSelectedItem(context, selectedId, sliderItems.value[0].id, true);
                    return;
                }
            }
        }
        selectedId.value = -1;
    });

    return {
        sliderItems,
        selectedId,

        itemSelected,
        scrollLeft,
        scrollRight
    };
}

function buildSliderItems (items: IdItem[]): IndexedItem[] {
    return items.map((value, index) => ({
        ...value,
        index
    }));
}

function setSelectedItem (context: SetupContext, selectedId: Ref<number>, newValue: number, force: boolean) {
    if (force || selectedId.value !== newValue) {
        selectedId.value = newValue;
        context.emit(SLIDER_SELECT_EVENT, newValue);
    }
}
