import { useWebRestService } from "@sportaq/services/web";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";
import { EVerifyField } from "@sportaq/model/common/user-accounts";

export function useResetPasswordDialogCommon (context: SetupContext) {
    const activeTab = ref<number>(1);
    const data = ref();
    const restService = useWebRestService();
    const confirmDialogVisible = ref(false);
    const i18n = useI18n();

    function switchTab (tab: number) {
        activeTab.value = tab;
        data.value = "";
    }

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function resetPassword () {
        if (data.value) {
            restService.resetPassword(data.value, activeTab.value === 0 ? EVerifyField.email : EVerifyField.phone).then(() => {
                confirmDialogVisible.value = true;
            });
        }
    }

    function onConfirmDialogClose () {
        confirmDialogVisible.value = false;
    }

    function onConfirmSuccess () {
        showMessageBoxEx(true, i18n.t("webdesktop.resetPasswordDialog.caption"), i18n.t("webdesktop.resetPasswordDialog.success"));
        onClose();
    }

    function onCodeError (message: string) {
        showMessageBoxEx(true, i18n.t("webdesktop.resetPasswordDialog.caption"), i18n.t(message));
    }
    return {
        activeTab,
        data,
        confirmDialogVisible,

        onClose,
        switchTab,
        resetPassword,
        onConfirmSuccess,
        onCodeError,
        onConfirmDialogClose
    };
}
