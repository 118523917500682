import { useWithdrawalOrdersCommon } from "@sportaq/vue/views/web/history/withdrawal-orders-common";
import ConfirmationDialog from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.vue";
import DateFilter from "@/views/stakes-view/date-filter/DateFilter.vue";
import { computed } from "vue";

export default {
    name: "WithdrawalOrders",
    components: {
        ConfirmationDialog,
        DateFilter
    },
    setup () {
        const {
            requestPending,
            withdrawalOrders,
            EWithdrawalStatus,
            guidToCancel,
            withdrawalOrdersPaged,
            itemsPerPageRef,
            currentPage,
            getDescription,
            itemsPerPageChanged,
            currentPageChanged,
            onCancelConfirmed,
            dateChanged,
            cancelOrder
        } = useWithdrawalOrdersCommon();
        const totalPages = computed(() => Math.ceil(withdrawalOrders.value.length / itemsPerPageRef.value));

        return {
            requestPending,
            withdrawalOrders,
            EWithdrawalStatus,
            guidToCancel,
            withdrawalOrdersPaged,
            itemsPerPageRef,
            currentPage,
            totalPages,
            getDescription,
            itemsPerPageChanged,
            currentPageChanged,
            onCancelConfirmed,
            dateChanged,
            cancelOrder
        };
    }
};
