import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { Tab } from "@sportaq/vue/views/web/routing-tab";
import { ROUTES } from "web_desktop/src/router/routes-consts";
import { computed } from "vue";

export function useFinanceViewCommon () {
    const store = useWebStore();
    const isLoggedIn = computed(() => store.user !== undefined);
    const tabs = computed(() => {
        const result : Tab[] = [
            { id: 1, path: ROUTES.DEPOSIT_OPERATIONS.path, i18nKey: "webdesktop.routes.depositOperations" },
            { id: 2, path: ROUTES.WITHDRAWAL_ORDERS.path, i18nKey: "webdesktop.routes.withdrawalOrders" },
            { id: 3, path: ROUTES.BONUS_OPERATIONS.path, i18nKey: "webdesktop.routes.bonusOperations" }
        ];
        return result;
    });
    return {
        tabs,
        isLoggedIn
    };
}
