import Flicking, { MOVE_TYPE } from "@egjs/vue3-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";
import { nextTick, PropType, ref, watch } from "vue";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import {
    EGameTabs,
    getCasinoGameTabImage,
    getCasinoGameTabName
} from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import GameWidget from "@sportaq/vue/components/games/common/games-body/game-widget/GameWidget.vue";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { getCasinoGameDirectLink } from "@sportaq/vue/components/games/common/functions/common-games-functions";
import { useRoute, useRouter } from "vue-router";
import { useCasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";

const PREVIEW_ITEM_COUNT = 10;

interface Props {
    gameType: ECasinoGameType,
    tab: EGameTabs,
    classifier: ECasinoGameClassifier,
    source: GameSortInfo[];
}

export default {
    props: {
        gameType: {
            required: true,
            type: Number
        },
        tab: {
            required: true,
            type: Number
        },
        classifier: {
            required: true,
            type: Number
        },
        source: {
            required: true,
            type: Object as PropType<GameSortInfo[]>
        }
    },
    components: {
        Flicking,
        GameWidget
    },
    setup (props: Props) {
        const gamesSupplier = useCasinoGamesSupplier();
        const route = useRoute();
        const router = useRouter();

        const categoryName = getCasinoGameTabName(props.tab);
        const categoryImage = getCasinoGameTabImage(props.gameType, props.tab);
        const link = (props.gameType === ECasinoGameType.Casino ? COMMON_ROUTES.CASINO.path : COMMON_ROUTES.VIRTUAL.path) + `?itemId=${props.tab}`;
        const plugins = [new Arrow()];

        const flicker = ref();
        const items = ref<GameSortInfo[]>([]);

        async function startGame (item: CasinoGame) {
            const gameDirectLink = getCasinoGameDirectLink(route, item.gameType, item.id);
            await router.push({ path: gameDirectLink });
        }

        watch(() => props.source, value => {
            items.value = value.filter(value => (value.classifiers & props.classifier) > 0).slice(0, PREVIEW_ITEM_COUNT);
            if (flicker.value && !gamesSupplier.isColdDataReceived()) {
                nextTick(() => {
                    if (flicker.value && !flicker.value.animating && !gamesSupplier.isColdDataReceived()) {
                        flicker.value.moveTo(0, 0);
                    }
                }).then();
            }
        });

        return {
            categoryName,
            categoryImage,
            link,
            plugins,

            items,
            flicker,

            startGame,

            MOVE_TYPE
        };
    }
};
