<template>
  <div class="search search-games" data-cy="event-search-dialog">
    <div class="search-head">
      <div class="search-head-title search-games-head-title">{{ $t("betting.navigationSideBar.search") }}</div>
      <div class="search-close" @click="close" data-cy="close-btn"></div>
    </div>
    <div class="search-field">
      <input type="text" class="search-field-input search-games-field-input"
             :placeholder="$t('betting.searchDialog.placeholder')"
              :value="searchText"
             @input="updateSearch($event.target.value)"
             ref="inputField"
             data-cy="search-input"
      />
    </div>
  </div>
</template>

<script src="./game-search-dialog.ts">

</script>
