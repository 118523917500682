import { ref } from "vue";

export default {
    name: "FooterGroup",
    setup () {
        const active = ref(false);
        function toggleActive () {
            active.value = !active.value;
        }
        return {
            active,
            toggleActive
        };
    }
};
