<template>
    <div class="details-filters">
        <!--
        <div class="title" v-if="sportTypeName">
            {{ sportTypeName}}
        </div>
        -->
        <div class="wrapper" data-cy="scoreboard-views">
            <div class="item" :class="{active: item.view === selectedView}"
                 v-for="item of menuItems"
                 :key="item.view"
                 @click="select(item)"
                 :data-cy="`view-${item.view}`">
                {{ $t(item.localizationKey) }}
            </div>
        </div>
    </div>
</template>

<script src="./mobile-scoreboard-head-component.ts"/>
