import { computed, PropType, SetupContext } from "vue";
import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { calculateBetTotal } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useSettings } from "@sportaq/services/index";
import { PlaceBetResponseSuccessInfo } from "@sportaq/model/types/place-bet-response";
import MobileInnerStakeTable
    from "@/components/betting/betslip/dialogs/common/inner-stake-table/MobileInnerStakeTable.vue";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";

const EVENT_CLOSE = "close";

interface Props {
    responseInfo: PlaceBetResponseSuccessInfo;
    simpleStakes: SimpleStakeDTO[];
    combinedStakes: CombinedStakeDTO[];
}

export default {
    props: {
        responseInfo: {
            required: true,
            type: Object as PropType<PlaceBetResponseSuccessInfo>
        },
        simpleStakes: {
            required: true,
            type: Object as PropType<SimpleStakeDTO[]>
        },
        combinedStakes: {
            required: true,
            type: Object as PropType<CombinedStakeDTO[]>
        }
    },
    components: {
        MobileInnerStakeTable
    },
    emits: [EVENT_CLOSE],

    setup (props: Props, context: SetupContext) {
        const settings = useSettings();
        const balanceStore = useBalanceStore();
        const currency = computed(() => {
            return balanceStore.currency ? balanceStore.currency : settings.pointSettings.currencyName;
        });
        const formattedPayCode = computed(() => formatVoucherNumber(props.responseInfo.payCode));
        const betTotal = computed(() => calculateBetTotal(props.simpleStakes, props.combinedStakes, settings.pointSettings.draftCoef));

        function onClose () {
            context.emit(EVENT_CLOSE);
        }

        return {
            currency,
            formattedPayCode,
            betTotal,
            onClose
        };
    }
};
