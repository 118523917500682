import { Comparable, Equable } from "@sportaq/common/types/interfaces";
import { DictionaryItem } from "@sportaq/common/types/types";

export function comparator<T> (a?: Comparable<T>, b?: T): number {
    if (a === b) {
        return 0;
    }
    if (!!a && !b) {
        return 1;
    }
    if (!a && !!b) {
        return -1;
    }
    if (a && b) {
        return a.compare(b);
    }
    return 0;
}

export function comparatorDesc<T> (a?: Comparable<T>, b?: T): number {
    if (a === b) {
        return 0;
    }
    if (!!a && !b) {
        return -1;
    }
    if (!a && !!b) {
        return 1;
    }
    if (a && b) {
        return -a.compare(b);
    }
    return 0;
}

export function equals<T> (a?: Equable<T>, b?: T): boolean {
    if (a === b) {
        return true;
    }
    if (!a || !b) {
        return false;
    }
    return a.equals(b);
}

export function equalsArrays<T> (arr1: T[] | undefined, arr2: T[] | undefined, eq: (a?: T, b?: T) => boolean) {
    if (arr1 === arr2) {
        return true;
    }
    if (!arr1 || !arr2) {
        return false;
    }
    const size = arr1.length;
    if (size !== arr2.length) {
        return false;
    }
    for (let i = 0; i < size; i++) {
        if (!eq(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
}

// Updates the array by setting the index value if the array value is different from the index. Returns whether an array has been modified
export function updateArrayValue<T> (arr: T[], value: Equable<T>, index: number): boolean {
    if (index < arr.length) {
        if (!value.equals(arr[index])) {
            arr[index] = (value as unknown) as T;
            return true;
        }
        return false;
    } else {
        arr.push((value as unknown) as T);
        return true;
    }
}

export function wait (ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function comparatorByName<T extends DictionaryItem> (item1: T, item2: T): number {
    return item1.name.localeCompare(item2.name);
}
