<template>
    <div class="filter-menu-block" :data-cy="`country-block-filter-${country.id}`">
        <div class="caption" :class="{active: isOpened}" @click="toggleOpened"
             :data-cy="`country-filter-${country.id}`">
            <div class="checkbox" :class="{active: isSelected}" @click.stop="toggleCountry" data-cy="checkbox">
                <div class="checkbox-label">
                    <img :src="`/img/flags/${country.image}.png`" class="icon" alt="" v-if="!isHotPartitionCountry"/>
                    {{ countryName }}
                    &nbsp;
                    <span class="count" data-cy="count">({{ country.count }})</span>
                </div>
                <span class="star" :class="{active: isFavourite}" @click.stop="toggleFavourite"
                      data-cy="favorite-star" v-if="!favouriteMode && !isHotPartitionCountry"></span>
            </div>
        </div>
        <ul class="list" v-if="isOpened" data-cy="partition-list">
            <betting-filter-menu-partition v-for="partition of country.partitions"
                                           :key="partition.id" :country="country"
                                           :partition="partition"/>
        </ul>
    </div>
</template>

<script src="./betting-filter-menu-country-component.ts"/>
