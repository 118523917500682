import { useI18n } from "vue-i18n";
import { useMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import InformationBox from "@/components/dialogs/information-box/InformationBox.vue";
import MessageBox from "@sportaq/vue/components/common/message-box/MessageBox.vue";
import { configureFormValidation } from "@sportaq/vue/utils/form-validation";
import { i18nCache } from "@sportaq/i18n/index";
import { provideWebStore } from "@sportaq/vuex/modules/web/web-module";
import { computed, onMounted } from "vue";
import { ThemeClass } from "@sportaq/common/enums/theme";
import { useWebSettings } from "@sportaq/services/web";
import { provideAuthorizationService } from "@sportaq/services/index";
import { WebAuthorizationService } from "@sportaq/services/authorization/AuthorizationService";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import { useBrand } from "@sportaq/vue/components/common/brand-component/brand-component";
import { useRootStore } from "@sportaq/vuex/index";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;

    prompt (): Promise<void>;
}

declare global {
    interface WindowEventMap {
        beforeinstallprompt: BeforeInstallPromptEvent;
    }
}

export default {
    components: {
        MessageBox,
        InformationBox
    },
    setup () {
        const settings = useWebSettings();
        const rootStore = useRootStore();
        const store = provideWebStore(rootStore);
        const i18n = useI18n();
        configureFormValidation(i18n);
        i18nCache.refresh(i18n);
        rootStore.theme = settings.currentTheme ?? ThemeClass.Default;
        const theme = computed(() => rootStore.theme);
        provideAuthorizationService(new WebAuthorizationService(store));

        let installApplicationEvent: BeforeInstallPromptEvent | undefined;

        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            installApplicationEvent = e;
            store.isAppInstallationAvailable = true;
        });

        window.addEventListener("appinstalled", () => {
            store.isAppInstallationAvailable = false;
            installApplicationEvent = undefined;
            trackEventFacebook("appinstalled");
        });

        useBrand();

        onMounted(() => {
            const footerWidgetScript = document.createElement("script");
            footerWidgetScript.setAttribute("src", "https://f10d5009-4f22-46fb-8e0d-6678ac15550f.snippet.antillephone.com/apg-seal.js");
            document.head.appendChild(footerWidgetScript);
            eventBus.on(Events.INSTALL_MOBILE_APPLICATION, () => {
                if (installApplicationEvent) {
                    installApplicationEvent.prompt().then(() => {
                        installApplicationEvent = undefined;
                        store.isAppInstallationAvailable = false;
                    });
                }
            });
        });
        const {
            isMessageBoxActive,
            message,
            success,
            caption,
            messageBoxClose
        } = useMessageBox(true);
        return {
            isMessageBoxActive,
            message,
            success,
            caption,
            theme,
            ThemeClass,
            messageBoxClose
        };
    }
};
