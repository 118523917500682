<template>
    <div class="deposit-filter">
        <div class="deposit-filter-title">{{$t("webdesktop.allStakes.dateFilter")}}</div>
        <div class="deposit-filter-body">
            <div class="form-line">
                <input type="date" v-model="startDateRef">
            </div>
            <div class="form-line">
                <input type="date" v-model="endDateRef">
            </div>
        </div>
    </div>
</template>

<script src="./date-filter-component.ts" />