import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useSettings } from "@sportaq/services/index";
import { resolveLanguage } from "@sportaq/i18n/index";
import { Tab } from "@sportaq/vue/views/web/routing-tab";
import { ROUTES } from "web_desktop/src/router/routes-consts";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { computed } from "vue";

export function useStakesViewCommon () {
    const store = useWebStore();
    const isLoggedIn = computed(() => store.user !== undefined);
    const settings = useSettings();
    const tabs = computed(() => {
        const lang = resolveLanguage();
        const result : Tab[] = [{ id: 1, path: ROUTES.IN_PLAY.path, i18nKey: "webdesktop.routes.inPlay" },
            { id: 3, path: ROUTES.ALL_STAKES.path, i18nKey: "webdesktop.routes.allStakes" },
            { id: 5, path: ROUTES.CASINO_STAKES.path, i18nKey: "mobile.navigationView.casinoButton" }
        ];
        if (settings.brandType !== ECompileBrandType.certabet || lang !== "ru") {
            result.push({ id: 6, path: ROUTES.RACE_STAKES.path, i18nKey: "webdesktop.routes.raceStakes" });
        }
        return result;
    });
    return {
        tabs,
        isLoggedIn
    };
}
