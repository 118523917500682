import { BettingEvent } from "@sportaq/model/betting/events/event";
import { insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import EPeriodType from "@sportaq/common/enums/period-types";
import { comparator } from "@sportaq/common/types/functions";
import {
    PeriodSortInfo,
    PeriodSortInfoOptions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";

export namespace ScoreboardPeriodFunctions {
    export type PeriodSortInfoFactory = (event: BettingEvent, period: EPeriodType, options: PeriodSortInfoOptions) => PeriodSortInfo;

    export class PeriodSortInfoHelper {
        constructor (private readonly factory: PeriodSortInfoFactory) {
        }

        insertNewEvent (list: PeriodSortInfo[], currentEvent: BettingEvent, isExpanded: boolean, options: PeriodSortInfoOptions) {
            insertIntoSortedArray(list, this.factory(currentEvent, EPeriodType.MAIN, options), comparator);
            if (isExpanded) {
                this.insertPeriods(list, currentEvent, options);
            }
        }

        updateEvent (list: PeriodSortInfo[], currentEvent: BettingEvent, isExpanded: boolean, options: PeriodSortInfoOptions, firstIndex?: number) {
            if (!firstIndex) {
                firstIndex = list.findIndex(value => value.positionId === currentEvent.positionId);
            }
            const deleteCount = ScoreboardPeriodFunctions.PeriodSortInfoHelper.getEventPeriodsCount(list, firstIndex, currentEvent.positionId);
            const newPeriods: PeriodSortInfo[] = [];
            newPeriods.push(this.factory(currentEvent, EPeriodType.MAIN, options));
            if (isExpanded) {
                this.insertPeriods(newPeriods, currentEvent, options);
            }
            list.splice(firstIndex, deleteCount, ...newPeriods);
        }

        deleteEvent (list: PeriodSortInfo[], positionId: number, firstIndex?: number): boolean {
            if (!firstIndex) {
                firstIndex = list.findIndex(value => value.positionId === positionId);
            }
            if (firstIndex >= 0) {
                list.splice(firstIndex, ScoreboardPeriodFunctions.PeriodSortInfoHelper.getEventPeriodsCount(list, firstIndex, positionId));
            }
            return firstIndex >= 0;
        }

        private static getEventPeriodsCount (list: PeriodSortInfo[], firstIndex: number, positionId: number): number {
            let counter = 1;
            while ((firstIndex + counter) < list.length && list[firstIndex + counter].positionId === positionId) {
                counter++;
            }
            return counter;
        }

        private insertPeriods (list: PeriodSortInfo[], currentEvent: BettingEvent, options: PeriodSortInfoOptions) {
            currentEvent.periods.getPeriods()
                .forEach(value => insertIntoSortedArray(list, this.factory(currentEvent, value.type, options), comparator));
        }
    }
}
