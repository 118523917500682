import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { ConfirmationMethod } from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { VisaWithdrawalParams } from "@sportaq/model/web/withdrawal-params";

export class APo617VisaWithdrawalMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.PO.6.17";
    constructor (readonly accountNumber: string, readonly currencyId: number,
                 readonly withdrawalParams: VisaWithdrawalParams, readonly confirmationMethod: ConfirmationMethod) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        const userElement = request.addChild(actionElement, "SessionUser");
        request.addChildWithValue(userElement, "AccountNumber", this.accountNumber);
        request.addChildWithValue(actionElement, "OrderedSum", this.withdrawalParams.orderedSum.toString());
        request.addChildWithValue(actionElement, "CurrencyId", this.currencyId.toString());
        request.addChildWithValue(actionElement, "ConfirmationMethod", this.confirmationMethod.toString());
        request.addChildWithValue(actionElement, "WithdrawalMethod",
            this.withdrawalParams.cCINumber ? "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT" : "WITHDRAWAL_METHOD_VISA");
        const withdrawalParamsElement = request.addChild(actionElement, "WithdrawalParams");
        request.addChildWithValue(withdrawalParamsElement, "BunkNumber", this.withdrawalParams.bunkNumber);
        request.addChildWithValue(withdrawalParamsElement, "CardName", this.withdrawalParams.cardName);
        request.addChildWithValue(withdrawalParamsElement, "FullNumber", this.withdrawalParams.fullNumber);
        request.addChildWithValue(withdrawalParamsElement, "TypeAccount", this.withdrawalParams.typeAccount);
        if (this.withdrawalParams.cCINumber) {
            request.addChildWithValue(withdrawalParamsElement, "CCINumber", this.withdrawalParams.cCINumber);
        }
        if (this.withdrawalParams.district) {
            request.addChildWithValue(withdrawalParamsElement, "District", this.withdrawalParams.district);
        }
        if (this.withdrawalParams.province) {
            request.addChildWithValue(withdrawalParamsElement, "Province", this.withdrawalParams.province);
        }
        if (this.withdrawalParams.department) {
            request.addChildWithValue(withdrawalParamsElement, "Province", this.withdrawalParams.department);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "4440") {
            throw new LocalizedError(`errors.mtl.aPo617.code${serverCode}`);
        }
    }
}
