import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { EmailRegistrationData } from "@sportaq/model/web/email-registration-data";
import Cookies from "js-cookie";

export class AUs22SendSms extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.US.2.2";

    constructor (readonly registrationData: EmailRegistrationData) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "FirstName", this.registrationData.firstName);
        request.addChildWithValue(actionElement, "LastName", this.registrationData.lastName);
        // request.addChildWithValue(actionElement, "CityText", this.registrationData.cityText);
        request.addChildWithValue(actionElement, "CountryId", this.registrationData.country.toString());
        request.addChildWithValue(actionElement, "Phone", this.registrationData.phone);
        request.addChildWithValue(actionElement, "Email", this.registrationData.email);
        request.addChildWithValue(actionElement, "Birthday", this.registrationData.birthday.toString());
        request.addChildWithValue(actionElement, "IdentificationCode", "DNI:" + this.registrationData.document);
        request.addChildWithValue(actionElement, "Login", this.registrationData.login);
        request.addChildWithValue(actionElement, "Password", this.registrationData.password);
        request.addChildWithValue(actionElement, "ReferralCode", this.registrationData.referralCode);
        const gaClientId = Cookies.get("_ga");
        if (gaClientId) {
            request.addChildWithValue(actionElement, "GA_CLIENTID", gaClientId);
        }
        if (this.registrationData.referralSystem) {
            request.addChildWithValue(actionElement, "ReferralSystem", this.registrationData.referralSystem);
        }
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1700") {
            return true;
        }
        throw new LocalizedError(`errors.mtl.aUs22.code${serverCode}`);
    }
}
