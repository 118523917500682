import EmailRegistration from "@/views/registration-view/components/email/EmailRegistration.vue";

export default {
    components: {
        EmailRegistration
    },
    props: {
        selectedBonusType: {
            type: String,
            required: false,
            default: undefined
        }
    }
};
