import { Card, CardContainer, CardItem, ContainerType } from "@sportaq/model/cashier/card";
import {
    CombinedStakeDTO,
    EXPRESS_BASE,
    SimpleStakeDTO,
    StakeEvent,
    StakeQuotationKey
} from "@sportaq/model/betting/bet-slip/stakes/stakes";
import EventType from "@sportaq/common/enums/event-type";
import { calculateStartTimeStr } from "@sportaq/model/betting/events/event";
import { numberOfCombinations } from "@sportaq/common/utils/number-utils";
import { calculateCombinedMaxWin, simpleStakeDTOComparator } from "@sportaq/model/common/combined-stakes-calculator";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { DateTimeFormatter } from "@sportaq/common/utils/miscellaneous-params";

export function convertCardToStakeArrays (clockProvider: ClockProvider, dateTimeFormatter: DateTimeFormatter, card: Card): { simples: SimpleStakeDTO[]; combined: CombinedStakeDTO[] } {
    const simples: SimpleStakeDTO[] = [];
    const combined: CombinedStakeDTO[] = [];
    for (const cc of card.containers) {
        if (cc.containerType === ContainerType.Simple) {
            const dto = makeSimpleStake(clockProvider, dateTimeFormatter, cc.items[0], cc.sumStake);
            simples.push(dto);
        } else {
            const dto = makeCombinedStake(cc, card.maxSystemCoef);
            for (const item of cc.items) {
                const entry = makeSimpleStake(clockProvider, dateTimeFormatter, item, 0);
                dto.simples.push(entry);
            }
            combined.push(dto);
        }
    }
    simples.sort(simpleStakeDTOComparator);
    for (const item of combined) {
        item.simples.sort(simpleStakeDTOComparator);
    }
    return {
        simples,
        combined
    };
}

function makeSimpleStake (clockProvider: ClockProvider, dateTimeFormatter: DateTimeFormatter, item: CardItem, sumStake: number): SimpleStakeDTO {
    const today = clockProvider.getDate();
    const event = new StakeEvent(item.isLive ? EventType.LIVE : EventType.PRE_MATCH, item.position.id, item.position.partition.sportTypeId, item.position.startTime,
        calculateStartTimeStr(dateTimeFormatter, item.position.startTime, today.getFullYear(), today.getMonth(), today.getDate()), item.position.partition.name, item.participants);
    return new SimpleStakeDTO(
        event,
        makeQuotationKey(item),
        item.coef,
        sumStake,
        `simple-stake-${item.position.id}-${item.quotationKey.quotationId}-${item.id}`);
}

function makeCombinedStake (cc: CardContainer, maxCoef: number): CombinedStakeDTO {
    const coef = cc.getCoefColumnValue();
    return {
        base: cc.containerType === ContainerType.Express ? EXPRESS_BASE : cc.expressEvents,
        limit: cc.items.length,
        express: cc.containerType === ContainerType.Express,
        coef,
        amount: cc.sumStake,
        templateKey: cc.containerType === ContainerType.Express ? "combined-stake-express" : `combined-stake-system-base${cc.expressEvents}`,
        variants: numberOfCombinations(cc.items.length, cc.expressEvents),
        bonus: cc.bonusSchemeCode === 3,
        maxWin: calculateCombinedMaxWin(cc.containerType === ContainerType.Express, coef, cc.sumStake, maxCoef),
        simples: []
    };
}

function makeQuotationKey (item: CardItem): StakeQuotationKey {
    return new StakeQuotationKey(item.id, item.quotationKey);
}
