import { Comparable, Equable } from "@sportaq/common/types/interfaces";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { QuotationWrapper } from "@sportaq/model/betting/events/quotation";

export class DecimalInfo implements Equable<DecimalInfo>, Comparable<DecimalInfo> {
    constructor (public readonly value: BigDecimal, public readonly isMain: boolean) {
    }

    compare (o: DecimalInfo): number {
        const result = (o.isMain ? 1 : 0) - (this.isMain ? 1 : 0); // Main handicap must be upper
        if (result) {
            return result;
        }
        return this.value.compare(o.value);
    }

    equals (o: DecimalInfo): boolean {
        return this.value.equals(o.value);
    }
}

export function getBaseQuotationP1 (eventId: number, arr: QuotationWrapper[]): BigDecimal {
    if (arr.length > 0) {
        const base = arr.find(v => v.quotation.key.po === BigDecimal.ZERO);
        if (!base) {
            throw new Error(`Error parring handicap quotation for event ${eventId} because can't find base quotation (po === 0) in ${JSON.stringify(arr)}. Quotation will be omitted`);
        }
        return base.quotation.key.p1;
    } else {
        return new BigDecimal(-100000000, 3);
    }
}
