<template>
    <div class="container">
        <div class="deposit-caption">
            <div class="deposit-caption-line">
                <div class="name">{{$t("mobile.navigationView.sportBalance")}}</div>
                <div class="value">{{sportBalance}}</div>
            </div>
            <div class="deposit-caption-line">
                <div class="name">{{$t("mobile.navigationView.sportBalanceAvailable")}}</div>
                <div class="value">{{sportBalanceAvailable}}</div>
            </div>
        </div>
        <div class="deposit-nav">

            <router-link :to="{path: ROUTES.DEPOSIT.path}" class="deposit-nav-item">
                <img :src="require(`@assets/img/mobile/${theme}/icon-deposit.svg`)" alt="">
                <div class="name">{{$t("mobile.navigationView.depositButton")}}</div>
            </router-link>
            <router-link :to="{path: ROUTES.WITHDRAWAL.path}" class="deposit-nav-item">
                <img :src="require(`@assets/img/mobile/${theme}/icon-withdraw.svg`)" alt="">
                <div class="name">{{$t("mobile.navigationView.withdrawalButton")}}</div>
            </router-link>
            <router-link :to="{path: ROUTES.BONUSES.path}" class="deposit-nav-item">
                <img :src="require(`@assets/img/mobile/${theme}/icon-bonus.svg`)" alt="">
                <div class="name">{{$t("mobile.navigationView.bonusButton")}}</div>
            </router-link>
            <router-link :to="{path: ROUTES.IN_PLAY.path}" class="deposit-nav-item">
                <img :src="require(`@assets/img/mobile/${theme}/icon-bets.svg`)" alt="">
                <div class="name">{{$t("mobile.navigationView.stakesButton")}}</div>
            </router-link>
            <router-link :to="{path: ROUTES.CASINO_STAKES.path}" class="deposit-nav-item">
                <img :src="require(`@assets/img/mobile/${theme}/icon-casino.svg`)" alt="" >
                <div class="name">{{$t("mobile.navigationView.casinoButton")}}</div>
            </router-link>
            <router-link :to="{path: ROUTES.RACE_STAKES.path}" class="deposit-nav-item" v-if="isRaceAvailable">
                <img :src="require(`@assets/img/mobile/${theme}/icon-horse.svg`)" alt="">
                <div class="name">{{$t("mobile.navigationView.raceButton")}}</div>
            </router-link>
        </div>
    </div>
</template>

<script src="./navigation-view-component.ts" />
