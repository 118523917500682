import {
    useSportTypeFilter
} from "@sportaq/vue/components/betting/betting-top-panel/sport-type-filter/use-sport-type-filter";
import ItemSlider from "@sportaq/vue/components/common/item-slider/desktop-slider/ItemSlider.vue";
import { computed } from "vue";
import { useRootStore } from "@sportaq/vuex/index";

export default {
    components: {
        ItemSlider
    },
    setup () {
        const store = useRootStore();
        const imageThemePath = computed(() => store.theme);

        const {
            autoSelect,
            selectedIndex,
            sportTypeItems,
            sportTypeSelected
        } = useSportTypeFilter();

        return {
            autoSelect,
            selectedIndex,
            sportTypeItems,
            imageThemePath,
            sportTypeSelected
        };
    }
};
