import { RouteLocationNormalizedLoaded } from "vue-router";

export interface Tab {
    id: number;
    path: string;
    i18nKey: string;
    additionPaths?: string[];
    icon?: string;
}

export function recognizeDefaultTabKey (route: RouteLocationNormalizedLoaded, t: Tab[]): Tab | undefined {
    for (const tab of t) {
        if (route.path.indexOf(tab.path) > 0) {
            return tab;
        }
        if (tab.additionPaths) {
            for (const p of tab.additionPaths) {
                if (route.path.indexOf(p) > 0) {
                    return tab;
                }
            }
        }
    }
    return undefined;
}
