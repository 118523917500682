import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { computed, ref } from "vue";
import EPeriodType from "@sportaq/common/enums/period-types";
import {
    ScoreboardBodyComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/common-scoreboard-body-component";

// This const must be synchronized with property "height" of css class .
const SCOREBOARD_EVENT_LINE_HEIGHT_IN_VW_FROM_CSS: number = 41.867;
const SCOREBOARD_CATEGORY_LINE_HEIGHT_IN_VW_FROM_CSS: number = 11.467;

export function useMobileScoreboardBodyItems () {
    const scoreboardStore = useScoreboardStore();
    const forceRecalculate = ref(false);
    const periods = computed(() =>
        scoreboardStore.getPeriodsView()
            .map(value => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const tmp = forceRecalculate.value;
                const result = value.clone();
                if (result.period === EPeriodType.CATEGORY) {
                    result.size = ScoreboardBodyComponent.calculateItemSize(SCOREBOARD_CATEGORY_LINE_HEIGHT_IN_VW_FROM_CSS);
                } else {
                    result.size = ScoreboardBodyComponent.calculateItemSize(SCOREBOARD_EVENT_LINE_HEIGHT_IN_VW_FROM_CSS);
                }
                return result;
            })
    );

    function onResize () {
        forceRecalculate.value = !forceRecalculate.value;
    }

    return {
        periods,
        onResize
    };
}
