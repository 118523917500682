<template>
    <footer class="footer">
        <div class="footer-menu">
            <footer-group>
                <li class="footer-nav-title">{{$t("webdesktop.footer.bets")}}</li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.SPORT.path}">{{$t("webdesktop.footer.sport")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.LIVE.path}">{{$t("webdesktop.footer.live")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/rules'}">{{$t("webdesktop.footer.rules")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/bonuses'}">{{$t("webdesktop.footer.bonuses")}}</router-link></li>
            </footer-group>
            <footer-group v-if="isCasinoEnabled">
                <li class="footer-nav-title">{{$t("webdesktop.footer.casino")}}</li>
                <li class="footer-nav-link"><router-link :to="{path: '/casino', query: {itemId: EGameTabs.Slots}}">{{$t("common.games.tabs.slots")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/casino', query: {itemId: EGameTabs.Card}}">{{$t("common.games.tabs.card")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/casino', query: {itemId: EGameTabs.Roulette}}">{{$t("common.games.tabs.roulette")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/casino', query: {itemId: EGameTabs.Live}}">{{$t("common.games.tabs.live")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/bonuses'}">{{$t("webdesktop.footer.bonuses")}}</router-link></li>
            </footer-group>
            <footer-group>
                <li class="footer-nav-title">{{$t("webdesktop.footer.popular")}}</li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.SPORT.path + '/soccer'}">{{$t("mobile.homeView.sport.soccer")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.SPORT.path + '/basketball'}">{{$t("mobile.homeView.sport.basketball")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.SPORT.path + '/tennis'}">{{$t("mobile.homeView.sport.tennis")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.SPORT.path + '/volleyball'}">{{$t("mobile.homeView.sport.volleyball")}}</router-link></li>
            </footer-group>
            <footer-group v-if="isVirtualEnabled">
                <li class="footer-nav-title">{{$t("webdesktop.footer.virtual")}}</li>
                <li class="footer-nav-link"><router-link :to="{path: '/virtual', query: {itemId: EGameTabs.Football}}">{{$t("common.games.tabs.football")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/virtual', query: {itemId: EGameTabs.Moto}}">{{$t("common.games.tabs.moto")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/virtual', query: {itemId: EGameTabs.Casual}}">{{$t("common.games.tabs.casual")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/virtual', query: {itemId: EGameTabs.Horse}}">{{$t("common.games.tabs.horse")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: '/virtual', query: {itemId: EGameTabs.Dogs}}">{{$t("common.games.tabs.dog")}}</router-link></li>
            </footer-group>
            <footer-group v-if="isAboutPagePresent">
                <li class="footer-nav-title">{{$t("webdesktop.footer.about.caption")}}</li>
                <!-- <li class="footer-nav-link"><a href="">{{$t("webdesktop.footer.affiliates")}}</a></li> -->
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.TERMS.path}">{{$t("webdesktop.footer.about.terms")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.PRIVACY_POLICY.path}">{{$t("webdesktop.footer.about.privacy")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.AML.path}">{{$t("webdesktop.footer.about.aml")}}</router-link></li>
                <li class="footer-nav-link"><router-link :to="{path: COMMON_ROUTES.RESPONSIBLE_GAMING.path}">{{$t("webdesktop.footer.about.resp")}}</router-link></li>
                <li class="footer-nav-link" v-if="isFeedbackPresent"><a href="https://forms.gle/h82gR4oi3Jdv6Jyg9" target="_blank">{{$t("webdesktop.footer.complaintsBook")}}</a></li>
            </footer-group>
        </div>
        <div class="footer-verify">
            <div id="apg-f10d5009-4f22-46fb-8e0d-6678ac15550f" class="verify-img" data-apg-seal-id="f10d5009-4f22-46fb-8e0d-6678ac15550f" data-apg-image-size="170" data-apg-image-type="basic-light-large" v-if="isAboutPagePresent">
            </div>
            <div class="content-rating">
                <span>18+</span>
            </div>
        </div>
        <div class="footer-social">
            <a href="#">
                <img :src="require(`@assets/img/social/twitter.png`)" alt="twitter">
            </a>
            <a href="#">
                <img :src="require(`@assets/img/social/instagram.png`)" alt="instagram">
            </a>
            <a href="#">
                <img :src="require(`@assets/img/social/facebook.png`)" alt="facebook">
            </a>
            <a href="#">
                <img :src="require(`@assets/img/social/telegram.png`)" alt="telegram">
            </a>
            <a href="#">
                <img :src="require(`@assets/img/social/tiktok.png`)" alt="tiktok">
            </a>
            <a href="#">
                <img :src="require(`@assets/img/social/youtube.png`)" alt="youtube">
            </a>
        </div>
        <div class="payments-list">
            <img v-for="s in paymentSystemIcons" :class="`${s}`" :key="s" :src="require(`@assets/img/payments/${s}.svg`)"/>
        </div>
        <div class="footer-rights" v-html="$t('webdesktop.footer.rights')" v-if="isAboutPagePresent">
        </div>
    </footer>
</template>

<script src="./footer-component.ts" />
