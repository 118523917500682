import StakesMenu from "@/views/stakes-view/stakes-menu/StakesMenu.vue";
import { ROUTES } from "@/router/routes-consts";
import { useRouter } from "vue-router";
import { Tab } from "@sportaq/vue/views/web/routing-tab";

const tabs: Tab[] = [
    { id: 1, path: ROUTES.HISTORY_OPERATIONS.path, i18nKey: "mobile.routes.operationsHistory" }
    /* { id: 2, path: ROUTES.HISTORY_SESSIONS.path, i18nKey: "mobile.routes.sessionsHistory" },
    { id: 3, path: ROUTES.HISTORY_BALANCE.path, i18nKey: "mobile.routes.balance" } */
];

export default {
    name: "HistoryView",
    components: {
        StakesMenu
    },
    setup () {
        const router = useRouter();
        function back () {
            router.push({ path: ROUTES.DEPOSIT_NAV.path }).then();
        }
        return {
            tabs,
            back
        };
    }
};
