import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebRestService } from "@sportaq/services/web";
import { UpdateProfileData } from "@sportaq/model/web/update-profile-data";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { formatForMaxDate } from "@sportaq/common/utils/time-utils";
import { onNaturalNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { EVerifyField } from "@sportaq/model/common/user-accounts";
import { countries } from "@sportaq/model/consts/countries";

export interface ProfileUpdateData {
    firstName: string;
    lastName: string;
    birthday: Date;
    city: string;
    document: string;
}

export function useProfileViewCommon () {
    const store = useWebStore();
    const user = computed(() => store.user);
    const currentDialog = ref();
    const restService = useWebRestService();
    const i18n = useI18n();
    const d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    const maxDate = formatForMaxDate(d);

    function showPhoneVerifiedMessage () {
        showMessageBoxEx(true, i18n.t("webdesktop.verification.phoneCaption"), i18n.t("webdesktop.verification.phoneVerified"));
    }

    function showEmailVerifiedMessage () {
        showMessageBoxEx(true, i18n.t("webdesktop.verification.emailCaption"), i18n.t("webdesktop.verification.emailVerified"));
    }

    const countryName = computed(() => {
        if (!store.user) {
            return "";
        }
        const country = countries.getById(store.user.countryId);
        return country?.name ?? "";
    });

    function isPhoneVerified (): boolean {
        return user.value ? user.value.verifiedFields.has(EVerifyField.phone) : false;
    }

    function isEmailVerified (): boolean {
        return user.value ? user.value.verifiedFields.has(EVerifyField.email) : false;
    }

    function onDialogClose () {
        currentDialog.value = undefined;
    }

    function showVerification () {
        currentDialog.value = "VerificationDialog";
    }

    function onSubmit (formData: ProfileUpdateData) {
        const u = user.value;
        if (u) {
            const data: UpdateProfileData = {
                identificationCode: !u.IdentificationCode && formData.document ? formData.document : undefined,
                city: formData.city,
                birthday: !u.birthday && formData.birthday ? formData.birthday : undefined,
                lastName: !u.lastName && formData.lastName ? formData.lastName : undefined,
                firstName: !u.firstName && formData.firstName ? formData.firstName : undefined
            };
            restService.updateProfile(data).then(() => {
                showMessageBoxEx(true, undefined, i18n.t("webdesktop.profileView.success"));
                store.updateUserProfile(data);
            });
        }
    }

    return {
        user,
        countryName,
        currentDialog,
        maxDate,
        isPhoneVerified,
        isEmailVerified,
        EVerifyField,
        showPhoneVerifiedMessage,
        showEmailVerifiedMessage,
        onNaturalNumberKeyFilter,
        showVerification,
        onDialogClose,
        onSubmit
    };
}
