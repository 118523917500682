import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { ECardVerificationRule, EPaymentMethod } from "@sportaq/common/enums/bet-slip";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { dateToString, parseDate } from "@sportaq/common/utils/time-utils";

import {
    forEach, getAttribute,
    getChild,
    getIntAttribute,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { stringToBigDecimalDTO } from "@sportaq/common/types/classes/bigdecimal";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import {
    PlaceBetDetailedError,
    PlaceBetResponse,
    PlaceBetResponseExceededMaxSumWin,
    PlaceBetResponseLikedQuotation,
    PlaceBetResponseQuotation,
    PlaceBetResponseQuotationBlocked,
    PlaceBetResponseQuotationChanged,
    PlaceBetResponseSimpleOrSystem,
    PlaceBetResponseSuccessInfo
} from "@sportaq/model/types/place-bet-response";
import { CombinedStakeDTO, EXPRESS_BASE, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { parseQuotationKey } from "@sportaq/services/rest/messages/bet-slip/common/card-functions";

enum ServerCode {
    Success = 1200,
    SystemError = 1201,
    BetLessPermissible = 1202,
    ExceededMaxSumStake = 1203,
    QuotationsHaveChanges = 1204,
    EventStarted = 1205,
    EventFullTime = 1206,
    PositionsHaveChanges = 1207,
    IncorrectNumberOfPositions = 1208,
    PositionLiveServiceNotAvailable = 1209,
    NotEnoughMoneyOnCenterBalance = 1211,
    NotEnoughMoneyOnUserBalance = 1212,
    UserDontHaveOpenAccount = 1214,
    CoefIsNotAllowedForBonus = 1216
}

enum EDetailErrorCode {
    changedCoef = "CHANGED_COEF",
    quotationBlocked = "QUOTATION_BLOCKED",
    eventStarted = "EVENT_STARTED",
    eventFullTime = "EVENT_FULL_TIME",
    positionBlocked = "POSITION_BLOCKED",
    positionLiveServiceNotAvailable = "POSITION_LIVE_SERVICE_NOT_AVAILABLE",
    exceededMaxSumWinAllNetwork = "EXCEEDED_MAX_SUM_WIN_ALL_NETWORK",
    exceededMaxSumWinStation = "EXCEEDED_MAX_SUM_WIN_STATION",
    exceededMaxSumStake = "EXCEEDED_MAX_SUM_STAKE",
    incorrectNumberOfPositions = "INCORRECT_NUMBER_OF_POSITIONS"
}

enum EContainerType {
    Simple = 1, Express = 2, System = 3
}

export class AST12PlaceBetMessageHandler extends AbstractMessageHandler<PlaceBetResponse> {
    protected readonly requestType = "A.ST.1.2";

    constructor (
        private readonly uuidProvider: UUIDProvider,
        private readonly currencyId: number,
        private readonly simples: SimpleStakeDTO[],
        private readonly combined: CombinedStakeDTO[],
        private readonly fullChecking: boolean,
        private readonly paymentMethod: EPaymentMethod
    ) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const root = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(root, "CardVerificationRule",
            this.fullChecking
                ? ECardVerificationRule.FullChecking.toString()
                : ECardVerificationRule.AcceptingWithChangedCoef.toString()
        );
        request.addChildWithValue(root, "PaymentMethod", this.paymentMethod);

        const card = request.addChild(root, "CA", {
            cardGUID: this.uuidProvider.getUUID(),
            acceptTime: dateToString(request.clockProvider)
        });
        this.addSimplesToRequest(request, card);
        this.addCombinedToRequest(request, card);
    }

    parseMessageBody (body: Element): PlaceBetResponse {
        const action = getRequiredChild(body, "action");
        const serverCode: ServerCode = getRequiredIntAttribute(action, "servercode");
        switch (serverCode) {
            case ServerCode.Success: {
                return this.parseSuccessResponse(action);
            }
            case ServerCode.SystemError: {
                return { type: "registrationSystemError" };
            }
            case ServerCode.NotEnoughMoneyOnCenterBalance: {
                return { type: "notEnoughMoneyOnCenterBalance" };
            }
            case ServerCode.NotEnoughMoneyOnUserBalance: {
                return { type: "notEnoughMoneyOnUserBalance" };
            }
            case ServerCode.UserDontHaveOpenAccount: {
                return { type: "userDontHaveOpenAccount" };
            }
            case ServerCode.CoefIsNotAllowedForBonus: {
                return { type: "coefIsNotAllowedForBonus" };
            }
            default: {
                return AST12PlaceBetMessageHandler.parseDetailedError(action);
            }
        }
    }

    private addSimplesToRequest (request: XmlRequest, card: Element) {
        for (const item of this.simples) {
            const container = this.createContainer(request, card, EContainerType.Simple, 1, item.amount);
            this.addCardItem(request, container, item);
        }
    }

    private addCombinedToRequest (request: XmlRequest, card: Element) {
        for (const item of this.combined) {
            const container = this.createContainer(
                request,
                card,
                item.base === EXPRESS_BASE ? EContainerType.Express : EContainerType.System,
                item.base === EXPRESS_BASE ? item.simples.length : item.base,
                item.amount
            );
            if (item.bonus) {
                container.setAttribute("bonusSchemeCode", "3");
            }
            for (const simple of item.simples) {
                this.addCardItem(request, container, simple);
            }
        }
    }

    private createContainer (request: XmlRequest, parent: Element, containerType: EContainerType, expressEvents: number, amount: number): Element {
        return request.addChild(parent, "CC", {
            cardcontainerGUID: this.uuidProvider.getUUID(),
            containerType: containerType.toString(),
            expressEvents: expressEvents.toString(),
            sumStake: amount.toFixed(2),
            currencyTypeId: this.currencyId.toString()
        });
    }

    private addCardItem (request: XmlRequest, parent: Element, item: SimpleStakeDTO) {
        const key = item.key.quotationKey;
        const attrs: Record<string, string> = {
            carditemGUID: this.uuidProvider.getUUID(),
            positionId: item.event.positionId.toString(),
            QTId: key.quotationId.toString(),
            coef: item.coef.toString(),
            p1: key.p1.toString(),
            p2: key.p2.toString(),
            p1Id: key.p1Id.toString(),
            p2Id: key.p2Id.toString()
        };
        request.addChild(parent, "CI", attrs);
    }

    private parseSuccessResponse (elAction: Element): { type: "success"; info: PlaceBetResponseSuccessInfo } {
        const elCard = getRequiredChild(elAction, "CA");
        const cardId = getRequiredIntAttribute(elCard, "cardId");
        const innerCardId = getRequiredIntAttribute(elCard, "innerCardId");
        const payCode = getRequiredAttribute(elCard, "payCode");
        const isBonus = getAttribute(elCard, "isBonus") === "1";
        const acceptServerTime = parseDate(getRequiredAttribute(elCard, "acceptServerTime"));
        const acceptClientTime = parseDate(getRequiredAttribute(elCard, "acceptClientTime"));
        const elCheckList = getChild(elAction, "CheckList");
        const changedCoefs: PlaceBetResponseQuotationChanged[] = [];
        if (elCheckList) {
            forEach(elCheckList, "CheckItem", el => {
                if (getRequiredAttribute(el, "code") === "CHANGED_COEF") {
                    changedCoefs.push(AST12PlaceBetMessageHandler.parseChangeCoefElement(el));
                }
            });
        }
        const info: PlaceBetResponseSuccessInfo = {
            cardId,
            innerCardId,
            isBonus,
            payCode,
            changedCoefs,
            acceptServerTime,
            acceptClientTime
        };
        return {
            type: "success",
            info
        };
    }

    private static parseDetailedError (action: Element): { type: "detailedError"; causes: PlaceBetDetailedError[] } {
        const causes: PlaceBetDetailedError[] = [];
        const checkList = getRequiredChild(action, "CheckList");
        if (checkList) {
            forEach(checkList, "CheckItem", el => {
                const code: EDetailErrorCode = getRequiredAttribute(el, "code") as EDetailErrorCode;
                switch (code) {
                    case EDetailErrorCode.changedCoef: {
                        causes.push({
                            type: "changedCoef",
                            quotation: AST12PlaceBetMessageHandler.parseChangeCoefElement(el)
                        });
                        break;
                    }
                    case EDetailErrorCode.quotationBlocked: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorQuotationBlocked(el));
                        break;
                    }
                    case EDetailErrorCode.eventStarted: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorEventStarted(el));
                        break;
                    }
                    case EDetailErrorCode.eventFullTime: {
                        causes.push({
                            type: "eventFullTime",
                            position: getRequiredIntAttribute(getRequiredChild(el, "EventFullTime"), "positionId")
                        });
                        break;
                    }
                    case EDetailErrorCode.positionBlocked: {
                        causes.push({
                            type: "positionBlocked",
                            position: getRequiredIntAttribute(getRequiredChild(el, "PositionBlocked"), "positionId")
                        });
                        break;
                    }
                    case EDetailErrorCode.positionLiveServiceNotAvailable: {
                        causes.push({
                            type: "positionLiveServiceNotAvailable",
                            position: getRequiredIntAttribute(getRequiredChild(el, "PositionBlocked"), "positionId")
                        });
                        break;
                    }
                    case EDetailErrorCode.exceededMaxSumWinAllNetwork: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorExceededMaxSumWinAllNetwork(el));
                        break;
                    }
                    case EDetailErrorCode.exceededMaxSumWinStation: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorExceededMaxSumWinStation(el));
                        break;
                    }
                    case EDetailErrorCode.exceededMaxSumStake: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorExceededMaxSumStake(el));
                        break;
                    }
                    case EDetailErrorCode.incorrectNumberOfPositions: {
                        causes.push(AST12PlaceBetMessageHandler.parseErrorIncorrectNumberOfPositions(el));
                        break;
                    }
                }
            });
        }
        return {
            type: "detailedError",
            causes
        };
    }

    private static parseErrorQuotationBlocked (elCheckItem: Element): { type: "quotationBlocked"; quotation: PlaceBetResponseQuotationBlocked } {
        const elQuotationBlocked = getRequiredChild(elCheckItem, "QuotationBlocked");
        const quotation = this.readQuotation(elQuotationBlocked);
        const likedQuotations: PlaceBetResponseLikedQuotation[] = [];
        const elLikedList = getChild(elQuotationBlocked, "LikedQuotationList");
        if (elLikedList) {
            forEach(elLikedList, "QT", el => {
                const key = parseQuotationKey(el);
                likedQuotations.push({
                    positionId: quotation.positionId,
                    key,
                    coef: getRequiredFloatAttribute(el, "coef")
                });
            });
        }
        return {
            type: "quotationBlocked",
            quotation: {
                ...quotation,
                likedQuotations
            }
        };
    }

    private static parseErrorExceededMaxSumWinAllNetwork (elCheckItem: Element): { type: "exceededMaxSumWinAllNetwork"; quotation: PlaceBetResponseExceededMaxSumWin } {
        const elExceededMaxSumWin = getRequiredChild(elCheckItem, "ExceededMaxSumWin");
        const quotation = this.readQuotation(elExceededMaxSumWin);
        return {
            type: "exceededMaxSumWinAllNetwork",
            quotation: {
                ...quotation,
                maxStake: getRequiredFloatAttribute(elExceededMaxSumWin, "maxStake")
            }
        };
    }

    private static parseErrorExceededMaxSumWinStation (elCheckItem: Element): { type: "exceededMaxSumWinStation"; quotation: PlaceBetResponseExceededMaxSumWin } {
        const elExceededMaxSumWin = getRequiredChild(elCheckItem, "ExceededMaxSumWin");
        const quotation = this.readQuotation(elExceededMaxSumWin);
        return {
            type: "exceededMaxSumWinStation",
            quotation: {
                ...quotation,
                maxStake: getRequiredFloatAttribute(elExceededMaxSumWin, "maxStake")
            }
        };
    }

    private static parseChangeCoefElement (elCheckItem: Element): PlaceBetResponseQuotationChanged {
        const elChangedCoef = getRequiredChild(elCheckItem, "ChangedCoef");
        const newCoef = getRequiredAttribute(elChangedCoef, "newCoef");
        return {
            ...this.readQuotation(elChangedCoef),
            cardCoef: getRequiredFloatAttribute(elChangedCoef, "cardCoef"),
            newCoef: stringToBigDecimalDTO(newCoef, BIG_DECIMAL_SCALES.BETTING_COEF)
        };
    }

    private static parseErrorEventStarted (elCheckItem: Element): { type: "eventStarted"; position: number } {
        const el = getRequiredChild(elCheckItem, "EventStarted");
        return {
            type: "eventStarted",
            position: getRequiredIntAttribute(el, "positionId")
        };
    }

    private static parseErrorExceededMaxSumStake (elCheckItem: Element): { type: "exceededMaxSumStake"; quotation: PlaceBetResponseSimpleOrSystem } {
        const el = getRequiredChild(elCheckItem, "ExceededMaxSumStake");
        const position = getIntAttribute(el, "positionId");
        const maxStake = getRequiredFloatAttribute(el, "maxStake");
        if (position) {
            return {
                type: "exceededMaxSumStake",
                quotation: {
                    type: "simple",
                    position: {
                        ...this.readQuotation(el),
                        maxStake
                    }
                }
            };
        }
        const containerType = getRequiredIntAttribute(el, "containerType") as EContainerType;

        if (containerType === EContainerType.Express) {
            return {
                type: "exceededMaxSumStake",
                quotation: {
                    type: "system",
                    base: EXPRESS_BASE,
                    maxStake
                }
            };
        }
        return {
            type: "exceededMaxSumStake",
            quotation: {
                type: "system",
                base: getRequiredIntAttribute(el, "expressEvents"),
                maxStake
            }
        };
    }

    private static parseErrorIncorrectNumberOfPositions (elCheckItem: Element): { type: "incorrectNumberOfPositions"; item: { base: number; itemCount: number } } {
        const el = getRequiredChild(elCheckItem, "IncorrectNumberOfPositions");
        const containerType = getRequiredIntAttribute(el, "containerType") as EContainerType;
        return {
            type: "incorrectNumberOfPositions",
            item: {
                base: containerType === EContainerType.Express ? EXPRESS_BASE : getRequiredIntAttribute(el, "expressEvents"),
                itemCount: getRequiredIntAttribute(el, "itemCount")
            }
        };
    }

    private static readQuotation (el: Element): PlaceBetResponseQuotation {
        return {
            positionId: getRequiredIntAttribute(el, "positionId"),
            key: parseQuotationKey(el)
        };
    }
}
