import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { EVerifyField } from "@sportaq/model/common/user-accounts";

export class AUs15ResetPassword extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.US.1.5";

    constructor (readonly data: string, readonly confirmationMethod: EVerifyField) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        if (this.confirmationMethod === EVerifyField.phone) {
            request.addChildWithValue(actionElement, "Phone", this.data);
            request.addChildWithValue(actionElement, "ConfirmationMethod", "phone");
        } else {
            request.addChildWithValue(actionElement, "Email", this.data);
            request.addChildWithValue(actionElement, "ConfirmationMethod", "email");
        }
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1160") {
            return true;
        }
        throw new LocalizedError(`errors.mtl.aUs12.code${serverCode}`);
    }
}
