<template>
    <div class="filter-menu">
        <scroll-buttons :bodyElement="bodyElement" v-if="isScrollButtonsVisible && bodyElement"></scroll-buttons>
        <div class="filter-menu-content"  v-click-outside="onClickOutside">
            <div class="filter-menu-head">
                <betting-filter-menu-date/>
                <div class="filter-menu-close" @click.stop="close" data-cy="filter-menu-close"/>
            </div>
            <div class="filter-menu-body" data-cy="betting-filter-menu" ref="bodyElement">
                <betting-filter-menu-country v-for="(item, index) of items" :key="item.id"
                                             :country="item" :open="index === 0"/>
            </div>
        </div>
    </div>
</template>

<script src="./betting-filter-menu-component.ts"/>
