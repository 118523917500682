import { ref } from "vue";

export function useClickOutside () {
    const menuActive = ref(false);
    let canHandleOutside = false;
    function setActive (value: boolean) {
        if (menuActive.value !== value) {
            menuActive.value = value;
            if (value) {
                setTimeout(() => {
                    canHandleOutside = true;
                }, 20);
            } else {
                canHandleOutside = false;
            }
        }
    }

    function onClickOutside () {
        if (canHandleOutside) {
            setActive(false);
        }
    }

    return {
        menuActive,
        setActive,
        onClickOutside
    };
}
