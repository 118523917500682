<template>
    <div class="game-frame" data-cy="game-frame">
        <slot name="closeButton"/>
        <iframe v-if="currentGameUrl" :src="currentGameUrl" data-cy="iframe-current-game"/>
        <div class="preloader virtual" v-else>
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./game-frame-component.ts"/>
