import { CombinedStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { PropType, SetupContext } from "vue";
import {
    CombinedStakeRowComponent
} from "@sportaq/vue/components/betting/bet-slip/rows/combined-stake-row/common-combined-stake-row-component";
import useCombinedStakeRowComponent = CombinedStakeRowComponent.useCombinedStakeRowComponent;

export default {
    props: {
        stake: {
            type: Object as PropType<CombinedStake>,
            required: true
        }
    },
    emits: [CombinedStakeRowComponent.UPDATE_AMOUNT_EVENT],
    setup (props: CombinedStakeRowComponent.Props, context: SetupContext) {
        return useCombinedStakeRowComponent(props, context);
    }
};
