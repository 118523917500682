import { DiffType } from "@sportaq/model/betting/events/event";
import { EventViewFilter } from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import EventType from "@sportaq/common/enums/event-type";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { markRaw } from "vue";
import {
    EPeriodListResolverType, EventListData,
    PeriodListResolver,
    PeriodListUpdater
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/period-list-resolver";
import {
    ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT,
    MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW
} from "@sportaq/common/consts/default-consts";
import {
    LeagueModePeriodListResolver
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/resolvers/league-mode-period-list-resolver";
import {
    PlainPeriodListResolver
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/resolvers/plain-period-list-resolver";
import {
    ScoreboardPeriodFunctions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/common/functions";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import {
    FavouriteModePeriodListResolver
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/resolvers/favourite-mode-period-list-resolver";
import { IdItem } from "@sportaq/common/types/types";
import { appLogger } from "@sportaq/common/utils/logger";
import {
    FavouriteItem,
    FavouritesResolver,
    FavouritesResolverImpl
} from "@sportaq/vuex/modules/betting/scoreboard/periods/favourites/favourites-resolver";
import { CountryKey } from "@sportaq/model/types/types";
import {
    CountryMenuItem,
    CountryMenuResolver,
    ERefreshCountryMenuType
} from "@sportaq/vuex/modules/betting/scoreboard/periods/country-menu-resolver/country-menu-resolver";
import PeriodSortInfoHelper = ScoreboardPeriodFunctions.PeriodSortInfoHelper;

export enum EScoreboardPageType {
    EVENT_LIST, FAVOURITES, EVENT_DETAILS
}

export class PeriodsController {
    private readonly plainHelper: PeriodSortInfoHelper;
    private readonly favourites: FavouritesResolver;
    private periodListResolver: PeriodListResolver<PeriodSortInfo> | undefined;
    private countryMenuResolver = new CountryMenuResolver();

    periods: PeriodSortInfo[] = [];
    groupEvents: PeriodSortInfo[] = [];
    scoreboardPageType: EScoreboardPageType = EScoreboardPageType.EVENT_LIST;
    leagueMode: boolean = false;
    reverseTimeSorting: boolean = false;

    constructor () {
        this.plainHelper = markRaw(new PeriodSortInfoHelper(
            (event, period, options) => PeriodSortInfo.createEvent(event, period, options)
        ));
        this.favourites = new FavouritesResolverImpl();
    }

    clear () {
        this.periods = [];
        this.groupEvents = [];
        this.scoreboardPageType = EScoreboardPageType.EVENT_LIST;
        this.leagueMode = false;
        this.reverseTimeSorting = false;
        this.favourites.clear();
    }

    syncWithStorage (eventType: EventType, list: EventDiffsResponse[], nonConfirmedPositionIds: number[], eventSupplier: EventSupplier, filter: EventViewFilter, isExpanded: (id: number) => boolean, refreshPositionCallback: (positionId: number, accept: boolean) => void) {
        const eventListData = this.internalSyncPeriodsWithStorage(eventType, list, nonConfirmedPositionIds, eventSupplier, filter, isExpanded, refreshPositionCallback);
        this.addPrematchEventsToLiveWhenLiveCountTooSmall(eventListData.periods, eventSupplier, filter, isExpanded);
        this.periods = eventListData.periods;
        this.groupEvents = eventListData.groupEvents;
    }

    refreshByFilter (eventSupplier: EventSupplier, filter: EventViewFilter, needRefreshCountryMenu: boolean, isExpanded: (positionId: number) => boolean) {
        if ((filter.eventType && filter.sportType) || this.scoreboardPageType === EScoreboardPageType.FAVOURITES) {
            const periodListResolver = this.resolvePeriodListResolver();
            const rebuilder = periodListResolver.batchRebuilder;
            rebuilder.begin();
            this.countryMenuResolver.startBatchUpdate(filter, needRefreshCountryMenu ? ERefreshCountryMenuType.ClearAndRefresh : ERefreshCountryMenuType.Disable);
            if (this.scoreboardPageType === EScoreboardPageType.FAVOURITES) {
                this.favourites.items
                    .map(value => eventSupplier.getOptionalEvent(value.eventType, value.positionId))
                    .forEach(event => {
                        if (event) {
                            const acceptResult = rebuilder.addEvent(filter, event, isExpanded(event.positionId), this.reverseTimeSorting);
                            if (acceptResult.acceptRegardlessCountryFilter) {
                                this.countryMenuResolver.addEvent(filter, event, this.scoreboardPageType);
                            }
                        }
                    });
            } else {
                if (filter.eventType && filter.sportType) {
                    const interestedSports = filter.additionalSportTypes ? [filter.sportType, ...filter.additionalSportTypes] : [filter.sportType];
                    for (const sport of interestedSports) {
                        eventSupplier.forEachBySportType(filter.eventType, sport, event => {
                            const acceptResult = rebuilder.addEvent(filter, event, isExpanded(event.positionId), this.reverseTimeSorting);
                            if (acceptResult.acceptRegardlessCountryFilter) {
                                this.countryMenuResolver.addEvent(filter, event, this.scoreboardPageType);
                            }
                            return true;
                        });
                    }
                }
            }
            this.countryMenuResolver.endBatchUpdate(filter);
            const eventListData = rebuilder.end();
            this.addPrematchEventsToLiveWhenLiveCountTooSmall(eventListData.periods, eventSupplier, filter, isExpanded);

            this.periods = eventListData.periods;
            this.groupEvents = eventListData.groupEvents;
        } else {
            this.periods = [];
            this.groupEvents = [];
        }
    }

    toggleEventExpanded (eventSupplier: EventSupplier, eventType: EventType, eventId: number, expanded: boolean) {
        const currentEvent = eventSupplier.getEvent(eventType, eventId);
        if (currentEvent) {
            const periodListResolver = this.resolvePeriodListResolver();
            this.periods = periodListResolver.expandEvent(this.periods, currentEvent, expanded, this.reverseTimeSorting);
        }
    }

    toggleFavouriteCountry (eventSupplier: EventSupplier, item: CountryKey, filter: EventViewFilter, refreshPositionCallback: (positionId: number, accept: boolean) => void): void {
        this.favourites.toggleCountry(eventSupplier, item, items => this.removeFavouritesFromPeriodsView(items, filter, refreshPositionCallback));
    }

    toggleFavouritePartition (eventSupplier: EventSupplier, country: CountryKey, item: IdItem, filter: EventViewFilter, refreshPositionCallback: (positionId: number, accept: boolean) => void) {
        this.favourites.togglePartition(eventSupplier, country, item, items => this.removeFavouritesFromPeriodsView(items, filter, refreshPositionCallback));
    }

    toggleFavouriteItem (eventSupplier: EventSupplier, eventType: EventType, positionId: number, filter: EventViewFilter, refreshPositionCallback: (positionId: number, accept: boolean) => void) {
        this.favourites.togglePosition(eventSupplier, eventType, positionId, items => this.removeFavouritesFromPeriodsView(items, filter, refreshPositionCallback));
    }

    isCountryFavourite (item: CountryKey) {
        return this.favourites.isCountryFavourite(item);
    }

    isPartitionFavourite (country: CountryKey, item: IdItem) {
        return this.favourites.isPartitionFavourite(country, item);
    }

    isFavourite (positionId: number): boolean {
        return this.favourites.isFavourite(positionId);
    }

    removeCountriesOrPartitions (countries: CountryKey[], partitionIds: number[]) {
        this.favourites.removeCountriesOrPartitions(countries, partitionIds);
    }

    get favouritesCount (): number {
        return this.favourites.items.length;
    }

    get countryMenuItems (): CountryMenuItem[] {
        return this.countryMenuResolver.countryMenuItems;
    }

    private internalSyncPeriodsWithStorage (eventType: EventType, list: EventDiffsResponse[], nonConfirmedPositionIds: number[], eventSupplier: EventSupplier, filter: EventViewFilter, isExpanded: (id: number) => boolean, refreshPositionCallback: (positionId: number, accept: boolean) => void): EventListData<PeriodSortInfo> {
        const periodListResolver = this.resolvePeriodListResolver();
        const batchUpdater = periodListResolver.batchUpdater;
        batchUpdater.begin({
            periods: this.periods,
            groupEvents: this.groupEvents
        });
        this.countryMenuResolver.startBatchUpdate(filter, ERefreshCountryMenuType.Refresh);
        for (const diff of list) {
            const currentEvent = eventSupplier.getOptionalEvent(eventType, diff.positionId);
            switch (diff.diffType) {
                case DiffType.NEW:
                case DiffType.UPDATE: {
                    if (currentEvent && currentEvent.version === diff.version) {
                        if (diff.diffType === DiffType.NEW) {
                            this.favourites.receiveNewEvent(eventSupplier, currentEvent);
                        }
                        if (this.scoreboardPageType !== EScoreboardPageType.FAVOURITES || this.favourites.items.some(value => value.positionId === diff.positionId)) {
                            const acceptResult = batchUpdater.addOrUpdateEvent(filter, currentEvent, isExpanded(currentEvent.positionId), this.reverseTimeSorting);
                            refreshPositionCallback(diff.positionId, acceptResult.accept);
                            if (diff.diffType === DiffType.NEW && acceptResult.acceptRegardlessCountryFilter) {
                                this.countryMenuResolver.addEvent(filter, currentEvent, this.scoreboardPageType);
                            }
                        }
                    } else {
                        const diffJson = JSON.stringify(diff);
                        if (!currentEvent) {
                            appLogger.logger.warn(`SyncPeriodsWithStorage: Position with id=${diff.positionId} for ${diff.diffType === DiffType.NEW ? "NEW" : "UPDATE"} diff wasn't founded in event storage. Event diff has been omitted\nDiff:\n${diffJson}`);
                        } else {
                            appLogger.logger.warn(`SyncPeriodsWithStorage: Position with id=${diff.positionId} for ${diff.diffType === DiffType.NEW ? "NEW" : "UPDATE"} has version ${currentEvent.version} and diff has different version ${diff.version}, but the diff should have been applied. Event diff has been omitted\nDiff:\n${diffJson}\nCurrent event:\n${JSON.stringify(currentEvent)}`);
                        }
                    }
                    break;
                }
                case DiffType.DELETE: {
                    if (!currentEvent) {
                        this.deletePosition(batchUpdater, diff.positionId, refreshPositionCallback);
                        this.countryMenuResolver.removePosition(filter, diff.positionId);
                    } else {
                        appLogger.logger.warn(`SyncPeriodsWithStorage: Position with id=${diff.positionId} for DELETE diff was founded in event storage. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}\nCurrent event:\n${JSON.stringify(currentEvent)}`, undefined);
                    }
                    break;
                }
            }
        }
        for (const nonConfirmedPosition of nonConfirmedPositionIds) {
            this.deletePosition(batchUpdater, nonConfirmedPosition, refreshPositionCallback);
            this.countryMenuResolver.removePosition(filter, nonConfirmedPosition);
        }
        this.countryMenuResolver.endBatchUpdate(filter);
        return batchUpdater.end();
    }

    private deletePosition (batchUpdater: PeriodListUpdater<PeriodSortInfo>, positionId: number, refreshPositionCallback: (positionId: number, accept: boolean) => void) {
        batchUpdater.deleteEvent(positionId);
        refreshPositionCallback(positionId, false);
        this.favourites.removePosition(positionId);
    }

    private removeFavouritesFromPeriodsView (items: FavouriteItem[], filter: EventViewFilter, refreshPositionCallback: (positionId: number, accept: boolean) => void) {
        if (this.scoreboardPageType === EScoreboardPageType.FAVOURITES) {
            // Delete removed item from periods
            const periodListResolver = this.resolvePeriodListResolver();
            const batchUpdater = periodListResolver.batchUpdater;
            this.countryMenuResolver.startBatchUpdate(filter, ERefreshCountryMenuType.Refresh);
            batchUpdater.begin({
                periods: this.periods,
                groupEvents: this.groupEvents
            });
            for (const item of items) {
                batchUpdater.deleteEvent(item.positionId);
                refreshPositionCallback(item.positionId, false);
                this.countryMenuResolver.removePosition(filter, item.positionId);
            }
            this.countryMenuResolver.endBatchUpdate(filter);
            const eventListData = batchUpdater.end();
            this.periods = eventListData.periods;
            this.groupEvents = eventListData.groupEvents;
        }
    }

    private resolvePeriodListResolver (): PeriodListResolver<PeriodSortInfo> {
        const needed = this.resolvePeriodListResolverType();
        if (!this.periodListResolver || this.periodListResolver.type !== needed) {
            let resolver: PeriodListResolver<PeriodSortInfo>;
            switch (needed) {
                case EPeriodListResolverType.FAVOURITES:
                case EPeriodListResolverType.FAVOURITES_WITH_LEAGUES: {
                    resolver = new FavouriteModePeriodListResolver(this.leagueMode);
                    break;
                }
                case EPeriodListResolverType.LEAGUE: {
                    resolver = new LeagueModePeriodListResolver();
                    break;
                }
                case EPeriodListResolverType.PLAIN: {
                    resolver = new PlainPeriodListResolver();
                }
            }
            this.periodListResolver = markRaw(resolver);
        }
        return this.periodListResolver;
    }

    private resolvePeriodListResolverType () {
        if (this.scoreboardPageType === EScoreboardPageType.FAVOURITES) {
            return this.leagueMode ? EPeriodListResolverType.FAVOURITES_WITH_LEAGUES : EPeriodListResolverType.FAVOURITES;
        }
        if (this.leagueMode) {
            return EPeriodListResolverType.LEAGUE;
        }
        return EPeriodListResolverType.PLAIN;
    }

    // Adding preMatch events to live if live count too small but not less than ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT
    private addPrematchEventsToLiveWhenLiveCountTooSmall (periods: PeriodSortInfo[], eventSupplier: EventSupplier, filter: EventViewFilter, isExpanded: (id: number) => boolean) {
        if (!this.leagueMode && this.scoreboardPageType !== EScoreboardPageType.FAVOURITES && filter.eventType === EventType.LIVE && filter.sportType) {
            const distinctPositionIds: number[] = [];
            let preMatchCounter = 0;
            for (const p of periods) {
                if (distinctPositionIds.indexOf(p.positionId) < 0) {
                    distinctPositionIds.push(p.positionId);
                    if (p.eventType === EventType.PRE_MATCH) {
                        preMatchCounter++;
                    }
                }
            }
            if (distinctPositionIds.length < MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW || preMatchCounter < ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT) {
                const needsToAddPretty = distinctPositionIds.length < MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW ? MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW - distinctPositionIds.length : 0;
                const needsToAddAlways = preMatchCounter < ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT ? ALWAYS_ADD_PREMATCH_TO_LIVE_COUNT - preMatchCounter : 0;
                const needsToAdd = Math.max(needsToAddPretty, needsToAddAlways);
                let counter = 0;
                eventSupplier.forEachBySportType(EventType.PRE_MATCH, filter.sportType, event => {
                    const acceptResult = filter.tryAccept(event, this.scoreboardPageType === EScoreboardPageType.FAVOURITES, { eventType: EventType.PRE_MATCH });
                    if (acceptResult.accept && periods.every(e => e.eventId !== event.eventId)) {
                        this.plainHelper.insertNewEvent(periods, event, isExpanded(event.positionId), { reverseTimeSorting: this.reverseTimeSorting });
                        counter++;
                    }
                    return counter < needsToAdd;
                });
            }
        }
    }
}
