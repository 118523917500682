import { CombinedStakeDTO, SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { computed, PropType, SetupContext } from "vue";
import MobileInnerStakeTable
    from "@/components/betting/betslip/dialogs/common/inner-stake-table/MobileInnerStakeTable.vue";
import { calculateBetTotal } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useSettings } from "@sportaq/services/index";

const EVENT_CONFIRM = "confirm";
const EVENT_CANCEL = "cancel";

interface Props {
    simpleStakes: SimpleStakeDTO[];
    combinedStakes: CombinedStakeDTO[];
}

export default {
    props: {
        simpleStakes: {
            required: true,
            type: Object as PropType<SimpleStakeDTO[]>
        },
        combinedStakes: {
            required: true,
            type: Object as PropType<CombinedStakeDTO[]>
        }
    },
    components: {
        MobileInnerStakeTable
    },
    emits: [EVENT_CONFIRM, EVENT_CANCEL],

    setup (props: Props, context: SetupContext) {
        const settings = useSettings();
        const currency = settings.pointSettings.currencyName;

        const betTotal = computed(() => calculateBetTotal(props.simpleStakes, props.combinedStakes, settings.pointSettings.draftCoef));

        function confirm () {
            context.emit(EVENT_CONFIRM);
        }

        function cancel () {
            context.emit(EVENT_CANCEL);
        }

        return {
            currency,
            betTotal,
            cancel,
            confirm
        };
    }
};
