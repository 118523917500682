import { useWebRestService } from "@sportaq/services/web";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { ref, SetupContext } from "vue";

interface FormData {
    currentPassword: string;
    password: string;
    confirmPassword: string;
}

export function useChangePasswordDialogCommon (context: SetupContext) {
    const restService = useWebRestService();
    const i18n = useI18n();
    const oldPasswordVisible = ref(false);
    const newPasswordVisible = ref(false);
    const confirmPasswordVisible = ref(false);
    const isRequestPending = ref(false);

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function changePassword (data: FormData) {
        isRequestPending.value = true;
        restService.changePassword(data.currentPassword, data.password).then(() => {
            isRequestPending.value = false;
            onClose();
            showMessageBoxEx(true, undefined, i18n.t("webdesktop.changePasswordDialog.successMessage"));
        }).catch(() => {
            isRequestPending.value = false;
        });
    }
    return {
        oldPasswordVisible,
        newPasswordVisible,
        confirmPasswordVisible,
        isRequestPending,
        onClose,
        changePassword
    };
}
