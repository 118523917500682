/* eslint-disable camelcase */
enum EDetailRow {
        americanFootball_x1_2 = 1,
        americanFootball_total = 2,
        americanFootball_handicap = 3,
        americanFootball_individualTotalTeam1 = 4,
        americanFootball_individualTotalTeam2 = 5,
        americanFootball_evenOddMatch = 6,
        americanFootball_x1x2Quarter1 = 7,
        americanFootball_totalQuarter1 = 8,
        americanFootball_handicapQuarter1 = 9,
        americanFootball_individualTotalTeam1Quarter1 = 10,
        americanFootball_individualTotalTeam2Quarter1 = 11,
        americanFootball_evenOddMatchQuarter1 = 12,
        americanFootball_x1x2Quarter2 = 13,
        americanFootball_totalQuarter2 = 14,
        americanFootball_handicapQuarter2 = 15,
        americanFootball_individualTotalTeam1Quarter2 = 16,
        americanFootball_individualTotalTeam2Quarter2 = 17,
        americanFootball_evenOddMatchQuarter2 = 18,
        americanFootball_x1x2Quarter3 = 19,
        americanFootball_totalQuarter3 = 20,
        americanFootball_handicapQuarter3 = 21,
        americanFootball_individualTotalTeam1Quarter3 = 22,
        americanFootball_individualTotalTeam2Quarter3 = 23,
        americanFootball_evenOddMatchQuarter3 = 24,
        americanFootball_x1x2Quarter4 = 25,
        americanFootball_totalQuarter4 = 26,
        americanFootball_handicapQuarter4 = 27,
        americanFootball_individualTotalTeam1Quarter4 = 28,
        americanFootball_individualTotalTeam2Quarter4 = 29,
        americanFootball_evenOddMatchQuarter4 = 30,
        americanFootball_x1x2Half1 = 31,
        americanFootball_totalHalf1 = 32,
        americanFootball_handicapHalf1 = 33,
        americanFootball_individualTotalTeam1Half1 = 34,
        americanFootball_individualTotalTeam2Half1 = 35,
        americanFootball_evenOddMatchHalf1 = 36,
        americanFootball_x1x2Half2 = 37,
        americanFootball_totalHalf2 = 38,
        americanFootball_handicapHalf2 = 39,
        americanFootball_individualTotalTeam1Half2 = 40,
        americanFootball_individualTotalTeam2Half2 = 41,
        baseball_x1_2 = 42,
        baseball_total = 43,
        baseball_handicaps = 44,
        baseball_individualTotalTeam1 = 45,
        baseball_individualTotalTeam2 = 46,
        baseball_evenOddMatch = 47,
        baseball_x1x2Inning1 = 48,
        baseball_totalInning1 = 49,
        baseball_handicapInning1 = 50,
        baseball_x1x2Inning2 = 51,
        baseball_totalInning2 = 52,
        baseball_handicapInning2 = 53,
        baseball_x1x2Inning3 = 54,
        baseball_totalInning3 = 55,
        baseball_handicapInning3 = 56,
        baseball_x1x2Inning4 = 57,
        baseball_totalInning4 = 58,
        baseball_handicapInning4 = 59,
        baseball_x1x2Inning5 = 60,
        baseball_totalInning5 = 61,
        baseball_handicapInning5 = 62,
        baseball_x1x2Inning6 = 63,
        baseball_totalInning6 = 64,
        baseball_handicapInning6 = 65,
        baseball_x1x2Inning7 = 66,
        baseball_totalInning7 = 67,
        baseball_handicapInning7 = 68,
        baseball_x1x2Inning8 = 69,
        baseball_totalInning8 = 70,
        baseball_handicapInning8 = 71,
        baseball_x1x2Inning9 = 72,
        baseball_totalInning9 = 73,
        baseball_handicapInning9 = 74,
        baseball_x1x2Half1 = 75,
        baseball_totalHalf1 = 76,
        baseball_handicapHalf1 = 77,
        baseball_handicap = 78,
        basketball_x1x2 = 79,
        basketball_total = 80,
        basketball_handicap = 81,
        basketball_individualTotalTeam1 = 82,
        basketball_individualTotalTeam2 = 83,
        basketball_evenOddMatch = 84,
        basketball_x1x2Quarter1 = 85,
        basketball_totalQuarter1 = 86,
        basketball_handicapQuarter1 = 87,
        basketball_individualTotalTeam1Quarter1 = 88,
        basketball_individualTotalTeam2Quarter1 = 89,
        basketball_evenOddMatchQuarter1 = 90,
        basketball_x1x2Quarter2 = 91,
        basketball_totalQuarter2 = 92,
        basketball_handicapQuarter2 = 93,
        basketball_individualTotalTeam1Quarter2 = 94,
        basketball_individualTotalTeam2Quarter2 = 95,
        basketball_evenOddMatchQuarter2 = 96,
        basketball_x1x2Quarter3 = 97,
        basketball_totalQuarter3 = 98,
        basketball_handicapQuarter3 = 99,
        basketball_individualTotalTeam1Quarter3 = 100,
        basketball_individualTotalTeam2Quarter3 = 101,
        basketball_evenOddMatchQuarter3 = 102,
        basketball_x1x2Quarter4 = 103,
        basketball_totalQuarter4 = 104,
        basketball_handicapQuarter4 = 105,
        basketball_individualTotalTeam1Quarter4 = 106,
        basketball_individualTotalTeam2Quarter4 = 107,
        basketball_evenOddMatchQuarter4 = 108,
        basketball_x1x2Half1 = 109,
        basketball_totalHalf1 = 110,
        basketball_handicapHalf1 = 111,
        basketball_individualTotalTeam1Half1 = 112,
        basketball_individualTotalTeam2Half1 = 113,
        basketball_evenOddMatchHalf1 = 114,
        basketball_x1x2Half2 = 115,
        basketball_totalHalf2 = 116,
        basketball_handicapHalf2 = 117,
        basketball_individualTotalTeam1Half2 = 118,
        basketball_individualTotalTeam2Half2 = 119,
        football_x1x2 = 120,
        football_x1x12x2 = 121,
        football_total = 122,
        football_individualTotalTeam1 = 123,
        football_individualTotalTeam2 = 124,
        football_scoreTeam1 = 125,
        football_scoreTeam2 = 126,
        football_scoreBoth = 127,
        football_handicap = 128,
        football_goalWillScore1 = 129,
        football_goalWillScore2 = 130,
        football_goalWillScore3 = 131,
        football_goalWillScore4 = 132,
        football_goalWillScore5 = 133,
        football_goalWillScore6 = 134,
        football_goalWillScore7 = 135,
        football_goalWillScore8 = 136,
        football_goalWillScore9 = 137,
        football_goalWillScore10 = 138,
        football_halfMatch = 139,
        football_goalHalf1 = 140,
        football_goalHalf2 = 141,
        football_goalBothHalves = 142,
        football_goalTeam1BothHalves = 143,
        football_goalTeam2BothHalves = 144,
        football_scoreBothHalf1 = 145,
        football_scoreBothHalf2 = 146,
        football_goalTeam1Half1 = 147,
        football_goalTeam2Half1 = 148,
        football_goalTeam1Half2 = 149,
        football_goalTeam2Half2 = 150,
        football_winBothHalves = 151,
        football_firstGoalTeam = 152,
        football_lastGoalTeam = 153,
        football_scoreBothTeam1 = 154,
        football_scoreBothTeam2 = 155,
        football_scoreBothDraw = 156,
        football_scoreBoth1X = 157,
        football_scoreBoth12 = 158,
        football_scoreBothX2 = 159,
        football_atLeastOneWontScoreTeam1 = 160,
        football_atLeastOneWontScoreDraw = 161,
        football_atLeastOneWontScoreTeam2 = 162,
        football_atLeastOneWontScore1X = 163,
        football_atLeastOneWontScore12 = 164,
        football_atLeastOneWontScoreX2 = 165,
        football_team1Goal1Team1 = 166,
        football_team1Goal1Team2 = 167,
        football_team1Goal1Draw = 168,
        football_team1Goal11X = 169,
        football_team1Goal112 = 170,
        football_team1Goal1X2 = 171,
        football_team2Goal1Team2 = 172,
        football_team2Goal1Team1 = 173,
        football_team2Goal1Draw = 174,
        football_team2Goal11X = 175,
        football_team2Goal112 = 176,
        football_team2Goal1X2 = 177,
        football_team1TotalUnder = 178,
        football_team1TotalOver = 179,
        football_team2TotalUnder = 180,
        football_team2TotalOver = 181,
        football_DrawTotalUnder = 182,
        football_DrawTotalOver = 183,
        football_x1TotalUnder = 184,
        football_x1TotalOver = 185,
        football_12TotalUnder = 186,
        football_x12TotalOver = 187,
        football_x2TotalUnder = 188,
        football_x2TotalOver = 189,
        football_bothScoreTotalUnder = 190,
        football_bothScoreTotalOver = 191,
        football_atLeastOneWontScoreTotalUnder = 192,
        football_atLeastOneWontScoreTotalOver = 193,
        football_matchScore = 194,
        football_halvesComparison = 195,
        football_dryWin = 196,
        football_strongWilledWin = 197,
        football_passage = 198,
        football_evenOddMatch = 199,
        football_evenOddHalf1 = 200,
        football_goalIntervalx1x15 = 201,
        football_goalIntervalx1x30 = 202,
        football_goalIntervalx1x45 = 203,
        football_goalIntervalx1x60 = 204,
        football_goalIntervalx1x75 = 205,
        football_goalIntervalx16x30 = 206,
        football_goalIntervalx31x45 = 207,
        football_goalIntervalx46x60 = 208,
        football_goalIntervalx61x75 = 209,
        football_goalIntervalx76x90 = 210,
        football_goalIntervalTeam1x1x15 = 211,
        football_goalIntervalTeam1x1x30 = 212,
        football_goalIntervalTeam1x1x45 = 213,
        football_goalIntervalTeam1x1x60 = 214,
        football_goalIntervalTeam1x1x75 = 215,
        football_goalIntervalTeam1x16x30 = 216,
        football_goalIntervalTeam1x31x45 = 217,
        football_goalIntervalTeam1x46x60 = 218,
        football_goalIntervalTeam1x61x75 = 219,
        football_goalIntervalTeam1x76x90 = 220,
        football_goalIntervalTeam2x1x15 = 221,
        football_goalIntervalTeam2x1x30 = 222,
        football_goalIntervalTeam2x1x45 = 223,
        football_goalIntervalTeam2x1x60 = 224,
        football_goalIntervalTeam2x1x75 = 225,
        football_goalIntervalTeam2x16x30 = 226,
        football_goalIntervalTeam2x31x45 = 227,
        football_goalIntervalTeam2x46x60 = 228,
        football_goalIntervalTeam2x61x75 = 229,
        football_goalIntervalTeam2x76x90 = 230,
        football_x1x2fHalf1 = 231,
        football_x1x12x2Half1 = 232,
        football_handicapHalf1 = 233,
        football_totalHalf1 = 234,
        football_individualTotalTeam1Half1 = 235,
        football_individualTotalTeam2Half1 = 236,
        football_x1x2fHalf2 = 237,
        football_x1x12x2Half2 = 238,
        football_handicapHalf2 = 239,
        football_totalHalf2 = 240,
        football_individualTotalTeam1Half2 = 241,
        football_individualTotalTeam2Half2 = 242,
        football_totalBalls = 243,
        football_handicapMatch = 244,
        handball_x1x2 = 245,
        handball_x1x12x2 = 246,
        handball_total = 247,
        handball_handicap = 248,
        handball_individualTotalTeam1 = 249,
        handball_individualTotalTeam2 = 250,
        handball_x1x2fHalf1 = 251,
        handball_x1x12x2Half1 = 252,
        handball_handicapHalf1 = 253,
        handball_totalHalf1 = 254,
        handball_individualTotalTeam1Half1 = 255,
        handball_individualTotalTeam2Half1 = 256,
        handball_x1x2fHalf2 = 257,
        handball_x1x12x2Half2 = 258,
        handball_handicapHalf2 = 259,
        handball_totalHalf2 = 260,
        handball_individualTotalTeam1Half2 = 261,
        handball_individualTotalTeam2Half2 = 262,
        handball_passage = 263,
        handball_evenOddMatch = 264,
        handball_evenOddHalf1 = 265,
        handball_handicapMatch = 266,
        hockey_x1x2 = 267,
        hockey_x1x12x2 = 268,
        hockey_total = 269,
        hockey_handicap = 270,
        hockey_individualTotalTeam1 = 271,
        hockey_individualTotalTeam2 = 272,
        hockey_x1x2Period1 = 273,
        hockey_x1x12x2Period1 = 274,
        hockey_totalPeriod1 = 275,
        hockey_handicapPeriod1 = 276,
        hockey_x1x2Period2 = 277,
        hockey_x1x12x2Period2 = 278,
        hockey_totalPeriod2 = 279,
        hockey_handicapPeriod2 = 280,
        hockey_x1x2Period3 = 281,
        hockey_x1x12x2Period3 = 282,
        hockey_totalPeriod3 = 283,
        hockey_handicapPeriod3 = 284,
        hockey_totalMatch = 285,
        tableTennis_x1_2 = 286,
        tableTennis_total = 287,
        tableTennis_handicap = 288,
        tableTennis_individualTotalTeam1 = 289,
        tableTennis_individualTotalTeam2 = 290,
        tableTennis_evenOddMatch = 291,
        tableTennis_matchScore = 292,
        tableTennis_x1_2Set1 = 293,
        tableTennis_totalSet1 = 294,
        tableTennis_handicapSet1 = 295,
        tableTennis_evenOddSet1 = 296,
        tableTennis_x1_2Set2 = 297,
        tableTennis_totalSet2 = 298,
        tableTennis_handicapSet2 = 299,
        tableTennis_evenOddSet2 = 300,
        tableTennis_x1_2Set3 = 301,
        tableTennis_totalSet3 = 302,
        tableTennis_handicapSet3 = 303,
        tableTennis_evenOddSet3 = 304,
        tableTennis_x1_2Set4 = 305,
        tableTennis_totalSet4 = 306,
        tableTennis_handicapSet4 = 307,
        tableTennis_evenOddSet4 = 308,
        tableTennis_x1_2Set5 = 309,
        tableTennis_totalSet5 = 310,
        tableTennis_handicapSet5 = 311,
        tableTennis_evenOddSet5 = 312,
        tableTennis_totalMatch = 313,
        tennis_x1_2 = 314,
        tennis_total = 315,
        tennis_handicap = 316,
        tennis_individualTotalTeam1 = 317,
        tennis_individualTotalTeam2 = 318,
        tennis_evenOddMatch = 319,
        tennis_matchScore = 320,
        tennis_x1_2Set1 = 321,
        tennis_totalSet1 = 322,
        tennis_handicapSet1 = 323,
        tennis_evenOddSet1 = 324,
        tennis_x1_2Set2 = 325,
        tennis_totalSet2 = 326,
        tennis_handicapSet2 = 327,
        tennis_evenOddSet2 = 328,
        tennis_x1_2Set3 = 329,
        tennis_totalSet3 = 330,
        tennis_handicapSet3 = 331,
        tennis_evenOddSet3 = 332,
        tennis_x1_2Set4 = 333,
        tennis_totalSet4 = 334,
        tennis_handicapSet4 = 335,
        tennis_evenOddSet4 = 336,
        tennis_x1_2Set5 = 337,
        tennis_totalSet5 = 338,
        tennis_handicapSet5 = 339,
        tennis_evenOddSet5 = 340,
        tennis_totalMatch = 341,
        volleyball_x1_2 = 342,
        volleyball_total = 343,
        volleyball_handicap = 344,
        volleyball_individualTotalTeam1 = 345,
        volleyball_individualTotalTeam2 = 346,
        volleyball_evenOddMatch = 347,
        volleyball_matchScore = 348,
        volleyball_scoreAfter2Games = 349,
        volleyball_x1_2Set1 = 350,
        volleyball_totalSet1 = 351,
        volleyball_handicapSet1 = 352,
        volleyball_evenOddSet1 = 353,
        volleyball_x1_2Set2 = 354,
        volleyball_totalSet2 = 355,
        volleyball_handicapSet2 = 356,
        volleyball_evenOddSet2 = 357,
        volleyball_x1_2Set3 = 358,
        volleyball_totalSet3 = 359,
        volleyball_handicapSet3 = 360,
        volleyball_evenOddSet3 = 361,
        volleyball_x1_2Set4 = 362,
        volleyball_totalSet4 = 363,
        volleyball_handicapSet4 = 364,
        volleyball_evenOddSet4 = 365,
        volleyball_x1_2Set5 = 366,
        volleyball_totalSet5 = 367,
        volleyball_handicapSet5 = 368,
        volleyball_evenOddSet5 = 369,
        volleyball_totalMatch = 370
}

export default EDetailRow;
