import { isPaymentSystemPresent, PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { ref } from "vue";
import { EUserRole, EVerifyField, hasRole } from "@sportaq/model/common/user-accounts";
import { usePointList } from "@sportaq/vue/views/web/withdrawal/pount-list";
import { useRootStore } from "@sportaq/vuex/index";
import { useTextComponentCommon } from "@sportaq/vue/views/web/rules/components/text-component-common";

export function useWithdrawalViewCommon () {
    const rootStore = useRootStore();
    const store = useWebStore();
    const settings = useWebSettings();
    const currentDialog = ref();
    const minAmount = ref(20);
    const selectedPaymentSystem = ref<PaymentSystems>();
    const isVerificationDialogVisible = ref(false);
    const pointList = usePointList(useWebRestService(), useWebSettings().pointSettings);

    const countryId = rootStore.userSharedInfo.countryId;

    function onDialogClose () {
        currentDialog.value = undefined;
    }

    function isPaymentSystemAvailable (paymentSystem: PaymentSystems) {
        return store.user !== undefined
            ? isPaymentSystemPresent(store.user.paymentSystems, paymentSystem)
            : settings.pointSettings.isPaymentSystemAvailable(paymentSystem);
    }

    function openWithdrawalDialog (paymentSystem: PaymentSystems) {
        selectedPaymentSystem.value = paymentSystem;
        if (isUserVerified() || hasRole(store.user, EUserRole.EXPRESS)) {
            internalOpenWithdrawal(paymentSystem);
        } else {
            isVerificationDialogVisible.value = true;
        }
    }

    function internalOpenWithdrawal (paymentSystem: PaymentSystems) {
        minAmount.value = 20;
        switch (paymentSystem) {
            case PaymentSystems.WITHDRAWAL_METHOD_YAPE:
            case PaymentSystems.WITHDRAWAL_METHOD_TUNKI:
            case PaymentSystems.WITHDRAWAL_METHOD_LUKITA: {
                currentDialog.value = "GeneralWithdrawalDialog";
                break;
            }
            case PaymentSystems.WITHDRAWAL_METHOD_VOUCHER: {
                minAmount.value = 1;
                currentDialog.value = "PartnerWithdrawalDialog";
                break;
            }
            case PaymentSystems.WITHDRAWAL_METHOD_BANK_CARD: {
                if (countryId === 62) {
                    minAmount.value = 5;
                    currentDialog.value = "EquadorWithdrawalDialog";
                } else {
                    currentDialog.value = "VisaWithdrawalDialog";
                }
                break;
            }
        }
    }

    function isUserVerified (): boolean {
        const user = store.user;
        if (!user) {
            return false;
        }
        return user.verifiedFields.has(EVerifyField.phone) && user.verifiedFields.has(EVerifyField.email);
    }

    function onVerificationDialogClose () {
        isVerificationDialogVisible.value = false;
        if (isUserVerified() && selectedPaymentSystem.value) {
            openWithdrawalDialog(selectedPaymentSystem.value);
        }
    }

    const { brandName } = useTextComponentCommon();

    return {
        selectedPaymentSystem,
        currentDialog,
        minAmount,
        countryId,
        isVerificationDialogVisible,
        pointList,
        brandName,
        onVerificationDialogClose,
        openWithdrawalDialog,
        isPaymentSystemAvailable,
        onDialogClose
    };
}
