import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { dateToMtlStr } from "@sportaq/common/utils/time-utils";
import { BaseRaceStakesHandler } from "@sportaq/services/rest/messages/games/race/base-race-stakes-handler";

export class QRs12GetRaceStakesHandler extends BaseRaceStakesHandler<HorseWagerContainer[]> {
    protected readonly requestTypeCashier: string = "Q.RS.1.2";
    protected readonly requestType: string = "Q.RS.1.1";

    constructor (readonly isCashier: boolean, readonly startDate: Date, readonly endDate: Date | undefined, readonly lastOperationId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.isCashier ? this.requestTypeCashier : this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        request.addChildWithValue(filterElement, "startDate", dateToMtlStr(this.startDate));
        if (this.endDate) {
            request.addChildWithValue(filterElement, "endDate", dateToMtlStr(this.endDate));
        }
        request.addChildWithValue(filterElement, "lasContainerId", this.lastOperationId.toString());
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): HorseWagerContainer[] {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode !== "8550") {
            throw new LocalizedError(`errors.mtl.${this.isCashier ? "qRs12" : "qRs11"}.code${serverCode}`);
        }
        const result: HorseWagerContainer[] = [];
        const wagerContainerListElement = getRequiredChild(query, "RaceContainerList");
        for (let wcElement = wagerContainerListElement.firstElementChild; wcElement != null; wcElement = wcElement.nextElementSibling) {
            const rc = this.parseContainer(wcElement);
            result.push(rc);
        }
        return result;
    }
}
