import { useI18n } from "vue-i18n";
import { useSettings } from "@sportaq/services/index";

export function useTextComponentCommon () {
    const i18n = useI18n();
    const settings = useSettings();
    const brandName = i18n.t("brands." + settings.brandType);
    const support = i18n.t("brands.support." + settings.brandType);
    return {
        brandName,
        support
    };
}