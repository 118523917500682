import { EUserRole, EVerifyField, User, UserAccountsImpl, UserSession } from "@sportaq/model/common/user-accounts";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntAttribute,
    getRequiredIntValue,
    getRequiredValue,
    getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { stripDni } from "@sportaq/common/utils/string-utils";
import { PaymentSystemData } from "@sportaq/common/enums/payment-systems";
import { toLocalTime } from "@sportaq/common/utils/time-utils";
import { parsePaymentSystemList } from "@sportaq/services/rest/messages/system/point-settings-message-handler";

function checkZeroBirthday (birthday: string): string {
    return birthday === "0000-00-00" ? "" : birthday;
}
export function parseUser (userElement: Element): User {
    const registrationTimeElement = getChild(userElement, "RegistrationTime");
    const userIdElement = getChild(userElement, "UserId");
    const cityElement = getChild(userElement, "CityText");
    const identificationCodeElement = getChild(userElement, "IdentificationCode");
    const roles: EUserRole[] = [];
    const sessions: UserSession[] = [];
    const paymentSystems: PaymentSystemData[] = parsePaymentSystemList(userElement);
    const rolesElement = getChild(userElement, "UserRoleList");
    if (rolesElement !== undefined) {
        for (let roleElement: Element | null = rolesElement.firstElementChild; roleElement !== null; roleElement = roleElement?.nextElementSibling) {
            roles.push(getRequiredIntAttribute(roleElement, "Id") as EUserRole);
        }
    }
    const sessionsElement = getChild(userElement, "UserSessions");
    if (sessionsElement !== undefined) {
        for (let sessionElement: Element | null = sessionsElement.firstElementChild; sessionElement !== null; sessionElement = sessionElement?.nextElementSibling) {
            sessions.push({
                id: getRequiredIntAttribute(sessionElement, sessionsElement.hasAttribute("Id") ? "Id" : "sessionId"),
                ipAddress: getRequiredAttribute(sessionElement, "ipAddress"),
                registerTime: toLocalTime(new Date(getRequiredAttribute(sessionElement, "registerTime")))
            });
        }
    }
    const verifiedFields = new Set<EVerifyField>();
    const userVerifyElement = getChild(userElement, "UserVerifyFieldList");
    if (userVerifyElement) {
        for (let uf = userVerifyElement.firstElementChild; uf != null; uf = uf.nextElementSibling) {
            if (getRequiredAttribute(uf, "isVerify") === "true") {
                verifiedFields.add(EVerifyField[getRequiredAttribute(uf, "fieldName") as keyof typeof EVerifyField]);
            }
        }
    }
    return {
        userId: userIdElement ? getRequiredIntValue(userIdElement) : 0,
        userNumber: getRequiredValue(getRequiredChild(userElement, "UserNumber")),
        firstName: getValue(getRequiredChild(userElement, "FirstName")),
        lastName: getValue(getRequiredChild(userElement, "LastName")),
        middleName: getValue(getRequiredChild(userElement, "MiddleName")),
        address: getValue(getRequiredChild(userElement, "Address")),
        phone: getValue(getRequiredChild(userElement, "Phone")),
        email: getValue(getRequiredChild(userElement, "Email")),
        login: getRequiredValue(getRequiredChild(userElement, "Login")),
        partnerId: getChild(userElement, "PartnerId") ? getRequiredIntValue(getRequiredChild(userElement, "PartnerId")) : 0,
        IdentificationCode: identificationCodeElement ? stripDni(getValue(identificationCodeElement)) : "",
        verifiedFields,
        verified: verifiedFields.has(EVerifyField.identificationCode),
        birthday: checkZeroBirthday(getRequiredValue(getRequiredChild(userElement, "Birthday"))),
        userAccounts: new UserAccountsImpl([]),
        registrationTime: registrationTimeElement ? toLocalTime(new Date(getValue(registrationTimeElement))) : undefined,
        countryId: getRequiredIntValue(getRequiredChild(userElement, "CountryId")),
        city: cityElement ? getValue(cityElement) : "",
        sessions,
        userRoles: roles,
        paymentSystems
    };
}

export interface LoginResult {
    sessionCode: string;
    user: User;
}
