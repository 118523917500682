import { Cloneable, Comparable, Equable, Hashable } from "@sportaq/common/types/interfaces";

export interface BigDecimalDTO {
    value: number;
    scale: number;
}

export class BigDecimal implements Cloneable<BigDecimal>, Comparable<BigDecimal>, Equable<BigDecimal>, Hashable<BigDecimal> {
    static readonly ZERO = new BigDecimal(0, 0);
    readonly numberValue: number;
    private readonly precision: number;

    constructor (readonly value: number, readonly scale: number) {
        const pow = Math.pow(10, scale);
        this.numberValue = value / pow;
        this.precision = 1 / (100 * pow);
    }

    static fromDTO (dto: BigDecimalDTO): BigDecimal {
        return new BigDecimal(dto.value, dto.scale);
    }

    static fromNumber (value: number, scale: number): BigDecimal {
        const pow = Math.pow(10, scale);
        const rounded = Math.round(value * pow);
        return new BigDecimal(rounded, scale);
    }

    toDecimal (): number {
        return this.numberValue;
    }

    toString (): string {
        if (Math.abs(this.numberValue) < this.precision) {
            return "0";
        }
        return this.numberValue.toFixed(this.scale);
    }

    toInt (): number {
        return Math.round(this.numberValue);
    }

    toIntString (): string {
        return this.numberValue.toFixed();
    }

    clone (): BigDecimal {
        return new BigDecimal(this.value, this.scale);
    }

    compare (o: BigDecimal): number {
        const result = this.numberValue - o.numberValue;
        if (Math.abs(result) < this.precision) {
            return 0;
        }
        return result;
    }

    negate (): BigDecimal {
        return new BigDecimal(-this.value, this.scale);
    }

    subtract (o: BigDecimal) {
        if (o === BigDecimal.ZERO) {
            return this;
        }
        if (o.scale !== this.scale) {
            throw new Error(`Can't subtract two BigDecimals with scale difference: ${this.scale}, ${o.scale}`);
        }
        return new BigDecimal(this.value - o.value, this.scale);
    }

    add (o: BigDecimal) {
        if (o.scale !== this.scale) {
            throw new Error(`Can't subtract two BigDecimals with scale difference: ${this.scale}, ${o.scale}`);
        }
        return new BigDecimal(this.value + o.value, this.scale);
    }

    equals (o: BigDecimal): boolean {
        return this === o || (this.value === o.value && this.scale === o.scale);
    }

    equalsNumber (o: number): boolean {
        const result = this.numberValue - o;
        return (Math.abs(result) < this.precision);
    }

    hashCode (): number {
        let hash = 7;
        hash = 31 * hash + this.value;
        hash = 31 * hash + this.scale;
        return hash;
    }
}

export function stringToBigDecimalDTO (value: string, scale: number): BigDecimalDTO {
    if (value.length === 0) {
        return {
            value: 0,
            scale
        };
    }
    const v = parseFloat(value);
    return BigDecimal.fromNumber(v, scale);
}

const pFormat = new Intl.NumberFormat("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
});

export function bigDecimalToStringWithVariableMantis (value: BigDecimal | undefined): string {
    return value ? pFormat.format(value.numberValue) : "";
}
