import { computed, PropType, ref, SetupContext } from "vue";
import { QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { createQuotationLabel, createQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import CompactScoreboardLineQuotation
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/compact/quotation-group/quotation/CompactScoreboardLineQuotation.vue";
import { useI18n } from "vue-i18n";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import EDetailRow from "@sportaq/common/enums/detail-row";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { ESportType } from "@sportaq/common/enums/sport-type";

const TOGGLE_PINNED = "togglePinned";

interface Props {
    id: EDetailRow;
    sportType: ESportType;
    quotations: QuotationWrapper[][];
    participants: Participant[];
    p1?: BigDecimal;
    eventBlocked: boolean;
    titleTemplate: string;
    allowCollapse: boolean;
    allowBePinned: boolean;
}

interface Row {
    headers: string[];
    quotations: QuotationWrapper[];
}

export default {
    props: {
        sportType: {
            required: true,
            type: Number
        },
        quotations: {
            required: true,
            type: Object as PropType<QuotationWrapper[]>
        },
        participants: {
            required: true,
            type: Object as PropType<Participant[]>
        },
        eventBlocked: {
            required: true,
            type: Boolean
        },
        titleTemplate: {
            required: false,
            type: String,
            default: ""
        },
        allowCollapse: {
            required: false,
            type: Boolean,
            default: false
        },
        allowBePinned: {
            required: false,
            type: Boolean,
            default: false
        },
        p1: {
            required: false,
            type: Object as PropType<BigDecimal>
        },
        id: {
            required: false,
            type: Number,
            default: 0
        }
    },
    components: {
        CompactScoreboardLineQuotation
    },
    emits: [TOGGLE_PINNED],
    setup (props: Props, context: SetupContext) {
        const scoreboardStore = useScoreboardStore();
        const i18n = useI18n();
        const collapsed = ref(false);
        const rows = computed(() => props.quotations.map(v => createRow(v, { participants: props.participants })));
        const title = computed(() => createQuotationTitle(i18n, props.titleTemplate, props.participants, props.p1));
        const isPinned = computed(() => scoreboardStore.isDetailRowPinned(props.sportType, props.id));

        function toggleCollapsed () {
            if (props.allowCollapse) {
                collapsed.value = !collapsed.value;
            }
        }

        function togglePinned () {
            if (props.allowBePinned) {
                scoreboardStore.toggleDetailRowPinned(props.sportType, props.id);
                context.emit(TOGGLE_PINNED);
            }
        }

        return {
            collapsed,
            rows,
            title,
            isPinned,

            toggleCollapsed,
            togglePinned
        };
    }
};

function createRow (quotations: QuotationWrapper[], participantsSupplier: ParticipantsSupplier): Row {
    const headers = quotations.map(l => createQuotationLabel(participantsSupplier, l.quotation.key));
    return {
        headers,
        quotations
    };
}
