// source: responses/events/EventDiffsListMessage.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.EventDiffsListMsg', null, global);
goog.exportSymbol('proto.EventDiffsMsg', null, global);
goog.exportSymbol('proto.ParticipantMsg', null, global);
goog.exportSymbol('proto.PartitionMsg', null, global);
goog.exportSymbol('proto.PeriodContentMsg', null, global);
goog.exportSymbol('proto.PeriodMsg', null, global);
goog.exportSymbol('proto.QuotationDiffMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventDiffsListMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EventDiffsListMsg.repeatedFields_, null);
};
goog.inherits(proto.EventDiffsListMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventDiffsListMsg.displayName = 'proto.EventDiffsListMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EventDiffsMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.EventDiffsMsg.repeatedFields_, null);
};
goog.inherits(proto.EventDiffsMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EventDiffsMsg.displayName = 'proto.EventDiffsMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PartitionMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PartitionMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PartitionMsg.displayName = 'proto.PartitionMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ParticipantMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ParticipantMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ParticipantMsg.displayName = 'proto.ParticipantMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.QuotationDiffMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.QuotationDiffMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.QuotationDiffMsg.displayName = 'proto.QuotationDiffMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PeriodMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PeriodMsg.repeatedFields_, null);
};
goog.inherits(proto.PeriodMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PeriodMsg.displayName = 'proto.PeriodMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PeriodContentMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PeriodContentMsg.repeatedFields_, null);
};
goog.inherits(proto.PeriodContentMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PeriodContentMsg.displayName = 'proto.PeriodContentMsg';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventDiffsListMsg.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventDiffsListMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.EventDiffsListMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventDiffsListMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventDiffsListMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    diffList: jspb.Message.toObjectList(msg.getDiffList(),
    proto.EventDiffsMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventDiffsListMsg}
 */
proto.EventDiffsListMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventDiffsListMsg;
  return proto.EventDiffsListMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventDiffsListMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventDiffsListMsg}
 */
proto.EventDiffsListMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.EventDiffsMsg;
      reader.readMessage(value,proto.EventDiffsMsg.deserializeBinaryFromReader);
      msg.addDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventDiffsListMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventDiffsListMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventDiffsListMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventDiffsListMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiffList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.EventDiffsMsg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventDiffsMsg diff = 1;
 * @return {!Array<!proto.EventDiffsMsg>}
 */
proto.EventDiffsListMsg.prototype.getDiffList = function() {
  return /** @type{!Array<!proto.EventDiffsMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.EventDiffsMsg, 1));
};


/**
 * @param {!Array<!proto.EventDiffsMsg>} value
 * @return {!proto.EventDiffsListMsg} returns this
*/
proto.EventDiffsListMsg.prototype.setDiffList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.EventDiffsMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.EventDiffsMsg}
 */
proto.EventDiffsListMsg.prototype.addDiff = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.EventDiffsMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventDiffsListMsg} returns this
 */
proto.EventDiffsListMsg.prototype.clearDiffList = function() {
  return this.setDiffList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EventDiffsMsg.repeatedFields_ = [14,15,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EventDiffsMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.EventDiffsMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EventDiffsMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventDiffsMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    eventid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sporttypeid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    countryid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    difftype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    version: jspb.Message.getFieldWithDefault(msg, 6, 0),
    partition: (f = msg.getPartition()) && proto.PartitionMsg.toObject(includeInstance, f),
    starttime: (f = msg.getStarttime()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    resulttext: (f = msg.getResulttext()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    currentperiod: (f = msg.getCurrentperiod()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    blocked: (f = msg.getBlocked()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    statuscode: (f = msg.getStatuscode()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    statusmark: (f = msg.getStatusmark()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    participantList: jspb.Message.toObjectList(msg.getParticipantList(),
    proto.ParticipantMsg.toObject, includeInstance),
    quotationdiffList: jspb.Message.toObjectList(msg.getQuotationdiffList(),
    proto.QuotationDiffMsg.toObject, includeInstance),
    periodList: jspb.Message.toObjectList(msg.getPeriodList(),
    proto.PeriodMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EventDiffsMsg}
 */
proto.EventDiffsMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EventDiffsMsg;
  return proto.EventDiffsMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EventDiffsMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EventDiffsMsg}
 */
proto.EventDiffsMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPositionid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSporttypeid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountryid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDifftype(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVersion(value);
      break;
    case 7:
      var value = new proto.PartitionMsg;
      reader.readMessage(value,proto.PartitionMsg.deserializeBinaryFromReader);
      msg.setPartition(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setStarttime(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setResulttext(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setCurrentperiod(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBlocked(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStatusmark(value);
      break;
    case 14:
      var value = new proto.ParticipantMsg;
      reader.readMessage(value,proto.ParticipantMsg.deserializeBinaryFromReader);
      msg.addParticipant(value);
      break;
    case 15:
      var value = new proto.QuotationDiffMsg;
      reader.readMessage(value,proto.QuotationDiffMsg.deserializeBinaryFromReader);
      msg.addQuotationdiff(value);
      break;
    case 16:
      var value = new proto.PeriodMsg;
      reader.readMessage(value,proto.PeriodMsg.deserializeBinaryFromReader);
      msg.addPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EventDiffsMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EventDiffsMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EventDiffsMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EventDiffsMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEventid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSporttypeid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCountryid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getDifftype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getPartition();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.PartitionMsg.serializeBinaryToWriter
    );
  }
  f = message.getStarttime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getResulttext();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCurrentperiod();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getBlocked();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getStatusmark();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getParticipantList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.ParticipantMsg.serializeBinaryToWriter
    );
  }
  f = message.getQuotationdiffList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.QuotationDiffMsg.serializeBinaryToWriter
    );
  }
  f = message.getPeriodList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.PeriodMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 positionId = 1;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getPositionid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setPositionid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 eventId = 2;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getEventid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setEventid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 sportTypeId = 3;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getSporttypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setSporttypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 countryId = 4;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getCountryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setCountryid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 diffType = 5;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getDifftype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setDifftype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 version = 6;
 * @return {number}
 */
proto.EventDiffsMsg.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional PartitionMsg partition = 7;
 * @return {?proto.PartitionMsg}
 */
proto.EventDiffsMsg.prototype.getPartition = function() {
  return /** @type{?proto.PartitionMsg} */ (
    jspb.Message.getWrapperField(this, proto.PartitionMsg, 7));
};


/**
 * @param {?proto.PartitionMsg|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setPartition = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearPartition = function() {
  return this.setPartition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasPartition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.UInt64Value startTime = 8;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.EventDiffsMsg.prototype.getStarttime = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 8));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setStarttime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearStarttime = function() {
  return this.setStarttime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasStarttime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue resultText = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.EventDiffsMsg.prototype.getResulttext = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setResulttext = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearResulttext = function() {
  return this.setResulttext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasResulttext = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value currentPeriod = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.EventDiffsMsg.prototype.getCurrentperiod = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setCurrentperiod = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearCurrentperiod = function() {
  return this.setCurrentperiod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasCurrentperiod = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue blocked = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.EventDiffsMsg.prototype.getBlocked = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setBlocked = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearBlocked = function() {
  return this.setBlocked(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasBlocked = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.UInt32Value statusCode = 12;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.EventDiffsMsg.prototype.getStatuscode = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 12));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue statusMark = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.EventDiffsMsg.prototype.getStatusmark = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setStatusmark = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearStatusmark = function() {
  return this.setStatusmark(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EventDiffsMsg.prototype.hasStatusmark = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * repeated ParticipantMsg participant = 14;
 * @return {!Array<!proto.ParticipantMsg>}
 */
proto.EventDiffsMsg.prototype.getParticipantList = function() {
  return /** @type{!Array<!proto.ParticipantMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ParticipantMsg, 14));
};


/**
 * @param {!Array<!proto.ParticipantMsg>} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setParticipantList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.ParticipantMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ParticipantMsg}
 */
proto.EventDiffsMsg.prototype.addParticipant = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.ParticipantMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearParticipantList = function() {
  return this.setParticipantList([]);
};


/**
 * repeated QuotationDiffMsg quotationDiff = 15;
 * @return {!Array<!proto.QuotationDiffMsg>}
 */
proto.EventDiffsMsg.prototype.getQuotationdiffList = function() {
  return /** @type{!Array<!proto.QuotationDiffMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.QuotationDiffMsg, 15));
};


/**
 * @param {!Array<!proto.QuotationDiffMsg>} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setQuotationdiffList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.QuotationDiffMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.QuotationDiffMsg}
 */
proto.EventDiffsMsg.prototype.addQuotationdiff = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.QuotationDiffMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearQuotationdiffList = function() {
  return this.setQuotationdiffList([]);
};


/**
 * repeated PeriodMsg period = 16;
 * @return {!Array<!proto.PeriodMsg>}
 */
proto.EventDiffsMsg.prototype.getPeriodList = function() {
  return /** @type{!Array<!proto.PeriodMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PeriodMsg, 16));
};


/**
 * @param {!Array<!proto.PeriodMsg>} value
 * @return {!proto.EventDiffsMsg} returns this
*/
proto.EventDiffsMsg.prototype.setPeriodList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.PeriodMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PeriodMsg}
 */
proto.EventDiffsMsg.prototype.addPeriod = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.PeriodMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EventDiffsMsg} returns this
 */
proto.EventDiffsMsg.prototype.clearPeriodList = function() {
  return this.setPeriodList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PartitionMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.PartitionMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PartitionMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PartitionMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sporttypeid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PartitionMsg}
 */
proto.PartitionMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PartitionMsg;
  return proto.PartitionMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PartitionMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PartitionMsg}
 */
proto.PartitionMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSporttypeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PartitionMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PartitionMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PartitionMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PartitionMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSporttypeid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.PartitionMsg.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PartitionMsg} returns this
 */
proto.PartitionMsg.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.PartitionMsg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PartitionMsg} returns this
 */
proto.PartitionMsg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sportTypeId = 3;
 * @return {number}
 */
proto.PartitionMsg.prototype.getSporttypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.PartitionMsg} returns this
 */
proto.PartitionMsg.prototype.setSporttypeid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ParticipantMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.ParticipantMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ParticipantMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ParticipantMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ParticipantMsg}
 */
proto.ParticipantMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ParticipantMsg;
  return proto.ParticipantMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ParticipantMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ParticipantMsg}
 */
proto.ParticipantMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ParticipantMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ParticipantMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ParticipantMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ParticipantMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.ParticipantMsg.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ParticipantMsg} returns this
 */
proto.ParticipantMsg.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.ParticipantMsg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ParticipantMsg} returns this
 */
proto.ParticipantMsg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.QuotationDiffMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.QuotationDiffMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.QuotationDiffMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuotationDiffMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quotationid: (f = msg.getQuotationid()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    p1: (f = msg.getP1()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    p2: (f = msg.getP2()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    po: (f = msg.getPo()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    p1id: (f = msg.getP1id()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    p2id: (f = msg.getP2id()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    coef: (f = msg.getCoef()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    maxwin: (f = msg.getMaxwin()) && google_protobuf_wrappers_pb.UInt32Value.toObject(includeInstance, f),
    deleted: (f = msg.getDeleted()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.QuotationDiffMsg}
 */
proto.QuotationDiffMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.QuotationDiffMsg;
  return proto.QuotationDiffMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.QuotationDiffMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.QuotationDiffMsg}
 */
proto.QuotationDiffMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setQuotationid(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setP1(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setP2(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPo(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setP1id(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setP2id(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setCoef(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.UInt32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt32Value.deserializeBinaryFromReader);
      msg.setMaxwin(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.QuotationDiffMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.QuotationDiffMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.QuotationDiffMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.QuotationDiffMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQuotationid();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getP1();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getP2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPo();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getP1id();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getP2id();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getCoef();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getMaxwin();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.UInt32Value.serializeBinaryToWriter
    );
  }
  f = message.getDeleted();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.QuotationDiffMsg.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.UInt32Value quotationId = 2;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.QuotationDiffMsg.prototype.getQuotationid = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 2));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setQuotationid = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearQuotationid = function() {
  return this.setQuotationid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasQuotationid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value p1 = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.QuotationDiffMsg.prototype.getP1 = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setP1 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearP1 = function() {
  return this.setP1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasP1 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value p2 = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.QuotationDiffMsg.prototype.getP2 = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setP2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearP2 = function() {
  return this.setP2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasP2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value po = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.QuotationDiffMsg.prototype.getPo = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setPo = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearPo = function() {
  return this.setPo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasPo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value p1Id = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.QuotationDiffMsg.prototype.getP1id = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setP1id = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearP1id = function() {
  return this.setP1id(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasP1id = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Int32Value p2Id = 10;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.QuotationDiffMsg.prototype.getP2id = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 10));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setP2id = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearP2id = function() {
  return this.setP2id(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasP2id = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.UInt32Value coef = 6;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.QuotationDiffMsg.prototype.getCoef = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 6));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setCoef = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearCoef = function() {
  return this.setCoef(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasCoef = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.UInt32Value maxWin = 7;
 * @return {?proto.google.protobuf.UInt32Value}
 */
proto.QuotationDiffMsg.prototype.getMaxwin = function() {
  return /** @type{?proto.google.protobuf.UInt32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt32Value, 7));
};


/**
 * @param {?proto.google.protobuf.UInt32Value|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setMaxwin = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearMaxwin = function() {
  return this.setMaxwin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasMaxwin = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue deleted = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.QuotationDiffMsg.prototype.getDeleted = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.QuotationDiffMsg} returns this
*/
proto.QuotationDiffMsg.prototype.setDeleted = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.QuotationDiffMsg} returns this
 */
proto.QuotationDiffMsg.prototype.clearDeleted = function() {
  return this.setDeleted(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.QuotationDiffMsg.prototype.hasDeleted = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PeriodMsg.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PeriodMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.PeriodMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PeriodMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PeriodMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deleted: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    contentList: jspb.Message.toObjectList(msg.getContentList(),
    proto.PeriodContentMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PeriodMsg}
 */
proto.PeriodMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PeriodMsg;
  return proto.PeriodMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PeriodMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PeriodMsg}
 */
proto.PeriodMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    case 3:
      var value = new proto.PeriodContentMsg;
      reader.readMessage(value,proto.PeriodContentMsg.deserializeBinaryFromReader);
      msg.addContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PeriodMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PeriodMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PeriodMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PeriodMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getContentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.PeriodContentMsg.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 type = 1;
 * @return {number}
 */
proto.PeriodMsg.prototype.getType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PeriodMsg} returns this
 */
proto.PeriodMsg.prototype.setType = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool deleted = 2;
 * @return {boolean}
 */
proto.PeriodMsg.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PeriodMsg} returns this
 */
proto.PeriodMsg.prototype.setDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * repeated PeriodContentMsg content = 3;
 * @return {!Array<!proto.PeriodContentMsg>}
 */
proto.PeriodMsg.prototype.getContentList = function() {
  return /** @type{!Array<!proto.PeriodContentMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PeriodContentMsg, 3));
};


/**
 * @param {!Array<!proto.PeriodContentMsg>} value
 * @return {!proto.PeriodMsg} returns this
*/
proto.PeriodMsg.prototype.setContentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.PeriodContentMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PeriodContentMsg}
 */
proto.PeriodMsg.prototype.addContent = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.PeriodContentMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PeriodMsg} returns this
 */
proto.PeriodMsg.prototype.clearContentList = function() {
  return this.setContentList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PeriodContentMsg.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PeriodContentMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.PeriodContentMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PeriodContentMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PeriodContentMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    view: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qidList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PeriodContentMsg}
 */
proto.PeriodContentMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PeriodContentMsg;
  return proto.PeriodContentMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PeriodContentMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PeriodContentMsg}
 */
proto.PeriodContentMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setView(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addQid(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PeriodContentMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PeriodContentMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PeriodContentMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PeriodContentMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getView();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getQidList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 view = 1;
 * @return {number}
 */
proto.PeriodContentMsg.prototype.getView = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PeriodContentMsg} returns this
 */
proto.PeriodContentMsg.prototype.setView = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 qId = 2;
 * @return {!Array<number>}
 */
proto.PeriodContentMsg.prototype.getQidList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.PeriodContentMsg} returns this
 */
proto.PeriodContentMsg.prototype.setQidList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.PeriodContentMsg} returns this
 */
proto.PeriodContentMsg.prototype.addQid = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PeriodContentMsg} returns this
 */
proto.PeriodContentMsg.prototype.clearQidList = function() {
  return this.setQidList([]);
};


goog.object.extend(exports, proto);
