<template>
    <div id="login-popup-dialog" class="popup active" data-cy="login-dialog">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <Form class="popup-content" @submit="handleSubmit(doLogin)" v-slot="{ errors, handleSubmit }">
                <div class="form-line">
                    <Field name="login" id="login-input" type="text" :placeholder="$t('webdesktop.loginDialog.emailPlaceholder')"
                           v-model="loginRef" autofocus data-cy="login-input" rules="required" />
                    <div class="field-error" v-if="errors.login">{{errors.login}}</div>
                </div>
                <div class="form-line password">
                    <div class="icons">
                        <img :src="'/img/icon-eye-' + (isPasswordVisible?'off':'on') + '.svg'" alt=""
                            @click.prevent="isPasswordVisible = !isPasswordVisible" >
                    </div>
                    <Field name="password" id="password-input" :type="isPasswordVisible?'text':'password'"
                           :placeholder="$t('webdesktop.loginDialog.passwordPlaceholder')" v-model="passwordRef"
                           autocomplete="off" data-cy="password-input" rules="required" />
                    <div class="field-error" v-if="errors.password">{{errors.password}}</div>
                </div>
                <div class="form-line space-between">
                    <div class="checkbox">
                        <input type="checkbox" id="checkbox-login" class="checkbox-input" v-model="saveSessionRef">
                        <label for="checkbox-login" class="checkbox-label">{{$t("webdesktop.loginDialog.save")}}</label>
                    </div>
                    <a class="link" @click.prevent="onForgetPasswordClick">{{$t("webdesktop.loginDialog.forgetPassword")}}</a>
                </div>
                <div class="form-line">
                    <button id="login-button" type="submit" class="button button-general" @click.prevent="handleSubmit(doLogin)" data-cy="login-button">{{$t("webdesktop.loginDialog.loginButton")}}</button>
                </div>
                <div class="form-line">
                    <button id="login-close-button" type="submit" class="button button-secondary" @click.prevent="onClose()" data-cy="login-close-button">{{$t("common.forms.close")}}</button>
                </div>
                <div class="form-line no-register">
                    <div>
                        {{$t("webdesktop.loginDialog.no-register")}} <a href="" class="register-link" @click.prevent="onRegistrationClick">{{$t("routes.registration")}}</a>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script src="./login-popup-dialog-component.ts" />
