import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getTennisMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.popular",
        rows: [
            {
                id: EDetailRow.tennis_x1_2,
                title: "detailInfo.groups.tennis.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.tennis_total,
                title: "detailInfo.groups.tennis.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tennis_handicap,
                title: "detailInfo.groups.tennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam1,
                title: "detailInfo.groups.tennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam2,
                title: "detailInfo.groups.tennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddMatch,
                title: "detailInfo.groups.tennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tennis_matchScore,
                title: "detailInfo.groups.tennis.matchScore",
                quotationIds: [
                    [13, 15],
                    [14, 16],
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2,
                title: "detailInfo.groups.tennis.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.tennis_total,
                title: "detailInfo.groups.tennis.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tennis_handicap,
                title: "detailInfo.groups.tennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam1,
                title: "detailInfo.groups.tennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam2,
                title: "detailInfo.groups.tennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddMatch,
                title: "detailInfo.groups.tennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set1,
                title: "detailInfo.groups.tennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet1,
                title: "detailInfo.groups.tennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet1,
                title: "detailInfo.groups.tennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet1,
                title: "detailInfo.groups.tennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set2,
                title: "detailInfo.groups.tennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet2,
                title: "detailInfo.groups.tennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet2,
                title: "detailInfo.groups.tennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet2,
                title: "detailInfo.groups.tennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set3,
                title: "detailInfo.groups.tennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet3,
                title: "detailInfo.groups.tennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet3,
                title: "detailInfo.groups.tennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet3,
                title: "detailInfo.groups.tennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set4,
                title: "detailInfo.groups.tennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet4,
                title: "detailInfo.groups.tennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet4,
                title: "detailInfo.groups.tennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet4,
                title: "detailInfo.groups.tennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set5,
                title: "detailInfo.groups.tennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet5,
                title: "detailInfo.groups.tennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet5,
                title: "detailInfo.groups.tennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet5,
                title: "detailInfo.groups.tennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            },
            {
                id: EDetailRow.tennis_matchScore,
                title: "detailInfo.groups.tennis.matchScore",
                quotationIds: [
                    [13, 15],
                    [14, 16],
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicap,
                title: "detailInfo.groups.tennis.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet1,
                title: "detailInfo.groups.tennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet2,
                title: "detailInfo.groups.tennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet3,
                title: "detailInfo.groups.tennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet4,
                title: "detailInfo.groups.tennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet5,
                title: "detailInfo.groups.tennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalMatch,
                title: "detailInfo.groups.tennis.totalMatch",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet1,
                title: "detailInfo.groups.tennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet2,
                title: "detailInfo.groups.tennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet3,
                title: "detailInfo.groups.tennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet4,
                title: "detailInfo.groups.tennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet5,
                title: "detailInfo.groups.tennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam1,
                title: "detailInfo.groups.tennis.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.tennis_individualTotalTeam2,
                title: "detailInfo.groups.tennis.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddMatch,
                title: "detailInfo.groups.tennis.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet1,
                title: "detailInfo.groups.tennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet2,
                title: "detailInfo.groups.tennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet3,
                title: "detailInfo.groups.tennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet4,
                title: "detailInfo.groups.tennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet5,
                title: "detailInfo.groups.tennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.tennis.sets",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set1,
                title: "detailInfo.groups.tennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet1,
                title: "detailInfo.groups.tennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet1,
                title: "detailInfo.groups.tennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet1,
                title: "detailInfo.groups.tennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set2,
                title: "detailInfo.groups.tennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet2,
                title: "detailInfo.groups.tennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet2,
                title: "detailInfo.groups.tennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet2,
                title: "detailInfo.groups.tennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set3,
                title: "detailInfo.groups.tennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet3,
                title: "detailInfo.groups.tennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet3,
                title: "detailInfo.groups.tennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet3,
                title: "detailInfo.groups.tennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set4,
                title: "detailInfo.groups.tennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet4,
                title: "detailInfo.groups.tennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet4,
                title: "detailInfo.groups.tennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet4,
                title: "detailInfo.groups.tennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.tennis_x1_2Set5,
                title: "detailInfo.groups.tennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet5,
                title: "detailInfo.groups.tennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet5,
                title: "detailInfo.groups.tennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet5,
                title: "detailInfo.groups.tennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}

export function getTennisPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set1,
                title: "detailInfo.groups.tennis.x1_2Set1",
                quotationIds: [
                    [290, 292]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet1,
                title: "detailInfo.groups.tennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet1,
                title: "detailInfo.groups.tennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet1,
                title: "detailInfo.groups.tennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicapSet1,
                title: "detailInfo.groups.tennis.handicapSet1",
                quotationIds: [
                    [293, 294]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalSet1,
                title: "detailInfo.groups.tennis.totalSet1",
                quotationIds: [
                    [295, 296]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet1,
                title: "detailInfo.groups.tennis.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    return result;
}

export function getTennisPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set2,
                title: "detailInfo.groups.tennis.x1_2Set2",
                quotationIds: [
                    [656, 657]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet2,
                title: "detailInfo.groups.tennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet2,
                title: "detailInfo.groups.tennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet2,
                title: "detailInfo.groups.tennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicapSet2,
                title: "detailInfo.groups.tennis.handicapSet2",
                quotationIds: [
                    [664, 665]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalSet2,
                title: "detailInfo.groups.tennis.totalSet2",
                quotationIds: [
                    [672, 673]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet2,
                title: "detailInfo.groups.tennis.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    return result;
}

export function getTennisPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set3,
                title: "detailInfo.groups.tennis.x1_2Set3",
                quotationIds: [
                    [658, 659]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet3,
                title: "detailInfo.groups.tennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet3,
                title: "detailInfo.groups.tennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet3,
                title: "detailInfo.groups.tennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicapSet3,
                title: "detailInfo.groups.tennis.handicapSet3",
                quotationIds: [
                    [666, 667]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalSet3,
                title: "detailInfo.groups.tennis.totalSet3",
                quotationIds: [
                    [674, 675]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet3,
                title: "detailInfo.groups.tennis.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    return result;
}

export function getTennisPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set4,
                title: "detailInfo.groups.tennis.x1_2Set4",
                quotationIds: [
                    [660, 661]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet4,
                title: "detailInfo.groups.tennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet4,
                title: "detailInfo.groups.tennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet4,
                title: "detailInfo.groups.tennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicapSet4,
                title: "detailInfo.groups.tennis.handicapSet4",
                quotationIds: [
                    [668, 669]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalSet4,
                title: "detailInfo.groups.tennis.totalSet4",
                quotationIds: [
                    [676, 677]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet4,
                title: "detailInfo.groups.tennis.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    return result;
}

export function getTennisPeriod5Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.tennis.all",
        rows: [
            {
                id: EDetailRow.tennis_x1_2Set5,
                title: "detailInfo.groups.tennis.x1_2Set5",
                quotationIds: [
                    [662, 663]
                ]
            },
            {
                id: EDetailRow.tennis_totalSet5,
                title: "detailInfo.groups.tennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tennis_handicapSet5,
                title: "detailInfo.groups.tennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet5,
                title: "detailInfo.groups.tennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.tennis.handicaps",
        rows: [
            {
                id: EDetailRow.tennis_handicapSet5,
                title: "detailInfo.groups.tennis.handicapSet5",
                quotationIds: [
                    [670, 671]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.tennis.totals",
        rows: [
            {
                id: EDetailRow.tennis_totalSet5,
                title: "detailInfo.groups.tennis.totalSet5",
                quotationIds: [
                    [678, 679]
                ]
            },
            {
                id: EDetailRow.tennis_evenOddSet5,
                title: "detailInfo.groups.tennis.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}
