<template>
    <a href="" class="deposit-back" @click.prevent="back">{{$t('mobile.stakesView.caption')}}</a>
    <div class="deposit-tabs" v-if="isLoggedIn">
        <stakes-menu :tabs="tabs"/>
        <div class="deposit-tabs-body">
            <router-view/>
        </div>
    </div>
</template>

<script src="./stakes-view-component.ts" />
