<template>
    <div class="deposit-filter">
        <div class="deposit-filter-title">{{$t("webdesktop.allStakes.dateFilter")}}</div>
        <div class="deposit-filter-body">
            <div class="form-line">
                <input type="date" v-model="startDateRef">
            </div>
            <div class="form-line">
                <input type="date" v-model="endDateRef">
            </div>
        </div>
    </div>
    <card-panel v-for="(card) in cardsPaged" :key="card.id" :card="card" :showPayCode="false"></card-panel>
    <div class="line">
        <button class="button button-primary btn-load-more" @click.prevent="currentPage ++" v-if="currentPage < totalPages">
            {{$t("common.forms.loadMore")}}
        </button>
    </div>
    <div class="preloader" v-if="requestPending">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./all-stakes-component.ts" />
