import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { UserAccount } from "@sportaq/model/common/user-accounts";
import { UserBalance } from "@sportaq/model/common/user-balance";
import {
    getRequiredAttribute,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";

export abstract class BaseAccountMessageHandler<T> extends AbstractMessageHandler<T> {
    parseAccounts (accountListElement: Element): UserBalance {
        let primaryAccount: UserAccount | undefined;
        let bonusAccount: UserAccount | undefined;
        if (accountListElement !== undefined) {
            for (let userAccountElement = accountListElement.firstElementChild; userAccountElement !== null; userAccountElement = userAccountElement.nextElementSibling) {
                const accountMark = getRequiredAttribute(userAccountElement, "accountMark");
                if (accountMark === "USER_ACCOUNT_PRIMARY") {
                    primaryAccount = this.parseAccount(userAccountElement);
                } else if (accountMark === "USER_ACCOUNT_BONUS" || accountMark === "USER_ACCOUNT_CASINO_BONUS") {
                    bonusAccount = this.parseAccount(userAccountElement);
                }
            }
        }
        return {
            primaryAccount,
            bonusAccount
        };
    }

    private parseAccount (userAccountElement: Element) {
        return {
            accountId: getRequiredIntAttribute(userAccountElement, "Id"),
            accountNumber: getRequiredAttribute(userAccountElement, "accountNumber"),
            currencyId: getRequiredIntAttribute(userAccountElement, "currencyId"),
            currencyMark: getRequiredAttribute(userAccountElement, "currencyMark"),
            currencyValue: getRequiredFloatAttribute(userAccountElement, "currencyValue"),
            isBlocked: getRequiredIntAttribute(userAccountElement, "isBlocked") === 1,
            isBonus: getRequiredIntAttribute(userAccountElement, "isBonus") === 1,
            isUsed: getRequiredIntAttribute(userAccountElement, "isUsed") === 1,
            sumAccount: getRequiredFloatAttribute(userAccountElement, "sumAccount"),
            sumCaptive: getRequiredFloatAttribute(userAccountElement, "sumCaptive"),
            sumStake: getRequiredFloatAttribute(userAccountElement, "sumStake"),
            accountMark: getRequiredAttribute(userAccountElement, "accountMark")
        };
    }
}
