enum Events {
    QUOTATION_BACKLIGHT_OFF = "QUOTATION_BACKLIGHT_OFF",
    MESSAGE_BOX = "MESSAGE_BOX",
    PRINT_PREVIEW = "PRINT_PREVIEW",
    REFRESH_BALANCE = "REFRESH_BALANCE",
    DATA_STREAM_SERVER_CONNECTION_STATUS_CHANGED = "DATA_STREAM_SERVER_CONNECTION_STATUS_CHANGED",
    CANCEL_CARD = "CANCEL_CARD",
    MONITORING_REFRESH = "MONITORING_REFRESH",
    QUOTATION_FORMAT_CHANGED = "QUOTATION_FORMAT_CHANGED",
    OPEN_RESULTS_WINDOW = "OPEN_RESULTS_WINDOW",
    INSTALL_MOBILE_APPLICATION = "INSTALL_MOBILE_APPLICATION",
    BIG_BUTTONS_CHANGED = "BIG_BUTTONS_CHANGED",
    VIRTUAL_GAME_SELECTED = "VIRTUAL_GAME_SELECTED",
    APPLICATION_IS_ABOUT_TO_RELOAD = "APPLICATION_IS_ABOUT_TO_RELOAD",
    APPLICATION_IS_ABOUT_TO_SUSPEND = "APPLICATION_IS_ABOUT_TO_SUSPEND",
    APPLICATION_POINT_SETTINGS_CHANGED = "APPLICATION_POINT_SETTINGS_CHANGED",
    COLD_HOT_DELIMITER_PREMATCH_RECEIVED = "COLD_HOT_DELIMITER_PREMATCH_RECEIVED",
    COLD_HOT_DELIMITER_LIVE_RECEIVED = "COLD_HOT_DELIMITER_LIVE_RECEIVED",
    COLD_HOT_DELIMITER_GAMES_RECEIVED = "COLD_HOT_DELIMITER_GAMES_RECEIVED"
}

export default Events;
