<template>
    <div id="confirm-code-dialog" class="popup active">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.confirmCodeDialog.caption")}}</div>
            <div class="popup-content">
                <div class="text">
                    {{$t("webdesktop.confirmCodeDialog.text")}}
                </div>

                <div class="form-line">
                    <label>{{$t("webdesktop.confirmCodeDialog.inputLabel")}}</label>
                    <input id="confirm-code-input" type="text" v-model="confirmCodeRef">
                </div>

                <div class="form-line">
                    <button type="submit" id="submit-button" class="button button-primary smaller" @click.prevent="onSubmit()" :class="{disabled: !confirmCodeRef}">{{$t("webdesktop.confirmCodeDialog.submitButton")}}</button>
                </div>
                <div class="form-line">
                  <button class="button button-secondary" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./confirm-code-dialog-component.ts" />
