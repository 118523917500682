import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { ConfirmationMethod } from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";

export class APo621GeneralWithdrawalMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.PO.6.21";
    constructor (readonly withdrawalMethod: PaymentSystems, readonly confirmationMethod: ConfirmationMethod,
                 readonly currencyId: number, readonly orderedSum: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "OrderedSum", this.orderedSum.toString());
        request.addChildWithValue(actionElement, "CurrencyId", this.currencyId.toString());
        request.addChildWithValue(actionElement, "ConfirmationMethod", this.confirmationMethod.toString());
        request.addChildWithValue(actionElement, "WithdrawalMethod", this.withdrawalMethod.toString());
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "4440") {
            throw new LocalizedError(`errors.mtl.aPo617.code${serverCode}`);
        }
    }
}
