import { SetupContext } from "vue";
import { useDateFilterCommon } from "@sportaq/vue/views/web/stakes/date-filter-common";

export default {
    name: "DateFilter",
    props: {
        itemCount: {
            type: Number,
            required: true
        }
    },
    emits: ["dateChanged", "itemsPerPageChanged", "currentPageChanged"],
    setup (props:{ itemCount: number }, context: SetupContext) {
        return useDateFilterCommon(props, context);
    }
};
