<template>
    <div class="deposit-section">
        <DateFilter @dateChanged="dateChanged"
                    :itemCount="withdrawalOrders.length"
                    @itemsPerPageChanged="itemsPerPageChanged"
                    @currentPageChanged="currentPageChanged"
        ></DateFilter>
        <div class="deposit-section-body">
            <template  v-for="order in withdrawalOrdersPaged" :key="order.id">
            <div class="line">
                <div class="line-date">{{ $d(order.orderTime, 'long') }}</div>
                <div class="line-total smaller">
                    <div class="name">
                        <template v-if="order.withdrawalType">
                            {{ $t("webdesktop.withdrawalOrders.wt." + order.withdrawalType) }}
                        </template>
                    </div>
                    <div class="value"><b>{{ order.sumOrder.toFixed(2) }} {{order.currencyMark}}</b></div>
                </div>
                <div class="line-total smaller">
                    <div class="name">{{$d(order.changeStatusTime, 'long')}} {{$t(getDescription(order.status))}}</div>
                    <div class="value" v-if="order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE ||
                                                      order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION ||
                                                      order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_APPROVED ||
                                                       order.status === EWithdrawalStatus.ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED">
                        <a class="button button-secondary btn-cancel" href="#" @click.prevent="cancelOrder(order.GUID)">{{$t("webdesktop.withdrawalOrders.cancel")}}</a>
                    </div>
                </div>
                <div class="personal-table-td width-60 text-left"> <br> </div>
            </div>
            </template>
        </div>
    </div>
    <div class="line">
        <button class="button button-primary btn-load-more" @click.prevent="currentPage ++" v-if="currentPage < totalPages">
            {{$t("common.forms.loadMore")}}
        </button>
    </div>
    <confirmation-dialog v-if="guidToCancel" :caption="$t('webdesktop.withdrawalOrders.confirm.caption')"
                         :message="$t('webdesktop.withdrawalOrders.confirm.text')" @submit="onCancelConfirmed" @close="guidToCancel = undefined"></confirmation-dialog>
    <div class="preloader" v-if="requestPending">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./withdrawal-orders-component.ts">

</script>
