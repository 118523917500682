import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { SetupContext } from "vue";
import { Form, Field } from "vee-validate";
import { equadorBanks, useBankWithdrawalCommon } from "@sportaq/vue/views/web/withdrawal/bank-withdrawal-common";

export default {
    name: "VisaWithdrawalDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field, ConfirmCodeDialog
    },
    props: {
        minAmount: {
            type: Number,
            required: true
        },
        paymentSystem: {
            type: String,
            required: false
        }
    },
    setup (props: { minAmount: number }, context: SetupContext) {
        return useBankWithdrawalCommon(props.minAmount, context, equadorBanks);
    }
};
