import { createApp } from "vue";
import Root from "@/dependency-container-component";
import router from "@/router";
import { i18n } from "@sportaq/i18n/index";
import store from "@/store";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { RecycleScroller } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import "@egjs/vue3-flicking/dist/flicking.css";
import clickOutside from "@sportaq/vue/directives/click-outside";
import "./register-sw";
import VueGtag from "vue-gtag";
import { isCypressTest } from "@sportaq/vue/utils/CypressUtil";
import { requestEnvironmentSettings, webEnvironmentPlugin } from "@sportaq/services/web/environment/web-environment";
import { I18nSetup } from "@sportaq/i18n/settings/i18n-settings";
import i18nSettings = I18nSetup.i18nSettings;

// Fix for phone bottom toolbar panel
refreshViewPointHeight();
// We listen to the resize event
window.addEventListener("resize", () => {
    refreshViewPointHeight();
});

async function run () {
    APP_TYPE.value = EApplicationType.webMobile;
    const environment = await requestEnvironmentSettings();
    i18nSettings.disabledLanguages = environment.disabledLanguages ?? [];
    const application = createApp(Root);
    application.directive("click-outside", clickOutside);
    application.use(store).use(router).use(i18n).use(webEnvironmentPlugin, environment);
    if (!isCypressTest()) {
        application.use(VueGtag, {
            config: { id: environment.gtag }
        }, router);
    }
    application.mount("#app");
    application.component("RecycleScroller", RecycleScroller);
}

run().then();
function refreshViewPointHeight () {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
}
