<template>
    <div class="coupon-block-item simple" :data-cy="stake.templateKey">
        <div class="cancelled" v-if="blocked">
            <div class="cancelled-icon">
                <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17.5" cy="17.5" r="16.5" fill="white" stroke="#D32D23"
                            stroke-width="2"/>
                    <rect x="28.9179" y="5.16791" width="2" height="33.9403"
                          transform="rotate(45 28.9179 5.16791)" fill="#D32D23"/>
                </svg>
            </div>
        </div>
        <div class="info">
            <div class="info-title" data-cy="title">{{ label }}</div>
            <div class="info-line" v-if="!stake.express">
                <div class="info-line-name" data-cy="variants">{{ $t("betting.betSlip.variants") }} <span
                    class="info-line-rate">{{ stake.variants }}</span></div>
            </div>
            <div class="info-line" v-if="stake.express">
                <template v-if="stake.canUseBonus && bonus">
                    <div class="info-line-name old" data-cy="coef-old">@ {{
                            formattedQuotationCoefWithoutBonus
                        }}
                    </div>
                    <img class="info-line-arrow" src="@assets/img/icon-arrow-7.png" alt=""/>
                    <span class="info-line-rate" data-cy="coef">@ {{ formattedQuotationCoef }}</span>
                </template>
                <div class="info-line-rate" data-cy="coef" v-else>@ {{ formattedQuotationCoef }}</div>
            </div>
            <div class="info-line" v-if="maxWin">
                <div class="info-line-name" data-cy="max-win-amount">{{ $t("betting.betSlip.maxWin") }}
                    <span> {{ maxWin }}</span>
                </div>
            </div>
        </div>
        <div class="form-line">
            <div class="checkbox" v-if="stake.canUseBonus">
                <input type="checkbox" :id="stake.templateKey" class="checkbox-input" v-model="bonus"
                    data-cy="bonus-checkbox" @change="onChange">
                <label :for="stake.templateKey" class="checkbox-label">BONUS</label>
            </div>
        </div>
        <div class="actions">
            <div class="form-line small">
            <input type="number" title="" v-model.number="amount" @blur="onChange"
                   data-cy="amount-input"/>
            </div>
            <div class="actions-score" data-cy="max-sum-stake">max: {{ stake.maxSumStake.toFixed() }}</div>
            <div class="actions-error-icon" v-if="stakeError" @click="showErrorMessage">
                <img :src="require(`@assets/img/${ stakeError.icon }.png`)" alt="" data-cy="error-icon"/>
            </div>
        </div>
        <div class="coupon-error" v-if="stakeError && errorShowed" data-cy="error-message-box">
            <img class="coupon-error-icon" src="@assets/img/icon-error.png" alt=""/>
            <div class="coupon-error-info">
                <div class="message"> {{ stakeError.message }}</div>
                <a href="" class="button" @click.prevent.stop="accept(stakeError.cause)"
                    data-cy="accept-button">{{ $t("betting.betSlip.buttons.accept") }}</a>
            </div>
        </div>
    </div>

</template>

<script src="./mobile-combined-stake-row-component.ts"/>
