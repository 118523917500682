import { binarySearchIndex, insertIntoSortedArray } from "@sportaq/common/utils/arrays";

export class SortedArray<T> {
    public readonly items: T[] = [];

    constructor (private readonly comparator: (a: T, b: T) => number) {
    }

    push (item: T) {
        insertIntoSortedArray(this.items, item, this.comparator);
    }

    remove (item: T): boolean {
        const index = binarySearchIndex(this.items, item, this.comparator);
        if (index >= 0) {
            this.items.splice(index, 1);
            return true;
        }
        return false;
    }

    contains (item: T): boolean {
        return binarySearchIndex(this.items, item, this.comparator) >= 0;
    }
}
