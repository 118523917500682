import { Analytics } from "@firebase/analytics";

export const firebaseConfig = {
    apiKey: "AIzaSyBfbp29oQ_eE5RXYY156i3G6IaTYU6hLvU",
    authDomain: "betgol-app.firebaseapp.com",
    projectId: "betgol-app",
    storageBucket: "betgol-app.appspot.com",
    messagingSenderId: "416384825647",
    appId: "1:416384825647:web:ccaff4ef4bb8db688309da",
    measurementId: "G-RK0HJEP7G7"
};

let firebaseAnalytics: Analytics | undefined;

export function getFirebaseAnalytics () {
    return firebaseAnalytics;
}

export function setFirebaseAnalytics (analytics: Analytics) {
    firebaseAnalytics = analytics;
}