import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import {
    CasinoGameDiffsListMsg, CasinoGameDiffsMsg
} from "@sportaq/services/streaming/streaming-network/messages/responses/casino/CasinoGameDiffsListMessage_pb";

export class CasinoGameDiffsListResponseDeserializer {
    static deserializeBinary (brandId: number, message: Uint8Array): CasinoGameDiffsResponse[] {
        const msg = CasinoGameDiffsListMsg.deserializeBinary(message);
        const result: CasinoGameDiffsResponse[] = [];
        for (const item of msg.getDiffList()) {
            const casinoItem: CasinoGameDiffsResponse = {
                id: item.getId(),
                diffType: item.getDifftype(),
                version: item.getVersion(),
                provider: item.getProvider(),
                virtualGame: item.getVirtualgame(),
                title: item.getTitle()?.getValue(),
                image: item.getImage()?.getValue(),
                releaseDate: item.getReleasedate()?.getValue(),
                classifiers: item.getClassifiers()?.getValue(),
                hasDemo: item.getHasdemo()?.getValue(),
                order: this.getOrder(brandId, item)
            };
            result.push(casinoItem);
        }
        return result;
    }

    private static getOrder (brandId: number, game: CasinoGameDiffsMsg): number {
        const orderList = game.getOrderList();
        if (orderList) {
            const exactBrand = orderList.find(value => value.getBrandid() === brandId);
            if (exactBrand) {
                return exactBrand.getOrder();
            }
        }
        return 0;
    }
}
