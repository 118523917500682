import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";

export class QPi52RefreshBalanceMessageHandler extends AbstractMessageHandler<ExtendedBalance> {
    protected get requestType (): string {
        return "Q.PI.5.2";
    }

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    parseMessageBody (body: Element): ExtendedBalance {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2600") {
            throw new LocalizedError(`errors.mtl.aPi54.code${serverCode}`);
        }
        const betplaceElement = getRequiredChild(action, "BetPlace");
        const balance = getRequiredFloatAttribute(betplaceElement, "balance");
        const betPlaceCasinoElement = getRequiredChild(action, "BetPlaceCasino");
        const casinoBalance = getRequiredFloatAttribute(betPlaceCasinoElement, "balance");
        return {
            mainBalance: this.createBalance(balance),
            casinoBalance: this.createBalance(casinoBalance)
        };
    }

    protected createBalance (balance: number) {
        return {
            primaryAccount: {
                isUsed: true,
                sumAccount: balance,
                isBlocked: false,
                isBonus: false,
                currencyMark: "",
                currencyId: 0,
                accountId: 0,
                sumCaptive: 0,
                accountNumber: ""
            },
            bonusAccount: undefined
        };
    }
}
