import StakesMenu from "@/views/stakes-view/stakes-menu/StakesMenu.vue";
import { useRulesViewCommon } from "@sportaq/vue/views/web/rules/rules-view-common";
import { defineAsyncComponent } from "vue";

export default {
    name: "RulesView",
    components: {
        StakesMenu,
        esgeneral: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/es/GeneralText.vue")),
        esfinance: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/es/FinanceText.vue")),
        esresults: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/es/ResultsText.vue")),
        esspecial: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/es/SpecialText.vue")),
        esstakes: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/es/StakesText.vue")),
        engeneral: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/en/GeneralText.vue")),
        enfinance: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/en/FinanceText.vue")),
        enresults: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/en/ResultsText.vue")),
        enspecial: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/en/SpecialText.vue")),
        enstakes: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/en/StakesText.vue")),
        rugeneral: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/ru/GeneralText.vue")),
        rufinance: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/ru/FinanceText.vue")),
        ruresults: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/ru/ResultsText.vue")),
        ruspecial: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/ru/SpecialText.vue")),
        rustakes: defineAsyncComponent(() => import("@sportaq/vue/views/web/rules/components/ru/StakesText.vue"))
    },
    setup () {
        return useRulesViewCommon();
    }
};
