<template>
    <div class="details-caption"
         :data-cy="`scoreboard-category-line-${source.categoryId}`">
        <img :src="`/img/flags/${flag}.png`" alt="" class="icon"/>
        <div class="title">{{ source.categoryName }}</div>
        <template v-if="source.countryId >=0">
            <div :class="['star', isFavorite? 'active':'']" @click.stop="togglePartitionFavorite"
                 data-cy="favorite-star">
                <svg viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0.363037L12.2451 7.27287H19.5106L13.6327 11.5434L15.8779 18.4532L10 14.1827L4.12215 18.4532L6.36729 11.5434L0.489435 7.27287H7.75486L10 0.363037Z"
                        fill="#EA5D24"/>
                </svg>
            </div>
        </template>
    </div>
</template>

<script src="./compact-scoreboard-category-line-component.ts"/>
