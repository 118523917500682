import { ref, SetupContext } from "vue";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { Point } from "@sportaq/model/web/point";
import { useWebRestService } from "@sportaq/services/web";
import { DialogState } from "@sportaq/vue/views/web/withdrawal/dialog-state";
import { ConfirmationMethod } from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { WithdrawalOrder } from "@sportaq/model/web/withdrawal-order";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { ConfirmationResult, ConfirmationType } from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { EUserRole, EVerifyField, hasRole } from "@sportaq/model/common/user-accounts";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export function usePartnerWithdrawalDialogCommon (minAmount: number, context: SetupContext) {
    const store = useWebStore();
    const i18n = useI18n();
    const amountRef = ref<number>();
    const account = store.user?.userAccounts.getPrimaryAccount();
    const currency = account ? account.currencyMark : "";
    const pointList = ref<Point[]>([]);
    const restService = useWebRestService();
    const dialogState = ref<DialogState>(DialogState.InputAmount);
    const confirmationMethod = ref<ConfirmationMethod>(EVerifyField.email);
    const withdrawalOrder = ref<WithdrawalOrder>();

    function close () {
        context.emit(CLOSE_EVENT);
    }
    function submit () {
        if (dialogState.value === DialogState.InputAmount) {
            if (amountRef.value && amountRef.value < minAmount) {
                showMessageBox(i18n.t("webdesktop.withdrawal.minAmountError", { minAmount: minAmount + currency }));
                return;
            }
            if (hasRole(store.user, EUserRole.EXPRESS)) {
                if (account && amountRef.value) {
                    restService.withdrawal(account.accountNumber, amountRef.value,
                        account.currencyId, "instant").then((code) => {
                        confirmationMethod.value = "instant";
                        restService.emailConfirmation(code).then(result => {
                            dialogState.value = DialogState.Finish;
                            onCodeSuccess(result);
                        });
                    });
                }
            } else {
                dialogState.value = DialogState.ConfirmationType;
            }
        } else if (dialogState.value === DialogState.ConfirmationType) {
            if (account && amountRef.value) {
                restService.withdrawal(account.accountNumber, amountRef.value,
                    account.currencyId, confirmationMethod.value).then(() => {
                    dialogState.value = DialogState.CodeInput;
                });
            }
        } else if (dialogState.value === DialogState.Finish) {
            close();
        }
    }
    function onConfirmCodeClose () {
        close();
    }
    function onCodeSuccess (result: ConfirmationResult) {
        if (result.confirmationType === ConfirmationType.Withdrawal) {
            dialogState.value = DialogState.Finish;
            withdrawalOrder.value = result.withdrawalOrder;
        }
    }
    return {
        amountRef,
        currency,
        pointList,
        dialogState,
        confirmationMethod,
        withdrawalOrder,
        close,
        submit,
        onConfirmCodeClose,
        onCodeSuccess,
        formatVoucherNumber
    };
}
