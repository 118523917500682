export enum EBonusStatus {
    Inactive = 0, Active = 1, Expired = 2, Canceled = 3, Finished = 4
}
/* bonusTypeId
1  -  first deposit sport
4  -  first deposit CASINO */

export interface Bonus {
    readonly bonusId: number;
    readonly initTime?: Date;
    readonly endTime?: Date;
    readonly expirationTime?: number;
    readonly canUse: boolean;
    readonly description:Map<string, string>;
    readonly name:Map<string, string>;
    readonly title:Map<string, string>;
    readonly img: string;
    readonly countBeat: number;
    readonly sumBonus?: number;
    readonly sumStake?: number;
    readonly sumWithdrawal: number;
    readonly status: EBonusStatus;
    readonly miniImg: string;
    readonly bonusTypeId: number;
    readonly isSubscribe: number;
    readonly freeSpin: number;
    readonly gameMark: string;
    isConditionsOpen: boolean;
}

export function getTextIdByBonusType (bonusType: number | undefined) {
    switch (bonusType) {
        case 1: return "reg-s";
        case 4: return "reg-c";
        case 9: return "reg-f";
        default: return "reg-w";
    }
}

export function getBonusTypeByName (bonusName: string) {
    switch (bonusName) {
        case "reg-c": return 4;
        case "reg-s": return 1;
        case "reg-f": return 9;
        default: return -1;
    }
}

export function getBonusName (bonusType: number) {
    if (bonusType === 1) {
        return "bonusRegistration.sportBetting.title";
    }
    if (bonusType === 4) {
        return "bonusRegistration.casino.title";
    }
    if (bonusType === 9) {
        return "bonusRegistration.freeSpins.title";
    }
    return "";
}
