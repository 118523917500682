import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { LoginResult, parseUser } from "@sportaq/services/web/rest/messages/registration/login-result";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { User } from "@sportaq/model/common/user-accounts";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";

export class AUs13Authorization extends AbstractMessageHandler<LoginResult> {
    protected readonly requestType: string = "A.US.1.3";

    constructor (readonly login: string, readonly password: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "UserNumber", this.login);
        request.addChildWithValue(actionElement, "Password", this.password);
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): LoginResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1140") {
            const sessionCode = getRequiredValue(getRequiredChild(_head, "sessioncode"));
            const userElement = getRequiredChild(action, "User");
            const user: User = parseUser(userElement);
            return {
                sessionCode,
                user
            };
        }
        throw new LocalizedError(`errors.mtl.aUs12.code${serverCode}`);
    }
}
