<template>
    <div class="popup active" id="change-phone-dialog">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.changeEmailDialog.caption")}}</div>
            <div class="popup-content">
                <Form @submit="changeEmail" v-slot="{ errors, handleSubmit }">
                    <div class="form-line">
                        <label for="email" :class="{'label-error': errors.email}">{{$t("webdesktop.changeEmailDialog.email")}}</label>
                        <Field id="email" name="email" type="email" rules="required" v-model="email" />
                        <div class="field-error" v-if="errors.email">{{errors.email}}</div>
                    </div>
                    <div class="form-line">
                        <button id="submit-change-phone" class="button button-primary" @click.stop.prevent="handleSubmit(changeEmail)">{{$t("common.forms.continue")}}</button>
                    </div>
                    <div class="form-line">
                        <button class="button button-secondary" @click.prevent="onClose">{{$t("common.forms.cancel")}}</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="confirmDialogVisible" @close="onConfirmDialogClose" @success="onConfirmSuccess" />
</template>

<script src="./change-email-dialog-component.ts" />
