import { computed, PropType } from "vue";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import {
    CountryMenuItem, PartitionCounter
} from "@sportaq/vuex/modules/betting/scoreboard/periods/country-menu-resolver/country-menu-resolver";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { useRoute } from "vue-router";

interface Props {
    country: CountryMenuItem;
    partition: PartitionCounter;
}

export default {
    props: {
        country: {
            required: true,
            type: Object as PropType<CountryMenuItem>
        },
        partition: {
            required: true,
            type: Object as PropType<PartitionCounter>
        }
    },

    setup (props: Props) {
        const store = useScoreboardStore();
        const route = useRoute();

        const isFavourite = computed(() => store.isPartitionFavourite(props.country, props.partition));
        const isSelected = computed(() => store.getEventsViewFilter().isPartitionSelected(props.partition.id));
        const favouriteMode = computed(() => store.scoreboardPageType === EScoreboardPageType.FAVOURITES);

        function toggleFavorite () {
            store.toggleFavouritePartition(props.country, props.partition);
        }

        function filterByPartition () {
            store.updateEventsFilter({
                changePartition: {
                    type: "toggle",
                    value: props.partition.id
                }
            });
            const selected = store.getEventsViewFilter().isPartitionSelected(props.partition.id);
            const basePath = route.params.eventType && route.params.sportType ? `/${route.params.eventType}/${route.params.sportType}` : route.path;
            const path = selected ? basePath + `?partition=${props.partition.id}` : basePath;
            if (!window.location.href.endsWith(path)) {
                history.pushState({}, "", path);
            }
        }

        return {
            isFavourite,
            isSelected,
            favouriteMode,

            toggleFavorite,
            filterByPartition
        };
    }
};
