export interface GoogleTagService {
    sendSuccessRegistrationEvent(): void
    sendSuccessLoginEvent (): void;
}

interface CustomEvent {
    event: string
}
interface DataLayer {
    push(event: CustomEvent): void
}
declare global {
    interface Window {
        dataLayer: DataLayer
    }
}
export class GoogleTagServiceImpl implements GoogleTagService {
    sendSuccessRegistrationEvent (): void {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event: "Registration_ successful" });
        }
    }

    sendSuccessLoginEvent (): void {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({ event: "Login_Success" });
        }
    }
}