import { useWebRestService } from "@sportaq/services/web";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";

interface FormData {
    email: string;
}

export function useChangeEmailDialogCommon (context: SetupContext) {
    const restService = useWebRestService();
    const store = useWebStore();
    const confirmDialogVisible = ref(false);
    const email = ref("");
    const i18n = useI18n();

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function changeEmail (data: FormData) {
        restService.changeEmail(data.email, undefined).then(() => {
            confirmDialogVisible.value = true;
        });
    }

    function onConfirmDialogClose () {
        confirmDialogVisible.value = false;
    }

    function onConfirmSuccess () {
        if (store.user) {
            store.updateUserEmail(email.value);
            showMessageBoxEx(true, undefined, i18n.t("webdesktop.changeEmailDialog.success"));
        }
        onClose();
    }

    return {
        confirmDialogVisible,
        email,

        onClose,
        changeEmail,
        onConfirmDialogClose,
        onConfirmSuccess
    };
}
