import ScoreboardGroupEvent
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-group-events/scoreboard-group-event/ScoreboardGroupEvent.vue";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { computed } from "vue";

export default {
    components: {
        ScoreboardGroupEvent
    },
    setup () {
        const scoreboardStore = useScoreboardStore();
        const groupEvents = computed(() => scoreboardStore.getGroupEventsView());
        return {
            groupEvents
        };
    }
};
