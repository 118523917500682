<template>
    <div id="verification-dialog" class="popup popup-verification-dialog active"
         :class="{'popup-verification-dialog-es' : language === 'es'}"
    >
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.verification.caption")}}</div>
            <div class="popup-content">
                <div class="site-popup-text">
                    {{$t("webdesktop.verification.text")}}
                </div>
                <div class="form-line" v-if="isPhoneVerified" id="phone-verified-label">
                    <label>{{$t("webdesktop.verification.phoneVerified")}}</label>
                </div>
                <div class="form-line" v-else>
                    <label>{{$t("webdesktop.verification.phone")}}</label>
                    <input id="phone-code-input" type="number" @keydown="onNaturalNumberKeyFilter"
                           v-model="phoneConfirmCodeRef" :placeholder="language !== 'es' ? $t('webdesktop.verification.placeholder') + phone : $t('webdesktop.verification.placeholder')">
                    <input type="button" class="get-code-button" @click.prevent="sendPhoneCode"
                           :value="$t('webdesktop.verification.getCode')">
                    <div class="field-error" v-if="phoneError">{{phoneError}}</div>
                </div>
                <div class="form-line" v-if="isEmailVerified" id="email-verified-label">
                    <label>{{$t("webdesktop.verification.emailVerified")}}</label>
                </div>
                <div class="form-line" v-else>
                    <label>{{$t("webdesktop.verification.email")}}</label>
                    <input id="email-code-input" type="email"
                           v-model="emailConfirmCodeRef" :placeholder="language !== 'es' ? $t('webdesktop.verification.placeholder') + email :  $t('webdesktop.verification.placeholder')">
                  <input type="button" class="get-code-button" @click.prevent="sendEmailCode"
                         :value="$t('webdesktop.verification.getCode')">
                    <div class="field-error" v-if="emailError">{{emailError}}</div>
                </div>

                <div class="site-popup-buttons">
                    <a id="ok-button" class="button button-primary" data-cy="confirm-code-dialog-submit-button" v-if="isEmailVerified && isPhoneVerified"
                       @click.prevent="onClose" >{{$t("common.forms.ok")}}</a>
                    <a id="submit-button" class="button button-primary" data-cy="confirm-code-dialog-submit-button" v-else
                       @click.prevent="onSubmit()" :class="{disabled: !isSubmitEnabled}">{{$t("webdesktop.confirmCodeDialog.submitButton")}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./verification-dialog.ts" />
