import { useI18n } from "vue-i18n";
import {
    BettingNavigationSidebarDateFilterComponent
} from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-date/common-betting-date-filter-component";
import {
    TimeFilter
} from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-date/time-filter-functions";
import Button = TimeFilter.Button;
import ETimeSelector = TimeFilter.ETimeSelector;

export default {
    setup () {
        const i18n = useI18n();

        const buttons: Button[] = [
            {
                id: 1,
                timeSelector: ETimeSelector.HOUR,
                value: 1,
                name: i18n.t("common.pluralization.hour", { hour: 1 }, 0)
            },
            {
                id: 2,
                timeSelector: ETimeSelector.DAY,
                value: 0,
                name: i18n.t("common.days.today")
            },
            {
                id: 3,
                timeSelector: ETimeSelector.DAY,
                value: 1,
                name: i18n.t("common.days.tomorrow")
            }
        ];

        const {
            selectedId,
            allButtonActive,
            showDateFilter,
            select,
            clearFilters
        } = BettingNavigationSidebarDateFilterComponent.useBettingNavigationSidebarDateFilter(buttons);

        return {
            buttons,
            selectedId,
            showDateFilter,
            allButtonActive,

            select,
            clearFilters
        };
    }
};
