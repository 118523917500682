import { RootStore } from "@sportaq/vuex/index";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { Quotation } from "@sportaq/model/betting/events/quotation";
import EventType from "@sportaq/common/enums/event-type";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { BetSlipVuex } from "@sportaq/vuex/modules/betting/bet-slip/internal/bet-slip-vuex";
import { PlaceBetDetailedError } from "@sportaq/model/types/place-bet-response";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { CombinedStake, SimpleStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";

export interface BetSlipStore {
    isShowed: boolean;
    isConfirmed: boolean;
    isCombinedBlocked: boolean;
    fullChecking: boolean;
    readonly simples: SimpleStake[];
    readonly combined: CombinedStake[];
    readonly topMessage: string | undefined;

    isStakePresent (eventType: EventType, quotation: Quotation): boolean;

    addSimpleStake (event: BettingEvent, quotation: Quotation, settings: PointSettings): void;

    removeSimpleStake (eventType: EventType, positionId: number, id: number, settings: PointSettings): void;

    setSimpleStakeAmount (eventType: EventType, positionId: number, id: number, amount?: number): void;

    confirmSimpleStake (eventType: EventType, positionId: number, id: number): void;

    confirmAllSimples (): void;

    setCombinedStakeAmount (base: number, bonus: boolean, amount?: number): void;

    handlePlacesBetErrors (causes: PlaceBetDetailedError[], settings: PointSettings): void;

    clear (): void;

    init (): void;

    destroy (): void;
}

export function provideBetSlipStore (rootStore: RootStore, eventSupplier: EventSupplier): BetSlipStore {
    const store = new BetSlipVuex.BetSlipStoreImpl(rootStore.store, eventSupplier);
    provide(betSlipStoreProvideSymbol, store);
    return store;
}

export function provideInjectedBetSlipStore (store: BetSlipStore) {
    provide(betSlipStoreProvideSymbol, store);
}

export function useBetSlipStore (): BetSlipStore {
    return use(betSlipStoreProvideSymbol);
}

const betSlipStoreProvideSymbol = Symbol("Betting Bet Slip Store");
