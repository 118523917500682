import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { AGe22ApplicationStartMessageHandler } from "@sportaq/services/rest/messages/system/a_ge_2_2_application_start";
import { AbstractRestService, RestService } from "@sportaq/services/rest/rest-service";
import { ClockProvider, dateToMtlStr } from "@sportaq/common/utils/time-utils";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { QVc11FindVoucherMessageHandler } from "@sportaq/services/web/rest/messages/account/q_vc_1_1_find-voucher";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { EmailRegistrationData } from "@sportaq/model/web/email-registration-data";
import {
    AUs11EmailRegistrationMessageHandler
} from "@sportaq/services/web/rest/messages/registration/a_us_1_1_email_registration";
import { Region } from "@sportaq/model/web/region";
import { QGe11RegionMessageHandler } from "@sportaq/services/web/rest/messages/system/q_ge_1_1_geo";
import { EVerifyField, User } from "@sportaq/model/common/user-accounts";
import { OneClickRegistrationData } from "@sportaq/model/web/one-click-registration-data";
import {
    AUs110OneClickRegistrationMessageHandler
} from "@sportaq/services/web/rest/messages/registration/a_us_1_10_one_click_registration";
import { AUs13Authorization } from "@sportaq/services/web/rest/messages/registration/a_us_1_3_authorization";
import { AuthorizationData } from "@sportaq/model/web/authorization-data";
import { AUs22SendSms } from "@sportaq/services/web/rest/messages/registration/a_us_2_2_send_sms";
import { UpdateProfileData } from "@sportaq/model/web/update-profile-data";
import { AUs14UpdateProfileMessageHandler } from "@sportaq/services/web/rest/messages/profile/a_us_1_4_update_profile";
import { Card } from "@sportaq/model/cashier/card";
import { QSt100CardsInPlay } from "@sportaq/services/web/rest/messages/stakes/q_st_10_0_cards_in_play";
import { QSt100CardsByDate } from "@sportaq/services/web/rest/messages/stakes/q_st_10_0_cards_by_date";
import { Operation } from "@sportaq/model/web/operation";
import { QPo11OperationsByDate } from "@sportaq/services/web/rest/messages/account/q_po_1_1_get-operations";
import { APo51DepositMessageHandler } from "@sportaq/services/web/rest/messages/account/a_po_5_1_deposit";
import { DepositData } from "@sportaq/model/web/deposit-data";
import { Point } from "@sportaq/model/web/point";
import { QGe23PointListMessageHandler } from "@sportaq/services/web/rest/messages/system/q_ge_2_3_point-list";
import {
    APo68WithdrawalMessageHandler,
    ConfirmationMethod
} from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { VisaWithdrawalParams } from "@sportaq/model/web/withdrawal-params";
import {
    APo617VisaWithdrawalMessageHandler
} from "@sportaq/services/web/rest/messages/account/a_po_6_17_visa-withdrawal";
import { AVc21DepositMessageHandler } from "@sportaq/services/web/rest/messages/account/a_vc_2_1_voucher-deposit";
import { Bonus } from "@sportaq/model/web/bonus";
import { QBn11BonusList } from "@sportaq/services/web/rest/messages/bonus/q_bn_1_1_bonus-list";
import { ABn12CancelBonusMessageHandler } from "@sportaq/services/web/rest/messages/bonus/a_bn_1_2_cancel-bonus";
import { ABn11UseBonusMessageHandler } from "@sportaq/services/web/rest/messages/bonus/a_bn_1_1_use-bonus";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";
import { QSt100CardsWin } from "@sportaq/services/web/rest/messages/stakes/q_st_10_0_cards_win";
import { QSt100CardsLive } from "@sportaq/services/web/rest/messages/stakes/q_st_10_0_cards_live";
import { ACs27CasinoDepositMessageHandler } from "@sportaq/services/web/rest/messages/account/a_cs_2_7_casino-deposit";
import {
    ACs28CasinoWithdrawalMessageHandler
} from "@sportaq/services/web/rest/messages/account/a_cs_2_8_casino-withdrawal";
import { QCs34WagerStakesHandler } from "@sportaq/services/web/rest/messages/account/q_cs_3_4_wager-stakes";
import { HorseWagerContainer, WagerContainer } from "@sportaq/model/cashier/wager-container";
import { WebSettings } from "@sportaq/services/web/settings/settings-service";
import { Banner } from "@sportaq/model/common/banner";
import { ACs29CasinoEnterMessageHandler } from "@sportaq/services/web/rest/messages/games/a_cs_2_9_casino-enter";
import { ACs210CasinoLeaveMessageHandler } from "@sportaq/services/web/rest/messages/games/a_cs_2_10_casino-leave";
import { AUs18SendVerification } from "@sportaq/services/web/rest/messages/registration/a_us_1_8_send-verification";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";
import {
    APo621GeneralWithdrawalMessageHandler
} from "@sportaq/services/web/rest/messages/account/a_po_6_21_general-withdrawal";
import { QUs112ExtendedBalance } from "@sportaq/services/web/rest/messages/account/q_us_1_12_extended-get-balance";
import { QRs12GetRaceStakesHandler } from "@sportaq/services/rest/messages/games/race/q_rs_1_2_get-race-stakes";
import {
    ABn14UnsubscribeBonusMessageHandler
} from "@sportaq/services/web/rest/messages/bonus/a_bn_1_4_unsubscribe-bonus";
import { ABn13SubscribeBonusMessageHandler } from "@sportaq/services/web/rest/messages/bonus/a_bn_1_3_subscribe-bonus";
import { WithdrawalOrderEx } from "@sportaq/model/web/withdrawal-order";
import { QPo61WithdrawalOrders } from "@sportaq/services/web/rest/messages/account/q_po_6_1_get-withdrawal-orders";
import {
    APo62CancelWithdrawalMessageHandler
} from "@sportaq/services/web/rest/messages/account/a_po_6_2_cancel-withdrawal";

export interface WebDesktopRestService extends RestService {

    startApplication (countryId: number, locale: string): Promise<Banner[]>;

    findVoucher (voucher: string): Promise<VoucherSellInfo>;

    voucherDeposit (voucher: string): Promise<void>;

    withdrawal (accountNumber: string, orderSum: number,
        currencyId: number, confirmationMethod: ConfirmationMethod): Promise<string>;

    visaWithdrawal (accountNumber: string, currencyId: number,
        withdrawalParams: VisaWithdrawalParams, confirmationMethod: ConfirmationMethod): Promise<void>;

    generalWithdrawal (withdrawalMethod: PaymentSystems, confirmationMethod: ConfirmationMethod,
                       currencyId: number, orderSum: number): Promise<void>;

    getRegions (countryId: number): Promise<Region[]>;

    emailRegistration (data: EmailRegistrationData, bonusId: number | undefined): Promise<User>;

    oneClickRegistration (data: OneClickRegistrationData): Promise<AuthorizationData>;

    authorization (ad: AuthorizationData): Promise<User>;

    sendSms (data: EmailRegistrationData): Promise<boolean>;

    sendVerification (confirmationMethod: EVerifyField): Promise<void>;

    requestUserInfo (): Promise<User>;

    updateProfile (data: UpdateProfileData): Promise<void>;

    getCardsInPlay (locale: string): Promise<Card[]>;

    getLiveCards (locale: string): Promise<Card[]>;

    getCardsByDate (locale: string, startDate: Date, endDate: Date, onlyCalculated: boolean): Promise<Card[]>;

    getOperations (operations: string[], startDate: Date, endDate: Date): Promise<Operation[]>;

    getWagerContainers (startTime: Date, endTime?: Date): Promise<WagerContainer[]>;

    getWebRaceStakes (startDate: Date, endDate: Date | undefined, lastContainerId: number): Promise<HorseWagerContainer[]>;

    deposit (depositMethod: string, orderSum: number, currencyId: number): Promise<DepositData>;

    getPointList (pointTypeId: number, partnerList: number[]): Promise<Point[]>;

    getBonusList (countryId: number): Promise<Bonus[]>;

    cancelBonus (bonusId: number): Promise<void>;

    useBonus (bonusId: number): Promise<void>;

    casinoDeposit (amount: number): Promise<void>;

    casinoWithdrawal (amount: number): Promise<void>;

    casinoEnter (): Promise<void>;

    casinoLeave (): Promise<void>;

    cancelBonusSubscription (bonusId: number): Promise<void>;

    subscribeBonus (bonusId: number): Promise<void>;

    getWithdrawalOrders (startDate: Date, endDate: Date): Promise<WithdrawalOrderEx[]>

    cancelWithdrawal (orderGuid: string): Promise<void>
}

export class WebDesktopRestServiceImpl extends AbstractRestService<BaseSettingsService> implements WebDesktopRestService {
    constructor (clockProvider: ClockProvider, uuidProvider: UUIDProvider, readonly webSettingService: WebSettings) {
        super(uuidProvider, clockProvider, webSettingService);
    }

    async startApplication (countryId: number, locale: string): Promise<Banner[]> {
        const xmlRequest = new XmlRequest(
            this.settingService.appCode,
            this.clockProvider,
            "00000000-0000-0000-0000-000000000000",
            locale);
        const handler = new AGe22ApplicationStartMessageHandler(countryId);
        const applicationStartResult = await this.executeRequest(handler, xmlRequest);
        this.session = applicationStartResult.sessionCode;
        this.settingService.pointSettings = applicationStartResult.pointSettings;
        return applicationStartResult.banners;
    }

    async getWithdrawalOrders (startDate: Date, endDate: Date): Promise<WithdrawalOrderEx[]> {
        const handler = new QPo61WithdrawalOrders(startDate, endDate);
        return this.executeRequest(handler);
    }

    async getWebRaceStakes (startDate: Date, endDate: Date | undefined, lastContainerId: number): Promise<HorseWagerContainer[]> {
        const handler = new QRs12GetRaceStakesHandler(false, startDate, endDate, lastContainerId);
        return this.executeRequest(handler);
    }

    async findVoucher (voucher: string): Promise<VoucherSellInfo> {
        const handler = new QVc11FindVoucherMessageHandler(voucher);
        return this.executeRequest(handler);
    }

    async generalWithdrawal (withdrawalMethod: PaymentSystems, confirmationMethod: ConfirmationMethod,
        currencyId: number, orderSum: number): Promise<void> {
        const handler = new APo621GeneralWithdrawalMessageHandler(withdrawalMethod, confirmationMethod, currencyId, orderSum);
        return this.executeRequest(handler);
    }

    async withdrawal (accountNumber: string, orderSum: number,
        currencyId: number, confirmationMethod: ConfirmationMethod): Promise<string> {
        const handler = new APo68WithdrawalMessageHandler(accountNumber, orderSum, currencyId, confirmationMethod);
        return this.executeRequest(handler);
    }

    async visaWithdrawal (accountNumber: string, currencyId: number,
        withdrawalParams: VisaWithdrawalParams, confirmationMethod: ConfirmationMethod) {
        const handler = new APo617VisaWithdrawalMessageHandler(accountNumber, currencyId, withdrawalParams, confirmationMethod);
        return this.executeRequest(handler);
    }

    async getRegions (countryId: number): Promise<Region[]> {
        const handler = new QGe11RegionMessageHandler(countryId);
        return this.executeRequest(handler);
    }

    async emailRegistration (data: EmailRegistrationData, bonusId: number | undefined): Promise<User> {
        const handler = new AUs11EmailRegistrationMessageHandler(data, bonusId);
        const result = await this.executeRequest(handler);
        this.session = result.sessionCode;
        if (result.user === undefined) {
            throw new Error("User can't be null after success registration");
        }
        return result.user;
    }

    async oneClickRegistration (data: OneClickRegistrationData): Promise<AuthorizationData> {
        const handler = new AUs110OneClickRegistrationMessageHandler(data);
        return this.executeRequest(handler);
    }

    async authorization (ad: AuthorizationData): Promise<User> {
        const handler = new AUs13Authorization(ad.login, ad.password);
        const result = await this.executeRequest(handler);
        this.session = result.sessionCode;
        if (result.user === undefined) {
            throw new Error("User can't be null after success authorization");
        }
        return result.user;
    }

    async sendSms (data: EmailRegistrationData): Promise<boolean> {
        const handler = new AUs22SendSms(data);
        return this.executeRequest(handler);
    }

    async sendVerification (confirmationMethod: EVerifyField): Promise<void> {
        const handler = new AUs18SendVerification(confirmationMethod);
        return this.executeRequest(handler);
    }

    async updateProfile (data: UpdateProfileData): Promise<void> {
        const handler = new AUs14UpdateProfileMessageHandler(data);
        return this.executeRequest(handler);
    }

    async getCardsInPlay (locale: string): Promise<Card[]> {
        const handler = new QSt100CardsInPlay(locale, this.settingService.pointSettings.draftCoef);
        return this.executeRequest(handler);
    }

    async getWinCards (locale: string): Promise<Card[]> {
        const handler = new QSt100CardsWin(locale, this.settingService.pointSettings.draftCoef);
        return this.executeRequest(handler);
    }

    async getLiveCards (locale: string): Promise<Card[]> {
        const handler = new QSt100CardsLive(locale, this.settingService.pointSettings.draftCoef);
        return this.executeRequest(handler);
    }

    async getCardsByDate (locale: string, startDate: Date, endDate: Date, onlyCalculated: boolean): Promise<Card[]> {
        const handler = new QSt100CardsByDate(locale, startDate, endDate, this.settingService.pointSettings.draftCoef, onlyCalculated);
        return this.executeRequest(handler);
    }

    async getExtendedBalance (): Promise<ExtendedBalance> {
        const handler = new QUs112ExtendedBalance();
        return this.executeRequest(handler);
    }

    async getOperations (operations: string[], startDate: Date, endDate: Date): Promise<Operation[]> {
        const handler = new QPo11OperationsByDate(operations, startDate, endDate);
        return this.executeRequest(handler);
    }

    async deposit (depositMethod: string, orderSum: number, currencyId: number): Promise<DepositData> {
        const handler = new APo51DepositMessageHandler(depositMethod, orderSum, currencyId);
        return this.executeRequest(handler);
    }

    async voucherDeposit (voucher: string): Promise<void> {
        const handler = new AVc21DepositMessageHandler(voucher);
        return this.executeRequest(handler);
    }

    async getPointList (pointTypeId: number, partnerList: number[]): Promise<Point[]> {
        const handler = new QGe23PointListMessageHandler(pointTypeId, partnerList);
        return this.executeRequest(handler);
    }

    async getBonusList (countryId: number): Promise<Bonus[]> {
        const handler = new QBn11BonusList(countryId);
        return this.executeRequest(handler);
    }

    async cancelBonus (bonusId: number): Promise<void> {
        const handler = new ABn12CancelBonusMessageHandler(bonusId);
        return this.executeRequest(handler);
    }

    async useBonus (bonusId: number): Promise<void> {
        const handler = new ABn11UseBonusMessageHandler(bonusId);
        return this.executeRequest(handler);
    }

    async casinoDeposit (amount: number): Promise<void> {
        const handler = new ACs27CasinoDepositMessageHandler(amount);
        return this.executeRequest(handler);
    }

    async casinoWithdrawal (amount: number): Promise<void> {
        const handler = new ACs28CasinoWithdrawalMessageHandler(amount);
        return this.executeRequest(handler);
    }

    async casinoEnter (): Promise<void> {
        const handler = new ACs29CasinoEnterMessageHandler();
        return this.executeRequest(handler);
    }

    async casinoLeave (): Promise<void> {
        const handler = new ACs210CasinoLeaveMessageHandler();
        return this.executeRequest(handler);
    }

    async getWagerContainers (startTime: Date, endTime?: Date): Promise<WagerContainer[]> {
        const handler = new QCs34WagerStakesHandler(dateToMtlStr(startTime), endTime ? dateToMtlStr(endTime) : undefined);
        return this.executeRequest(handler);
    }

    async cancelBonusSubscription (bonusId: number): Promise<void> {
        const handler = new ABn14UnsubscribeBonusMessageHandler(bonusId);
        return this.executeRequest(handler);
    }

    async subscribeBonus (bonusId: number): Promise<void> {
        const handler = new ABn13SubscribeBonusMessageHandler(bonusId);
        return this.executeRequest(handler);
    }

    cancelWithdrawal (orderGuid: string): Promise<void> {
        const handler = new APo62CancelWithdrawalMessageHandler(orderGuid);
        return this.executeRequest(handler);
    }
}
