import { useI18n } from "vue-i18n";
import { computed, onMounted, onUnmounted, watch } from "vue";
import { showError } from "@sportaq/vue/utils/exceptions-functions";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useWebRestService } from "@sportaq/services/web";
import { AccountCategory, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import {
    disableWebDisconnectTimer,
    enableWebDisconnectTimer
} from "@sportaq/vue/components/web/disconnect-worker/idle-disconnect";

export function isCasinoRoute (route: string | undefined | null) {
    return route === COMMON_ROUTES.CASINO.name || route === COMMON_ROUTES.VIRTUAL.name || route === COMMON_ROUTES.AVIATOR.name;
}

export function isSportRoute (value: string | undefined | null) {
    if (!value) {
        return false;
    }
    return value.indexOf(COMMON_ROUTES.LIVE.path) >= 0 || value.indexOf(COMMON_ROUTES.SPORT.path) >= 0;
}

export function useWebVirtualGamesLifecycle () {
    const i18n = useI18n();
    const restService = useWebRestService();
    const balanceStore = useBalanceStore();
    const store = useWebStore();

    onMounted(async () => {
        disableWebDisconnectTimer();
        if (store.user && balanceStore.activeAccount !== AccountCategory.Casino) {
            try {
                balanceStore.activeAccount = AccountCategory.Casino;
                await restService.casinoEnter();
            } catch (e) {
                showError(i18n, e as Error, "Error of sending A.CS.2.9 request");
            }
            eventBus.emit(Events.REFRESH_BALANCE);
        }
    });

    onUnmounted(() => {
        enableWebDisconnectTimer();
    });

    function getLeaveGuard () {
        if (store.user) {
            restService.casinoLeave()
                .catch(reason => showError(i18n, reason as Error, "Error of sending A.CS.2.10 request"))
                .finally(() => {
                    setTimeout(() => eventBus.emit(Events.REFRESH_BALANCE), 500);
                });
        }
    }

    onBeforeRouteLeave((to, from, next) => {
        if (!isCasinoRoute(to.name?.toString())) {
            setTimeout(() => getLeaveGuard(), 50); // delay for closing game request sending
        }
        next();
    });
}

export function makeCasinoUserWatch () {
    const store = useWebStore();
    const balanceStore = useBalanceStore();
    const restService = useWebRestService();
    const route = useRoute();
    const isLoggedIn = computed(() => store.user !== undefined);

    function switchBalance () {
        if (isSportRoute(route.path.toString())) {
            balanceStore.activeAccount = AccountCategory.Main;
        } else if (!isCasinoRoute(route.name?.toString())) {
            balanceStore.activeAccount = AccountCategory.Other;
        }
    }

    switchBalance();

    watch(() => route.name, () => {
        switchBalance();
    });

    watch(isLoggedIn, async () => {
        if (isLoggedIn.value) {
            if (isCasinoRoute(route.name?.toString())) {
                await restService.casinoEnter();
                balanceStore.activeAccount = AccountCategory.Casino;
            } else {
                await restService.casinoLeave();
                if (isSportRoute(route.path.toString())) {
                    balanceStore.activeAccount = AccountCategory.Main;
                } else {
                    balanceStore.activeAccount = AccountCategory.Other;
                }
            }
        }
    });
}
