import {
    BettingNavigationSidebarComponent
} from "@sportaq/vue/components/betting/betting-navigation-sidebar/common-betting-navigation-sidebar-component";
import { computed, onBeforeUpdate, ref, watch } from "vue";
import BettingFilterMenu from "@sportaq/vue/components/betting/betting-filter-menu/BettingFilterMenu.vue";
import MobileEventSearchDialog from "@/components/betting/event-search-dialog/MobileEventSearchDialog.vue";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { useRootStore } from "@sportaq/vuex/index";

export default {
    components: {
        BettingFilterMenu,
        MobileEventSearchDialog
    },
    setup () {
        const store = useRootStore();
        const betSlipStore = useBetSlipStore();
        const isSearchDialogOpened = ref(false);

        const {
            isFilterActive,
            isFilterVisible,
            favouriteMode,
            favouriteCount,
            leagueMode,
            liveReverseTimeSorting,
            isPopular,
            showPopular,
            showReverseTimeSorting,
            showLeague,

            toggleFavouriteMode,
            togglePopularMode,
            toggleLeagueMode,
            toggleReverseTimeSorting,
            toggleFilterVisible
        } = BettingNavigationSidebarComponent.useBettingNavigationSidebarComponent();

        const imageThemePath = computed(() => store.theme);

        onBeforeUpdate(() => {
            closeSearchDialog();
        });

        function openSearchDialog () {
            isSearchDialogOpened.value = true;
        }

        function closeSearchDialog () {
            isSearchDialogOpened.value = false;
        }

        watch(() => betSlipStore.isShowed, () => {
            closeSearchDialog();
        });

        return {
            isFilterActive,
            isFilterVisible,
            isSearchDialogOpened,
            favouriteMode,
            favouriteCount,
            leagueMode,
            liveReverseTimeSorting,
            isPopular,
            showPopular,
            showReverseTimeSorting,
            showLeague,
            imageThemePath,

            toggleFavouriteMode,
            togglePopularMode,
            toggleLeagueMode,
            toggleReverseTimeSorting,
            toggleFilterVisible,
            openSearchDialog,
            closeSearchDialog
        };
    }
};
