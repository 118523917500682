<template>
    <div class="slider-section" v-if="rows.length > 0 && !selectedItem" :data-cy="dataCy">
        <div class="caption" v-if="labelInfo" data-cy="games-body-caption">
            <div data-cy="games-body-caption-img">
                <img :src="labelInfo.image" :alt="labelInfo.title">
            </div>
            <div data-cy="games-body-caption-title" v-if="labelInfo.title">
                {{ labelInfo.title }}
            </div>
          <div data-cy="games-body-caption-title" v-else>
            {{ $t(labelInfo.name) }}
          </div>
        </div>
        <recycle-scroller
            class="wrapper"
            :key-field="'id'"
            :items="rows"
            :item-size="itemSize"
            :buffer="buffer"
            @resize="onResize"
            v-slot="{ item }"
            data-cy="games-body">
            <div class="game-row">
                <game-widget
                    v-for="el of item.items" :key="el.id"
                    :item="el" :showDemo="showDemo" :showBacklight="!!$slots.preview"
                    @startGame="startGame" @startDemo="startDemo"/>
            </div>
        </recycle-scroller>
    </div>
    <div v-if="$slots.preview && selectedItem">
        <slot name="preview" :item="selectedItem" :close="clearSelectedItem"/>
    </div>
</template>

<script src="./games-body-component.ts"/>
