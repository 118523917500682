<template>
    <div :class="cssClass" v-if="items.length > 0">
        <a class="arrow arrow-left" @click.stop.prevent="scrollLeft"/>
        <div :class="cssClass+'-wrapper'" :data-cy="testSelector">
            <div :class="cssClass+'-items'">
                <div v-for="item of sliderItems" :key="item.id" class="item"
                     :class="{active: item.id === selectedId}" @click.stop="itemSelected(item)" :data-cy="`slider-item-${item.id}`">
                    <slot :item="item" :selected="item.id === selectedId"/>
                </div>
            </div>
        </div>
        <a class="arrow arrow-right" @click.stop.prevent="scrollRight"/>
    </div>
</template>

<script src="./item-slider-component.ts"/>
