import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { useKeyHandler } from "@sportaq/vue/components/common/dialog-key-handler/dialog-key-handler";

export default {
    props: {
        message: { required: false, type: String },
        caption: { required: false, type: String },
        success: { required: false, type: Boolean },
        isActive: { required: true, type: Boolean }
    },
    emits: [CLOSE_EVENT],
    setup (props: [], context: SetupContext) {
        function close () {
            context.emit(CLOSE_EVENT);
        }
        useKeyHandler(close, close);
        return {
            close
        };
    }
};
