export enum ECompileChannel {
    release = "release",
    beta = "beta",
    dev = "dev"
}

export enum ECompileBrandType {
    betgol = "betgol",
    oncebet = "oncebet",
    latinoApuesta = "latinoapuesta",
    ligabet = "ligabet",
    columbiaApuesta = "apuestas",
    certabet = "certabet"
}

export enum ECompileVersionType {
    full = "full",
    withoutBalance = "withoutBalance"
}

export function stringToCompileBrandType (str: string): ECompileBrandType {
    switch (str) {
        case "betgol":
            return ECompileBrandType.betgol;
        case "oncebet":
            return ECompileBrandType.oncebet;
        case "latinoapuesta":
            return ECompileBrandType.latinoApuesta;
        case "ligabet":
            return ECompileBrandType.ligabet;
        case "apuestas":
            return ECompileBrandType.columbiaApuesta;
        case "certabet":
            return ECompileBrandType.certabet;
        default:
            return ECompileBrandType.betgol;
    }
}
