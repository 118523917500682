import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { useGameSearchCommon } from "@sportaq/vue/components/games/common/search-panel/game-search-common";

export default {
    emits: [CLOSE_EVENT],
    name: "GameSearchDialog",
    setup (props: [], context: SetupContext) {
        return useGameSearchCommon(context);
    }
};
