export type ValueChanger<T> =
    | { type: "set"; value: T }
    | { type: "toggle"; value: T }
    | { type: "unset" }

export function handleValueChanger<T> (changer: ValueChanger<T> | undefined, getter: () => T | undefined, setter: (value: T | undefined) => void): boolean {
    if (changer) {
        const current = getter();
        switch (changer.type) {
            case "set": {
                if (current !== changer.value) {
                    setter(changer.value);
                    return true;
                }
                break;
            }
            case "unset": {
                if (current) {
                    setter(undefined);
                    return true;
                }
                break;
            }
            case "toggle": {
                // This type should be handled manually
                break;
            }
        }
    }
    return false;
}
