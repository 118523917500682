import EPeriodType from "@sportaq/common/enums/period-types";
import QuotationView from "@sportaq/common/enums/quotation-views";
import { binarySearchIndex, insertIntoSortedArrayAtIndex } from "@sportaq/common/utils/arrays";

export class PeriodStore {
    private _mainPeriod: Period = new Period(EPeriodType.MAIN);
    private periods: Period[] = [];

    getPeriod (type: EPeriodType): Period {
        if (type === EPeriodType.MAIN) {
            return this._mainPeriod;
        }
        const index = binarySearchIndex(this.periods, type, (a, b) => a.type - b);
        if (index >= 0) {
            return this.periods[index];
        } else {
            const result = new Period(type);
            insertIntoSortedArrayAtIndex(this.periods, index, result);
            return result;
        }
    }

    get mainPeriod () {
        return this._mainPeriod;
    }

    getPeriods (): Period[] {
        return this.periods;
    }

    removePeriod (type: EPeriodType) {
        const index = binarySearchIndex(this.periods, type, (a, b) => a.type - b);
        if (index >= 0) {
            this.periods.splice(index, 1);
        }
    }
}

export class Period {
    private views = new Map<QuotationView, number[]>();

    constructor (readonly type: EPeriodType) {
    }

    addView (view: QuotationView, ids: number[]) {
        this.views.set(view, ids);
    }

    getView (view: QuotationView): number[] {
        return this.views.get(view) ?? [];
    }
}
