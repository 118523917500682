import { ISubscriber } from "rsocket-types";
import {
    Flow,
    StreamingNetwork,
    StreamingNetworkConnectResponse
} from "@sportaq/services/streaming/streaming-network/streaming-network";
import { StreamingNetworkRoutes } from "@sportaq/services/streaming/streaming-network/streaming-network-routes";

export class MockBaseStreamingNetwork implements StreamingNetwork {
    private preMatchSubscriber: ISubscriber<unknown> | undefined;
    private liveSubscriber: ISubscriber<unknown> | undefined;
    private casinoGamesSubscriber: ISubscriber<unknown> | undefined;

    async connect (): Promise<StreamingNetworkConnectResponse> {
        return Promise.resolve({
            serverName: "MOCK",
            serverVersion: "0.0.0",
            protocolVersion: 0,
            isSupportedClientProtocolVersion: true,
            language: "ru"
        });
    }

    disconnect () {
        // Empty
    }

    async requestResponse<T> (route: string): Promise<T> {
        return Promise.reject(new Error(`Rout ${route} isn't supported by mock E2eNetworkService`));
    }

    async requestStream<T> (route: string): Promise<Flow<T>> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const $this = this;
        return Promise.resolve({
            subscribe (subscriberOrCallback?: Partial<ISubscriber<T>> | (<T>(a: T) => void)) {
                switch (route) {
                    case StreamingNetworkRoutes.PRE_MATCH_EVENT_DIFFS_STREAM: {
                        $this.preMatchSubscriber = subscriberOrCallback as ISubscriber<unknown>;
                        break;
                    }
                    case StreamingNetworkRoutes.LIVE_EVENT_DIFFS_STREAM: {
                        $this.liveSubscriber = subscriberOrCallback as ISubscriber<unknown>;
                        break;
                    }
                    case StreamingNetworkRoutes.CASINO_GAMES_DIFFS_STREAM: {
                        $this.casinoGamesSubscriber = subscriberOrCallback as ISubscriber<unknown>;
                        break;
                    }
                    default: {
                        throw new Error(`Rout ${route} isn't supported by mock E2eNetworkService requestStream`);
                    }
                }
            }
        });
    }

    public pushToStream (route: string, message: unknown) {
        let subscriber;
        switch (route) {
            case StreamingNetworkRoutes.PRE_MATCH_EVENT_DIFFS_STREAM: {
                subscriber = this.preMatchSubscriber;
                break;
            }
            case StreamingNetworkRoutes.LIVE_EVENT_DIFFS_STREAM: {
                subscriber = this.liveSubscriber;
                break;
            }
            case StreamingNetworkRoutes.CASINO_GAMES_DIFFS_STREAM: {
                subscriber = this.casinoGamesSubscriber;
                break;
            }
            default: {
                throw new Error(`Rout ${route} isn't supported by mock E2eNetworkService requestStream`);
            }
        }

        if (subscriber) {
            subscriber.onNext(message);
        } else {
            throw new Error(`E2eNetworkService: Subscriber for rout ${route} hasn't already set`);
        }
    }

    setDisconnectCallback () {
        // Empty
    }

    protected static getDiffsFileName (route: string, index: number) {
        let name = "";
        switch (route) {
            case StreamingNetworkRoutes.PRE_MATCH_EVENT_DIFFS_STREAM: {
                name = "prematch";
                break;
            }
            case StreamingNetworkRoutes.LIVE_EVENT_DIFFS_STREAM: {
                name = "live";
                break;
            }
            case StreamingNetworkRoutes.CASINO_GAMES_DIFFS_STREAM: {
                name = "casino_games";
                break;
            }
            default: {
                throw new Error(`Rout ${route} isn't supported by mock E2eNetworkService requestStream`);
            }
        }
        return MockBaseStreamingNetwork.pad(index, 3) + "_" + name + ".json";
    }

    private static pad (num: number, size: number) {
        const s = "000000000" + num;
        return s.substr(s.length - size);
    }
}
