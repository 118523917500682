import { UserBalance } from "@sportaq/model/common/user-balance";
import { AccountType, BalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { useMiscellaneousParamsProvider } from "@sportaq/services/index";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { WebDesktopRestService } from "@sportaq/services/web/rest/web-desktop-rest-service";
import { computed, onMounted, onUnmounted, watch } from "vue";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { BalanceUpdate } from "@sportaq/vue/components/common/balance-component/common/balance-update-functions";

export interface BalanceUpdateParams {
    canSwitchAccounts: boolean;
    balanceUpdateCallBack?: (balance: UserBalance) => void;
    isLoggedIn?: () => boolean;
}

export function useWebBalanceUpdate (restService: WebDesktopRestService, balanceStore: BalanceStore, settingsService: BaseSettingsService, params: BalanceUpdateParams) {
    // eslint-disable-next-line
    let timerId: any;
    const i18n = useI18n();
    const paramsProvider = useMiscellaneousParamsProvider();
    const mainAccountType = computed(() => balanceStore.mainAccountType);
    const casinoAccountType = computed(() => balanceStore.casinoAccountType);
    watch(mainAccountType, (newVal, oldVal) => {
        if (oldVal === AccountType.USER_ACCOUNT_UNKNOWN || newVal === AccountType.USER_ACCOUNT_UNKNOWN) {
            return;
        }
        showMessageBox(i18n.t(newVal === AccountType.USER_ACCOUNT_PRIMARY
            ? "webdesktop.accountSwitch.sport.primary"
            : "webdesktop.accountSwitch.sport.bonus"));
    });

    watch(casinoAccountType, (newVal, oldVal) => {
        if (oldVal === AccountType.USER_ACCOUNT_UNKNOWN || newVal === AccountType.USER_ACCOUNT_UNKNOWN) {
            return;
        }
        showMessageBox(i18n.t(newVal === AccountType.USER_ACCOUNT_PRIMARY
            ? "webdesktop.accountSwitch.casino.primary"
            : "webdesktop.accountSwitch.casino.bonus"));
    });

    async function refreshBalance () {
        return BalanceUpdate.refreshBalance(restService, balanceStore, settingsService, params);
    }

    onMounted(async () => {
        await refreshBalance();
        eventBus.on(Events.REFRESH_BALANCE, refreshBalance);
        timerId = setInterval(() => {
            eventBus.emit(Events.REFRESH_BALANCE);
        }, paramsProvider.balanceRefreshTimeout);
    });

    onUnmounted(() => {
        eventBus.off(Events.REFRESH_BALANCE, refreshBalance);
        clearInterval(timerId);
    });
    return refreshBalance;
}
