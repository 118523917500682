<template>
    <div class="popup active" id="partner-withdrawal-dialog">
        <div class="overlay" @click="close"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.withdrawal.general." + paymentSystem)}}</div>
            <div class="popup-content">
              <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }" v-if="dialogState === 1 || dialogState === 2">
                <div class="site-popup-form">
                  <div class="part" v-if="dialogState === 1">
                    <div class="form-line" >
                      <label for="orderedSum"  :class="{'label-error': errors.orderedSum}">{{$t("webdesktop.withdrawal.visaForm.orderedSum", { minAmount: minAmount + currency })}}</label>
                      <Field type="number" id="orderedSum" name="orderedSum" rules="required" v-model.number="amountRef" />
                      <div class="field-error" v-if="errors.orderedSum">{{errors.orderedSum}}</div>
                    </div>
                    <div class="form-line" >
                      <label>{{$t("webdesktop.withdrawal.general.phoneNumber", { phoneNumber })}}</label>
                    </div>
                  </div>
                </div>
                <div class="part" v-if="dialogState === 2"> <!-- State 2 Confirmation method -->
                  <div class="text">
                    {{$t("webdesktop.withdrawal.partner.confirmationMethod")}}
                  </div>
                  <div class="form-line">
                    <label>
                      <input type="radio" id="phone" name="phone" value="phone" v-model="confirmationMethod">
                      {{$t("webdesktop.withdrawal.partner.confirmationPhone")}}
                    </label>
                  </div>
                  <div class="form-line">
                    <label>
                      <input type="radio" id="email" name="email" value="email" v-model="confirmationMethod">
                      {{$t("webdesktop.withdrawal.partner.confirmationEmail")}}
                    </label>
                  </div>
                </div>
                <div class="form-line">
                  <a class="button button-primary" @click.stop.prevent="handleSubmit(onSubmit)">{{$t("common.forms.continue")}}</a>
                </div>
                <div class="form-line">
                  <button class="button button-secondary" v-if="dialogState !== 4" @click.prevent="close">{{$t("common.forms.cancel")}}</button>
                </div>
              </Form>
              <template v-if="withdrawalOrder" >
                  <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.title")}}{{withdrawalOrder.id}}</label>
                  </div>
                  <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.total")}}
                      {{withdrawalOrder.orderSum}}{{withdrawalOrder.currencyMark}}</label>
                  </div>
                  <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.expiration")}}{{$d(withdrawalOrder.expirationTime, 'long')}}</label>
                  </div>
                  <div class="form-line">
                    <a class="button button-primary" @click.stop.prevent="onSubmit">{{$t("common.forms.ok")}}</a>
                  </div>
              </template>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="dialogState === 3" @success="onCodeSuccess" @close="onConfirmCodeClose" />
</template>

<script src="./general-withdrawal-dialog-component.ts" />
