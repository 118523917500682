import { RootStore } from "@sportaq/vuex/index";
import { use } from "@sportaq/common/utils/dependency-injection";
import { provide } from "vue";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { CasinoGamesVuex } from "@sportaq/vuex/modules/games/internal/casino-games-vuex";
import { GameFilterChanger, GameViewFilter } from "@sportaq/vuex/modules/games/game-view-filter/GameViewFilter";
import { BaseSettingsService } from "@sportaq/services/src/base-setting-service/base-settings-service";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import { CountGameProvider, GameProvider } from "@sportaq/model/consts/game-providers";

export interface CasinoGamesStore {
    favouriteMode: boolean;

    getProviders (): CountGameProvider[];

    setProviders (value: GameProvider[]): void;

    getGamesView (): GameSortInfo[];

    updateGameFilters (changers: GameFilterChanger[]): void;

    updateGameFilter (changer: GameFilterChanger): void;

    clearGameFilter (): void;

    getGameViewFilter (): GameViewFilter;

    isFavourite (item: GameSortInfo): boolean;

    toggleFavourite (item: GameSortInfo): void;

    getFavouritesCount (): number;

    addRestrictedGameId (id: string): void;

    init (): void;

    destroy (): void;
}

export function provideCasinoGamesStore (rootStore: RootStore, casinoGamesSupplier: CasinoGamesSupplier, settingsService: BaseSettingsService): CasinoGamesStore {
    const casinoGamesStore = new CasinoGamesVuex.CasinoGamesStoreImpl(rootStore.store, casinoGamesSupplier, settingsService);
    provide(casinoGamesStoreSymbol, casinoGamesStore);
    return casinoGamesStore;
}

export function provideInjectedCasinoGamesStore (casinoGamesStore: CasinoGamesStore) {
    provide(casinoGamesStoreSymbol, casinoGamesStore);
}

export function useCasinoGamesStore (): CasinoGamesStore {
    return use(casinoGamesStoreSymbol);
}

export const casinoGamesStoreSymbol = Symbol("Casino Games Store");
