import { useAllStakesComponentCommon } from "@sportaq/vue/views/web/stakes/all-stakes-component-common";
import CardPanel from "@sportaq/vue/components/web/mobile/card-panel/CardPanel.vue";
import { computed } from "vue";

export default {
    name: "AllStakes",
    components: {
        CardPanel
    },
    props: {
        onlyCalculated: { required: false, type: Boolean }
    },
    setup (props: {readonly onlyCalculated: boolean | undefined}) {
        const {
            cardsRef,
            startDateRef,
            endDateRef,
            itemsPerPageRef,
            currentPage,
            totalPages,
            requestPending
        } = useAllStakesComponentCommon(props);
        const cardsPaged = computed(() => {
            return cardsRef.value.slice(0, currentPage.value * itemsPerPageRef.value);
        });
        return {
            startDateRef,
            endDateRef,
            itemsPerPageRef,
            cardsPaged,
            currentPage,
            totalPages,
            requestPending
        };
    }
};
