<template>
    <template v-if="checkingUpdates">
        <main class="container">
            <div class="popup error active" id="checking-updates-messagebox" data-cy="checking-updates-message-box">
                <div class="popup-container">
                    <div class="popup-text">
                        <pre>{{ updateMessage }}</pre>
                    </div>
                </div>
            </div>
        </main>
    </template>
    <template v-else-if="appLoading">
        <window-buttons-panel v-if="false"/>
        <main class="container">
            <div class="preloader">
                <div class="preloader-el"></div>
            </div>
        </main>
    </template>
    <template v-else-if="appStarted">
        <app-menu-bar/>
        <main class="container">
            <router-view/>
            <mobile-bet-slip v-if="isBetSlipShowed"/>
            <common-footer/>
            <login-popup-dialog v-if="loginDialogVisible" @close="onLoginDialogClose"
                                @openDialog="openResetPasswordDialog"/>
            <reset-password-dialog v-if="resetPasswordDialogVisible" @close="onResetPasswordDialogClose"/>
            <express-change-password-dialog v-if="changePasswordDialogVisible" @close="onChangePasswordDialogClose"></express-change-password-dialog>
            <install-application-dialog></install-application-dialog>
        </main>
        <app-bottom-bar/>
    </template>
    <template v-else-if="forbidden">
        <div id="forbidden-message" class="forbidden">
            <div class="forbidden-text">
                The site is not available<br/> for your country.
            </div>
        </div>
    </template>
    <template v-else>
        <main class="container"/>
    </template>
</template>

<script src="./main-view-component.ts"></script>
