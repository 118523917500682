import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { Module, Store } from "vuex";
import { RootState, RootStore } from "@sportaq/vuex/index";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import AbstractStore from "@sportaq/vuex/abstract-store";

export const balanceModule = "balanceModule";

interface BalanceState {
    mainBalance: BigDecimal;
    mainBonusBalance: BigDecimal;
    casinoBalance: BigDecimal;
    casinoBonusBalance: BigDecimal;
    mainAccountType: AccountType;
    casinoAccountType: AccountType;
    activeAccount: AccountCategory;
    initialized: boolean;
    currency: string;
    currencyId: number;
    currencyValue: number;
    balanceIsChanging: boolean;
}

enum Getters {
    GET_BALANCE = "getBalance",
    GET_BONUS = "getBonus",
    GET_CURRENCY = "getCurrency",
    GET_CURRENCY_VALUE = "getCurrencyValue",
    GET_CURRENCY_ID = "getCurrencyId",
    GET_ACTIVE_ACCOUNT = "getActiveAccount",
    GET_ACCOUNT_TYPE = "getAccountType",
    GET_MAIN_ACCOUNT_TYPE = "getMainAccountType",
    GET_CASINO_ACCOUNT_TYPE = "getCasinoAccountType",
    IS_INITIALISED = "isInitialized",
    IS_BALANCE_CHANGING = "isBalanceChanging",
    GET_MAIN_BALANCE = "getMainBalance"
}

enum Mutations {
    SET_MAIN_BALANCE = "setBalance",
    SET_MAIN_BONUS = "setBonus",
    SET_CASINO_BALANCE = "setCasinoBalance",
    SET_CASINO_BONUS = "setCasinoBonus",
    SET_CURRENCY = "setCurrency",
    SET_CURRENCY_VALUE = "setCurrencyValue",
    SET_CURRENCY_ID = "setCurrencyId",
    SET_MAIN_ACCOUNT_TYPE = "setMainAccountType",
    SET_CASINO_ACCOUNT_TYPE = "setCasinoAccountType",
    SET_ACTIVE_ACCOUNT = "setActiveAccount",
    CLEAR_BALANCE = "clearBalance",
    SET_BALANCE_CHANGING = "setBalanceChanging"
}

export enum AccountCategory {
    Main, Casino, Other
}

export enum AccountType {
    USER_ACCOUNT_UNKNOWN, USER_ACCOUNT_PRIMARY, USER_ACCOUNT_BONUS
}

enum Actions {

}

export const BalanceModule: Module<BalanceState, RootState> = {
    namespaced: true,
    state: {
        mainBalance: new BigDecimal(0, 2),
        casinoBalance: new BigDecimal(0, 2),
        mainBonusBalance: new BigDecimal(0, 2),
        casinoBonusBalance: new BigDecimal(0, 2),
        mainAccountType: AccountType.USER_ACCOUNT_UNKNOWN,
        casinoAccountType: AccountType.USER_ACCOUNT_UNKNOWN,
        activeAccount: AccountCategory.Main,
        initialized: false,
        currency: "",
        currencyId: 0,
        currencyValue: 0,
        balanceIsChanging: false
    },
    getters: {
        [Getters.GET_BALANCE]: state => getBalance(state),
        [Getters.GET_BONUS]: state => getBonus(state),
        [Getters.GET_ACCOUNT_TYPE]: state => state.activeAccount === AccountCategory.Main ? state.mainAccountType : state.casinoAccountType,
        [Getters.GET_MAIN_ACCOUNT_TYPE]: state => state.mainAccountType,
        [Getters.GET_CASINO_ACCOUNT_TYPE]: state => state.casinoAccountType,
        [Getters.GET_ACTIVE_ACCOUNT]: state => state.activeAccount,
        [Getters.GET_CURRENCY]: state => state.currency,
        [Getters.GET_CURRENCY_VALUE]: state => state.currencyValue,
        [Getters.GET_CURRENCY_ID]: state => state.currencyId,
        [Getters.IS_INITIALISED]: state => state.initialized,
        [Getters.IS_BALANCE_CHANGING]: state => state.balanceIsChanging,
        [Getters.GET_MAIN_BALANCE]: state => state.mainBalance
    },
    mutations: {
        [Mutations.SET_MAIN_BALANCE]: (state: BalanceState, payload: BigDecimal) => {
            state.mainBalance = payload;
            state.initialized = true;
        },
        [Mutations.SET_CASINO_BALANCE]: (state: BalanceState, payload: BigDecimal) => {
            state.casinoBalance = payload;
            state.initialized = true;
        },
        [Mutations.SET_MAIN_BONUS]: (state: BalanceState, payload: BigDecimal) => {
            state.mainBonusBalance = payload;
            state.initialized = true;
        },
        [Mutations.SET_CASINO_BONUS]: (state: BalanceState, payload: BigDecimal) => {
            state.casinoBonusBalance = payload;
            state.initialized = true;
        },
        [Mutations.SET_CURRENCY]: (state: BalanceState, payload: string) => (state.currency = payload),
        [Mutations.SET_CURRENCY_VALUE]: (state: BalanceState, payload: number) => (state.currencyValue = payload),
        [Mutations.SET_CURRENCY_ID]: (state: BalanceState, payload: number) => (state.currencyId = payload),
        [Mutations.SET_MAIN_ACCOUNT_TYPE]: (state: BalanceState, payload: AccountType) => (state.mainAccountType = payload),
        [Mutations.SET_CASINO_ACCOUNT_TYPE]: (state: BalanceState, payload: AccountType) => (state.casinoAccountType = payload),
        [Mutations.SET_ACTIVE_ACCOUNT]: (state: BalanceState, payload: AccountCategory) => (state.activeAccount = payload),
        [Mutations.CLEAR_BALANCE]: clearBalance,
        [Mutations.SET_BALANCE_CHANGING]: (state: BalanceState, payload: boolean) => (state.balanceIsChanging = payload)
    }
};

function getBalance (state: BalanceState) {
    switch (state.activeAccount) {
        case AccountCategory.Main:
            return state.mainBalance;
        case AccountCategory.Casino:
            return state.casinoBalance;
        default:
            return state.mainBalance.add(state.casinoBalance);
    }
}

function getBonus (state: BalanceState) {
    switch (state.activeAccount) {
        case AccountCategory.Main:
            return state.mainBonusBalance;
        case AccountCategory.Casino:
            return state.casinoBonusBalance;
        default:
            return state.mainBonusBalance.add(state.casinoBonusBalance);
    }
}

function clearBalance (state: BalanceState) {
    state.mainBalance = new BigDecimal(0, 2);
    state.casinoBalance = new BigDecimal(0, 2);
    state.mainBonusBalance = new BigDecimal(0, 2);
    state.casinoBonusBalance = new BigDecimal(0, 2);
    state.mainAccountType = AccountType.USER_ACCOUNT_UNKNOWN;
    state.casinoAccountType = AccountType.USER_ACCOUNT_UNKNOWN;
    state.activeAccount = AccountCategory.Main;
    state.initialized = false;
    state.currency = "";
    state.currencyId = 0;
    state.currencyValue = 0;
    state.balanceIsChanging = false;
}

export interface BalanceStore {
    readonly balance: BigDecimal;
    readonly bonus: BigDecimal;
    readonly accountType: AccountType;
    readonly initialized: boolean;
    readonly mainAccountType: AccountType;
    readonly casinoAccountType: AccountType;
    readonly isBalanceChanging: boolean;
    activeAccount: AccountCategory,

    setMainAccountBalance (balance: BigDecimal): void;

    setMainAccountBonus (balance: BigDecimal): void;

    setCasinoAccountBalance (balance: BigDecimal): void;

    setCasinoAccountBonus (balance: BigDecimal): void;

    setCasinoAccountType (accountType: AccountType): void;

    setMainAccountType (accountType: AccountType): void;

    currency: string;
    currencyId: number;
    currencyValue: number;

    clearBalance (): void;

    startBalanceChanging (): void;

    endBalanceChanging (): void;

    getMainBalance (): BigDecimal;
}

class BalanceStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements BalanceStore {
    constructor (store: Store<RootState>) {
        super(store, balanceModule);
    }

    public get initialized () {
        return this.get(Getters.IS_INITIALISED);
    }

    public get accountType () {
        return this.get(Getters.GET_ACCOUNT_TYPE);
    }

    public set activeAccount (value: AccountCategory) {
        this.mutate(Mutations.SET_ACTIVE_ACCOUNT, value);
    }

    public get activeAccount (): AccountCategory {
        return this.get(Getters.GET_ACTIVE_ACCOUNT);
    }

    public setCasinoAccountType (value: AccountType) {
        this.mutate(Mutations.SET_CASINO_ACCOUNT_TYPE, value);
    }

    public setMainAccountType (value: AccountType) {
        this.mutate(Mutations.SET_MAIN_ACCOUNT_TYPE, value);
    }

    public get bonus () {
        return this.get(Getters.GET_BONUS);
    }

    public get balance () {
        return this.get(Getters.GET_BALANCE);
    }

    public get currencyValue () {
        return this.get(Getters.GET_CURRENCY_VALUE);
    }

    public set currencyValue (value: number) {
        this.mutate(Mutations.SET_CURRENCY_VALUE, value);
    }

    public get currencyId () {
        return this.get(Getters.GET_CURRENCY_ID);
    }

    public set currencyId (value: number) {
        this.mutate(Mutations.SET_CURRENCY_ID, value);
    }

    public setMainAccountBalance (balance: BigDecimal) {
        this.mutate(Mutations.SET_MAIN_BALANCE, balance);
    }

    public setMainAccountBonus (balance: BigDecimal) {
        this.mutate(Mutations.SET_MAIN_BONUS, balance);
    }

    public setCasinoAccountBalance (balance: BigDecimal) {
        this.mutate(Mutations.SET_CASINO_BALANCE, balance);
    }

    public setCasinoAccountBonus (balance: BigDecimal) {
        this.mutate(Mutations.SET_CASINO_BONUS, balance);
    }

    public get currency (): string {
        return this.get(Getters.GET_CURRENCY);
    }

    public set currency (value: string) {
        this.mutate(Mutations.SET_CURRENCY, value);
    }

    clearBalance (): void {
        this.mutate(Mutations.CLEAR_BALANCE);
    }

    get casinoAccountType (): AccountType {
        return this.get(Getters.GET_CASINO_ACCOUNT_TYPE);
    }

    get mainAccountType (): AccountType {
        return this.get(Getters.GET_MAIN_ACCOUNT_TYPE);
    }

    get isBalanceChanging (): boolean {
        return this.get(Getters.IS_BALANCE_CHANGING);
    }

    startBalanceChanging () {
        this.mutate(Mutations.SET_BALANCE_CHANGING, true);
    }

    endBalanceChanging () {
        this.mutate(Mutations.SET_BALANCE_CHANGING, false);
    }

    getMainBalance (): BigDecimal {
        return this.get(Getters.GET_MAIN_BALANCE);
    }
}

export const balanceStoreSymbol = Symbol("BalanceStore");

export function provideBalanceStore (rootStore: RootStore): BalanceStore {
    const store = new BalanceStoreImpl(rootStore.store);
    provide(balanceStoreSymbol, store);
    return store;
}

export function provideInjectedBalanceStore (store: BalanceStore) {
    provide(balanceStoreSymbol, store);
}

export function useBalanceStore (): BalanceStore {
    return use(balanceStoreSymbol);
}
