import { markRaw } from "vue";
import {
    binarySearchIndex, deleteFromSortedArray,
    insertIntoSortedArray,
    insertUniqueIntoSortedArray,
    nativeNumberComparator
} from "@sportaq/common/utils/arrays";

export namespace EventViewFilterUtils {
    export class CountryFilter {
        private readonly state = markRaw(new Map<number, number[]>());
        private readonly countryByPartition = markRaw(new Map<number, number>());

        private countries: number[] = [];
        private partitions: number[] = [];

        private forcedPartition: number | undefined;

        addCountryToState (countryId: number) {
            this.state.set(countryId, []);
        }

        addPartitionToState (countryId: number, partitionId: number) {
            let arr = this.state.get(countryId);
            if (!arr) {
                arr = [];
                this.state.set(countryId, arr);
            }
            insertUniqueIntoSortedArray(arr, partitionId, nativeNumberComparator);
            this.countryByPartition.set(partitionId, countryId);
            if (this.forcedPartition === partitionId) {
                insertIntoSortedArray(this.partitions, partitionId, nativeNumberComparator);
                this.forcedPartition = undefined;
            }
        }

        removeCountryFromState (countryId: number) {
            const partitions = this.state.get(countryId);
            if (partitions) {
                for (const p of partitions) {
                    this.countryByPartition.delete(p);
                    deleteFromSortedArray(this.partitions, p, nativeNumberComparator);
                }
            }
            deleteFromSortedArray(this.countries, countryId, nativeNumberComparator);
            this.state.delete(countryId);
        }

        removePartitionFromState (partitionId: number) {
            const countryId = this.countryByPartition.get(partitionId);
            this.countryByPartition.delete(partitionId);
            if (countryId) {
                const partitions = this.state.get(countryId);
                if (partitions) {
                    deleteFromSortedArray(partitions, partitionId, nativeNumberComparator);
                }
            }
            deleteFromSortedArray(this.partitions, partitionId, nativeNumberComparator);
        }

        synchronizeState () {
            this.updateCountriesSelectionState();
        }

        toggleCountry (countryId: number): boolean {
            if (!this.state.has(countryId)) {
                return false;
            }
            if (!this.isCountrySelected(countryId)) {
                this.selectCountry(countryId);
            } else {
                this.unselectCountry(countryId);
            }
            return true;
        }

        togglePartition (partitionId: number): boolean {
            if (!this.countryByPartition.has(partitionId)) {
                return false;
            }
            if (!this.isPartitionSelected(partitionId)) {
                this.selectPartition(partitionId);
            } else {
                this.unselectPartition(partitionId);
            }
            return true;
        }

        empty () {
            this.countries = [];
            this.partitions = [];
        }

        isEmpty (): boolean {
            return this.countries.length === 0 && this.partitions.length === 0;
        }

        setForcedPartition (value: number | undefined) {
            this.forcedPartition = value;
        }

        private selectCountry (countryId: number) {
            insertIntoSortedArray(this.countries, countryId, nativeNumberComparator);
            const partitionIds = this.state.get(countryId);
            if (partitionIds) {
                for (const partitionId of partitionIds) {
                    insertUniqueIntoSortedArray(this.partitions, partitionId, nativeNumberComparator);
                }
            }
        }

        private unselectCountry (countryId: number) {
            if (deleteFromSortedArray(this.countries, countryId, nativeNumberComparator)) {
                const partitionIds = this.state.get(countryId);
                if (partitionIds) {
                    for (const partitionId of partitionIds) {
                        deleteFromSortedArray(this.partitions, partitionId, nativeNumberComparator);
                    }
                }
            }
        }

        private selectPartition (partitionId: number) {
            insertIntoSortedArray(this.partitions, partitionId, nativeNumberComparator);
            const countryId = this.countryByPartition.get(partitionId);
            this.refreshCountrySelection(countryId, partitionId);
        }

        private refreshCountrySelection (countryId: number | undefined, partitionId: number | undefined) {
            if (countryId) {
                const partitionIds = this.state.get(countryId);
                if (partitionIds) {
                    if (partitionIds.every(value => value === partitionId || this.isPartitionSelected(value))) {
                        insertUniqueIntoSortedArray(this.countries, countryId, nativeNumberComparator);
                    } else {
                        deleteFromSortedArray(this.countries, countryId, nativeNumberComparator);
                    }
                }
            }
        }

        private unselectPartition (partitionId: number) {
            if (deleteFromSortedArray(this.partitions, partitionId, nativeNumberComparator)) {
                const countryId = this.countryByPartition.get(partitionId);
                if (countryId && this.isCountrySelected(countryId)) {
                    deleteFromSortedArray(this.countries, countryId, nativeNumberComparator);
                }
            }
        }

        private updateCountriesSelectionState () {
            const allCountries = [...this.countries];
            for (const partitionId of this.partitions) {
                const country = this.countryByPartition.get(partitionId);
                if (country) {
                    insertUniqueIntoSortedArray(allCountries, country, nativeNumberComparator);
                }
            }
            for (const country of allCountries) {
                this.refreshCountrySelection(country, undefined);
            }
        }

        isCountrySelected (countryId: number): boolean {
            return binarySearchIndex(this.countries, countryId, nativeNumberComparator) >= 0;
        }

        isPartitionSelected (partitionId: number): boolean {
            return binarySearchIndex(this.partitions, partitionId, nativeNumberComparator) >= 0;
        }

        filter (countryId: number, partitionId: number): boolean {
            if (!this.forcedPartition && (this.countries.length === 0) && (this.partitions.length === 0)) {
                return true;
            }
            if (this.forcedPartition === partitionId) {
                return true;
            }
            return this.isPartitionSelected(partitionId) || this.isCountrySelected(countryId);
        }

        clear () {
            if (this.state.size > 0) {
                this.forcedPartition = undefined;
            }
            this.state.clear();
            this.countryByPartition.clear();

            this.countries = [];
            this.partitions = [];
        }
    }
}
