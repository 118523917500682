import { PropType, SetupContext } from "vue";
import { CLOSE_EVENT, EVENT_SUCCESS } from "@sportaq/vue/components/web/dialogs/events";
import { useAmountInputDialogCommon } from "@sportaq/vue/views/web/deposit/amount-inpot-dialog-common";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";

interface Props {
    depositMethod: PaymentSystems,
    currencyValues: Map<number, number>
}

export default {
    name: "AmountInputDialog",
    emits: [CLOSE_EVENT, EVENT_SUCCESS],
    props: {
        depositMethod: {
            type: String as PropType<PaymentSystems>,
            required: true
        },
        currencyValues: {
            type: Object as PropType<Map<number, number>>,
            required: true
        }
    },
    setup (props: Props, context: SetupContext) {
        return useAmountInputDialogCommon(context, props.depositMethod, props.currencyValues);
    }
};
