<template>
    <div class="main-section main-section-sports" v-if="periods.length > 0"
         :data-cy="`home-view-sport-widget-${sportType}`">
        <div class="main-section-head">
            <img :src="`/img/sports/${sport.image}.png`" :alt="`${sport.name}`"/>
            <div class="title" :data-cy="`home-view-sport-widget-title-${sportType}`">
                {{ sport.name }}
            </div>
            <router-link :to="{path: `/sport/${linkNameBySportType}`}" class="button button-secondary"
                         :data-cy="`home-view-sport-widget-link-${sportType}`">
                {{ $t("mobile.homeView.sport.allMatches") }}
            </router-link>
        </div>
        <div class="horizontal-scroll">
            <Flicking ref="flicker"
                      :options="{ align: 'prev', moveType: [MOVE_TYPE.FREE_SCROLL, { stopAtEdge: true }], circular: false, bound: true, bounce: 15}"
                      :plugins="plugins">
                <compact-scoreboard-event-line v-for="period of periods" :extra-props="extraProps" :source="period" :is-mobile="isMobile"
                                               :key="period.id"/>
                <template #viewport>
                    <span class="flicking-arrow-prev"></span>
                    <span class="flicking-arrow-next"></span>
                </template>
            </Flicking>
        </div>
    </div>
</template>

<script src="./home-page-sport-widget-component.ts"/>
