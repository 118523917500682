import { CountDictionaryItem, DictionaryItem } from "@sportaq/common/types/types";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { ESportType } from "@sportaq/common/enums/sport-type";

export interface CountBySportType {
    sportType: ESportType;
    count: number;
}

export class CountryCounter {
    readonly partitions: CountDictionaryItem[] = [];
    private _count: number = 0;

    constructor (readonly id: number) {
    }

    add (event: BettingEvent) {
        this._count++;
        return this.addPartition(event.partition);
    }

    remove (event: BettingEvent): number | undefined {
        this._count--;
        return this.removePartition(event.partition);
    }

    get count () {
        return this._count;
    }

    private addPartition (item: DictionaryItem) {
        const index = this.partitions.findIndex(value => value.id === item.id);
        if (index < 0) {
            this.partitions.push({
                id: item.id,
                name: item.name,
                count: 1
            });
            this.partitions.sort((a, b) => a.name.localeCompare(b.name));
        } else {
            const value = this.partitions[index];
            value.count = value.count + 1;
        }
    }

    private removePartition (item: DictionaryItem): number | undefined {
        const index = this.partitions.findIndex(value => value.id === item.id);
        if (index >= 0) {
            const value = this.partitions[index];
            value.count = value.count - 1;
            if (value.count <= 0) {
                const items = this.partitions.splice(index, 1);
                if (items.length > 0) {
                    return items[0].id;
                }
            }
        }
        return undefined;
    }
}
