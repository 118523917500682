<template>
    <div class="item" :class="{hover: backlight, 'aviator': game.id === 'AVI_aviator'}" :data-cy="`game-${game.id}`" @click="wholeItemClick">
        <div class="in">
            <div class="preloader virtual">
                <div class="preloader-el"></div>
            </div>
            <img :src="game.image" alt="" @error="imageLoadError" draggable="false"/>
            <div class="title">
                <div class="name">{{ game.title }}</div>
                <div class="star" :class="{active: isFavourite}" @click.stop="toggleFavourite"
                     data-cy="favourite-btn"></div>
            </div>
        </div>
        <div class="actions" v-if="showDemoSelector">
            <a href="" class="btn" data-cy="start-game-btn"
               @click.prevent.stop="startGame">{{ $t("common.games.buttons.play") }}</a>
            <a href="" class="btn btn-additional" data-cy="start-demo-btn"
               @click.prevent.stop="startDemo">{{ $t("common.games.buttons.demo") }}</a>
        </div>
    </div>
</template>

<script src="./game-widget-component.ts"/>
