<template>
    <div class="navigation">
        <item-slider :items="sportTypeItems" css-class="navigation-overlay" :auto-select="autoSelect"
                            @select="sportTypeSelected"
                            v-slot="slotProps" testSelector="sport-type-filter" :selectedIndex="selectedIndex">
            <img :src="require(`@assets/img/mobile/${imageThemePath}/sport-menu/${slotProps.item.image}.svg`)" :alt="`${ slotProps.item.name }`"/>
            <div class="text">{{ slotProps.item.name }}</div>
            <div class="count" data-cy="count">{{ slotProps.item.count }}</div>
        </item-slider>
    </div>
</template>

<script src="./mobile-sport-type-filter-component.ts"/>
