import { RootState } from "@sportaq/vuex/index";
import { CasinoGamesVuex } from "@sportaq/vuex/modules/games/internal/casino-games-vuex";
import { ActionContext } from "vuex";
import { nextTick } from "vue";

export namespace CasinoGamesVuexActions {
    export async function actionUpdateCasinoGames (context: ActionContext<CasinoGamesVuex.CasinoGamesStoreState, RootState>, payload: unknown): Promise<void> {
        await nextTick(() => {
            context.commit(CasinoGamesVuex.Mutations.SYNC_GAMES_VIEW_WITH_STORE, payload);
        });
    }
}
