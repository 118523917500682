export enum EQuotation {
    GAP_QUOTATION = -2,
    LOCKED_QUOTATION = -1,

    MATCH_WITH_OT_WIN_1 = 7,
    MATCH_WITH_OT_DRAW = 8,
    MATCH_WITH_OT_WIN_2 = 9,
    MATCH_WIN_1 = 171,
    MATCH_DRAW = 172,
    MATCH_WIN_2 = 173,
    MATCH_WITH_OT_WIN_1_DRAW = 10,
    MATCH_WITH_OT_WIN_1_WIN_2 = 11,
    MATCH_WITH_OT_WIN_2_DRAW = 12,
    MATCH_WIN_1_DRAW = 178,
    MATCH_WIN_1_WIN_2 = 179,
    MATCH_WIN_2_DRAW = 180,
    MATCH_WITH_OT_HANDICAP_1 = 3,
    MATCH_WITH_OT_HANDICAP_2 = 4,
    MATCH_HANDICAP_1 = 167,
    MATCH_HANDICAP_2 = 168,
    MATCH_WITH_OT_TOTAL_OVER = 6,
    MATCH_WITH_OT_TOTAL_UNDER = 5,
    MATCH_WITH_OT_TOTAL_EVEN = 701,
    MATCH_WITH_OT_TOTAL_ODD = 702,
    MATCH_TOTAL_OVER = 170,
    MATCH_TOTAL_UNDER = 169,
    MATCH_TOTAL_EVEN = 335,
    MATCH_TOTAL_ODD = 336,
    MATCH_WITH_OT_INDIVIDUAL_1_TOTAL_OVER = 28,
    MATCH_WITH_OT_INDIVIDUAL_1_TOTAL_UNDER = 27,
    MATCH_WITH_OT_INDIVIDUAL_2_TOTAL_OVER = 30,
    MATCH_WITH_OT_INDIVIDUAL_2_TOTAL_UNDER = 29,
    MATCH_INDIVIDUAL_1_TOTAL_OVER = 175,
    MATCH_INDIVIDUAL_1_TOTAL_UNDER = 174,
    MATCH_INDIVIDUAL_2_TOTAL_OVER = 177,
    MATCH_INDIVIDUAL_2_TOTAL_UNDER = 176,
    MATCH_WITH_OT_QUALIFIED_1 = 23,
    MATCH_WITH_OTQUALIFIED_2 = 24,

    /// REGION_FOOT_BALL_TIME
    F_TIME_1_WIN_1 = 280,
    F_TIME_1_DRAW = 281,
    F_TIME_1_WIN_2 = 282,
    F_TIME_1_WIN_1_DRAW = 703,
    F_TIME_1_WIN_1_WIN_2 = 704,
    F_TIME_1_WIN_2_DRAW = 705,
    F_TIME_1_HANDICAP_1 = 276,
    F_TIME_1_HANDICAP_2 = 277,
    F_TIME_1_TOTAL_OVER = 71,
    F_TIME_1_TOTAL_UNDER = 70,
    F_TIME_2_WIN_1 = 287,
    F_TIME_2_DRAW = 288,
    F_TIME_2_WIN_2 = 289,
    F_TIME_2_WIN_1_DRAW = 706,
    F_TIME_2_WIN_1_WIN_2 = 707,
    F_TIME_2_WIN_2_DRAW = 708,
    F_TIME_2_HANDICAP_1 = 283,
    F_TIME_2_HANDICAP_2 = 284,
    F_TIME_2_TOTAL_OVER = 73,
    F_TIME_2_TOTAL_UNDER = 72,
    // OT_PART
    F_TOTAL_UNDER_OT = 1104,
    F_TOTAL_OVER_OT = 1105,
    F_HANDICAP_1_OT = 1121,
    F_HANDICAP_2_OT = 1122,
    F_WIN_1_DRAW_2_OT = 1087,
    F_WIN_1_WIN_2_OT = 1088,
    F_WIN_2_DRAW_1_OT = 1089,
    // FOOTBALL_SCORE
    F_MATH_SCORE_00 = 37,
    F_MATH_SCORE_01 = 38,
    F_MATH_SCORE_OTHER = 52,

    // FOOTBALL_MATH\/TIME
    F_MATH_TIME_1_1 = 53,
    F_MATH_TIME_1_X = 54,
    F_MATH_TIME_X_X = 61,

    // COMPARISON_HALVES
    F_TIME_1_TOTAL_OVER_F_TIME_2_TOTAL = 25,
    F_TIME_1_TOTAL_UNDER_F_TIME_2_TOTAL = 26,
    F_TIME_1_TOTAL_EQUALS_F_TIME_2_TOTAL = 109,

    /// ENDREGION
    /// REGION_HAND_BALL_TIME
    H_TIME_1_WIN_1 = 401,
    H_TIME_1_DRAW = 402,
    H_TIME_1_WIN_2 = 403,
    H_TIME_1_WIN_1_DRAW = 423,
    H_TIME_1_WIN_1_WIN_2 = 424,
    H_TIME_1_WIN_2_DRAW = 425,
    H_TIME_1_HANDICAP_1 = 404,
    H_TIME_1_HANDICAP_2 = 405,
    H_TIME_1_TOTAL_OVER = 412,
    H_TIME_1_TOTAL_UNDER = 411,
    H_TIME_1_INDIVIDUAL_1_TOTAL_OVER = 416,
    H_TIME_1_INDIVIDUAL_1_TOTAL_UNDER = 415,
    H_TIME_1_INDIVIDUAL_2_TOTAL_OVER = 420,
    H_TIME_1_INDIVIDUAL_2_TOTAL_UNDER = 419,
    H_TIME_2_WIN_1 = 406,
    H_TIME_2_DRAW = 407,
    H_TIME_2_WIN_2 = 408,
    H_TIME_2_WIN_1_DRAW = 426,
    H_TIME_2_WIN_1_WIN_2 = 427,
    H_TIME_2_WIN_2_DRAW = 428,
    H_TIME_2_HANDICAP_1 = 409,
    H_TIME_2_HANDICAP_2 = 410,
    H_TIME_2_TOTAL_OVER = 414,
    H_TIME_2_TOTAL_UNDER = 413,
    H_TIME_2_INDIVIDUAL_1_TOTAL_OVER = 418,
    H_TIME_2_INDIVIDUAL_1_TOTAL_UNDER = 417,
    H_TIME_2_INDIVIDUAL_2_TOTAL_OVER = 422,
    H_TIME_2_INDIVIDUAL_2_TOTAL_UNDER = 421,
    //

    /// ENDREGION

    TWIN_SCORE_20 = 13,
    TWIN_SCORE_21 = 14,
    TWIN_SCORE_02 = 15,
    TWIN_SCORE_12 = 16,

    /// REGION_TENNIS_SET
    T_SET_1_WIN_1 = 290,
    T_SET_1_DRAW = 291,
    T_SET_1_WIN_2 = 292,
    T_SET_1_HANDICAP_1 = 293,
    T_SET_1_HANDICAP_2 = 294,
    T_SET_1_TOTAL_OVER = 296,
    T_SET_1_TOTAL_UNDER = 295,
    T_SET_2_WIN_1 = 656,
    T_SET_2_WIN_2 = 657,
    T_SET_2_HANDICAP_1 = 664,
    T_SET_2_HANDICAP_2 = 665,
    T_SET_2_TOTAL_OVER = 673,
    T_SET_2_TOTAL_UNDER = 672,
    T_SET_3_WIN_1 = 658,
    T_SET_3_WIN_2 = 659,
    T_SET_3_HANDICAP_1 = 666,
    T_SET_3_HANDICAP_2 = 667,
    T_SET_3_TOTAL_OVER = 675,
    T_SET_3_TOTAL_UNDER = 674,
    T_SET_4_WIN_1 = 660,
    T_SET_4_WIN_2 = 661,
    T_SET_4_HANDICAP_1 = 668,
    T_SET_4_HANDICAP_2 = 669,
    T_SET_4_TOTAL_OVER = 677,
    T_SET_4_TOTAL_UNDER = 676,
    T_SET_5_WIN_1 = 662,
    T_SET_5_WIN_2 = 663,
    T_SET_5_HANDICAP_1 = 670,
    T_SET_5_HANDICAP_2 = 671,
    T_SET_5_TOTAL_OVER = 679,
    T_SET_5_TOTAL_UNDER = 678,

    // COMPARISON_HALVES_FOR_TENNIS
    F_TIME_1_TOTAL_OVER_F_TIME_2_TOTAL_TENNIS = 970,
    F_TIME_1_TOTAL_UNDER_F_TIME_2_TOTAL_TENNIS = 971,
    F_TIME_1_TOTAL_EQUALS_F_TIME_2_TOTAL_TENNIS = 972,

    /// REGION_GAME_WINS
    T_SET_1_GAME_WIN_1 = 709,
    T_SET_1_GAME_WIN_2 = 710,
    T_SET_2_GAME_WIN_1 = 711,
    T_SET_2_GAME_WIN_2 = 712,
    T_SET_3_GAME_WIN_1 = 713,
    T_SET_3_GAME_WIN_2 = 714,
    T_SET_4_GAME_WIN_1 = 715,
    T_SET_4_GAME_WIN_2 = 716,
    T_SET_5_GAME_WIN_1 = 717,
    T_SET_5_GAME_WIN_2 = 718,
    /// ENDREGION

    /// TENIS_WIN_IN_GAME_X_OR_Y
    T_SET_1_WIN_1_IN_GAME = 1135,
    T_SET_1_WIN_2_IN_GAME = 1136,
    T_SET_1_DRAW_IN_GAME = 1145,
    T_SET_2_WIN_1_IN_GAME = 1137,
    T_SET_2_WIN_2_IN_GAME = 1138,
    T_SET_2_DRAW_IN_GAME = 1146,
    T_SET_3_WIN_1_IN_GAME = 1139,
    T_SET_3_WIN_2_IN_GAME = 1140,
    T_SET_3_DRAW_IN_GAME = 1147,
    T_SET_4_WIN_1_IN_GAME = 1141,
    T_SET_4_WIN_2_IN_GAME = 1142,
    T_SET_4_DRAW_IN_GAME = 1148,
    T_SET_5_WIN_1_IN_GAME = 1143,
    T_SET_5_WIN_2_IN_GAME = 1144,
    T_SET_5_DRAW_IN_GAME = 1149,
    /// ENDREGION

    /// REGION_VOLLEYBALL_SET
    V_SET_1_WIN_1 = 431,
    V_SET_1_WIN_2 = 433,
    V_SET_1_HANDICAP_1 = 438,
    V_SET_1_HANDICAP_2 = 439,
    V_SET_1_TOTAL_OVER = 445,
    V_SET_1_TOTAL_UNDER = 444,
    V_SET_2_WIN_1 = 434,
    V_SET_2_WIN_2 = 435,
    V_SET_2_HANDICAP_1 = 440,
    V_SET_2_HANDICAP_2 = 441,
    V_SET_2_TOTAL_OVER = 447,
    V_SET_2_TOTAL_UNDER = 446,
    V_SET_3_WIN_1 = 436,
    V_SET_3_WIN_2 = 437,
    V_SET_3_HANDICAP_1 = 442,
    V_SET_3_HANDICAP_2 = 443,
    V_SET_3_TOTAL_OVER = 449,
    V_SET_3_TOTAL_UNDER = 448,
    V_SET_4_WIN_1 = 644,
    V_SET_4_WIN_2 = 645,
    V_SET_4_HANDICAP_1 = 648,
    V_SET_4_HANDICAP_2 = 649,
    V_SET_4_TOTAL_OVER = 653,
    V_SET_4_TOTAL_UNDER = 652,
    V_SET_5_WIN_1 = 646,
    V_SET_5_WIN_2 = 647,
    V_SET_5_HANDICAP_1 = 650,
    V_SET_5_HANDICAP_2 = 651,
    V_SET_5_TOTAL_OVER = 655,
    V_SET_5_TOTAL_UNDER = 654,

    V_SCORE_AFTER_2_SETS_2_0 = 890,
    V_SCORE_AFTER_2_SETS_1_1 = 891,
    V_SCORE_AFTER_2_SETS_0_2 = 892,

    V_ACCURATE_GAMES_NUMBER = 889,

    /// REGION_VOLLEYBALL_SET
    V_SET_1_GET_POINT_1 = 1047,
    V_SET_1_GET_POINT_2 = 1048,
    V_SET_2_GET_POINT_1 = 1049,
    V_SET_2_GET_POINT_2 = 1050,
    V_SET_3_GET_POINT_1 = 1051,
    V_SET_3_GET_POINT_2 = 1052,
    V_SET_4_GET_POINT_1 = 1053,
    V_SET_4_GET_POINT_2 = 1054,
    V_SET_5_GET_POINT_1 = 1055,
    V_SET_5_GET_POINT_2 = 1056,
    V_SET_1_FIRST_GET_POINT_1 = 1057,
    V_SET_1_FIRST_GET_POINT_2 = 1058,
    V_SET_2_FIRST_GET_POINT_1 = 1059,
    V_SET_2_FIRST_GET_POINT_2 = 1060,
    V_SET_3_FIRST_GET_POINT_1 = 1061,
    V_SET_3_FIRST_GET_POINT_2 = 1062,
    V_SET_4_FIRST_GET_POINT_1 = 1063,
    V_SET_4_FIRST_GET_POINT_2 = 1064,
    V_SET_5_FIRST_GET_POINT_1 = 1065,
    V_SET_5_FIRST_GET_POINT_2 = 1066,
    /// ENDREGION

    /// REGION_BASKETBALL_QUARTER
    QUARTER_1_EVEN = 1014,
    QUARTER_1_ODD = 1015,
    QUARTER_2_EVEN = 1016,
    QUARTER_2_ODD = 1017,
    QUARTER_3_EVEN = 1018,
    QUARTER_3_ODD = 1019,
    QUARTER_4_EVEN = 1020,
    QUARTER_4_ODD = 1021,

    QUARTER_1_WIN_1 = 680,
    QUARTER_1_DRAW = 681,
    QUARTER_1_WIN_2 = 682,
    QUARTER_2_WIN_1 = 683,
    QUARTER_2_DRAW = 684,
    QUARTER_2_WIN_2 = 685,
    QUARTER_3_WIN_1 = 686,
    QUARTER_3_DRAW = 687,
    QUARTER_3_WIN_2 = 688,
    QUARTER_4_WIN_1 = 689,
    QUARTER_4_DRAW = 690,
    QUARTER_4_WIN_2 = 691,
    QUARTER_1_HANDICAP_1 = 254,
    QUARTER_1_HANDICAP_2 = 255,
    QUARTER_2_HANDICAP_1 = 258,
    QUARTER_2_HANDICAP_2 = 259,
    QUARTER_3_HANDICAP_1 = 262,
    QUARTER_3_HANDICAP_2 = 263,
    QUARTER_4_HANDICAP_1 = 266,
    QUARTER_4_HANDICAP_2 = 267,
    QUARTER_1_TOTAL_OVER = 257,
    QUARTER_1_TOTAL_UNDER = 256,
    QUARTER_2_TOTAL_OVER = 261,
    QUARTER_2_TOTAL_UNDER = 260,
    QUARTER_3_TOTAL_OVER = 265,
    QUARTER_3_TOTAL_UNDER = 264,
    QUARTER_4_TOTAL_OVER = 269,
    QUARTER_4_TOTAL_UNDER = 268,
    // Q_1
    QUARTER_1_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 874,
    QUARTER_1_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 873,
    QUARTER_1_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 876,
    QUARTER_1_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 875,
    // Q_2
    QUARTER_2_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 878,
    QUARTER_2_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 877,
    QUARTER_2_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 880,
    QUARTER_2_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 879,
    // Q_3
    QUARTER_3_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 882,
    QUARTER_3_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 881,
    QUARTER_3_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 884,
    QUARTER_3_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 883,
    // Q_4
    QUARTER_4_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 886,
    QUARTER_4_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 885,
    QUARTER_4_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 888,
    QUARTER_4_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 887,
    // FIRST_PART
    BK_FIRST_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 866,
    BK_FIRST_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 865,
    BK_FIRST_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 868,
    BK_FIRST_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 867,
    // SECOND_PART
    BK_SECOND_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_OVER = 870,
    BK_SECOND_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER = 869,
    BK_SECOND_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_OVER = 872,
    BK_SECOND_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER = 871,

    // HIGHEST_SCORING_QUARTER
    BK_MOST_SCORING_QUARTER_TOTAL_OVER = 894,
    BK_MOST_SCORING_QUARTER_TOTAL_UNDER = 893,
    BK_LOWEST_SCORING_QUARTER_TOTAL_OVER = 896,
    BK_LOWEST_SCORING_QUARTER_TOTAL_UNDER = 895,

    BK_FIRST_HALF_EVEN = 1012,
    BK_FIRST_HALF_ODD = 1013,
    BK_FIRST_HALF_WIN_1 = 827,
    BK_FIRST_HALF_DRAW = 828,
    BK_FIRST_HALF_WIN_2 = 829,
    BK_FIRST_HALF_HANDICAP_1 = 833,
    BK_FIRST_HALF_HANDICAP_2 = 834,
    BK_FIRST_HALF_TOTAL_UNDER = 837,
    BK_FIRST_HALF_TOTAL_OVER = 838,
    BK_SECOND_HALF_WIN_1 = 830,
    BK_SECOND_HALF_DRAW = 831,
    BK_SECOND_HALF_WIN_2 = 832,
    BK_SECOND_HALF_HANDICAP_1 = 835,
    BK_SECOND_HALF_HANDICAP_2 = 836,
    BK_SECOND_HALF_TOTAL_UNDER = 839,
    BK_SECOND_HALF_TOTAL_OVER = 840,
    /// ENDREGION (HOCKEY)

    /// REGION_PERIOD
    PERIOD_1_WIN_1 = 376,
    PERIOD_1_DRAW = 377,
    PERIOD_1_WIN_2 = 378,
    PERIOD_1_WIN_1_DRAW = 692,
    PERIOD_1_WIN_1_WIN_2 = 693,
    PERIOD_1_WIN_2_DRAW = 694,
    PERIOD_1_HANDICAP_1 = 370,
    PERIOD_1_HANDICAP_2 = 371,
    PERIOD_1_TOTAL_OVER = 386,
    PERIOD_1_TOTAL_UNDER = 385,
    PERIOD_2_WIN_1 = 379,
    PERIOD_2_DRAW = 380,
    PERIOD_2_WIN_2 = 381,
    PERIOD_2_WIN_1_DRAW = 695,
    PERIOD_2_WIN_1_WIN_2 = 696,
    PERIOD_2_WIN_2_DRAW = 697,
    PERIOD_2_HANDICAP_1 = 372,
    PERIOD_2_HANDICAP_2 = 373,
    PERIOD_2_TOTAL_OVER = 388,
    PERIOD_2_TOTAL_UNDER = 387,
    PERIOD_3_WIN_1 = 382,
    PERIOD_3_DRAW = 383,
    PERIOD_3_WIN_2 = 384,
    PERIOD_3_WIN_1_DRAW = 698,
    PERIOD_3_WIN_1_WIN_2 = 699,
    PERIOD_3_WIN_2_DRAW = 700,
    PERIOD_3_HANDICAP_1 = 374,
    PERIOD_3_HANDICAP_2 = 375,
    PERIOD_3_TOTAL_OVER = 390,
    PERIOD_3_TOTAL_UNDER = 389,
    PERIOD_4_TOTAL_OVER = 848,
    PERIOD_4_TOTAL_UNDER = 847,
    PERIOD_4_HANDICAP_1 = 849,
    PERIOD_4_HANDICAP_2 = 850,

    HK_WIN_IN_MATCH_TEAM_1 = 851,
    HK_WIN_IN_MATCH_TEAM_2 = 852,

    HK_PENALTY_MINS_TOTAL_OVER = 1002,
    HK_PENALTY_MINS_TOTAL_UNDER = 1001,
    // INDIVIDUAL
    HK_TEAM_1_PENALTY_MINS_INDIVIDUAL_TOTAL_OVER = 1004,
    HK_TEAM_1_PENALTY_MINS_INDIVIDUAL_TOTAL_UNDER = 1003,
    HK_TEAM_2_PENALTY_MINS_INDIVIDUAL_TOTAL_OVER = 1006,
    HK_TEAM_2_PENALTY_MINS_INDIVIDUAL_TOTAL_UNDER = 1005,

    HK_SHOTS_TOTAL_OVER = 365,
    HK_SHOTS_TOTAL_UNDER = 364,
    // INDIVIDUAL
    HK_TEAM_1_SHOTS_INDIVIDUAL_TOTAL_OVER = 367,
    HK_TEAM_1_SHOTS_INDIVIDUAL_TOTAL_UNDER = 366,
    HK_TEAM_2_SHOTS_INDIVIDUAL_TOTAL_OVER = 369,
    HK_TEAM_2_SHOTS_INDIVIDUAL_TOTAL_UNDER = 368,
    /// ENDREGION

    /// REGION_BASEBALL
    // КОЛИЧЕСТВО_ХИТОВ (СУММАРНОЕ_КОЛИЧЕСТВО_ПОЙМАНЫХ_МЯЧЕЙ_КОМАНДАМИ )
    MATCH_HITS_TOTAL_UNDER = 825,
    MATCH_HITS_TOTAL_OVER = 826,
    // ПЕРВАЯ_ПОЛОВИНА_МАТЧА (1_5_ИННИНГ)
    BS_FIRST_HALF_WIN_1 = 759,
    BS_FIRST_HALF_DRAW = 760,
    BS_FIRST_HALF_WIN_2 = 761,
    BS_FIRST_HALF_HANDICAP_1 = 472,
    BS_FIRST_HALF_HANDICAP_2 = 473,
    BS_FIRST_HALF_TOTAL_UNDER = 474,
    BS_FIRST_HALF_TOTAL_OVER = 475,
    // ИННИНГИ
    BS_INNING_1_WIN_1 = 762,
    BS_INNING_1_DRAW = 763,
    BS_INNING_1_WIN_2 = 764,
    BS_INNING_1_HANDICAP_1 = 789,
    BS_INNING_1_HANDICAP_2 = 790,
    BS_INNING_1_TOTAL_UNDER = 807,
    BS_INNING_1_TOTAL_OVER = 808,
    BS_INNING_2_WIN_1 = 765,
    BS_INNING_2_DRAW = 766,
    BS_INNING_2_WIN_2 = 767,
    BS_INNING_2_HANDICAP_1 = 791,
    BS_INNING_2_HANDICAP_2 = 792,
    BS_INNING_2_TOTAL_UNDER = 809,
    BS_INNING_2_TOTAL_OVER = 810,
    BS_INNING_3_WIN_1 = 768,
    BS_INNING_3_DRAW = 769,
    BS_INNING_3_WIN_2 = 770,
    BS_INNING_3_HANDICAP_1 = 793,
    BS_INNING_3_HANDICAP_2 = 794,
    BS_INNING_3_TOTAL_UNDER = 811,
    BS_INNING_3_TOTAL_OVER = 812,
    BS_INNING_4_WIN_1 = 771,
    BS_INNING_4_DRAW = 772,
    BS_INNING_4_WIN_2 = 773,
    BS_INNING_4_HANDICAP_1 = 795,
    BS_INNING_4_HANDICAP_2 = 796,
    BS_INNING_4_TOTAL_UNDER = 813,
    BS_INNING_4_TOTAL_OVER = 814,
    BS_INNING_5_WIN_1 = 774,
    BS_INNING_5_DRAW = 775,
    BS_INNING_5_WIN_2 = 776,
    BS_INNING_5_HANDICAP_1 = 797,
    BS_INNING_5_HANDICAP_2 = 798,
    BS_INNING_5_TOTAL_UNDER = 815,
    BS_INNING_5_TOTAL_OVER = 816,
    BS_INNING_6_WIN_1 = 777,
    BS_INNING_6_DRAW = 778,
    BS_INNING_6_WIN_2 = 779,
    BS_INNING_6_HANDICAP_1 = 799,
    BS_INNING_6_HANDICAP_2 = 800,
    BS_INNING_6_TOTAL_UNDER = 817,
    BS_INNING_6_TOTAL_OVER = 818,
    BS_INNING_7_WIN_1 = 780,
    BS_INNING_7_DRAW = 781,
    BS_INNING_7_WIN_2 = 782,
    BS_INNING_7_HANDICAP_1 = 801,
    BS_INNING_7_HANDICAP_2 = 802,
    BS_INNING_7_TOTAL_UNDER = 819,
    BS_INNING_7_TOTAL_OVER = 820,
    BS_INNING_8_WIN_1 = 783,
    BS_INNING_8_DRAW = 784,
    BS_INNING_8_WIN_2 = 785,
    BS_INNING_8_HANDICAP_1 = 803,
    BS_INNING_8_HANDICAP_2 = 804,
    BS_INNING_8_TOTAL_UNDER = 821,
    BS_INNING_8_TOTAL_OVER = 822,
    BS_INNING_9_WIN_1 = 786,
    BS_INNING_9_DRAW = 787,
    BS_INNING_9_WIN_2 = 788,
    BS_INNING_9_HANDICAP_1 = 805,
    BS_INNING_9_HANDICAP_2 = 806,
    BS_INNING_9_TOTAL_UNDER = 823,
    BS_INNING_9_TOTAL_OVER = 824,

    BS_EXTRA_INNING_YES = 962,
    BS_EXTRA_INNING_NO = 963,

    /// ENDREGION

    /// REGION_SOCCER_SCORE
    MATCH_F_SCORE_0_0 = 37,
    MATCH_F_SCORE_1_1 = 38,
    MATCH_F_SCORE_2_2 = 39,
    MATCH_F_SCORE_1_0 = 40,
    MATCH_F_SCORE_2_0 = 41,
    MATCH_F_SCORE_3_0 = 42,
    MATCH_F_SCORE_0_1 = 43,
    MATCH_F_SCORE_0_2 = 44,
    MATCH_F_SCORE_0_3 = 45,
    MATCH_F_SCORE_2_1 = 46,
    MATCH_F_SCORE_3_1 = 47,
    MATCH_F_SCORE_3_2 = 48,
    MATCH_F_SCORE_1_2 = 49,
    MATCH_F_SCORE_1_3 = 50,
    MATCH_F_SCORE_2_3 = 51,
    MATCH_F_SCORE_3_3 = 1231,
    MATCH_F_SCORE_4_1 = 1232,
    MATCH_F_SCORE_4_2 = 1233,
    MATCH_F_SCORE_4_3 = 1234,
    MATCH_F_SCORE_4_4 = 1235,
    MATCH_F_SCORE_1_4 = 1236,
    MATCH_F_SCORE_2_4 = 1237,
    MATCH_F_SCORE_3_4 = 1238,
    MATCH_F_SCORE_5_1 = 1239,
    MATCH_F_SCORE_5_2 = 1240,
    MATCH_F_SCORE_5_3 = 1241,
    MATCH_F_SCORE_5_4 = 1242,
    MATCH_F_SCORE_5_5 = 1243,
    MATCH_F_SCORE_1_5 = 1244,
    MATCH_F_SCORE_2_5 = 1245,
    MATCH_F_SCORE_3_5 = 1246,
    MATCH_F_SCORE_4_5 = 1247,
    MATCH_F_SCORE_0_4 = 1248,
    MATCH_F_SCORE_0_5 = 1249,
    MATCH_F_SCORE_4_0 = 1250,
    MATCH_F_SCORE_5_0 = 1251,
    MATCH_F_SCORE_0_6 = 1252,
    MATCH_F_SCORE_1_6 = 1253,
    MATCH_F_SCORE_2_6 = 1254,
    MATCH_F_SCORE_3_6 = 1255,
    MATCH_F_SCORE_4_6 = 1256,
    MATCH_F_SCORE_5_6 = 1257,
    MATCH_F_SCORE_6_6 = 1258,
    MATCH_F_SCORE_0_7 = 1259,
    MATCH_F_SCORE_1_7 = 1260,
    MATCH_F_SCORE_2_7 = 1261,
    MATCH_F_SCORE_3_7 = 1262,
    MATCH_F_SCORE_4_7 = 1263,
    MATCH_F_SCORE_5_7 = 1264,
    MATCH_F_SCORE_6_7 = 1265,
    MATCH_F_SCORE_7_7 = 1266,
    MATCH_F_SCORE_0_8 = 1267,
    MATCH_F_SCORE_1_8 = 1268,
    MATCH_F_SCORE_2_8 = 1269,
    MATCH_F_SCORE_3_8 = 1270,
    MATCH_F_SCORE_4_8 = 1271,
    MATCH_F_SCORE_5_8 = 1272,
    MATCH_F_SCORE_6_8 = 1273,
    MATCH_F_SCORE_7_8 = 1274,
    MATCH_F_SCORE_8_8 = 1275,
    MATCH_F_SCORE_0_9 = 1276,
    MATCH_F_SCORE_1_9 = 1278,
    MATCH_F_SCORE_2_9 = 1279,
    MATCH_F_SCORE_3_9 = 1280,
    MATCH_F_SCORE_4_9 = 1281,
    MATCH_F_SCORE_5_9 = 1282,
    MATCH_F_SCORE_6_9 = 1283,
    MATCH_F_SCORE_7_9 = 1284,
    MATCH_F_SCORE_8_9 = 1285,
    MATCH_F_SCORE_9_9 = 1286,
    MATCH_F_SCORE_0_10 = 1287,
    MATCH_F_SCORE_1_10 = 1288,
    MATCH_F_SCORE_2_10 = 1289,
    MATCH_F_SCORE_3_10 = 1290,
    MATCH_F_SCORE_4_10 = 1291,
    MATCH_F_SCORE_5_10 = 1292,
    MATCH_F_SCORE_6_10 = 1293,
    MATCH_F_SCORE_7_10 = 1294,
    MATCH_F_SCORE_8_10 = 1295,
    MATCH_F_SCORE_9_10 = 1296,
    MATCH_F_SCORE_10_10 = 1297,
    MATCH_F_SCORE_6_0 = 1298,
    MATCH_F_SCORE_6_1 = 1299,
    MATCH_F_SCORE_6_2 = 1400,
    MATCH_F_SCORE_6_3 = 1401,
    MATCH_F_SCORE_6_4 = 1402,
    MATCH_F_SCORE_6_5 = 1403,
    MATCH_F_SCORE_7_0 = 1404,
    MATCH_F_SCORE_7_1 = 1405,
    MATCH_F_SCORE_7_2 = 1406,
    MATCH_F_SCORE_7_3 = 1407,
    MATCH_F_SCORE_7_4 = 1408,
    MATCH_F_SCORE_7_5 = 1409,
    MATCH_F_SCORE_7_6 = 1410,
    MATCH_F_SCORE_8_0 = 1411,
    MATCH_F_SCORE_8_1 = 1412,
    MATCH_F_SCORE_8_2 = 1413,
    MATCH_F_SCORE_8_3 = 1414,
    MATCH_F_SCORE_8_4 = 1415,
    MATCH_F_SCORE_8_5 = 1416,
    MATCH_F_SCORE_8_6 = 1417,
    MATCH_F_SCORE_8_7 = 1418,
    MATCH_F_SCORE_9_0 = 1419,
    MATCH_F_SCORE_9_1 = 1420,
    MATCH_F_SCORE_9_2 = 1421,
    MATCH_F_SCORE_9_3 = 1422,
    MATCH_F_SCORE_9_4 = 1423,
    MATCH_F_SCORE_9_5 = 1424,
    MATCH_F_SCORE_9_6 = 1425,
    MATCH_F_SCORE_9_7 = 1426,
    MATCH_F_SCORE_9_8 = 1427,
    MATCH_F_SCORE_10_0 = 1428,
    MATCH_F_SCORE_10_1 = 1429,
    MATCH_F_SCORE_10_2 = 1430,
    MATCH_F_SCORE_10_3 = 1431,
    MATCH_F_SCORE_10_4 = 1432,
    MATCH_F_SCORE_10_5 = 1433,
    MATCH_F_SCORE_10_6 = 1434,
    MATCH_F_SCORE_10_7 = 1435,
    MATCH_F_SCORE_10_8 = 1436,
    MATCH_F_SCORE_10_9 = 1437,

    MATCH_F_SCORE_OTHER = 52,
    /// ENDREGION

    /// REGION_TENNIS_SCORE
    // 3_СЕТА
    MATCH_T_SCORE_2_0 = 13,
    MATCH_T_SCORE_2_1 = 14,
    MATCH_T_SCORE_0_2 = 15,
    MATCH_T_SCORE_1_2 = 16,
    // 5_СЕТОВ
    MATCH_T_SCORE_3_0 = 17,
    MATCH_T_SCORE_3_1 = 18,
    MATCH_T_SCORE_3_2 = 19,
    MATCH_T_SCORE_0_3 = 20,
    MATCH_T_SCORE_1_3 = 21,
    MATCH_T_SCORE_2_3 = 22,
    /// ENDREGION

    /// REGION_SOCCER_MATCH_F_TIME_1
    MATCH_WIN_1_F_TIME_1_WIN_1 = 53,
    MATCH_DRAW_F_TIME_1_WIN_1 = 54,
    MATCH_WIN_1_F_TIME_1_DRAW = 55,
    MATCH_WIN_2_F_TIME_1_WIN_2 = 56,
    MATCH_DRAW_F_TIME_1_WIN_2 = 57,
    MATCH_WIN_2_F_TIME_1_DRAW = 58,
    MATCH_WIN_1_F_TIME_1_WIN_2 = 59,
    MATCH_WIN_2_F_TIME_1_WIN_1 = 60,
    MATCH_DRAW_F_TIME_1_DRAW = 61,
    /// ENDREGION

    /// REGION_TENNIS  1_SET/MATCH
    MATCH_WIN_1_F_TIME_1_WIN_1_SET = 973,
    MATCH_WIN_1_F_TIME_1_DRAW_SET = 974,
    MATCH_WIN_2_F_TIME_1_WIN_2_SET = 975,
    MATCH_WIN_2_F_TIME_1_DRAW_SET = 976,

/// ENDREGION
    MATCH_WITH_OT_TOTAL_ODD_2 = 1011,
    MATCH_TOTAL_UNDER_2 = 1130,
    MATCH_TOTAL_EQUAL_UNDER = 939,
    MATCH_TOTAL_INTERVAL = 129,
    MATCH_TOTAL_EQUAL_OVER = 130,
    MATCH_TOTAL_EQUAL = 940,
    F_TIME1_TOTAL_EQUAL = 941,
    F_TIME1_TOTAL_EQUAL_OVER = 942,
    F_MATCH_INDIVIDUAL_1_TOTAL_INTERVAL = 943,
    F_MATCH_INDIVIDUAL_1_TOTAL_OVER_EQUAL = 944,
    F_MATCH_INDIVIDUAL_1_TOTAL_UNDER_EQUAL = 945,
    F_MATCH_INDIVIDUAL2_TOTAL_INTERVAL = 946,
    F_MATCH_INDIVIDUAL2_TOTAL_OVER_EQUAL = 947,
    F_MATCH_INDIVIDUAL2_TOTAL_UNDER_EQUAL = 948,
    F_TIME1_INDIVIDUAL1_TOTAL_EQUAL = 949,
    F_TIME1_INDIVIDUAL1_TOTAL_EQUAL_OVER = 950,
    F_TIME1_INDIVIDUAL1_TOTAL_INTERVAL = 951,
    F_TIME1_INDIVIDUAL2_TOTAL_EQUAL = 952,
    F_TIME1_INDIVIDUAL2_TOTAL_EQUAL_OVER = 953,
    F_TIME1_INDIVIDUAL2_TOTAL_INTERVAL = 954,
    F_TIME1_TOTAL_EQUAL_UNDER = 955,
    F_TIME1_TOTAL_INTERVAL = 956,
    F_MATCH_INDIVIDUAL2_TOTAL_EQUAL = 957,
    F_MATCH_INDIVIDUAL1_TOTAL_EQUAL = 958,
    FOOTBALL_MATCH_INTERVAL_YES = 902,
    FOOTBALL_MATCH_INTERVAL_NO = 907,
    FOOTBALL_TEAM1_INTERVAL_YES = 912,
    FOOTBALL_TEAM1_INTERVAL_NO = 913,
    FOOTBALL_TEAM2_INTERVAL_YES = 914,
    FOOTBALL_TEAM2_INTERVAL_NO = 915,

    FOOTBALL_BOTH_TEAMS_TO_SCORE_TEAM1_YES = 1438,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TEAM1_NO = 1482,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TEAM2_YES = 1439,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TEAM2_NO = 1483,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_DRAW_YES = 1440,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_DRAW_NO = 1484,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_1X_YES = 1441,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_1X_NO = 1485,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_12_YES = 1442,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_12_NO = 1486,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_X2_YES = 1443,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_X2_NO = 1487,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TEAM1_YES = 1488,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TEAM1_NO = 1489,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_DRAW_YES = 1490,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_DRAW_NO = 1491,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TEAM2_YES = 1492,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TEAM2_NO = 1493,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_1X_YES = 1494,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_1X_NO = 1495,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_12_YES = 1496,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_12_NO = 1497,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_X2_YES = 1498,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_X2_NO = 1499,
    FOOTBALL_FIRST_GOAL_TEAM1_TEAM1_YES = 1460,
    FOOTBALL_FIRST_GOAL_TEAM1_TEAM1_NO = 1500,
    FOOTBALL_FIRST_GOAL_TEAM1_TEAM2_YES = 1461,
    FOOTBALL_FIRST_GOAL_TEAM1_TEAM2_NO = 1501,
    FOOTBALL_FIRST_GOAL_TEAM1_DRAW_YES = 1462,
    FOOTBALL_FIRST_GOAL_TEAM1_DRAW_NO = 1502,
    FOOTBALL_FIRST_GOAL_TEAM1_1X_YES = 1503,
    FOOTBALL_FIRST_GOAL_TEAM1_1X_NO = 1504,
    FOOTBALL_FIRST_GOAL_TEAM1_12_YES = 1505,
    FOOTBALL_FIRST_GOAL_TEAM1_12_NO = 1506,
    FOOTBALL_FIRST_GOAL_TEAM1_X2_YES = 1507,
    FOOTBALL_FIRST_GOAL_TEAM1_X2_NO = 1508,
    FOOTBALL_FIRST_GOAL_TEAM2_TEAM2_YES = 1463,
    FOOTBALL_FIRST_GOAL_TEAM2_TEAM2_NO = 1509,
    FOOTBALL_FIRST_GOAL_TEAM2_TEAM1_YES = 1464,
    FOOTBALL_FIRST_GOAL_TEAM2_TEAM1_NO = 1510,
    FOOTBALL_FIRST_GOAL_TEAM2_DRAW_YES = 1465,
    FOOTBALL_FIRST_GOAL_TEAM2_DRAW_NO = 1511,
    FOOTBALL_FIRST_GOAL_TEAM2_1X_YES = 1512,
    FOOTBALL_FIRST_GOAL_TEAM2_1X_NO = 1513,
    FOOTBALL_FIRST_GOAL_TEAM2_12_YES = 1514,
    FOOTBALL_FIRST_GOAL_TEAM2_12_NO = 1515,
    FOOTBALL_FIRST_GOAL_TEAM_2_X2_YES = 1516,
    FOOTBALL_FIRST_GOAL_TEAM_2_X2_NO = 1517,
    FOOTBALL_TEAM1_TOTAL_UNDER_YES = 719,
    FOOTBALL_TEAM1_TOTAL_UNDER_NO = 1472,
    FOOTBALL_TEAM1_TOTAL_OVER_YES = 720,
    FOOTBALL_TEAM1_TOTAL_OVER_NO = 1473,
    FOOTBALL_TEAM2_TOTAL_UNDER_YES = 721,
    FOOTBALL_TEAM2_TOTAL_UNDER_NO = 1524,
    FOOTBALL_TEAM2_TOTAL_OVER_YES = 722,
    FOOTBALL_TEAM2_TOTAL_OVER_NO = 1525,
    FOOTBALL_DRAW_TOTAL_UNDER_YES = 1448,
    FOOTBALL_DRAW_TOTAL_UNDER_NO = 1474,
    FOOTBALL_DRAW_TOTAL_OVER_YES = 1449,
    FOOTBALL_DRAW_TOTAL_OVER_NO = 1475,
    FOOTBALL_1X_TOTAL_UNDER_YES = 1450,
    FOOTBALL_1X_TOTAL_UNDER_NO = 1476,
    FOOTBALL_1X_TOTAL_OVER_YES = 1451,
    FOOTBALL_1X_TOTAL_OVER_NO = 1477,
    FOOTBALL_12_TOTAL_UNDER_YES = 1452,
    FOOTBALL_12_TOTAL_UNDER_NO = 1478,
    FOOTBALL_12_TOTAL_OVER_YES = 1453,
    FOOTBALL_12_TOTAL_OVER_NO = 1479,
    FOOTBALL_X2_TOTAL_UNDER_YES = 1454,
    FOOTBALL_X2_TOTAL_UNDER_NO = 1480,
    FOOTBALL_X2_TOTAL_OVER_YES = 1455,
    FOOTBALL_X2_TOTAL_OVER_NO = 1481,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TOTAL_UNDER_YES = 1456,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TOTAL_UNDER_NO = 1518,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TOTAL_OVER_YES = 1457,
    FOOTBALL_BOTH_TEAMS_TO_SCORE_TOTAL_OVER_NO = 1519,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TOTAL_UNDER_YES = 1458,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TOTAL_UNDER_NO = 1520,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TOTAL_OVER_YES = 1459,
    FOOTBALL_AT_LEAST_ONE_TEAM_NOT_TO_SCORE_TOTAL_OVER_NO = 1521,

    // GROUP_EVENTS
    GROUP_EVENT_WINNER = 112,
    GROUP_EVENT_LEAVE_THE_GROUP = 118,
    GROUP_EVENT_STAY_THE_GROUP = 119,
    GROUP_EVENT_PLACE_IN_GROUP = 199,
    GROUP_EVENT_FINISH_THE_RACE_YES = 191,
    GROUP_EVENT_FINISH_THE_RACE_NO = 192,
    GROUP_EVENT_FASTEST_LAP = 193,
    GROUP_EVENT_FIRST_PITSTOP = 194,
    GROUP_EVENT_LAST_PLACE = 195,
    GROUP_EVENT_WILL_QUALIFY_TO_FINAL = 201,
    GROUP_EVENT_WILL_QUALIFY_TO_FINAL_1_2 = 202,
    GROUP_EVENT_WILL_QUALIFY_TO_FINAL_1_4 = 203
}

export function isHandicap (quotation: EQuotation): boolean {
    if (quotation) {
        switch (quotation) {
            case EQuotation.MATCH_WITH_OT_HANDICAP_1:
            case EQuotation.MATCH_WITH_OT_HANDICAP_2:
            case EQuotation.MATCH_HANDICAP_1:
            case EQuotation.MATCH_HANDICAP_2:
            case EQuotation.F_TIME_1_HANDICAP_1:
            case EQuotation.F_TIME_1_HANDICAP_2:
            case EQuotation.F_TIME_2_HANDICAP_1:
            case EQuotation.F_TIME_2_HANDICAP_2:
            case EQuotation.H_TIME_1_HANDICAP_1:
            case EQuotation.H_TIME_1_HANDICAP_2:
            case EQuotation.H_TIME_2_HANDICAP_1:
            case EQuotation.H_TIME_2_HANDICAP_2:
            case EQuotation.T_SET_1_HANDICAP_1:
            case EQuotation.T_SET_1_HANDICAP_2:
            case EQuotation.T_SET_2_HANDICAP_1:
            case EQuotation.T_SET_2_HANDICAP_2:
            case EQuotation.T_SET_3_HANDICAP_1:
            case EQuotation.T_SET_3_HANDICAP_2:
            case EQuotation.T_SET_4_HANDICAP_1:
            case EQuotation.T_SET_4_HANDICAP_2:
            case EQuotation.T_SET_5_HANDICAP_1:
            case EQuotation.T_SET_5_HANDICAP_2:
            case EQuotation.V_SET_1_HANDICAP_1:
            case EQuotation.V_SET_1_HANDICAP_2:
            case EQuotation.V_SET_2_HANDICAP_1:
            case EQuotation.V_SET_2_HANDICAP_2:
            case EQuotation.V_SET_3_HANDICAP_1:
            case EQuotation.V_SET_3_HANDICAP_2:
            case EQuotation.V_SET_4_HANDICAP_1:
            case EQuotation.V_SET_4_HANDICAP_2:
            case EQuotation.V_SET_5_HANDICAP_1:
            case EQuotation.V_SET_5_HANDICAP_2:
            case EQuotation.BK_FIRST_HALF_HANDICAP_1:
            case EQuotation.BK_FIRST_HALF_HANDICAP_2:
            case EQuotation.BK_SECOND_HALF_HANDICAP_1:
            case EQuotation.BK_SECOND_HALF_HANDICAP_2:
            case EQuotation.QUARTER_1_HANDICAP_1:
            case EQuotation.QUARTER_1_HANDICAP_2:
            case EQuotation.QUARTER_2_HANDICAP_1:
            case EQuotation.QUARTER_2_HANDICAP_2:
            case EQuotation.QUARTER_3_HANDICAP_1:
            case EQuotation.QUARTER_3_HANDICAP_2:
            case EQuotation.QUARTER_4_HANDICAP_1:
            case EQuotation.QUARTER_4_HANDICAP_2:
            case EQuotation.PERIOD_1_HANDICAP_1:
            case EQuotation.PERIOD_1_HANDICAP_2:
            case EQuotation.PERIOD_2_HANDICAP_1:
            case EQuotation.PERIOD_2_HANDICAP_2:
            case EQuotation.PERIOD_3_HANDICAP_1:
            case EQuotation.PERIOD_3_HANDICAP_2:
            case EQuotation.BS_FIRST_HALF_HANDICAP_1:
            case EQuotation.BS_FIRST_HALF_HANDICAP_2:
            case EQuotation.BS_INNING_1_HANDICAP_1:
            case EQuotation.BS_INNING_1_HANDICAP_2:
            case EQuotation.BS_INNING_2_HANDICAP_1:
            case EQuotation.BS_INNING_2_HANDICAP_2:
            case EQuotation.BS_INNING_3_HANDICAP_1:
            case EQuotation.BS_INNING_3_HANDICAP_2:
            case EQuotation.BS_INNING_4_HANDICAP_1:
            case EQuotation.BS_INNING_4_HANDICAP_2:
            case EQuotation.BS_INNING_5_HANDICAP_1:
            case EQuotation.BS_INNING_5_HANDICAP_2:
            case EQuotation.BS_INNING_6_HANDICAP_1:
            case EQuotation.BS_INNING_6_HANDICAP_2:
            case EQuotation.BS_INNING_7_HANDICAP_1:
            case EQuotation.BS_INNING_7_HANDICAP_2:
            case EQuotation.BS_INNING_8_HANDICAP_1:
            case EQuotation.BS_INNING_8_HANDICAP_2:
            case EQuotation.BS_INNING_9_HANDICAP_1:
            case EQuotation.BS_INNING_9_HANDICAP_2: {
                return true;
            }
        }
    }
    return false;
}

export function isTotal (quotation: EQuotation): boolean {
    if (quotation) {
        switch (quotation) {
            case EQuotation.MATCH_WITH_OT_TOTAL_OVER:
            case EQuotation.MATCH_WITH_OT_TOTAL_UNDER: // case EQuotation.MATCH_WITH_OT_TOTAL_EVEN:
            case EQuotation.MATCH_WITH_OT_TOTAL_ODD: // case EQuotation.MATCH_WITH_OT_TOTAL_EVEN_2:
            case EQuotation.MATCH_WITH_OT_TOTAL_ODD_2:
            case EQuotation.MATCH_TOTAL_OVER:
            case EQuotation.MATCH_TOTAL_UNDER:
            case EQuotation.MATCH_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.MATCH_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.MATCH_INDIVIDUAL_2_TOTAL_OVER:
            case EQuotation.MATCH_INDIVIDUAL_2_TOTAL_UNDER:
            case EQuotation.H_TIME_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.H_TIME_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.H_TIME_1_INDIVIDUAL_2_TOTAL_OVER:
            case EQuotation.H_TIME_1_INDIVIDUAL_2_TOTAL_UNDER:
            case EQuotation.MATCH_TOTAL_UNDER_2:
            case EQuotation.MATCH_TOTAL_EQUAL_UNDER:
            case EQuotation.MATCH_TOTAL_EQUAL_OVER:
            case EQuotation.MATCH_TOTAL_EQUAL:
            case EQuotation.MATCH_TOTAL_INTERVAL:
            case EQuotation.F_MATCH_INDIVIDUAL_1_TOTAL_INTERVAL:
            case EQuotation.F_MATCH_INDIVIDUAL_1_TOTAL_OVER_EQUAL:
            case EQuotation.F_MATCH_INDIVIDUAL1_TOTAL_EQUAL:
            case EQuotation.F_MATCH_INDIVIDUAL_1_TOTAL_UNDER_EQUAL:
            case EQuotation.F_MATCH_INDIVIDUAL2_TOTAL_INTERVAL:
            case EQuotation.F_MATCH_INDIVIDUAL2_TOTAL_OVER_EQUAL:
            case EQuotation.F_MATCH_INDIVIDUAL2_TOTAL_EQUAL:
            case EQuotation.F_MATCH_INDIVIDUAL2_TOTAL_UNDER_EQUAL:
            case EQuotation.F_TIME1_TOTAL_EQUAL:
            case EQuotation.F_TIME1_TOTAL_EQUAL_OVER:
            case EQuotation.F_TIME1_TOTAL_EQUAL_UNDER:
            case EQuotation.F_TIME1_TOTAL_INTERVAL:
            case EQuotation.F_TIME1_INDIVIDUAL1_TOTAL_EQUAL:
            case EQuotation.F_TIME1_INDIVIDUAL1_TOTAL_EQUAL_OVER:
            case EQuotation.F_TIME1_INDIVIDUAL1_TOTAL_INTERVAL:
            case EQuotation.F_TIME1_INDIVIDUAL2_TOTAL_EQUAL:
            case EQuotation.F_TIME1_INDIVIDUAL2_TOTAL_EQUAL_OVER:
            case EQuotation.F_TIME1_INDIVIDUAL2_TOTAL_INTERVAL: // case EQuotation.MATCH_TOTAL_EVEN: case EQuotation.MATCH_TOTAL_ODD:
            case EQuotation.MATCH_WITH_OT_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.MATCH_WITH_OT_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.MATCH_WITH_OT_INDIVIDUAL_2_TOTAL_OVER:
            case EQuotation.MATCH_WITH_OT_INDIVIDUAL_2_TOTAL_UNDER:
            case EQuotation.F_TIME_1_TOTAL_OVER:
            case EQuotation.F_TIME_1_TOTAL_UNDER:
            case EQuotation.F_TIME_2_TOTAL_OVER:
            case EQuotation.F_TIME_2_TOTAL_UNDER:
            case EQuotation.H_TIME_1_TOTAL_OVER:
            case EQuotation.H_TIME_1_TOTAL_UNDER:
            case EQuotation.H_TIME_2_TOTAL_OVER:
            case EQuotation.H_TIME_2_TOTAL_UNDER:
            case EQuotation.H_TIME_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.H_TIME_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.H_TIME_2_INDIVIDUAL_2_TOTAL_OVER:
            case EQuotation.H_TIME_2_INDIVIDUAL_2_TOTAL_UNDER:
            case EQuotation.T_SET_1_TOTAL_OVER:
            case EQuotation.T_SET_1_TOTAL_UNDER:
            case EQuotation.T_SET_2_TOTAL_OVER:
            case EQuotation.T_SET_2_TOTAL_UNDER:
            case EQuotation.T_SET_3_TOTAL_OVER:
            case EQuotation.T_SET_3_TOTAL_UNDER:
            case EQuotation.T_SET_4_TOTAL_OVER:
            case EQuotation.T_SET_4_TOTAL_UNDER:
            case EQuotation.T_SET_5_TOTAL_OVER:
            case EQuotation.T_SET_5_TOTAL_UNDER:
            case EQuotation.V_SET_1_TOTAL_OVER:
            case EQuotation.V_SET_1_TOTAL_UNDER:
            case EQuotation.V_SET_2_TOTAL_OVER:
            case EQuotation.V_SET_2_TOTAL_UNDER:
            case EQuotation.V_SET_3_TOTAL_OVER:
            case EQuotation.V_SET_3_TOTAL_UNDER:
            case EQuotation.V_SET_4_TOTAL_OVER:
            case EQuotation.V_SET_4_TOTAL_UNDER:
            case EQuotation.V_SET_5_TOTAL_OVER:
            case EQuotation.V_SET_5_TOTAL_UNDER:
            case EQuotation.BK_FIRST_HALF_TOTAL_UNDER:
            case EQuotation.BK_FIRST_HALF_TOTAL_OVER:
            case EQuotation.BK_SECOND_HALF_TOTAL_UNDER:
            case EQuotation.BK_SECOND_HALF_TOTAL_OVER:
            case EQuotation.QUARTER_1_TOTAL_OVER:
            case EQuotation.QUARTER_1_TOTAL_UNDER:
            case EQuotation.QUARTER_2_TOTAL_OVER:
            case EQuotation.QUARTER_2_TOTAL_UNDER:
            case EQuotation.QUARTER_3_TOTAL_OVER:
            case EQuotation.QUARTER_3_TOTAL_UNDER:
            case EQuotation.QUARTER_4_TOTAL_OVER:
            case EQuotation.QUARTER_4_TOTAL_UNDER:
            case EQuotation.PERIOD_1_TOTAL_OVER:
            case EQuotation.PERIOD_1_TOTAL_UNDER:
            case EQuotation.PERIOD_2_TOTAL_OVER:
            case EQuotation.PERIOD_2_TOTAL_UNDER:
            case EQuotation.PERIOD_3_TOTAL_OVER:
            case EQuotation.PERIOD_3_TOTAL_UNDER:
            case EQuotation.MATCH_HITS_TOTAL_UNDER:
            case EQuotation.MATCH_HITS_TOTAL_OVER:
            case EQuotation.BS_FIRST_HALF_TOTAL_UNDER:
            case EQuotation.BS_FIRST_HALF_TOTAL_OVER:
            case EQuotation.BS_INNING_1_TOTAL_UNDER:
            case EQuotation.BS_INNING_1_TOTAL_OVER:
            case EQuotation.BS_INNING_2_TOTAL_UNDER:
            case EQuotation.BS_INNING_2_TOTAL_OVER:
            case EQuotation.BS_INNING_3_TOTAL_UNDER:
            case EQuotation.BS_INNING_3_TOTAL_OVER:
            case EQuotation.BS_INNING_4_TOTAL_UNDER:
            case EQuotation.BS_INNING_4_TOTAL_OVER:
            case EQuotation.BS_INNING_5_TOTAL_UNDER:
            case EQuotation.BS_INNING_5_TOTAL_OVER:
            case EQuotation.BS_INNING_6_TOTAL_UNDER:
            case EQuotation.BS_INNING_6_TOTAL_OVER:
            case EQuotation.BS_INNING_7_TOTAL_UNDER:
            case EQuotation.BS_INNING_7_TOTAL_OVER:
            case EQuotation.BS_INNING_8_TOTAL_UNDER:
            case EQuotation.BS_INNING_8_TOTAL_OVER:
            case EQuotation.BS_INNING_9_TOTAL_UNDER:
            case EQuotation.BS_INNING_9_TOTAL_OVER:
            case EQuotation.BK_FIRST_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.BK_FIRST_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.BK_FIRST_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.BK_FIRST_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.BK_SECOND_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.BK_SECOND_HALF_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.BK_SECOND_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.BK_SECOND_HALF_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_1_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_1_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_1_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_1_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_2_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_2_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_2_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_2_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_3_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_3_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_3_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_3_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_4_TEAM_1_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_4_TEAM_1_INDIVIDUAL_1_TOTAL_UNDER:
            case EQuotation.QUARTER_4_TEAM_2_INDIVIDUAL_1_TOTAL_OVER:
            case EQuotation.QUARTER_4_TEAM_2_INDIVIDUAL_1_TOTAL_UNDER: {
                return true;
            }
        }
    }
    return false;
}

export function isMatchScore (quotation: EQuotation): boolean {
    switch (quotation) {
        case EQuotation.MATCH_F_SCORE_0_0:
        case EQuotation.MATCH_F_SCORE_1_1:
        case EQuotation.MATCH_F_SCORE_2_2:
        case EQuotation.MATCH_F_SCORE_1_0:
        case EQuotation.MATCH_F_SCORE_2_0:
        case EQuotation.MATCH_F_SCORE_3_0:
        case EQuotation.MATCH_F_SCORE_0_1:
        case EQuotation.MATCH_F_SCORE_0_2:
        case EQuotation.MATCH_F_SCORE_0_3:
        case EQuotation.MATCH_F_SCORE_2_1:
        case EQuotation.MATCH_F_SCORE_3_1:
        case EQuotation.MATCH_F_SCORE_3_2:
        case EQuotation.MATCH_F_SCORE_1_2:
        case EQuotation.MATCH_F_SCORE_1_3:
        case EQuotation.MATCH_F_SCORE_2_3:
        case EQuotation.MATCH_F_SCORE_3_3:
        case EQuotation.MATCH_F_SCORE_4_1:
        case EQuotation.MATCH_F_SCORE_4_2:
        case EQuotation.MATCH_F_SCORE_4_3:
        case EQuotation.MATCH_F_SCORE_4_4:
        case EQuotation.MATCH_F_SCORE_1_4:
        case EQuotation.MATCH_F_SCORE_2_4:
        case EQuotation.MATCH_F_SCORE_3_4:
        case EQuotation.MATCH_F_SCORE_5_1:
        case EQuotation.MATCH_F_SCORE_5_2:
        case EQuotation.MATCH_F_SCORE_5_3:
        case EQuotation.MATCH_F_SCORE_5_4:
        case EQuotation.MATCH_F_SCORE_5_5:
        case EQuotation.MATCH_F_SCORE_1_5:
        case EQuotation.MATCH_F_SCORE_2_5:
        case EQuotation.MATCH_F_SCORE_4_5:
        case EQuotation.MATCH_F_SCORE_0_4:
        case EQuotation.MATCH_F_SCORE_0_5:
        case EQuotation.MATCH_F_SCORE_4_0:
        case EQuotation.MATCH_F_SCORE_5_0:
        case EQuotation.MATCH_F_SCORE_0_6:
        case EQuotation.MATCH_F_SCORE_1_6:
        case EQuotation.MATCH_F_SCORE_2_6:
        case EQuotation.MATCH_F_SCORE_3_6:
        case EQuotation.MATCH_F_SCORE_4_6:
        case EQuotation.MATCH_F_SCORE_5_6:
        case EQuotation.MATCH_F_SCORE_6_6:
        case EQuotation.MATCH_F_SCORE_0_7:
        case EQuotation.MATCH_F_SCORE_1_7:
        case EQuotation.MATCH_F_SCORE_2_7:
        case EQuotation.MATCH_F_SCORE_3_7:
        case EQuotation.MATCH_F_SCORE_4_7:
        case EQuotation.MATCH_F_SCORE_5_7:
        case EQuotation.MATCH_F_SCORE_6_7:
        case EQuotation.MATCH_F_SCORE_7_7:
        case EQuotation.MATCH_F_SCORE_0_8:
        case EQuotation.MATCH_F_SCORE_1_8:
        case EQuotation.MATCH_F_SCORE_2_8:
        case EQuotation.MATCH_F_SCORE_3_8:
        case EQuotation.MATCH_F_SCORE_4_8:
        case EQuotation.MATCH_F_SCORE_5_8:
        case EQuotation.MATCH_F_SCORE_6_8:
        case EQuotation.MATCH_F_SCORE_7_8:
        case EQuotation.MATCH_F_SCORE_8_8:
        case EQuotation.MATCH_F_SCORE_0_9:
        case EQuotation.MATCH_F_SCORE_1_9:
        case EQuotation.MATCH_F_SCORE_2_9:
        case EQuotation.MATCH_F_SCORE_3_9:
        case EQuotation.MATCH_F_SCORE_4_9:
        case EQuotation.MATCH_F_SCORE_5_9:
        case EQuotation.MATCH_F_SCORE_6_9:
        case EQuotation.MATCH_F_SCORE_7_9:
        case EQuotation.MATCH_F_SCORE_8_9:
        case EQuotation.MATCH_F_SCORE_9_9:
        case EQuotation.MATCH_F_SCORE_0_10:
        case EQuotation.MATCH_F_SCORE_1_10:
        case EQuotation.MATCH_F_SCORE_2_10:
        case EQuotation.MATCH_F_SCORE_3_10:
        case EQuotation.MATCH_F_SCORE_4_10:
        case EQuotation.MATCH_F_SCORE_5_10:
        case EQuotation.MATCH_F_SCORE_6_10:
        case EQuotation.MATCH_F_SCORE_7_10:
        case EQuotation.MATCH_F_SCORE_8_10:
        case EQuotation.MATCH_F_SCORE_9_10:
        case EQuotation.MATCH_F_SCORE_10_10:
        case EQuotation.MATCH_F_SCORE_6_0:
        case EQuotation.MATCH_F_SCORE_6_1:
        case EQuotation.MATCH_F_SCORE_6_2:
        case EQuotation.MATCH_F_SCORE_6_3:
        case EQuotation.MATCH_F_SCORE_6_4:
        case EQuotation.MATCH_F_SCORE_6_5:
        case EQuotation.MATCH_F_SCORE_7_0:
        case EQuotation.MATCH_F_SCORE_7_1:
        case EQuotation.MATCH_F_SCORE_7_2:
        case EQuotation.MATCH_F_SCORE_7_3:
        case EQuotation.MATCH_F_SCORE_7_4:
        case EQuotation.MATCH_F_SCORE_7_5:
        case EQuotation.MATCH_F_SCORE_7_6:
        case EQuotation.MATCH_F_SCORE_8_0:
        case EQuotation.MATCH_F_SCORE_8_1:
        case EQuotation.MATCH_F_SCORE_8_2:
        case EQuotation.MATCH_F_SCORE_8_3:
        case EQuotation.MATCH_F_SCORE_8_4:
        case EQuotation.MATCH_F_SCORE_8_5:
        case EQuotation.MATCH_F_SCORE_8_6:
        case EQuotation.MATCH_F_SCORE_8_7:
        case EQuotation.MATCH_F_SCORE_9_0:
        case EQuotation.MATCH_F_SCORE_9_1:
        case EQuotation.MATCH_F_SCORE_9_2:
        case EQuotation.MATCH_F_SCORE_9_3:
        case EQuotation.MATCH_F_SCORE_9_4:
        case EQuotation.MATCH_F_SCORE_9_5:
        case EQuotation.MATCH_F_SCORE_9_6:
        case EQuotation.MATCH_F_SCORE_9_7:
        case EQuotation.MATCH_F_SCORE_9_8:
        case EQuotation.MATCH_F_SCORE_10_0:
        case EQuotation.MATCH_F_SCORE_10_1:
        case EQuotation.MATCH_F_SCORE_10_2:
        case EQuotation.MATCH_F_SCORE_10_3:
        case EQuotation.MATCH_F_SCORE_10_4:
        case EQuotation.MATCH_F_SCORE_10_5:
        case EQuotation.MATCH_F_SCORE_10_6:
        case EQuotation.MATCH_F_SCORE_10_7:
        case EQuotation.MATCH_F_SCORE_10_8 :
        case EQuotation.MATCH_F_SCORE_10_9:
        case EQuotation.MATCH_F_SCORE_OTHER: {
            return true;
        }
    }
    return false;
}

export function isInterval (quotation: EQuotation): boolean {
    switch (quotation) {
        case EQuotation.FOOTBALL_MATCH_INTERVAL_YES:
        case EQuotation.FOOTBALL_MATCH_INTERVAL_NO:
        case EQuotation.FOOTBALL_TEAM1_INTERVAL_YES:
        case EQuotation.FOOTBALL_TEAM1_INTERVAL_NO:
        case EQuotation.FOOTBALL_TEAM2_INTERVAL_YES:
        case EQuotation.FOOTBALL_TEAM2_INTERVAL_NO: {
            return true;
        }
    }
    return false;
}

export function isGroupEventQuotation (quotation: EQuotation): boolean {
    switch (quotation) {
        case EQuotation.GROUP_EVENT_WINNER:
        case EQuotation.GROUP_EVENT_LEAVE_THE_GROUP:
        case EQuotation.GROUP_EVENT_STAY_THE_GROUP:
        case EQuotation.GROUP_EVENT_PLACE_IN_GROUP: {
            return true;
        }
    }
    return false;
}
