import { Comparable, Equable } from "@sportaq/common/types/interfaces";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { comparator, equals } from "@sportaq/common/types/functions";
import { EQuotation } from "@sportaq/common/consts/quotation-consts";
import EventType from "@sportaq/common/enums/event-type";

export class QuotationKey implements Equable<QuotationKey>, Comparable<QuotationKey> {
    readonly quotationId: EQuotation;

    readonly p1: BigDecimal;

    readonly p2: BigDecimal;

    readonly po: BigDecimal;

    readonly p1Id: number;

    readonly p2Id: number;

    constructor (
        quotationId: EQuotation,
        p1: BigDecimal | undefined,
        p2: BigDecimal | undefined,
        po: BigDecimal | undefined,
        p1Id: number | undefined,
        p2Id: number | undefined) {
        this.quotationId = quotationId;
        this.p1 = p1 && p1.numberValue !== 0 ? p1 : BigDecimal.ZERO;
        this.p2 = p2 && p2.numberValue !== 0 ? p2 : BigDecimal.ZERO;
        this.po = po && po.numberValue !== 0 ? po : BigDecimal.ZERO;
        this.p1Id = p1Id && p1Id !== 0 ? p1Id : 0;
        this.p2Id = p2Id && p2Id !== 0 ? p2Id : 0;
    }

    compare (o: QuotationKey): number {
        let result = this.quotationId - o.quotationId;
        if (result) {
            return result;
        }
        result = comparator(this.p1, o.p1);
        if (result) {
            return result;
        }
        result = comparator(this.p2, o.p2);
        if (result) {
            return result;
        }
        result = comparator(this.po, o.po);
        if (result) {
            return result;
        }
        result = this.p1Id - o.p1Id;
        if (result) {
            return result;
        }
        return this.p2Id - o.p2Id;
    }

    equals (o: QuotationKey): boolean {
        return this.quotationId === o.quotationId &&
            equals(this.p1, o.p1) &&
            equals(this.p2, o.p2) &&
            equals(this.po, o.po) &&
            this.p1Id === o.p1Id &&
            this.p2Id === o.p2Id;
    }
}

/*
    Needs because when new quotations receive from server, we don't create new quotation objects but update exist objects so the references don't be changed and Vue can't refresh view.
    Therefore, we wrap quotation to QuotationWrapper instead deep clone whole quotation.
 */
export interface QuotationWrapper {
    eventType: EventType;
    quotation: Quotation;
}

export class Quotation implements Equable<Quotation> {
    constructor (
        readonly id: number,
        readonly positionId: number,
        readonly key: QuotationKey,
        public coef: BigDecimal,
        public maxWin: number
    ) {
    }

    equals (o: Quotation): boolean {
        return this.id === o.id && this.positionId === o.positionId;
    }
}

export function isQuotationPresent (quotation: Quotation): boolean {
    return quotation && quotation.key.quotationId !== EQuotation.LOCKED_QUOTATION && quotation.key.quotationId !== EQuotation.GAP_QUOTATION;
}

export const LOCKED_QUOTATION = new Quotation(EQuotation.LOCKED_QUOTATION, -1,
    new QuotationKey(EQuotation.LOCKED_QUOTATION, undefined, undefined, undefined, undefined, undefined),
    new BigDecimal(-1, 1000), -1);
export const GAP_QUOTATION = new Quotation(EQuotation.GAP_QUOTATION, -2,
    new QuotationKey(EQuotation.GAP_QUOTATION, undefined, undefined, undefined, undefined, undefined),
    new BigDecimal(-2, 1000), -2);
