import { computed, SetupContext } from "vue";
import {
    EventSearchDialogComponent
} from "@sportaq/vue/components/betting/event-search-dialog/common-event-search-dialog-component";
import { useRootStore } from "@sportaq/vuex/index";

export default {
    emits: [EventSearchDialogComponent.CLOSE_EVENT],
    setup (props: unknown, setupContext: SetupContext) {
        const store = useRootStore();
        const imageThemePath = computed(() => store.theme);

        const {
            searchText,
            inputField,
            searchCompleted,
            searchResultFilter,
            searchResult,
            filteredSearchResult,
            close,
            change,
            openEventDetails
        } = EventSearchDialogComponent.useEventSearchDialogComponent(setupContext);

        function updateSearch (value: string) {
            searchText.value = value;
            change();
        }

        return {
            imageThemePath,
            searchText,
            inputField,
            searchCompleted,
            searchResultFilter,
            searchResult,
            filteredSearchResult,

            close,
            updateSearch,
            openEventDetails
        };
    }
};
