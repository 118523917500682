import { Ref } from "@sportaq/common/types/types";
import {
    CombinedStakeDTO,
    EStakeStatus,
    SimpleStake,
    SimpleStakeDTO,
    Stake
} from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { BetSlipStore, useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { createQuotationLabel, formatQuotationCoef } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { calculateCombinedMaxWin } from "@sportaq/model/common/combined-stakes-calculator";
import { useSettings } from "@sportaq/services/index";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { onPositiveNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { Localization } from "@sportaq/i18n/interfaces/interfaces";

export namespace BetSlipRowCommon {
    export enum CalculatedErrorCause {
        amountExceedMax = 100, // shouldn't intercept with EStakeStatus
        amountLessMin,
        coefNotConfirmed
    }

    export interface ErrorInfo {
        cause: CalculatedErrorCause | EStakeStatus;
        icon: string;
        message: string;
    }

    export function useStakeCommons (stake: Stake, amountChanged: (store: BetSlipStore, newAmount: number) => void) {
        const i18n = useI18n();
        const betSlipStore = useBetSlipStore();
        const settings = useSettings();
        const amount = ref(stake.amount);
        const errorShowed = ref(false);
        const numberInputDialogShowed = ref(false);
        const quotationFormat = ref(settings.quotationFormat);
        const stakeError = computed<ErrorInfo | undefined>(() => checkError(stake, i18n, amount));

        function showErrorMessage () {
            if (stakeError.value && !errorShowed.value) {
                errorShowed.value = true;
            }
        }

        function onInputFocus () {
            if (APP_TYPE.value === EApplicationType.betgol) {
                numberInputDialogShowed.value = true;
            }
        }

        function cancelAmountEditing () {
            numberInputDialogShowed.value = false;
        }

        function confirmAmountEditing (value: number) {
            numberInputDialogShowed.value = false;
            amount.value = value !== 0 ? value : undefined;
        }

        function accept (errorCause: CalculatedErrorCause | EStakeStatus) {
            switch (errorCause) {
                case CalculatedErrorCause.amountLessMin: {
                    amount.value = stake.minSumStake;
                    errorShowed.value = false;
                    amountChanged(betSlipStore, stake.minSumStake);
                    break;
                }
                case CalculatedErrorCause.amountExceedMax: {
                    amount.value = stake.maxSumStake;
                    errorShowed.value = false;
                    amountChanged(betSlipStore, stake.maxSumStake);
                    break;
                }
                case CalculatedErrorCause.coefNotConfirmed: {
                    const simpleStake = stake as SimpleStake;
                    const event = simpleStake.event;
                    const key = simpleStake.key;
                    betSlipStore.confirmSimpleStake(event.eventType, event.positionId, key.id);
                    errorShowed.value = false;
                    break;
                }
                case EStakeStatus.positionBlocked:
                case EStakeStatus.quotationBlocked:
                case EStakeStatus.eventFullTime:
                case EStakeStatus.eventStarted:
                case EStakeStatus.positionLiveServiceNotAvailable: {
                    const simpleStake = stake as SimpleStake;
                    const event = simpleStake.event;
                    const key = simpleStake.key;
                    betSlipStore.removeSimpleStake(event.eventType, event.positionId, key.id, settings.pointSettings);
                    break;
                }
            }
        }

        function onQuotationFormatChanged () {
            quotationFormat.value = settings.quotationFormat;
        }

        onMounted(() => {
            eventBus.on(Events.QUOTATION_FORMAT_CHANGED, onQuotationFormatChanged);
        });
        onUnmounted(() => {
            eventBus.off(Events.QUOTATION_FORMAT_CHANGED, onQuotationFormatChanged);
        });

        return {
            i18n,
            betSlipStore,
            amount,
            errorShowed,
            numberInputDialogShowed,
            quotationFormat,
            stakeError,

            showErrorMessage,
            accept,
            cancelAmountEditing,
            confirmAmountEditing,
            onAmountKeyDown: onPositiveNumberKeyFilter,
            onInputFocus
        };
    }

    export function getSimpleStakeQuotationLabel (quotationFormat: EQuotationFormat, simple: SimpleStake): string {
        const label = createQuotationLabel(simple, simple.key.quotationKey);
        return label ? label + " @ " + formatQuotationCoef(quotationFormat, simple.coef) : formatQuotationCoef(quotationFormat, simple.coef);
    }

    export function getSimpleStakeDTOQuotationLabel (quotationFormat: EQuotationFormat, simple: SimpleStakeDTO): string {
        const label = createQuotationLabel(simple, simple.key.quotationKey);
        return label ? label + " @ " + formatQuotationCoef(quotationFormat, simple.coef) : formatQuotationCoef(quotationFormat, simple.coef);
    }

    function checkError (stake: Stake, i18n: Localization, amount: Ref<number | undefined>): ErrorInfo | undefined {
        if (stake.status !== EStakeStatus.active) {
            const icon = "icon-break";
            let message = "";
            let cause = EStakeStatus.quotationBlocked;
            switch (stake.status) {
                case EStakeStatus.quotationBlocked: {
                    message = i18n.t("errors.betSlip.quotationBlocked");
                    cause = EStakeStatus.quotationBlocked;
                    break;
                }
                case EStakeStatus.positionBlocked: {
                    message = i18n.t("errors.betSlip.positionBlocked");
                    cause = EStakeStatus.positionBlocked;
                    break;
                }
                case EStakeStatus.eventFullTime: {
                    message = i18n.t("errors.betSlip.eventFullTime");
                    cause = EStakeStatus.eventFullTime;
                    break;
                }
                case EStakeStatus.eventStarted: {
                    message = i18n.t("errors.betSlip.eventStarted");
                    cause = EStakeStatus.eventStarted;
                    break;
                }
                case EStakeStatus.positionLiveServiceNotAvailable: {
                    message = i18n.t("errors.betSlip.response.positionLiveServiceNotAvailable");
                    cause = EStakeStatus.positionLiveServiceNotAvailable;
                    break;
                }
            }
            return {
                cause,
                icon,
                message
            };
        }
        if (amount.value && amount.value < stake.minSumStake) {
            return {
                cause: CalculatedErrorCause.amountLessMin,
                icon: "icon-min",
                message: i18n.t("errors.betSlip.lessMinStakeAmount")
            };
        }
        if (amount.value && amount.value > stake.maxSumStake) {
            return {
                cause: CalculatedErrorCause.amountExceedMax,
                icon: "icon-max",
                message: i18n.t("errors.betSlip.exceedsMaxStakeAmount")
            };
        }
        if (!stake.isConfirmed) {
            return {
                cause: CalculatedErrorCause.coefNotConfirmed,
                icon: "icon-coeff",
                message: i18n.t("errors.betSlip.coefWasChanged")
            };
        }
        return undefined;
    }
}

export interface BetTotal {
    total: number;
    maxWin: number;
}

export function calculateBetTotal (simpleStakes: SimpleStakeDTO[], combinedStakes: CombinedStakeDTO[], maxSystemCoef: number): BetTotal {
    let total = 0;
    let maxWin = 0;
    for (const stake of simpleStakes) {
        if (stake.amount) {
            total += stake.amount;
            maxWin += stake.amount * stake.coef;
        }
    }
    for (const stake of combinedStakes) {
        if (stake.amount) {
            total += stake.amount;
            maxWin += calculateCombinedMaxWin(stake.express, stake.coef, stake.amount, maxSystemCoef);
        }
    }
    return {
        total,
        maxWin
    };
}
