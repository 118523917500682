import ConstantItemsPanel from "@sportaq/vue/components/common/constant-items-panel/ConstantItemsPanel.vue";
import { SetupContext } from "vue";
import {
    ImageDictionaryItemWithSubItems
} from "@sportaq/vue/components/common/constant-items-panel/constant-items-panel-component";
import {
    EGameTabs,
    getCasinoGameTabImage,
    getCasinoGameTabName,
    useGamesTopMenu
} from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";

const STATIC_ITEMS: ImageDictionaryItemWithSubItems[] =
    [
        EGameTabs.Popular,
        EGameTabs.All,
        EGameTabs.Football,
        EGameTabs.Casual,
        EGameTabs.Crash,
        EGameTabs.Tennis,
        EGameTabs.Basketball,
        EGameTabs.Hockey,
        EGameTabs.Horse,
        EGameTabs.Dogs,
        EGameTabs.Moto,
        EGameTabs.Lottery,
        EGameTabs.Fighting,
        EGameTabs.Fishing,
        EGameTabs.Scratch,
        EGameTabs.Keno,
        EGameTabs.Bingo
    ].map(tab => ({
        id: tab,
        name: getCasinoGameTabName(tab),
        image: getCasinoGameTabImage(ECasinoGameType.Virtual, tab)
    }));

const EVENT = "select";

export default {
    components: { ConstantItemsPanel },
    props: {
        forceOpenSelectedTabByClick: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    emits: [EVENT],
    setup (_: unknown, context: SetupContext) {
        const defaultSelectedItemId = EGameTabs.All;
        const {
            items,
            select
        } = useGamesTopMenu(STATIC_ITEMS, ECasinoGameType.Virtual, context, EVENT);
        return {
            defaultSelectedItemId,
            items,
            select
        };
    }

};
