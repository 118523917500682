export const ROUTES = {
    HOME: {
        name: "home",
        path: ""
    },
    LOGIN: {
        name: "login",
        path: "/login"
    },
    REGISTRATION: {
        name: "registration",
        path: "/registration"
    },
    PROFILE: {
        name: "profile",
        path: "/profile"
    },
    EMAILCONFIRM: {
        name: "emailconfirm",
        path: "/emailconfirm"
    },
    ACCOUNT: {
        name: "account",
        path: "/account"
    },
    OPERATIONS: {
        name: "operations",
        path: "/operations"
    },
    FINANCES: {
        name: "finances",
        path: "/finances"
    },
    WITHDRAWAL_ORDERS: {
        name: "withdrawalOrders",
        path: "/withdrawal-orders"
    },
    DEPOSIT_OPERATIONS: {
        name: "depositOperations",
        path: "/deposit-operations"
    },
    BONUS_OPERATIONS: {
        name: "bonusOperations",
        path: "/bonus-operations"
    },
    ALL_STAKES: {
        name: "allstakes",
        path: "/stakes/all"
    },
    CASINO_STAKES: {
        name: "casinostakes",
        path: "/stakes/casino"
    },
    IN_PLAY: {
        name: "inplay",
        path: "/stakes/inplay"
    },
    WIN_STAKES: {
        name: "/winstakes",
        path: "/stakes/win"
    },
    RACE_STAKES: {
        name: "/racestakes",
        path: "/stakes/race"
    },
    DEPOSIT: {
        name: "deposit",
        path: "/deposit"
    },
    WITHDRAWAL: {
        name: "withdrawal",
        path: "/withdrawal"
    },
    BONUSES: {
        name: "bonuses",
        path: "/bonuses"
    },
    WHATSAPP: {
        name: "whatsappw",
        path: "/whatsappw"
    },
    PRIVACY: {
        name: "privacy",
        path: "/privacy"
    },
    RULES: {
        name: "rules",
        path: "/rules"
    },
    RULES_GENERAL: {
        name: "rulesGeneral",
        path: "/rules/general"
    },
    RULES_SPECIAL: {
        name: "rulesSpecial",
        path: "/rules/special"
    },
    RULES_STAKE_TYPES: {
        name: "rulesStakeTypes",
        path: "/rules/stakes"
    },
    RULES_FINANCE: {
        name: "rulesFinance",
        path: "/rules/finance"
    },
    RULES_RESULTS: {
        name: "rulesResults",
        path: "/rules/results"
    },
    DEPOSIT_NAV: {
        name: "depositNav",
        path: "/deposit/nav"
    },
    HISTORY_OPERATIONS: {
        name: "operationsHistory",
        path: "/history/operations"
    },
    HISTORY_SESSIONS: {
        name: "sessionsHistory",
        path: "/history/sessions"
    },
    HISTORY_BALANCE: {
        name: "operationsTotal",
        path: "/history/optotal"
    },
    NETWORK_ERROR: {
        name: "networkError",
        path: "/error"
    }
};
