<template>
    <DateFilter @dateChanged="dateChanged"
                :itemCount="operationsRef.length"
                @itemsPerPageChanged="itemsPerPageChanged"
                @currentPageChanged="currentPageChanged"
    ></DateFilter>
    <div class="deposit-section">
        <div class="deposit-section-body">
        <template v-for="(operation) in operationsPaged" :key="operation.operationId">
            <div class="line">
                <div class="line-date">{{$d(operation.operationDate, "long")}}</div>
                <div class="line-total smaller">
                    <div class="name">{{ $t("cashier.monitoring.operations." + operation.operationType) }}</div>
                    <div class="value">{{operation.amount}} {{operation.currency}}</div>
                </div>
            </div>
        </template>
        </div>
    </div>
    <br>
    <div class="line">
        <button class="button button-primary btn-load-more" @click.prevent="currentPage ++" v-if="currentPage < totalPages">
            {{$t("common.forms.loadMore")}}
        </button>
    </div>
    <div class="preloader" v-if="requestPending">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./operation-list-component.ts" />
