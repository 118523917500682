import { useRestService } from "@sportaq/services/index";
import { EventResult, Sport, SportEvent } from "@sportaq/model/results/results";
import { addLeadingZero, formatForMaxDate } from "@sportaq/common/utils/time-utils";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { getParticipantList, getParticipantsAsString } from "@sportaq/model/common/participants-functions";
import { countries } from "@sportaq/model/consts/countries";

export enum DateFilter {
    Yesterday, Today, Selected
}

export function useResultsViewCommon () {
    const restService = useRestService();
    const results = ref<Sport[]>();
    const selectedSport = ref<number>();
    const dateFilter = ref<DateFilter>(DateFilter.Today);
    const exactDate = ref<string>();
    const i18n = useI18n();
    const d = new Date();
    const maxDate = formatForMaxDate(d);
    const loading = computed(() => {
        return results.value === undefined;
    });

    watch(dateFilter, (value) => {
        if (value !== DateFilter.Selected) {
            exactDate.value = undefined;
        }
    });

    watch(exactDate, (value) => {
        if (value !== undefined) {
            const d = new Date(value);
            if (d.getFullYear() < 2000) {
                return;
            }
            if (d.getTime() > new Date().getTime()) {
                showMessageBox(i18n.t("webdesktop.results.invalidDate"));
                return;
            }
            dateFilter.value = DateFilter.Selected;
            loadResults();
        }
    });
    const filteredResults = computed(() => {
        if (!results.value) {
            return [];
        }
        if (!selectedSport.value) {
            return results.value;
        }
        for (const sport of results.value) {
            if (sport.id === selectedSport.value) {
                return [sport];
            }
        }
        return [];
    });

    function changeDateFilter (filter: DateFilter) {
        dateFilter.value = filter;
        loadResults();
    }

    function getDateFilter () {
        const startDate = dateFilter.value === DateFilter.Selected && exactDate.value ? new Date(exactDate.value) : new Date();
        startDate.setHours(0, 0, 0, 0);
        const finishDate = dateFilter.value === DateFilter.Selected && exactDate.value ? new Date(exactDate.value) : new Date();
        finishDate.setHours(23, 59, 59, 0);
        if (dateFilter.value === DateFilter.Yesterday) {
            startDate.setDate(startDate.getDate() - 1);
            finishDate.setDate(finishDate.getDate() - 1);
        }
        return {
            startDate,
            finishDate
        };
    }

    onMounted(loadResults);

    function loadResults () {
        results.value = undefined;
        const { startDate, finishDate } = getDateFilter();
        restService.getResults(startDate, finishDate)
            .then(r => { results.value = r; })
            .catch(() => { results.value = []; });
    }

    function getCountryImageUrl (countryId: number) {
        return "/img/flags/" + countries.getFlag(countryId) + ".png";
    }

    function formatDate (date: Date) {
        const now = new Date();
        if (date.getDate() === now.getDate()) {
            return i18n.t("webdesktop.results.today");
        }
        return addLeadingZero(date.getDate().toString()) + "." + addLeadingZero((date.getMonth() + 1).toString());
    }

    function formatTime (date: Date) {
        return addLeadingZero(date.getHours().toString()) + ":" + addLeadingZero(date.getMinutes().toString());
    }

    function getMainResults (results: EventResult[]) {
        const filtered = results.filter(r => r.resultTypeId === 200);
        if (filtered.length === 0) {
            return "";
        }
        let result = filtered[0].value + ":" + filtered[1].value + " (";
        for (let i = 2; i < filtered.length;) {
            result += filtered[i]?.value + ":" + filtered[i + 1]?.value;
            i += 2;
            if (i < filtered.length) {
                result += ", ";
            }
        }
        result += ")";
        return result;
    }

    function hasAdditionalResults (results: EventResult[]) {
        return results.filter(r => r.resultTypeId === 440 || r.resultTypeId === 250).length > 0;
    }

    function getAdditionalResults (sport: ESportType, event: SportEvent): string[] {
        const participants = getParticipantList(event);
        if (sport === ESportType.Football) {
            return event.results.filter(r => r.resultTypeId === 440).map(v => {
                return i18n.t("webdesktop.results.goals.goal" + v.resultIndex, {
                    participantName: participants[v.eventParticipantIndex - 1].name,
                    time: v.resultTime
                });
            });
        }
        if (sport === ESportType.Tennis || sport === ESportType.TableTennis) {
            return event.results.filter(r => r.resultTypeId === 250 && r.value === 1).map(v => {
                return i18n.t("webdesktop.results.set", {
                    participant: participants[v.eventParticipantIndex - 1].name,
                    set: v.period.substring(0, 2),
                    game: v.period.substring(2, 4)
                });
            });
        }
        return [];
    }

    function getParticipants (event: SportEvent): string {
        return getParticipantsAsString(event);
    }

    return {
        selectedSport,
        filteredResults,
        results,
        dateFilter,
        loading,
        exactDate,
        maxDate,
        getAdditionalResults,
        hasAdditionalResults,
        getMainResults,
        formatDate,
        formatTime,
        getCountryImageUrl,
        changeDateFilter,
        getParticipants
    };
}
