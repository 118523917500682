import MobileCasinoGames from "@/components/games/casino/MobileCasinoGames.vue";
import { useCasinoPageTitle } from "@sportaq/vue/components/web/casino-page-title";
import { onMounted } from "vue";

export default {
    components: {
        MobileCasinoGames
    },
    setup () {
        const { updateTitle } = useCasinoPageTitle("casino");
        onMounted(() => {
            updateTitle();
        });
    }
};
