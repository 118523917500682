import StakesMenu from "@/views/stakes-view/stakes-menu/StakesMenu.vue";
import { ROUTES } from "@/router/routes-consts";
import { useRouter } from "vue-router";
import { useFinanceViewCommon } from "@sportaq/vue/views/web/stakes/finance-view-common";

export default {
    name: "StakesView",
    components: {
        StakesMenu
    },
    setup () {
        const {
            tabs,
            isLoggedIn
        } = useFinanceViewCommon();
        const router = useRouter();
        function back () {
            router.push({ path: ROUTES.FINANCES.path }).then();
        }
        return {
            tabs,
            isLoggedIn,
            back
        };
    }
};
