import VirtualGamesTopMenu from "@sportaq/vue/components/games/virtual/virtual-games-top-menu/VirtualGamesTopMenu.vue";
import GamePreview from "@/components/games/game-preview/GamePreview.vue";
import GamesBody from "@sportaq/vue/components/games/common/games-body/GamesBody.vue";
import GameFrame from "@sportaq/vue/components/games/common/game-frame/GameFrame.vue";
import { computed, onMounted, SetupContext } from "vue";
import { VirtualGamesComponent } from "@sportaq/vue/components/games/virtual/common-virtual-games-component";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import { useWebVirtualGamesLifecycle } from "@sportaq/vue/components/web/web-virtual-games-lifecycle";

export default {
    components: {
        VirtualGamesTopMenu,
        GamesBody,
        GamePreview,
        GameFrame
    },
    setup (props: [], context: SetupContext) {
        useWebVirtualGamesLifecycle();
        const webStore = useWebStore();

        const isLoggedIn = computed(() => webStore.user !== undefined);

        const {
            currentGame,
            forceOpenSelectedTabByClick,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        } = VirtualGamesComponent.useVirtualGamesComponent(context);

        onMounted(() => {
            trackEventFacebook("viewcontentvirtual");
        });

        return {
            currentGame,
            forceOpenSelectedTabByClick,
            isLoggedIn,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        };
    }
};
