import MobileScoreboardDetails
    from "@/components/betting/betting-scoreboard/scoreboard-details/MobileScoreboardDetails.vue";

import {
    EventPageComponent
} from "@sportaq/vue/components/betting/event-details/page/common-event-details-page-component";
import useCommonEventDetailsPageComponent = EventPageComponent.useCommonEventDetailsPageComponent;
import { EDetailsViewType } from "@sportaq/model/betting/view/scoreboard-selection";

export default {
    components: {
        MobileScoreboardDetails
    },
    setup () {
        const { selection } = useCommonEventDetailsPageComponent();

        return {
            EDetailsViewType,
            selection
        };
    }
};
