import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    forEach,
    getAttribute,
    getChild,
    getRequiredChild,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import EventType from "@sportaq/common/enums/event-type";

export abstract class QLE12AbstractHandler extends AbstractMessageHandler<number[]> {
    protected readonly requestType = "Q.LE.1.2";

    protected abstract fillFilter (request: XmlRequest, filter: Element): void;

    protected constructor (readonly eventType: EventType) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const root = request.addChild(request.body, "query", {
            type: this.requestType
        });

        const filter = request.addChild(root, "Filter", { logic: "AND" });
        const lineFilter = request.addChild(filter, "LineFilter", { logic: "AND" });
        request.addChildWithValue(lineFilter, "IsBlockedPosition", "False");
        switch (this.eventType) {
            case EventType.PRE_MATCH: {
                request.addChildWithValue(lineFilter, "EventStatus", "NotStarted");
                request.addChildWithValue(lineFilter, "LiveLine", "False");
                break;
            }
            case EventType.LIVE: {
                request.addChildWithValue(lineFilter, "EventStatus", "InProcess");
                request.addChildWithValue(lineFilter, "LiveLine", "True");
                break;
            }
        }
        this.fillFilter(request, lineFilter);

        const selector = request.addChild(root, "Selector");
        const selectObject = request.addChild(selector, "SelectObject", { class: "ps.line.Position" });
        request.addChild(selectObject, "SelectField", { name: "Id" });
    }

    parseMessageBody (body: Element): number[] {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredIntAttribute(query, "servercode");
        if (serverCode === 100) {
            const result: number[] = [];
            const positionList = getChild(query, "PositionList");
            if (positionList) {
                forEach(positionList, "PS", el => {
                    result.push(getRequiredIntAttribute(el, "Id"));
                });
            }
            return result;
        } else {
            throw new Error(getAttribute(query, "msg"));
        }
    }
}
