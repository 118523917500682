/* eslint quote-props: ["error", "always"] */
export default {
    "brands": {
        "betgol": "BetGol",
        "certabet": "Certabet",
        "support": {
            "betgol": "info{'@'}tubetgol.com",
            "certabet": "info{'@'}certa.bet"
        },
        "site": {
            "betgol": "tubetgol.com",
            "certabet": "certa.bet"
        }
    },
    "sports": {
        "1": "Футбол",
        "2": "Баскетбол",
        "3": "Хоккей",
        "4": "Теннис",
        "5": "Formula1",
        "6": "Волейбол",
        "7": "Бейсбол",
        "8": "Регби",
        "9": "AmericanFootball",
        "10": "Бокс",
        "11": "Keno",
        "12": "SkiJumping",
        "13": "Skeleton",
        "14": "Luge",
        "15": "Snowboard",
        "16": "FreestyleSkiing",
        "17": "FigureSkating",
        "18": "ShortTrack",
        "19": "Biathlon",
        "20": "Bobsleigh",
        "21": "AlpineSkiing",
        "22": "Curling",
        "23": "SpeedSkating",
        "24": "NordicCombined",
        "25": "CrossCountry",
        "26": "SuperLotto",
        "27": "WaterPolo",
        "28": "Гандбол",
        "29": "Bandy",
        "30": "Swimming",
        "31": "Chess",
        "32": "Futsal",
        "33": "Contest",
        "35": "Policy",
        "36": "OlympicGames",
        "37": "FieldHockey",
        "38": "AutoSport",
        "39": "Motorsport",
        "40": "Billiards",
        "41": "Athletic",
        "42": "Lotto3",
        "43": "Badminton",
        "44": "Настольный теннис",
        "45": "BeachSoccer",
        "46": "BeachVolleyball",
        "47": "ESports"
    },
    "routes": {
        "home": "ГЛАВНАЯ",
        "sport": "СПОРТ",
        "live": "LIVE",
        "casino": "КАЗИНО",
        "registration": "Открыть счёт",
        "results": "Результаты",
        "virtual": "Виртуальные",
        "horse": "Скачки",
        "games": "ИГРЫ"
    },
    "appMenuBar": {
        "deposit": "Пополнение",
        "withdrawal": "Снятие",
        "verifyBetSlip": "Проверить ставку"
    },
    "common": {
        "tempPassword": "Смените временный пароль",
        "language": {
            "language": "Язык",
            "languageName": "Русский"
        },
        "pluralization": {
            "hour": "{hour} ч|{hour} ч|{hour} ч",
            "match": "{count} матчей|{count} матч|{count} матча|{count} матчей"
        },
        "days": {
            "tomorrow": "Завтра",
            "today": "Сегодня"
        },
        "update": {
            "checkingForUpdates": "Проверка наличия новых версий...",
            "updatesAvailable": "Загрузка новой версии...\nПосле загрузки приложение будет перезапущено для установки новой версии",
            "updateError": "Во время обновления произошла ошибка:\n{error}\nПожалуйста, перезапустите приложение и попробуйте чуть позже.\nЕсли ошибка будет повторяться, обратитесь к системному администратору."
        },
        "settings": {
            "tabs": {
                "generalTab": "Общие",
                "connectionTab": "Подключение"
            },
            "appCode": "Код программы:",
            "partnerCode": "Код партнера:",
            "address": "Адрес:",
            "network": {
                "restServerAddress": "Адрес сервера обработки:",
                "streamServerAddress": "Адрес сервера данных:",
                "useSSL": "Использовать защищённое соединение SSL"
            },
            "logo": "Логотип (формат BASE64) :"
        },
        "forms": {
            "yes": "Да",
            "no": "Нет",
            "ok": "Ок",
            "cancel": "Отмена",
            "close": "Закрыть",
            "open": "Открыть",
            "continue": "Продолжить",
            "print": "Печать",
            "clear": "Очистить",
            "copy": "Копировать",
            "loadMore": "Больше",
            "validation": {
                "required": "Поле должно быть заполнено",
                "email": "Указанная почта не является корректной",
                "min": "Минимальная длина поля {param} символов.",
                "confirmed": "Пароли не совпадают",
                "invalidFormat": "Неверный формат",
                "birthday": "Вам должно быть 18+ лет",
                "invalidCharacters": "Символы + - , ; & являются недопустимыми"
            }
        },
        "games": {
            "tabs": {
                "all": "Все игры",
                "popular": "Популярные",
                "new": "Новые",
                "favourites": "Избранные",
                "football": "Футбол",
                "tennis": "Теннис",
                "horse": "Лошади",
                "dog": "Собаки",
                "moto": "Мотогонки",
                "fighting": "Бои",
                "providers": "Провайдеры",
                "casual": "Казуальные",
                "slots": "Слоты",
                "card": "Настольные",
                "lottery": "Лотерея",
                "roulette": "Рулетка",
                "poker": "Покер",
                "jackpot": "Джекпот",
                "live": "Live",
                "blackjack": "Блэкджек",
                "craps": "Craps",
                "buyfeature": "Buy feature",
                "megaways": "Megaways",
                "crash": "Crash",
                "fishing": "Fishing",
                "scratch": "Scratch",
                "basketball": "Basketball",
                "keno": "Keno",
                "bingo": "Bingo",
                "hockey": "Хоккей"
            },
            "buttons": {
                "play": "ИГРАТЬ",
                "demo": "ДЕМО"
            },
            "zeroBalance": "Недостаточно средств на счету казино"
        }
    },
    "appTitle": {
        "brand": {
            "betgol": "Ставки на спорт и Казино Онлайн | Betgol",
            "BETGOL": "Ставки на спорт и Казино Онлайн | BETGOL",
            "latino": "Apuestas Deportivas",
            "ligabet": "Apuestas Deportivas",
            "columbiaApuesta": "Apuestas",
            "certabet": "Apostas Esportivas e Cassino"
        },
        "cashier": "Cashier"
    },
    "homePage": {
        "widgets": {
            "coupon": {
                "coupons": "КУПОНЫ",
                "today": "Сегодня",
                "totalGoals": "Тотал матча",
                "bothToScore": "Обе забьют",
                "weekend": "Матчи выходных",
                "mostPopular": "Популярные"
            },
            "live": "LIVE",
            "starting-soon": "СКОРО"
        }
    },
    "betting": {
        "depositAccount": {
            "success": "Пополнение баланса произведено успешно!"
        },
        "countryMenu": {
            "all": "Все",
            "hot": "Избранные"
        },
        "codeInputForm": {
            "submit": "Проверить",
            "close": "Закрыть",
            "caption": "Проверить номер"
        },
        "withdrawalForm": {
            "caption": "Введите сумму вывода",
            "cancel": "Отмена",
            "ticket": "Снять",
            "success": {
                "text": "Снятие в размере <b>{amount} {currency}</b> произведено успешно. <br> N кода <b>{code}</b>",
                "caption": "Снятие"
            }
        },
        "horse": {
            "ticket": {
                "serial": "Код",
                "date": "Дата",
                "horse": "Лошадь",
                "game": "Игра",
                "amount": "Сумма",
                "status": "Статус",
                "total": "Итого:"
            }
        },
        "couponVerification": {
            "caption": "Проверка ставки",
            "coupon": "Карточка ставки",
            "bet": "Ставка",
            "date": "Дата",
            "event": "Событие",
            "coef": "Коэф.",
            "amount": "Ставка",
            "payment": "Выплата",
            "info": "Инфо",
            "cardNotFound": "Карточка не найдена",
            "paymentTotal": "Выплата составляет {total} {curr} ",
            "transferButton": "Пеервести на баланс",
            "transfer": "Перевести на баланс?",
            "success": "Начисление произведено успешно"
        },
        "navigationSideBar": {
            "league": "Лиги",
            "countries": "A-Z",
            "search": "Поиск",
            "rules": "Правила",
            "faq": "Вопросы",
            "reload": "Обновить",
            "time": "Время"
        },
        "searchDialog": {
            "placeholder": "Поиск",
            "emptyResults": "Подходящие события отсутствуют"
        },
        "mobile": {
            "favourites": "Избранные",
            "popular": "Популярные"
        },
        "event": {
            "scoreboardTitle": {
                "events": "События",
                "menu": {
                    "item1x2": "Победа в матче",
                    "item12": "Победа в матче",
                    "item1x12x2": "Двойной шанс",
                    "itemHandicap": "Победа с учетом форы",
                    "itemTotalGoals": "Тотал матча",
                    "itemLocalTotalGoals": "Индивидуальный тотал 1 команды",
                    "itemVisitTotalGoals": "Индивидуальный тотал 2 команды",
                    "itemBothWillScore": "Обе забьют",
                    "itemTotalPoints": "Тотал матча",
                    "itemLocalTotalPoints": "Индивидуальный тотал 1 команды",
                    "itemVisitTotalPoints": "Индивидуальный тотал 2 команды",
                    "itemTotalGames": "Тотал матча",
                    "itemLocalTotalGames": "Индивидуальный тотал 1 команды",
                    "itemVisitTotalGames": "Индивидуальный тотал 2 команды",
                    "itemTotalRuns": "Тотал матча",
                    "itemTotal": "Тотал матча",
                    "itemMatchTotal": "Тотал матча",
                    "item1x2xFavourites": "Результат матча",
                    "itemTotalFavourites": "Тотал матча",
                    "itemHandicapFavourites": "Победа с учетом форы"
                }
            },
            "scoreboardLine": {
                "startDate": "Сегодня"
            },
            "periods": {
                "sport1": {
                    "period1": "1 тайм",
                    "period2": "2 тайм"
                },
                "sport2": {
                    "period1": "1 четверть",
                    "period2": "2 четверть",
                    "period3": "3 четверть",
                    "period4": "4 четверть",
                    "period100": "1 половина",
                    "period101": "2 половина"
                },
                "sport3": {
                    "period1": "1 период",
                    "period2": "2 период",
                    "period3": "3 период"
                },
                "sport4": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                },
                "sport6": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                },
                "sport7": {
                    "period1": "1 иннинг",
                    "period2": "2 иннинг",
                    "period3": "3 иннинг",
                    "period4": "4 иннинг",
                    "period5": "5 иннинг",
                    "period6": "6 иннинг",
                    "period7": "7 иннинг",
                    "period8": "8 иннинг",
                    "period9": "9 иннинг",
                    "period10": "10 иннинг",
                    "period11": "11 иннинг",
                    "period12": "12 иннинг",
                    "period13": "13 иннинг",
                    "period100": "1 половина"
                },
                "sport9": {
                    "period1": "1 четверть",
                    "period2": "2 четверть",
                    "period3": "3 четверть",
                    "period4": "4 четверть",
                    "period100": "1 половина"
                },
                "sport28": {
                    "period1": "1 тайм",
                    "period2": "2 тайм"
                },
                "sport44": {
                    "period1": "1 сет",
                    "period2": "2 сет",
                    "period3": "3 сет",
                    "period4": "4 сет",
                    "period5": "5 сет"
                }
            },
            "groupEvents": {
                "participant": "Участник",
                "leaveTheGroup": "Выйдет из группы",
                "stayTheGroup": "Не выйдет из группы",
                "winner": "Победитель",
                "placeInGroup": "{p1} место в группе",
                "finishTheRaceYes": "Закончит гонку - Да",
                "finishTheRaceNo": "Закончит гонку - Нет",
                "fastestLap": "Быстрейший круг",
                "firstPitstop": "Первый Pitstop",
                "lastPlace": "Последнее место",
                "willQualifyToFinal": "Проход в Финал",
                "willQualifyToFinal1_2": "Проход в 1/2 Финала",
                "willQualifyToFinal1_4": "Проход в 1/4 Финала"
            }
        },
        "betSlip": {
            "title": "Карточка",
            "shortTitle": "Карточка",
            "acceptWithAllQuotations": "Принять со всеми изменениями",
            "acceptOnlyWithQuotationsAreRaised": "Принять только при повышении коэффициентов",
            "oneClickBet": "Ставка одним кликом",
            "simple": "Одиночная ставка",
            "combined": "Комбинированная ставка",
            "maxWin": "Максимальный выигрыш",
            "ordinar": "Ординар",
            "express": "Экспресс",
            "expressWithCoef": "Экспресс (коэф. {coef})",
            "system": "Система (из {limit} по {base})",
            "variants": "Варианты:",
            "columns": {
                "event": "Событие",
                "sum": "Сумма"
            },
            "buttons": {
                "clear": "Очистить",
                "continue": "Оплатить",
                "accept": "Принять"
            },
            "confirmCoefDiffs": "Линия, коэффициенты или доступность исходов на вашем купоне ставок изменилась.",
            "acceptButton": "Принять",
            "common": {
                "total": "Сумма ставки:",
                "maxWin": "Возможный выигрыш"
            },
            "print": {
                "card": "Карточка",
                "code": "Код",
                "time": "Время:",
                "endTime": "Действителен до:",
                "confirm": "Сделав ставку, Вы подтверждаете, что ознакомлены с правилами."
            },
            "dialogBetAccepted": {
                "caption": "Ставка принята",
                "code": "Код",
                "columns": {
                    "date": "Дата, Время",
                    "event": "Событие",
                    "count": "Количество",
                    "coef": "Кф",
                    "amount": "Ставка",
                    "maxWin": "Макс. выигрыш",
                    "variants": "{variants} вар."
                },
                "timer": "У вас есть {timer} секунд, чтобы  отменить ставку.",
                "copy": "Копировать",
                "cancel": "Отменить"
            },
            "dialogBetVerification": {
                "caption": "Проверьте правильность данных вашей ставки"
            },
            "numberInputDialog": {
                "caption": "Введите сумму ставки",
                "confirmButton": "OK"
            }
        },
        "registration": {
            "title": "Регистрация",
            "tabs": {
                "logoTab": "Логотип"
            },
            "fields": {
                "pointName": "Название зала:",
                "city": "Город:",
                "countryLabel": "Страна:",
                "phone": "Номер телефона:"
            }
        }
    },
    "cashier": {
        "loginForm": {
            "caption": "Авторизация",
            "user": "Пользователь",
            "password": "Пароль",
            "parameters": "Параметры",
            "settings": "Настройки",
            "registration": "Регистрация",
            "settingsNotFilled": "Не заполнены настройки  приложения",
            "serverAddressNotFilled": "Не заполнен адрес сервера",
            "noAccount": "Отсутствует валюта счета"
        },
        "registrationForm": {
            "caption": "Имя пользователя",
            "number": "#",
            "nameGroup": "Имя пользователя",
            "lastName": "Фамилия:*",
            "firstName": "Имя:*",
            "login": "Логин:*",
            "requiredFields": "* - поля, обязательные для заполнения",
            "contactsGroup": "Контакты",
            "address": "Адрес:",
            "phone": "Телефон:*",
            "email": "E-mail:*",
            "passwordGroup": "Пароль",
            "password": "Пароль:*",
            "confirmPassword": "Подтверждение пароля:*"
        },
        "expressAccount": {
            "caption": "Cоздание экспресс счета",
            "firstName": "Имя: *",
            "lastName": "Фамилия: *",
            "orderSum": "Сумма: *",
            "document": "Документ: *",
            "print": {
                "number": "Номер экспресс-счета:",
                "balance": "Баланс счета:",
                "siteCaption": "Данные для входа на сайт:",
                "login": "Логин:",
                "password": "Пароль:",
                "warning": "Внимание! Сразу же после входа необходимо сменить пароль!"
            }
        },
        "settingsForm": {
            "caption": "Параметры",
            "locationTab": "Расположение",
            "optionsTab": "Опции",
            "requisites": "Реквизиты",
            "remoteAccess": "Настройка удалённого доступа",
            "partnerCode": "Код партнёра:",
            "cashboxName": "Название кассы:",
            "finance": "Финансы",
            "currency": "Валюта:",
            "minStakeOrdinar": "Минимальная ставка (Ординар):",
            "minStakeExpress": "Минимальная ставка (Экспресс):",
            "minStakeSystem": "Минимальная ставка (Система):",
            "proxy": "Прокси-сервер:",
            "useProxy": "Использовать прокси-сервер",
            "proxyAddress": "Адрес:",
            "proxyPort": "Порт:",
            "captionImage": "Файл картинок для заголовков",
            "multiscreen": "Мультискрин Лайв",
            "multiscreenFile": "Exe-файл:",
            "hotkey": "Горячяя клавиша Ctrl+",
            "directOutput": "Прямой вывод чека на принтер",
            "cashbox": "Касса",
            "voucher": "Ваучеры",
            "express": "Экспресс счета",
            "terminal": "Терминал счета",
            "checkFormat": "Формат чека:",
            "leftPad": "Левый отступ:",
            "barcodeScanner": "Сканер штрих кодов",
            "barcodeScannerPort": "Номер порта:",
            "depositMenu": "Пункт меню Депозит"
        },
        "finDayForm": {
            "caption": "Финансовый день - Сверка наличных"
        },
        "dailyReportForm": {
            "caption": "Отчет за день",
            "totalGenerationVoucher": "Всего проданных ваучеров ",
            "totalCashCheque": "Всего погашенных ваучеров",
            "totalBalanceNulling": "Всего обнулений баланса БП",
            "totalBalanceDeposit": "Всего пополнений",
            "totalBalanceWithdowal": "Всего снятий",
            "totalStake": "Всего ставок Спорт",
            "totalPaid": "Всего выплат Спорт (касса)",
            "totalStakeRace": "Всего ставок Забеги",
            "totalPayRace": "Всего выплат Забеги",
            "totalCancelStakeBP": "Всего отмененных БП",
            "totalPaidTerminal": "Всего выплат (терминал)",
            "total": "Итого",
            "closeDay": "Закрыть фин. день",
            "success": "Финансовый день успешно закрыт",
            "confirm": "Закрыть финансовый день?",
            "totalBalanceDepositBetPlace": "Прямое пополнение баланса BP",
            "refresh": "Обновить"
        },
        "userConfirmationForm": {
            "caption": "Поиск Пользователя",
            "accountId": "Номер счёта:",
            "document": "Документ:",
            "findButton": "Поиск пользователя",
            "personalData": "Персональные данные",
            "personalCode": "Персональный код:",
            "name": "Имя:",
            "birthdate": "Дата рождения:",
            "lastName": "Фамилия:",
            "docs": "Документы пользователя:",
            "verify": "Верификация пользователя:",
            "confirmButton": "Подтвердить данные",
            "verifiedYes": "Да",
            "verifiedNo": "Нет",
            "success": "Выполнено успешно"
        },
        "refillAccountForm": {
            "caption": "Прямое пополнение счёта",
            "user": "Пользователь:",
            "findUser": "Поиск пользователя",
            "accountNumber": "Номер счёта или логин:",
            "phone": "Телефон: +",
            "document": "Документ:",
            "checkButton": "Проверить",
            "accountRefill": "Пополнение счёта",
            "amount": "Сумма:",
            "getBonus": "Получить бонус",
            "refillButton": "Пополнить",
            "insufficientFunds": "Недостаточно средств",
            "empty": "Поле должно быть заполнено",
            "table": {
                "time": "Время",
                "num": "Номер",
                "name": "Имя",
                "amount": "Сумма"
            },
            "print": {
                "accountNumber": "Номер счета",
                "amount": "Баланс"
            }
        },
        "winPaymentForm": {
            "caption": "Выплата выигрыша",
            "cardCode": "Код карточки:",
            "find": "Поиск",
            "doPayment": "Выплатить",
            "success": "Выплата выигрыша ставки выполнена успешно",
            "notfound": "Карточка не найдена",
            "expired": "Срок выплаты выигрыша уже прошел.",
            "alreadyPaid": "Карточка уже выплачена"
        },
        "cashWithdrawalForm": {
            "status0": "Ожидание",
            "status1": "Одобрено",
            "status2": "Оплачено",
            "status3": "Отклонена",
            "status4": "Отменена пользователем",
            "status5": "Неизвестно",
            "caption": "Снятие со счёта",
            "payCode": "Код выплаты:",
            "checkWithdrawalButton": "Проверить код выплаты",
            "withdrawalCaption": "Спецификация заявки",
            "withdrawalNum": "Номер заявки:",
            "amount": "Сумма:",
            "crdate": "Создана:",
            "expdate": "Действительно до:",
            "cashbox": "Касса:",
            "status": "Статус заявки:",
            "document": "Предъявленный документ",
            "documentType": "Тип документа",
            "documentTypes": {
                "idcard": "ID ",
                "passport": "Паспорт",
                "driver": "Водительские права"
            },
            "docNumber": "Номер предъявленного документа:",
            "makeWithdrawalButton": "Проверить выплату",
            "personalData": "Персональные данные",
            "userNumber": "Персональный код:",
            "firstName": "Имя:",
            "lastName": "Фамилия:",
            "birthday": "Дата рождения:",
            "userDocuments": "Документы пользователя:",
            "verify": "Верификация пользователя:",
            "confirmUserButton": "Проверить данные",
            "print": {
                "caption": "Снятие со счета",
                "sum": "Сумма:",
                "account": "Номер счета"
            },
            "notFound": "Заказ не найден"
        },
        "saleVoucherForm": {
            "caption": "Ваучеры - продажа",
            "amount": "Сумма:",
            "saleButton": "Оплатить",
            "table": {
                "time": "Время",
                "num": "Номер",
                "amount": "Сумма"
            },
            "print": {
                "time": "Время операции:",
                "voucher": "Ваучер №:",
                "amount": "Сумма выплаты:",
                "until": "Действителен до:"
            }
        },
        "buyVoucherForm": {
            "caption": "Ваучеры - покупка",
            "voucher": "Ваучер №:",
            "buyButton": "Покупка",
            "success": "Выполнено успешно, сумма {amount}"
        },
        "menu": {
            "monitoring": "Мониторинг",
            "payments": "Выплаты",
            "vouchers": "Ваучеры",
            "account": "Игровой счет",
            "finance": "Финансы",
            "service": "Сервис",
            "sport": "СПОРТ",
            "live": "LIVE",
            "state": "Не выплаченные",
            "payment": "Выплата выигрыша",
            "withdrawal": "Снятие со счета",
            "refill": "Пополнение счета",
            "userFind": "Поиск пользователя",
            "profile": "Параметры регистрации",
            "settings": "Параметры",
            "salevoucher": "Продажа",
            "buyvoucher": "Покупка",
            "dailyreport": "Отчет за день",
            "finday": "Финансовый день",
            "betplaces": "Точки",
            "express": "Экспресс счет"
        },
        "monitoring": {
            "table": {
                "id": "Id",
                "time": "Время",
                "position": "Объект/позиция",
                "quotation": "Источник/котировка",
                "coef": "Кф",
                "stake": "Сумма",
                "status": "Статус",
                "pay": "Выплата",
                "income": "Доход",
                "print": "Печать",
                "number": "#",
                "iscancel": "Отменена",
                "isaccepted": "Принята",
                "iscalculated": "Рассчитана",
                "ispaid": "Выплачена",
                "object": "Вн № {innerCardId} Код выплаты: {payCode}",
                "containerType1": "Ординар",
                "containerType3": "Система (из {itemCount} по {express})",
                "containerType2": "Экспресс",
                "itemStatus0": "В игре",
                "itemStatus1": "Выигрыш",
                "itemStatus2": "Без выигрыша",
                "itemStatus3": "Возврат",
                "itemStatus4": "Неизвестно",
                "itemStatus5": "Отменена",
                "horse": {
                    "statusG": "Выигрыш",
                    "statusJ": "В игре",
                    "statusP": "Без выигрыша",
                    "statusD": "Возврат",
                    "statusA": "Void"
                }
            },
            "operations": {
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Пополнение игрового счета",
                "PAY_OPERATION_WITHDRAWAL_CASH": "Снятие с игрового счета",
                "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Продажа ваучера",
                "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Покупка ваучера",
                "PAY_OPERATION_VOUCHER_GENERATION_BP": "Продажа ваучера на Betplace",
                "PAY_OPERATION_INCREASE_BALANCE_BP": "Пополнение баланса Betplace",
                "PAY_OPERATION_WINPAID": "Выплата выигрыша",
                "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Выплата по карточке Скачки BP",
                "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Выплата по карточке Скачки",
                "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Обнуление баланса Betplace",
                "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT": "Партнерское пополнение",
                "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWA": "Вывод средств через партнера",
                "PAY_OPERATION_INCREASE_ACCOUNT": "Пополнение счета",
                "PAY_OPERATION_WITHDRAWAL": "Снятие со счета ",
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT": "Прямое пополнение",
                "PAY_OPERATION_DEPOSIT_BONUS_REFILL": "Бонус",
                "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Пополнение бонусного счета (Спорт)",
                "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Пополнение бонусного счета (Казино)",
                "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Отмена бонуса (Cпорт)",
                "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Отмена бонуса (Казино)",
                "PAY_OPERATION_CANCEL_STAKE_BP": "Отмена ставки Betplace",
                "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT": "Зачисление с бонусного счета (Спорт)",
                "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT": "Зачисление с бонусного счета (Казино)",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL": "Перевод на основной счет",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT": "Перевод на игровой счет",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": "Перевод на основной счет",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": "Перевод на игровой счет",
                "PAY_OPERATION_FOR_RACE_WINPAID": "Выигрыш на Забегах",
                "PAY_OPERATION_FOR_RACE_CARD": "Ставка на Забеги"
            },
            "filter": {
                "cashier": "Cashier",
                "allbp": "Все БП"
            },
            "search": {
                "caption": "Поиск",
                "inputLabel": "Введите номер"
            },
            "copyError": "Невозможно скопировать карточку",
            "deleteComfirm": "Вы действительно хотите удалить ставку #{innerCardId}?",
            "deleteSuccess": "Ставка удалена успешно"
        },
        "cardsState": {
            "innerCardId": "Номер",
            "cardFilter": "Карточка:",
            "sumPay": "Сумма",
            "status": "Статус",
            "payCode": "Код выплаты: ",
            "caption": "Не выплаченные",
            "unpaidSum": "Невыплаченная сумма: {sumPay}",
            "unpaidRadio": "Непроплаченные выигрыши",
            "paidRadio": "Выплаченные выигрыши",
            "loseRadio": "Проигранные ставки",
            "allRadio": "Рассчитанные ставки",
            "footer": "Карточка:",
            "unpaid": "не выплачена",
            "empty": "Нет неоплаченных карточек."
        },
        "updateProfile": {
            "success": "Редактирование профиля выполнено корректно"
        },
        "activeBetplaces": {
            "deposit": "Пополнить счет",
            "depositDialog": {
                "caption": "Пополнение счета"
            },
            "depositsuccess": "Счет успешно пополнен",
            "depositsuccesscaption": "Пополнение счета",
            "nullify": "Обнулить счет",
            "nullifyConfirm": "Вы действительно хотите обнулить баланс бетплейса {betplace}?",
            "nullifysuccess": "Баланс обнулен",
            "nullifysuccesscaption": "Обнуление баланса",
            "sum": "Баланс"
        },
        "forms": {
            "print": "Печать"
        }
    },
    "errors": {
        "mtl": {
            "serverError": "Непредвиденная ошибка сервера",
            "status": {
                "code201": "На сервер был отправлен некорректный запрос",
                "code202": "Ошибка определения запроса на сервере",
                "code203": "Ошибка разбора запроса на сервере: запрос не соответствует протоколу",
                "code101": "Обработка запроса сейчас невозможна. Попробуйте чуть позже",
                "code102": "Ошибка обработки запроса на сервере",
                "code401": "В запросе не указан код точки",
                "code402": "Точка не активирована",
                "code403": "Точка была заблокирована"
            },
            "aUs11": {
                "code1103": "Минимальная длина пароля 8 символов",
                "code1104": "Минимальная длина логина 8 символов",
                "code1106": "Пользователь с таким e-mail уже существует",
                "code1107": "Пользователь с таким login уже существует",
                "code1113": "Указанный номер документа уже использован",
                "code1114": "Реферальный код не корректный или отсутствует в базе",
                "code1115": "Пользователь с таким телефоном уже существует",
                "code1118": "Неверно указан номер телефона"
            },
            "aUs111": {
                "code1191": "Пользователь сеанса не найден"
            },
            "aUs16": {
                "code1172": "Некорректно указан текущий пароль",
                "code1173": "Минимальная длина пароля 8 символов"
            },
            "aUs17": {
                "code1181": "Текущий пользователь не установлен в сессии",
                "code1182": "Некорректно указан электронный адрес",
                "code1183": "Электронный адрес не существует",
                "code1184": "Некорректно указан номер телефона"
            },
            "acn11": {
                "code1051": "Код подтверждения не найден",
                "code1052": "Код подтверждения уже использован",
                "code1053": "Возможное время на подтверждение операции истекло"
            },
            "aUs12": {
                "code1121": "Не определен номер счёта!",
                "code1122": "Неверно указаны номер пользователя и пароль",
                "code1123": "Пользователь заблокирован",
                "code1124": "Пользователь не может выполнять вход с указанного рабочего места",
                "code1125": "Отсутствует валюта счета",
                "code1161": "Некорректный адрес электронной почты или телефон",
                "code1126": "У пользователя несколько счетов",
                "code1162": "Пользователь не активирован"
            },
            "aUs22": {
                "code1703": "Телефон уже зарегистрирован",
                "code1106": "Пользователь с таким e-mail уже существует",
                "code1107": "Пользователь с таким login уже существует",
                "code1113": "Указанный персональный код пользователя уже использован",
                "code1114": "Реферальный код не корректный или отсутствует в базе",
                "code1115": "Пользователь с таким телефоном уже существует"
            },
            "aPi55": {
                "code2601": "Балансы не совпадают",
                "code2604": "Несоответствие Валюты"
            },
            "aUs25": {
                "code1121": "Текущий пользователь не имеет прав для редактирования информации"
            },
            "aPi54": {
                "code1001": "Пользователь не авторизирован"
            },
            "qSt100": {
                "code1311": "указанный в фильтре объект не существует",
                "code1312": "не существует карточек соответствующих указанному фильтру",
                "code1313": "не установлен пользователь в сессии",
                "code1314": "у пользователя нет прав для запроса списка ставок"
            },
            "aSt13": {
                "code1221": "Карточка не найдена в базе данных",
                "code1222": "Превышено время отведенное на отмену карточки"
            },
            "aSt22": {
                "code1281": "Карточка не найдена",
                "code1282": "Карточка уже оплачена",
                "code1283": "Карточка не имеет выигрыша",
                "code1284": "Ошибка операции оплаты"
            },
            "aSt25": {
                "code1381": "Карточка не найдена",
                "code1382": "Карточка уже оплачена",
                "code1383": "Карточка уже оплачена",
                "code1384": "Карточка не имеет выигрыша"
            },
            "qSt106": {
                "code1411": "Карточка не найдена"
            },
            "qSt107": {
                "code1421": "Карточка не найдена"
            },
            "qUs22": {
                "code1191": "Нет прав для запроса данной информации",
                "code1192": "Запрос не вернул результатов"
            },
            "qRs12": {
                "code1411": "Cupom não foi encontrado"
            },
            "aPo51": {
                "code5108": "Ошибка создания ордера, проверьте данные",
                "code5109": "Платёжная система недоступна. Для пополнения счета выберите другой способ или свяжитесь с нами."
            },
            "aPo510": {
                "code4301": "Общая ошибка пополнения",
                "code4303": "Приложение не имеет прав для проведения операции",
                "code4304": "У пользователя нет прав для проведения операции",
                "code4305": "Пользователь с указанными реквизитами не существует",
                "code4306": "У пользователя не открыт счёт с указанной валютой",
                "code4307": "Пользователь заблокирован",
                "code4309": "Для этого пользователя бонус недоступен",
                "code4310": "Несовпадение партнеров",
                "code4308": "Не авторизированная операция, обратитесь в службу поддержки",
                "code4314": "Телефон пользователя уже существует",
                "code4404": "Несоответствие валюты"
            },
            "aPo513": {
                "code4403": "Недостаточное сальдо для проведения операции",
                "code4404": "Не совпадает валюта"
            },
            "aUs14": {
                "code1151": "Не удалось изменить данные пользователя",
                "code1152": "Пользователь не найден",
                "code1153": "Логин должен иметь 5-ти символов и более",
                "code1154": "Пользователь с таким login уже существует",
                "code1155": "Не указана фамилия",
                "code1156": "Не указано имя",
                "code1157": "Некорректно указана дата рождения",
                "code1158": "Указанный ID пользователя уже существует "
            },
            "aVc14": {
                "code4811": "Отсутствуют права доступа",
                "code2302": "ВАУЧЕР УЖЕ ИСПОЛЬЗОВАН",
                "code2303": "Несовпадение партнеров",
                "code2305": "Несовпадение партнеров",
                "code2308": "Не совпадает валюта"
            },
            "aVc11": {
                "code1": "Неавторизованная сессия",
                "code4801": "Отсутствуют права доступа",
                "code2202": "Не верно указана сумма",
                "code2201": "Сумма операции превышает баланс пользователя",
                "code2203": "Не верная валюта"
            },
            "aPo67": {
                "code4441": "У пользователя нет прав для проведения операции",
                "code4442": "У пользователя нет прав для проведения операции",
                "code4443": "Указанная заявка не найдена",
                "code4444": "Статус заявки не позволяет провести операцию",
                "code4445": "Не совпадает заявленный расчётный пункт",
                "code4446": "Общая ошибка снятия",
                "code4447": "Превышено количество снятий на кассе в день",
                "code4463": "Указанный запрос не найден",
                "code4464": "Заявка выплачена",
                "code4466": "ОПЕРАЦИЯ ПО СНЯТИЮ СО СЧЕТА ЗАПРЕЩЕНА"
            },
            "aVc21": {
                "code5103": "Неверная сумма",
                "code2302": "Ваучер уже использован",
                "code4316": "Не верная валюта"
            },
            "aGe21": {
                "code1010": "Регистрация выполнена успешно, программа будет закрыта. Пожалуйста, откройте программу после ручной активация точки",
                "code1011": "Расчётный пункт по коду не найден",
                "code1012": "Некорректно указан название расчётного пункта",
                "code1013": "Некорректно указан почтовый адрес",
                "code1014": "Не указана страна",
                "code1015": "Не указан адрес",
                "code1016": "Партнёр не определён ",
                "code1017": "Код расчётного пункта уже определён"
            },
            "aGe22": {
                "code1021": "Расчётный пункт заблокирован",
                "code1131": "Ошибка регистрации сессии"
            },
            "aVc18": {
                "code1001": "Пользователь не авторизирован",
                "code2301": "Ваучер не найден",
                "code2302": "Ваучер использован",
                "code2305": "Отсутствуют права на погашение ваучера",
                "code2307": "Несовпадение партнеров",
                "code4316": "Несовпадение валют",
                "code4301": "Общая ошибка пополнения",
                "code4307": "Пользователь заблокирован",
                "code4310": "несовпадение партнеров"
            },
            "aVc22": {
                "code1211": "Недостаточно денег на балансе",
                "code1214": "ОПЕРАЦИЯ НЕ РАЗРЕШЕНА ДЛЯ ДАННОГО ТИПА ТОЧКИ"
            },
            "aPo512": {
                "code4301": "Приложение не имеет прав для проведения операции",
                "code4302": "У пользователя нет прав для проведения операции",
                "code4303": "Индификационный код уже используется",
                "code4304": "Телефон уже используется",
                "code4305": "Неверная сумма депозита",
                "code4313": "Номер документа уже используется",
                "code4306": "Неверный код валюты",
                "code4311": "Не авторизированная операция, обратитесь в службу поддержки",
                "code4314": "Пользователь с таким телефоном уже существует",
                "code4315": "Не авторизированная операция, обратитесь в службу поддержки"
            },
            "aPo68": {
                "code4451": "Приложение не имеет прав для проведения операции",
                "code4452": "Недостаточно прав для проведения операции",
                "code4453": "Не открыт счёт с указанной валютой",
                "code4454": "Запрашиваемая сумма превышает имеющуюся",
                "code4455": "Не установлен контакт для отправки подтверждения",
                "code4456": "Нет прав для использования указанного метода подтверждения операции"
            },
            "aPo617": {
                "code4443": "СНЯТИЕ СРЕДСТВ НЕ РАЗРЕШЕНО ДЛЯ ПОЛЬЗОВАТЕЛЯ",
                "code4445": "Запрашиваемая сумма превышает имеющуюся"
            },
            "aCs11": {
                "code8101": "Сессия не авторизована",
                "code8132": "Игра недоступна",
                "code8136": "Для активации приложения пополните баланс"
            },
            "aCs12": {
                "code8101": "Сессия не авторизована",
                "code8121": "Сессия не авторизована"
            },
            "aCs27": {
                "code8125": "На основном счету недостаточно средств для осуществления перевода!"
            },
            "aCs28": {
                "code8135": "На счету казино недостаточно средств для осуществления перевода!"
            },
            "aCn11": {
                "code1051": "Подтверждение не найдено"
            },
            "aBn11": {
                "code4231": "Операция не разрешена для неактивного сеанса",
                "code4232": "Операция не разрешена для экспресс-пользователей",
                "code4233": "Бонус не разрешен без депозита",
                "code4236": "Бонус не выходит на депозит",
                "code4234": "Cумма депозита не соответствует Правилам получения бонуса.",
                "code4240": "Бонус не разрешен после ставки",
                "code4238": "Приветственный бонус должен быть первым",
                "code4235": "Бонус уже активирован",
                "code4237": "Одновременно может быть активен только один бонус",
                "code4241": "Cумма депозита не соответствует Правилам получения бонуса."
            }
        },
        "betSlip": {
            "duplicateEventWarning": "Ставки на одно и то же событие в экспрессы и системы не принимаются!",
            "exceedsMaxStakeAmount": "Сумма ставки больше максимальной",
            "lessMinStakeAmount": "Сумма ставки меньше минимальной",
            "coefWasChanged": "Коэффициент изменился",
            "quotationBlocked": "Прием ставок приостановлен",
            "positionBlocked": "Прием ставок временно приостановлен",
            "eventFullTime": "Ставки на это событие закрыты - матч окончен",
            "eventStarted": "Ставки на это событие закрыты",
            "betSlipEmpty": "Карточка пустая",
            "betSlipInvalidItems": "Карточка содержит ошибки",
            "response": {
                "registrationSystemError": "Системная ошибка регистрации",
                "incorrectNumberOfPositions": "Системная ошибка регистрации комбинированной ставки",
                "notEnoughMoneyOnCenterBalance": "Недостаточно денег на счету",
                "notEnoughMoneyOnUserBalance": "Недостаточно денег на счету",
                "userDontHaveOpenAccount": "Для принятия ставки необходимо сначала зайти в свой аккаунт или зарегистрироваться",
                "positionLiveServiceNotAvailable": "В настоящее время прием ставок Live на это событие приостановлен",
                "coefIsNotAllowedForBonus": "Коэффициент ниже допустимого"
            }
        },
        "games": {
            "errorStartingGame": "Игра недоступна"
        }
    },
    "webdesktop": {
        "pageTitle": {
            "default": {
                "title": "{brand} - официальный сайт: казино, ставки на спорт, лайв игры",
                "description": "Официальный сайт компании {brand} ✅Букмеккерская компания ☝️Онлайн Казино 🎰 Лайв игры. Получите  при регистрации до $300"
            },
            "sport": {
                "title": "{sport} - {brand} Букмекерская контора: делайте ставки на {sport} с уверенностью!",
                "description": "Делайте ставки на {sport} с уверенностью в букмекерской конторе {brand}! ☝Быстрая регистрация и легкий доступ ☝☝Выбирайте из широкого спектра спортивных ставок ☝ Получайте бесплатные ставки, промокоды и эксклюзивные бонусы"
            },
            "live": {
                "title": "{sport} в букмекерской конторе {brand}: делайте ставки Live на {sport} и выигрывайте по-крупному!",
                "description": "Готовы делать ставки Live на {sport}? Букмекерская контора {brand} поможет вам! ☝Быстрая регистрация и легкий доступ ☝ Получайте бесплатные ставки, промокоды и специальные бонусы ☝ Мы предлагаем разнообразные виды спорта"
            },
            "casino": {
                "title": "{brand} Онлайн-казино: играйте и выигрывайте реальные деньги!",
                "description": "Окунитесь в мир развлечений онлайн-казино с {brand}! ☝Присоединяйтесь сейчас, чтобы упростить регистрацию и получить доступ к широкому спектру игровых автоматов. ☝ Получите захватывающие бонусы и бесплатные спины до $300 ☝"
            },
            "virtual": {
                "title": "{brand} Онлайн-казино: играйте и выигрывайте реальные деньги!",
                "description": "Окунитесь в мир развлечений онлайн-казино с {brand}! ☝Присоединяйтесь сейчас, чтобы упростить регистрацию и получить доступ к широкому спектру игровых автоматов. ☝ Получите захватывающие бонусы и бесплатные спины до $300 ☝"
            },
            "registration": {
                "title": "{brand} Зарегистрируйся и Выбери Свой Welcome Bonus!",
                "description": "Официальный сайт компании {brand} ✅Букмеккерская компания ☝️Онлайн Казино 🎰 Лайв игры. Получите  при регистрации до $300"
            }
        },
        "race": {
            "bonusActive": "Ставки на Ricing недоступны для игры с бонусного счета"
        },
        "idle": {
            "message": "Сеанс завершен из-за неактивности."
        },
        "verification": {
            "emailCaption": "Верификация E-MAIL",
            "phoneCaption": "Верификация номера телефона",
            "caption": "Проверка безопасности",
            "text": "Чтобы обезопасить свой счет, пройдите следующую проверку",
            "phone": "Код подтверждения номера телефона",
            "email": "Код проверки электронной почты",
            "placeholder": "Введите 8-значный код, отправленный на ",
            "phoneVerified": "Ваш номер телефона успешно проверен",
            "emailVerified": "Ваша электронная почта успешно проверена",
            "getCode": "Получить код",
            "sent": "Проверочный код отправлен на "
        },
        "accountSwitch": {
            "sport": {
                "primary": "Вы переключились на основной счет Спорт",
                "bonus": "Вы переключились на бонусный счет Спорт"
            },
            "casino": {
                "primary": "Вы переключились на основной счет Казино",
                "bonus": "Вы переключились на бонусный счет Казино"
            }
        },
        "casino": {
            "balance": "Счет казино",
            "transferFromCasino": "Перевод со счета казино:",
            "transferToCasino": "Перевод на счет казино:",
            "depositSuccess": "Переаод с основного счета произведен успешно!",
            "withdrawalSuccess": "Переаод со счета казино произведен успешно!"
        },
        "preferences": {
            "languages": "Языки",
            "quotations": {
                "caption": "Формат котировок",
                "decimal": "Дес.",
                "american": "Амер.",
                "english": "Дробн."
            },
            "theme": {
                "caption": "Тема",
                "light": "Светлая",
                "dark": "Темная"
            },
            "view": "Вид"
        },
        "registration": {
            "success": "Спасибо за регистрацию",
            "oneclick": {
                "tabName": "Один Клик",
                "confirm": {
                    "title": "СПАСИБО ЗА РЕГИСТРАЦИЮ",
                    "subtitle1": "Пожалуйста, ",
                    "subtitle2": "!запишите свой логин и пароль!",
                    "login": "Логин",
                    "password": "Пароль",
                    "copy": "Копировать",
                    "savetofile": "Сохранить в файл",
                    "savetoimage": "Сохранить как картинку",
                    "emailplaceholder": "E-mail",
                    "emailbutton": "Отправить на e-mail",
                    "loginCopied": "Логин скопирован",
                    "passwordCopied": "Пароль скопирован"
                }
            },
            "phone": {
                "tabName": "По телефону",
                "sendSmsButton": "Отправить SMS",
                "confirmCode": "Код подтверждения",
                "confirmButton": "Подтвердить"
            },
            "email": {
                "tabName": "Через E-mail",
                "emailLabel": "E-mail",
                "countryLabel": "Страна",
                "phoneLabel": "Номер телефона",
                "firstNameLabel": "Имя",
                "lastNameLabel": "Фамилия",
                "birthdayLabel": "Дата рождения",
                "passwordLabel": "Пароль",
                "passwordConfirmLabel": "Повтор пароля",
                "promoLabel": "Бонус",
                "registerLabel": "Зарегистрироваться",
                "promoPlaceholder": "Введите промо код",
                "documentLabel": "Документ",
                "loginLabel": "Логин",
                "cityLabel": "Город",
                "policy1": "Этот сайт защищен reCAPTCHA и ",
                "policy2": " Политика конфиденциальности ",
                "policy3": " от Google и применяются ",
                "policy4": "Условия и положения",
                "policy6": "Нажимая эту кнопку, вы подтверждаете, что прочитали и принимаете ",
                "policy7": "Условия и положения",
                "policy8": "и",
                "policy9": " Политика конфиденциальности ",
                "policy10": "компании и подтверждаете, что вы достигли совершеннолетия."
            }
        },
        "emailConfirm": {
            "title": "!Ваш счет активирован!",
            "userNumber": "Ваш номер счета:",
            "userLogin": "Имя пользователя:",
            "ref1": "Для того, чтобы делать ставки, вам необходимо",
            "ref2": "внести депозит на свой счет"
        },
        "withdrawalConfirm": {
            "title": "Платежный запрос №",
            "total": "Всего к оплате: ",
            "withdrawalType": "Тип выплаты: ",
            "payCode": "Код выплаты: ",
            "expiration": "Оплата этого запроса может быть произведена до: ",
            "WITHDRAWAL_METHOD_VOUCHER": "Агенты {brandName}",
            "WITHDRAWAL_METHOD_VISA": "Visa/MasterCard",
            "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT": "Расчетный счет"
        },
        "loginDialog": {
            "menuButton": "Войти",
            "emailPlaceholder": "ID, ЛОГИН или E-MAIL",
            "passwordPlaceholder": "Пароль",
            "save": "Запомнить",
            "forgetPassword": "Забыли свой пароль?",
            "loginButton": "Войти",
            "social": "Вы можете получить доступ к веб-сайту через:",
            "no-register": "Еще не зарегистрированы?"
        },
        "confirmCodeDialog": {
            "success": "На ваш контактный адрес, указанный при регистрации, отправлен новый код!",
            "caption": "Необходимо подтвердить операцию!",
            "text": "Введите код, отправленный на ваш контактный адрес, указанный при регистрации, в следующее поле:",
            "inputLabel": "Код подтверждения",
            "submitButton": "Проверить"
        },
        "resetPasswordDialog": {
            "caption": "Восстановить пароль",
            "emailTabCaption": "По электронной почте",
            "phoneTabCaption": "По телефону",
            "submitButton": "Создать временный пароль",
            "emailPlaceholder": "Введите свой e-mail",
            "emailTabText1": "Для восстановления пароля введите адрес электронной почты, указанный вами при регистрации.",
            "emailTabText2": "Мы отправим вам электронное письмо с дальнейшими инструкциями.",
            "phoneTabText1": "Для восстановления пароля введите номер телефона, который вы указали при регистрации.",
            "phoneTabText2": "Мы отправим вам SMS с дальнейшими инструкциями.",
            "success": "Вам отправлен временный пароль"
        },
        "changePasswordDialog": {
            "caption": "Смена пароля",
            "currentPasswordLabel": "Текущий пароль",
            "newPasswordLabel": "Новый пароль",
            "newPasswordConfirmLabel": "Подтвердить новый пароль",
            "submitButton": "Сменить пароль",
            "successMessage": "Пароль успешно изменен"
        },
        "expressChangePasswordDialog": {
            "caption": "Добро пожаловать",
            "text1": "В целях безопасности вашей учетной записи мы рекомендуем сменить пароль прямо сейчас!",
            "submitButton": "Смените пароль сейчас",
            "closeButton": "Изменить позже"
        },
        "userMenu": {
            "personalData": "Персональные данные",
            "settings": "Настройки аккаунта",
            "bids": "Ставки в игре",
            "allbids": "Все ставки",
            "finance": "Финансы",
            "stakes": "Мои ставки",
            "bonuses": "Бонусы",
            "logout": "Закрыть сессию"
        },
        "changePhoneDialog": {
            "caption": "Изменить номер телефона",
            "phone": "Номер телефона",
            "submitButton": "Ок",
            "success": "Ваш номер телефона успешно изменен"
        },
        "changeEmailDialog": {
            "caption": "Новый e-mail",
            "email": "Введите адрес электронной почты:",
            "success": "Ваш адрес электронной почты был успешно изменен"
        },
        "profileView": {
            "title": "ЛИЧНАЯ ИНФОРМАЦИЯ",
            "subtitle": "Заполните пустые поля, чтобы использовать функциональные возможности сайта!",
            "titleLine": "ДАННЫЕ УЧЕТНОЙ ЗАПИСИ",
            "accountNumber": "Номер счета",
            "passwordLabel": "Ваш пароль",
            "registrationDate": "Дата регистрации",
            "information": "Контактная информация",
            "submitButton": "Сохранить",
            "success": "Данные сохранены успешно"
        },
        "routes": {
            "inPlay": "Ставки в игре",
            "account": "Счет",
            "allStakes": "Все ставки",
            "winstakes": "Результативные",
            "finances": "Финансы",
            "raceStakes": "Скачки",
            "withdrawalOrders": "Снятия",
            "depositOperations": "Пополнения",
            "bonusOperations": "Бонусы"
        },
        "withdrawalOrders": {
            "dateColumn": "Дата",
            "amount": "Сумма",
            "method": "Тип снятия",
            "status": "Статус",
            "cancel": "Отмена",
            "wt": {
                "WITHDRAWAL_METHOD_VOUCHER": "Voucher",
                "WITHDRAWAL_METHOD_VOUCHER_INSTANT": "Voucher Express",
                "WITHDRAWAL_METHOD_VISA": "Visa",
                "WITHDRAWAL_METHOD_LUKITA": "Lukita",
                "WITHDRAWAL_METHOD_YAPE": "Yape",
                "WITHDRAWAL_METHOD_TUNKI": "Tunki"
            },
            "statusText": {
                "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION": "Требуется подтвердить заявку с помощью секретного кода в течение 24 часов",
                "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED": "Заявка на выплату не была подтверждена пользователем в течение 24 часов",
                "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE": "Заявка на выплату ожидает рассмотрения",
                "ORDER_WITHDRAWAL_STATUS_APPROVED": "Заявка одобрена администрацией и готовится к выплате",
                "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED": "Заявка одобрена и будет передана на выплату после подтверждения личности. Требуется предоставить документ, удостоверяющий личность. Свяжитесь с поддержкой для получения подробной информации",
                "ORDER_WITHDRAWAL_STATUS_PAID": "Заявка передана на оплату",
                "ORDER_WITHDRAWAL_STATUS_CASH_WAITING": "Заявка ожидает выплаты клиенту на кассе в течение 3 суток.",
                "ORDER_WITHDRAWAL_STATUS_CASH_PAID": "Заявка выплачена на кассе",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED": "Выплата не была востребована клиентом на протяжении 3 суток и была аннулирована",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR": "Выплата невозможна. Свяжитесь с поддержкой для получения подробной информации",
                "ORDER_WITHDRAWAL_STATUS_DENIED": "В выплате отказано. Заявка аннулирована. Для получения подробной информации свяжитесь с поддержкой",
                "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": "Заявка на выплату была отменена клиентом"
            },
            "confirm": {
                "caption": "Отмена платежной операции",
                "text": "Вы действительно хотите отменить платежную операцию?"
            },
            "cancelSuccess": "Платежная операция успешно отменена"
        },
        "cardsTable": {
            "dateColumn": "Дата, время",
            "eventColumn": "Событие/результат",
            "quotationColumn": "Коэффициент",
            "amountColumn": "Ставка",
            "winColumn": "Выплаты",
            "infoColumn": "Информация",
            "bonus": "бонусный счет"
        },
        "inPlay": {
            "cardsTotal": "Ставки в игре:",
            "amount": "Всего:"
        },
        "allStakes": {
            "beginDate": "Период с:",
            "endDate": "По:",
            "itemsPerPage": "Строк на странице:",
            "dateFilter": "Фильтр по дате"
        },
        "operations": {
            "operationColumn": "Операция",
            "amountColumn": "Сумма"
        },
        "deposit": {
            "amountInput": {
                "caption": "Пополнение счета {type}",
                "amount": "Сумма: ",
                "currency": "Валюта: ",
                "dialogText": "Проверьте данные и подтвердите операцию: вы запросили платеж на сумму {amount}{currency}",
                "min": "Минимальная сумма пополнения составляет {amount}{currency}."
            },
            "monnetCard": {
                "description": "Управление депозитами с использованием карт Visa или Mastercard осуществляется через платформу Monnet. В зависимости от обстоятельств эта организация имеет право одобрять или отклонять транзакции в соответствии со своей внутренней политикой. Для осуществления депозитов с использованием карт Visa или Mastercard необходимо быть держателем карты."
            },
            "tupay": {
                "description": "С Tupay у вас все под контролем! Делайте покупки онлайн и выбирайте, как платить. Будь то наличные, банковский перевод, кредитные или дебетовые карты или QR-цифровые кошельки, выбор за вами. Мы делаем ваши онлайн-покупки простыми и гибкими. Откройте для себя свободу платежей с Tupay."
            },
            "DEPOSIT_MONNET_CARD": "MONNET CARD",
            "SAFETYPAY_ONLINE": "SAFETYPAY",
            "SAFETYPAY_CASH": "SAFETYPAY",
            "DEPOSIT_MONNET": "SAFETYPAY ONLINE",
            "DEPOSIT_MONNET_CASH": "SAFETYPAY",
            "DEPOSIT_TUPAY": "TUPAY",
            "PAGOEFECTIVO_INVOICE": "Pago Efectivo",
            "caption": "Способы пополнения",
            "method": "Способ пополнения",
            "time": "Время перевода на счет",
            "comment": "Примечания",
            "yape": "Yape, Lukita, Tunki",
            "zeroCommission": "Комиссия, 0%",
            "refillRef": "Пополнить счет",
            "tunki": "izipayYA",
            "yapeText": "Сделайте свою жизнь проще с Yape и забудьте о наличных! <br> Пополняйте счет быстро, легко и безопасно с помощью Yape.",
            "tunkiText": "С помощью izipayYA вы можете отправлять деньги  по номеру мобильного телефона или QR, расплачиваться в тысячах винных магазинов и филиалов по всей стране, пополнять баланс мобильного телефона и оплачивать услуги БЕСПЛАТНО, БЫСТРО И БЕЗОПАСНО. <br>" +
                            "Пополняйте баланс быстро, легко и безопасно с помощью izipayYA.",
            "safetyPayInternet": "Интернет-банкинг SAFETYPAY",
            "auto": "Автоматически",
            "safetyPayDescription": "Это безопасный и быстрый способ оплаты, позволяющий вносить средства на Ваш счет в {brandName} через Интернет-банк или через агентов и агентства вBCP, BBVA, INTERBANK or SCOTIABANK. <br> Пополнение счета происходит мгновенно и {brandName} не взимает комиссию за использование этого метода.",
            "pagoEfectivo": "PagoEfectivo",
            "pagoSeguro": "PAGO SEGURO",
            "pagoSeguroEfectivo": "Efectivo",
            "immediate": "Немедленно",
            "pagoEfectivoDescription": "Пополнение счета в банках BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa и Banco Pichincha через мобильный банк, QR или через агентов Red Digital, Kasnet, Agente Niubiz, Tambo+, Western Union и Disashop.",
            "agents": "Агенты {brandName}",
            "iPay": "Ticket iPay",
            "iPayDescription": "Платежная система IPAY. В течение банковского дня. Комиссия 0%. Вы можете вывести средства со своего игрового счета через платежную систему iPay. Создайте заказ на оплату через платежную систему iPay и подтвердите его. После подтверждения заказа вы получите секретный код выплаты, и сумма выплаты будет списана с вашего игрового счета. Предъявите Секретный Код представителю системы IPay и после проверки кода, вы получите сумму выплаты наличными. Комиссия за оплату через платежную систему IPay отсутствует.",
            "voucher": {
                "success": "Пополнение на сумму {amount} произведено успешно.",
                "found": "Талон №{code} найден, на сумму {amount}",
                "numberLabel": "Введите код билета:",
                "caption": "Ваучер iPay"
            }
        },
        "withdrawal": {
            "minAmountError": "Минимальная сумма снятия {minAmount}",
            "general": {
                "WITHDRAWAL_METHOD_YAPE": "Вывод на YAPE",
                "WITHDRAWAL_METHOD_LUKITA": "Вывод на LUKITA",
                "WITHDRAWAL_METHOD_TUNKI": "Вывод на TUNKI",
                "phoneNumber": "Номер телефона {phoneNumber} будет использован для снятия"
            },
            "partner": {
                "caption": "Вывод средств со счета",
                "confirmationMethod": "Выберите способ подтверждения:",
                "confirmationEmail": "Электронная почта",
                "confirmationPhone": "Телефон"
            },
            "visaForm": {
                "caption": "Банковский перевод",
                "document": "Документ",
                "email": "Электронная почта",
                "bank": "Название банка:",
                "cardName": "Полное имя:",
                "accountType": "Тип счета:",
                "fullNumber": "Номер счета:",
                "orderedSum": "Количество (минимум {minAmount}):",
                "dni": "ID:"
            },
            "minAmount": "Минимальная сумма",
            "caption": "Способы вывода средств",
            "paymentMethod": "Способ оплаты",
            "commission": "Комиссия",
            "agents": "АГЕНТЫ {brandName}",
            "agentsDescription": "Вы можете вывести средства со своего игрового счета через платежную систему IPAY. Для этого необходимо отправить запрос на выплату через платежную систему IPAY и подтвердить. После подтверждения запроса Вы получаете Секретный платежный код, а сумма платежа будет снята с Вашего игрового счета. Вы должны сообщить свой Секретный код представителю системы IPAY и после проверки кода, вы получите оплату наличными. При оплате через систему IPAY комиссия не взимается.",
            "agentsLink": "Создать платежный запрос через IPAY >>",
            "visaLink": "Сделать запрос",
            "ipay": {
                "description": "Платежная система IPAY. В течение банковского дня. Комиссия 0%. Вы можете вывести средства со своего игрового счета через платежную систему iPay. Создайте заказ на оплату через платежную систему iPay и подтвердите его. После подтверждения заказа вы получите секретный код выплаты, и сумма выплаты будет списана с вашего игрового счета. Предъявите Секретный Код представителю системы IPay и после проверки кода, вы получите сумму выплаты наличными. Комиссия за выплату через платежную систему IPay отсутствует."
            }
        },
        "emailDone": {
            "caption": "АКТИВАЦИЯ АККАУНТА",
            "text": "Чтобы защитить свою учетную запись, подтвердите зарегистрированный адрес электронной почты.\n" +
                "                    <br><br>\n" +
                "                    Вы получите подтверждение по электронной почте в течение следующих 10 минут.\n" +
                "                    Если вы не получили его, проверьте папку со спамом.",
            "codeButton": "Ввести код"
        },
        "homeView": {
            "articles": {
                "sport": "Спорт",
                "live": "Live",
                "casino": "Казино",
                "virtual": "Виртуальные <br> игры",
                "bonuses": "Бонусы"
            }
        },
        "footer": {
            "sport": "Спорт",
            "live": "Live",
            "casino": "Казино",
            "virtual": "Виртуальные игры",
            "bonuses": "Бонусы",
            "privacyPolicy": "Политика конфиденциальности",
            "rules": "Правила",
            "popular": "Популярные ставки",
            "about": {
                "caption": "О нас",
                "terms": "Условия и положения",
                "privacy": "Политика конфиденциальности",
                "aml": "Политика AML/ KYC",
                "resp": "Ответственная игра"
            },
            "bets": "Ставки на спорт",
            "affiliates": "Партнерская программа",
            "rights": "Этот веб-сайт управляется компанией MCC Digital N.V. Идентификационный номер MCC Digital N.V.: 154760 со штаб-квартирой по адресу Zuikertuintjeweg Z/N (Zuikertuin Tower), Кюрасао, представленный корпоративным директором Downtown E-Commerce Company B.V. по игровой лицензии № 8048-JAZ2021-164.<br>\n" +
                "Транзакции обрабатываются компанией Monte Carlos Games Europe Ltd, зарегистрированной под номером 12676271, с головным офисом по адресу: Kemp House 160 City Road, Лондон, Великобритания. В целях обработки платежей через Paysafe Group, включая, помимо прочего, Neteller и Skrill, субъектом обработки транзакций является компания Monte Carlos Games N.V., зарегистрированная в Кюрасао под номером 154760 по адресу Heelsumstraat 51 Curaçao."
        },
        "privacyPolicy": {
            "textContent": "<b><u>Политика конфиденциальности</u></b><br>" +
                "Настоящая политика конфиденциальности опубликована для информирования о принципах обработки персональных данных пользователей веб-сайта www.tubetgol.com.<br>" +
                "<br>" +
                "<b>Общие характеристики</b><br>" +
                "Веб-сайт www.tubetgol.com (далее «веб-сайт») заботится о предоставлении клиенту и пользователю конфиденциальности их персональных данных (далее «Пользователи», «Персональные данные»). Под персональными данными понимается любая информация о пользователе сайта, в том числе имя, адрес, дата рождения, контактные данные, платежные требования и любая другая информация, которую пользователь мог предоставить Компании. Компания постарается гарантировать использование личной информации пользователя в соответствии с международными стандартами защиты данных и конфиденциальности.<br>" +
                "Когда пользователь предоставляет Компании свои персональные данные Когда пользователь на сайте запрашивает информацию о продуктах и ​​услугах Компании, он добровольно предоставляет Компании свои персональные данные. Когда вы регистрируете новую игровую учетную запись, вы должны предоставить Компании свою личную информацию. Если у пользователя уже есть зарегистрированная учетная запись (он является клиентом Компании), он должен использовать свое имя и пароль для доступа к сайту. Файлы cookie могут использоваться для идентификации повторных входов пользователя на веб-сайт. Сервер Компании фиксирует посещаемые пользователем страницы на веб-сайте.<br>" +
                "<b>Использование персональных данных</b><br>" +
                "Компания собирает, хранит и использует персональные данные пользователей в соответствии с действующим законодательством о защите данных для следующих целей: управление игровой учетной записью пользователя, предоставление услуг пользователю, анализ и контроль использования пользователем веб-сайта. и услуг, предоставляемых Компанией, контроль качества услуг пользователям, периодическое информирование о продуктах и ​​услугах, которые могут заинтересовать пользователей (далее «цели»). Информация о действиях пользователей хранится у Компании, является ее собственностью и может быть использована для указанных Целей.<br>" +
                "<b>Открытие личных данных</b><br>" +
                "Персональная информация пользователя может быть передана или обнаружена Компанией ее деловыми партнерами, которые могут совместно с Компанией использовать эту информацию для установленных Целей. Использование персональных данных пользователя в этих случаях будет регулироваться договором в установленной законодательством форме. В случаях, когда это является требованием законодательства или постановлениями судебных органов, персональные данные пользователя могут быть предоставлены соответствующим государственным, административным и исполнительным органам.<br>" +
                "<b>Соглашение об использовании персональных данных</b><br>" +
                "Предоставляя личные данные на веб-сайте и/или регистрируясь на веб-сайте и/или входя в свою учетную запись веб-игры, пользователь добровольно разрешает Компании собирать, обрабатывать и получать доступ к своей личной информации для целей, указанных выше. Если вы, как пользователь сайта, желаете каким-либо образом уточнить, изменить или ограничить свою авторизацию, просим сообщить об этом Компании, используя контактную информацию, указанную на сайте."
        },
        "rules": {
            "generalTabName": "Общее",
            "specialTabName": "Особые случаи",
            "stakeTypesTabName": "Типы ставок",
            "financeTabName": "Финансовые операции",
            "resultsTabName": "Результаты"
        },
        "bonus": {
            "playGame": "Играть",
            "saldo": "Бонусный баланс",
            "rest": "Вы оставили делать ставки",
            "amount": "Сумма ставки",
            "useBonusButton": "Использовать бонус",
            "cancelBonusButton": "Отменить бонус",
            "terms": "Положения и условия",
            "init": "Активация бонуса",
            "endTime": "Действителен до",
            "cancelled": "Отмена бонуса",
            "expired": "Срок действия бонуса истек",
            "winning": "Зачисление с бонусного счета",
            "cancelBonusConfirmCaption": "Внимание!",
            "cancelBonusConfirmMessage": "Все бонусные средства будут обнулены"
        },
        "results": {
            "goals": {
                "goal1": "Первый гол: {participantName} на {time} min.",
                "goal2": "Второй гол: {participantName} на {time} min.",
                "goal3": "Третий гол: {participantName} на {time} min.",
                "goal4": "Четвертый гол: {participantName} на {time} min.",
                "goal5": "Пятый гол: {participantName} на {time} min.",
                "goal6": "Шестой гол: {participantName} на {time} min.",
                "goal7": "Седьмой гол: {participantName} на {time} min.",
                "goal8": "Восьмой гол: {participantName} на {time} min.",
                "goal9": "Девятый гол: {participantName} на {time} min.",
                "goal10": "Десятый гол: {participantName} на {time} min.",
                "goal11": "Одинадцатый гол: {participantName} на {time} min.",
                "goal12": "Двенадцатый гол: {participantName} на {time} min."
            },
            "set": "сет: {set} гейм: {game} победа {participant}",
            "caption": "РЕЗУЛЬТАТЫ",
            "dateFilter": "Дата",
            "all": "Все",
            "today": "Сегодня",
            "yesterday": "Вчера",
            "select": "Выбрать",
            "calendar": "Календарь",
            "invalidDate": "Неправильная дата"
        },
        "copyright": "© 2019-2020 Компания"
    },
    "mobile": {
        "profileView": {
            "profileTab": "Ваши данные",
            "settingsTab": "Настройки",
            "loginLabel": "Логин"
        },
        "bottomBar": {
            "menuButton": "Меню"
        },
        "menuBar": {
            "installApplication": "Установить приложение",
            "bets": "История ставок"
        },
        "navigationView": {
            "depositButton": "Депозит",
            "withdrawalButton": "Вывод",
            "stakesButton": "Ставки на спорт",
            "casinoButton": "Ставки на казино",
            "historyButton": "История",
            "raceButton": "Ставки гонки",
            "financesButton": "Финансы",
            "bonusButton": "Бонус",
            "sportBalance": "Баланс:",
            "sportBalanceAvailable": "Доступный для вывода:"
        },
        "homeView": {
            "sport": {
                "caption": "Ставки на спорт",
                "sportButton": "Открыть спорт >>",
                "prematch": "Прематч",
                "live": "Live",
                "soccer": "Футбол",
                "basketball": "Баскетбол",
                "baseball": "Бейсбол",
                "boxing": "Бокс",
                "rugby": "Рэгби",
                "tennis": "Теннис",
                "volleyball": "Волейбол",
                "allMatches": "Все Матчи"
            },
            "casino": {
                "caption": "Казино",
                "casinoButton": "Открыть казино >>"
            },
            "virtual": {
                "caption": "Виртуальные игры",
                "virtualButton": "Открыть виртуальные игры"
            },
            "horse": {
                "caption": "Скачки",
                "horseButton": "Перейти на Скачки",
                "game1": "Лошади",
                "game2": "Собаки",
                "game3": "Забеги"
            },
            "bonus": "Welcome Bonus",
            "allGames": "Все игры"
        },
        "depositView": {
            "min": "Мин. сумма:",
            "max": "Макс. сумма:",
            "depositButton": "Депозит"
        },
        "withdrawalView": {
            "withdrawalButton": "Вывод"
        },
        "financesView": {
            "caption": "Мои финансы"
        },
        "stakesView": {
            "caption": "Мои ставки",
            "cardCaption": "N° карт. {innerCardId}",
            "cardStatus": {
                "accepted": "В ожидании",
                "win": "Выигрыш",
                "lost": "Без выигрыша"
            },
            "containerStatusCaption": "Статус"
        },
        "historyView": {
            "caption": "История"
        },
        "routes": {
            "sport": "Спорт",
            "live": "Live",
            "casino": "Казино",
            "coupon": "Купон",
            "operationsHistory": "Операции",
            "sessionsHistory": "Сессии",
            "balance": "Баланс",
            "casinoStakes": "Казино",
            "search": "Поиск",
            "virtual": "Виртуальные",
            "bonus": "Бонус"
        }
    },
    "mcashier": {
        "saleVoucherDialog": {
            "copyButton": "Скопировать номер",
            "printButton": "Печать",
            "copySuccess": "Номер успешно скопирован"
        },
        "cashWithdrawal": {
            "success": "Снятие со счета прошло успешно"
        },
        "menu": {
            "saleVoucher": "Продажа ваучера",
            "buyVoucher": "Покупка ваучера"
        },
        "refillAccount": {
            "success": "Счет успешно пополнен"
        },
        "expressAccount": {
            "success": "Учетная запись успешно создана"
        }
    },
    "landing": {
        "agreement": "Мне больше 18 лет и я подтверждаю, что прочитал и принял условия"
    },
    "bonusNotification": {
        "body": "Пополните счет, чтобы активировать Бонус"
    },
    "bonusRegistration": {
        "sportBetting": {
            "title": "СТАВКИ НА СПОРТ"
        },
        "casino": {
            "title": "КАЗИНО"
        },
        "title": "Зарегистрируйтесь",
        "subtitle": "Выберите свой приветственный бонус",
        "freeSpins": {
            "title": "Free spins"
        },
        "noBonus": {
            "title": "БЕЗ БОНУСА",
            "description": "ИГРАЙТЕ НА<br/> {site}",
            "list": `
                <li>Бонусы можно активировать после регистрации.</li>
                <li>Эта опция не повлияет на вашу регистрацию.</li>
            `
        }
    }
};
