import { Banner, BannerScreen } from "@sportaq/model/common/banner";
import { RootState, RootStore } from "@sportaq/vuex/index";
import { Module, Store } from "vuex";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";

export const bannersModule = "bannersModule";

interface BannersState {
    banners: Banner[];
}

enum Getters {
    GET_BANNERS = "getBanners",
    GET_SECOND_MONITOR_BANNERS = "getSecondMonitorBanners",
    GET_HOME_SCREEN_BANNERS = "getHomeScreenBanners"
}

enum Mutations {
    SET_BANNERS = "setBanners"
}

enum Actions {

}

export const BannersModule: Module<BannersState, RootState> = {
    namespaced: true,
    state: {
        banners: []
    },
    getters: {
        [Getters.GET_BANNERS]: state => state.banners,
        [Getters.GET_SECOND_MONITOR_BANNERS]: state => state.banners.filter(i => i.screenId === BannerScreen.secondMonitor),
        [Getters.GET_HOME_SCREEN_BANNERS]: state => state.banners.filter(i => i.screenId === BannerScreen.homeScreen)
    },
    mutations: {
        [Mutations.SET_BANNERS]: (state: BannersState, payload: Banner[]) => (state.banners = payload)
    }
};

export interface BannersStore {
    banners: Banner[];
    readonly homeScreenBanners: Banner[];
    readonly secondMonitorBanners: Banner[]
}

class BannersStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements BannersStore {
    constructor (store: Store<RootState>) {
        super(store, bannersModule);
    }

    get homeScreenBanners (): Banner[] {
        return this.get(Getters.GET_HOME_SCREEN_BANNERS);
    }

    get secondMonitorBanners (): Banner[] {
        return this.get(Getters.GET_SECOND_MONITOR_BANNERS);
    }

    get banners (): Banner[] {
        return this.get(Getters.GET_BANNERS);
    }

    set banners (value: Banner[]) {
        this.mutate(Mutations.SET_BANNERS, value);
    }
}

export const bannersStoreSymbol = Symbol("BannersStore");

export function provideBannersStore (rootStore: RootStore): BannersStore {
    const store = new BannersStoreImpl(rootStore.store);
    provide(bannersStoreSymbol, store);
    return store;
}

export function useBannersStore (): BannersStore {
    return use(bannersStoreSymbol);
}
