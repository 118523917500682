import { TIMEOUTS } from "@sportaq/common/consts/default-consts";

export interface DateTimeFormatter {
    formatDate (date: Date, showYear: boolean): string;

    formatTime (date: Date): string;
}

export interface MiscellaneousParamsProvider {
    get recycleScrollerBufferSize (): number;

    get dateTimeFormatter (): DateTimeFormatter;

    get balanceRefreshTimeout (): number;
}

export class DefaultMiscellaneousParamsProvider implements MiscellaneousParamsProvider {
    private readonly _dateTimeFormatter = {
        formatDate (date: Date, showYear: boolean): string {
            return date.toLocaleDateString(undefined, {
                day: "2-digit",
                month: "2-digit",
                year: showYear ? "numeric" : undefined
            });
        },
        formatTime (date: Date): string {
            return date.toLocaleTimeString(undefined, {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
        }
    };

    get recycleScrollerBufferSize (): number {
        return 200;
    }

    get dateTimeFormatter () {
        return this._dateTimeFormatter;
    }

    get balanceRefreshTimeout (): number {
        return TIMEOUTS.BALANCE_REFRESH_TIMEOUT;
    }
}
