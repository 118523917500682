<template>
    <div class="deposit-filter">
        <div class="deposit-filter-body">
            <div class="form-line">
                <div class="label">{{$t("webdesktop.inPlay.cardsTotal")}} <span class="value" v-if="cardsRef">{{cardsRef.length}}</span></div>
            </div>
            <div class="form-line">
                <div class="label additional">{{$t("webdesktop.inPlay.amount")}} <span class="value">{{totalStake}} {{currency}}</span></div>
            </div>
        </div>
    </div>
    <card-panel v-for="(card) in cardsRef" :key="card.id" :card="card" :showPayCode="false"></card-panel>
    <div class="preloader" v-if="!cardsRef">
        <div class="preloader-el"></div>
    </div>
</template>
<script src="./stakes-in-play-component.ts" />
