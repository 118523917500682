/* eslint quote-props: ["error", "always"] */
export default {
    "detailInfo": {
        "groups": {
            "americanFootball": {
                "evenOddMatch": "Чет / Нечет матча",
                "evenOddMatchHalf1": "1 половина Чет / Нечет",
                "evenOddMatchQuarter1": "1 четверть Чет / Нечет",
                "evenOddMatchQuarter2": "2 четверть Чет / Нечет",
                "evenOddMatchQuarter3": "3 четверть Чет / Нечет",
                "evenOddMatchQuarter4": "4 четверть Чет / Нечет",
                "handicap": "Фора",
                "handicapHalf1": "1 половина Фора",
                "handicapHalf2": "2 половина Фора",
                "handicapQuarter1": "1 четверть Фора",
                "handicapQuarter2": "2 четверть Фора",
                "handicapQuarter3": "3 четверть Фора",
                "handicapQuarter4": "4 четверть Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam1Half1": "1 половина Индивидуальный тотал {team1}",
                "individualTotalTeam1Half2": "2 половина Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter1": "1 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter2": "2 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter3": "3 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter4": "4 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "individualTotalTeam2Half1": "1 половина Индивидуальный тотал {team2}",
                "individualTotalTeam2Half2": "2 половина Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter1": "1 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter2": "2 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter3": "3 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter4": "4 четверть Индивидуальный тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "1 половина Тотал",
                "totalHalf2": "2 половина Тотал",
                "totalQuarter1": "1 четверть Тотал",
                "totalQuarter2": "2 четверть Тотал",
                "totalQuarter3": "3 четверть Тотал",
                "totalQuarter4": "4 четверть Тотал",
                "x1_2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Half2": "Результат 2 половина",
                "x1x2Quarter1": "Результат 1 четверть",
                "x1x2Quarter2": "Результат 2 четверть",
                "x1x2Quarter3": "Результат 3 четверть",
                "x1x2Quarter4": "Результат 4 четверть"
            },
            "baseball": {
                "evenOddMatch": "Чет/Нечет матча",
                "handicap": "Фора",
                "handicapHalf1": "Фора 1 половина",
                "handicapInning1": "1 иннинг Фора",
                "handicapInning2": "2 иннинг Фора",
                "handicapInning3": "3 иннинг Фора",
                "handicapInning4": "4 иннинг Фора",
                "handicapInning5": "5 иннинг Фора",
                "handicapInning6": "6 иннинг Фора",
                "handicapInning7": "7 иннинг Фора",
                "handicapInning8": "8 иннинг Фора",
                "handicapInning9": "9 иннинг Фора",
                "handicaps": "Форы",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "Тотал 1 половина",
                "totalInning1": "1 иннинг Тотал",
                "totalInning2": "2 иннинг Тотал",
                "totalInning3": "3 иннинг Тотал",
                "totalInning4": "4 иннинг Тотал",
                "totalInning5": "5 иннинг Тотал",
                "totalInning6": "6 иннинг Тотал",
                "totalInning7": "7 иннинг Тотал",
                "totalInning8": "8 иннинг Тотал",
                "totalInning9": "9 иннинг Тотал",
                "x1_2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Inning1": "Результат 1 иннинг",
                "x1x2Inning2": "Результат 2 иннинг",
                "x1x2Inning3": "Результат 3 иннинг",
                "x1x2Inning4": "Результат 4 иннинг",
                "x1x2Inning5": "Результат 5 иннинг",
                "x1x2Inning6": "Результат 6 иннинг",
                "x1x2Inning7": "Результат 7 иннинг",
                "x1x2Inning8": "Результат 8 иннинг",
                "x1x2Inning9": "Результат 9 иннинг"
            },
            "basketball": {
                "evenOddMatch": "Чет / Нечет матча",
                "evenOddMatchHalf1": "1 половина Чет / Нечет",
                "evenOddMatchQuarter1": "1 четверть Чет / Нечет",
                "evenOddMatchQuarter2": "2 четверть Чет / Нечет",
                "evenOddMatchQuarter3": "3 четверть Чет / Нечет",
                "evenOddMatchQuarter4": "4 четверть Чет / Нечет",
                "handicap": "Фора",
                "handicapHalf1": "1 половина Фора",
                "handicapHalf2": "2 половина Фора",
                "handicapQuarter1": "1 четверть Фора",
                "handicapQuarter2": "2 четверть Фора",
                "handicapQuarter3": "3 четверть Фора",
                "handicapQuarter4": "4 четверть Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam1Half1": "1 половина Индивидуальный тотал {team1}",
                "individualTotalTeam1Half2": "2 половина Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter1": "1 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter2": "2 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter3": "3 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam1Quarter4": "4 четверть Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "individualTotalTeam2Half1": "1 половина Индивидуальный тотал {team2}",
                "individualTotalTeam2Half2": "2 половина Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter1": "1 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter2": "2 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter3": "3 четверть Индивидуальный тотал {team2}",
                "individualTotalTeam2Quarter4": "4 четверть Индивидуальный тотал {team2}",
                "total": "Тотал",
                "totalHalf1": "1 половина Тотал",
                "totalHalf2": "2 половина Тотал",
                "totalQuarter1": "1 четверть Тотал",
                "totalQuarter2": "2 четверть Тотал",
                "totalQuarter3": "3 четверть Тотал",
                "totalQuarter4": "4 четверть Тотал",
                "x1x2": "Результат матча",
                "x1x2Half1": "Результат 1 половина",
                "x1x2Half2": "Результат 2 половина",
                "x1x2Quarter1": "Результат 1 четверть",
                "x1x2Quarter2": "Результат 2 четверть",
                "x1x2Quarter3": "Результат 3 четверть",
                "x1x2Quarter4": "Результат 4 четверть"
            },
            "football": {
                "12TotalUnder": "12 + тотал меньше ({p1})",
                "DrawTotalOver": "Ничья + тотал больше ({p1})",
                "DrawTotalUnder": "Ничья + тотал меньше ({p1})",
                "atLeastOneWontScore12": "Хотя бы одна не забьет + 12",
                "atLeastOneWontScore1X": "Хотя бы одна не забьет + 1X",
                "atLeastOneWontScoreDraw": "Хотя бы одна не забьет + Ничья",
                "atLeastOneWontScoreTeam1": "Хотя бы одна не забьет + {team1}",
                "atLeastOneWontScoreTeam2": "Хотя бы одна не забьет + {team2}",
                "atLeastOneWontScoreTotalOver": "Хотя бы одна не забьет + тотал бол. ({p1})",
                "atLeastOneWontScoreTotalUnder": "Хотя бы одна не забьет + тотал мен. ({p1})",
                "atLeastOneWontScoreX2": "Хотя бы одна не забьет + X2",
                "bothScoreTotalOver": "Обе забьют + тотал бол. ({p1})",
                "bothScoreTotalUnder": "Обе забьют + тотал мен. ({p1})",
                "dryWin": "Сухая победа",
                "evenOddHalf1": "Чет / Нечет 1 тайм",
                "evenOddMatch": "Чет / Нечет матча",
                "firstGoalTeam": "Первый гол забьёт",
                "goalBothHalves": "Гол в обоих таймах",
                "goalHalf1": "Гол в первом тайме",
                "goalHalf2": "Гол во втором тайме",
                "goalIntervalTeam1x16x30": "Гол {team1} 15:01 - 30:00 мин",
                "goalIntervalTeam1x1x15": "Гол {team1} 00:01 - 15:00 мин",
                "goalIntervalTeam1x1x30": "Гол {team1} 00:01 - 30:00 мин",
                "goalIntervalTeam1x1x45": "Гол {team1} 00:01 - 45:00 мин",
                "goalIntervalTeam1x1x60": "Гол {team1} 00:01 - 60:00 мин",
                "goalIntervalTeam1x1x75": "Гол {team1} 00:01 - 75:00 мин",
                "goalIntervalTeam1x31x45": "Гол {team1} 30:01 - 45:00 мин",
                "goalIntervalTeam1x46x60": "Гол {team1} 45:01 - 60:00 мин",
                "goalIntervalTeam1x61x75": "Гол {team1} 60:01 - 75:00 мин",
                "goalIntervalTeam1x76x90": "Гол {team1} 75:01 - 90:00 мин",
                "goalIntervalTeam2x16x30": "Гол {team2} 15:01 - 30:00 мин",
                "goalIntervalTeam2x1x15": "Гол {team2} 00:01 - 15:00 мин",
                "goalIntervalTeam2x1x30": "Гол {team2} 00:01 - 30:00 мин",
                "goalIntervalTeam2x1x45": "Гол {team2} 00:01 - 45:00 мин",
                "goalIntervalTeam2x1x60": "Гол {team2} 00:01 - 60:00 мин",
                "goalIntervalTeam2x1x75": "Гол {team2} 00:01 - 75:00 мин",
                "goalIntervalTeam2x31x45": "Гол {team2} 30:01 - 45:00 мин",
                "goalIntervalTeam2x46x60": "Гол {team2} 45:01 - 60:00 мин",
                "goalIntervalTeam2x61x75": "Гол {team2} 60:01 - 75:00 мин",
                "goalIntervalTeam2x76x90": "Гол {team2} 75:01 - 90:00 мин",
                "goalIntervalx16x30": "Гол 15:01 - 30:00 мин",
                "goalIntervalx1x15": "Гол 00:01 - 15:00 мин",
                "goalIntervalx1x30": "Гол 00:01 - 30:00 мин",
                "goalIntervalx1x45": "Гол 00:01 - 45:00 мин",
                "goalIntervalx1x60": "Гол 00:01 - 60:00 мин",
                "goalIntervalx1x75": "Гол 00:01 - 75:00 мин",
                "goalIntervalx31x45": "Гол 30:01 - 45:00 мин",
                "goalIntervalx46x60": "Гол 45:01 - 60:00 мин",
                "goalIntervalx61x75": "Гол 60:01 - 75:00 мин",
                "goalIntervalx76x90": "Гол 75:01 - 90:00 мин",
                "goalTeam1BothHalves": "{team1} забьёт в обоих таймах",
                "goalTeam1Half1": "{team1} забьёт гол в 1 тайме",
                "goalTeam1Half2": "{team1} забьёт гол в 2 тайме",
                "goalTeam2BothHalves": "{team2} забьёт в обоих таймах",
                "goalTeam2Half1": "{team2} забьёт гол в 1 тайме",
                "goalTeam2Half2": "{team2} забьёт гол в 2 тайме",
                "goalWillScore1": "1 гол забьет",
                "goalWillScore10": "10 гол забьет",
                "goalWillScore2": "2 гол забьет",
                "goalWillScore3": "3 гол забьет",
                "goalWillScore4": "4 гол забьет",
                "goalWillScore5": "5 гол забьет",
                "goalWillScore6": "6 гол забьет",
                "goalWillScore7": "7 гол забьет",
                "goalWillScore8": "8 гол забьет",
                "goalWillScore9": "9 гол забьет",
                "halfMatch": "Тайм/Матч",
                "halvesComparison": "Сравнение таймов",
                "handicap": "Фора",
                "handicapHalf1": "1 Тайм Фора",
                "handicapHalf2": "2 Тайм Фора",
                "handicapMatch": "Фора матч",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam1Half1": "1 Тайм Индивидуальный тотал {team1}",
                "individualTotalTeam1Half2": "2 Тайм Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "individualTotalTeam2Half1": "1 Тайм Индивидуальный тотал {team2}",
                "individualTotalTeam2Half2": "2 Тайм Индивидуальный тотал {team2}",
                "lastGoalTeam": "Последний гол забьёт команда",
                "matchScore": "Точный счет матча",
                "passage": "Проход",
                "scoreBoth": "Обе забьют",
                "scoreBoth12": "Обе забьют + 12",
                "scoreBoth1X": "Обе забьют + 1X",
                "scoreBothDraw": "Обе забьют + Ничья",
                "scoreBothHalf1": "Обе забьют в 1-ом тайме",
                "scoreBothHalf2": "Обе забьют во 2-ом тайме",
                "scoreBothTeam1": "Обе забьют + {team1}",
                "scoreBothTeam2": "Обе забьют + {team2}",
                "scoreBothX2": "Обе забьют + X2",
                "scoreTeam1": "{team1} забьёт гол",
                "scoreTeam2": "{team2} забьёт гол",
                "strongWilledWin": "Волевая победа",
                "team1Goal112": "{team1} забьет 1-й гол + 12",
                "team1Goal11X": "{team1} забьет 1-й гол + 1X",
                "team1Goal1Draw": "{team1} забьет 1-й гол + Ничья",
                "team1Goal1Team1": "{team1} забьет 1-й гол + {team1}",
                "team1Goal1Team2": "{team1} забьет 1-й гол + {team2}",
                "team1Goal1X2": "{team1} забьет 1-й гол + X2",
                "team1TotalOver": "{team1} + Тотал больше ({p1})",
                "team1TotalUnder": "{team1} + Тотал меньше ({p1})",
                "team2Goal112": "{team2} забьет 1-й гол + 12",
                "team2Goal11X": "{team2} забьет 1-й гол + 1X",
                "team2Goal1Draw": "{team2} забьет 1-й гол + Ничья",
                "team2Goal1Team1": "{team2} забьет 1-й гол + {team1}",
                "team2Goal1Team2": "{team2} забьет 1-й гол + {team2}",
                "team2Goal1X2": "{team2} забьет 1-й гол + X2",
                "team2TotalOver": "{team2} + Тотал больше ({p1})",
                "team2TotalUnder": "{team2} + Тотал меньше ({p1})",
                "total": "Тотал",
                "totalBalls": "Тотал мячей",
                "totalHalf1": "1 Тайм Тотал",
                "totalHalf2": "2 Тайм Тотал",
                "winBothHalves": "Выиграет оба тайма",
                "x12TotalOver": "12 + тотал больше ({p1})",
                "x1TotalOver": "1X + тотал больше ({p1})",
                "x1TotalUnder": "1X + тотал меньше ({p1})",
                "x1x12x2": "Двойной исход",
                "x1x12x2Half1": "Двойной исход 1 Тайм",
                "x1x12x2Half2": "Двойной исход 2 Тайм",
                "x1x2": "Результат матча",
                "x1x2fHalf1": "Результат 1 Тайм",
                "x1x2fHalf2": "Результат 2 Тайм",
                "x2TotalOver": "X2 + тотал больше ({p1})",
                "x2TotalUnder": "X2 + тотал меньше ({p1})"
            },
            "handball": {
                "evenOddHalf1": "1 тайм Чет / Нечет",
                "evenOddMatch": "Чет / Нечет матча",
                "handicap": "Фора",
                "handicapHalf1": "1 тайм Фора",
                "handicapHalf2": "2 тайм Фора",
                "handicapMatch": "Фора матч",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam1Half1": "1 тайм Индивидуальный тотал {team1}",
                "individualTotalTeam1Half2": "2 тайм Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "individualTotalTeam2Half1": "1 тайм Индивидуальный тотал {team2}",
                "individualTotalTeam2Half2": "2 тайм Индивидуальный тотал {team2}",
                "passage": "Проход",
                "total": "Тотал",
                "totalHalf1": "1 тайм Тотал",
                "totalHalf2": "2 тайм Тотал",
                "x1x12x2": "Двойной шанс",
                "x1x12x2Half1": "Двойной шанс 1 тайм",
                "x1x12x2Half2": "Двойной шанс 2 тайм",
                "x1x2": "Результат матча",
                "x1x2fHalf1": "Результат 1 тайм",
                "x1x2fHalf2": "Результат 2 тайм"
            },
            "hockey": {
                "handicap": "Фора",
                "handicapPeriod1": "1 период Фора",
                "handicapPeriod2": "2 период Фора",
                "handicapPeriod3": "3 период Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalPeriod1": "1 период Тотал",
                "totalPeriod2": "2 период Тотал",
                "totalPeriod3": "3 период Тотал",
                "x1x12x2": "Двойной шанс",
                "x1x12x2Period1": "Двойной шанс 1 период",
                "x1x12x2Period2": "Двойной шанс 2 период",
                "x1x12x2Period3": "Двойной шанс 3 период",
                "x1x2": "Результат  матча",
                "x1x2Period1": "Результат 1 период",
                "x1x2Period2": "Результат 2 период",
                "x1x2Period3": "Результат 3 период"
            },
            "tableTennis": {
                "evenOddMatch": "Чет / Нечет матча",
                "evenOddSet1": "1 сет Чет / Нечет",
                "evenOddSet2": "2 сет Чет / Нечет",
                "evenOddSet3": "3 сет Чет / Нечет",
                "evenOddSet4": "4 сет Чет / Нечет",
                "evenOddSet5": "5 сет Чет / Нечет",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "matchScore": "Точный счет матча",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            },
            "tennis": {
                "evenOddMatch": "Чет / Нечет матча",
                "evenOddSet1": "1 сет Чет / Нечет",
                "evenOddSet2": "2 сет Чет / Нечет",
                "evenOddSet3": "3 сет Чет / Нечет",
                "evenOddSet4": "4 сет Чет / Нечет",
                "evenOddSet5": "5 сет Чет / Нечет",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "matchScore": "Точный счет матча",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            },
            "volleyball": {
                "evenOddMatch": "Чет / Нечет матча",
                "evenOddSet1": "1 сет Чет / Нечет",
                "evenOddSet2": "2 сет Чет / Нечет",
                "evenOddSet3": "3 сет Чет / Нечет",
                "evenOddSet4": "4 сет Чет / Нечет",
                "evenOddSet5": "5 сет Чет / Нечет",
                "handicap": "Фора",
                "handicapSet1": "1 сет Фора",
                "handicapSet2": "2 сет Фора",
                "handicapSet3": "3 сет Фора",
                "handicapSet4": "4 сет Фора",
                "handicapSet5": "5 сет Фора",
                "individualTotalTeam1": "Индивидуальный тотал {team1}",
                "individualTotalTeam2": "Индивидуальный тотал {team2}",
                "matchScore": "Точный счет матча",
                "scoreAfter2Games": "Cчёт после 2х партий",
                "total": "Тотал",
                "totalMatch": "Тотал матча",
                "totalSet1": "1 сет Тотал",
                "totalSet2": "2 сет Тотал",
                "totalSet3": "3 сет Тотал",
                "totalSet4": "4 сет Тотал",
                "totalSet5": "5 сет Тотал",
                "x1_2": "Результат матча",
                "x1_2Set1": "Результат 1 сет",
                "x1_2Set2": "Результат 2 сет",
                "x1_2Set3": "Результат 3 сет",
                "x1_2Set4": "Результат 4 сет",
                "x1_2Set5": "Результат 5 сет"
            }
        },
        "tabs": {
            "americanFootball": {
                "all": "Все",
                "halves": "Половины",
                "handicaps": "Форы",
                "popular": "Популярные",
                "quarters": "Четверти",
                "totals": "Тоталы"
            },
            "baseball": {
                "all": "Все",
                "halves": "Половины",
                "handicap": "Фора",
                "handicaps": "Форы",
                "innings": "Иннинги",
                "popular": "Популярные",
                "total": "Тотал",
                "totals": "Тоталы"
            },
            "basketball": {
                "all": "Все",
                "halves": "Половины",
                "handicaps": "Форы",
                "popular": "Популярные",
                "quarters": "Четверти",
                "totals": "Тоталы"
            },
            "football": {
                "all": "Все",
                "goals": "Голы",
                "goalsPlusResult": "Голы + Результат",
                "goalsPlusTotal": "Голы + Тотал",
                "halves": "Таймы",
                "handicaps": "Форы",
                "popular": "Популярные",
                "resultPlusTotal": "Результат + Тотал",
                "totals": "Тоталы"
            },
            "handball": {
                "all": "Все",
                "half1": "1 тайм",
                "half2": "2 тайм",
                "handicap": "Фора",
                "handicaps": "Форы",
                "popular": "Популярные",
                "total": "Тотал",
                "totals": "Тоталы"
            },
            "hockey": {
                "all": "Все",
                "handicaps": "Форы",
                "periods": "Периоды",
                "popular": "Популярные",
                "totals": "Тоталы"
            },
            "tableTennis": {
                "all": "Все",
                "handicaps": "Форы",
                "popular": "Популярные",
                "sets": "Сеты",
                "totals": "Тоталы"
            },
            "tennis": {
                "all": "Все",
                "handicaps": "Форы",
                "popular": "Популярные",
                "sets": "Сеты",
                "totals": "Тоталы"
            },
            "volleyball": {
                "all": "Все",
                "handicaps": "Форы",
                "popular": "Популярные",
                "sets": "Сеты",
                "totals": "Тоталы"
            }
        }
    }
};