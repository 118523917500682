import EventType from "@sportaq/common/enums/event-type";
import { Cloneable, Equable } from "@sportaq/common/types/interfaces";
import { Quotation, QuotationKey } from "@sportaq/model/betting/events/quotation";
import { BettingEvent, Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { numberOfCombinations } from "@sportaq/common/utils/number-utils";
import {
    calculateCombinedMaxWin,
    calculateExpressCoef,
    calculateSystemCoef
} from "@sportaq/model/common/combined-stakes-calculator";
import { calculateMaxStake } from "@sportaq/model/common/betting-calculations";

export const EXPRESS_BASE = -1;

export enum EStakeStatus {
    active,
    positionBlocked,
    quotationBlocked,
    eventStarted,
    eventFullTime,
    positionLiveServiceNotAvailable
}

export interface CoefHolder {
    get coef (): number;
}

export interface Stake extends CoefHolder {
    amount?: number;
    coef: number;

    get templateKey (): string;

    get minSumStake (): number;

    get maxSumStake (): number;

    get status (): EStakeStatus;

    get isConfirmed (): boolean;
}

export class SimpleStakeDTO implements ParticipantsSupplier, CoefHolder {
    constructor (readonly event: StakeEvent,
        readonly key: StakeQuotationKey,
        readonly coef: number,
        readonly amount: number,
        readonly templateKey: string) {
    }

    get participants (): Participant[] {
        return this.event.participants;
    }

    cloneWithNewCoef (coef: number): SimpleStakeDTO {
        return new SimpleStakeDTO(
            this.event.clone(),
            this.key.clone(),
            coef,
            this.amount,
            this.templateKey
        );
    }
}

export class SimpleStake implements ParticipantsSupplier, Stake, Equable<SimpleStake> {
    private _confirmedCoef: StakeQuotationCoef;
    private _currentCoef: StakeQuotationCoef;
    private _maxSumStake: number;

    readonly event: StakeEvent;
    readonly key: StakeQuotationKey;
    public status: EStakeStatus;
    public amount?: number;

    constructor (event: BettingEvent, quotation: Quotation, private readonly pointSettings: PointSettings) {
        this.event = StakeEvent.fromEvent(event);
        this.key = StakeQuotationKey.fromQuotation(quotation);
        this._currentCoef = new StakeQuotationCoef(quotation.coef, quotation.maxWin);
        this._confirmedCoef = this._currentCoef;

        this.status = EStakeStatus.active;
        this._maxSumStake = this.calculateMaxSumStake();
    }

    equals (o: SimpleStake): boolean {
        return this.event.eventType === o.event.eventType && this.event.positionId === o.event.positionId && this.key.id === o.key.id;
    }

    equalsQuotation (eventType: EventType, quotation: Quotation) {
        return this.event.eventType === eventType && this.event.positionId === quotation.positionId && this.key.id === quotation.id;
    }

    updateCoef (coef: BigDecimal, maxWin: number) {
        this._currentCoef = new StakeQuotationCoef(coef, maxWin);
        this._maxSumStake = this.calculateMaxSumStake();
    }

    get templateKey (): string {
        return "simple-stake-" + this.event.positionId + "-" + this.key.quotationKey.quotationId + "-" + this.key.id;
    }

    get coef (): number {
        return this.bgCoef.numberValue;
    }

    get bgCoef (): BigDecimal {
        return this._currentCoef.coef;
    }

    get minSumStake (): number {
        return this.event.eventType === EventType.PRE_MATCH ? this.pointSettings.preMatchSumStakeMin : this.pointSettings.liveSumStakeMin;
    }

    get maxSumStake (): number {
        return this._maxSumStake;
    }

    set maxSumStake (value: number) {
        this._maxSumStake = value;
    }

    get maxWin (): number {
        return this._currentCoef.maxWin;
    }

    get isConfirmed (): boolean {
        return this._currentCoef === this._confirmedCoef || this._currentCoef.equals(this._confirmedCoef);
    }

    confirmed () {
        this._confirmedCoef = this._currentCoef;
    }

    createDTO (): SimpleStakeDTO {
        return new SimpleStakeDTO(
            this.event.clone(),
            this.key.clone(),
            this._currentCoef.coef.numberValue,
            this.amount ?? 0,
            this.templateKey);
    }

    get participants (): Participant[] {
        return this.event.participants;
    }

    private calculateMaxSumStake (): number {
        return calculateMaxStake(this.event.eventType, this._currentCoef.coef, this._currentCoef.maxWin, this.pointSettings);
    }
}

export class StakeEvent implements ParticipantsSupplier, Cloneable<StakeEvent> {
    readonly eventType: EventType;
    readonly positionId: number;
    readonly sportType: ESportType;
    readonly startTime: Date;
    readonly startTimeStr: string;
    readonly partitionName: string;
    readonly participants: Participant[];

    constructor (eventType: EventType, positionId: number, sportType: ESportType, startTime: Date, startTimeStr: string, partitionName: string, participants: Participant[]) {
        this.eventType = eventType;
        this.positionId = positionId;
        this.sportType = sportType;
        this.startTime = startTime;
        this.startTimeStr = startTimeStr;
        this.partitionName = partitionName;
        this.participants = participants;
    }

    clone (): StakeEvent {
        return new StakeEvent(
            this.eventType,
            this.positionId,
            this.sportType,
            this.startTime,
            this.startTimeStr,
            this.partitionName,
            [...this.participants.map(value => value.clone())]
        );
    }

    static fromEvent (event: BettingEvent): StakeEvent {
        return new StakeEvent(
            event.eventType,
            event.positionId,
            event.sportTypeId,
            event.startTime,
            event.startTimeStr,
            event.partition.name,
            [...event.participants.map(value => value.clone())]
        );
    }
}

export class StakeQuotationKey implements Cloneable<StakeQuotationKey> {
    readonly id: number;
    readonly quotationKey: QuotationKey;

    constructor (id: number, quotationKey: QuotationKey) {
        this.id = id;
        this.quotationKey = quotationKey;
    }

    clone (): StakeQuotationKey {
        return new StakeQuotationKey(this.id, this.quotationKey);
    }

    static fromQuotation (quotation: Quotation): StakeQuotationKey {
        return new StakeQuotationKey(
            quotation.id,
            quotation.key // The key is read-only, so it doesn't need to be cloned
        );
    }
}

class StakeQuotationCoef implements Equable<StakeQuotationCoef>, Cloneable<StakeQuotationCoef> {
    readonly coef: BigDecimal;

    constructor (coef: BigDecimal, readonly maxWin: number) {
        this.coef = coef.clone();
    }

    equals (o: StakeQuotationCoef): boolean {
        return this.coef.equals(o.coef) && this.maxWin === o.maxWin;
    }

    clone (): StakeQuotationCoef {
        return new StakeQuotationCoef(this.coef.clone(), this.maxWin);
    }
}

export interface CombinedStakeDTO {
    express: boolean;
    base: number;
    limit: number;
    coef: number;
    amount: number;
    templateKey: string;
    variants: number;
    maxWin: number;
    bonus: boolean;
    simples: SimpleStakeDTO[];
}

// if base equals -1 it's an express
export class CombinedStake implements Stake {
    private _coef: number;
    private _canUseBonus: boolean = false;
    private _expressCoefWithoutBonus: number;

    private _maxSumStake: number;
    private _limit: number;

    public amount?: number;
    public bonus: boolean = false;

    private constructor (
        private readonly settings: PointSettings,
        readonly base: number,
        simples: SimpleStake[],
        maxSumStake: number
    ) {
        this._limit = simples.length;
        this._maxSumStake = maxSumStake;
        if (this.express) {
            this._coef = calculateExpressCoef(simples, 1);
        } else {
            this._coef = calculateSystemCoef(simples, base, 1);
        }
        this._expressCoefWithoutBonus = this._coef;
        this._canUseBonus = this.recalculateCanUseBonus(simples);
    }

    get isConfirmed (): boolean {
        return true;
    }

    get expressWithoutBonus (): number {
        return this._expressCoefWithoutBonus;
    }

    get coef (): number {
        return this._coef;
    }

    get canUseBonus (): boolean {
        return this._canUseBonus;
    }

    get minSumStake (): number {
        return this.express ? this.settings.expressSumStakeMin : this.settings.systemSumStakeMin;
    }

    get variants (): number {
        return !this.express ? numberOfCombinations(this.limit, this.base) : 0;
    }

    get maxSumStake (): number {
        const b = this._canUseBonus && this.bonus;
        return b ? Math.min(this.settings.expressBonusMaxSumStake, this._maxSumStake) : this._maxSumStake;
    }

    set maxSumStake (value: number) {
        this._maxSumStake = value;
    }

    get limit (): number {
        return this._limit;
    }

    update (simples: SimpleStake[], maxSumStake?: number) {
        this._canUseBonus = this.recalculateCanUseBonus(simples);
        this._limit = simples.length;
        const bonus = this._canUseBonus && this.bonus ? this.settings.expressBonusMultiplier : 1;
        if (this.express) {
            this._coef = calculateExpressCoef(simples, bonus);
            if (bonus !== 1) {
                this._expressCoefWithoutBonus = calculateExpressCoef(simples, 1);
            } else {
                this._expressCoefWithoutBonus = this.coef;
            }
        } else {
            this._coef = calculateSystemCoef(simples, this.base, bonus);
            this._expressCoefWithoutBonus = this._coef; // It's optional, because _expressCoefWithoutBonus is used for express only
        }
        if (maxSumStake) {
            this._maxSumStake = maxSumStake;
        }
    }

    get templateKey (): string {
        if (this.express) {
            return "combined-stake-express";
        } else {
            return `combined-stake-system-base${this.base}`;
        }
    }

    get express () {
        return this.base === EXPRESS_BASE;
    }

    get status (): EStakeStatus {
        return EStakeStatus.active;
    }

    createDTO (simples: SimpleStakeDTO[], maxCoef: number): CombinedStakeDTO {
        return {
            base: this.base,
            limit: this.limit,
            coef: this.coef,
            amount: this.amount ?? 0,
            templateKey: this.templateKey,
            express: this.base === EXPRESS_BASE,
            variants: this.variants,
            bonus: this._canUseBonus && this.bonus,
            maxWin: calculateCombinedMaxWin(this.express, this.coef, this.amount, maxCoef),
            simples
        };
    }

    private recalculateCanUseBonus (simples: SimpleStake[]): boolean {
        return this.settings.isBonusActive && simples.every(value => value.event.eventType !== EventType.LIVE);
    }

    static express (pointSettings: PointSettings, simples: SimpleStake[], maxSumStake: number): CombinedStake {
        return new CombinedStake(pointSettings, EXPRESS_BASE, simples, maxSumStake);
    }

    static system (pointSettings: PointSettings, base: number, simples: SimpleStake[], maxSumStake: number): CombinedStake {
        return new CombinedStake(pointSettings, base, simples, maxSumStake);
    }
}
