import { createI18n } from "vue-i18n";
import eventDetailCardsEn from "@sportaq/i18n/messages/detail-cards/event-detail-info-en";
import eventDetailCardsEs from "@sportaq/i18n/messages/detail-cards/event-detail-info-es";
import eventDetailCardsPt from "@sportaq/i18n/messages/detail-cards/event-detail-info-pt";
import eventDetailCardsRu from "@sportaq/i18n/messages/detail-cards/event-detail-info-ru";
import generalMessagesEn from "@sportaq/i18n/messages/general/general-messages-en";
import generalMessagesEs from "@sportaq/i18n/messages/general/general-messages-es";
import generalMessagesPt from "@sportaq/i18n/messages/general/general-messages-pt";
import generalMessagesRu from "@sportaq/i18n/messages/general/general-messages-ru";

import { ESupportedLocale, LocaleChanger, Localization } from "@sportaq/i18n/interfaces/interfaces";
import { I18nSetup } from "@sportaq/i18n/settings/i18n-settings";

// export const disabledLanguages: string[] = [];

class CurrentLanguageHolder {
    constructor (public value: string | undefined) {
    }
}

const currentLanguageHolder = new CurrentLanguageHolder(undefined);

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    warnHtmlInMessage: false,
    locale: I18nSetup.i18nSettings.defaultLanguage,
    messages: {
        [ESupportedLocale.EN]: { ...eventDetailCardsEn, ...generalMessagesEn },
        [ESupportedLocale.ES]: { ...eventDetailCardsEs, ...generalMessagesEs },
        [ESupportedLocale.RU]: { ...eventDetailCardsRu, ...generalMessagesRu },
        [ESupportedLocale.PT]: { ...eventDetailCardsPt, ...generalMessagesPt }
    },
    datetimeFormats: I18nSetup.dateTimeFormats,
    numberFormats: I18nSetup.numberFormats,
    pluralRules: I18nSetup.pluralRules
});

class I18nCache {
    private _today: string = "";
    get today (): string {
        return this._today;
    }

    public refresh (value: Localization) {
        this._today = value.t("betting.event.scoreboardLine.startDate");
    }
}

export const i18nCache = new I18nCache();

export function changeLanguage (localeChanger: LocaleChanger, locale: string) {
    if (locale && testLocale(locale)) {
        currentLanguageHolder.value = locale;
        localeChanger.locale.value = locale;
        i18nCache.refresh(localeChanger);
        window.localStorage.setItem(I18nSetup.langCookieName, locale);
    }
}

export function resolveLanguage (): string {
    if (currentLanguageHolder.value) {
        return currentLanguageHolder.value;
    }
    const locale: string | null = window.localStorage.getItem(I18nSetup.langCookieName);
    if (locale && testLocale(locale)) {
        currentLanguageHolder.value = locale;
        return locale;
    }
    const browserLocale = navigator.language;
    if (browserLocale) {
        const browserLocaleItems = browserLocale.split("-");
        const currentLocale = browserLocaleItems[0].toLowerCase();
        if (testLocale(currentLocale)) {
            currentLanguageHolder.value = currentLocale;
            return currentLocale;
        }
    }
    const defaultLanguage = I18nSetup.i18nSettings.defaultLanguage;
    currentLanguageHolder.value = defaultLanguage;
    return defaultLanguage;
}

export function testLocale (locale: string): boolean {
    const enumValues: string[] = Object.values(ESupportedLocale);
    const disabledLanguages = I18nSetup.i18nSettings.disabledLanguages;
    return enumValues.includes(locale) && !disabledLanguages.includes(locale);
}

export function isSupportedLocale (locale: string) {
    const enumValues: string[] = Object.values(ESupportedLocale);
    return enumValues.includes(locale);
}

export function getAvailableLocales (): string[] {
    const result: string[] = [];
    for (const lang of Object.values(ESupportedLocale)) {
        if (!I18nSetup.i18nSettings.disabledLanguages.includes(lang)) {
            result.push(lang);
        }
    }
    return result;
}
