<template>
    <div class="popup active" id="success-window">
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.emailDone.caption")}}</div>
            <div class="popup-content">
                <div class="text" v-html="$t('webdesktop.emailDone.text')"></div>
                <div class="form-line">
                    <a id="submit-button" @click.prevent="onEnterCodeClick" class="button button-primary smaller">{{$t("webdesktop.emailDone.codeButton")}}</a>
                </div>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="confirmCodeDialogActive" @success="onCodeSuccess" @close="confirmCodeDialogActive = false"  @onCodeError="onCodeError"></confirm-code-dialog>
</template>

<script src="./email-done-component.ts" />
