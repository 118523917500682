import { Participant, ParticipantsSupplier } from "@sportaq/model/betting/events/event";
import { QuotationKey } from "@sportaq/model/betting/events/quotation";
import { isGroupEventQuotation } from "@sportaq/common/consts/quotation-consts";

export function getParticipantList (participantsSupplier: ParticipantsSupplier, quotationKey?: QuotationKey): Participant[] {
    if (quotationKey && (isGroupEventQuotation(quotationKey.quotationId))) {
        const result: Participant[] = [];
        addParticipant(result, participantsSupplier, quotationKey.p1Id);
        addParticipant(result, participantsSupplier, quotationKey.p2Id);
        return result;
    }
    return participantsSupplier.participants;
}

export function getParticipantsAsString (participantsSupplier: ParticipantsSupplier, quotationKey?: QuotationKey): string {
    const participants = getParticipantList(participantsSupplier, quotationKey);
    return participants.map(value => value.name).join(participants.length === 2 ? " — " : " * ");
}

function addParticipant (participants: Participant[], participantsSupplier: ParticipantsSupplier, id: number | undefined) {
    if (id) {
        const participant = participantsSupplier.participants.find(value => value.id === id);
        if (participant) {
            participants.push(participant);
        }
    }
}
