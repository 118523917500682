import { Hashable } from "@sportaq/common/types/interfaces";

export class HashMap<K extends Hashable<K>, V> {
    private readonly map = new Map<number, Entry<K, V>[]>();

    get (key: K): V | undefined {
        if (key) {
            const hashCode = key.hashCode();
            const list = this.map.get(hashCode);
            if (list) {
                for (const entry of list) {
                    if (entry.key.equals(key)) {
                        return entry.value;
                    }
                }
            }
        }
        return undefined;
    }

    set (key: K, value: V) {
        if (key) {
            const hashCode = key.hashCode();
            let list = this.map.get(hashCode);
            if (!list) {
                list = [];
                this.map.set(hashCode, list);
            }
            const newEntry = new Entry(key, value);
            const index = list.findIndex(entry => entry.key.equals(key));
            if (index >= 0) {
                list.splice(index, 1, newEntry);
            } else {
                list.push(newEntry);
            }
        }
    }

    delete (key: K): boolean {
        if (key) {
            const hashCode = key.hashCode();
            const list = this.map.get(hashCode);
            if (list) {
                const index = list.findIndex(entry => entry.key.equals(key));
                if (index >= 0) {
                    list.splice(index, 1);
                    return true;
                }
            }
        }
        return false;
    }
}

export class Entry<K extends Hashable<K>, V> {
    constructor (readonly key: K, readonly value: V) {
    }
}

export class Number2Key implements Hashable<Number2Key> {
    constructor (readonly value1: number, readonly value2: number) {
    }

    equals (o: Number2Key): boolean {
        if (this === o) {
            return true;
        }
        return this.value1 === o.value1 && this.value2 === o.value2;
    }

    hashCode (): number {
        let hash = 7;
        hash = 31 * hash + this.value1;
        hash = 31 * hash + this.value2;
        return hash;
    }
}
