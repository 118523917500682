import { useWebRestService } from "@sportaq/services/web";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { EVerifyField } from "@sportaq/model/common/user-accounts";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { onNaturalNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { computed, ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";
import { resolveLanguage } from "@sportaq/i18n/index";

export function useVerificationDialogCommon (context: SetupContext) {
    const phoneConfirmCodeRef = ref<number>();
    const emailConfirmCodeRef = ref<number>();
    const restService = useWebRestService();
    const store = useWebStore();
    const phoneError = ref<string>();
    const emailError = ref<string>();
    const i18n = useI18n();
    const isSubmitEnabled = computed(() => {
        return (!isPhoneVerified.value && phoneConfirmCodeRef.value && phoneConfirmCodeRef.value.toString().length === 8) ||
            (!isEmailVerified.value && emailConfirmCodeRef.value && emailConfirmCodeRef.value.toString().length === 8);
    });
    const phone = computed(() => store.user?.phone);
    const email = computed(() => store.user?.email);
    const isPhoneVerified = computed(() => {
        return store.user?.verifiedFields.has(EVerifyField.phone);
    });
    const isEmailVerified = computed(() => {
        return store.user?.verifiedFields.has(EVerifyField.email);
    });
    const language = resolveLanguage();

    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function sendPhoneCode () {
        const phone = store.user?.phone;
        if (phone) {
            restService.sendVerification(EVerifyField.phone).then(() => {
                showMessageBoxEx(false, undefined, i18n.t("webdesktop.verification.sent") + " " + phone);
            });
        }
    }

    function sendEmailCode () {
        const email = store.user?.email;
        if (email) {
            restService.sendVerification(EVerifyField.email).then(() => {
                showMessageBoxEx(true, undefined, i18n.t("webdesktop.verification.sent") + " " + email);
            });
        }
    }

    function onSubmit () {
        if (phoneConfirmCodeRef.value) {
            restService.emailConfirmation(phoneConfirmCodeRef.value.toString()).then(() => {
                store.user?.verifiedFields.add(EVerifyField.phone);
                phoneConfirmCodeRef.value = undefined;
            }).catch((e) => {
                phoneError.value = i18n.t(e.message);
            });
        }
        if (emailConfirmCodeRef.value) {
            restService.emailConfirmation(emailConfirmCodeRef.value.toString()).then(() => {
                store.user?.verifiedFields.add(EVerifyField.email);
                emailConfirmCodeRef.value = undefined;
            }).catch((e) => {
                emailError.value = i18n.t(e.message);
            });
        }
    }
    return {
        language,
        phoneConfirmCodeRef,
        emailConfirmCodeRef,
        isPhoneVerified,
        isEmailVerified,
        phone,
        email,
        phoneError,
        emailError,
        isSubmitEnabled,
        sendPhoneCode,
        sendEmailCode,
        onNaturalNumberKeyFilter,
        onSubmit,
        onClose
    };
}
