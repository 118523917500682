import { isQuotationPresent, QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { Participant } from "@sportaq/model/betting/events/event";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { useSettings } from "@sportaq/services/index";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { EQuotation } from "@sportaq/common/consts/quotation-consts";
import EventType from "@sportaq/common/enums/event-type";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { formatQuotationCoef } from "@sportaq/vue/components/betting/utils/quotation-strings";

export namespace ScoreboardLineQuotationComponent {
    export interface Props {
        source?: QuotationWrapper;
        participants: Participant[];
        eventBlocked: boolean;
    }

    export function useScoreboardLineQuotationComponent (props: Props) {
        const betSlipStore = useBetSlipStore();
        const eventSupplier = useEventSupplier();
        const settings = useSettings();

        const quotationFormat = ref(settings.quotationFormat);

        const diffsClass = ref("");
        let oldValue = SavedValue.fromQuotationWrapper(props.source);

        const quotationFormattedCoef = computed(() => {
            if (!props.source) {
                return "";
            }
            return formatQuotationCoef(quotationFormat.value, props.source.quotation.coef.numberValue);
        });

        function backlightOff () {
            diffsClass.value = "";
        }

        onMounted(() => {
            eventBus.on(Events.QUOTATION_BACKLIGHT_OFF, backlightOff);
            eventBus.on(Events.QUOTATION_FORMAT_CHANGED, onQuotationFormatChanged);
        });
        onUnmounted(() => {
            eventBus.off(Events.QUOTATION_BACKLIGHT_OFF, backlightOff);
            eventBus.off(Events.QUOTATION_FORMAT_CHANGED, onQuotationFormatChanged);
        });

        const quotationPresent = computed(() => {
            return props.source && props.source.quotation && props.source.quotation.key.quotationId > 0;
        });

        const isLocked = computed(() => {
            return props.source && (props.source.quotation.key.quotationId === EQuotation.LOCKED_QUOTATION);
        });

        const isSelected = computed(() => {
            if (props.source) {
                if (isQuotationPresent(props.source.quotation)) {
                    return betSlipStore.isStakePresent(props.source.eventType, props.source.quotation);
                }
            }
            return false;
        });

        function onQuotationFormatChanged () {
            quotationFormat.value = settings.quotationFormat;
        }

        function selectQuotation () {
            if (props.source) {
                const quotation = props.source.quotation;
                if (isQuotationPresent(quotation)) {
                    if (betSlipStore.isStakePresent(props.source.eventType, quotation)) {
                        betSlipStore.removeSimpleStake(props.source.eventType, quotation.positionId, quotation.id, settings.pointSettings);
                    } else {
                        const bettingEvent = eventSupplier.getOptionalEvent(props.source.eventType, quotation.positionId);
                        if (bettingEvent) {
                            betSlipStore.addSimpleStake(bettingEvent, quotation, settings.pointSettings);
                        }
                    }
                }
            }
        }

        watch(() => props.source, (value) => {
            if (value) {
                if (oldValue && value.quotation.positionId === oldValue.positionId && value.quotation.id === oldValue.id) {
                    const compare = value.quotation.coef.compare(oldValue.coef);
                    if (compare) {
                        diffsClass.value = compare > 0 ? "up" : "down";
                    }
                }
                oldValue = SavedValue.fromQuotationWrapper(value);
            }
        });

        return {
            diffsClass,

            quotationPresent,
            isSelected,
            isLocked,
            quotationFormattedCoef,

            selectQuotation
        };
    }
}

class SavedValue {
    private constructor (
        readonly eventType: EventType,
        readonly positionId: number,
        readonly id: number,
        readonly coef: BigDecimal) {
    }

    static fromQuotationWrapper (wrapper: QuotationWrapper | undefined): SavedValue | undefined {
        if (wrapper) {
            return new SavedValue(wrapper.eventType, wrapper.quotation.positionId, wrapper.quotation.id, wrapper.quotation.coef.clone());
        }
        return undefined;
    }
}
