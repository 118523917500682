import { PropType } from "vue";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import CompactScoreboardCategoryLine
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-category-line/compact/CompactScoreboardCategoryLine.vue";
import CompactScoreboardEventLine
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-event-line/compact/CompactScoreboardEventLine.vue";
import {
    ScoreboardLineComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/common-scoreboard-line-component";

export default {
    props: {
        source: {
            required: true,
            type: Object as PropType<PeriodSortInfo>
        },
        extraProps: {
            required: true,
            type: Object as PropType<ScoreboardLineComponent.ExtraProps>
        }
    },
    components: {
        CompactScoreboardCategoryLine,
        CompactScoreboardEventLine
    },
    setup (props: ScoreboardLineComponent.Props) {
        return ScoreboardLineComponent.useScoreboardLineComponent(props);
    }
};
