// region Betplace Rest Service
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { UUIDProvider } from "@sportaq/common/utils/guid-generator";
import { provideRestService, useRestService, useSettings } from "@sportaq/services/index";
import { WebDesktopRestService, WebDesktopRestServiceImpl } from "@sportaq/services/web/rest/web-desktop-rest-service";
import { WebSettings } from "@sportaq/services/web/settings/settings-service";

export function provideWebDesktopRestService (clockProvider: ClockProvider, uuidProvider: UUIDProvider, settingsService: WebSettings): WebDesktopRestService {
    return provideInjectedWebDesktopRestService(new WebDesktopRestServiceImpl(clockProvider, uuidProvider, settingsService));
}

export function provideInjectedWebDesktopRestService (restService: WebDesktopRestService): WebDesktopRestService {
    provideRestService(restService);
    return restService;
}

export function useWebRestService (): WebDesktopRestService {
    return useRestService() as WebDesktopRestService;
}

export function useWebSettings (): WebSettings {
    return useSettings() as WebSettings;
}
// endregion
