import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { BaseCardRequest } from "@sportaq/services/rest/messages/bet-slip/base/base-card-request";

export class QSt100CardsInPlay extends BaseCardRequest {
    protected readonly requestType: string = "Q.ST.10.0";

    constructor (readonly locale: string, readonly maxCoef: number) {
        super(locale, maxCoef);
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter");
        request.addChildWithValue(sessionFilterElement, "User", "true");
        const stakeFilterElement = request.addChild(filterElement, "StakeFilter", {
            logic: "AND"
        });
        request.addChildWithValue(stakeFilterElement, "CalcStatusCard", "InGame");
        this.appendSelector(request, actionElement);
    }
}
