import HomePageSportWidget from "@sportaq/vue/components/web/home-page/home-page-sport-widget/HomePageSportWidget.vue";
import HomePageGamesWidget from "@sportaq/vue/components/web/home-page/home-page-games-widget/HomePageGamesWidget.vue";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { useWebSettings } from "@sportaq/services/web";
import { computed, onMounted } from "vue";
import { useRestService } from "@sportaq/services/index";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW } from "@sportaq/common/consts/default-consts";
import EventType from "@sportaq/common/enums/event-type";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { EGameTabs } from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { onBeforeRouteLeave } from "vue-router";

const POPULAR_PREMATCH_SPORT_TYPES = [ESportType.Football, ESportType.Basketball, ESportType.Baseball, ESportType.Tennis];
const GAMES_MASK = ECasinoGameClassifier.CRASH | ECasinoGameClassifier.SLOTS | ECasinoGameClassifier.ROULETTE;

export default {
    props: {
        isMobile: {
            type: Boolean,
            required: true
        }
    },
    components: {
        HomePageSportWidget,
        HomePageGamesWidget
    },
    setup () {
        const settings = useWebSettings();
        const restService = useRestService();
        const scoreboardStore = useScoreboardStore();
        const casinoGamesStore = useCasinoGamesStore();

        const isBettingEnabled = isServiceEnabled(settings, EGameService.Betting);
        const isCasinoEnabled = isServiceEnabled(settings, EGameService.Casino);
        const isVirtualEnabled = isServiceEnabled(settings, EGameService.Virtual);

        const periods = computed(() => scoreboardStore.getPeriodsView());
        const games = computed(() => casinoGamesStore.getGamesView());

        onMounted(async () => {
            const popularPositions = await restService.getPopularPrematchPositions(POPULAR_PREMATCH_SPORT_TYPES, MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW);
            scoreboardStore.clearEventsFilter();
            scoreboardStore.updateEventsFilters([
                {
                    changeEventType: {
                        type: "set",
                        value: EventType.PRE_MATCH
                    }
                },
                {
                    changeSportTypeId: {
                        type: "set",
                        value: ESportType.Football
                    }
                },
                {
                    popular: {
                        type: "set",
                        value: popularPositions
                    }
                },
                {
                    additionalSportTypes: {
                        type: "set",
                        value: POPULAR_PREMATCH_SPORT_TYPES.filter(value => value !== ESportType.Football)
                    }
                }
            ]);

            casinoGamesStore.clearGameFilter();
            casinoGamesStore.updateGameFilter(
                {
                    changeClassifierMask: {
                        type: "set",
                        value: GAMES_MASK
                    }
                });
        });

        onBeforeRouteLeave(() => {
            scoreboardStore.clearEventsFilter();
            casinoGamesStore.clearGameFilter();
        });

        return {
            isBettingEnabled,
            isCasinoEnabled,
            isVirtualEnabled,

            periods,
            games,

            ESportType,
            ECasinoGameClassifier,
            ECasinoGameType,
            EGameTabs
        };
    }
};
