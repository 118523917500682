import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild, getRequiredValue } from "@sportaq/common/utils/xml-helper-functions";
import { StartGameFrameResponse } from "@sportaq/model/types/responses";

export class ACs11GetGameFrameRequestHandler extends AbstractMessageHandler<StartGameFrameResponse> {
    protected readonly requestType: string = "A.CS.1.1";

    constructor (private gameId: string, private demo: boolean, private isDesktop: boolean, private withTransferMoney: boolean, private hostUrl: string) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CasinoGameMark", this.gameId);
        request.addChildWithValue(actionElement, "siteUrl", this.hostUrl);
        request.addChildWithValue(actionElement, "Demo", this.demo ? "1" : "0");

        if (this.isDesktop) {
            request.addChildWithValue(actionElement, "ClientType", "desktop");
        }
        if (this.withTransferMoney) {
            request.addChildWithValue(actionElement, "withTransferMoney", "1");
        }
    }

    protected parseMessageBody (body: Element): StartGameFrameResponse {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "8100") {
            const gameUrl = getRequiredChild(action, "GameURL");
            return {
                type: "success",
                url: getRequiredValue(gameUrl)
            };
        } else {
            return {
                type: "error",
                code: serverCode
            };
        }
    }
}
