import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { EVerifyField } from "@sportaq/model/common/user-accounts";

export class AUs18SendVerification extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.US.1.8";

    constructor (readonly confirmationMethod: EVerifyField) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "ConfirmationMethod", this.confirmationMethod === EVerifyField.email ? "email" : "phone");
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "1230") {
            throw new LocalizedError(`errors.mtl.aUs12.code${serverCode}`);
        }
    }
}
