import { ref } from "vue";
import { useSettings } from "@sportaq/services/index";

export function useScrollButtons () {
    const isUsed = useSettings().pointSettings.isScrollButtonsUsed;
    const bodyElement = ref<Element>();
    const isScrollButtonsVisible = isUsed;

    return {
        bodyElement,
        isScrollButtonsVisible
    };
}
