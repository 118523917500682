import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebRestService } from "@sportaq/services/web";
import { DialogState } from "@sportaq/vue/views/web/withdrawal/dialog-state";
import { WithdrawalOrder } from "@sportaq/model/web/withdrawal-order";
import { ConfirmationMethod } from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { EVerifyField } from "@sportaq/model/common/user-accounts";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { ConfirmationResult, ConfirmationType } from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import { ref, SetupContext } from "vue";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export function useGeneralWithdrawalDialogCommon (minAmount: number, paymentSystem: PaymentSystems, context: SetupContext) {
    const store = useWebStore();
    const restService = useWebRestService();
    const i18n = useI18n();
    const account = store.user?.userAccounts.getPrimaryAccount();
    const dialogState = ref<DialogState>(DialogState.InputAmount);
    const phoneNumber = hideDigits(store.user?.phone);
    const withdrawalOrder = ref<WithdrawalOrder>();
    const confirmationMethod = ref<ConfirmationMethod>(EVerifyField.phone);
    const amountRef = ref<number>();
    const currency = account ? account.currencyMark : "";

    function close () {
        context.emit(CLOSE_EVENT);
    }

    function onSubmit () {
        if (dialogState.value === DialogState.InputAmount) {
            if (amountRef.value && amountRef.value < minAmount) {
                showMessageBox(i18n.t("webdesktop.withdrawal.minAmountError", { minAmount: minAmount + currency }));
                return;
            }
            dialogState.value = DialogState.ConfirmationType;
        } else if (dialogState.value === DialogState.ConfirmationType) {
            if (account && amountRef.value) {
                restService.generalWithdrawal(paymentSystem, confirmationMethod.value,
                    account.currencyId, amountRef.value).then(() => {
                    dialogState.value = DialogState.CodeInput;
                });
            }
        } else if (dialogState.value === DialogState.Finish) {
            close();
        }
    }

    function hideDigits (phoneNumber: string | undefined): string {
        if (!phoneNumber) {
            return "";
        }
        const digitsToKeep = 3;
        const digitsToReplace = phoneNumber.length - digitsToKeep * 2;
        const hiddenDigits = "*".repeat(digitsToReplace);
        const visibleDigits = phoneNumber.substring(digitsToReplace + digitsToKeep);
        const visibleDigits1 = phoneNumber.substring(0, digitsToKeep);
        return visibleDigits1 + hiddenDigits + visibleDigits;
    }

    function onConfirmCodeClose () {
        close();
    }

    function onCodeSuccess (result: ConfirmationResult) {
        if (result.confirmationType === ConfirmationType.Withdrawal) {
            dialogState.value = DialogState.Finish;
            withdrawalOrder.value = result.withdrawalOrder;
        }
    }

    return {
        dialogState,
        phoneNumber,
        currency,
        withdrawalOrder,
        confirmationMethod,
        amountRef,
        onSubmit,
        close,
        onConfirmCodeClose,
        onCodeSuccess
    };
}
