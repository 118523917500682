import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { appLogger } from "@sportaq/common/utils/logger";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import {
    disableWebDisconnectTimer,
    enableWebDisconnectTimer
} from "@sportaq/vue/components/web/disconnect-worker/idle-disconnect";

export function useHorseGameViewCommon (eventName: string) {
    const store = useWebStore();
    const isLoggedIn = computed(() => {
        const user = store.user;
        return user !== undefined;
    });
    const router = useRouter();
    const isResetPasswordDialogVisible = ref(false);
    watch(isLoggedIn, () => {
        goHome();
    });
    function onLoginClose () {
        goHome();
    }
    function goHome () {
        if (!isLoggedIn.value) {
            router.push({ name: "home" }).catch(reason => appLogger.logger.warn(reason));
        }
    }
    onMounted(() => {
        disableWebDisconnectTimer();
        trackEventFacebook(eventName);
    });

    onUnmounted(() => {
        enableWebDisconnectTimer();
    });
    return {
        isLoggedIn,
        isResetPasswordDialogVisible,
        onLoginClose
    };
}
