import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { Route } from "@sportaq/common/types/interfaces";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";

export function getCasinoGameDirectLink (route: Route, gameType: ECasinoGameType | undefined, gameId: string | undefined) {
    let basePath;
    switch (gameType) {
        case ECasinoGameType.Virtual: {
            basePath = COMMON_ROUTES.VIRTUAL.path;
            break;
        }
        case ECasinoGameType.Casino: {
            basePath = COMMON_ROUTES.CASINO.path;
            break;
        }
        default : {
            basePath = route.path;
            if (route.path.startsWith(COMMON_ROUTES.VIRTUAL.path)) {
                basePath = COMMON_ROUTES.VIRTUAL.path;
            }
            if (route.path.startsWith(COMMON_ROUTES.CASINO.path)) {
                basePath = COMMON_ROUTES.CASINO.path;
            }
        }
    }
    return gameId ? basePath + `/${encodeURIComponent(gameId)}` : basePath;
}

export function updateGameUrl (route: Route, gameType: ECasinoGameType | undefined, gameId: string | undefined) {
    const path = getCasinoGameDirectLink(route, gameType, gameId);
    if (!window.location.href.endsWith(path)) {
        history.pushState({}, "", path);
    }
}
