import { IntlNumberFormats, PluralizationRulesMap } from "vue-i18n";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import { DateTimeFormats as IntlDateTimeFormats } from "@intlify/core-base";
import { ESupportedLocale, I18nSettings } from "@sportaq/i18n/interfaces/interfaces";
import { CypressWindow } from "@sportaq/vue/src/components/common/dependency-container/cypress-window";
import {
    SharedDependencyProvider
} from "@sportaq/vue/src/components/common/dependency-container/shared-dependency-container";

export namespace I18nSetup {
    export const langCookieName = "lang";
    export const dateTimeFormats: IntlDateTimeFormats = {
        en: {
            short: {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            },
            long: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        },
        es: {
            short: {
                year: "numeric",
                month: "numeric",
                day: "numeric"
            },
            long: {
                year: "numeric",
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        },
        ru: {
            short: {
                day: "numeric",
                month: "numeric",
                year: "numeric"
            },
            long: {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: false
            },
            time: {
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }
        }
    };

    export const numberFormats: IntlNumberFormats = {
        en: {
            currency: {
                style: "decimal",
                minimumFractionDigits: BIG_DECIMAL_SCALES.MONEY,
                maximumFractionDigits: BIG_DECIMAL_SCALES.MONEY
            }
        },
        es: {
            currency: {
                style: "decimal",
                minimumFractionDigits: BIG_DECIMAL_SCALES.MONEY,
                maximumFractionDigits: BIG_DECIMAL_SCALES.MONEY
            }
        },
        pt: {
            currency: {
                style: "decimal",
                minimumFractionDigits: BIG_DECIMAL_SCALES.MONEY,
                maximumFractionDigits: BIG_DECIMAL_SCALES.MONEY
            }
        },
        ru: {
            currency: {
                style: "decimal",
                minimumFractionDigits: BIG_DECIMAL_SCALES.MONEY,
                maximumFractionDigits: BIG_DECIMAL_SCALES.MONEY
            }
        }
    };

    export const pluralRules: PluralizationRulesMap = {
        [ESupportedLocale.RU]: function (choice, choicesLength) {
            // this === VueI18n instance, so the locale property also exists here
            if (choice <= 0) {
                return 0;
            }

            const teen = choice > 10 && choice < 20;
            const endsWithOne = choice % 10 === 1;

            if (choicesLength < 4) {
                return (!teen && endsWithOne) ? 1 : 2;
            }
            if (!teen && endsWithOne) {
                return 1;
            }
            if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
                return 2;
            }

            return (choicesLength < 4) ? 2 : 3;
        }
    };

    function resolveExternalI18nSettings (): I18nSettings {
        if (window) {
            const cypressWindow = window as unknown as CypressWindow<SharedDependencyProvider>;
            if (cypressWindow.Cypress && cypressWindow.i18nSettings) {
                window.localStorage.setItem(langCookieName, cypressWindow.i18nSettings.defaultLanguage);
                return cypressWindow.i18nSettings;
            }
        }
        return {
            disabledLanguages: [ESupportedLocale.RU, ESupportedLocale.PT],
            defaultLanguage: ESupportedLocale.ES
        };
    }

    export const i18nSettings = resolveExternalI18nSettings();
}
