import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getVolleyballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.popular",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2,
                title: "detailInfo.groups.volleyball.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.volleyball_total,
                title: "detailInfo.groups.volleyball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.volleyball_handicap,
                title: "detailInfo.groups.volleyball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam1,
                title: "detailInfo.groups.volleyball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam2,
                title: "detailInfo.groups.volleyball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddMatch,
                title: "detailInfo.groups.volleyball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.volleyball_matchScore,
                title: "detailInfo.groups.volleyball.matchScore",
                quotationIds: [
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            },
            {
                id: EDetailRow.volleyball_scoreAfter2Games,
                title: "detailInfo.groups.volleyball.scoreAfter2Games",
                quotationIds: [
                    [890, 891, 892]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2,
                title: "detailInfo.groups.volleyball.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.volleyball_total,
                title: "detailInfo.groups.volleyball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.volleyball_handicap,
                title: "detailInfo.groups.volleyball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam1,
                title: "detailInfo.groups.volleyball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam2,
                title: "detailInfo.groups.volleyball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddMatch,
                title: "detailInfo.groups.volleyball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set1,
                title: "detailInfo.groups.volleyball.x1_2Set1",
                quotationIds: [
                    [431, 433]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet1,
                title: "detailInfo.groups.volleyball.totalSet1",
                quotationIds: [
                    [444, 445]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet1,
                title: "detailInfo.groups.volleyball.handicapSet1",
                quotationIds: [
                    [438, 439]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet1,
                title: "detailInfo.groups.volleyball.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set2,
                title: "detailInfo.groups.volleyball.x1_2Set2",
                quotationIds: [
                    [434, 435]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet2,
                title: "detailInfo.groups.volleyball.totalSet2",
                quotationIds: [
                    [446, 447]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet2,
                title: "detailInfo.groups.volleyball.handicapSet2",
                quotationIds: [
                    [440, 441]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet2,
                title: "detailInfo.groups.volleyball.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set3,
                title: "detailInfo.groups.volleyball.x1_2Set3",
                quotationIds: [
                    [436, 437]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet3,
                title: "detailInfo.groups.volleyball.totalSet3",
                quotationIds: [
                    [448, 449]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet3,
                title: "detailInfo.groups.volleyball.handicapSet3",
                quotationIds: [
                    [442, 443]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet3,
                title: "detailInfo.groups.volleyball.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set4,
                title: "detailInfo.groups.volleyball.x1_2Set4",
                quotationIds: [
                    [644, 645]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet4,
                title: "detailInfo.groups.volleyball.totalSet4",
                quotationIds: [
                    [652, 653]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet4,
                title: "detailInfo.groups.volleyball.handicapSet4",
                quotationIds: [
                    [648, 649]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet4,
                title: "detailInfo.groups.volleyball.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set5,
                title: "detailInfo.groups.volleyball.x1_2Set5",
                quotationIds: [
                    [646, 647]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet5,
                title: "detailInfo.groups.volleyball.totalSet5",
                quotationIds: [
                    [654, 655]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet5,
                title: "detailInfo.groups.volleyball.handicapSet5",
                quotationIds: [
                    [650, 651]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet5,
                title: "detailInfo.groups.volleyball.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            },
            {
                id: EDetailRow.volleyball_matchScore,
                title: "detailInfo.groups.volleyball.matchScore",
                quotationIds: [
                    [17, 20],
                    [18, 21],
                    [19, 22]
                ]
            },
            {
                id: EDetailRow.volleyball_scoreAfter2Games,
                title: "detailInfo.groups.volleyball.scoreAfter2Games",
                quotationIds: [
                    [890, 891, 892]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicap,
                title: "detailInfo.groups.volleyball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet1,
                title: "detailInfo.groups.volleyball.handicapSet1",
                quotationIds: [
                    [438, 439]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet2,
                title: "detailInfo.groups.volleyball.handicapSet2",
                quotationIds: [
                    [440, 441]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet3,
                title: "detailInfo.groups.volleyball.handicapSet3",
                quotationIds: [
                    [442, 443]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet4,
                title: "detailInfo.groups.volleyball.handicapSet4",
                quotationIds: [
                    [648, 649]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet5,
                title: "detailInfo.groups.volleyball.handicapSet5",
                quotationIds: [
                    [650, 651]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalMatch,
                title: "detailInfo.groups.volleyball.totalMatch",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet1,
                title: "detailInfo.groups.volleyball.totalSet1",
                quotationIds: [
                    [444, 445]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet2,
                title: "detailInfo.groups.volleyball.totalSet2",
                quotationIds: [
                    [446, 447]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet3,
                title: "detailInfo.groups.volleyball.totalSet3",
                quotationIds: [
                    [448, 449]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet4,
                title: "detailInfo.groups.volleyball.totalSet4",
                quotationIds: [
                    [652, 653]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet5,
                title: "detailInfo.groups.volleyball.totalSet5",
                quotationIds: [
                    [654, 655]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam1,
                title: "detailInfo.groups.volleyball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.volleyball_individualTotalTeam2,
                title: "detailInfo.groups.volleyball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddMatch,
                title: "detailInfo.groups.volleyball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet1,
                title: "detailInfo.groups.volleyball.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet2,
                title: "detailInfo.groups.volleyball.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet3,
                title: "detailInfo.groups.volleyball.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet4,
                title: "detailInfo.groups.volleyball.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet5,
                title: "detailInfo.groups.volleyball.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.volleyball.sets",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set1,
                title: "detailInfo.groups.volleyball.x1_2Set1",
                quotationIds: [
                    [431, 433]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet1,
                title: "detailInfo.groups.volleyball.totalSet1",
                quotationIds: [
                    [444, 445]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet1,
                title: "detailInfo.groups.volleyball.handicapSet1",
                quotationIds: [
                    [438, 439]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet1,
                title: "detailInfo.groups.volleyball.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set2,
                title: "detailInfo.groups.volleyball.x1_2Set2",
                quotationIds: [
                    [434, 435]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet2,
                title: "detailInfo.groups.volleyball.totalSet2",
                quotationIds: [
                    [446, 447]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet2,
                title: "detailInfo.groups.volleyball.handicapSet2",
                quotationIds: [
                    [440, 441]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet2,
                title: "detailInfo.groups.volleyball.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set3,
                title: "detailInfo.groups.volleyball.x1_2Set3",
                quotationIds: [
                    [436, 437]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet3,
                title: "detailInfo.groups.volleyball.totalSet3",
                quotationIds: [
                    [448, 449]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet3,
                title: "detailInfo.groups.volleyball.handicapSet3",
                quotationIds: [
                    [442, 443]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet3,
                title: "detailInfo.groups.volleyball.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set4,
                title: "detailInfo.groups.volleyball.x1_2Set4",
                quotationIds: [
                    [644, 645]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet4,
                title: "detailInfo.groups.volleyball.totalSet4",
                quotationIds: [
                    [652, 653]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet4,
                title: "detailInfo.groups.volleyball.handicapSet4",
                quotationIds: [
                    [648, 649]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet4,
                title: "detailInfo.groups.volleyball.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            },
            {
                id: EDetailRow.volleyball_x1_2Set5,
                title: "detailInfo.groups.volleyball.x1_2Set5",
                quotationIds: [
                    [646, 647]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet5,
                title: "detailInfo.groups.volleyball.totalSet5",
                quotationIds: [
                    [654, 655]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet5,
                title: "detailInfo.groups.volleyball.handicapSet5",
                quotationIds: [
                    [650, 651]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet5,
                title: "detailInfo.groups.volleyball.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}

export function getVolleyballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set1,
                title: "detailInfo.groups.volleyball.x1_2Set1",
                quotationIds: [
                    [431, 433]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet1,
                title: "detailInfo.groups.volleyball.totalSet1",
                quotationIds: [
                    [444, 445]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet1,
                title: "detailInfo.groups.volleyball.handicapSet1",
                quotationIds: [
                    [438, 439]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet1,
                title: "detailInfo.groups.volleyball.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicapSet1,
                title: "detailInfo.groups.volleyball.handicapSet1",
                quotationIds: [
                    [438, 439]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalSet1,
                title: "detailInfo.groups.volleyball.totalSet1",
                quotationIds: [
                    [444, 445]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet1,
                title: "detailInfo.groups.volleyball.evenOddSet1",
                quotationIds: [
                    [1030, 1029]
                ]
            }
        ]
    });
    return result;
}

export function getVolleyballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set2,
                title: "detailInfo.groups.volleyball.x1_2Set2",
                quotationIds: [
                    [434, 435]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet2,
                title: "detailInfo.groups.volleyball.totalSet2",
                quotationIds: [
                    [446, 447]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet2,
                title: "detailInfo.groups.volleyball.handicapSet2",
                quotationIds: [
                    [440, 441]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet2,
                title: "detailInfo.groups.volleyball.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicapSet2,
                title: "detailInfo.groups.volleyball.handicapSet2",
                quotationIds: [
                    [440, 441]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalSet2,
                title: "detailInfo.groups.volleyball.totalSet2",
                quotationIds: [
                    [446, 447]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet2,
                title: "detailInfo.groups.volleyball.evenOddSet2",
                quotationIds: [
                    [1032, 1031]
                ]
            }
        ]
    });
    return result;
}

export function getVolleyballPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set3,
                title: "detailInfo.groups.volleyball.x1_2Set3",
                quotationIds: [
                    [436, 437]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet3,
                title: "detailInfo.groups.volleyball.totalSet3",
                quotationIds: [
                    [448, 449]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet3,
                title: "detailInfo.groups.volleyball.handicapSet3",
                quotationIds: [
                    [442, 443]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet3,
                title: "detailInfo.groups.volleyball.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicapSet3,
                title: "detailInfo.groups.volleyball.handicapSet3",
                quotationIds: [
                    [442, 443]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalSet3,
                title: "detailInfo.groups.volleyball.totalSet3",
                quotationIds: [
                    [448, 449]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet3,
                title: "detailInfo.groups.volleyball.evenOddSet3",
                quotationIds: [
                    [1034, 1033]
                ]
            }
        ]
    });
    return result;
}

export function getVolleyballPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set4,
                title: "detailInfo.groups.volleyball.x1_2Set4",
                quotationIds: [
                    [644, 645]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet4,
                title: "detailInfo.groups.volleyball.totalSet4",
                quotationIds: [
                    [652, 653]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet4,
                title: "detailInfo.groups.volleyball.handicapSet4",
                quotationIds: [
                    [648, 649]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet4,
                title: "detailInfo.groups.volleyball.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicapSet4,
                title: "detailInfo.groups.volleyball.handicapSet4",
                quotationIds: [
                    [648, 649]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalSet4,
                title: "detailInfo.groups.volleyball.totalSet4",
                quotationIds: [
                    [652, 653]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet4,
                title: "detailInfo.groups.volleyball.evenOddSet4",
                quotationIds: [
                    [1036, 1035]
                ]
            }
        ]
    });
    return result;
}

export function getVolleyballPeriod5Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.volleyball.all",
        rows: [
            {
                id: EDetailRow.volleyball_x1_2Set5,
                title: "detailInfo.groups.volleyball.x1_2Set5",
                quotationIds: [
                    [646, 647]
                ]
            },
            {
                id: EDetailRow.volleyball_totalSet5,
                title: "detailInfo.groups.volleyball.totalSet5",
                quotationIds: [
                    [654, 655]
                ]
            },
            {
                id: EDetailRow.volleyball_handicapSet5,
                title: "detailInfo.groups.volleyball.handicapSet5",
                quotationIds: [
                    [650, 651]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet5,
                title: "detailInfo.groups.volleyball.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.volleyball.handicaps",
        rows: [
            {
                id: EDetailRow.volleyball_handicapSet5,
                title: "detailInfo.groups.volleyball.handicapSet5",
                quotationIds: [
                    [650, 651]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.volleyball.totals",
        rows: [
            {
                id: EDetailRow.volleyball_totalSet5,
                title: "detailInfo.groups.volleyball.totalSet5",
                quotationIds: [
                    [654, 655]
                ]
            },
            {
                id: EDetailRow.volleyball_evenOddSet5,
                title: "detailInfo.groups.volleyball.evenOddSet5",
                quotationIds: [
                    [1038, 1037]
                ]
            }
        ]
    });
    return result;
}
