<template>
    <div class="event" :data-cy="`scoreboard-line-${event.positionId}`">
        <div :class="['details-head', {live: event.liveBetting, longer: isBaseball}]" @click="showDetails" :data-cy="`scoreboard-header-of-line-${event.positionId}`">
            <div class="subtitle">
                <div class="live" v-if="event.liveBetting">LIVE</div>
                <template v-if="!isLeagueMode">
                    <img :src="`/img/flags/${flag}.png`" alt="" class="flag"/>
                    <span :title="partitionLabel">{{ partitionLabel }}</span>
                </template>
            </div>
            <div class="date"  v-if="!event.liveBetting">{{ event.startTimeStr }}</div>
            <div class="title">
                <div class="title-left">
                    <div class="name" v-for="participant of participants" :key="participant.id"> {{ participant.name }}</div>
                </div>
                <div class="title-right" v-if="isLive">
                    <div class="counts" v-for="(row, rowIndex) of score" :key="rowIndex">
                        <div :class="['item', index===0 ? 'active' : '']" v-for="(item, index) of row" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="date">{{ periodInfo }}</div>
            <div class="info">
                <div :class="['star', isFavorite? 'active':'']" @click.stop="toggleFavorite" data-cy="favorite-star">
                    <svg viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path
                            d="M10 0.363037L12.2451 7.27287H19.5106L13.6327 11.5434L15.8779 18.4532L10 14.1827L4.12215 18.4532L6.36729 11.5434L0.489435 7.27287H7.75486L10 0.363037Z"
                            fill="#EA5D24"/>
                    </svg>
                </div>
                <div class="count">+{{ allCount }}</div>
            </div>
        </div>
        <compact-quotation-group :sport-type="event.sportTypeId" :quotations="quotationArr" :participants="participants"
                                :event-blocked="event.blocked" :titleTemplate="extraProps.viewTitle"/>
    </div>
</template>

<script src="./compact-scoreboard-event-line-component.ts"/>
