import { onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";
import { useSettings } from "@sportaq/services/index";
import { setTitle } from "@sportaq/vue/components/web/page-title";
import { getSportTypeByName } from "@sportaq/common/enums/sport-type";

export function useBettingPageTitle () {
    const i18n = useI18n();
    const settings = useSettings();
    function updateTitle (eventType: string, sportType: string) {
        const brandName = i18n.t("brands." + settings.brandType);
        const sportName = i18n.t("sports." + getSportTypeByName(sportType));
        setTitle(i18n.t("webdesktop.pageTitle." + eventType + ".title", { brand: brandName, sport: sportName }),
            i18n.t("webdesktop.pageTitle." + eventType + ".description", { brand: brandName, sport: sportName }));
    }
    onBeforeRouteUpdate((to) => {
        updateTitle(to.params.eventType.toString(), to.params.sportType.toString());
    });

    return {
        updateTitle
    };
}
