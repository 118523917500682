import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { ItemOrderSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/dictionaries/item-order-supplier";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    forEach,
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { INTERNATIONAL_COUNTRY_ID } from "@sportaq/common/consts/default-consts";
import { IdItem } from "@sportaq/common/types/types";
import { countries } from "@sportaq/model/consts/countries";

export class QLe112GetCountriesOrderCountryFilter extends AbstractMessageHandler<Map<number, ItemOrderSupplier<IdItem>>> {
    protected readonly requestType: string = "Q.LE.1.12";

    constructor (private countryId: number) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const countryCode = countries.getById(this.countryId)?.alpha2 ?? "";
        request.addChildWithValue(request.head, "country", countryCode);
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    protected parseMessageBody (body: Element): Map<number, ItemOrderSupplier<IdItem>> {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode === "100") {
            const sportMap: Map<number, CountryOrder[]> = new Map<number, CountryOrder[]>();
            const list = getRequiredChild(query, "SportCountryList");
            forEach(list, "SC", el => {
                const sportType = getRequiredIntAttribute(el, "sportTypeId");
                const countryId = getRequiredIntAttribute(el, "countryId");
                const order = getRequiredIntAttribute(el, "orderNum");
                let items = sportMap.get(sportType);
                if (!items) {
                    items = [];
                    sportMap.set(sportType, items);
                }
                items.push({
                    countryId: countryId > 0 ? countryId : INTERNATIONAL_COUNTRY_ID,
                    order
                });
            });
            const result = new Map<number, ItemOrderSupplier<IdItem>>();
            sportMap.forEach((value, key) => {
                const sortedCountries = value.sort((a, b) => {
                    let result = a.order - b.order;
                    if (result === 0) {
                        const countryA = countries.getById(a.countryId)?.name ?? "";
                        const countryB = countries.getById(a.countryId)?.name ?? "";
                        result = countryA.localeCompare(countryB);
                    }
                    return result;
                }).map(v => v.countryId);
                result.set(key, new ItemOrderSupplier(sortedCountries.map(v => ({ id: v }))));
            });
            return result;
        }
        throw new Error(`errors.mtl.qLe112.code${serverCode}`);
    }
}

interface CountryOrder {
    countryId: number;
    order: number;
}
