import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { computed, ref } from "vue";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import { isPaymentSystemPresent, PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { useRootStore } from "@sportaq/vuex/index";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useTextComponentCommon } from "@sportaq/vue/views/web/rules/components/text-component-common";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export function useDepositViewCommon () {
    const rootStore = useRootStore();
    const store = useWebStore();
    const balanceStore = useBalanceStore();
    const settings = useWebSettings();
    const isLoggedId = computed(() => store.user !== undefined);
    const restService = useWebRestService();
    const depositMethod = ref<PaymentSystems>();
    const amountInputDialogVisible = ref(false);
    const voucherDialogVisible = ref(false);
    const currencyValues = ref<Map<number, number>>();
    const redirectUrl = ref<string>();
    const needShowBonusNotification = ref(isLoggedId.value && (store.user?.sessions === undefined || store.user?.sessions.length <= 1) && !balanceStore.initialized);
    const countryId = computed(() => rootStore.userSharedInfo.countryId);
    const { brandName } = useTextComponentCommon();

    restService.getCurrencyInfo().then(r => {
        currencyValues.value = r;
    });

    function depositSafetyPay () {
        depositMethod.value = isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET_CASH)
            ? PaymentSystems.DEPOSIT_MONNET_CASH
            : PaymentSystems.DEPOSIT_MONNET;
        amountInputDialogVisible.value = true;
        trackEventFacebook("InitiateCheckout");
    }

    function depositPagoEfectivo () {
        depositMethod.value = PaymentSystems.PAGOEFECTIVO_INVOICE;
        amountInputDialogVisible.value = true;
        trackEventFacebook("InitiateCheckout");
    }

    function depositMonnetCard () {
        depositMethod.value = PaymentSystems.DEPOSIT_MONNET_CARD;
        amountInputDialogVisible.value = true;
        trackEventFacebook("InitiateCheckout");
    }
    function depositTupay () {
        depositMethod.value = PaymentSystems.DEPOSIT_TUPAY;
        amountInputDialogVisible.value = true;
        trackEventFacebook("InitiateCheckout");
    }

    function depositPagoSeguro () {
        depositMethod.value = isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET_CASH)
            ? PaymentSystems.DEPOSIT_MONNET_CASH
            : PaymentSystems.DEPOSIT_MONNET;
        amountInputDialogVisible.value = true;
        trackEventFacebook("InitiateCheckout");
    }

    function onAmountInputClose () {
        amountInputDialogVisible.value = false;
    }

    function onAmountConfirmed (amount: number, currencyId: number) {
        if (depositMethod.value) {
            restService.deposit(depositMethod.value, amount, currencyId).then(d => {
                redirectUrl.value = d.redirectUrl;
            });
        }
    }

    function onExternalServiceFrameClose () {
        redirectUrl.value = undefined;
    }

    function depositWithTicket () {
        trackEventFacebook("InitiateCheckout");
        voucherDialogVisible.value = true;
    }

    function onVoucherDepositDialogClose () {
        voucherDialogVisible.value = false;
    }

    function openYape () {
        trackEventFacebook("InitiateCheckout");
        window.open("https://api.whatsapp.com/send?phone=+51990268077&text=Hola,%20estoy%20en%20la%20pagina%20de%20Betgol%20y%20quiero%20hacer%20una%20recarga%20con%20Yape", "_blank");
    }

    function openLukita () {
        trackEventFacebook("InitiateCheckout");
        window.open("https://api.whatsapp.com/send?phone=+51990268077&text=Hola,%20estoy%20en%20la%20pagina%20de%20Betgol%20y%20quiero%20hacer%20una%20recarga%20con%20%20Lukita", "_blank");
    }

    function openTunki () {
        trackEventFacebook("InitiateCheckout");
        window.open("https://api.whatsapp.com/send?phone=+51990268077&text=Hola,%20estoy%20en%20la%20pagina%20de%20Betgol%20y%20quiero%20hacer%20una%20recarga%20con%20Tunki", "_blank");
    }

    function isPaymentSystemAvailable (paymentSystem: PaymentSystems) {
        return isPaymentSystemPresent(getPaymentSystems(), paymentSystem);
    }

    function getPaymentSystems () {
        return store.user !== undefined ? store.user.paymentSystems : settings.pointSettings.paymentSystems;
    }

    function getPaymentSystemMin (paymentSystems: PaymentSystems[]) {
        return getPaymentSystems().find(s => paymentSystems.find(p => p === s.paymentSystemType))
            ?.availableCurrencies.map(c => {
                const v = currencyValues.value?.get(c);
                return v ? (v * 5).toFixed(2) + getCurrencyName(c) : "";
            });
    }

    return {
        isLoggedId,
        amountInputDialogVisible,
        depositMethod,
        redirectUrl,
        voucherDialogVisible,
        countryId,
        needShowBonusNotification,
        brandName,
        currencyValues,
        getPaymentSystemMin,
        openYape,
        openLukita,
        openTunki,
        onAmountInputClose,
        onAmountConfirmed,
        depositSafetyPay,
        depositPagoEfectivo,
        depositWithTicket,
        depositMonnetCard,
        depositPagoSeguro,
        depositTupay,
        onExternalServiceFrameClose,
        onVoucherDepositDialogClose,
        isPaymentSystemAvailable
    };
}
