export enum BannerScreen { homeScreen = 0, secondMonitor = 1}

export interface Banner {
    id: number;
    image: string;
    name: string;
    orderNum: number;
    onclickUrl: string;
    screenId: BannerScreen;
}
