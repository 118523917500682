import { Comparable } from "@sportaq/common/types/interfaces";
import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import { StringIdItem } from "@sportaq/common/types/types";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";

export interface CasinoGameCompareKey {
    readonly classifiers: number;
    readonly releaseDate: number;
    readonly title: string;
    readonly id: string;
    readonly order: number;
}

export class CasinoGame implements Comparable<CasinoGame>, StringIdItem, CasinoGameCompareKey {
    readonly id: string;
    readonly provider: number;
    readonly gameType: ECasinoGameType;

    title: string;
    image: string;
    releaseDate: number;
    classifiers: number;
    hasDemo: boolean;
    order: number;
    version: number;

    constructor (id: string, provider: number, gameType: ECasinoGameType, title: string, image: string, releaseDate: number, classifiers: number, hasDemo: boolean, order: number, version: number) {
        this.id = id;
        this.provider = provider;
        this.gameType = gameType;
        this.title = title;
        this.image = image;
        this.releaseDate = releaseDate;
        this.classifiers = classifiers;
        this.hasDemo = hasDemo;
        this.order = order;
        this.version = version;
    }

    compare (o: CasinoGame): number {
        return compareGames(this, o);
    }

    update (response: CasinoGameDiffsResponse) {
        this.version = response.version;

        if (response.title) {
            this.title = response.title;
        }
        if (response.image) {
            this.image = response.image;
        }
        if (response.releaseDate) {
            this.releaseDate = response.releaseDate;
        }
        if (response.classifiers) {
            this.classifiers = response.classifiers;
        }
        if (response.order) {
            this.order = response.order;
        }
    }

    clone (): CasinoGame {
        return new CasinoGame(this.id, this.provider, this.gameType, this.title, this.image, this.releaseDate, this.classifiers, this.hasDemo, this.order, this.version);
    }

    static newFromResponse (response: CasinoGameDiffsResponse): CasinoGame {
        return new CasinoGame(
            response.id,
            response.provider,
            response.virtualGame ? ECasinoGameType.Virtual : ECasinoGameType.Casino,
            response.title ?? "__UNKNOWN_TITLE__",
            response.image ?? "__UNKNOWN_IMAGE__",
            response.releaseDate ?? 0,
            response.classifiers ?? 0,
            response.hasDemo ?? false,
            response.order ?? 0,
            response.version
        );
    }
}

export function compareGames (game1: CasinoGameCompareKey, game2: CasinoGameCompareKey): number {
    let result = game2.order - game1.order;
    if (result === 0) {
        result = game2.releaseDate - game1.releaseDate;
    }
    if (result === 0) {
        const oNewGame = game2.classifiers & ECasinoGameClassifier.NEW;
        const newGame = game2.classifiers & ECasinoGameClassifier.NEW;
        result = oNewGame - newGame;
    }
    if (result === 0) {
        const oPopularGame = game2.classifiers & ECasinoGameClassifier.POPULAR;
        const popularGame = game1.classifiers & ECasinoGameClassifier.POPULAR;
        result = oPopularGame - popularGame;
    }
    if (result === 0) {
        result = game1.title.localeCompare(game2.title);
    }
    if (result === 0) {
        result = game1.id.localeCompare(game2.id);
    }
    return result;
}
