<template>
    <li class="form-line">
        <div class="checkbox" :class="{active: isSelected}" @click.stop="filterByPartition"
             :data-cy="`partition-filter-${partition.id}`">
            <div class="checkbox-label">
                <span>
                    <span class="sport-type" v-if="partition.sportTypeName">
                        {{ partition.sportTypeName }}.&nbsp;
                    </span>
                    {{ partition.name }}&nbsp;
                </span>
                <span class="count" data-cy="count">({{ partition.count }})</span>
            </div>
            <span class="star" :class="{active: isFavourite}" @click.stop="toggleFavorite"
                  data-cy="favorite-star" v-if="!favouriteMode"></span>
        </div>
    </li>
</template>

<script src="./betting-filter-menu-partition-component.ts"/>
