<template>
    <div class="coupon-block-item" :data-cy="stake.templateKey">
        <div class="info">
            <div class="info-subtitle">
                <div class="live" v-if="isLive" data-cy="live">LIVE</div>
                <div class="date" data-cy="date">{{ eventDate }} {{ eventTime }}</div>
                <div class="name" data-cy="partition">{{ stake.event.partitionName }}</div>
            </div>
            <div class="info-title">
                <span class="name" v-for="(participant, index) of participants" :key="participant.id"
                      :data-cy="`participant-${index+1}`">{{ participant.name }}</span>
            </div>
        </div>
        <div class="info-line">
            <div class="info-line-name" data-cy="quotation-title"><span>{{ quotationTitle }}</span></div>
            <div class="info-line-rate" v-html="fullQuotationLabel" data-cy="quotation-label"></div>
        </div>
        <div class="info-line" v-if="standalone">
            <div class="info-line-name" data-cy="amount">{{ $t("betting.betSlip.dialogBetAccepted.columns.amount") }} :
                {{ $n(stake.amount, "currency") }}
            </div>
            <div class="info-line-name" data-cy="maxWin">{{ $t("betting.betSlip.dialogBetAccepted.columns.maxWin") }} :
                {{ $n(maxWin, "currency") }}
            </div>
        </div>
    </div>
</template>

<script src="./mobile-simple-stake-row-component.ts"/>
