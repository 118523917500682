import { BettingEvent } from "@sportaq/model/betting/events/event";
import {
    GAP_QUOTATION,
    isQuotationPresent,
    LOCKED_QUOTATION, Quotation,
    QuotationWrapper
} from "@sportaq/model/betting/events/quotation";
import { EQuotation, isHandicap, isInterval, isTotal } from "@sportaq/common/consts/quotation-consts";
import { pairingHandicaps } from "@sportaq/model/betting/events/pairing/pairing-handicaps";
import { pairingTotals } from "@sportaq/model/betting/events/pairing/pairing-totals";
import EDetailRow from "@sportaq/common/enums/detail-row";
import { DetailRow, DetailTab, QuotationRow } from "@sportaq/model/betting/view/event-details/event-details-info";
import EventType from "@sportaq/common/enums/event-type";
import { appLogger } from "@sportaq/common/utils/logger";
import {
    rowHasBeenHandledAsTotalGroup
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-details/utils/handlers/recalculate-total-group-row";
import { listToColumnMatrix } from "@sportaq/common/utils/arrays";
import { EDetailsViewType } from "@sportaq/model/betting/view/scoreboard-selection";

const INTERVAL_REGEXP = /^.*x(\d+)x(\d+)$/;

export function scoreBoardDetailsRecalculateQuotationColumns (event: BettingEvent, tab: DetailTab, viewType: EDetailsViewType, betSlipShowed: boolean, filterShowed: boolean): QuotationRow[][] {
    const rows = [] as QuotationRow[];
    for (const row of tab.rows) {
        if (rowHasBeenHandledAsTotalGroup(rows, tab.rows, event, row)) {
            continue;
        }
        let currentRow: QuotationRow;
        switch (row.id) {
            case EDetailRow.football_matchScore:
            case EDetailRow.tableTennis_matchScore:
            case EDetailRow.tennis_matchScore:
            case EDetailRow.volleyball_matchScore: {
                currentRow = recalculateMatchScoreRow(event, row);
                break;
            }
            default: {
                currentRow = recalculateDefaultRow(event, row);
                break;
            }
        }
        if (currentRow.quotations.length > 0) {
            rows.push(currentRow);
        }
    }
    // issue #55. If details is empty, all tabs should be shown
    if (rows.length === 0) {
        fillAllTabQuotations(event.eventType, rows, tab);
    }
    const columnCount = viewType === EDetailsViewType.Mobile || viewType === EDetailsViewType.Inline ? 1 : Math.min(betSlipShowed || filterShowed ? 2 : 3, rows.length);
    return listToColumnMatrix(rows, columnCount);
}

function recalculateMatchScoreRow (event: BettingEvent, row: DetailRow): QuotationRow {
    const result: QuotationRow = {
        id: row.id,
        title: row.title,
        quotations: [],
        p1: undefined
    };
    const size = row.quotationIds.length;
    const columnCount = row.quotationIds[0].length;
    const quotations: Quotation[][] = [];
    for (let i = 0; i < columnCount; i++) {
        const arr: Quotation[] = [];
        for (let j = 0; j < size; j++) {
            const quotationsByQuotationId = event.quotations.getQuotationsByQuotationId(row.quotationIds[j][i]);
            if (quotationsByQuotationId.length === 1) {
                arr.push(quotationsByQuotationId[0]);
            }
        }
        quotations.push(arr);
    }
    let maxLength = 0;
    for (let i = 0; i < columnCount; i++) {
        maxLength = Math.max(maxLength, quotations[i].length);
    }
    for (let i = 0; i < maxLength; i++) {
        const row: QuotationWrapper[] = [];
        for (let j = 0; j < columnCount; j++) {
            row.push(getQuotationByIndex(event.eventType, quotations[j], i));
        }
        result.quotations.push(row);
    }
    return result;
}

function getQuotationByIndex (eventType: EventType, arr: Quotation[], index: number): QuotationWrapper {
    return index < arr.length
        ? {
            eventType,
            quotation: arr[index]
        }
        : {
            eventType,
            quotation: GAP_QUOTATION
        };
}

function recalculateDefaultRow (event: BettingEvent, row: DetailRow): QuotationRow {
    const result: QuotationRow = {
        id: row.id,
        title: row.title,
        quotations: [],
        p1: undefined
    };
    for (const ids of row.quotationIds) {
        const columns: QuotationWrapper[][] = [];
        let handlerType = QuotationHandlerType.Default;
        for (const quotationId of ids) {
            if (isHandicap(quotationId)) {
                handlerType = QuotationHandlerType.Handicap;
            }
            if (isTotal(quotationId)) {
                handlerType = QuotationHandlerType.Total;
            }
            if (isInterval(quotationId)) {
                handlerType = QuotationHandlerType.Interval;
            }
            switch (quotationId) {
                case EQuotation.LOCKED_QUOTATION: {
                    columns.push([{
                        eventType: event.eventType,
                        quotation: LOCKED_QUOTATION
                    }]);
                    break;
                }
                case EQuotation.GAP_QUOTATION: {
                    columns.push([{
                        eventType: event.eventType,
                        quotation: GAP_QUOTATION
                    }]);
                    break;
                }
                default: {
                    const quotations = event.quotations.getQuotationsByQuotationId(quotationId);
                    if (quotations.length > 0) {
                        columns.push(quotations.map(v => ({
                            eventType: event.eventType,
                            quotation: v
                        })));
                    } else {
                        columns.push([{
                            eventType: event.eventType,
                            quotation: LOCKED_QUOTATION
                        }]);
                    }
                }
            }
        }
        if (columns.length === 1) {
            handlerType = QuotationHandlerType.SingleColumn;
        }
        switch (handlerType) {
            case QuotationHandlerType.SingleColumn: {
                handleSingleColumn(columns, result);
                break;
            }
            case QuotationHandlerType.Handicap: {
                pairingHandicaps(event.eventType, event.positionId, event.eventId, event.sportTypeId, columns, result);
                break;
            }
            case QuotationHandlerType.Total: {
                pairingTotals(event.eventType, event.positionId, event.eventId, event.sportTypeId, columns, result);
                break;
            }
            case QuotationHandlerType.Interval: {
                handleInterval(columns, result, event);
                break;
            }
            default: {
                handleDefault(columns, result, event);
            }
        }
    }
    return result;
}

function handleSingleColumn (columns: QuotationWrapper[][], currentRow: QuotationRow) {
    for (const quotation of columns[0]) {
        if (isQuotationPresent(quotation.quotation)) {
            currentRow.quotations.push([quotation]);
        }
    }
}

function handleInterval (columns: QuotationWrapper[][], currentRow: QuotationRow, event: BettingEvent) {
    if (columns.length === 2) {
        const match = INTERVAL_REGEXP.exec(currentRow.title);
        if (match) {
            const p1 = Number.parseInt(match[1]);
            const p2 = Number.parseInt(match[2]);
            const q1 = columns[0].find(v => v.quotation.key.p1.numberValue === p1 && v.quotation.key.p2.numberValue === p2) ?? {
                eventType: event.eventType,
                quotation: LOCKED_QUOTATION
            };
            const q2 = columns[1].find(v => v.quotation.key.p1.numberValue === p1 && v.quotation.key.p2.numberValue === p2) ?? {
                eventType: event.eventType,
                quotation: LOCKED_QUOTATION
            };
            if (isQuotationPresent(q1.quotation) || isQuotationPresent(q2.quotation)) {
                currentRow.quotations.push([q1, q2]);
            }
        } else {
            appLogger.logger.warn(`Error recalculate quotation rows for event ${event.eventId} [position: ${event.positionId}, ${JSON.stringify(event.partition)}] detailing: intervals current row title doesn't match regexp`, undefined, currentRow.title);
        }
    } else {
        appLogger.logger.warn(`Error recalculate quotation rows for event ${event.eventId} [position: ${event.positionId}, ${JSON.stringify(event.partition)}] detailing: intervals column count doesn't equal 2`, undefined, columns);
    }
}

function handleDefault (columns: QuotationWrapper[][], currentRow: QuotationRow, event: BettingEvent) {
    const line = [] as QuotationWrapper[];
    for (const column of columns) {
        if (column.length > 1) {
            appLogger.logger.warn(`Error recalculate quotation rows for event ${event.eventId} [position: ${event.positionId}, ${JSON.stringify(event.partition)}] detailing: it has several quotations in array, but it isn't handicap or total`, undefined, columns, column);
            break;
        }
        line.push(column[0]);
    }
    if ((line.length > 0) && line.some(v => v && isQuotationPresent(v.quotation))) {
        currentRow.quotations.push(line);
    }
}

function fillAllTabQuotations (eventType: EventType, result: QuotationRow[], tab: DetailTab) {
    for (const tabRow of tab.rows) {
        const currentRow: QuotationRow = {
            id: tabRow.id,
            title: tabRow.title,
            quotations: [],
            p1: undefined
        };
        const arr = tabRow.quotationIds[0];
        const row: QuotationWrapper[] = [];
        arr.forEach(() => {
            row.push({
                eventType,
                quotation: LOCKED_QUOTATION
            });
        });
        currentRow.quotations.push(row);
        result.push(currentRow);
    }
}

enum QuotationHandlerType {
    SingleColumn,
    Handicap,
    Total,
    Interval,
    Default
}
