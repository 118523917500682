import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { BaseCardRequest } from "@sportaq/services/rest/messages/bet-slip/base/base-card-request";

export class QSt100CardRequest extends BaseCardRequest {
    protected readonly requestType: string = "Q.ST.10.0";

    constructor (
        readonly paymentCode: string,
        readonly locale: string,
        readonly maxCoef: number,
        readonly pointInStation: boolean
    ) {
        super(locale, maxCoef);
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const stakeFilterElement = request.addChild(filterElement, "StakeFilter", {
            logic: "AND"
        });
        if (this.pointInStation) {
            const pointFilterElement = request.addChild(filterElement, "PointFilter");
            request.addChildWithValue(pointFilterElement, "PointInStation", "true");
        }
        request.addChildWithValue(stakeFilterElement, "PayCode", this.paymentCode);
        this.appendSelector(request, actionElement);
    }
}
