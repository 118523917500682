import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";

export class ACs29CasinoEnterMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.CS.2.9";

    buildRequest (request: XmlRequest): void {
        request.addChild(request.body, "action", {
            type: this.requestType
        });
    }

    protected parseMessageBody (body: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        if (serverCode !== "8220" && serverCode !== "8222") {
            // Show only error code to user
            throw new Error(serverCode);
        }
    }
}
