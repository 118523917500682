import {
    BigDecimalResponse,
    EventDiffsResponse,
    PartitionResponse,
    PeriodResponse,
    QuotationDiffResponse
} from "@sportaq/model/types/responses";
import {
    EventDiffsListMsg,
    EventDiffsMsg
} from "@sportaq/services/streaming/streaming-network/messages/responses/events/EventDiffsListMessage_pb";
import { DictionaryItem } from "@sportaq/common/types/types";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";

export class EventDiffsListResponseDeserializer {
    static deserializeBinary (message: Uint8Array): EventDiffsResponse[] {
        // performance.clearMarks();
        // performance.clearMeasures();
        // performance.mark("Parse message");
        const msg = EventDiffsListMsg.deserializeBinary(message);
        // performance.mark("Create objects");
        const result: EventDiffsResponse[] = [];
        for (const item of msg.getDiffList()) {
            result.push({
                positionId: item.getPositionid(),
                eventId: item.getEventid(),
                sportTypeId: item.getSporttypeid(),
                countryId: item.getCountryid(),
                diffType: item.getDifftype(),
                version: item.getVersion(),
                partition: EventDiffsListResponseDeserializer.getPartition(item),
                startTime: item.getStarttime()?.getValue(),
                resultText: item.getResulttext()?.getValue(),
                currentPeriod: item.getCurrentperiod()?.getValue(),
                blocked: item.getBlocked()?.getValue(),
                statusCode: item.getStatuscode()?.getValue(),
                statusMark: item.getStatusmark()?.getValue(),
                participantList: EventDiffsListResponseDeserializer.getParticipants(item),
                quotationDiffList: EventDiffsListResponseDeserializer.getQuotationDiffList(item),
                periodList: EventDiffsListResponseDeserializer.getPeriodList(item)
            });
        }
        // performance.mark("End");
        // const count = result.flatMap(value => value.quotationDiffList).length;
        // performance.measure("Parse", "Parse message", "Create objects");
        // performance.measure("Create", "Create objects", "End");
        // const entriesByType = performance.getEntriesByType("measure");
        // appLogger.logger.debug(`Size: ${message.length} [quotation count: ${count}]: parse ${entriesByType[0].duration} ms, create ${entriesByType[1].duration} ms`);
        return result;
    }

    private static getPartition (item: EventDiffsMsg): PartitionResponse | undefined {
        const partition = item.getPartition();
        if (partition) {
            return {
                id: partition.getId(),
                name: partition.getName()
            };
        }
        return undefined;
    }

    private static getParticipants (item: EventDiffsMsg): DictionaryItem[] {
        return item.getParticipantList().map(value => ({
            id: value.getId(),
            name: value.getName()
        }));
    }

    private static getQuotationDiffList (item: EventDiffsMsg): QuotationDiffResponse[] {
        return item.getQuotationdiffList().map(value => {
            return {
                id: value.getId(),
                quotationId: value.getQuotationid()?.getValue(),
                p1: this.createBigDecimal(value.getP1(), BIG_DECIMAL_SCALES.BETTING_KEY_P),
                p2: this.createBigDecimal(value.getP2(), BIG_DECIMAL_SCALES.BETTING_KEY_P),
                po: this.createBigDecimal(value.getPo(), BIG_DECIMAL_SCALES.BETTING_KEY_P),
                p1Id: value.getP1id()?.getValue(),
                p2Id: value.getP2id()?.getValue(),
                coef: this.createBigDecimal(value.getCoef(), BIG_DECIMAL_SCALES.BETTING_COEF),
                maxWin: value.getMaxwin()?.getValue(),
                deleted: value.getDeleted()?.getValue()
            };
        });
    }

    private static getPeriodList (item: EventDiffsMsg): PeriodResponse[] {
        return item.getPeriodList().map(value => ({
            type: value.getType(),
            deleted: value.getDeleted(),
            contentList: value.getContentList().map(v => ({
                view: v.getView(),
                qidList: v.getQidList()
            }))
        }));
    }

    private static createBigDecimal (p: { getValue (): number } | undefined, scale: number): BigDecimalResponse | undefined {
        return p && p.getValue() !== 0 ? { value: p.getValue(), scale } : undefined;
    }
}
