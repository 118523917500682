export enum EPaymentMethod {
    TerminalCredit = "TerminalCredit",
    CashierBalance = "CashierBalance",
    BetPlaceCredit = "BetPlaceCredit",
    CashierAccountCredit = "AccountCredit"
}

export enum ECardVerificationRule {
    SavingWithoutCheck = "SavingWithoutCheck",
    AcceptingWithChangedCoef = "AcceptingWithChangedCoef",
    FullChecking = "FullChecking"
}
