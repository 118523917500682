<template>
    <div class="preloader" v-if="loading">
        <div class="preloader-el"></div>
    </div>
    <div class="search-head">
        <div class="search-head-title">{{$t("webdesktop.results.caption")}}</div>
    </div>
    <div class="search-tabs">
        <div class="search-tabs-item" :class="{active: !selectedSport}" @click.prevent="selectedSport = undefined">{{$t("webdesktop.results.all")}}</div>
        <div class="search-tabs-item" v-for="sport of results" :key="sport.id" :class="{active: sport.id === selectedSport}"
             @click.prevent="selectedSport = sport.id">
            {{sport.name}}
        </div>
    </div>
    <div class="search-filters">
        <div class="search-filters-item">
            <div class="label">{{$t("webdesktop.results.dateFilter")}}</div>
            <div class="button" :class="{active: dateFilter === 1}" @click.prevent="changeDateFilter(1)">{{$t("webdesktop.results.today")}}</div>
        </div>
        <div class="search-filters-item">
            <div class="label">&nbsp;</div>
            <div class="button" :class="{active: dateFilter === 0}"  @click.prevent="changeDateFilter(0)">{{$t("webdesktop.results.yesterday")}}</div>
        </div>
        <div class="search-filters-item">
            <div class="label">{{$t("webdesktop.results.calendar")}}</div>
            <input type="date" :max="maxDate" class="button" v-model="exactDate" :placeholder="$t('webdesktop.results.select')"/>
        </div>
    </div>
    <div class="search-results">
        <template v-for="sport of filteredResults" :key="sport.id">
        <div class="search-block" v-for="partition of sport.partitions" :key="partition.id">
            <div class="search-block-title">
                <img alt="" class="image" v-if="partition.countryId" :src="getCountryImageUrl(partition.countryId)"/>
                <div class="name">{{sport.name}} / {{partition.name}}</div>
            </div>
            <div class="search-block-line" v-for="event of partition.events" :key="event.id">
                <div class="date">{{formatDate(event.startDate)}} {{formatTime(event.startDate)}}</div>
                <div class="name">{{ getParticipants(event) }}</div>
                <div class="result" :class="{active: hasAdditionalResults(event.results)}" @click.prevent="event.expanded = !event.expanded">{{getMainResults(event.results)}}</div>
                <div class="result active" v-if="event.expanded">
                    <div v-for="result of getAdditionalResults(sport.id, event)" :key="result">{{result}}</div>
                </div>
            </div>
        </div>
        </template>
    </div>
</template>

<script src="./web-results-view.ts" />
