import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { BaseRaceStakesHandler } from "@sportaq/services/rest/messages/games/race/base-race-stakes-handler";

export class QSt106GetRaceByPayCodeHandler extends BaseRaceStakesHandler<HorseWagerContainer> {
    protected readonly requestType: string = "Q.ST.10.6";

    constructor (readonly paycode: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        request.addChildWithValue(filterElement, "PayCode", this.paycode.toString());
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): HorseWagerContainer {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode !== "1410") {
            throw new LocalizedError(`errors.mtl.qSt106.code${serverCode}`);
        }
        const rc = this.parseContainer(getRequiredChild(query, "RC"));
        return rc;
    }
}
