import {
    EPeriodListResolverType, EventListData,
    PeriodListRebuilder,
    PeriodListResolver,
    PeriodListUpdater
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/period-list-resolver";
import {
    AcceptResult,
    EventViewFilter
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { binarySearchIndex, insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import EPeriodType from "@sportaq/common/enums/period-types";
import {
    ScoreboardPeriodFunctions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/common/functions";
import {
    CategoryPeriodSortInfo
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/category-period-sort-info";
import { comparator } from "@sportaq/common/types/functions";
import PeriodSortInfoHelper = ScoreboardPeriodFunctions.PeriodSortInfoHelper;

export class LeagueModePeriodListResolver implements PeriodListResolver<CategoryPeriodSortInfo> {
    private readonly helper;
    private partitionMap = new Map<number, number[]>();
    readonly type = EPeriodListResolverType.LEAGUE;

    constructor () {
        this.helper = new PeriodSortInfoHelper(
            (event, period, options) => CategoryPeriodSortInfo.createEvent(event, period, options)
        );
    }

    get batchRebuilder (): PeriodListRebuilder<CategoryPeriodSortInfo> {
        return new InternalPeriodListRebuilder(this.helper, this.partitionMap);
    }

    get batchUpdater (): PeriodListUpdater<CategoryPeriodSortInfo> {
        return new InternalPeriodListUpdater(this.helper, this.partitionMap);
    }

    expandEvent (periods: CategoryPeriodSortInfo[], event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): CategoryPeriodSortInfo[] {
        const newViewList = Array.from(periods);
        this.helper.updateEvent(newViewList, event, expanded, {
            reverseTimeSorting
        });
        return newViewList;
    }
}

class InternalPeriodListRebuilder implements PeriodListRebuilder<CategoryPeriodSortInfo> {
    private periods: CategoryPeriodSortInfo[] = [];
    private groupEvents: CategoryPeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper, private partitionMap: Map<number, number[]>) {
    }

    begin (): void {
        this.periods = [];
        this.groupEvents = [];
        this.partitionMap.clear();
    }

    addEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, false);
        if (acceptResult.accept) {
            const list = event.isGroupEvent ? this.groupEvents : this.periods;
            addNewGroupedEvent(this.helper, list, this.partitionMap, event, expanded, reverseTimeSorting);
        }
        return acceptResult;
    }

    end (): EventListData<CategoryPeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}

class InternalPeriodListUpdater implements PeriodListUpdater<CategoryPeriodSortInfo> {
    private periods: CategoryPeriodSortInfo[] = [];
    private groupEvents: CategoryPeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper, private partitionMap: Map<number, number[]>) {
    }

    begin (data: EventListData<CategoryPeriodSortInfo>): void {
        this.periods = Array.from(data.periods);
        this.groupEvents = Array.from(data.groupEvents);
    }

    addOrUpdateEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, false);
        const list = event.isGroupEvent ? this.groupEvents : this.periods;
        const index = list.findIndex(value => value.positionId === event.positionId);
        if (acceptResult.accept && index >= 0) {
            this.helper.updateEvent(list, event, expanded, { reverseTimeSorting }, index);
        } else if (acceptResult.accept && index === -1) {
            addNewGroupedEvent(this.helper, list, this.partitionMap, event, expanded, reverseTimeSorting);
        } else if (!acceptResult.accept && index >= 0) {
            this.helper.deleteEvent(list, event.positionId, index);
        }
        return acceptResult;
    }

    deleteEvent (positionId: number): void {
        if (!this.helper.deleteEvent(this.groupEvents, positionId)) {
            this.helper.deleteEvent(this.periods, positionId);
        }
        let needDeleteCategory = false;
        let category: number | undefined;
        for (const categoryId of this.partitionMap.keys()) {
            const values = this.partitionMap.get(categoryId);
            if (values) {
                const index = binarySearchIndex(values, positionId, (a, b) => a - b);
                if (index >= 0) {
                    values.splice(index, 1);
                    category = categoryId;
                    needDeleteCategory = values.length === 0;
                    break;
                }
            }
        }
        if (needDeleteCategory && category) {
            const categoryIndex = this.periods.findIndex(value => value.period === EPeriodType.CATEGORY && value.categoryId === category);
            if (categoryIndex >= 0) {
                this.periods.splice(categoryIndex, 1);
            }
            this.partitionMap.delete(category);
        }
    }

    end (): EventListData<CategoryPeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}

function addNewGroupedEvent (helper: PeriodSortInfoHelper, list: CategoryPeriodSortInfo[], partitionMap: Map<number, number[]>, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean) {
    if (!event.isGroupEvent) {
        const itemsInCategory = partitionMap.get(event.partition.id);
        if (!itemsInCategory) {
            insertIntoSortedArray(list, CategoryPeriodSortInfo.createCategoryRow(event.partition), comparator);
            partitionMap.set(event.partition.id, [event.positionId]);
        } else {
            insertIntoSortedArray(itemsInCategory, event.positionId, (a, b) => a - b);
        }
    }
    helper.insertNewEvent(list, event, expanded, { reverseTimeSorting });
}
