import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { onMounted, onUnmounted, ref, SetupContext } from "vue";

export function useGameSearchCommon (context: SetupContext) {
    const store = useCasinoGamesStore();
    const filter = store.getGameViewFilter();
    const searchText = ref(filter?.nameFilter);
    const inputField = ref<HTMLInputElement>();

    function close () {
        context.emit(CLOSE_EVENT);
    }

    onMounted(() => {
        inputField.value?.focus();
        eventBus.on(Events.VIRTUAL_GAME_SELECTED, onVirtualGameSelected);
    });

    function onVirtualGameSelected () {
        close();
    }

    onUnmounted(() => {
        eventBus.off(Events.VIRTUAL_GAME_SELECTED, onVirtualGameSelected);
        store.updateGameFilter({
            nameFilter: { type: "unset" }
        });
    });

    function updateSearch (value: string) {
        searchText.value = value;
        store.updateGameFilter({
            nameFilter: { type: searchText.value ? "set" : "unset", value: searchText.value ? searchText.value : "" }
        });
    }

    return {
        searchText,
        inputField,

        updateSearch,
        close
    };
}