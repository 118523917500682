<template>
    <div class="popup active" id="partner-withdrawal-dialog">
        <div class="overlay" @click="close"></div>
        <div class="popup-container">
            <div class="popup-title">Withdraw method - IPAY</div>
            <div class="popup-content">
                <template v-if="codeRef">
                    <div class="form-line">
                        <label>Account No. {{accountNumber}}</label>
                    </div>
                    <div class="form-line">
                        <label>Payout amount: {{amountRef}}{{currency}}</label>
                    </div>
                    <div class="form-line">
                        <label>PAYOUT CODE: {{formatVoucherNumber(codeRef)}}</label>
                    </div>
                </template>
                <template v-else>
                <div class="form-line">
                    <label>{{$t("webdesktop.deposit.amountInput.amount")}}{{currency}}</label>
                    <input type="number" v-model.number="amountRef" class="text-center">
                </div>
                <div class="form-line">
                    <button class="button button-primary" @click.prevent="submit" :class="{disabled: (!amountRef || amountRef < 0)}">{{$t("common.forms.continue")}}</button>
                </div>
                </template>
                <div class="form-line">
                    <button class="button button-secondary" @click.prevent="close">{{codeRef ? $t("common.forms.ok") : $t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./express-withdrawal-dialog-component.ts" />
