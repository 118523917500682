import { Form, Field } from "vee-validate";
import { CLOSE_EVENT, EVENT_OPEN_DIALOG } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { useLoginDialog } from "@sportaq/vue/components/web/dialogs/login-dialog-common";

export default {
    name: "LoginPopupDialog",
    emits: [EVENT_OPEN_DIALOG, CLOSE_EVENT],
    components: {
        Form, Field
    },
    setup (props: unknown, context: SetupContext) {
        const {
            isPasswordVisible,
            loginRef,
            passwordRef,
            saveSessionRef,
            isOpen,
            switchPasswordVisible,
            doLogin,
            onForgetPasswordClick,
            onRegistrationClick
        } = useLoginDialog(context);

        function onClose () {
            context.emit(CLOSE_EVENT);
        }

        return {
            isPasswordVisible,
            loginRef,
            passwordRef,
            saveSessionRef,
            isOpen,
            onClose,
            switchPasswordVisible,
            doLogin,
            onForgetPasswordClick,
            onRegistrationClick
        };
    }
};
