import {
    PeriodSortInfo,
    PeriodSortInfoOptions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { BettingEvent, Partition } from "@sportaq/model/betting/events/event";
import EPeriodType from "@sportaq/common/enums/period-types";
import { markRaw } from "vue";
import EventType from "@sportaq/common/enums/event-type";
import { Cloneable, Comparable } from "@sportaq/common/types/interfaces";
import { ESportType } from "@sportaq/common/enums/sport-type";

export class CategoryPeriodSortInfo extends PeriodSortInfo implements Comparable<CategoryPeriodSortInfo>, Cloneable<CategoryPeriodSortInfo> {
    protected constructor (
        id: number,
        eventType: EventType,
        sportType: ESportType,
        positionId: number,
        eventId: number,
        groupEvent: boolean,
        period: EPeriodType,
        startTime: Date,
        options: PeriodSortInfoOptions,
        readonly categoryId: number,
        readonly categoryName: string,
        readonly countryId: number
    ) {
        super(id, eventType, sportType, positionId, eventId, groupEvent, period, startTime, options);
    }

    compare (o: CategoryPeriodSortInfo): number {
        if ((this.period !== EPeriodType.CATEGORY) && (o.period !== EPeriodType.CATEGORY)) {
            if ((this.categoryId === o.categoryId) || (this.groupEvent && o.groupEvent)) {
                return super.compare(o);
            }
            return this.categoryName.localeCompare(o.categoryName);
        }
        if ((this.period === EPeriodType.CATEGORY) && (o.period === EPeriodType.CATEGORY)) {
            return CategoryPeriodSortInfo.compareCategories(this, o);
        }
        if (this.period === EPeriodType.CATEGORY) {
            return CategoryPeriodSortInfo.compareCategoryAndEvent(this, o);
        }
        if (o.period === EPeriodType.CATEGORY) {
            return -CategoryPeriodSortInfo.compareCategoryAndEvent(o, this);
        }
        return 0;
    }

    clone (): CategoryPeriodSortInfo {
        return new CategoryPeriodSortInfo(this.id, this.eventType, this.sportType, this.positionId, this.eventId, this.groupEvent, this.period,
            this.startTime, this.options, this.categoryId, this.categoryName, this.countryId);
    }

    private static compareCategories (category1: CategoryPeriodSortInfo, category2: CategoryPeriodSortInfo) {
        if (category1.categoryId === category2.categoryId) {
            return 0;
        }
        return category1.categoryName.localeCompare(category2.categoryName);
    }

    private static compareCategoryAndEvent (category: CategoryPeriodSortInfo, event: CategoryPeriodSortInfo) {
        if (category.categoryId === event.categoryId) {
            return -1; // category always must be on top
        }
        return category.categoryName.localeCompare(event.categoryName);
    }

    public static createEvent (event: BettingEvent, period: EPeriodType, options: PeriodSortInfoOptions): CategoryPeriodSortInfo {
        return markRaw(
            new CategoryPeriodSortInfo(
                event.positionId * 100 + period * 10 + event.eventType,
                event.eventType,
                event.sportTypeId,
                event.positionId,
                event.eventId,
                event.isGroupEvent,
                period,
                event.startTime,
                options,
                event.partition.id,
                event.partition.name,
                event.partition.countryId
            ));
    }

    public static createCategoryRow (partition: Partition): CategoryPeriodSortInfo {
        return markRaw(
            new CategoryPeriodSortInfo(
                -1 * partition.id,
                EventType.PRE_MATCH,
                partition.sportTypeId,
                0,
                0,
                false,
                EPeriodType.CATEGORY,
                this.CONSTANT_DATE,
                { reverseTimeSorting: false },
                partition.id,
                partition.name,
                partition.countryId)
        );
    }

    // Value doesn't matter
    protected static CONSTANT_DATE = new Date();
}
