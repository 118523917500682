import {
    BaseSettingsService, BRAND_TYPE, getBrandTypeByTheme,
    QUOTATION_FORMAT_KEY, THEME_KEY
} from "@sportaq/services/base-setting-service/base-settings-service";
import { EPaymentMethod } from "@sportaq/common/enums/bet-slip";
import { EGameService, PointSettings } from "@sportaq/model/common/point-settings";
import Cookies from "js-cookie";
import { WebCommonSettings } from "@sportaq/services/web/rest/web-common-settings";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import { ThemeClass } from "@sportaq/common/enums/theme";
import { ECompileBrandType, stringToCompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { COUNTRY_ID_PERU } from "@sportaq/model/consts/countries";
import { WebEnvironmentSettings } from "@sportaq/services/web/environment/web-environment";

const SESSION_CODE_KEY = "sessionCode";
const BANK_ACCOUNTS_KEY = "bankAccounts";
const WITHDRAWAL_DATA_KEY = "withdrawalData";

export interface WithdrawalData {
    withdrawalBank: string;
    withdrawalAccountType: string;
    department: string;
    province: string;
    district: string;
    cCINumber: string;
    cardName: string;
    email: string;
}

export interface WebSettings extends BaseSettingsService {
    readonly sessionCode: string | undefined;

    storeSessionCode (sessionCode: string | undefined, persistent: boolean): void;
    clearSessionCookie(): void;

    saveBankAccount (userId: number | undefined, bankName: string, accountNumber: string): void;

    getBankAccounts (userId: number | undefined, bankName: string): string[];

    getWithdrawalData (userId: number | undefined): WithdrawalData | undefined;

    setWithdrawalData (userId: number | undefined, withdrawalData: WithdrawalData): void;
}

interface BankAccounts {
    bankName: string;
    accounts: string[];
}

export class WebDesktopSettingsServiceImpl implements WebSettings {
    private _commonSettings: WebCommonSettings;
    private _pointSettings!: PointSettings;
    private _sessionCode: string | undefined;
    private _quotationformat: EQuotationFormat;
    private _bankAccounts: BankAccounts[] = [];
    private _withdrawalData?: WithdrawalData;
    private _logo: string = "";
    private _isRegistrationEnabled = true;
    private _isAboutPagePresent = true;
    private _brandType: ECompileBrandType;

    constructor (environment:WebEnvironmentSettings) {
        this._commonSettings = new WebCommonSettings(environment.appCode, environment.partnerCode, environment.restServerAddress, true, environment.logo ?? "");
        const storage = window.localStorage;
        const storageBrandType = storage.getItem(BRAND_TYPE);
        this._brandType = storageBrandType ? stringToCompileBrandType(storageBrandType) : process.env.__BRAND_TYPE__ as ECompileBrandType;
        const sessionCode = window.localStorage.getItem(SESSION_CODE_KEY);
        if (!sessionCode) {
            this._sessionCode = Cookies.get(SESSION_CODE_KEY);
        } else {
            this._sessionCode = sessionCode;
        }
        const qf = storage.getItem(QUOTATION_FORMAT_KEY);
        this._quotationformat = qf ? parseInt(qf) : (this._brandType === ECompileBrandType.columbiaApuesta ? EQuotationFormat.American : EQuotationFormat.Decimal);
        this.countryId = COUNTRY_ID_PERU;
    }

    countryId: number;
    bigButtons: boolean = false;

    get isAboutPagePresent (): boolean {
        return this._isAboutPagePresent;
    }

    set isAboutPagePresent (value: boolean) {
        this._isAboutPagePresent = value;
    }

    get isRegistrationEnabled (): boolean {
        return this._isRegistrationEnabled;
    }

    set isRegistrationEnabled (value: boolean) {
        this._isRegistrationEnabled = value;
    }

    get logo (): string {
        return this._logo;
    }

    set logo (value: string) {
        this._logo = value;
    }

    get paymentMethod (): EPaymentMethod {
        return EPaymentMethod.CashierAccountCredit;
    }

    get appCode (): string {
        return this._commonSettings.appCode;
    }

    get partnerCode (): string {
        return this._commonSettings.partnerCode;
    }

    get restServerAddress (): string {
        return this._commonSettings.restServerAddress;
    }

    get usePrintPreview (): boolean {
        return false;
    }

    get useSSL (): boolean {
        return this._commonSettings.useSSL;
    }

    setCommonSettings (settings: WebCommonSettings) {
        this._commonSettings = settings;
    }

    public get base64Logo (): string {
        return "";
    }

    public get sessionCode (): string | undefined {
        return this._sessionCode;
    }

    public clearSessionCookie () {
        Cookies.set(SESSION_CODE_KEY, "");
    }

    public storeSessionCode (sessionCode: string | undefined, persistent: boolean): void {
        this._sessionCode = sessionCode;
        if (!sessionCode) {
            window.localStorage.setItem(SESSION_CODE_KEY, "");
            Cookies.set(SESSION_CODE_KEY, "");
        } else {
            if (persistent) {
                Cookies.set(SESSION_CODE_KEY, sessionCode, { expires: 1 });
            } else {
                Cookies.set(SESSION_CODE_KEY, sessionCode);
            }
        }
    }

    get quotationFormat () {
        return this._quotationformat;
    }

    set quotationFormat (value: EQuotationFormat) {
        this._quotationformat = value;
        window.localStorage.setItem(QUOTATION_FORMAT_KEY, value.toFixed(0));
    }

    saveBankAccount (userId: number | undefined, bankName: string, accountNumber: string): void {
        if (!userId) {
            return;
        }
        const ba = this._bankAccounts.find(ba => ba.bankName === bankName);
        if (ba) {
            if (!ba.accounts.find(a => a === accountNumber)) {
                ba.accounts.push(accountNumber);
            }
        } else {
            this._bankAccounts.push({
                bankName,
                accounts: [accountNumber]
            });
        }
        window.localStorage.setItem(BANK_ACCOUNTS_KEY + "_" + userId, JSON.stringify(this._bankAccounts));
    }

    getBankAccounts (userId: number | undefined, bankName: string): string[] {
        if (!userId) {
            return [];
        }
        const bankAccountsJson = window.localStorage.getItem(BANK_ACCOUNTS_KEY + "_" + userId);
        if (bankAccountsJson) {
            this._bankAccounts = JSON.parse(bankAccountsJson);
        } else {
            this._bankAccounts = [];
        }
        const ba = this._bankAccounts.find(ba => ba.bankName === bankName);
        if (ba) {
            return ba.accounts;
        }
        return [];
    }

    getWithdrawalData (userId: number | undefined): WithdrawalData | undefined {
        if (!this._withdrawalData) {
            if (!userId) {
                return undefined;
            }
            const withdrawalDataJson = window.localStorage.getItem(WITHDRAWAL_DATA_KEY + "_" + userId);
            if (withdrawalDataJson) {
                this._withdrawalData = JSON.parse(withdrawalDataJson);
            }
        }
        return this._withdrawalData;
    }

    setWithdrawalData (userId: number | undefined, value: WithdrawalData) {
        if (value && userId) {
            this._withdrawalData = value;
            window.localStorage.setItem(WITHDRAWAL_DATA_KEY + "_" + this.pointSettings.id, JSON.stringify(this._withdrawalData));
        }
    }

    getStreamServerAddress (locale: string): string {
        return this._commonSettings.getStreamServerAddress(locale);
    }

    get currentTheme (): ThemeClass | undefined {
        const theme = window.localStorage.getItem(THEME_KEY);
        return theme as ThemeClass;
    }

    set currentTheme (theme) {
        if (theme) {
            window.localStorage.setItem(THEME_KEY, theme);
        }
    }

    get pointSettings (): PointSettings {
        return this._pointSettings;
    }

    set pointSettings (value: PointSettings) {
        this._pointSettings = value;
        const newBrandType = getBrandTypeByTheme(value);
        if (newBrandType !== this._brandType) {
            this._brandType = newBrandType;
            window.localStorage.setItem(BRAND_TYPE, newBrandType);
            const qf = window.localStorage.getItem(QUOTATION_FORMAT_KEY);
            this._quotationformat = qf ? parseInt(qf) : (this._brandType === ECompileBrandType.columbiaApuesta ? EQuotationFormat.American : EQuotationFormat.Decimal);
        }
        eventBus.emit(Events.APPLICATION_POINT_SETTINGS_CHANGED);
    }

    get disabledServices (): EGameService[] {
        return this._pointSettings ? this._pointSettings.disabledServices : [];
    }

    get brandType (): ECompileBrandType {
        return this._brandType;
    }
}
