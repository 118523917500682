declare global {
    interface Window {
        logEvent(name: string, params: unknown): void;
    }
}

export function logAnalyticsEvent (name: string, params: unknown) {
    if (typeof window.logEvent !== "undefined") {
        setTimeout(() => window.logEvent(name, params), 1500);
    }
}
