import { computed, PropType, ref, SetupContext, shallowRef, watch } from "vue";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import { useCasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";

const START_GAME_EVENT = "startGame";
const START_DEMO_EVENT = "startDemo";

interface Props {
    item: GameSortInfo;
    showDemo: boolean;
    showBacklight: boolean;
}

const BACKLIGHT_TIMEOUT = 300;

export default {
    props: {
        item: {
            required: true,
            type: Object as PropType<GameSortInfo>
        },
        showDemo: {
            required: false,
            type: Boolean,
            default: false
        },
        showBacklight: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    emits: [START_GAME_EVENT, START_DEMO_EVENT],
    setup (props: Props, context: SetupContext) {
        const gamesStore = useCasinoGamesStore();
        const supplier = useCasinoGamesSupplier();
        const nonReactiveGame = supplier.getGameWithType(props.item.gameType, props.item.id);

        const game = shallowRef(nonReactiveGame);
        const backlight = ref(false);

        const isFavourite = computed(() => gamesStore.isFavourite(props.item));
        const showDemoSelector = computed(() => props.showDemo && game.value.hasDemo);

        function toggleFavourite () {
            gamesStore.toggleFavourite(props.item);
        }

        function wholeItemClick () {
            if (!showDemoSelector.value) {
                startGame();
            }
        }

        function start (event: string) {
            if (props.showBacklight) {
                backlight.value = true;
                setTimeout(() => {
                    backlight.value = false;
                    context.emit(event, props.item);
                }, BACKLIGHT_TIMEOUT);
            } else {
                context.emit(event, props.item);
            }
        }

        function startGame () {
            start(START_GAME_EVENT);
        }

        function startDemo () {
            start(START_DEMO_EVENT);
        }

        function imageLoadError () {
            console.warn("Image load fail for ", props.item.id);
            gamesStore.addRestrictedGameId(props.item.id);
        }

        watch(() => props.item, value => {
            game.value = supplier.getGameWithType(value.gameType, value.id);
        });

        return {
            game,
            isFavourite,
            showDemoSelector,
            backlight,

            imageLoadError,
            toggleFavourite,
            wholeItemClick,
            startGame,
            startDemo
        };
    }
};
