<template>
    <div class="popup active" id="change-password-dialog">
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.expressChangePasswordDialog.caption")}}</div>
            <div class="popup-content">
                <Form @submit="changePassword" v-slot="{ errors, handleSubmit }">
                    <div class="form-line">
                        <div class="popup-text">
                            {{$t("webdesktop.expressChangePasswordDialog.text1")}}
                        </div>
                    </div>
                    <div class="form-line">
                        <div class="icons">
                            <img :src="'/img/icon-eye-' + (oldPasswordVisible?'off':'on') + '.svg'" alt=""
                                 @click.prevent="oldPasswordVisible = !oldPasswordVisible">
                        </div>
                        <label for="current-password" :class="{'label-error': errors.currentPassword}">{{$t("webdesktop.changePasswordDialog.currentPasswordLabel")}}</label>
                        <Field id="current-password" name="currentPassword" :type="oldPasswordVisible?'text':'password'" autocomplete="current-password" rules="required"/>
                        <div class="field-error" v-if="errors.currentPassword">{{errors.currentPassword}}</div>
                    </div>
                    <div class="form-line">
                        <password-field :isConfirm="false" :errors="errors"></password-field>
                    </div>
                    <div class="form-line">
                        <password-field :isConfirm="true" :errors="errors"></password-field>
                    </div>
                    <div class="form-line">
                        <button id="submit-change-password" class="button button-primary" @click.stop.prevent="handleSubmit(changePassword)">{{$t("webdesktop.expressChangePasswordDialog.submitButton")}}</button>
                    </div>
                    <div class="form-line">
                        <button class="button button-secondary" @click.prevent="onClose">{{$t("webdesktop.expressChangePasswordDialog.closeButton")}}</button>
                    </div>
                </Form>
            </div>
        </div>
        <div class="preloader" v-if="isRequestPending">
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./express-change-password-dialog-component.ts" />
