<template>
    <div id="install-application-dialog" class="popup active" v-if="dialogVisible">
        <div class="overlay" @click="onClose"></div>
        <div class="popup-container">
            <div class="popup-content">
                <div class="text">
                    Install application?
                </div>
                <div class="form-line">
                    <button id="login-button" type="submit" class="button button-general" @click.prevent="onSubmit" data-cy="login-button">{{$t("common.forms.yes")}}</button>
                </div>
                <div class="form-line">
                    <button id="login-close-button" class="button button-secondary" @click.prevent="onClose()" data-cy="login-close-button">{{$t("common.forms.no")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./install-application-dialog-component.ts" />
