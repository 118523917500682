import { ESportType } from "@sportaq/common/enums/sport-type";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { insertOrReplaceIntoSortedSet } from "@sportaq/common/utils/arrays";
import { comparator } from "@sportaq/common/types/functions";
import EventType from "@sportaq/common/enums/event-type";

export class SortedEventsKeeper {
    private readonly sortedEventsBySportType = new Map<ESportType, BettingEvent[]>();

    get (sportType: ESportType): BettingEvent[] {
        return this.sortedEventsBySportType.get(sportType) ?? [];
    }

    add (event: BettingEvent) {
        let sortedList = this.sortedEventsBySportType.get(event.sportTypeId);
        if (!sortedList) {
            sortedList = [];
            this.sortedEventsBySportType.set(event.sportTypeId, sortedList);
        }
        insertOrReplaceIntoSortedSet(sortedList, event, comparator);
    }

    update (event: BettingEvent) {
        const sortedList = this.sortedEventsBySportType.get(event.sportTypeId);
        if (sortedList) {
            sortedList.sort(comparator);
        }
    }

    remove (eventType: EventType, sportType: ESportType, positionId: number) {
        const sortedList = this.sortedEventsBySportType.get(sportType);
        if (sortedList) {
            // We cannot find with a binary search in sortedList, because this list is sorted by startTime, and we must find by ID
            const index = sortedList.findIndex(value => value.positionId === positionId);
            if (index >= 0) {
                sortedList.splice(index, 1);
            }
        }
    }

    clear () {
        this.sortedEventsBySportType.clear();
    }

    counts (): Map<number, number> {
        const result = new Map<number, number>();
        this.sortedEventsBySportType.forEach((value, key) => result.set(key, value.length));
        return result;
    }
}
