export enum EApplicationType {
    betgol = 1,
    cashier = 2,
    webDesktop = 3,
    webMobile = 4,
    mobileCashier = 5
}

export class ApplicationType {
    value: EApplicationType = EApplicationType.betgol;
}

export const APP_TYPE: ApplicationType = new ApplicationType();
