import { RouteLocationNormalizedLoaded, useRoute, useRouter } from "vue-router";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { ROUTES } from "web_desktop/src/router/routes-consts";
import { computed, ref, watch } from "vue";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { useWebSettings } from "@sportaq/services/web";
import GameSearchDialog from "@/views/games/game-search-dialog/GameSearchDialog.vue";
import { useRootStore } from "@sportaq/vuex/index";

export interface MenuItem {
    id: number;
    path: string;
    i18nKey: string;
    iconName: string;
    clickHandler?: () => void;
    dataCy: string;
}

export default {
    components: {
        GameSearchDialog
    },
    setup () {
        const router = useRouter();
        const route = useRoute();
        const betSlipStore = useBetSlipStore();
        const store = useRootStore();
        const isCasinoEnabled = isServiceEnabled(useWebSettings(), EGameService.Casino);
        const menuItems = computed(() => getMenuItems(isCasinoEnabled, route,
            () => { isSearchDialogVisible.value = true; }));
        const selectedTab = computed(() => recognizeSelectedMenuItem(menuItems.value, route));
        const quotationCount = computed(() => betSlipStore.simples.length);
        const imageThemePath = computed(() => store.theme);
        const isSearchDialogVisible = ref(false);
        watch(route, () => {
            if (route.name !== COMMON_ROUTES.VIRTUAL.name && route.name !== COMMON_ROUTES.CASINO.name) {
                isSearchDialogVisible.value = false;
            }
        });
        async function select (item: MenuItem) {
            if (item.id === EMenuItem.BetSlip) {
                if (betSlipStore.simples.length > 0) {
                    betSlipStore.isShowed = !betSlipStore.isShowed;
                }
                return;
            }
            if (item.clickHandler) {
                item.clickHandler();
                return;
            }
            if (item.path) {
                betSlipStore.isShowed = false;
                await router.push({ path: item.path });
            }
        }

        function showMenu () {
            document.body.classList.add("drop-menu-active");
        }

        function onSearchDialogClose () {
            isSearchDialogVisible.value = false;
        }

        return {
            menuItems,
            EMenuItem,

            selectedTab,
            imageThemePath,
            quotationCount,
            isCasinoEnabled,
            isSearchDialogVisible,
            select,
            showMenu,
            onSearchDialogClose
        };
    }
};

export enum EMenuItem {
    Sport = 1, Live = 2, BetSlip = 3, Casino = 4, Virtual = 5, Horses = 6, Search = 7, Bonus = 8, Finance = 9,
}

function getMenuItems (isCasinoEnabled: boolean, route: RouteLocationNormalizedLoaded, searchButtonHandler: () => void): MenuItem[] {
    const result: MenuItem[] = [
        {
            id: EMenuItem.Sport,
            path: COMMON_ROUTES.SPORT.path,
            i18nKey: "mobile.routes.sport",
            iconName: "icon-deportes.svg",
            dataCy: "sport"
        },
        {
            id: EMenuItem.Live,
            path: COMMON_ROUTES.LIVE.path,
            i18nKey: "mobile.routes.live",
            iconName: "icon-envivo.svg",
            dataCy: "live"
        },
        {
            id: EMenuItem.Bonus,
            path: ROUTES.BONUSES.path,
            i18nKey: "mobile.routes.bonus",
            iconName: "icon-bonus-box.svg",
            dataCy: "bonus"
        }
    ];
    if (route.name !== COMMON_ROUTES.VIRTUAL.name && route.name !== COMMON_ROUTES.CASINO.name) {
        result.splice(2, 1,
            {
                id: EMenuItem.BetSlip,
                path: "",
                i18nKey: "betting.betSlip.shortTitle",
                iconName: "icon-cupon.svg",
                dataCy: "betSlip"
            });
    }
    if (route.name === COMMON_ROUTES.VIRTUAL.name || route.name === COMMON_ROUTES.CASINO.name) {
        result.splice(2, 1);
        result.push({
            id: EMenuItem.Search,
            path: "",
            clickHandler: searchButtonHandler,
            i18nKey: "mobile.routes.search",
            iconName: "icon-loop.svg",
            dataCy: "search"
        });
    }
    if (route.name === ROUTES.CASINO.name) {
        result.push({
            id: EMenuItem.Virtual,
            path: COMMON_ROUTES.VIRTUAL.path,
            i18nKey: "mobile.routes.virtual",
            iconName: "icon-virtual.svg",
            dataCy: "virtual"
        });
    }
    if (isCasinoEnabled && route.name !== COMMON_ROUTES.CASINO.name) {
        result.push({
            id: EMenuItem.Casino,
            path: COMMON_ROUTES.CASINO.path,
            i18nKey: "mobile.routes.casino",
            iconName: "icon-casino2.svg",
            dataCy: "casino"
        });
    }
    return result;
}

function recognizeSelectedMenuItem (menu: MenuItem[], route: RouteLocationNormalizedLoaded): MenuItem | undefined {
    for (let i = 0; i < menu.length; i++) {
        const menuItem = menu[i];
        if (menuItem.path && route.path.indexOf(menuItem.path) > 0) {
            return menuItem;
        }
    }
    return undefined;
}
