import { computed, ref, SetupContext } from "vue";
import { EVENT_OPEN_DIALOG } from "@sportaq/vue/components/web/dialogs/events";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";

export enum ItemType {Deposit, Withdrawal}

export default {
    name: "DepositViewItem",
    props: {
        itemId: {
            type: String,
            required: false,
            default: ""
        },
        itemName: {
            type: String,
            required: true
        },
        depositButtonVisible: {
            type: Boolean,
            required: true
        },
        itemType: {
            type: Number,
            required: true
        },
        hasDescription: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        LoginPopupDialog,
        ResetPasswordDialog
    },
    emits: [EVENT_OPEN_DIALOG],
    setup (props: [], context: SetupContext) {
        const infoDialogVisible = ref(false);
        const store = useWebStore();
        const isLoggedIn = computed(() => {
            const user = store.user;
            return user !== undefined;
        });
        const isLoginDialogVisible = ref(false);
        const isResetPasswordDialogVisible = ref(false);
        function openDialog () {
            if (!isLoggedIn.value) {
                isLoginDialogVisible.value = true;
            } else {
                context.emit(EVENT_OPEN_DIALOG);
            }
        }

        function onLoginClose () {
            isLoginDialogVisible.value = false;
            if (isLoggedIn.value) {
                context.emit(EVENT_OPEN_DIALOG);
            }
        }
        return {
            infoDialogVisible,
            isLoggedIn,
            ItemType,
            isLoginDialogVisible,
            isResetPasswordDialogVisible,
            onLoginClose,
            openDialog
        };
    }
};
