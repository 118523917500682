import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getHockeyMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.hockey.popular",
        rows: [
            {
                id: EDetailRow.hockey_x1x2,
                title: "detailInfo.groups.hockey.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2,
                title: "detailInfo.groups.hockey.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.hockey_total,
                title: "detailInfo.groups.hockey.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.hockey_handicap,
                title: "detailInfo.groups.hockey.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam1,
                title: "detailInfo.groups.hockey.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam2,
                title: "detailInfo.groups.hockey.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.hockey.all",
        rows: [
            {
                id: EDetailRow.hockey_x1x2,
                title: "detailInfo.groups.hockey.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2,
                title: "detailInfo.groups.hockey.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.hockey_total,
                title: "detailInfo.groups.hockey.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.hockey_handicap,
                title: "detailInfo.groups.hockey.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam1,
                title: "detailInfo.groups.hockey.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam2,
                title: "detailInfo.groups.hockey.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.hockey_x1x2Period1,
                title: "detailInfo.groups.hockey.x1x2Period1",
                quotationIds: [
                    [376, 377, 378]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period1,
                title: "detailInfo.groups.hockey.x1x12x2Period1",
                quotationIds: [
                    [692, 693, 694]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod1,
                title: "detailInfo.groups.hockey.totalPeriod1",
                quotationIds: [
                    [385, 386]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod1,
                title: "detailInfo.groups.hockey.handicapPeriod1",
                quotationIds: [
                    [370, 371]
                ]
            },
            {
                id: EDetailRow.hockey_x1x2Period2,
                title: "detailInfo.groups.hockey.x1x2Period2",
                quotationIds: [
                    [379, 380, 381]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period2,
                title: "detailInfo.groups.hockey.x1x12x2Period2",
                quotationIds: [
                    [695, 696, 697]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod2,
                title: "detailInfo.groups.hockey.totalPeriod2",
                quotationIds: [
                    [387, 388]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod2,
                title: "detailInfo.groups.hockey.handicapPeriod2",
                quotationIds: [
                    [372, 373]
                ]
            },
            {
                id: EDetailRow.hockey_x1x2Period3,
                title: "detailInfo.groups.hockey.x1x2Period3",
                quotationIds: [
                    [382, 383, 384]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period3,
                title: "detailInfo.groups.hockey.x1x12x2Period3",
                quotationIds: [
                    [698, 699, 700]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod3,
                title: "detailInfo.groups.hockey.totalPeriod3",
                quotationIds: [
                    [389, 390]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod3,
                title: "detailInfo.groups.hockey.handicapPeriod3",
                quotationIds: [
                    [374, 375]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.hockey.handicaps",
        rows: [
            {
                id: EDetailRow.hockey_handicap,
                title: "detailInfo.groups.hockey.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod1,
                title: "detailInfo.groups.hockey.handicapPeriod1",
                quotationIds: [
                    [370, 371]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod2,
                title: "detailInfo.groups.hockey.handicapPeriod2",
                quotationIds: [
                    [372, 373]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod3,
                title: "detailInfo.groups.hockey.handicapPeriod3",
                quotationIds: [
                    [374, 375]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.hockey.totals",
        rows: [
            {
                id: EDetailRow.hockey_totalMatch,
                title: "detailInfo.groups.hockey.totalMatch",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod1,
                title: "detailInfo.groups.hockey.totalPeriod1",
                quotationIds: [
                    [385, 386]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod2,
                title: "detailInfo.groups.hockey.totalPeriod2",
                quotationIds: [
                    [387, 388]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod3,
                title: "detailInfo.groups.hockey.totalPeriod3",
                quotationIds: [
                    [389, 390]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam1,
                title: "detailInfo.groups.hockey.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.hockey_individualTotalTeam2,
                title: "detailInfo.groups.hockey.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.hockey.periods",
        rows: [
            {
                id: EDetailRow.hockey_x1x2Period1,
                title: "detailInfo.groups.hockey.x1x2Period1",
                quotationIds: [
                    [376, 377, 378]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period1,
                title: "detailInfo.groups.hockey.x1x12x2Period1",
                quotationIds: [
                    [692, 693, 694]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod1,
                title: "detailInfo.groups.hockey.totalPeriod1",
                quotationIds: [
                    [385, 386]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod1,
                title: "detailInfo.groups.hockey.handicapPeriod1",
                quotationIds: [
                    [370, 371]
                ]
            },
            {
                id: EDetailRow.hockey_x1x2Period2,
                title: "detailInfo.groups.hockey.x1x2Period2",
                quotationIds: [
                    [379, 380, 381]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period2,
                title: "detailInfo.groups.hockey.x1x12x2Period2",
                quotationIds: [
                    [695, 696, 697]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod2,
                title: "detailInfo.groups.hockey.totalPeriod2",
                quotationIds: [
                    [387, 388]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod2,
                title: "detailInfo.groups.hockey.handicapPeriod2",
                quotationIds: [
                    [372, 373]
                ]
            },
            {
                id: EDetailRow.hockey_x1x2Period3,
                title: "detailInfo.groups.hockey.x1x2Period3",
                quotationIds: [
                    [382, 383, 384]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period3,
                title: "detailInfo.groups.hockey.x1x12x2Period3",
                quotationIds: [
                    [698, 699, 700]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod3,
                title: "detailInfo.groups.hockey.totalPeriod3",
                quotationIds: [
                    [389, 390]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod3,
                title: "detailInfo.groups.hockey.handicapPeriod3",
                quotationIds: [
                    [374, 375]
                ]
            }
        ]
    });
    return result;
}

export function getHockeyPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.hockey.all",
        rows: [
            {
                id: EDetailRow.hockey_x1x2Period1,
                title: "detailInfo.groups.hockey.x1x2Period1",
                quotationIds: [
                    [376, 377, 378]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period1,
                title: "detailInfo.groups.hockey.x1x12x2Period1",
                quotationIds: [
                    [692, 693, 694]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod1,
                title: "detailInfo.groups.hockey.totalPeriod1",
                quotationIds: [
                    [385, 386]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod1,
                title: "detailInfo.groups.hockey.handicapPeriod1",
                quotationIds: [
                    [370, 371]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.hockey.handicaps",
        rows: [
            {
                id: EDetailRow.hockey_handicapPeriod1,
                title: "detailInfo.groups.hockey.handicapPeriod1",
                quotationIds: [
                    [370, 371]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.hockey.totals",
        rows: [
            {
                id: EDetailRow.hockey_totalPeriod1,
                title: "detailInfo.groups.hockey.totalPeriod1",
                quotationIds: [
                    [385, 386]
                ]
            }
        ]
    });
    return result;
}

export function getHockeyPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.hockey.all",
        rows: [
            {
                id: EDetailRow.hockey_x1x2Period2,
                title: "detailInfo.groups.hockey.x1x2Period2",
                quotationIds: [
                    [379, 380, 381]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period2,
                title: "detailInfo.groups.hockey.x1x12x2Period2",
                quotationIds: [
                    [695, 696, 697]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod2,
                title: "detailInfo.groups.hockey.totalPeriod2",
                quotationIds: [
                    [387, 388]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod2,
                title: "detailInfo.groups.hockey.handicapPeriod2",
                quotationIds: [
                    [372, 373]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.hockey.handicaps",
        rows: [
            {
                id: EDetailRow.hockey_handicapPeriod2,
                title: "detailInfo.groups.hockey.handicapPeriod2",
                quotationIds: [
                    [372, 373]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.hockey.totals",
        rows: [
            {
                id: EDetailRow.hockey_totalPeriod2,
                title: "detailInfo.groups.hockey.totalPeriod2",
                quotationIds: [
                    [387, 388]
                ]
            }
        ]
    });
    return result;
}

export function getHockeyPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.hockey.all",
        rows: [
            {
                id: EDetailRow.hockey_x1x2Period3,
                title: "detailInfo.groups.hockey.x1x2Period3",
                quotationIds: [
                    [382, 383, 384]
                ]
            },
            {
                id: EDetailRow.hockey_x1x12x2Period3,
                title: "detailInfo.groups.hockey.x1x12x2Period3",
                quotationIds: [
                    [698, 699, 700]
                ]
            },
            {
                id: EDetailRow.hockey_totalPeriod3,
                title: "detailInfo.groups.hockey.totalPeriod3",
                quotationIds: [
                    [389, 390]
                ]
            },
            {
                id: EDetailRow.hockey_handicapPeriod3,
                title: "detailInfo.groups.hockey.handicapPeriod3",
                quotationIds: [
                    [374, 375]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.hockey.handicaps",
        rows: [
            {
                id: EDetailRow.hockey_handicapPeriod3,
                title: "detailInfo.groups.hockey.handicapPeriod3",
                quotationIds: [
                    [374, 375]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.hockey.totals",
        rows: [
            {
                id: EDetailRow.hockey_totalPeriod3,
                title: "detailInfo.groups.hockey.totalPeriod3",
                quotationIds: [
                    [389, 390]
                ]
            }
        ]
    });
    return result;
}
