import { Form, Field } from "vee-validate";
import { CLOSE_EVENT, SUBMIT_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { ref } from "vue";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useRoute } from "vue-router";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

export default {
    name: "LoginPopupDialog",
    emits: [SUBMIT_EVENT, CLOSE_EVENT],
    components: {
        Form, Field
    },
    // @typescript-eslint/no-unused-vars
    setup () {
        const store = useWebStore();
        const route = useRoute();

        const dialogVisible = ref(store.isAppInstallationAvailable && route.query.install);

        function onClose () {
            dialogVisible.value = false;
        }

        function onSubmit () {
            dialogVisible.value = false;
            eventBus.emit(Events.INSTALL_MOBILE_APPLICATION);
        }
        return {
            dialogVisible,
            onClose,
            onSubmit
        };
    }
};
