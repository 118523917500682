import { ImageDictionaryItem } from "@sportaq/common/types/types";
import { insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import { resolveLanguage } from "@sportaq/i18n/index";
import { INTERNATIONAL_COUNTRY_ID, INTERNATIONAL_COUNTRY_IMAGE } from "@sportaq/common/consts/default-consts";

export const COUNTRY_ID_PERU = 165;
export const COUNTRY_ID_UKRAINE = 217;

export interface Country extends ImageDictionaryItem {
    alpha2: string;
    phoneCode?: string;
}

class CountryHolder {
    private readonly comparator: (a: Country, b: Country) => number = (a, b) => a.name.localeCompare(b.name);
    private readonly indexById = new Map<number, Country>();
    private readonly indexByCode = new Map<string, Country>();
    private list: Country[] = [];
    private locale = "_Undefined_";

    getById (countryId?: number): Country | undefined {
        if (countryId) {
            this.refreshByLocale();
            return this.indexById.get(countryId);
        }
        return undefined;
    }

    getByCode (countryCode?: string): Country | undefined {
        if (countryCode) {
            this.refreshByLocale();
            return this.indexByCode.get(countryCode);
        }
        return undefined;
    }

    getFlag (countryId?: number): string {
        const country = this.getById(countryId);
        return country?.image ?? INTERNATIONAL_COUNTRY_IMAGE;
    }

    getList (): Country[] {
        this.refreshByLocale();
        return this.list;
    }

    private refreshByLocale () {
        const newLocale = resolveLanguage();
        if (newLocale !== this.locale) {
            this.rebuild(newLocale);
        }
    }

    private rebuild (locale: string) {
        this.indexById.clear();
        this.indexByCode.clear();
        this.list = [];
        for (const item of rawCountriesArray) {
            const country: Country = {
                id: item.id,
                name: this.getCountryName(item, locale),
                image: item.alpha2.toLowerCase(),
                alpha2: item.alpha2,
                phoneCode: item.phoneCode
            };
            this.indexById.set(country.id, country);
            this.indexByCode.set(country.alpha2, country);
            if (item.id !== INTERNATIONAL_COUNTRY_ID) {
                insertIntoSortedArray(this.list, country, this.comparator);
            }
        }
        this.locale = locale;
    }

    private getCountryName (country: RawCountry, locale: string) {
        switch (locale) {
            case "ru":
                return country.names.ru;
            case "en":
                return country.names.en;
            case "es":
                return country.names.es;
            case "pt":
                return country.names.pt;
            default: {
                throw new Error("Unsupported language: " + locale);
            }
        }
    }
}

export const countries = new CountryHolder();

interface RawCountry {
    id: number;
    alpha2: string;
    alpha3: string;
    defaultLang?: string;
    phoneCode?: string;
    names: {
        en: string;
        ru: string;
        es: string;
        pt: string;
    };
}

const rawCountriesArray: RawCountry[] = [
    {
        id: 1,
        alpha2: "AF",
        alpha3: "AFG",
        defaultLang: "en",
        phoneCode: "+93",
        names: {
            en: "Afghanistan",
            ru: "Афганистан",
            es: "Afganistán",
            pt: "Afeganistão"
        }
    },
    {
        id: 2,
        alpha2: "AL",
        alpha3: "ALB",
        defaultLang: "en",
        phoneCode: "+355",
        names: {
            en: "Albania",
            ru: "Албания",
            es: "Albania",
            pt: "Albânia"
        }
    },
    {
        id: 3,
        alpha2: "DZ",
        alpha3: "DZA",
        defaultLang: "en",
        phoneCode: "+21",
        names: {
            en: "Algeria",
            ru: "Алжир",
            es: "Argelia",
            pt: "Argélia"
        }
    },
    {
        id: 4,
        alpha2: "AS",
        alpha3: "ASM",
        defaultLang: "en",
        phoneCode: "+684",
        names: {
            en: "Samoa",
            ru: "Самоа",
            es: "Samoa",
            pt: "Samoa"
        }
    },
    {
        id: 5,
        alpha2: "AD",
        alpha3: "AND",
        defaultLang: "es",
        phoneCode: "+376",
        names: {
            en: "Andorra",
            ru: "Андорра",
            es: "Andorra",
            pt: "Andorra"
        }
    },
    {
        id: 6,
        alpha2: "AO",
        alpha3: "AGO",
        defaultLang: "en",
        phoneCode: "+244",
        names: {
            en: "Angola",
            ru: "Ангола",
            es: "Angola",
            pt: "Angola"
        }
    },
    {
        id: 7,
        alpha2: "AI",
        alpha3: "AIA",
        defaultLang: "es",
        phoneCode: "+1-264",
        names: {
            en: "Anguilla",
            ru: "Ангуилла",
            es: "Anguila",
            pt: "Anguilla"
        }
    },
    {
        id: 8,
        alpha2: "AQ",
        alpha3: "ATA",
        defaultLang: "en",
        phoneCode: "+672",
        names: {
            en: "Antarctica",
            ru: "Антарктика",
            es: "Antártida",
            pt: "Antártida"
        }
    },
    {
        id: 9,
        alpha2: "AG",
        alpha3: "ATG",
        defaultLang: "es",
        phoneCode: "+1-268",
        names: {
            en: "Antigua and Barbuda",
            ru: "Антигуа и Барбуда",
            es: "Antigua y Barbuda",
            pt: "Antígua e Barbuda"
        }
    },
    {
        id: 10,
        alpha2: "AR",
        alpha3: "ARG",
        defaultLang: "es",
        phoneCode: "+54",
        names: {
            en: "Argentina",
            ru: "Аргентина",
            es: "Argentina",
            pt: "Argentina"
        }
    },
    {
        id: 11,
        alpha2: "AM",
        alpha3: "ARM",
        defaultLang: "ru",
        phoneCode: "+374",
        names: {
            en: "Armenia",
            ru: "Армения",
            es: "Armenia",
            pt: "Armênia"
        }
    },
    {
        id: 12,
        alpha2: "AW",
        alpha3: "ABW",
        defaultLang: "es",
        phoneCode: "+297",
        names: {
            en: "Aruba",
            ru: "Аруба",
            es: "Aruba",
            pt: "Aruba"
        }
    },
    {
        id: 13,
        alpha2: "AU",
        alpha3: "AUS",
        defaultLang: "en",
        phoneCode: "+61",
        names: {
            en: "Australia",
            ru: "Австралия",
            es: "Australia",
            pt: "Austrália"
        }
    },
    {
        id: 14,
        alpha2: "AT",
        alpha3: "AUT",
        defaultLang: "en",
        phoneCode: "+43",
        names: {
            en: "Austria",
            ru: "Австрия",
            es: "Austria",
            pt: "Áustria"
        }
    },
    {
        id: 15,
        alpha2: "AZ",
        alpha3: "AZE",
        defaultLang: "ru",
        phoneCode: "+994",
        names: {
            en: "Azerbaijan",
            ru: "Азербайджан",
            es: "Azerbaiyán",
            pt: "Azerbaijão"
        }
    },
    {
        id: 16,
        alpha2: "BS",
        alpha3: "BHS",
        defaultLang: "es",
        phoneCode: "+1-242",
        names: {
            en: "Bahamas",
            ru: "Багамы",
            es: "Bahamas (las)",
            pt: "Bahamas"
        }
    },
    {
        id: 17,
        alpha2: "BH",
        alpha3: "BHR",
        defaultLang: "en",
        phoneCode: "+973",
        names: {
            en: "Bahrain",
            ru: "Бахрейн",
            es: "Bahréin",
            pt: "Bahrain"
        }
    },
    {
        id: 18,
        alpha2: "BD",
        alpha3: "BGD",
        defaultLang: "en",
        phoneCode: "+880",
        names: {
            en: "Bangladesh",
            ru: "Бангладеш",
            es: "Bangladesh",
            pt: "Bangladesh"
        }
    },
    {
        id: 19,
        alpha2: "BB",
        alpha3: "BRB",
        defaultLang: "es",
        phoneCode: "+1-246",
        names: {
            en: "Barbados",
            ru: "Барбадос",
            es: "Barbados",
            pt: "Barbados"
        }
    },
    {
        id: 20,
        alpha2: "BY",
        alpha3: "BLR",
        defaultLang: "ru",
        phoneCode: "+375",
        names: {
            en: "Belarus",
            ru: "Беларусь",
            es: "Belarús",
            pt: "Bielorrússia"
        }
    },
    {
        id: 21,
        alpha2: "BE",
        alpha3: "BEL",
        defaultLang: "en",
        phoneCode: "+32",
        names: {
            en: "Belgium",
            ru: "Бельгия",
            es: "Bélgica",
            pt: "Bélgica"
        }
    },
    {
        id: 22,
        alpha2: "BZ",
        alpha3: "BLZ",
        defaultLang: "en",
        phoneCode: "+501",
        names: {
            en: "Belize",
            ru: "Белиз",
            es: "Belice",
            pt: "Belize"
        }
    },
    {
        id: 23,
        alpha2: "BJ",
        alpha3: "BEN",
        defaultLang: "en",
        phoneCode: "+229",
        names: {
            en: "Benin",
            ru: "Бенин",
            es: "Benín",
            pt: "Benin"
        }
    },
    {
        id: 24,
        alpha2: "BM",
        alpha3: "BMU",
        defaultLang: "es",
        phoneCode: "+1-441",
        names: {
            en: "Bermuda",
            ru: "Бермуды",
            es: "Bermudas",
            pt: "Bermudas"
        }
    },
    {
        id: 25,
        alpha2: "BT",
        alpha3: "BTN",
        defaultLang: "en",
        phoneCode: "+975",
        names: {
            en: "Bhutan",
            ru: "Бутан",
            es: "Bhután",
            pt: "Butão"
        }
    },
    {
        id: 26,
        alpha2: "BO",
        alpha3: "BOL",
        defaultLang: "es",
        phoneCode: "+591",
        names: {
            en: "Bolivia",
            ru: "Боливия",
            es: "Bolivia",
            pt: "Bolívia"
        }
    },
    {
        id: 27,
        alpha2: "BA",
        alpha3: "BIH",
        defaultLang: "en",
        phoneCode: "+387",
        names: {
            en: "Bosnia",
            ru: "Босния",
            es: "Bosnia",
            pt: "Bósnia"
        }
    },
    {
        id: 28,
        alpha2: "BW",
        alpha3: "BWA",
        defaultLang: "en",
        phoneCode: "+267",
        names: {
            en: "Botswana",
            ru: "Ботсвана",
            es: "Botswana",
            pt: "Botsuana"
        }
    },
    {
        id: 29,
        alpha2: "BV",
        alpha3: "BVT",
        defaultLang: "en",
        phoneCode: "+47",
        names: {
            en: "Bouvet",
            ru: "Буве",
            es: "Bouvet",
            pt: "Bouvet"
        }
    },
    {
        id: 30,
        alpha2: "BR",
        alpha3: "BRA",
        defaultLang: "pt",
        phoneCode: "+55",
        names: {
            en: "Brazil",
            ru: "Бразилия",
            es: "Brasil",
            pt: "Brasil"
        }
    },
    {
        id: 31,
        alpha2: "IO",
        alpha3: "IOT",
        defaultLang: "en",
        phoneCode: "+246",
        names: {
            en: "British Indian Ocean Territory",
            ru: "Британская территория в Индийском океане",
            es: "Territorio Británico del Océano Índico (el)",
            pt: "Território Britânico do Oceano Índico"
        }
    },
    {
        id: 32,
        alpha2: "BN",
        alpha3: "BRN",
        defaultLang: "en",
        phoneCode: "+673",
        names: {
            en: "Brunei",
            ru: "Бруней",
            es: "Brunéi",
            pt: "Brunei"
        }
    },
    {
        id: 33,
        alpha2: "BG",
        alpha3: "BGR",
        defaultLang: "en",
        phoneCode: "+359",
        names: {
            en: "Bulgaria",
            ru: "Болгария",
            es: "Bulgaria",
            pt: "Bulgária"
        }
    },
    {
        id: 34,
        alpha2: "BF",
        alpha3: "BFA",
        defaultLang: "en",
        phoneCode: "+226",
        names: {
            en: "Burkina Faso",
            ru: "Буркина Фасо",
            es: "Burkina Faso",
            pt: "Burquina Faso"
        }
    },
    {
        id: 35,
        alpha2: "BI",
        alpha3: "BDI",
        defaultLang: "en",
        phoneCode: "+257",
        names: {
            en: "Burundi",
            ru: "Бурунди",
            es: "Burundi",
            pt: "Burundi"
        }
    },
    {
        id: 36,
        alpha2: "KH",
        alpha3: "KHM",
        defaultLang: "en",
        phoneCode: "+855",
        names: {
            en: "Cambodia",
            ru: "Камбоджа",
            es: "Camboya",
            pt: "Camboja"
        }
    },
    {
        id: 37,
        alpha2: "CM",
        alpha3: "CMR",
        defaultLang: "en",
        phoneCode: "+237",
        names: {
            en: "Cameroon",
            ru: "Камерун",
            es: "Camerún",
            pt: "Camarões"
        }
    },
    {
        id: 38,
        alpha2: "CA",
        alpha3: "CAN",
        defaultLang: "en",
        phoneCode: "+1",
        names: {
            en: "Canada",
            ru: "Канада",
            es: "Canadá",
            pt: "Canadá"
        }
    },
    {
        id: 39,
        alpha2: "CV",
        alpha3: "CPV",
        defaultLang: "en",
        phoneCode: "+238",
        names: {
            en: "Cabo Verde",
            ru: "Cabo Verde",
            es: "Cabo Verde",
            pt: "Cabo Verde"
        }
    },
    {
        id: 40,
        alpha2: "KY",
        alpha3: "CYM",
        defaultLang: "en",
        phoneCode: "+1-345",
        names: {
            en: "Cayman",
            ru: "Кайманы",
            es: "Caimán",
            pt: "Caiman"
        }
    },
    {
        id: 41,
        alpha2: "CF",
        alpha3: "CAF",
        defaultLang: "en",
        phoneCode: "+236",
        names: {
            en: "CAR",
            ru: "ЦАР",
            es: "CAR",
            pt: "CAR"
        }
    },
    {
        id: 42,
        alpha2: "TD",
        alpha3: "TCD",
        defaultLang: "en",
        phoneCode: "+235",
        names: {
            en: "Chad",
            ru: "Чад",
            es: "Chad",
            pt: "Chade"
        }
    },
    {
        id: 43,
        alpha2: "CL",
        alpha3: "CHL",
        defaultLang: "es",
        phoneCode: "+56",
        names: {
            en: "Chile",
            ru: "Чили",
            es: "Chile",
            pt: "Chile"
        }
    },
    {
        id: 44,
        alpha2: "CN",
        alpha3: "CHN",
        defaultLang: "en",
        phoneCode: "+86",
        names: {
            en: "China",
            ru: "Китай",
            es: "China",
            pt: "China"
        }
    },
    {
        id: 45,
        alpha2: "CX",
        alpha3: "CXR",
        defaultLang: "en",
        phoneCode: "+672",
        names: {
            en: "Christmas Island",
            ru: "Остров Рождества",
            es: "Navidad",
            pt: "Ilhas Natal"
        }
    },
    {
        id: 46,
        alpha2: "CC",
        alpha3: "CCK",
        defaultLang: "en",
        phoneCode: "+672",
        names: {
            en: "Cocos (Keeling) Islands",
            ru: "Кокосовые острова",
            es: "Cocos / Keeling",
            pt: "Ilhas Coco"
        }
    },
    {
        id: 47,
        alpha2: "CO",
        alpha3: "COL",
        defaultLang: "es",
        phoneCode: "+57",
        names: {
            en: "Colombia",
            ru: "Колумбия",
            es: "Colombia",
            pt: "Colômbia"
        }
    },
    {
        id: 48,
        alpha2: "KM",
        alpha3: "COM",
        defaultLang: "en",
        phoneCode: "+269",
        names: {
            en: "Comoros",
            ru: "Коморы",
            es: "Comoras (las)",
            pt: "Comores"
        }
    },
    {
        id: 49,
        alpha2: "CG",
        alpha3: "COG",
        defaultLang: "en",
        phoneCode: "+242",
        names: {
            en: "Congo",
            ru: "Конго",
            es: "Congo (el)",
            pt: "Congo"
        }
    },
    {
        id: 50,
        alpha2: "CK",
        alpha3: "COK",
        defaultLang: "en",
        phoneCode: "+282",
        names: {
            en: "Cook Islands",
            ru: "Острова Кука",
            es: "Cook",
            pt: "Ilhas Cook"
        }
    },
    {
        id: 51,
        alpha2: "CR",
        alpha3: "CRI",
        defaultLang: "es",
        phoneCode: "+506",
        names: {
            en: "Costa Rica",
            ru: "Коста-Рика",
            es: "Costa Rica",
            pt: "Costa Rica"
        }
    },
    {
        id: 52,
        alpha2: "CI",
        alpha3: "CIV",
        defaultLang: "en",
        phoneCode: "+225",
        names: {
            en: "Cote d'Ivoire",
            ru: "Кот д’Ивуар",
            es: "Côte d'Ivoire",
            pt: "Costa do Marfim"
        }
    },
    {
        id: 53,
        alpha2: "HR",
        alpha3: "HRV",
        defaultLang: "en",
        phoneCode: "+385",
        names: {
            en: "Croatia",
            ru: "Хорватия",
            es: "Croacia",
            pt: "Croácia"
        }
    },
    {
        id: 54,
        alpha2: "CU",
        alpha3: "CUB",
        defaultLang: "es",
        phoneCode: "+53",
        names: {
            en: "Cuba",
            ru: "Куба",
            es: "Cuba",
            pt: "Cuba"
        }
    },
    {
        id: 55,
        alpha2: "CY",
        alpha3: "CYP",
        defaultLang: "es",
        phoneCode: "+357",
        names: {
            en: "Cyprus",
            ru: "Кипр",
            es: "Chipre",
            pt: "Chipre"
        }
    },
    {
        id: 56,
        alpha2: "CZ",
        alpha3: "CZE",
        defaultLang: "en",
        phoneCode: "+420",
        names: {
            en: "Czechia",
            ru: "Чехия",
            es: "Checa",
            pt: "Tcheca"
        }
    },
    {
        id: 57,
        alpha2: "DK",
        alpha3: "DNK",
        defaultLang: "en",
        phoneCode: "+45",
        names: {
            en: "Denmark",
            ru: "Дания",
            es: "Dinamarca",
            pt: "Dinamarca"
        }
    },
    {
        id: 58,
        alpha2: "DJ",
        alpha3: "DJI",
        defaultLang: "en",
        phoneCode: "+253",
        names: {
            en: "Djibouti",
            ru: "Джибути",
            es: "Djibouti",
            pt: "Djibuti"
        }
    },
    {
        id: 59,
        alpha2: "DM",
        alpha3: "DMA",
        defaultLang: "es",
        phoneCode: "+1-767",
        names: {
            en: "Dominica",
            ru: "Доминика",
            es: "Dominica",
            pt: "Dominica"
        }
    },
    {
        id: 60,
        alpha2: "DO",
        alpha3: "DOM",
        defaultLang: "es",
        phoneCode: "+1-809",
        names: {
            en: "Dominicana",
            ru: "Доминикана",
            es: "Dominicana",
            pt: "Dominicana"
        }
    },
    {
        id: 61,
        alpha2: "TL",
        alpha3: "TLS",
        defaultLang: "en",
        phoneCode: "+670",
        names: {
            ru: "Тимор",
            en: "Timor",
            es: "TLS",
            pt: "TLS"
        }
    },
    {
        id: 62,
        alpha2: "EC",
        alpha3: "ECU",
        defaultLang: "es",
        phoneCode: "+593",
        names: {
            en: "Ecuador",
            ru: "Эквадор",
            es: "Ecuador",
            pt: "Equador"
        }
    },
    {
        id: 63,
        alpha2: "EG",
        alpha3: "EGY",
        defaultLang: "en",
        phoneCode: "+20",
        names: {
            en: "Egypt",
            ru: "Египет",
            es: "Egipto",
            pt: "Egito"
        }
    },
    {
        id: 64,
        alpha2: "SV",
        alpha3: "SLV",
        defaultLang: "es",
        phoneCode: "+503",
        names: {
            en: "El Salvador",
            ru: "Сальвадор",
            es: "El Salvador",
            pt: "El Salvador"
        }
    },
    {
        id: 65,
        alpha2: "GQ",
        alpha3: "GNQ",
        defaultLang: "en",
        phoneCode: "+240",
        names: {
            en: "Guinea Equatorial",
            ru: "Экваториальная Гвинея",
            es: "Guinea Equatorial",
            pt: "Guiné Equatorial"
        }
    },
    {
        id: 66,
        alpha2: "ER",
        alpha3: "ERI",
        defaultLang: "en",
        phoneCode: "+291",
        names: {
            en: "Eritrea",
            ru: "Эритрея",
            es: "Eritrea",
            pt: "Eritreia"
        }
    },
    {
        id: 67,
        alpha2: "EE",
        alpha3: "EST",
        defaultLang: "en",
        phoneCode: "+372",
        names: {
            en: "Estonia",
            ru: "Эстония",
            es: "Estonia",
            pt: "Estônia"
        }
    },
    {
        id: 68,
        alpha2: "ET",
        alpha3: "ETH",
        defaultLang: "en",
        phoneCode: "+251",
        names: {
            en: "Ethiopia",
            ru: "Эфиопия",
            es: "Etiopía",
            pt: "Etiópia"
        }
    },
    {
        id: 69,
        alpha2: "FK",
        alpha3: "FLK",
        defaultLang: "es",
        phoneCode: "+500",
        names: {
            en: "Falkland Islands (Malvinas)",
            ru: "Фолклендские острова",
            es: "Malvinas [Falkland]",
            pt: "Ilhas Malvinas"
        }
    },
    {
        id: 70,
        alpha2: "FO",
        alpha3: "FRO",
        defaultLang: "en",
        phoneCode: "+298",
        names: {
            en: "Faroe Islands",
            ru: "Фарерские острова",
            es: "Feroe",
            pt: "Ilhas Faroe"
        }
    },
    {
        id: 71,
        alpha2: "FJ",
        alpha3: "FJI",
        defaultLang: "en",
        phoneCode: "+679",
        names: {
            en: "Fiji",
            ru: "Фиджи",
            es: "Fiji",
            pt: "Fiji"
        }
    },
    {
        id: 72,
        alpha2: "FI",
        alpha3: "FIN",
        defaultLang: "en",
        phoneCode: "+358",
        names: {
            en: "Finland",
            ru: "Финляндия",
            es: "Finlandia",
            pt: "Finlândia"
        }
    },
    {
        id: 73,
        alpha2: "FR",
        alpha3: "FRA",
        defaultLang: "en",
        phoneCode: "+33",
        names: {
            en: "France",
            ru: "Франция",
            es: "Francia",
            pt: "França"
        }
    },
    {
        id: 75,
        alpha2: "PF",
        alpha3: "PYF",
        defaultLang: "en",
        phoneCode: "+689",
        names: {
            en: "French Polynesia",
            ru: "Французская Полинезия",
            es: "Polinesia Francesa",
            pt: "Polinésia Francesa"
        }
    },
    {
        id: 76,
        alpha2: "TF",
        alpha3: "ATF",
        defaultLang: "en",
        phoneCode: "+262",
        names: {
            en: "French Southern Territories",
            ru: "Французские Южные Территории",
            es: "Tierras Australes Francesas (las)",
            pt: "Territórios Franceses do Sul"
        }
    },
    {
        id: 77,
        alpha2: "GA",
        alpha3: "GAB",
        defaultLang: "en",
        phoneCode: "+241",
        names: {
            en: "Gabon",
            ru: "Габон",
            es: "Gabón",
            pt: "Gabão"
        }
    },
    {
        id: 78,
        alpha2: "GM",
        alpha3: "GMB",
        defaultLang: "en",
        phoneCode: "+220",
        names: {
            en: "Gambia",
            ru: "Гамбия",
            es: "Gambia",
            pt: "Gâmbia"
        }
    },
    {
        id: 79,
        alpha2: "GE",
        alpha3: "GEO",
        defaultLang: "en",
        phoneCode: "+995",
        names: {
            en: "Georgia",
            ru: "Грузия",
            es: "Georgia",
            pt: "Geórgia"
        }
    },
    {
        id: 80,
        alpha2: "DE",
        alpha3: "DEU",
        defaultLang: "en",
        phoneCode: "+49",
        names: {
            en: "Germany",
            ru: "Германия",
            es: "Alemania",
            pt: "Alemanha"
        }
    },
    {
        id: 81,
        alpha2: "GH",
        alpha3: "GHA",
        defaultLang: "en",
        phoneCode: "+233",
        names: {
            en: "Ghana",
            ru: "Гана",
            es: "Ghana",
            pt: "Gana"
        }
    },
    {
        id: 82,
        alpha2: "GI",
        alpha3: "GIB",
        defaultLang: "en",
        phoneCode: "+350",
        names: {
            en: "Gibraltar",
            ru: "Гибралтар",
            es: "Gibraltar",
            pt: "Gibraltar"
        }
    },
    {
        id: 83,
        alpha2: "GR",
        alpha3: "GRC",
        defaultLang: "en",
        phoneCode: "+30",
        names: {
            en: "Greece",
            ru: "Греция",
            es: "Grecia",
            pt: "Grécia"
        }
    },
    {
        id: 84,
        alpha2: "GL",
        alpha3: "GRL",
        defaultLang: "en",
        phoneCode: "+299",
        names: {
            en: "Greenland",
            ru: "Гренландия",
            es: "Groenlandia",
            pt: "Groênlandia"
        }
    },
    {
        id: 85,
        alpha2: "GD",
        alpha3: "GRD",
        defaultLang: "es",
        phoneCode: "+1-473",
        names: {
            en: "Grenada",
            ru: "Гренада",
            es: "Granada",
            pt: "Granada"
        }
    },
    {
        id: 86,
        alpha2: "GP",
        alpha3: "GLP",
        defaultLang: "es",
        phoneCode: "+590",
        names: {
            en: "Guadeloupe",
            ru: "Гваделупа",
            es: "Guadeloupe",
            pt: "Guadalupe"
        }
    },
    {
        id: 87,
        alpha2: "GU",
        alpha3: "GUM",
        defaultLang: "es",
        phoneCode: "+671",
        names: {
            en: "Guam",
            ru: "Гуам",
            es: "Guam",
            pt: "Guam"
        }
    },
    {
        id: 88,
        alpha2: "GT",
        alpha3: "GTM",
        defaultLang: "es",
        phoneCode: "+502",
        names: {
            en: "Guatemala",
            ru: "Гватемала",
            es: "Guatemala",
            pt: "Guatemala"
        }
    },
    {
        id: 89,
        alpha2: "GN",
        alpha3: "GIN",
        defaultLang: "en",
        phoneCode: "+224",
        names: {
            en: "Guinea",
            ru: "Гвинея",
            es: "Guinea",
            pt: "Guiné"
        }
    },
    {
        id: 90,
        alpha2: "GW",
        alpha3: "GNB",
        defaultLang: "en",
        phoneCode: "+245",
        names: {
            en: "Guinea-Bissau",
            ru: "Гвинея-Биссау",
            es: "Guinea Bissau",
            pt: "Guiné Bissau"
        }
    },
    {
        id: 91,
        alpha2: "GY",
        alpha3: "GUY",
        defaultLang: "en",
        phoneCode: "+592",
        names: {
            en: "Guyana",
            ru: "Гайана",
            es: "Guyana",
            pt: "Guiana"
        }
    },
    {
        id: 92,
        alpha2: "HT",
        alpha3: "HTI",
        defaultLang: "en",
        phoneCode: "+509",
        names: {
            en: "Haiti",
            ru: "Гаити",
            es: "Haití",
            pt: "Haiti"
        }
    },
    {
        id: 93,
        alpha2: "HM",
        alpha3: "HMD",
        defaultLang: "en",
        phoneCode: "+1-672",
        names: {
            en: "Heard Island and McDonald Islands",
            ru: "Острова Херд и Макдональд",
            es: "Heard (Isla) e Islas McDonald",
            pt: "Ilha Heard e Ilhas McDonald"
        }
    },
    {
        id: 94,
        alpha2: "HN",
        alpha3: "HND",
        defaultLang: "es",
        phoneCode: "+504",
        names: {
            en: "Honduras",
            ru: "Гондурас",
            es: "Honduras",
            pt: "Honduras"
        }
    },
    {
        id: 95,
        alpha2: "HK",
        alpha3: "HKG",
        defaultLang: "en",
        phoneCode: "+852",
        names: {
            en: "Hong Kong",
            ru: "Гонконг",
            es: "Hong Kong",
            pt: "Hong Kong"
        }
    },
    {
        id: 96,
        alpha2: "HU",
        alpha3: "HUN",
        defaultLang: "en",
        phoneCode: "+36",
        names: {
            en: "Hungary",
            ru: "Венгрия",
            es: "Hungría",
            pt: "Hungria"
        }
    },
    {
        id: 97,
        alpha2: "IS",
        alpha3: "ISL",
        defaultLang: "en",
        phoneCode: "+354",
        names: {
            en: "Iceland",
            ru: "Исландия",
            es: "Islandia",
            pt: "Islândia"
        }
    },
    {
        id: 98,
        alpha2: "IN",
        alpha3: "IND",
        defaultLang: "en",
        phoneCode: "+91",
        names: {
            en: "India",
            ru: "Индия",
            es: "India",
            pt: "Índia"
        }
    },
    {
        id: 99,
        alpha2: "ID",
        alpha3: "IDN",
        defaultLang: "en",
        phoneCode: "+62",
        names: {
            en: "Indonesia",
            ru: "Индонезия",
            es: "Indonesia",
            pt: "Indonésia"
        }
    },
    {
        id: 100,
        alpha2: "IR",
        alpha3: "IRN",
        defaultLang: "en",
        phoneCode: "+98",
        names: {
            en: "Iran",
            ru: "Иран",
            es: "Irán",
            pt: "Irã"
        }
    },
    {
        id: 101,
        alpha2: "IQ",
        alpha3: "IRQ",
        defaultLang: "en",
        phoneCode: "+964",
        names: {
            en: "Iraq",
            ru: "Ирак",
            es: "Iraq",
            pt: "Iraque"
        }
    },
    {
        id: 102,
        alpha2: "IE",
        alpha3: "IRL",
        defaultLang: "en",
        phoneCode: "+353",
        names: {
            en: "Ireland",
            ru: "Ирландия",
            es: "Irlanda",
            pt: "Irlanda"
        }
    },
    {
        id: 103,
        alpha2: "IL",
        alpha3: "ISR",
        defaultLang: "en",
        phoneCode: "+972",
        names: {
            en: "Israel",
            ru: "Израиль",
            es: "Israel",
            pt: "Israel"
        }
    },
    {
        id: 104,
        alpha2: "IT",
        alpha3: "ITA",
        defaultLang: "en",
        phoneCode: "+39",
        names: {
            en: "Italy",
            ru: "Италия",
            es: "Italia",
            pt: "Itália"
        }
    },
    {
        id: 105,
        alpha2: "JM",
        alpha3: "JAM",
        defaultLang: "en",
        phoneCode: "+1-876",
        names: {
            en: "Jamaica",
            ru: "Ямайка",
            es: "Jamaica",
            pt: "Jamaica"
        }
    },
    {
        id: 106,
        alpha2: "JP",
        alpha3: "JPN",
        defaultLang: "en",
        phoneCode: "+81",
        names: {
            en: "Japan",
            ru: "Япония",
            es: "Japón",
            pt: "Japão"
        }
    },
    {
        id: 107,
        alpha2: "JO",
        alpha3: "JOR",
        defaultLang: "en",
        phoneCode: "+962",
        names: {
            en: "Jordan",
            ru: "Иордания",
            es: "Jordania",
            pt: "Jordânia"
        }
    },
    {
        id: 108,
        alpha2: "KZ",
        alpha3: "KAZ",
        defaultLang: "ru",
        phoneCode: "+7",
        names: {
            en: "Kazakhstan",
            ru: "Казахстан",
            es: "Kazajstán",
            pt: "Casaquistão"
        }
    },
    {
        id: 109,
        alpha2: "KE",
        alpha3: "KEN",
        defaultLang: "en",
        phoneCode: "+254",
        names: {
            en: "Kenya",
            ru: "Кения",
            es: "Kenya",
            pt: "Quênia"
        }
    },
    {
        id: 110,
        alpha2: "KI",
        alpha3: "KIR",
        defaultLang: "en",
        phoneCode: "+686",
        names: {
            en: "Kiribati",
            ru: "Кирибати",
            es: "Kiribati",
            pt: "Quiribati"
        }
    },
    {
        id: 111,
        alpha2: "KP",
        alpha3: "PRK",
        defaultLang: "en",
        phoneCode: "+82",
        names: {
            en: "Korea",
            ru: "Корея",
            es: "Corea",
            pt: "Coreia"
        }
    },
    {
        id: 112,
        alpha2: "KR",
        alpha3: "KOR",
        defaultLang: "en",
        phoneCode: "+82",
        names: {
            en: "Korea",
            ru: "Корея",
            es: "Corea",
            pt: "Coreia"
        }
    },
    {
        id: 113,
        alpha2: "KW",
        alpha3: "KWT",
        defaultLang: "en",
        phoneCode: "+965",
        names: {
            en: "Kuwait",
            ru: "Кувейт",
            es: "Kuwait",
            pt: "Kuwait"
        }
    },
    {
        id: 114,
        alpha2: "KG",
        alpha3: "KGZ",
        defaultLang: "ru",
        phoneCode: "+996",
        names: {
            en: "Kyrgyzstan",
            ru: "Кыргызстан",
            es: "Kirguistán",
            pt: "Quirguistão"
        }
    },
    {
        id: 115,
        alpha2: "LA",
        alpha3: "LAO",
        defaultLang: "en",
        phoneCode: "+856",
        names: {
            en: "Laos",
            ru: "Лаос",
            es: "Lao",
            pt: "Laos"
        }
    },
    {
        id: 116,
        alpha2: "LV",
        alpha3: "LVA",
        defaultLang: "en",
        phoneCode: "+371",
        names: {
            en: "Latvia",
            ru: "Латвия",
            es: "Letonia",
            pt: "Letônia"
        }
    },
    {
        id: 117,
        alpha2: "LB",
        alpha3: "LBN",
        defaultLang: "en",
        phoneCode: "+961",
        names: {
            en: "Lebanon",
            ru: "Ливан",
            es: "Líbano",
            pt: "Líbano"
        }
    },
    {
        id: 118,
        alpha2: "LS",
        alpha3: "LSO",
        defaultLang: "en",
        phoneCode: "+266",
        names: {
            en: "Lesotho",
            ru: "Лесcото",
            es: "Lesotho",
            pt: "Lesoto"
        }
    },
    {
        id: 119,
        alpha2: "LR",
        alpha3: "LBR",
        defaultLang: "en",
        phoneCode: "+231",
        names: {
            en: "Liberia",
            ru: "Либерия",
            es: "Liberia",
            pt: "Libéria"
        }
    },
    {
        id: 120,
        alpha2: "LY",
        alpha3: "LBY",
        defaultLang: "en",
        phoneCode: "+21",
        names: {
            en: "Libya",
            ru: "Ливия",
            es: "Libia",
            pt: "Líbia"
        }
    },
    {
        id: 121,
        alpha2: "LI",
        alpha3: "LIE",
        defaultLang: "en",
        phoneCode: "+41",
        names: {
            en: "Liechtenstein",
            ru: "Лихтенштейн",
            es: "Liechtenstein",
            pt: "Liechtenstein"
        }
    },
    {
        id: 122,
        alpha2: "LT",
        alpha3: "LTU",
        defaultLang: "en",
        phoneCode: "+370",
        names: {
            en: "Lithuania",
            ru: "Литва",
            es: "Lituania",
            pt: "Lituânia"
        }
    },
    {
        id: 123,
        alpha2: "LU",
        alpha3: "LUX",
        defaultLang: "en",
        phoneCode: "+352",
        names: {
            en: "Luxembourg",
            ru: "Люксембург",
            es: "Luxemburgo",
            pt: "Luxemburgo"
        }
    },
    {
        id: 124,
        alpha2: "MO",
        alpha3: "MAC",
        defaultLang: "en",
        phoneCode: "+853",
        names: {
            en: "Macao",
            ru: "Макао",
            es: "Macao",
            pt: "Macau"
        }
    },
    {
        id: 125,
        alpha2: "MK",
        alpha3: "MKD",
        defaultLang: "en",
        phoneCode: "+389",
        names: {
            en: "North Macedonia",
            ru: "Македония",
            es: "Macedonia",
            pt: "Macedônia"
        }
    },
    {
        id: 126,
        alpha2: "MG",
        alpha3: "MDG",
        defaultLang: "en",
        phoneCode: "+261",
        names: {
            en: "Madagascar",
            ru: "Мадагаскар",
            es: "Madagascar",
            pt: "Madagascar"
        }
    },
    {
        id: 127,
        alpha2: "MW",
        alpha3: "MWI",
        defaultLang: "en",
        phoneCode: "+265",
        names: {
            en: "Malawi",
            ru: "Малави",
            es: "Malawi",
            pt: "Malawi"
        }
    },
    {
        id: 128,
        alpha2: "MY",
        alpha3: "MYS",
        defaultLang: "en",
        phoneCode: "+60",
        names: {
            en: "Malaysia",
            ru: "Малайзия",
            es: "Malasia",
            pt: "Malásia"
        }
    },
    {
        id: 129,
        alpha2: "MV",
        alpha3: "MDV",
        defaultLang: "en",
        phoneCode: "+960",
        names: {
            en: "Maldives",
            ru: "Мальдивы",
            es: "Maldivas",
            pt: "Maldivas"
        }
    },
    {
        id: 130,
        alpha2: "ML",
        alpha3: "MLI",
        defaultLang: "en",
        phoneCode: "+223",
        names: {
            en: "Mali",
            ru: "Мали",
            es: "Mali",
            pt: "Mali"
        }
    },
    {
        id: 131,
        alpha2: "MT",
        alpha3: "MLT",
        defaultLang: "en",
        phoneCode: "+356",
        names: {
            en: "Malta",
            ru: "Мальта",
            es: "Malta",
            pt: "Malta"
        }
    },
    {
        id: 132,
        alpha2: "MH",
        alpha3: "MHL",
        defaultLang: "en",
        phoneCode: "+692",
        names: {
            en: "Marshall Islands",
            ru: "Маршалловы Острова",
            es: "Marshall",
            pt: "Ilhas Marshall"
        }
    },
    {
        id: 133,
        alpha2: "MQ",
        alpha3: "MTQ",
        defaultLang: "en",
        phoneCode: "+596",
        names: {
            en: "Martinique",
            ru: "Мартиник",
            es: "Martinique",
            pt: "Martinica"
        }
    },
    {
        id: 134,
        alpha2: "MR",
        alpha3: "MRT",
        defaultLang: "en",
        phoneCode: "+222",
        names: {
            en: "Mauritania",
            ru: "Мавритания",
            es: "Mauritania",
            pt: "Mauritânia"
        }
    },
    {
        id: 135,
        alpha2: "MU",
        alpha3: "MUS",
        defaultLang: "en",
        phoneCode: "+230",
        names: {
            en: "Mauritius",
            ru: "Маврикий",
            es: "Mauricio",
            pt: "Maurício"
        }
    },
    {
        id: 136,
        alpha2: "YT",
        alpha3: "MYT",
        defaultLang: "en",
        phoneCode: "+262",
        names: {
            en: "Mayotte",
            ru: "Майотта",
            es: "Mayotte",
            pt: "Mayotte"
        }
    },
    {
        id: 137,
        alpha2: "MX",
        alpha3: "MEX",
        defaultLang: "es",
        phoneCode: "+52",
        names: {
            en: "Mexico",
            ru: "Мексика",
            es: "México",
            pt: "México"
        }
    },
    {
        id: 138,
        alpha2: "FM",
        alpha3: "FSM",
        defaultLang: "es",
        phoneCode: "+691",
        names: {
            en: "Micronesia",
            ru: "Микронезия",
            es: "Micronesia",
            pt: "Micronésia"
        }
    },
    {
        id: 139,
        alpha2: "MD",
        alpha3: "MDA",
        defaultLang: "ru",
        phoneCode: "+373",
        names: {
            en: "Moldova",
            ru: "Молдова",
            es: "Moldova",
            pt: "Moldova"
        }
    },
    {
        id: 140,
        alpha2: "MC",
        alpha3: "MCO",
        defaultLang: "en",
        phoneCode: "+377",
        names: {
            en: "Monaco",
            ru: "Монако",
            es: "Mónaco",
            pt: "Mônaco"
        }
    },
    {
        id: 141,
        alpha2: "MN",
        alpha3: "MNG",
        defaultLang: "en",
        phoneCode: "+976",
        names: {
            en: "Mongolia",
            ru: "Монголия",
            es: "Mongolia",
            pt: "Mongólia"
        }
    },
    {
        id: 142,
        alpha2: "MS",
        alpha3: "MSR",
        defaultLang: "en",
        phoneCode: "+1-664",
        names: {
            en: "Montserrat",
            ru: "Монсеррат",
            es: "Montserrat",
            pt: "Montserrat"
        }
    },
    {
        id: 143,
        alpha2: "MA",
        alpha3: "MAR",
        defaultLang: "en",
        phoneCode: "+212",
        names: {
            en: "Morocco",
            ru: "Марокко",
            es: "Marruecos",
            pt: "Marrocos"
        }
    },
    {
        id: 144,
        alpha2: "MZ",
        alpha3: "MOZ",
        defaultLang: "en",
        phoneCode: "+258",
        names: {
            en: "Mozambique",
            ru: "Мозамбик",
            es: "Mozambique",
            pt: "Moçambique"
        }
    },
    {
        id: 145,
        alpha2: "MM",
        alpha3: "MMR",
        defaultLang: "en",
        phoneCode: "+95",
        names: {
            en: "Myanmar",
            ru: "Мьянма",
            es: "Birmania",
            pt: "Mianmar"
        }
    },
    {
        id: 146,
        alpha2: "NA",
        alpha3: "NAM",
        defaultLang: "en",
        phoneCode: "+264",
        names: {
            en: "Namibia",
            ru: "Намибия",
            es: "Namibia",
            pt: "Namíbia"
        }
    },
    {
        id: 147,
        alpha2: "NR",
        alpha3: "NRU",
        defaultLang: "en",
        phoneCode: "+674",
        names: {
            en: "Nauru",
            ru: "Науру",
            es: "Nauru",
            pt: "Nauru"
        }
    },
    {
        id: 148,
        alpha2: "NP",
        alpha3: "NPL",
        defaultLang: "en",
        phoneCode: "+977",
        names: {
            en: "Nepal",
            ru: "Непал",
            es: "Nepal",
            pt: "Nepal"
        }
    },
    {
        id: 149,
        alpha2: "NL",
        alpha3: "NLD",
        defaultLang: "en",
        phoneCode: "+31",
        names: {
            en: "Netherlands",
            ru: "Нидерланды",
            es: "Países Bajos",
            pt: "Holanda"
        }
    },
    {
        id: 150,
        alpha2: "NC",
        alpha3: "NCL",
        defaultLang: "en",
        phoneCode: "+687",
        names: {
            en: "New Caledonia",
            ru: "Новая Каледония",
            es: "Nueva Caledonia",
            pt: "Nova Caledônia"
        }
    },
    {
        id: 151,
        alpha2: "NZ",
        alpha3: "NZL",
        defaultLang: "en",
        phoneCode: "+64",
        names: {
            en: "N Zealand",
            ru: "Н Зеландия",
            es: "N Zelandia",
            pt: "N Zelândia"
        }
    },
    {
        id: 152,
        alpha2: "NI",
        alpha3: "NIC",
        defaultLang: "es",
        phoneCode: "+505",
        names: {
            en: "Nicaragua",
            ru: "Никарагуа",
            es: "Nicaragua",
            pt: "Nicarágua"
        }
    },
    {
        id: 153,
        alpha2: "NE",
        alpha3: "NER",
        defaultLang: "en",
        phoneCode: "+227",
        names: {
            en: "Niger",
            ru: "Нигер",
            es: "Níger (el)",
            pt: "Níger"
        }
    },
    {
        id: 154,
        alpha2: "NG",
        alpha3: "NGA",
        defaultLang: "en",
        phoneCode: "+234",
        names: {
            en: "Nigeria",
            ru: "Нигерия",
            es: "Nigeria",
            pt: "Nigéria"
        }
    },
    {
        id: 155,
        alpha2: "NU",
        alpha3: "NIU",
        defaultLang: "en",
        phoneCode: "+683",
        names: {
            en: "Niue",
            ru: "Ниуе",
            es: "Isla Niue",
            pt: "Niue"
        }
    },
    {
        id: 156,
        alpha2: "NF",
        alpha3: "NFK",
        defaultLang: "en",
        phoneCode: "+672",
        names: {
            en: "Norfolk Island",
            ru: "Остров Норфолк",
            es: "Norfolk",
            pt: "Ilha Norfolk"
        }
    },
    {
        id: 157,
        alpha2: "MP",
        alpha3: "MNP",
        defaultLang: "en",
        phoneCode: "+670",
        names: {
            en: "Northern Mariana Islands",
            ru: "Северные Марианские Острова",
            es: "Marianas del Norte",
            pt: "Ilhas Marianas do Norte"
        }
    },
    {
        id: 158,
        alpha2: "NO",
        alpha3: "NOR",
        defaultLang: "en",
        phoneCode: "+47",
        names: {
            en: "Norway",
            ru: "Норвегия",
            es: "Noruega",
            pt: "Noruega"
        }
    },
    {
        id: 159,
        alpha2: "OM",
        alpha3: "OMN",
        defaultLang: "en",
        phoneCode: "+968",
        names: {
            en: "Oman",
            ru: "Оман",
            es: "Omán",
            pt: "Omã"
        }
    },
    {
        id: 160,
        alpha2: "PK",
        alpha3: "PAK",
        defaultLang: "en",
        phoneCode: "+92",
        names: {
            en: "Pakistan",
            ru: "Пакистан",
            es: "Pakistán",
            pt: "Paquistão"
        }
    },
    {
        id: 161,
        alpha2: "PW",
        alpha3: "PLW",
        defaultLang: "en",
        phoneCode: "+680",
        names: {
            en: "Palau",
            ru: "Палау",
            es: "Palau",
            pt: "Palau"
        }
    },
    {
        id: 162,
        alpha2: "PA",
        alpha3: "PAN",
        defaultLang: "es",
        phoneCode: "+507",
        names: {
            en: "Panama",
            ru: "Панама",
            es: "Panamá",
            pt: "Panamá"
        }
    },
    {
        id: 163,
        alpha2: "PG",
        alpha3: "PNG",
        defaultLang: "en",
        phoneCode: "+675",
        names: {
            en: "Papua New Guinea",
            ru: "Папуа-Новая Гвинея",
            es: "Papúa Nueva Guinea",
            pt: "Papua-Nova Guiné"
        }
    },
    {
        id: 164,
        alpha2: "PY",
        alpha3: "PRY",
        defaultLang: "es",
        phoneCode: "+595",
        names: {
            en: "Paraguay",
            ru: "Парагвай",
            es: "Paraguay",
            pt: "Paraguai"
        }
    },
    {
        id: 165,
        alpha2: "PE",
        alpha3: "PER",
        defaultLang: "es",
        phoneCode: "+51",
        names: {
            en: "Peru",
            ru: "Перу",
            es: "Perú",
            pt: "Peru"
        }
    },
    {
        id: 166,
        alpha2: "PH",
        alpha3: "PHL",
        defaultLang: "en",
        phoneCode: "+63",
        names: {
            en: "Philippines",
            ru: "Филиппины",
            es: "Filipinas",
            pt: "Filipinas"
        }
    },
    {
        id: 167,
        alpha2: "PN",
        alpha3: "PCN",
        defaultLang: "en",
        phoneCode: "+64",
        names: {
            en: "Pitcairn",
            ru: "Питкерн",
            es: "Pitcairn",
            pt: "Pitcairn"
        }
    },
    {
        id: 168,
        alpha2: "PL",
        alpha3: "POL",
        defaultLang: "en",
        phoneCode: "+48",
        names: {
            en: "Poland",
            ru: "Польша",
            es: "Polonia",
            pt: "Polônia"
        }
    },
    {
        id: 169,
        alpha2: "PT",
        alpha3: "PRT",
        defaultLang: "pt",
        phoneCode: "+351",
        names: {
            en: "Portugal",
            ru: "Португалия",
            es: "Portugal",
            pt: "Portugal"
        }
    },
    {
        id: 170,
        alpha2: "PR",
        alpha3: "PRI",
        defaultLang: "en",
        phoneCode: "+1-787",
        names: {
            en: "Puerto Rico",
            ru: "Пуэрто-Рико",
            es: "Puerto Rico",
            pt: "Porto Rico"
        }
    },
    {
        id: 171,
        alpha2: "QA",
        alpha3: "QAT",
        defaultLang: "en",
        phoneCode: "+974",
        names: {
            en: "Qatar",
            ru: "Катар",
            es: "Qatar",
            pt: "Catar"
        }
    },
    {
        id: 172,
        alpha2: "RE",
        alpha3: "REU",
        defaultLang: "en",
        phoneCode: "+262",
        names: {
            en: "Reunion",
            ru: "Реюньон",
            es: "Reunión",
            pt: "Reunião"
        }
    },
    {
        id: 173,
        alpha2: "RO",
        alpha3: "ROU",
        defaultLang: "en",
        phoneCode: "+40",
        names: {
            en: "Romania",
            ru: "Румыния",
            es: "Rumanía",
            pt: "Romênia"
        }
    },
    {
        id: 174,
        alpha2: "RU",
        alpha3: "RUS",
        defaultLang: "ru",
        phoneCode: "+7",
        names: {
            en: "Russia",
            ru: "Россия",
            es: "Rusia",
            pt: "Rússia"
        }
    },
    {
        id: 175,
        alpha2: "RW",
        alpha3: "RWA",
        defaultLang: "en",
        phoneCode: "+250",
        names: {
            en: "Rwanda",
            ru: "Руанда",
            es: "Rwanda",
            pt: "Ruanda"
        }
    },
    {
        id: 176,
        alpha2: "KN",
        alpha3: "KNA",
        defaultLang: "en",
        phoneCode: "+1-869",
        names: {
            en: "Saint Kitts and Nevis",
            ru: "Сент-Киттс и Невис",
            es: "Saint Kitts y Nevis",
            pt: "São Cristovão e Nevis"
        }
    },
    {
        id: 177,
        alpha2: "LC",
        alpha3: "LCA",
        defaultLang: "en",
        phoneCode: "+1-758",
        names: {
            en: "Saint Lucia",
            ru: "Сент-Люсия",
            es: "Santa Lucía",
            pt: "Santa Lúcia"
        }
    },
    {
        id: 178,
        alpha2: "VC",
        alpha3: "VCT",
        defaultLang: "en",
        phoneCode: "+1-784",
        names: {
            en: "Saint Vincent and the Grenadines",
            ru: "Сент-Винсент и Гренадины",
            es: "San Vicente y las Granadinas",
            pt: "São Vicente e Granadinas"
        }
    },
    {
        id: 179,
        alpha2: "WS",
        alpha3: "WSM",
        defaultLang: "en",
        phoneCode: "+685",
        names: {
            en: "Samoa",
            ru: "Самоа",
            es: "Samoa",
            pt: "Samoa"
        }
    },
    {
        id: 180,
        alpha2: "SM",
        alpha3: "SMR",
        defaultLang: "en",
        phoneCode: "+39",
        names: {
            en: "San Marino",
            ru: "Сан-Марино",
            es: "San Marino",
            pt: "San Marino"
        }
    },
    {
        id: 181,
        alpha2: "ST",
        alpha3: "STP",
        defaultLang: "en",
        phoneCode: "+239",
        names: {
            en: "Sao Tome and Principe",
            ru: "Сан-Томе и Принсипи",
            es: "Santo Tomé y Príncipe",
            pt: "São Tomé e Príncipe"
        }
    },
    {
        id: 182,
        alpha2: "SA",
        alpha3: "SAU",
        defaultLang: "en",
        phoneCode: "+966",
        names: {
            en: "Saudi Arabia",
            ru: "Саудовская Аравия",
            es: "Arabia Saudita",
            pt: "Arábia Saudita"
        }
    },
    {
        id: 183,
        alpha2: "SN",
        alpha3: "SEN",
        defaultLang: "en",
        phoneCode: "+221",
        names: {
            en: "Senegal",
            ru: "Сенегал",
            es: "Senegal",
            pt: "Senegal"
        }
    },
    {
        id: 184,
        alpha2: "SC",
        alpha3: "SYC",
        defaultLang: "en",
        phoneCode: "+248",
        names: {
            en: "Seychelles",
            ru: "Сейшелы",
            es: "Seychelles",
            pt: "Seychelles"
        }
    },
    {
        id: 185,
        alpha2: "SL",
        alpha3: "SLE",
        defaultLang: "en",
        phoneCode: "+232",
        names: {
            en: "Sierra Leone",
            ru: "Сьерра-Леоне",
            es: "Sierra Leona",
            pt: "Serra Leoa"
        }
    },
    {
        id: 186,
        alpha2: "SG",
        alpha3: "SGP",
        defaultLang: "en",
        phoneCode: "+65",
        names: {
            en: "Singapore",
            ru: "Сингапур",
            es: "Singapur",
            pt: "Cingapura"
        }
    },
    {
        id: 187,
        alpha2: "SK",
        alpha3: "SVK",
        defaultLang: "en",
        phoneCode: "+421",
        names: {
            en: "Slovakia",
            ru: "Словакия",
            es: "Eslovaquia",
            pt: "Eslováquia"
        }
    },
    {
        id: 188,
        alpha2: "SI",
        alpha3: "SVN",
        defaultLang: "en",
        phoneCode: "+386",
        names: {
            en: "Slovenia",
            ru: "Словения",
            es: "Eslovenia",
            pt: "Eslovênia"
        }
    },
    {
        id: 189,
        alpha2: "SB",
        alpha3: "SLB",
        defaultLang: "en",
        phoneCode: "+677",
        names: {
            en: "Solomon Islands",
            ru: "Соломоновы Острова",
            es: "Salomón",
            pt: "Ilhas Salomão"
        }
    },
    {
        id: 190,
        alpha2: "SO",
        alpha3: "SOM",
        defaultLang: "en",
        phoneCode: "+252",
        names: {
            en: "Somalia",
            ru: "Сомали",
            es: "Somalia",
            pt: "Somália"
        }
    },
    {
        id: 191,
        alpha2: "ZA",
        alpha3: "ZAF",
        defaultLang: "en",
        phoneCode: "+27",
        names: {
            en: "South Africa",
            ru: "Южная Африка",
            es: "Sudáfrica",
            pt: "África do Sul"
        }
    },
    {
        id: 192,
        alpha2: "ES",
        alpha3: "ESP",
        defaultLang: "es",
        phoneCode: "+34",
        names: {
            en: "Spain",
            ru: "Испания",
            es: "España",
            pt: "Espanha"
        }
    },
    {
        id: 193,
        alpha2: "LK",
        alpha3: "LKA",
        defaultLang: "en",
        phoneCode: "+94",
        names: {
            en: "Sri Lanka",
            ru: "Шри-Ланка",
            es: "Sri Lanka",
            pt: "Sri Lanka"
        }
    },
    {
        id: 194,
        alpha2: "SH",
        alpha3: "SHN",
        defaultLang: "en",
        phoneCode: "+247",
        names: {
            en: "Saint Helena",
            ru: "Остров Святой Елены",
            es: "Santa Helena",
            pt: "Santa Helena"
        }
    },
    {
        id: 195,
        alpha2: "PM",
        alpha3: "SPM",
        defaultLang: "en",
        phoneCode: "+508",
        names: {
            en: "Saint Pierre and Miquelon",
            ru: "Сен-Пьер и Микелон",
            es: "San Pedro y Miquelón",
            pt: "Saint Pierre e Miquelon"
        }
    },
    {
        id: 196,
        alpha2: "SD",
        alpha3: "SDN",
        defaultLang: "en",
        phoneCode: "+249",
        names: {
            en: "Sudan",
            ru: "Судан",
            es: "Sudán",
            pt: "Sudão"
        }
    },
    {
        id: 197,
        alpha2: "SR",
        alpha3: "SUR",
        defaultLang: "en",
        phoneCode: "+597",
        names: {
            en: "Suriname",
            ru: "Суринам",
            es: "Suriname",
            pt: "Suriname"
        }
    },
    {
        id: 198,
        alpha2: "SJ",
        alpha3: "SJM",
        defaultLang: "en",
        phoneCode: "+47",
        names: {
            en: "Svalbard and Jan Mayen",
            ru: "Свальбард и Ян-Майен",
            es: "Svalbard y Jan Mayen",
            pt: "Svalbard e Jan Mayen"
        }
    },
    {
        id: 199,
        alpha2: "SZ",
        alpha3: "SWZ",
        defaultLang: "en",
        phoneCode: "+268",
        names: {
            en: "Eswatini",
            ru: "Свазиленд",
            es: "Suazilandia",
            pt: "Suazilândia"
        }
    },
    {
        id: 200,
        alpha2: "SE",
        alpha3: "SWE",
        defaultLang: "en",
        phoneCode: "+46",
        names: {
            en: "Sweden",
            ru: "Швеция",
            es: "Suecia",
            pt: "Suécia"
        }
    },
    {
        id: 201,
        alpha2: "CH",
        alpha3: "CHE",
        defaultLang: "en",
        phoneCode: "+41",
        names: {
            en: "Switzerland",
            ru: "Швейцария",
            es: "Suiza",
            pt: "Suíça"
        }
    },
    {
        id: 202,
        alpha2: "SY",
        alpha3: "SYR",
        defaultLang: "en",
        phoneCode: "+963",
        names: {
            en: "Syria",
            ru: "Сирия",
            es: "Siria",
            pt: "Síria"
        }
    },
    {
        id: 203,
        alpha2: "TW",
        alpha3: "TWN",
        defaultLang: "en",
        phoneCode: "+886",
        names: {
            en: "Taiwan",
            ru: "Тайвань",
            es: "Taiwán",
            pt: "Taiwan"
        }
    },
    {
        id: 204,
        alpha2: "TJ",
        alpha3: "TJK",
        defaultLang: "ru",
        phoneCode: "+992",
        names: {
            en: "Tajikistan",
            ru: "Таджикистан",
            es: "Tayikistán",
            pt: "Tadjiquistão"
        }
    },
    {
        id: 205,
        alpha2: "TZ",
        alpha3: "TZA",
        defaultLang: "en",
        phoneCode: "+255",
        names: {
            en: "Tanzania",
            ru: "Танзания",
            es: "Tanzania",
            pt: "Tanzânia"
        }
    },
    {
        id: 206,
        alpha2: "TH",
        alpha3: "THA",
        defaultLang: "en",
        phoneCode: "+66",
        names: {
            en: "Thailand",
            ru: "Таиланд",
            es: "Tailandia",
            pt: "Tailândia"
        }
    },
    {
        id: 207,
        alpha2: "TG",
        alpha3: "TGO",
        defaultLang: "en",
        phoneCode: "+228",
        names: {
            en: "Togo",
            ru: "Того",
            es: "Togo",
            pt: "Togo"
        }
    },
    {
        id: 208,
        alpha2: "TK",
        alpha3: "TKL",
        defaultLang: "en",
        phoneCode: "+690",
        names: {
            en: "Tokelau",
            ru: "Токелау",
            es: "Tokelau",
            pt: "Tokelau"
        }
    },
    {
        id: 209,
        alpha2: "TO",
        alpha3: "TON",
        defaultLang: "en",
        phoneCode: "+676",
        names: {
            en: "Tonga",
            ru: "Тонга",
            es: "Tonga",
            pt: "Tonga"
        }
    },
    {
        id: 210,
        alpha2: "TT",
        alpha3: "TTO",
        defaultLang: "en",
        phoneCode: "+1-868",
        names: {
            en: "Trinidad and Tobago",
            ru: "Тринидад и Тобаго",
            es: "Trinidad y Tobago",
            pt: "Trinidad e Tobago"
        }
    },
    {
        id: 211,
        alpha2: "TN",
        alpha3: "TUN",
        defaultLang: "en",
        phoneCode: "+21",
        names: {
            en: "Tunisia",
            ru: "Тунис",
            es: "Túnez",
            pt: "Tunísia"
        }
    },
    {
        id: 212,
        alpha2: "TR",
        alpha3: "TUR",
        defaultLang: "en",
        phoneCode: "+90",
        names: {
            en: "Turkey",
            ru: "Турция",
            es: "Turquía",
            pt: "Turquia"
        }
    },
    {
        id: 213,
        alpha2: "TM",
        alpha3: "TKM",
        defaultLang: "ru",
        phoneCode: "+993",
        names: {
            en: "Turkmenistan",
            ru: "Туркменистан",
            es: "Turkmenistán",
            pt: "Turcomenistão"
        }
    },
    {
        id: 214,
        alpha2: "TC",
        alpha3: "TCA",
        defaultLang: "en",
        phoneCode: "+1-649",
        names: {
            en: "Turks and Caicos Islands",
            ru: "Острова Тёркс и Кайкос",
            es: "Turcas y Caicos",
            pt: "Ilhas Turks e Caicos"
        }
    },
    {
        id: 215,
        alpha2: "TV",
        alpha3: "TUV",
        defaultLang: "en",
        phoneCode: "+688",
        names: {
            en: "Tuvalu",
            ru: "Тувалу",
            es: "Tuvalu",
            pt: "Tuvalu"
        }
    },
    {
        id: 216,
        alpha2: "UG",
        alpha3: "UGA",
        defaultLang: "en",
        phoneCode: "+256",
        names: {
            en: "Uganda",
            ru: "Уганда",
            es: "Uganda",
            pt: "Uganda"
        }
    },
    {
        id: 217,
        alpha2: "UA",
        alpha3: "UKR",
        defaultLang: "ru",
        phoneCode: "+380",
        names: {
            en: "Ukraine",
            ru: "Украина",
            es: "Ucrania",
            pt: "Ucrânia"
        }
    },
    {
        id: 218,
        alpha2: "AE",
        alpha3: "ARE",
        defaultLang: "en",
        phoneCode: "+971",
        names: {
            en: "United Arab Emirates",
            ru: "Объединенные Арабские Эмираты",
            es: "Emiratos Árabes Unidos",
            pt: "Emirados Árabes Unidos"
        }
    },
    {
        id: 219,
        alpha2: "GB",
        alpha3: "GBR",
        defaultLang: "en",
        phoneCode: "+44",
        names: {
            en: "United Kingdom",
            ru: "Британия",
            es: "Inglaterra",
            pt: "Inglaterra"
        }
    },
    {
        id: 220,
        alpha2: "US",
        alpha3: "USA",
        defaultLang: "en",
        phoneCode: "+1",
        names: {
            en: "USA",
            ru: "США",
            es: "Estados Unidos",
            pt: "Estados Unidos"
        }
    },
    {
        id: 221,
        alpha2: "UM",
        alpha3: "UMI",
        defaultLang: "en",
        phoneCode: "+246",
        names: {
            en: "United States Minor Outlying Islands",
            ru: "Внешние малые острова (США)",
            es: "Islas Ultramarinas Menores de los Estados Unidos (las)",
            pt: "Ilhas Menores Distantes dos Estados Unidos"
        }
    },
    {
        id: 222,
        alpha2: "UY",
        alpha3: "URY",
        defaultLang: "es",
        phoneCode: "+598",
        names: {
            en: "Uruguay",
            ru: "Уругвай",
            es: "Uruguay",
            pt: "Uruguai"
        }
    },
    {
        id: 223,
        alpha2: "UZ",
        alpha3: "UZB",
        defaultLang: "ru",
        phoneCode: "+998",
        names: {
            en: "Uzbekistan",
            ru: "Узбекистан",
            es: "Uzbekistán",
            pt: "Uzbequistão"
        }
    },
    {
        id: 224,
        alpha2: "VU",
        alpha3: "VUT",
        defaultLang: "en",
        phoneCode: "+678",
        names: {
            en: "Vanuatu",
            ru: "Вануату",
            es: "Vanuatu",
            pt: "Vanuatu"
        }
    },
    {
        id: 225,
        alpha2: "VA",
        alpha3: "VAT",
        defaultLang: "en",
        phoneCode: "+39",
        names: {
            en: "Holy See",
            ru: "Ватикан",
            es: "Santa Sede",
            pt: "Vaticano"
        }
    },
    {
        id: 226,
        alpha2: "VE",
        alpha3: "VEN",
        defaultLang: "es",
        phoneCode: "+58",
        names: {
            en: "Venezuela",
            ru: "Венесуэла",
            es: "Venezuela",
            pt: "Venezuela"
        }
    },
    {
        id: 227,
        alpha2: "VN",
        alpha3: "VNM",
        defaultLang: "en",
        phoneCode: "+84",
        names: {
            en: "Viet Nam",
            ru: "Вьетнам",
            es: "Viet Nam",
            pt: "Vietnã"
        }
    },
    {
        id: 228,
        alpha2: "VG",
        alpha3: "VGB",
        defaultLang: "en",
        phoneCode: "+1-284",
        names: {
            en: "Virgin Islands",
            ru: "Британские Виргинские Острова",
            es: "Vírgenes británicas",
            pt: "Ilhas Virgens Britânicas"
        }
    },
    {
        id: 229,
        alpha2: "VI",
        alpha3: "VIR",
        defaultLang: "en",
        phoneCode: "+1-340",
        names: {
            en: "Virgin Islands (U.S.)",
            ru: "Американские Виргинские острова",
            es: "Vírgenes de los Estados Unidos",
            pt: "Ilhas Virgens dos EUA"
        }
    },
    {
        id: 230,
        alpha2: "WF",
        alpha3: "WLF",
        defaultLang: "en",
        phoneCode: "+681",
        names: {
            en: "Wallis and Futuna",
            ru: "Уоллис и Футуна",
            es: "Wallis y Futuna",
            pt: "Wallis e Futuna"
        }
    },
    {
        id: 231,
        alpha2: "EH",
        alpha3: "ESH",
        defaultLang: "en",
        phoneCode: "+21",
        names: {
            en: "Western Sahara",
            ru: "Западная Сахара",
            es: "Sahara Occidental",
            pt: "Saara Ocidental"
        }
    },
    {
        id: 232,
        alpha2: "YE",
        alpha3: "YEM",
        defaultLang: "en",
        phoneCode: "+967",
        names: {
            en: "Yemen",
            ru: "Йемен",
            es: "Yemen",
            pt: "Iêmen"
        }
    },
    {
        id: 234,
        alpha2: "CD",
        alpha3: "COD",
        defaultLang: "en",
        phoneCode: "+242",
        names: {
            ru: "Конго",
            en: "Congo",
            es: "COD",
            pt: "COD"
        }
    },
    {
        id: 235,
        alpha2: "ZM",
        alpha3: "ZMB",
        defaultLang: "en",
        phoneCode: "+260",
        names: {
            en: "Zambia",
            ru: "Замбия",
            es: "Zambia",
            pt: "Zâmbia"
        }
    },
    {
        id: 236,
        alpha2: "ZW",
        alpha3: "ZWE",
        defaultLang: "en",
        phoneCode: "+263",
        names: {
            en: "Zimbabwe",
            ru: "Зимбабве",
            es: "Zimbabwe",
            pt: "Zimbábue"
        }
    },
    {
        id: 250,
        alpha2: "SCT",
        alpha3: "SCT",
        defaultLang: "en",
        phoneCode: "+44",
        names: {
            ru: "Шотландия",
            en: "Scotland",
            es: "SCT",
            pt: "SCT"
        }
    },
    {
        id: 251,
        alpha2: "WLS",
        alpha3: "WLS",
        defaultLang: "en",
        phoneCode: "+44",
        names: {
            ru: "Уэльс",
            en: "Wales",
            es: "WLS",
            pt: "WLS"
        }
    },
    {
        id: 249,
        alpha2: "EN",
        alpha3: "ENG",
        defaultLang: "en",
        phoneCode: "+44",
        names: {
            en: "England",
            ru: "Англия",
            es: "Inglaterra",
            pt: "Inglaterra"
        }
    },
    {
        id: 261,
        alpha2: "RS",
        alpha3: "SRB",
        defaultLang: "en",
        phoneCode: "+381",
        names: {
            en: "Serbia",
            ru: "Сербия",
            es: "Serbia",
            pt: "Sérvia"
        }
    },
    {
        id: 262,
        alpha2: "NL",
        alpha3: "NLD",
        defaultLang: "en",
        phoneCode: "+31",
        names: {
            en: "Netherlands",
            ru: "Нидерланды",
            es: "Países Bajos",
            pt: "Holanda"
        }
    },
    {
        id: 263,
        alpha2: "NIR",
        alpha3: "NIR",
        defaultLang: "en",
        phoneCode: "+44",
        names: {
            ru: "С. Ирландия",
            en: "N. Ireland",
            es: "N. Irlanda",
            pt: "N. Irlanda"
        }
    },
    {
        id: 264,
        alpha2: "EU",
        alpha3: "EU",
        names: {
            ru: "ЕС",
            en: "EU",
            es: "EU",
            pt: "EU"
        }
    },
    {
        id: 266,
        alpha2: "ME",
        alpha3: "MNE",
        defaultLang: "en",
        phoneCode: "+382",
        names: {
            ru: "Черногория",
            en: "Montenegro",
            es: "Montenegro",
            pt: "Montenegro"
        }
    },
    {
        id: INTERNATIONAL_COUNTRY_ID,
        alpha2: INTERNATIONAL_COUNTRY_IMAGE,
        alpha3: INTERNATIONAL_COUNTRY_IMAGE,
        defaultLang: "en",
        phoneCode: "",
        names: {
            ru: "Международные",
            en: "International",
            es: "Internacional",
            pt: "Internacional"
        }
    }
];
