import { Cloneable, Comparable } from "@sportaq/common/types/interfaces";
import EPeriodType from "@sportaq/common/enums/period-types";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { markRaw } from "vue";
import EventType from "@sportaq/common/enums/event-type";
import { ESportType } from "@sportaq/common/enums/sport-type";

export interface PeriodSortInfoOptions {
    reverseTimeSorting: boolean;
}

export class PeriodSortInfo implements Comparable<PeriodSortInfo>, Cloneable<PeriodSortInfo>, Record<string, unknown> {
    [key: string]: unknown;

    protected constructor (
        readonly id: number,
        readonly eventType: EventType,
        readonly sportType: ESportType,
        readonly positionId: number,
        readonly eventId: number,
        readonly groupEvent: boolean,
        readonly period: EPeriodType,
        protected startTime: Date,
        protected options: PeriodSortInfoOptions) {
    }

    compare (o: PeriodSortInfo): number {
        let result = o.eventType - this.eventType;
        if (result === 0) {
            if (o.groupEvent && !this.groupEvent) {
                result = 1;
            }
            if (!o.groupEvent && this.groupEvent) {
                result = -1;
            }
        }
        if (result === 0) {
            if ((this.eventType === EventType.LIVE) && (this.options.reverseTimeSorting)) {
                result = o.startTime.getTime() - this.startTime.getTime();
            } else {
                result = this.startTime.getTime() - o.startTime.getTime();
            }
        }
        if (result === 0) {
            result = this.positionId - o.positionId;
        }
        if (result === 0) {
            result = this.period - o.period;
        }
        return result;
    }

    clone (): PeriodSortInfo {
        return new PeriodSortInfo(this.id, this.eventType, this.sportType, this.positionId, this.eventId, this.groupEvent, this.period, this.startTime, this.options);
    }

    public static createEvent (event: BettingEvent, period: EPeriodType, options: PeriodSortInfoOptions): PeriodSortInfo {
        return markRaw(
            new PeriodSortInfo(
                event.positionId * 100 + period * 10 + event.eventType,
                event.eventType,
                event.sportTypeId,
                event.positionId,
                event.eventId,
                event.isGroupEvent,
                period,
                event.startTime,
                options
            ));
    }
}
