import { MockBaseStreamingNetwork } from "@sportaq/vue/__test__/common/streaming/mock-base-streaming-network";
import { PlaywrightWindow } from "@sportaq/vue/components/common/dependency-container/playwright-window";
import { timeout } from "@sportaq/common/utils/time-utils";
import { StreamingNetworkRoutes } from "@sportaq/services/streaming/streaming-network/streaming-network-routes";

export class PlaywrightStreamingNetwork extends MockBaseStreamingNetwork {
    private requestResponseResults = new Map<string, unknown>();
    private lastPushId: string = "";

    async pushFixtureToRequestResponse (route: string, fixture: string) {
        const playwrightWindow = window as unknown as PlaywrightWindow;
        const msgStr = await playwrightWindow.readTestData(fixture);
        const msg = JSON.parse(msgStr);
        this.requestResponseResults.set(route, msg);
        return msg;
    }

    async requestResponse<T> (route: string): Promise<T> {
        const response = this.requestResponseResults.get(route);
        if (!response) {
            return await this.defaultFixtureToRequestResponse(route);
        }
        if (response) {
            return response as T;
        }
        return super.requestResponse(route);
    }

    private async defaultFixtureToRequestResponse (route: string) {
        switch (route) {
            case StreamingNetworkRoutes.GAME_PROVIDERS_REQUEST: {
                return this.pushFixtureToRequestResponse(route, "/../../packages_tests/e2e/__data__/streaming-network/games/game-providers/providers.json");
            }
            default: {
                return undefined;
            }
        }
    }

    async pushFixtureToStream (pushId: string, route: string, folder: string, startIndex: number, endIndex?: number) {
        if (this.lastPushId === pushId) {
            // playwright tries to repeat push when any error is raised
            return;
        }
        if (!endIndex) {
            endIndex = startIndex;
        }
        for (let i = startIndex; i <= endIndex; i++) {
            const fixture = PlaywrightStreamingNetwork.getDiffsFileName(route, i);
            await this.pushSingleToStream(route, folder + "/" + fixture);
        }
        this.lastPushId = pushId;
    }

    async pushSingleFixtureToStream (pushId: string, route: string, fixture: string) {
        if (this.lastPushId === pushId) {
            // playwright tries to repeat push when any error is raised
            return;
        }
        await this.pushSingleToStream(route, fixture);
        this.lastPushId = pushId;
    }

    private async pushSingleToStream (route: string, fixture: string) {
        const playwrightWindow = window as unknown as PlaywrightWindow;
        const msgStr = await playwrightWindow.readTestData(fixture);
        const msg = JSON.parse(msgStr);
        await this.asyncPushToStream(route, msg);
    }

    async asyncPushToStream (route: string, msg: string): Promise<void> {
        try {
            this.pushToStream(route, msg);
        } catch (e) {
            await timeout(50);
            await this.asyncPushToStream(route, msg);
        }
    }
}
