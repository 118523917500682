import { computed, PropType } from "vue";
import { Card, CardItem } from "@sportaq/model/cashier/card";
import { useI18n } from "vue-i18n";
import { getLocalizedQuotationTitle } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { getParticipantsAsString } from "@sportaq/model/common/participants-functions";

export default {
    name: "CardPanel",
    props: {
        card: {
            required: true,
            type: Object as PropType<Card>
        },
        showPayCode: {
            required: false,
            default: true,
            type: Boolean
        }
    },
    setup (props: { card: Card }) {
        const i18n = useI18n();

        function getQuotationTitle (item: CardItem): string {
            return getLocalizedQuotationTitle(i18n, item);
        }

        function getParticipants (item: CardItem): string {
            return getParticipantsAsString(item, item.quotationKey);
        }

        const cardStatus = computed(() => {
            if (props.card.isCancel) {
                return i18n.t("cashier.monitoring.table.iscancel");
            }
            if (props.card.isPaid) {
                return i18n.t(props.card.isWin ? "mobile.stakesView.cardStatus.win" : "mobile.stakesView.cardStatus.lost");
            }
            return i18n.t("mobile.stakesView.cardStatus.accepted");
        });
        return {
            i18n,
            getQuotationTitle,
            getParticipants,
            cardStatus
        };
    }
};
