import { PositionResponse, SearchEventsResponse } from "@sportaq/model/types/responses";
import {
    SearchEventResponseMsg
} from "@sportaq/services/streaming/streaming-network/messages/responses/search/SearchEventResponse_pb";

export class SearchEventsResponseDeserializer {
    static deserializeBinary (message: Uint8Array): SearchEventsResponse {
        const msg = SearchEventResponseMsg.deserializeBinary(message);
        const positions: PositionResponse[] = [];
        for (const item of msg.getItemList()) {
            positions.push({
                eventType: item.getEventtype(),
                positionId: item.getPositionid()
            });
        }
        return {
            messageId: msg.getMessageid(),
            positions
        };
    }
}
