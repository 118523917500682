import { Form, Field } from "vee-validate";
import EmailDone from "@/views/registration-view/components/emaildone/EmailDone.vue";
import CountrySelect from "@sportaq/vue/components/web/registration/country-select/CountrySelect.vue";
import Policy from "@sportaq/vue/components/web/registration/policy/Policy.vue";
import PasswordField from "@sportaq/vue/components/web/password-field/PasswordField.vue";
import { useEmailRegistrationCommon } from "@sportaq/vue/components/web/registration/email-common";
import { Bonus } from "@sportaq/model/web/bonus";
import { ref } from "vue";

export default {
    name: "EmailRegistration",
    components: {
        Form, Field, EmailDone, CountrySelect, Policy, PasswordField
    },
    props: {
        selectedBonusType: {
            type: String,
            required: false,
            default: undefined
        }
    },
    setup (props: { readonly selectedBonusType: string }) {
        const showDescription = ref(false);
        const {
            btnDisabled,
            isBonusTerms,
            selectedBonus,
            bonusCheck,
            isRequestPending,
            sendSuccess,
            phoneCode,
            countryId,
            referralCode,
            maxDate,
            customErrors,
            phoneElementRef,
            emailElementRef,
            documentElementRef,
            loginElementRef,
            bonuses,
            pageTop,
            lang,
            site,
            agreement,
            getBonusName,
            selectBonus,
            getBonusClass,
            getImageUrl,
            onPhoneKeyDown: onNaturalNumberKeyFilter,
            onDocumentKeyFilter,
            onSubmit
        } = useEmailRegistrationCommon(props.selectedBonusType);
        function bonusClick (bonus: Bonus | undefined) {
            selectedBonus.value = bonus;
            selectBonus();
        }
        return {
            showDescription,
            btnDisabled,
            isBonusTerms,
            selectedBonus,
            bonusCheck,
            isRequestPending,
            sendSuccess,
            phoneCode,
            countryId,
            referralCode,
            maxDate,
            customErrors,
            phoneElementRef,
            emailElementRef,
            documentElementRef,
            loginElementRef,
            bonuses,
            pageTop,
            lang,
            site,
            agreement,
            bonusClick,
            getBonusName,
            selectBonus,
            getBonusClass,
            getImageUrl,
            onPhoneKeyDown: onNaturalNumberKeyFilter,
            onDocumentKeyFilter,
            onSubmit
        };
    }
};
