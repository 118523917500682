import PartnerWithdrawalDialog from "@/views/withdrawal-view/components/partner-withdrawal-dialog/PartnerWithdrawalDialog.vue";
import VisaWithdrawalDialog from "@/views/withdrawal-view/components/visa-withdrawal-dialog/VisaWithdrawalDialog.vue";
import EquadorWithdrawalDialog from "@/views/withdrawal-view/components/equador-withdrawal-dialog/EquadorWithdrawalDialog.vue";
import ExpressWithdrawalDialog from "@/views/withdrawal-view/components/express-withdrawal-dialog/ExpressWithdrawalDialog.vue";
import GeneralWithdrawalDialog from "@/views/withdrawal-view/components/general-withdrawal-dialog/GeneralWithdrawalDialog.vue";
import { ItemType } from "@/views/deposit-view/components/deposit-view-item/deposit-view-item-component";
import DepositViewItem from "@/views/deposit-view/components/deposit-view-item/DepositViewItem.vue";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { useWithdrawalViewCommon } from "@sportaq/vue/views/web/withdrawal/withdrawal-view-common";
import VerificationDialog from "@/views/profile-view/components/verification-dialog/VerificationDialog.vue";

export default {
    name: "WithdrawalView",
    components: {
        PartnerWithdrawalDialog,
        VisaWithdrawalDialog,
        ExpressWithdrawalDialog,
        EquadorWithdrawalDialog,
        GeneralWithdrawalDialog,
        DepositViewItem,
        VerificationDialog
    },
    setup () {
        const {
            currentDialog,
            minAmount,
            selectedPaymentSystem,
            countryId,
            isVerificationDialogVisible,
            pointList,
            brandName,
            onVerificationDialogClose,
            isPaymentSystemAvailable,
            onDialogClose,
            openWithdrawalDialog
        } = useWithdrawalViewCommon();
        return {
            currentDialog,
            ItemType,
            pointList,
            countryId,
            minAmount,
            selectedPaymentSystem,
            isVerificationDialogVisible,
            brandName,
            onVerificationDialogClose,
            isPaymentSystemAvailable,
            PaymentSystems,
            openWithdrawalDialog,
            onDialogClose
        };
    }
};
