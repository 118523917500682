/* eslint quote-props: ["error", "always"] */
/* eslint quote-props: ["error", "always"] */
export default {
    "brands": {
        "betgol": "BetGol",
        "certabet": "Certabet",
        "support": {
            "betgol": "info{'@'}tubetgol.com",
            "certabet": "info{'@'}certa.bet"
        },
        "site": {
            "betgol": "tubetgol.com",
            "certabet": "certa.bet"
        }
    },
    "sports": {
        "1": "Fútbol",
        "2": "Baloncesto",
        "3": "Hockey",
        "4": "Tenis",
        "5": "Fórmula 1",
        "6": "Voleibol",
        "7": "Béisbol",
        "8": "Rugby",
        "9": "Fútbol Americano",
        "10": "Boxeo",
        "13": "Skeleton",
        "19": "Biatlón",
        "20": "Bobsleigh",
        "21": "Esquí Alpino",
        "22": "Curling",
        "26": "SuperLotto",
        "27": "Waterpolo",
        "28": "Balonmano",
        "32": "Fútbol Sala",
        "36": "Juegos Olímpicos",
        "37": "Hockey sobre Hierba",
        "38": "Automovilismo",
        "39": "Motorsport",
        "40": "Billar",
        "41": "Atletismo",
        "43": "Bádminton",
        "44": "Tenis de Mesa",
        "45": "Fútbol Playa",
        "46": "Vóley Playa"
    },
    "routes": {
        "home": "INICIO",
        "sport": "DEPORTES",
        "live": "EN VIVO",
        "casino": "CASINO",
        "registration": "REGISTRO",
        "results": "Resultados",
        "virtual": "VIRTUALES",
        "horse": "HIPICAS",
        "games": "JUEGOS"
    },
    "appMenuBar": {
        "deposit": "Depósito",
        "withdrawal": "Retiro",
        "verifyBetSlip": "Verifica tu Apuesta"
    },
    "common": {
        "tempPassword": "Cambiar contraseña temporal",
        "notFound": {
            "networkError": {
                "title": "Network error",
                "description": "Verifica tu conexión a Internet y presiona el botón"
            },
            "responseError": {
                "title": "Response Error"
            },
            "code500": {
                "title": "Error interno del servidor",
                "description": "Lo siento, ha ocurrido un error en el servidor"
            },
            "code502": {
                "title": "Bad gateway",
                "description": "Lo siento, ha ocurrido un error en el servidor"
            },
            "code503": {
                "title": "Service temporarily unavailable",
                "description": "Lo siento, ha ocurrido un error en el servidor"
            },
            "code504": {
                "title": "Gateway Timeout",
                "description": "Lo siento, ha ocurrido un error en el servidor"
            },
            "code404": {
                "title": "Página no encontrada",
                "description": "Lo sentimos, no pudimos encontrar la página que está buscando."
            },
            "backButton": "Regresa",
            "homeButton": "Ir a la página de inicio",
            "refreshButton": "Recarga la página"
        },
        "language": {
            "language": "idioma",
            "languageName": "Español"
        },
        "pluralization": {
            "hour": "{hour} h|{hour} h|{hour} h",
            "match": "{count} evento|{count} evento|{count} eventos"
        },
        "days": {
            "tomorrow": "Mañana",
            "today": "Hoy dia"
        },
        "update": {
            "checkingForUpdates": "Buscando nuevas versiones ...",
            "updatesAvailable": "Descargando una nueva versión ...\nDespués de la descarga, la aplicación se reiniciará para instalar la nueva versión",
            "updateError": "Se produjo un error durante la actualización:\n{error}\nReinicie la aplicación y vuelva a intentarlo más tarde.\nSi el error persiste, comuníquese con el administrador"
        },
        "settings": {
            "tabs": {
                "generalTab": "General",
                "connectionTab": "Conexión"
            },
            "appCode": "Código de programa:",
            "partnerCode": "Código del socio:",
            "address": "Dirección:",
            "network": {
                "restServerAddress": "IP del servidor de procesamiento:",
                "streamServerAddress": "IP del servidor de datos:",
                "useSSL": "Utilice una conexión SSL segura"
            },
            "logo": "Logotipo (formato BASE64):"
        },
        "forms": {
            "ok": "Ок",
            "cancel": "Cancelar",
            "continue": "Continuar",
            "close": "Cerrar",
            "open": "Abrir",
            "yes": "Sí",
            "no": "No",
            "print": "Imprimir",
            "clear": "Borrar",
            "copy": "Copiar",
            "loadMore": "Más",
            "validation": {
                "required": "El campo debe ser llenado",
                "email": "El correo especificado no es correcto",
                "min": "La longitud mínima del campo es {param} de caracteres.",
                "confirmed": "Contraseña no coincide",
                "invalidFormat": "Formato inválido",
                "birthday": "Debe ser mayor de 18 años",
                "invalidCharacters": "Símbolos + - , ; & no son válidos"
            }
        },
        "games": {
            "tabs": {
                "all": "Todos",
                "popular": "Populares",
                "new": "Nuevos",
                "favourites": "Favoritos",
                "football": "Fútbol",
                "tennis": "Tenis ",
                "horse": "Caballos",
                "dog": "Galgos",
                "moto": "Motos",
                "fighting": "Combates",
                "providers": "Proveedores",
                "casual": "Casuales",
                "slots": "Slots",
                "card": "Juegos de mesa",
                "lottery": "Loteria",
                "roulette": "Ruleta",
                "poker": "Poker",
                "jackpot": "Jackpot",
                "live": "En vivo",
                "blackjack": "Blackjack",
                "craps": "Craps",
                "buyfeature": "Buy feature",
                "megaways": "Megaways",
                "crash": "Crash",
                "fishing": "Fishing",
                "scratch": "Scratch",
                "basketball": "Basketball",
                "keno": "Keno",
                "bingo": "Bingo",
                "hockey": "Hockey"
            },
            "buttons": {
                "play": "JUGAR",
                "demo": "DEMO"
            },
            "zeroBalance": "Fondos insuficientes en la cuenta del casino"
        }
    },
    "appTitle": {
        "brand": {
            "betgol": "Apuestas Deportivas y Casino Online | Betgol",
            "oncebet": "Apuestas Deportivas y Casino Online | Oncebet",
            "latino": "Apuestas Deportivas",
            "ligabet": "Apuestas Deportivas",
            "columbiaApuesta": "Apuestas",
            "certabet": "Apostas Esportivas e Cassino"
        },
        "cashier": "Cashier"
    },
    "homePage": {
        "widgets": {
            "coupon": {
                "coupons": "CUPONES",
                "today": "Hoy",
                "totalGoals": "Total de Goles",
                "bothToScore": "Ambos Anotarán",
                "weekend": "Fin de Semana",
                "mostPopular": "Populares"
            },
            "live": "EN VIVO",
            "starting-soon": "EN BREVE"
        }
    },
    "betting": {
        "depositAccount": {
            "success": "¡Recarga de saldo completada con éxito!"
        },
        "countryMenu": {
            "all": "Todos",
            "hot": "Destacados"
        },
        "codeInputForm": {
            "submit": "Verificar",
            "close": "Cerrar",
            "caption": "Verificar el código "
        },
        "withdrawalForm": {
            "caption": "Ingrese el monto del retiro",
            "cancel": "Cancelar",
            "ticket": "Retirar",
            "success": {
                "text": "El retiro por por el monto de <b>{amount} {currency}</b> se realizó correctamente. <br> N código <b>{code}</b>",
                "caption": "Retiro"
            }
        },
        "horse": {
            "ticket": {
                "serial": "Serial",
                "date": "Fecha",
                "horse": "Caballo",
                "game": "Jugada",
                "amount": "Monto",
                "status": "Estatus",
                "total": "Total:",
                "outcomes": {
                    "w": "WIN",
                    "p": "PLACE",
                    "s": "SHOW",
                    "f": "CUOTA FIJA",
                    "fp": "CUOTA FIJA PLACE",
                    "ex": "EXACTA",
                    "tr": "TRIFECTA",
                    "su": "SUPERFECTA",
                    "Pk2": "PICK 2",
                    "Pk3": "PICK 3"
                }
            }
        },
        "couponVerification": {
            "caption": "Verifica tu Apuesta",
            "coupon": "Cupón de Apuesta",
            "bet": "Apuesta",
            "date": "Fecha",
            "event": "Evento",
            "coef": "Cuota",
            "amount": "Apuesta",
            "payment": "Ganancia",
            "info": "Info",
            "cardNotFound": "Cupón no fue encontrado",
            "paymentTotal": "El Premio es {total} {curr}",
            "transferButton": "Transferir al saldo",
            "transfer": "¿Transferir al saldo?",
            "success": "La transferencia se realizó correctamente"
        },
        "navigationSideBar": {
            "league": "Ligas",
            "countries": "A-Z",
            "search": "Búsqueda",
            "rules": "Reglas",
            "faq": "Preguntas frecuentes",
            "reload": "Actualizar",
            "time": "Tiempo"
        },
        "searchDialog": {
            "placeholder": "Búsqueda",
            "emptyResults": "No se encontraron eventos relevantes"
        },
        "mobile": {
            "favourites": "Favoritos",
            "popular": "Populares"
        },
        "event": {
            "scoreboardTitle": {
                "events": "Eventos",
                "menu": {
                    "item1x2": "Resultado del Partido",
                    "item12": "Ganador del partido",
                    "item1x12x2": "Doble oportunidad",
                    "itemHandicap": "Ganar con hándicap",
                    "itemTotalGoals": "Total de goles",
                    "itemLocalTotalGoals": "Equipo local - Total de goles",
                    "itemVisitTotalGoals": "Equipo visitante - Total de goles",
                    "itemBothWillScore": "Ambos equipos marcaran",
                    "itemTotalPoints": "Total de puntos",
                    "itemLocalTotalPoints": "Equipo local - Total de puntos",
                    "itemVisitTotalPoints": "Equipo visitante - Total de puntos",
                    "itemTotalGames": "Total de juegos",
                    "itemLocalTotalGames": "Equipo local - Total de juegos",
                    "itemVisitTotalGames": "Equipo visitante - Total de juegos",
                    "itemTotalRuns": "Total de carreras",
                    "itemTotal": "Total",
                    "itemMatchTotal": "Total",
                    "item1x2xFavourites": "Resultado del Partido",
                    "itemTotalFavourites": "Total",
                    "itemHandicapFavourites": "Ganar con hándicap"
                }
            },
            "scoreboardLine": {
                "startDate": "Hoy dia"
            },
            "periods": {
                "sport1": {
                    "period1": "1er tiempo",
                    "period2": "2do tiempo"
                },
                "sport2": {
                    "period1": "1er Cuarto",
                    "period2": "2do Cuarto",
                    "period3": "3er Cuarto",
                    "period4": "4to Cuarto",
                    "period100": "1ra mitad",
                    "period101": "2da mitad"
                },
                "sport3": {
                    "period1": "1er periodo",
                    "period2": "2do periodo",
                    "period3": "3er periodo"
                },
                "sport4": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport6": {
                    "period1": "1 set",
                    "period2": "2 set",
                    "period3": "3 set",
                    "period4": "4 set",
                    "period5": "5 set"
                },
                "sport7": {
                    "period1": "1ra entrada",
                    "period2": "2da entrada",
                    "period3": "3ra entrada",
                    "period4": "4ta entrada",
                    "period5": "5ta entrada",
                    "period6": "6ta entrada",
                    "period7": "7ª entrada",
                    "period8": "8ª entrada",
                    "period9": "9ª entrada",
                    "period10": "10ª entrada",
                    "period11": "11ª entrada",
                    "period12": "12ª entrada",
                    "period13": "13ª entrada",
                    "period100": "Primeras 5 entradas"
                },
                "sport9": {
                    "period1": "1er cuarto",
                    "period2": "2do cuarto",
                    "period3": "3er cuarto",
                    "period4": "4to cuarto",
                    "period100": "1ra mitad"
                },
                "sport28": {
                    "period1": "1er periodo",
                    "period2": "2do periodo"
                },
                "sport44": {
                    "period5": "Set 5",
                    "period4": "Set 4",
                    "period3": "Set 3",
                    "period2": "Set 2",
                    "period1": "Set 1"
                }
            },
            "groupEvents": {
                "participant": "Equipo",
                "leaveTheGroup": "Clasificará",
                "stayTheGroup": "No clasificará",
                "winner": "Ganador",
                "placeInGroup": "{p1} Puesto en el grupo",
                "finishTheRaceYes": "Termina la carrera - Si",
                "finishTheRaceNo": "Termina la carrera - No",
                "fastestLap": "Vuelta más rápida",
                "firstPitstop": "1-er Pitstop",
                "lastPlace": "Ultimo Puesto",
                "willQualifyToFinal": "Se clasifica a Final",
                "willQualifyToFinal1_2": "Se clasifica a 1/2 de la Final",
                "willQualifyToFinal1_4": "Se clasifica a 1/4 de la Final"
            }
        },
        "betSlip": {
            "title": "Cupón de apuesta",
            "shortTitle": "Cupón",
            "acceptWithAllQuotations": "Aceptar cambio de cuotas",
            "acceptOnlyWithQuotationsAreRaised": "Aceptar cuando aumentan las cuotas",
            "oneClickBet": "Apuesta en un clic",
            "simple": "Simples",
            "combined": "Combinada",
            "maxWin": "Pago potencial",
            "ordinar": "Simples",
            "express": "Múltiple",
            "expressWithCoef": "Múltiple ({coef})",
            "system": "Sistema ({base} / {limit})",
            "variants": "Apuestas:",
            "columns": {
                "event": "Evento",
                "sum": "Monto"
            },
            "buttons": {
                "clear": "Eliminar",
                "continue": "Apostar",
                "accept": "Aceptar"
            },
            "confirmCoefDiffs": "Las probabilidades o la disponibilidad de las selecciones en su Cupón de apuestas han cambiado.",
            "acceptButton": "Aceptar",
            "common": {
                "total": "Monto de apuesta:",
                "maxWin": "Premio potencial"
            },
            "print": {
                "card": "Cupón",
                "code": "Código",
                "time": "Tiempo:",
                "endTime": "Válido hasta:",
                "confirm": "Al realizar una apuesta, usted confirma que está familiarizado con las reglas."
            },
            "dialogBetAccepted": {
                "caption": "Apuesta aceptada",
                "code": "Código",
                "columns": {
                    "date": "Fecha, Hora",
                    "event": "Evento",
                    "variants": "{variants} apuestas",
                    "count": "Cantidad",
                    "coef": "Cuota",
                    "amount": "Monto",
                    "maxWin": "Premio potencial"
                },
                "timer": "Tiene {timer} segundos para anular apuesta.",
                "copy": "Copiar",
                "cancel": "Cancelar"
            },
            "dialogBetVerification": {
                "caption": "Verifique los datos de su apuesta"
            },
            "numberInputDialog": {
                "caption": "Ingrese el monto de su apuesta",
                "confirmButton": "OK"
            }
        },
        "registration": {
            "title": "Registrarse",
            "tabs": {
                "logoTab": "Logotipo"
            },
            "fields": {
                "pointName": "Nombre de la sala:",
                "city": "Ciudad:",
                "countryLabel": "Pais:",
                "phone": "Número de teléfono:"
            }
        }
    },
    "cashier": {
        "loginForm": {
            "caption": "Iniciar sesión",
            "user": "Usuario",
            "password": "Contraseña",
            "parameters": "Opciones",
            "settings": "Ajustes",
            "registration": "Registrarse",
            "settingsNotFilled": "Configuración de la aplicación no completada",
            "serverAddressNotFilled": "Servidor de la aplicación no completado",
            "noAccount": "La moneda de la cuenta no existe"
        },
        "registrationForm": {
            "caption": "Registro",
            "number": "#",
            "nameGroup": "Nombre de usuario",
            "lastName": "Apellido:*",
            "firstName": "Nombre:*",
            "login": "Usuario: *",
            "requiredFields": "* - Campos requeridos",
            "contactsGroup": "Contactos",
            "address": "Dirección:",
            "phone": "Número de teléfono:*",
            "email": "E-mail:*",
            "passwordGroup": "Contraseña",
            "password": "Contraseña:*",
            "confirmPassword": "Confirmación de contraseña:*"
        },
        "settingsForm": {
            "caption": "Opciones",
            "locationTab": "Localización",
            "optionsTab": "Opciones",
            "requisites": "Requisitos",
            "remoteAccess": "Configuración de acceso remoto",
            "partnerCode": "Código de socio:",
            "cashboxName": "Nombre de cashier:",
            "finance": "Finanzas",
            "currency": "Divisa:",
            "minStakeOrdinar": "Monto mínimo de la apuesta (Simple):",
            "minStakeExpress": "Monto mínimo de la apuesta (Múltiple):",
            "minStakeSystem": "Monto mínimo de la apuesta (Sistema):",
            "proxy": "Proxy server:",
            "useProxy": "Utilice un servidor proxy",
            "proxyAddress": "Dirección IP:",
            "proxyPort": "Puerto:",
            "captionImage": "Archivo de imagen para encabezados",
            "multiscreen": "Multiscreen Live",
            "multiscreenFile": "Archivo exe:",
            "hotkey": "Ctrl+ tecla de acceso rápido",
            "directOutput": "Impresión directa",
            "cashbox": "Cashier",
            "voucher": "Ticket",
            "express": "Cuentas express",
            "terminal": "Cuenta de Terminal",
            "checkFormat": "Formato de impresión:",
            "leftPad": "Márgen izquierda:",
            "barcodeScanner": "Escáner de código de barras",
            "barcodeScannerPort": "Puerto:",
            "depositMenu": "Elemento del menú de depósito"
        },
        "finDayForm": {
            "caption": "Día financiero - conciliación de efectivo"
        },
        "dailyReportForm": {
            "caption": "Reporte diario",
            "totalGenerationVoucher": "Tickets (Venta)",
            "totalCashCheque": "Tickets (Compra)",
            "totalBalanceNulling": "Retiros (BP)",
            "totalBalanceDeposit": "Recargas (WEB)",
            "totalBalanceWithdowal": "Retiros (WEB)",
            "totalStake": "Apuestas Deportes",
            "totalPaid": "Premios Deportes",
            "totalStakeRace": "Apuestas Hipicas",
            "totalPayRace": "Premios Hipicas",
            "totalCancelStakeBP": "Anulado (BP)",
            "totalPaidTerminal": "Premios (Terminal)",
            "total": "Total",
            "closeDay": "Cerrar el día financiero",
            "success": "El día financiero se cerró con éxito",
            "confirm": "¿Cerrar el día financiero?",
            "totalBalanceDepositBetPlace": "Recargas (BP)",
            "refresh": "Recargar"
        },
        "userConfirmationForm": {
            "caption": "Búsqueda de usuario",
            "accountId": "Número de cuenta:",
            "document": "Documento:",
            "findButton": "Búsqueda de usuario",
            "personalData": "Datos personales",
            "personalCode": "Identificador privado:",
            "name": "Nombre:",
            "birthdate": "Fecha de nacimiento:",
            "lastName": "Apellido:",
            "docs": "Documentos de usuario:",
            "verify": "Verificación de usuario:",
            "confirmButton": "Confirmar datos",
            "verifiedYes": "Si",
            "verifiedNo": "No",
            "success": "Completado con éxito"
        },
        "refillAccountForm": {
            "caption": "Recarga directa de la cuenta",
            "user": "Usuario:",
            "findUser": "Búsqueda de usuario",
            "accountNumber": "Número de cuenta o login:",
            "phone": "Número de teléfono: +",
            "document": "Documento:",
            "checkButton": "Verificar",
            "accountRefill": "Recargar",
            "amount": "Monto:",
            "getBonus": "Obtener bono",
            "refillButton": "Recargar",
            "insufficientFunds": "Fondos insuficientes",
            "empty": "El campo debe rellenarse",
            "table": {
                "time": "Fecha",
                "num": "Número",
                "name": "Nombre",
                "amount": "Monto"
            },
            "print": {
                "accountNumber": "Número de cuenta",
                "amount": "Saldo"
            }
        },
        "winPaymentForm": {
            "caption": "Pago de premios",
            "cardCode": "Código de apuesta:",
            "find": "Buscar",
            "doPayment": "Pagar",
            "success": "El premio se ha pagado correctamente",
            "notfound": "No se encuentra el cupón",
            "expired": "El período de pago ya ha pasado.",
            "alreadyPaid": "Cupón ya pagado"
        },
        "cashWithdrawalForm": {
            "status0": "Pendiente",
            "status1": "Aprobado",
            "status2": "Pagado",
            "status3": "Rechazado",
            "status4": "Cancelado por el usuario",
            "status5": "Desconocido",
            "caption": "Retiro",
            "payCode": "Código de pago:",
            "checkWithdrawalButton": "Verifique el código de pago",
            "withdrawalCaption": "Detalles de pedido",
            "withdrawalNum": "Número de pedido:",
            "amount": "Monto",
            "crdate": "Creado:",
            "expdate": "Válido hasta:",
            "cashbox": "Cashier:",
            "status": "Estado de pedido:",
            "document": "Documento presentado",
            "documentType": "Tipo de Documento",
            "documentTypes": {
                "idcard": "ID / DNI",
                "passport": "Pasaporte",
                "driver": "Licencia de conducir"
            },
            "docNumber": "Número del Documento:",
            "makeWithdrawalButton": "Verificar retiro",
            "personalData": "Datos personales",
            "userNumber": "Código personal:",
            "firstName": "Nombre:",
            "lastName": "Apellido:",
            "birthday": "Fecha de nacimiento:",
            "userDocuments": "Documentos de usuario:",
            "verify": "Verificación de usuario:",
            "confirmUserButton": "Verificar datos",
            "print": {
                "caption": "Retiro de la cuenta",
                "sum": "Monto:",
                "account": "Número de cuenta"
            },
            "notFound": "Pedido no encontrado"
        },
        "saleVoucherForm": {
            "caption": "Ticket - venta",
            "amount": "Monto:",
            "saleButton": "Pagar",
            "table": {
                "time": "Fecha",
                "num": "Número",
                "amount": "Monto"
            },
            "print": {
                "time": "Tiempo de operacion:",
                "voucher": "Ticket №:",
                "amount": "Cantidad a pagar:",
                "until": "Válido hasta:"
            }
        },
        "buyVoucherForm": {
            "caption": "Ticket - Compra",
            "voucher": "Ticket №:",
            "buyButton": "Compra",
            "success": "La operación se realizó correctamente, \n el monto es {amount}",
            "notfound": "Ticket no encontrado"
        },
        "menu": {
            "monitoring": "Monitoreo",
            "payments": "Pagos",
            "vouchers": "Ticket",
            "account": "Cuenta de juego",
            "finance": "Finanzas",
            "service": "Servicio",
            "sport": "DEPORTE",
            "live": "EN VIVO",
            "state": "No pagados",
            "payment": "Pago de premios",
            "withdrawal": "Retiros",
            "refill": "Recargas",
            "userFind": "Buscar usuario",
            "profile": "Parámetros de registro",
            "settings": "Opciones",
            "salevoucher": "Venta",
            "buyvoucher": "Compra",
            "dailyreport": "Reporte diario",
            "finday": "Día financiero",
            "betplaces": "Puntos",
            "express": "Cuenta Express"
        },
        "monitoring": {
            "copyError": "Error de copia",
            "table": {
                "id": "Id",
                "time": "Tiempo",
                "position": "Objeto / Posición",
                "quotation": "Fuente / cuota",
                "coef": "Cuota",
                "stake": "Cantidad",
                "status": "Estado",
                "pay": "Pagos",
                "income": "Ingreso",
                "print": "Impresión",
                "number": "#",
                "iscancel": "Anulada",
                "isaccepted": "Aceptada",
                "iscalculated": "Calculada",
                "ispaid": "Pagado",
                "object": "N.° int. {innerCardId} Código de pago: {payCode}",
                "containerType1": "Simple",
                "containerType3": "Sistema ({express} / {itemCount})",
                "containerType2": "Múltiple",
                "itemStatus0": "Pendiente",
                "itemStatus1": "Premio",
                "itemStatus2": "Sin premio",
                "itemStatus3": "Reembolso",
                "itemStatus4": "Desconocido",
                "itemStatus5": "Anulada",
                "horse": {
                    "statusG": "Premio",
                    "statusJ": "Pendiente",
                    "statusP": "Sin premio",
                    "statusD": "Reembolso",
                    "statusA": "Void"
                }
            },
            "filter": {
                "cashier": "Cashier",
                "allbp": "Todos bp"
            },
            "search": {
                "caption": "Buscar",
                "inputLabel": "Ingrese el numero"
            },
            "operations": {
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_CASH": "Recarga de la cuenta",
                "PAY_OPERATION_WITHDRAWAL_CASH": "Retiro de la cuenta",
                "PAY_OPERATION_VOUCHER_GENERATION_CASH": "Venta del ticket",
                "PAY_OPERATION_VOUCHER_REDEMPTION_CASH": "Compra del ticket",
                "PAY_OPERATION_VOUCHER_GENERATION_BP": "Venta del ticket de Betplace",
                "PAY_OPERATION_INCREASE_BALANCE_BP": "Recarga del balance de Betplace",
                "PAY_OPERATION_WINPAID": "Pago de ganancias",
                "PAY_OPERATION_FOR_RACE_WINPAID_BP": "Pago de premio Hípicas BP",
                "PAY_OPERATION_FOR_RACE_WINPAID_CASH": "Pago de premio Hípicas",
                "PAY_OPERATION_NULLIFICATION_POINT_BALANCE": "Puesta a cero  balance de Betplace",
                "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT": "Recuperación de la cuenta del afiliado",
                "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWAL": "Retirar de la cuenta del afiliado ",
                "PAY_OPERATION_INCREASE_ACCOUNT": "Recarga de la cuenta ",
                "PAY_OPERATION_WITHDRAWAL": "Retirar de la cuenta ",
                "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT": "Deposito directo",
                "PAY_OPERATION_DEPOSIT_BONUS_REFILL": "Bono",
                "PAY_OPERATION_INCREASE_BONUS_ACCOUNT": "Depósito a la cuenta de bono (Deportes)",
                "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT": "Depósito a la cuenta de bono (Casino)",
                "PAY_OPERATION_CANCEL_BONUS_ACCOUNT": "Cancelación del bono (Deportes)",
                "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT": "Cancelación del bono (Casino)",
                "PAY_OPERATION_CANCEL_STAKE_BP": "Cancelación de la apuesta BP",
                "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT": "Depósito desde la cuenta de bono (Deportes)",
                "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT": "Depósito desde la cuenta de bono (Casino)",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL": "Transferencia a la cuenta del juegos",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT": "Transferencia de la cuenta del juegos",
                "PAY_OPERATION_CASINO_ACCOUNT_WITHDRAWAL_BP": "Transferencia de la cuenta de juegos",
                "PAY_OPERATION_CASINO_ACCOUNT_DEPOSIT_BP": "Transferencia a la cuenta de juegos",
                "PAY_OPERATION_FOR_RACE_WINPAID": "Premio Hipicas",
                "PAY_OPERATION_FOR_RACE_CARD": "Apuesta Hipicas"
            },
            "deleteSuccess": "Apuesta eliminada correctamente",
            "deleteComfirm": "¿Eliminar apuesta?"
        },
        "cardsState": {
            "innerCardId": "Número",
            "cardFilter": "Apuesta:",
            "payCode": "Código de pago",
            "sumPay": "Monto",
            "status": "Estado",
            "caption": "No pagados",
            "unpaidSum": "Monto para pagar: {sumPay}",
            "unpaidRadio": "Premios impagos",
            "paidRadio": "Premios pagados",
            "loseRadio": "Apuestas perdidas",
            "allRadio": "Apuestas calculadas",
            "footer": "Apuesta:",
            "unpaid": "no pagado",
            "empty": "NO HAY CUPONES PARA PAGAR"
        },
        "updateProfile": {
            "success": "La edición del perfil se realizó correctamente"
        },
        "activeBetplaces": {
            "deposit": "Recargar",
            "depositDialog": {
                "caption": "Recargar la cuenta"
            },
            "depositsuccess": "Cuenta recargada con éxito",
            "depositsuccesscaption": "Recargar la cuenta",
            "nullify": "Restablecer saldo",
            "nullifyConfirm": "¿Realmente desea restablecer el saldo en {betplace}?",
            "nullifysuccess": "El saldo es cero",
            "nullifysuccesscaption": "Restablecer saldo",
            "sum": "Saldo"
        },
        "expressAccount": {
            "caption": "Creación de cuenta express",
            "firstName": "Nombre: *",
            "lastName": "Apellido: *",
            "orderSum": "Monto: *",
            "document": "Documento: *",
            "print": {
                "number": "Número de cuenta express:",
                "balance": "Saldo de la cuenta:",
                "siteCaption": "Datos de inicio de sesión:",
                "login": "Usuario:",
                "password": "Contraseña:",
                "warning": "¡Atención! ¡Debe cambiar su contraseña inmediatamente después de iniciar sesión!"
            }
        },
        "forms": {
            "print": "Imprimir"
        }

    },
    "errors": {
        "mtl": {
            "serverError": "Error inesperado del servidor",
            "status": {
                "code201": "Se envió una solicitud incorrecta al servidor",
                "code202": "Error de definición de solicitud del servidor",
                "code203": "Error de definición de solicitud del servidor: la solicitud no coincide con el protocolo",
                "code101": "La solicitud no se puede procesar en este momento. Inténtalo un poco más tarde",
                "code102": "Error al procesar la solicitud en el servidor",
                "code401": "Código de punto no especificado en la solicitud",
                "code402": "Punto no activado",
                "code403": "El punto fue bloqueado"
            },
            "aUs11": {
                "code1103": "Número mínimo de caracteres de la contraseña es 8",
                "code1104": "Número mínimo de caracteres de la login es 8",
                "code1106": "El usuario con este correo electrónico ya existe",
                "code1107": "Ya existe un usuario con este LOGIN",
                "code1113": "El DNI especificado ya se ha utilizado",
                "code1114": "El código de referencia no es correcto",
                "code1115": "El usuario con este teléfono ya existe",
                "code1118": "Número de teléfono incorrecto"
            },
            "aUs16": {
                "code1172": "La contraseña actual es incorrecta",
                "code1173": "Longitud mínima de la contraseña 8 caracteres"
            },
            "aUs17": {
                "code1181": "El usuario actual no está configurado en la sesión.",
                "code1182": "Dirección de correo electrónico no válida",
                "code1183": "La dirección de correo electrónico no existe",
                "code1184": "El número de teléfono es incorrecto"
            },
            "aUs111": {
                "code1191": "Usuario de la sesión no encontrada"
            },
            "aUs12": {
                "code1121": "¡Número de cuenta no definido!",
                "code1122": "Número de usuario y contraseña incorrectos",
                "code1123": "Usuario está bloqueado",
                "code1124": "El usuario no puede iniciar sesión desde la estación especificada",
                "code1125": "La moneda de la cuenta no existe",
                "code1161": "Correo o número de teléfono incorrecto",
                "code1126": "El usuario tiene varias cuentas",
                "code1162": "El usuario no está activado"
            },
            "aUs22": {
                "code1703": "Teléfono ya registrado",
                "code1106": "El usuario con este correo electrónico ya existe",
                "code1107": "Ya existe un usuario con este LOGIN",
                "code1113": "El código de usuario personal especificado ya se ha utilizado",
                "code1114": "El código de referencia no es correcto",
                "code1115": "El usuario con este teléfono ya existe"
            },
            "aPi55": {
                "code2601": "Los saldos no coinciden",
                "code2604": "Desajuste de moneda"
            },
            "aUs25": {
                "code1121": "El usuario actual no tiene derechos para editar información."
            },
            "aPi54": {
                "code1001": "El usuario no ha iniciado sesión"
            },
            "qSt100": {
                "code1311": "El objeto especificado no existe",
                "code1312": "No hay apuestas que coincidan con el parámetro especificado",
                "code1313": "El usuario no está configurado en la sesión",
                "code1314": "El usuario no tiene permiso para solicitar una lista de apuestas"
            },
            "aSt13": {
                "code1221": "La apuesta no se encuentra en la base de datos",
                "code1222": "Se ha superado el tiempo estimado para anular la apuesta."
            },
            "aSt22": {
                "code1281": "Apuesta no fue encontrada",
                "code1282": "La apuesta ya ha sido pagada",
                "code1283": "El cupón de apuestas está pendiente",
                "code1284": "Error de pago"
            },
            "qSt106": {
                "code1411": "Cupón no fue encontrado"
            },
            "qSt107": {
                "code1421": "Cupón no fue encontrado"
            },
            "aSt25": {
                "code1381": "Apuesta no fue encontrada",
                "code1382": "La apuesta ya ha sido pagada",
                "code1383": "La apuesta ya ha sido pagada",
                "code1384": "El cupón de apuestas está pendiente"
            },
            "qUs22": {
                "code1191": "No está autorizado a solicitar esta información.",
                "code1192": "La solicitud del servidor no arrojó resultados"
            },
            "qRs12": {
                "code1411": "Cupón no fue encontrado"
            },
            "aPo51": {
                "code5108": "Error de deposito. Introduce importe válido",
                "code5109": "El metodo de pago no está disponible. Para recargar su cuenta, elija otro método o contáctenos."
            },
            "aPo510": {
                "code4301": "Error de depósito general",
                "code4303": "La aplicación no está autorizada para realizar la operación.",
                "code4304": "El usuario no tiene permiso para realizar la operación",
                "code4305": "El usuario con los datos especificados no existe",
                "code4306": "El usuario no tiene una cuenta con la moneda especificada.",
                "code4307": "Usuario está bloqueado",
                "code4308": "Operación no autorizada, póngase en contacto con el soporte",
                "code4309": "El bono no está disponible para este usuario",
                "code4310": "Los socios no coinciden",
                "code4314": "El teléfono del usario ya existe",
                "code4404": "Desajuste de moneda"
            },
            "aPo513": {
                "code4403": "Saldo insuficiente para realizar la operación",
                "code4404": "Desajuste de moneda"
            },
            "aPo68": {
                "code4451": "La aplicación no tiene permiso para realizar la operación",
                "code4452": "Derechos insuficientes para realizar la operación",
                "code4453": "No se abrió ninguna cuenta con la moneda especificada",
                "code4454": "La cantidad solicitada es superior a la disponible",
                "code4455": "Ningún contacto configurado para enviar confirmación",
                "code4456": "Sin derechos para usar el método de confirmación de operación especificado"
            },
            "aVc21": {
                "code5103": "Cantidad incorrecta",
                "code2302": "Ticket fue usado",
                "code4316": "Moneda incorrecta"
            },
            "aPo617": {
                "code4443": "El RETIRO NO ESTÁ AUTORIZADO PARA EL USUARIO",
                "code4445": "La cantidad solicitada es superior a la disponible"
            },
            "aUs14": {
                "code1151": "No se pudo cambiar los datos de usuario",
                "code1152": "Usuario no encontrado",
                "code1153": "Nombre de usuario debe tener 5 caracteres o más",
                "code1154": "Ya existe un usuario con este nombre",
                "code1155": "Apellido no especificado",
                "code1156": "Nombre no especificado",
                "code1157": "La fecha de nacimiento es incorrecta",
                "code1158": "ID / DNI de usuario especificado ya existe"
            },
            "aVc14": {
                "code4811": "Sin derecho de acceso",
                "code2302": "TICKET UTILIZADO",
                "code2303": "Desajuste de socios",
                "code2305": "Desajuste de socios",
                "code2308": "Desajuste de moneda"
            },
            "aVc11": {
                "code1": "Sesión no autorizada",
                "code4801": "Sin derecho de acceso",
                "code2202": "La cantidad es incorrecta",
                "code2201": "El monto de la transacción excede el saldo",
                "code2203": "Moneda incorrecta"
            },
            "aPo67": {
                "code4441": "Sin derecho de acceso",
                "code4442": "Sin derecho de acceso",
                "code4443": "No se encontró solicitud especificada",
                "code4444": "El estado de la solicitud no permite la operación.",
                "code4445": "El punto declarado no coincide",
                "code4446": "Error de retiro general",
                "code4447": "Superó el número de retiros en la caja por día",
                "code4463": "No se encontró solicitud especificada",
                "code4464": "Retiro pagado",
                "code4466": "OPERACIÓN DE RETIRO NO AUTORIZADA"
            },
            "aGe21": {
                "code1010": "El registro fue exitoso, el programa se cerrará. Inicie el programa después de la activación",
                "code1011": "Código de punto no encontrado",
                "code1012": "Nombre del punto es incorrecto",
                "code1013": "E-mail es incorrecto",
                "code1014": "País no especificado",
                "code1015": "Sin dirección especificada",
                "code1016": "Socio no definido",
                "code1017": "El código del punto ya está definido"
            },
            "aGe22": {
                "code1021": "El punto está bloqueado",
                "code1131": "Error de registro de sesión"
            },
            "aVc18": {
                "code1001": "El usuario no ha iniciado sesión",
                "code2301": "Ticket no encontrado",
                "code2302": "Ticket ya esta utilizado",
                "code2305": "Sin derechos de operación",
                "code2307": "Desajuste de socios",
                "code4316": "Las monedas no coinciden",
                "code4301": "Error de depósito general",
                "code4307": "Usuario está bloqueado",
                "code4310": "Los socios no coinciden"
            },
            "aVc22": {
                "code1211": "No hay suficiente dinero en el saldo",
                "code1214": "OPERACIÓN NO PERMITIDA PARA ESTE TIPO DE PUNTO"
            },
            "aPo512": {
                "code4301": "La aplicación no está autorizada para realizar la operación.",
                "code4302": "El usuario no tiene permiso para realizar la operación.",
                "code4303": "El código de identificación ya está en uso.",
                "code4304": "El número de teléfono ya está registrado en el sistema.",
                "code4305": "El monto de depósito no válido",
                "code4313": "Número de documento ya en uso",
                "code4306": "Código de moneda no válido",
                "code4311": "Operación no autorizada, póngase en contacto con el soporte",
                "code4314": "El usuario con este teléfono ya existe",
                "code4315": "Operación no autorizada, póngase en contacto con el soporte"
            },
            "aCs11": {
                "code8101": "Sesión no auth",
                "code8132": "El juego no está disponible",
                "code8136": "Recarga el saldo para activar la aplicación"

            },
            "aCs12": {
                "code8101": "Sesión no auth",
                "code8121": "Sesión no auth"
            },
            "aCs27": {
                "code8125": "No hay suficientes fondos en la cuenta principal para realizar la transferencia!"
            },
            "aCs28": {
                "code8135": "No hay suficientes fondos en la cuenta del casino para realizar la transferencia!"
            },
            "aCn11": {
                "code1051": "Confirmación no encontrada",
                "code1052": "¡Se utiliza la confirmación!",
                "code1053": "Tiempo posible para confirmar que la operación ha expirado"
            },
            "aBn11": {
                "code4231": "Operación no permitida por sesión no activa",
                "code4232": "Operación no permitida para usuarios express",
                "code4233": "Bonificación no permitida sin depósito",
                "code4236": "El bono no sale por depósito",
                "code4234": "El monto del depósito no cumple con las Reglas  de Bono.",
                "code4240": "Bonificación no permitida después de la apuesta",
                "code4238": "El bono de hola debe ser lo primero",
                "code4235": "Bono ya iniciado",
                "code4237": "Sólo puede haber un bono activo a la vez",
                "code4241": "El monto del depósito no cumple con las Reglas  de Bono."
            }
        },
        "betSlip": {
            "duplicateEventWarning": "No se puede combinar múltiples jugadas de un mismo evento seleccionado",
            "exceedsMaxStakeAmount": "El monto de la apuesta es mayor que el máximo permitido",
            "lessMinStakeAmount": "El monto de la apuesta es menor que el mínimo permitido",
            "coefWasChanged": "Las cuotas han cambiado",
            "quotationBlocked": "La aceptación de apuestas está suspendida",
            "positionBlocked": "La aceptación de apuestas está temporalmente suspendida",
            "eventFullTime": "Las apuestas en este evento están cerradas - el partido ha terminado",
            "eventStarted": "Las apuestas en este evento están cerradas",
            "betSlipEmpty": "Cupón está vacío",
            "betSlipInvalidItems": "Cupón contiene errores",
            "response": {
                "registrationSystemError": "Error de registro del sistema",
                "incorrectNumberOfPositions": "Error del sistema al registrar una apuesta combinada",
                "notEnoughMoneyOnCenterBalance": "Fondos insuficientes en la cuenta",
                "notEnoughMoneyOnUserBalance": "Fondos insuficientes en la cuenta",
                "userDontHaveOpenAccount": "Para realizar apuestas, primero debe iniciar sesión o registrarse",
                "positionLiveServiceNotAvailable": "Se ha suspendido la aceptación de apuestas en Vivo en este evento.",
                "coefIsNotAllowedForBonus": "La cuota de apuesta es inferior a la permitida"
            }
        },
        "games": {
            "errorStartingGame": "El juego no está disponible"
        }
    },
    "webdesktop": {
        "pageTitle": {
            "default": {
                "title": "{brand}: ¡Tu Destino Definitivo para Casino en Línea, Apuestas Deportivas y Juegos en Vivo!",
                "description": "¡Bienvenido a {brand}, el sitio web oficial de tu principal centro de apuestas! ☝Explora nuestros servicios de primera categoría como casa de apuestas ☝☝Experimenta la emoción de los juegos de casino en línea ☝ y aprovecha generosos bonos de hasta S/ 1000 "
            },
            "sport": {
                "title": "{sport} - {brand} Casa de Apuestas: ¡Apuesta en {sport} con Confianza!",
                "description": "¡Apuesta en {sport} con confianza en {brand} Casa de Apuestas! ☝Registro rápido y acceso sencillo ☝☝Elige entre una amplia variedad de apuestas deportivas ☝ Obtén apuestas gratuitas, códigos promocionales y bonos exclusivos ☝"
            },
            "live": {
                "title": "{sport} at {brand} Bookmaker: Bet Live on {sport} and Win Big!",
                "description": "Ready for live betting on {sport}? {brand} Bookmaker has you covered! ☝Quick sign-up and easy access ☝ Claim free bets, promo codes, and special bonuses ☝ We offer diverse sports options "
            },
            "casino": {
                "title": "Casino en Línea {brand}: ¡Juega y Gana Dinero Real!",
                "description": "¡Sumérgete en la diversión del casino en línea en {brand}! ☝Únete ahora para un registro sencillo y acceso a una amplia variedad de tragamonedas ☝ Obtén emocionantes bonos y giros gratis de hasta S/ 1000 "
            },
            "virtual": {
                "title": "Casino en Línea {brand}: ¡Juega y Gana Dinero Real!",
                "description": "¡Sumérgete en la diversión del casino en línea en {brand}! ☝Únete ahora para un registro sencillo y acceso a una amplia variedad de tragamonedas ☝ Obtén emocionantes bonos y giros gratis de hasta S/ 1000 "
            },
            "registration": {
                "title": "{brand} Regístrate y Elige tu Bono de Bienvenida!",
                "description": "¡Bienvenido a {brand}, el sitio web oficial de tu principal centro de apuestas! ☝Explora nuestros servicios de primera categoría como casa de apuestas ☝☝Experimenta la emoción de los juegos de casino en línea ☝ y aprovecha generosos bonos de hasta S/ 1000 "
            }
        },
        "race": {
            "bonusActive": "Las apuestas hipicas no están disponibles  desde una cuenta de bono"
        },
        "idle": {
            "message": "La sesión ha terminado por  inactividad."
        },
        "verification": {
            "emailCaption": "Verificación del correo",
            "phoneCaption": "Verificación del número de teléfono",
            "caption": "Verificación de seguridad",
            "text": "Para asegurar su cuenta, por favor complete la siguiente verificación",
            "phone": "Código de verificación del número de teléfono",
            "email": "Código de verificación de correo electrónico",
            "placeholder": "Introduzca el código de 8 digitos ",
            "phoneVerified": "Su número de teléfono se ha verificado correctamente",
            "emailVerified": "Su correo electrónico se ha verificado correctamente",
            "getCode": "Recibir el código",
            "sent": "El código de verificación será enviado a "
        },
        "accountSwitch": {
            "sport": {
                "primary": "Has sido transferido a la cuenta principal",
                "bonus": "Ha sido transferido a la cuenta Bono del Deportes"
            },
            "casino": {
                "primary": "Ha sido transferido a la cuenta del Casino",
                "bonus": "Ha sido transferido a la cuenta del Bono del Casino"
            }
        },
        "casino": {
            "balance": "Cuenta De Casino",
            "transferFromCasino": "Transfeir de la cuenta del casino:",
            "transferToCasino": "Transfeir a la cuenta del casino:",
            "depositSuccess": "Transferencia de la cuenta principal se ha realizado con éxito!",
            "withdrawalSuccess": "Transferencia de la cuenta del casino se ha realizado con éxito!"
        },
        "preferences": {
            "languages": "Idiomas",
            "quotations": {
                "caption": "Formato De Cuotas",
                "decimal": "Decimal",
                "american": "Americano",
                "english": "Fraccional"
            },
            "theme": {
                "caption": "Temática",
                "light": "Claro",
                "dark": "Nocturno"
            },
            "view": "Vista"
        },
        "registration": {
            "success": "Gracias por registrarse",
            "oneclick": {
                "tabName": "Un Click",
                "confirm": {
                    "title": "GRACIAS POR REGISTRARSE",
                    "subtitle1": "Por favor, ",
                    "subtitle2": "¡anote su nombre de usuario y contrasena!",
                    "login": "Usuario",
                    "password": "Contraseña",
                    "copy": "Copy",
                    "savetofile": "Guardar en un archivo",
                    "savetoimage": "Guardar como imagen",
                    "emailplaceholder": "Introzca e-mail",
                    "emailbutton": "Enviar al e-mail",
                    "loginCopied": "Usuario copiado",
                    "passwordCopied": "Contraseña copiada"
                }
            },
            "phone": {
                "tabName": "Por Teléfono",
                "sendSmsButton": "Enviar sms",
                "confirmCode": "Código de confirmación",
                "confirmButton": "Confirmar"
            },
            "email": {
                "tabName": "Por E-mail",
                "emailLabel": "E-mail",
                "countryLabel": "Pais",
                "phoneLabel": "Numero de teléfono",
                "firstNameLabel": "Nombre",
                "lastNameLabel": "Apellido",
                "birthdayLabel": "Fecha de nacimiento",
                "passwordLabel": "Contraseña",
                "passwordConfirmLabel": "Repita la contraseña",
                "promoLabel": "Сódigo BONO DE BIENVENIDA (si hay)",
                "promoPlaceholder": "Introduzca el codigo promocional",
                "registerLabel": "Registrarse",
                "documentLabel": "DNI",
                "loginLabel": "Login (nombre de usuario)",
                "cityLabel": "Ciudad",
                "policy1": "Este sistio esta protegido por reCAPTHA y por la ",
                "policy2": " Politica de Privacidad ",
                "policy3": " de Google y se aplican los ",
                "policy4": "Terminos y Condiciones",
                "policy5": ".",
                "policy6": "Al pulsar este boton, usted confirma que ha leido y acepta los ",
                "policy7": "Terminos y Condiciones",
                "policy8": "y la ",
                "policy9": " Politica de Privacidad ",
                "policy10": "de la compania, y comfirma que es mayor de edad."
            }
        },
        "emailConfirm": {
            "title": "!Su cuenta ha sido activada!",
            "userNumber": "Su numero de cuenta online:",
            "userLogin": "Su nombre de usario:",
            "ref1": "Para poder realizar apuestas Usted debe",
            "ref2": "recargar su cuenta"
        },
        "withdrawalConfirm": {
            "title": "Solicitud de pago #",
            "total": "Total a pagar: ",
            "withdrawalType": "Modo de pago: ",
            "payCode": "Código de pago: ",
            "expiration": "El pago de esta solicitud se podrá hacer efectivo hasta: ",
            "WITHDRAWAL_METHOD_VOUCHER": "Agentes {brandName}",
            "WITHDRAWAL_METHOD_VISA": "Visa/MasterCard",
            "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT": "Сuenta corriente"
        },
        "loginDialog": {
            "menuButton": "Ingresar",
            "emailPlaceholder": "ID, USUARIO o E-MAIL",
            "passwordPlaceholder": "Contraseña",
            "save": "Recordar",
            "forgetPassword": "¿Ha olvidado su contraseña?",
            "loginButton": "Iniciar Sesión",
            "social": "Puede acceder a la web a traves de:",
            "no-register": "¿Todavía no esta registrado?"
        },
        "confirmCodeDialog": {
            "success": "Le ha sido enviado un código nuevo a su dirección de contacto proporcionada al momento de su registración!",
            "caption": "Es Necesario la confirmación de la operación!",
            "text": "Introduzca el código enviado a su dirección de contacto proporcionada al momento de su registración en el siguiente campo:",
            "inputLabel": "Código de confirmación",
            "submitButton": "Verificar"
        },
        "resetPasswordDialog": {
            "caption": "Recuperar Contraseña",
            "emailTabCaption": "Por E-mail",
            "phoneTabCaption": "Por teléfono",
            "emailPlaceholder": "Introduzca su e-mail",
            "phonePlaceholder": "Introduzca su teléfono",
            "submitButton": "Crear contraseña temporal",
            "emailTabText1": "Para recuperar la contrasena introduzca el correo electronico indicado por Usted al registrarse.",
            "emailTabText2": "Le enviaremos un e-mail con mas instrucciones.",
            "phoneTabText1": "Para recuperar su contrasena, introduzca el numero de teléfono que indico al registrarse.",
            "phoneTabText2": "Le enviaremos un SMS con mas instrucciones.",
            "success": "Se le ha enviado una contraseña temporal"
        },
        "changePasswordDialog": {
            "caption": "Cambia la contraseña",
            "currentPasswordLabel": "Su contraseña actual",
            "newPasswordLabel": "Su nueva contraseña",
            "newPasswordConfirmLabel": "Confirmar nueva contraseña",
            "submitButton": "Confirmar",
            "successMessage": "Contraseña cambiada correctamente"
        },
        "expressChangePasswordDialog": {
            "caption": "Bienvenido",
            "text1": "Para la seguridad de su cuenta, le recomendamos cambiar la contraseña ahora!",
            "submitButton": "Cambie su contraseña ahora",
            "closeButton": "Cambiar más tarde"
        },
        "userMenu": {
            "personalData": "Datos personales",
            "settings": "Configuración de la cuenta",
            "bids": "Apuestas en juego",
            "allbids": "Todas Apuestas",
            "finance": "Finanzas",
            "stakes": "Mis apuestas",
            "bonuses": "Bonos",
            "logout": "Cerrar sesión"
        },
        "changePhoneDialog": {
            "caption": "Cambiar número de teléfono",
            "phone": "Número de teléfono",
            "submitButton": "Ок",
            "success": "El número de su teléfono ha cambiado con éxito"
        },
        "changeEmailDialog": {
            "caption": "Nuevo e-mail",
            "email": "Ingrese nuevo e-mail:",
            "success": "Su correo electrónico ha cambiado con éxito"
        },
        "profileView": {
            "title": "DATOS PERSONALES",
            "subtitle": "¡Rellene los campos vacios para utilizar las funcionalidades del sitio web!",
            "titleLine": "DATOS DE CUENTA",
            "accountNumber": "Numero de la cuenta",
            "passwordLabel": "Su contraseña",
            "registrationDate": "Fecha de registro",
            "information": "INFORMACION DE CONTACTO",
            "submitButton": "Guardar",
            "success": "Datos guardados exitosamente"
        },
        "routes": {
            "inPlay": "Pendientes",
            "account": "Mi cuenta",
            "allStakes": "Todas",
            "winstakes": "Resueltas",
            "finances": "Finanzas",
            "raceStakes": "Hipicas",
            "withdrawalOrders": "Retiros",
            "depositOperations": "Depositos",
            "bonusOperations": "Bonos"
        },
        "withdrawalOrders": {
            "dateColumn": "Fecha/Hora",
            "amount": "Cantidad",
            "method": "Modo de pago",
            "status": "Estado",
            "cancel": "Cancelar",
            "wt": {
                "WITHDRAWAL_METHOD_VOUCHER": "Voucher",
                "WITHDRAWAL_METHOD_VOUCHER_INSTANT": "Voucher Express",
                "WITHDRAWAL_METHOD_VISA": "Visa",
                "WITHDRAWAL_METHOD_LUKITA": "Lukita",
                "WITHDRAWAL_METHOD_YAPE": "Yape",
                "WITHDRAWAL_METHOD_TUNKI": "Tunki"
            },
            "statusText": {
                "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION": "Es necesario confirmar la solicitud mediante un código secreto dentro de las 24 horas",
                "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED": "La solicitud de pago no fue confirmada por el usuario dentro de las 24 horas",
                "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE": "La solicitud de pago está pendiente de consideración",
                "ORDER_WITHDRAWAL_STATUS_APPROVED": "La solicitud ha sido aprobada y se está preparando para el pago",
                "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED": "La solicitud ha sido aprobada y se enviará para pago después de la confirmación de identidad. Se requiere un documento de identificación. Póngase en contacto con el soporte para obtener información detallada",
                "ORDER_WITHDRAWAL_STATUS_PAID": "La solicitud ha sido enviada para pago.",
                "ORDER_WITHDRAWAL_STATUS_CASH_WAITING": "La solicitud está pendiente de pago al cliente en caja durante 3 días.",
                "ORDER_WITHDRAWAL_STATUS_CASH_PAID": "La solicitud ha sido pagada en la caja.",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED": "El pago no fue reclamado por el cliente durante 3 días y fue cancelado.",
                "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR": "El pago no es posible. Por favor, contacte con el soporte para obtener más información.",
                "ORDER_WITHDRAWAL_STATUS_DENIED": "El pago ha sido denegado. La solicitud ha sido anulada. Para obtener más información, póngase en contacto con el soporte.",
                "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED": "La solicitud de pago fue cancelada por el cliente."
            },
            "confirm": {
                "caption": "Confirmación de cancelación de la transacción de pago.",
                "text": "¿Quieres cancelar la transacción de pago?"
            },
            "cancelSuccess": "Transacción de pago cancelada"
        },
        "cardsTable": {
            "dateColumn": "Fecha, hora",
            "eventColumn": "Eventro/resultado",
            "quotationColumn": "Cuota",
            "amountColumn": "Cantidad",
            "winColumn": "Pagos",
            "infoColumn": "Info",
            "bonus": "cuenta de bonos"
        },
        "inPlay": {
            "cardsTotal": "Apuestas en juego:",
            "amount": "Cantidad total:"
        },
        "allStakes": {
            "beginDate": "Period desde:",
            "endDate": "Hasta:",
            "itemsPerPage": "Mostrar en la pagina hasta:",
            "dateFilter": "Filtrar por fecha"
        },
        "operations": {
            "operationColumn": "Operacion",
            "amountColumn": "Cantidad"
        },
        "deposit": {
            "amountInput": {
                "caption": "Recarga de la cuenta {type}",
                "amount": "Cantidad: ",
                "currency": "Moneda: ",
                "dialogText": "Verifique los datos y confirme la operación: usted ha solicitado un pago por un monto de {amount}{currency}",
                "min": "El monto mínimo de deposit es {amount}{currency}."
            },
            "monnetCard": {
                "description": "as recargas mediante tarjetas Visa o Mastercard son gestionadas a través de la plataforma de Monnet. En función de las circunstancias, esta entidad tiene la facultad de aprobar o rechazar las transacciones en concordancia con sus políticas internas. Para llevar a cabo depósitos con Visa o Mastercard, es necesario que sea el titular de la tarjeta."
            },
            "tupay": {
                "description": "¡Con Tupay, tú tienes el control! Compra en línea y elige cómo pagar. Ya sea con Dinero en Efectivo, Transferencia Bancaria, Tarjetas de Crédito o Débito, o QR Billeteras Digitales, la elección es tuya. Facilitamos tus compras en línea para que sean simples y flexibles. Descubre la libertad de pago con Tupay."
            },
            "DEPOSIT_MONNET_CARD": "MONNET CARD",
            "DEPOSIT_MONNET": "SAFETYPAY ONLINE",
            "DEPOSIT_MONNET_CASH": "SAFETYPAY",
            "DEPOSIT_TUPAY": "TUPAY",
            "PAGOEFECTIVO_INVOICE": "Pago Efectivo",
            "caption": "Métodos de recarga",
            "method": "Modo de recarga",
            "time": "Tiempo de transferencia a la cuenta",
            "comment": "Notas",
            "yape": "Yape",
            "plin": "Plin",
            "plinText": "Rápido, sencillo y seguro, realiza tus recargas a través de Plin (BBVA, Scotiabank, Interbank)",
            "yapeText": "¡Haz más fácil tu vida yapeando y olvídate del efectivo! Recarga de forma rápida, fácil y segura a través de Yape.",
            "tunkiText": "Con izipayYA puedes enviar dinero a tus contactos vía número de celular o QR, pagar en miles de bodegas y tiendas afiliadas a nivel nacional, recargar tu celular y pagar los servicios de forma GRATUITA, RÁPIDA Y SEGURA.<br>" +
                "Recarga de forma rápida, fácil y segura a través de izipayYA.",
            "lukita": "Lukita",
            "lukitaText": "Recarga de forma rápida, fácil y segura a través de Lukita (BBVA).",
            "tunki": "izipayYA",
            "zeroCommission": "Comision, 0%",
            "refillRef": "Añadir fondos",
            "safetyPayInternet": "Banca por internet SAFETYPAY",
            "auto": "Automatico",
            "safetyPayDescription": "Es un metodo de pago seguro y rapido que le permite ingresar fondos en su cuenta de {brandName} a traves de tu Banca por Internet o por Agentes y Agencias en el BCP, BBVA, INTERBANK SCOTIABANK. <br> Los ingresos son instantaneos y {brandName} no aplicara ninguna comision por utilizar este metodo.",
            "safetyPayCash": "Pagar en efectivo SAFETYPAY-CASH",
            "pagoEfectivo": "PagoEfectivo",
            "pagoSeguro": "PAGO SEGURO",
            "pagoSeguroEfectivo": "Efectivo",
            "immediate": "Inmediatamente",
            "pagoEfectivoDescription": "Paga en BBVA, BCP, Interbank, Scotiabank, BanBif, Caja Arequipa y Banco Pichincha a través de la banca móvil, QR o en agentes y bodegas de Red Digital, Kasnet, Agente Niubiz, Tambo+, Western Union y Disashop.",
            "agents": "Agentes {brandName}",
            "agentsDescription": "Usted puede de forma facil, rapida y segura recargar su cuenta a traves de la red de agentes {brandName}; dirijase a nuestro agente mas cercano,\n" +
                "                                    indicando su numero de cuenta, el nombre de usuario (LOGIN) o DNI, el abono del deposito a la cuenta es automatico y sin comisiones.",
            "iPay": "Ticket iPay",
            "iPayDescription": "Sistema de pago IPAY. Durante el día bancario. Comisión 0%. Puede retirar fondos de su cuenta de juego a través del sistema de pago iPay. Cree una orden de pago por el sistema de pago iPay y confírmelo. Después de confirmar el pedido, recibirá un código secreto de pago, y el monto del pago se deducirá de su cuenta de juego. Presente el Código Secreto a un representante del sistema IPay y después de verificar el código, recibirá el monto del pago en efectivo. No hay comisión por pagar a través del sistema de pago IPay.",
            "voucher": {
                "success": "El reabastecimiento por la cantidad {amount} se realizó con éxito.",
                "found": "Billete n.º {code} encontrado, el importe es {amount}",
                "numberLabel": "Ingresa el código del ticket:",
                "caption": "Ticket iPay"
            }
        },
        "withdrawal": {
            "minAmountError": "Monto mínimo para retirar es {minAmount}",
            "general": {
                "WITHDRAWAL_METHOD_YAPE": "RETIROS YAPE",
                "WITHDRAWAL_METHOD_LUKITA": "RETIROS LUKITA",
                "WITHDRAWAL_METHOD_TUNKI": "RETIROS TUNKI",
                "phoneNumber": "El número de teléfono {phoneNumber} se utilizará para el retiro"
            },
            "partner": {
                "caption": "Retiro de la cuenta",
                "confirmationMethod": "Indicar el modo de la confirmación:",
                "confirmationEmail": "Email",
                "confirmationPhone": "Teléfono"
            },
            "visaForm": {
                "caption": "Transferencia bancaria",
                "document": "Documento",
                "email": "Correo electrónico",
                "bank": "Nombre del Banco:",
                "cardName": "Nombre Completo:",
                "accountType": "Tipo de Cuenta:",
                "fullNumber": "Número de cuenta:",
                "orderedSum": "Cantidad (mínimo {minAmount}):",
                "dni": "DNI:"
            },
            "minAmount": "Monto minimo",
            "caption": "Metodos de retiro",
            "paymentMethod": "Modo de pago",
            "commission": "Comisión",
            "agents": "AGENTES {brandName}",
            "agentsDescription": "Usted podrá realizar retiros de su cuenta de juego a través del sistema de pago AGENTES Betgol. Para esto se debe enviar una solicitud para\n" +
                "                                    pago a través del sistema de pago AGENTES Betgol y confirmarla. Después de confirmar la solicitud, Usted recibe un código Secreto de pago,\n" +
                "                                    y la suma de pago será retirada de su cuenta de juego. Se debe indicar su código Secreto al representante del sistema AGENTES Betgol\n" +
                "                                    y después de la verificación del código, Usted obtendrá el pago en efectivo. No se cobra comisión por el pago a través del sistema\n" +
                "                                    de AGENTES Betgol.",
            "visaLink": "Nueva solicitud",
            "ipay": {
                "description": "Sistema de pago IPAY. Durante el día bancario. Comisión 0%. Puede retirar fondos de su cuenta de juego a través del sistema de pago iPay. Cree una orden de pago por el sistema de pago iPay y confírmelo. Después de confirmar el pedido, recibirá un código secreto de pago, y el monto del pago se deducirá de su cuenta de juego. Presente el Código Secreto a un representante del sistema IPay y después de verificar el código, recibirá el monto del pago en efectivo. No hay comisión por pagar a través del sistema de pago IPay."
            }
        },
        "emailDone": {
            "caption": "ACTIVACION DE LA CUENTA",
            "text": "Para proteger su cuenta, verifique su direccion de e-mail registrada.\n" +
                "                    <br><br>\n" +
                "                    Recibira un e-mail de confirmacion durante los proximos 10 minutos.\n" +
                "                    Si no lo recibe, revise la bandeja de correo no desseado.",
            "codeButton": "Introduzca el código"
        },
        "homeView": {
            "articles": {
                "sport": "Deporte",
                "live": "En vivo",
                "casino": "Casino",
                "virtual": "Juegos <br> Virtuales",
                "bonuses": "Promociones"
            }
        },
        "footer": {
            "sport": "Deporte",
            "live": "En vivo",
            "casino": "Casino",
            "virtual": "Juegos Virtuales",
            "bonuses": "Promociones",
            "privacyPolicy": "Existe Politica de privacidad.",
            "rules": "Reglas",
            "popular": "Apuestas Populares",
            "bets": "Apuestas",
            "affiliates": "Afiliados",
            "complaintsBook": "LIBRO DE RECLAMACIONES",
            "about": {
                "caption": "Acerca",
                "terms": "Terms & Conditions",
                "privacy": "Privacy Policy",
                "aml": "AML/KYC Policy",
                "resp": "Responsible gambling"
            },
            "rights": "Este sitio web es operado por Número de identificación de MCC Digital N.V.: 154760 con sede en Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao representada por el director corporativo Downtown E-Commerce Company B.V. bajo la licencia de juego No. 8048-JAZ2021-164 que opera este sitio web.<br>\n" +
                "Las transacciones son procesadas por Monte Carlos Games Europe Ltd registrada con el número 12676271, con domicilio social en Kemp House 160 City Road, Londres, Reino Unido. Con el fin de procesar pagos a través de Paysafe Group, incluidos, entre otros, Neteller y Skrill, la entidad de procesamiento de transacciones será Monte Carlos Games N.V., registrada en Curaçao con el número 154760, con dirección en Heelsumstraat 51 Curaçao."
        },
        "privacyPolicy": "<b><u>Política de confidencialidad</u></b><br>" +
                "La presente politica de privacidad se publica para informar sobre los principios de los datos personales de los usuarios del sitio web www.tubetgol.com<br>" +
                "<br>" +
                "<b>Aspectos generales</b><br>" +
                "Sitio web web www.tubetgol.com (en adelante “sitio web”) se preocupa por darle al cliente y al usuario la confidencialidad de sus datos personales (en adelante “Usuarios”, “Datos personales”). Por datos personales se entiende cualquier información del usuario del sitio web, incluyendo el nombre, la dirección, la fecha de nacimiento, los datos de contacto, los requisitos de pago y cualquier otra información, que el usuario pudo suministrarle a la Empresa. La Empresa tratará de garantizar el uso de la información personal del usuario acorde con las normas internacionales de protección de datos y de confidencialidad.<br>" +
                "Cuándo el usuario suministra a la Empresa sus datos personales Cuando el usuario en el sitio web solicita información sobre los productos y los servicios de la Empresa, él voluntariamente suministra a la Empresa sus datos personales. Cuando el usuario registra una cuenta de juego nueva se le solicita suministrarle a la Empresa su información personal. Si el usuario ya tiene una cuenta registrada (es un cliente de la Empresa), para el acceso al sitio web debe usar su nombre y contraseña. Los cookies pueden ser utilizados para identificar ingresos repetidos del usuario del sitio web. El servidor de la Empresa graba las páginas visitadas por el usuario en el sitio web.<br>" +
                "<b>Uso de los datos personales</b><br>" +
                "La Empresa recoge, conserva y utiliza los datos personales de los usuarios de acuerdo con la legislación actual de protección de datos para los siguientes usos: manejo de la cuenta de juego del usuario, prestación de servicios al usuario, análisis y control del uso por parte del usuario del sitio web y de los servicios prestados por la Empresa, control de calidad del servicios a los usuarios, información periódica sobre los productos y servicios, que podrían interesar a los usuarios (en adelante “metas”). La información sobre las acciones de los usuarios es guardada por la Empresa, es de su propiedad y puede ser usada para las Metas indicadas.<br>" +
                "<b>Apertura de datos personales</b><br>" +
                "La información personal del usuario podrá ser traspasada o descubierta por la Empresa por sus socios de negocios, quienes pueden junto con la Empresa utilizar esta información para las Metas establecidas. El uso de los datos personales del usuario en estos casos se regulará por el acuerdo en el formato establecido por ley. En los casos, donde es una exigencia legal o por disposiciones de los órganos judiciales, los datos personales del usuario podrán ser suministrados a los órganos gubernamentales, administrativos y ejecutivos correspondientes.<br>" +
                "<b>Acuerdo de uso de datos personales</b><br>" +
                "Al suministrar los datos personales en el sitio web y/o al registrarse en el sitio web y/o al ingresar a su cuenta de juego en la web, el usuario autoriza voluntariamente a la Empresa para reunir, manejar y acceder a su información personal para los fines indicados anteriormente. Si usted como usuario de la página web quiere precisar, cambiar o restringir su autorización de algún modo, por favor infórmele sobre esto a la Empresa, utilizando los datos de contacto, indicados en el sitio web.",
        "rules": {
            "generalTabName": "Generales",
            "specialTabName": "Casos especiales",
            "stakeTypesTabName": "Tipos de las apuestas",
            "financeTabName": "Operaciónes de finanazas",
            "resultsTabName": "Resultados"
        },
        "bonus": {
            "playGame": "Jugar",
            "saldo": "Saldo del bono",
            "rest": "Te resta por apostar",
            "amount": "Monto a apostar",
            "useBonusButton": "Usar Bono",
            "cancelBonusButton": "Cancelar Bono",
            "terms": "Términos y condiciones",
            "init": "Activación del bono",
            "endTime": "Válido hasta",
            "cancelled": "Cancelación del bono",
            "expired": "El termino del bono esta vencido",
            "winning": "Depósito desde la cuenta de bono",
            "cancelBonusConfirmCaption": "¡Tenga en cuenta!",
            "cancelBonusConfirmMessage": "Todos los fondos del bono se anulararán",
            "freeSpins": "El número de giros gratis",
            "freeSpinsWinnings": "Ganancia recibida"
        },
        "results": {
            "goals": {
                "goal1": "Primer gol: {participantName} por {time} min.",
                "goal2": "Segundo gol: {participantName} por {time} min.",
                "goal3": "Tercero gol: {participantName} por {time} min.",
                "goal4": "Cuatro gol: {participantName} por {time} min.",
                "goal5": "Quinto gol: {participantName} por {time} min.",
                "goal6": "Sexto gol: {participantName} por {time} min.",
                "goal7": "Séptimo gol: {participantName} por {time} min.",
                "goal8": "Octavo gol: {participantName} por {time} min.",
                "goal9": "Noveno gol: {participantName} por {time} min.",
                "goal10": "Décimo gol: {participantName} por {time} min.",
                "goal11": "Undécimo gol: {participantName} por {time} min.",
                "goal12": "Duodécimo gol: {participantName} por {time} min."
            },
            "set": "set: {set} game: {game} ganador {participant}",
            "caption": "RESULTADOS",
            "dateFilter": "Fecha",
            "all": "Todo",
            "today": "Hoy",
            "yesterday": "Ayer",
            "select": "Seleccionar",
            "calendar": "Calendar",
            "invalidDate": "Fecha invalida"
        },
        "copyright": "© 2019-2020 Compania"
    },
    "mobile": {
        "profileView": {
            "profileTab": "Tus Datos",
            "settingsTab": "Ajustes",
            "loginLabel": "Login (Usario)"
        },
        "navigationView": {
            "depositButton": "Depositos",
            "withdrawalButton": "Retiros",
            "stakesButton": "Apuestas deportivas",
            "casinoButton": "Casino",
            "historyButton": "Historial",
            "raceButton": "Apuestas Hipicas",
            "financesButton": "Finanzas",
            "bonusButton": "Bonos",
            "sportBalance": "Balance:",
            "sportBalanceAvailable": "Disponible para retirar:"
        },
        "bottomBar": {
            "menuButton": "Menú"
        },
        "menuBar": {
            "installApplication": "Instalar aplicación",
            "bets": "Historial de apuestas"
        },
        "depositView": {
            "min": "Monto minimo:",
            "max": "Cantidad maxima:",
            "depositButton": "Depositar"
        },
        "withdrawalView": {
            "withdrawalButton": "Retiro"
        },
        "financesView": {
            "caption": "Mis finanzas"
        },
        "stakesView": {
            "caption": "Mis apuestas",
            "cardCaption": "N.° int. {innerCardId}",
            "cardStatus": {
                "accepted": "Pendiente",
                "win": "Premio",
                "lost": "Sin premio"
            },
            "containerStatusCaption": "Estado"
        },
        "historyView": {
            "caption": "Historia"
        },
        "routes": {
            "sport": "Deportes",
            "live": "En Vivo",
            "casino": "Casino",
            "coupon": "Cupón",
            "operationsHistory": "Movimentos",
            "sessionsHistory": "Accesos",
            "balance": "Balance",
            "casinoStakes": "Casino",
            "search": "Buscar",
            "virtual": "Virtual",
            "bonus": "Bonos"
        },
        "homeView": {
            "sport": {
                "caption": "Apuestas Deportivas",
                "sportButton": "Visitar Deportes >>",
                "prematch": "Pre-Match",
                "live": "En Vivo",
                "soccer": "Fútbol",
                "basketball": "Baloncesto",
                "baseball": "Béisbol",
                "boxing": "Boxeo",
                "rugby": "Rugby",
                "tennis": "Tenis",
                "volleyball": "Voleibol",
                "allMatches": "Todos Eventos"
            },
            "casino": {
                "caption": "Casino",
                "casinoButton": "Visitar Casino >>"
            },
            "virtual": {
                "caption": "Juegos Virtuales",
                "virtualButton": "Visitar virtuales"
            },
            "horse": {
                "caption": "Hipicas",
                "horseButton": "Visitar Hipicas",
                "game1": "Caballos",
                "game2": "Galgos",
                "game3": "Carretas"
            },
            "bonus": "Bono De Bienvenida",
            "allGames": "todos los juegos"
        }
    },
    "mcashier": {
        "saleVoucherDialog": {
            "copyButton": "Copiar número",
            "printButton": "Imprimir",
            "copySuccess": "Número copiado con éxito"
        },
        "cashWithdrawal": {
            "success": "Retiro completado con éxito"
        },
        "menu": {
            "saleVoucher": "Ticket venta",
            "buyVoucher": "Ticket compra"
        },
        "refillAccount": {
            "success": "Cuenta recargada con éxito"
        },
        "expressAccount": {
            "success": "Cuenta creada con éxito"
        }
    },
    "landing": {
        "agreement": "Soy mayor de 18 años y confirmo que he leído y aceptado los términos y condiciones"
    },
    "bonusNotification": {
        "body": "Racarga tu cuenta para activar el Bono"
    },
    "bonusRegistration": {
        "title": "Regístrate",
        "subtitle": "Elige tu Bono de Bienvenida",
        "sportBetting": {
            "title": "APUESTAS DEPORTIVAS"
        },
        "casino": {
            "title": "CASINO"
        },
        "freeSpins": {
            "title": "Tiradas gratis"
        },
        "noBonus": {
            "title": "SIN BONO",
            "description": "JUGAR EN<br/> {site}",
            "list": `
                <li>Bonos podrán ser activados después del registro.</li>
                <li>Esta opción no afectará tu registro.</li>
            `
        }
    }
};
