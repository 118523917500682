import { PaymentSystems } from "@sportaq/common/enums/payment-systems";

export interface WithdrawalOrder {
    id: number;
    withdrawalType: PaymentSystems;
    orderSum: number;
    currencyId: number;
    currencyMark: string;
    payCode: string;
    expirationTime: Date;
}

export enum EWithdrawalType {
    WITHDRAWAL_METHOD_VOUCHER = "WITHDRAWAL_METHOD_VOUCHER",
    WITHDRAWAL_METHOD_BITCOIN = "WITHDRAWAL_METHOD_BITCOIN",
    WITHDRAWAL_METHOD_BITCOINCASH = "WITHDRAWAL_METHOD_BITCOINCASH",
    WITHDRAWAL_METHOD_BITCOINGOLD = "WITHDRAWAL_METHOD_BITCOINGOLD",
    WITHDRAWAL_METHOD_CASH = "WITHDRAWAL_METHOD_CASH",
    WITHDRAWAL_METHOD_DASH = "WITHDRAWAL_METHOD_DASH",
    WITHDRAWAL_METHOD_LIQPAY = "WITHDRAWAL_METHOD_LIQPAY",
    WITHDRAWAL_METHOD_LITECOIN = "WITHDRAWAL_METHOD_LITECOIN",
    WITHDRAWAL_METHOD_LUKITA = "WITHDRAWAL_METHOD_LUKITA",
    WITHDRAWAL_METHOD_MASTERCARD_MAESTRO = "WITHDRAWAL_METHOD_MASTERCARD_MAESTRO",
    WITHDRAWAL_METHOD_MONETA = "WITHDRAWAL_METHOD_MONETA",
    WITHDRAWAL_METHOD_MTS = "WITHDRAWAL_METHOD_MTS",
    WITHDRAWAL_METHOD_PAGOEFECTIVO = "WITHDRAWAL_METHOD_PAGOEFECTIVO",
    WITHDRAWAL_METHOD_QIWI = "WITHDRAWAL_METHOD_QIWI",
    WITHDRAWAL_METHOD_REFILL_BETWEEN_SYSUSERS = "WITHDRAWAL_METHOD_REFILL_BETWEEN_SYSUSERS",
    WITHDRAWAL_METHOD_RUSSIAN_STANDARD = "WITHDRAWAL_METHOD_RUSSIAN_STANDARD",
    WITHDRAWAL_METHOD_SETTLEMENTACCOUNT = "WITHDRAWAL_METHOD_SETTLEMENTACCOUNT",
    WITHDRAWAL_METHOD_TAPMBET = "WITHDRAWAL_METHOD_TAPMBET",
    WITHDRAWAL_METHOD_TUNKI = "WITHDRAWAL_METHOD_TUNKI",
    WITHDRAWAL_METHOD_VISA = "WITHDRAWAL_METHOD_VISA",
    WITHDRAWAL_METHOD_VISAMASTERCARD = "WITHDRAWAL_METHOD_VISAMASTERCARD",
    WITHDRAWAL_METHOD_VISAMC = "WITHDRAWAL_METHOD_VISAMC",
    WITHDRAWAL_METHOD_WEBMONEY = "WITHDRAWAL_METHOD_WEBMONEY",
    WITHDRAWAL_METHOD_YANDEX_MONEY = "WITHDRAWAL_METHOD_YANDEX_MONEY",
    WITHDRAWAL_METHOD_YAPE = "WITHDRAWAL_METHOD_YAPE"
}

export enum EWithdrawalStatus {
    ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION = "ORDER_WITHDRAWAL_STATUS_WAITING_CONFIRMATION",
    ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED = "ORDER_WITHDRAWAL_STATUS_CONFIRMATION_EXPIRED",
    ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE = "ORDER_WITHDRAWAL_STATUS_WAITING_APPROVE",
    ORDER_WITHDRAWAL_STATUS_APPROVED = "ORDER_WITHDRAWAL_STATUS_APPROVED",
    ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED = "ORDER_WITHDRAWAL_STATUS_APPROVED_UNVERIFIED",
    ORDER_WITHDRAWAL_STATUS_PAID = "ORDER_WITHDRAWAL_STATUS_PAID",
    ORDER_WITHDRAWAL_STATUS_CASH_WAITING = "ORDER_WITHDRAWAL_STATUS_CASH_WAITING",
    ORDER_WITHDRAWAL_STATUS_CASH_PAID = "ORDER_WITHDRAWAL_STATUS_CASH_PAID",
    ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED = "ORDER_WITHDRAWAL_STATUS_PAYMENT_EXPIRED",
    ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR = "ORDER_WITHDRAWAL_STATUS_PAYMENT_ERROR",
    ORDER_WITHDRAWAL_STATUS_DENIED = "ORDER_WITHDRAWAL_STATUS_DENIED",
    ORDER_WITHDRAWAL_STATUS_USER_CANCELLED = "ORDER_WITHDRAWAL_STATUS_USER_CANCELLED"
}

export interface WithdrawalOrderEx {
    id: number;
    withdrawalType: EWithdrawalType;
    orderTime: Date;
    sumOrder: number;
    currencyId: number;
    currencyMark: string;
    status: EWithdrawalStatus;
    changeStatusTime: Date;
    expirationTime: Date;
    GUID: string
}
