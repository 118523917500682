export enum ThemeClass {
    Default = "theme-default",
    Latino = "theme-latino",
    LigaBet = "theme-ligabet",
    Light = "theme-light",
    Dark = "theme-dark",
    SingleGameVersion = "single-game-version",
    CertaBetDark = "theme-certabet-dark",
    OnceBet = "theme-oncebet"
}
