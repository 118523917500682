import MobileScoreboardLine
    from "@/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/MobileScoreboardLine.vue";
import { PropType } from "vue";
import QuotationView from "@sportaq/common/enums/quotation-views";

import {
    ScoreboardBodyComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/common-scoreboard-body-component";
import ScoreboardGroupEvents
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-group-events/ScoreboardGroupEvents.vue";
import { RecycleScroller } from "vue-virtual-scroller";
import {
    useMobileScoreboardBodyItems
} from "@/components/betting/betting-scoreboard/scoreboard-body/mobile-scoreboard-body-items";

interface Props extends ScoreboardBodyComponent.Props {
    viewTitle: string;
}

export default {
    props: {
        quotationViews: {
            required: false,
            type: Object as PropType<QuotationView[]>,
            default: []
        },
        viewTitle: {
            required: false,
            type: String,
            default: ""
        }
    },
    components: {
        RecycleScroller,
        ScoreboardGroupEvents,
        MobileScoreboardLine
    },
    setup (props: Props) {
        const {
            buffer
        } = ScoreboardBodyComponent.useScoreboardBodyComponent<Props>(props);

        const {
            periods,
            onResize
        } = useMobileScoreboardBodyItems();

        return {
            periods,
            buffer,
            onResize
        };
    }
};
