import { resolveLanguage } from "@sportaq/i18n/index";
import { Tab } from "@sportaq/vue/views/web/routing-tab";
import { ROUTES } from "web_desktop/src/router/routes-consts";
import { ref, computed } from "vue";
import { useRoute } from "vue-router";

const tabs: Tab[] = [
    {
        id: 1,
        path: ROUTES.RULES_GENERAL.path,
        i18nKey: "webdesktop.rules.generalTabName"
    },
    {
        id: 2,
        path: ROUTES.RULES_SPECIAL.path,
        i18nKey: "webdesktop.rules.specialTabName"
    },
    {
        id: 3,
        path: ROUTES.RULES_STAKE_TYPES.path,
        i18nKey: "webdesktop.rules.stakeTypesTabName"
    },
    {
        id: 4,
        path: ROUTES.RULES_FINANCE.path,
        i18nKey: "webdesktop.rules.financeTabName"
    },
    {
        id: 5,
        path: ROUTES.RULES_RESULTS.path,
        i18nKey: "webdesktop.rules.resultsTabName"
    }
];

export function useRulesViewCommon () {
    const html = ref();
    const route = useRoute();
    const textComponent = computed(() => {
        const lang = resolveLanguage();
        const document = route.path.endsWith("rules") ? "general" : route.path.substring(route.path.lastIndexOf("/") + 1, route.path.length);
        return lang + document;
    });
    const loading = ref(false);
    return {
        tabs,
        html,
        textComponent,
        loading
    };
}
