import { Comparable, Equable, Hashable } from "@sportaq/common/types/interfaces";
import { ESportType } from "@sportaq/common/enums/sport-type";
import EPeriodType from "@sportaq/common/enums/period-types";
import EventType from "@sportaq/common/enums/event-type";

export class SportPeriodKey implements Hashable<SportPeriodKey> {
    constructor (readonly sport: ESportType, readonly period: EPeriodType) {
    }

    equals (o: SportPeriodKey): boolean {
        if (this === o) {
            return true;
        }
        return this.sport === o.sport && this.period === o.period;
    }

    hashCode (): number {
        let hash = 7;
        hash = 31 * hash + this.sport;
        hash = 31 * hash + this.period;
        return hash;
    }
}

export class CountryKey implements Comparable<CountryKey>, Equable<CountryKey> {
    constructor (readonly eventType: EventType, readonly sportType: ESportType, readonly id: number) {
    }

    compare (o: CountryKey): number {
        return this.eventType - o.eventType ||
            this.sportType - o.sportType ||
            this.id - o.id;
    }

    equals (o: CountryKey): boolean {
        return this.eventType === o.eventType &&
            this.sportType === o.sportType &&
            this.id === o.id;
    }

    static clone (item: CountryKey): CountryKey {
        return new CountryKey(item.eventType, item.sportType, item.id);
    }
}
