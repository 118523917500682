<template>
    <div class="deposit-tabs-head">
        <template v-for="tab of tabs" :key="tab.id">
            <router-link :to="{path: tab.path}" class="item" :class="{active: selectedTab && tab.id === selectedTab.id}" :data-cy="`${tab.path}-route`">
                {{ $t(tab.i18nKey) }}
            </router-link>
        </template>
    </div>
</template>

<script src="./stakes-menu-component.ts" />
