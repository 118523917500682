import { ScoreboardSelection, UNSELECTED } from "@sportaq/model/betting/view/scoreboard-selection";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EventType from "@sportaq/common/enums/event-type";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { quotationBacklightOffTimeoutController } from "@sportaq/common/utils/time-utils";
import { FilterChanger } from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import {
    binarySearchIndex,
    insertIntoSortedArrayAtIndex
} from "@sportaq/common/utils/arrays";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";
import { ScoreboardVuexGetters } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex-getters";
import { IdItem } from "@sportaq/common/types/types";
import { CountryKey } from "@sportaq/model/types/types";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { DiffType } from "@sportaq/model/betting/events/event";
import EPeriodType from "@sportaq/common/enums/period-types";
import { LerItemType, LiveEventResult } from "@sportaq/vuex/modules/betting/scoreboard/live-event-data";
import { ESportType } from "@sportaq/common/enums/sport-type";
import EDetailRow from "@sportaq/common/enums/detail-row";

export namespace ScoreboardVuexMutations {

    export function mutationSyncPeriodsViewWithStore (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        eventType: EventType;
        list: EventDiffsResponse[];
        nonConfirmedPositionIds: number[]
    }) {
        const isExpanded = ScoreboardVuexGetters.isExpandedPositionId(state);
        state.periodsController.syncWithStorage(payload.eventType, payload.list, payload.nonConfirmedPositionIds, payload.eventSupplier, state.eventViewFilter, isExpanded, (positionId, accept) =>
            refreshPositionCallback(state, positionId, accept)
        );
        refreshTotalEventCounters(state, payload.eventSupplier);
        refreshSportTypeCounters(payload.eventSupplier, payload.eventType, state);
        if (payload.eventType === EventType.LIVE && state.needLiveResults) {
            state.liveResults = updateLiveEvents(payload.list, state.liveResults);
        }
        quotationBacklightOffTimeoutController.delayedQuotationBacklightOff();
    }

    export function setNeedLiveResults (state: ScoreboardVuex.ScoreboardStoreState, value: boolean) {
        state.needLiveResults = value;
    }

    export function shiftLiveEvents (state: ScoreboardVuex.ScoreboardStoreState, count: number) {
        for (let i = 0; i < count; i++) {
            const firstItem = state.liveResults.shift();
            if (firstItem) {
                state.liveResults.push(firstItem);
            }
        }
    }

    export function mutationUpdateEventsFilter (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        changers: FilterChanger[]
    }) {
        let needRebuild = false;
        for (const changer of payload.changers) {
            const changeApplied = state.eventViewFilter.change(changer);
            if (changeApplied) {
                const changeEventType = changer.changeEventType;
                if (changeEventType) {
                    switch (changeEventType.type) {
                        case "set": {
                            refreshSportTypeCounters(payload.eventSupplier, changeEventType.value, state);
                            break;
                        }
                        case "unset": {
                            refreshSportTypeCounters(payload.eventSupplier, undefined, state);
                            break;
                        }
                    }
                }
                if ((changer.changeSportTypeId) || changeEventType) {
                    state.scoreboardSelection = UNSELECTED;
                    clearExpanded(state);
                }
            }
            needRebuild = needRebuild || changeApplied;
        }
        if (needRebuild) {
            const needRefreshCountryMenu = payload.changers.filter(value => !(value.changeCountry || value.changePartition)).length > 0;
            rebuildPeriodsView(state, payload.eventSupplier, needRefreshCountryMenu);
        }
    }

    export function mutationClearEventsFilter (state: ScoreboardVuex.ScoreboardStoreState) {
        state.eventViewFilter.clear();
    }

    export function mutationSetScoreboardSelection (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        selected: ScoreboardSelection
    }) {
        state.scoreboardSelection = payload.selected;
    }

    export function mutationAllExpanded (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier
    }) {
        state.inverseExpandedPositionIds = [];
        state.allExpanded = !state.allExpanded;
        rebuildPeriodsView(state, payload.eventSupplier, false);
    }

    export function mutationFilterMenuActive (state: ScoreboardVuex.ScoreboardStoreState, payload: boolean) {
        state.filterMenuActive = payload;
    }

    export function mutationToggleExpanded (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        eventType: EventType;
        positionId: number
    }) {
        const arr = Array.from(state.inverseExpandedPositionIds);
        const index = binarySearchIndex(arr, payload.positionId, (a, b) => a - b);
        if (index >= 0) {
            arr.splice(index, 1);
        } else {
            insertIntoSortedArrayAtIndex(arr, index, payload.positionId);
        }
        state.inverseExpandedPositionIds = arr;
        const expanded = index >= 0 ? state.allExpanded : !state.allExpanded;
        state.periodsController.toggleEventExpanded(payload.eventSupplier, payload.eventType, payload.positionId, expanded);
    }

    export function setLeagueMode (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        value: boolean
    }) {
        state.periodsController.leagueMode = payload.value;
        rebuildPeriodsView(state, payload.eventSupplier, false);
    }

    export function setLiveReverseTimeSorting (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        value: boolean
    }) {
        state.periodsController.reverseTimeSorting = payload.value;
        rebuildPeriodsView(state, payload.eventSupplier, false);
    }

    export function toggleFavouriteCountry (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        item: CountryKey
    }) {
        state.periodsController.toggleFavouriteCountry(payload.eventSupplier, payload.item, state.eventViewFilter,
            (positionId, accept) => refreshPositionCallback(state, positionId, accept));
    }

    export function toggleFavouritePartition (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        country: CountryKey;
        item: IdItem
    }) {
        state.periodsController.toggleFavouritePartition(payload.eventSupplier, payload.country, payload.item, state.eventViewFilter,
            (positionId, accept) => refreshPositionCallback(state, positionId, accept));
    }

    export function toggleFavouriteItem (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        eventType: EventType;
        positionId: number
    }) {
        state.periodsController.toggleFavouriteItem(payload.eventSupplier, payload.eventType, payload.positionId, state.eventViewFilter,
            (positionId, accept) => refreshPositionCallback(state, positionId, accept)
        );
    }

    export function toggleDetailTitlesExpanded (state: ScoreboardVuex.ScoreboardStoreState) {
        state.detailTitlesExpanded = !state.detailTitlesExpanded;
    }

    export function setScoreboardPageType (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        eventSupplier: EventSupplier;
        value: EScoreboardPageType
    }) {
        if (state.periodsController.scoreboardPageType !== payload.value) {
            state.periodsController.scoreboardPageType = payload.value;
            rebuildPeriodsView(state, payload.eventSupplier, true);
        }
    }

    export function removeCountriesOrPartitions (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        countries: CountryKey[];
        partitionIds: number[]
    }) {
        state.periodsController.removeCountriesOrPartitions(payload.countries, payload.partitionIds);
    }

    export function toggleDetailRowPinned (state: ScoreboardVuex.ScoreboardStoreState, payload: {
        sportType: ESportType,
        id: EDetailRow
    }) {
        let detailRows = state.pinnedDetailRows.get(payload.sportType);
        if (detailRows === undefined) {
            detailRows = [];
            state.pinnedDetailRows.set(payload.sportType, detailRows);
        }
        const index = binarySearchIndex(detailRows, payload.id, (a, b) => a - b);
        if (index >= 0) {
            detailRows.splice(index, 1);
        } else {
            insertIntoSortedArrayAtIndex(detailRows, index, payload.id);
        }
    }

    // region private functions
    function rebuildPeriodsView (state: ScoreboardVuex.ScoreboardStoreState, eventSupplier: EventSupplier, needRefreshCountryMenu: boolean) {
        const isExpanded = ScoreboardVuexGetters.isExpandedPositionId(state);
        state.periodsController.refreshByFilter(eventSupplier, state.eventViewFilter, needRefreshCountryMenu, isExpanded);
    }

    function clearExpanded (state: ScoreboardVuex.ScoreboardStoreState) {
        state.allExpanded = false;
        state.inverseExpandedPositionIds = [];
    }

    function refreshTotalEventCounters (state: ScoreboardVuex.ScoreboardStoreState, eventSupplier: EventSupplier) {
        const totalEvents = eventSupplier.getTotalEvents();
        state.totalPreMatchEvents = totalEvents.preMatch;
        state.totalLiveEvents = totalEvents.live;
    }

    function refreshSportTypeCounters (eventSupplier: EventSupplier, eventType: EventType | undefined, state: ScoreboardVuex.ScoreboardStoreState) {
        if (eventType && state.eventViewFilter.eventType === eventType) {
            const countBySportType = eventSupplier.getCountBySportType(eventType);
            for (const sportType of state.sportTypes) {
                sportType.count = countBySportType.get(sportType.id) ?? 0;
            }
        }
    }

    function refreshPositionCallback (state: ScoreboardVuex.ScoreboardStoreState, positionId: number, accept: boolean) {
        if (positionId === state.scoreboardSelection.positionId) {
            if (accept) {
                state.scoreboardSelection = { ...state.scoreboardSelection };
            } else {
                state.scoreboardSelection = UNSELECTED;
            }
        }
    }

    function findLiveEventIndexBySportType (events: LiveEventResult[], sportType: number) {
        return events.findIndex((e) => {
            return e.sportTypeId === sportType;
        });
    }

    function updateLiveEvents (list: EventDiffsResponse[], results: LiveEventResult[]): LiveEventResult[] {
        const liveResults = Array.from(results);
        for (const edr of list) {
            switch (edr.diffType) {
                case DiffType.DELETE: {
                    const eventIndex = liveResults.findIndex(e => e.positionId === edr.positionId);
                    if (eventIndex > 0) {
                        const deletedItem = liveResults.splice(eventIndex, 1)[0];
                        if (deletedItem.itemType === LerItemType.Header) {
                            const newHeaderIndex = liveResults.findIndex(e => e.partition.id === deletedItem.partition.id);
                            if (newHeaderIndex >= 0) {
                                const e = liveResults[newHeaderIndex];
                                liveResults.splice(newHeaderIndex, 1, {
                                    positionId: e.positionId,
                                    sportTypeId: e.sportTypeId,
                                    partition: e.partition,
                                    currentPeriod: edr.currentPeriod ? edr.currentPeriod : e.currentPeriod,
                                    result: edr.resultText ? edr.resultText : e.result,
                                    countryId: e.countryId,
                                    itemType: LerItemType.Header
                                });
                            }
                        }
                    }
                    break;
                }
                case DiffType.NEW: {
                    if (edr.partition) {
                        let index = liveResults.findIndex((e) => {
                            if (edr.partition) {
                                return e.sportTypeId === edr.sportTypeId && edr.partition.name === e.partition.name;
                            }
                            return false;
                        });
                        const ler = {
                            positionId: edr.positionId,
                            sportTypeId: edr.sportTypeId,
                            partition: edr.partition,
                            currentPeriod: edr.currentPeriod ? edr.currentPeriod : EPeriodType.PERIOD_1,
                            result: edr.resultText ? edr.resultText : "0:0",
                            countryId: edr.countryId,
                            itemType: index < 0 ? LerItemType.Header : LerItemType.Normal
                        };
                        if (index < 0) {
                            index = findLiveEventIndexBySportType(liveResults, edr.sportTypeId);
                        } else {
                            index++;
                        }
                        if (index < 0) {
                            index = liveResults.findIndex(e => e.itemType === LerItemType.Header);
                        }
                        liveResults.splice(index < 0 ? 0 : index, 0, ler);
                    }
                    break;
                }
                case DiffType.UPDATE: {
                    const index = liveResults.findIndex((e) => {
                        return e.positionId === edr.positionId;
                    });
                    if (index > 0 && (edr.currentPeriod || edr.resultText)) {
                        const e = liveResults[index];
                        liveResults.splice(index, 1, {
                            positionId: e.positionId,
                            sportTypeId: e.sportTypeId,
                            partition: e.partition,
                            currentPeriod: edr.currentPeriod ? edr.currentPeriod : e.currentPeriod,
                            result: edr.resultText ? edr.resultText : e.result,
                            countryId: e.countryId,
                            itemType: e.itemType
                        });
                    }
                    break;
                }
            }
        }
        return liveResults;
    }

// endregion
}
