<template>
    <div class="bonus-notification-dialog active" id="bonus-notification-dialog">
        <div class="bonus-notification-dialog-body">
            <div class="bonus-notification-dialog-message">
                {{$t("bonusNotification.body")}}
            </div>
            <button type="button" class="bonus-notification-dialog-btn" @click="onClose">
                OK
            </button>
        </div>
    </div>
</template>

<script src="./bonus-notification-dialog-component.ts"> </script>
