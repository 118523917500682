import { DateTimeFormatter, MiscellaneousParamsProvider } from "@sportaq/common/utils/miscellaneous-params";

export class MockMiscellaneousParamsProvider implements MiscellaneousParamsProvider {
    private readonly _dateTimeFormatter = {
        formatDate (date: Date, showYear: boolean): string {
            return date.toLocaleDateString(["ru-RU"], {
                day: "2-digit",
                month: "2-digit",
                year: showYear ? "numeric" : undefined
            });
        },
        formatTime (date: Date): string {
            return date.toLocaleTimeString(["ru-RU"], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false
            });
        }
    };

    get recycleScrollerBufferSize (): number {
        return 100000;
    }

    get dateTimeFormatter (): DateTimeFormatter {
        return this._dateTimeFormatter;
    }

    get balanceRefreshTimeout (): number {
        return Math.pow(2, 31) - 1;
    }

    reloadApplication (): void {
        // ignore
    }
}
