<template>
  <div id="confirmation-dialog" class="popup active">
    <div class="overlay" @click="onNoButtonClick"></div>
    <div class="popup-container">
      <div class="popup-title">{{caption}}</div>
      <div class="popup-content">
        <div class="text">
          {{message}}
        </div>
        <div class="form-line">
          <button id="login-button" type="submit" class="button button-general" @click.prevent="onYesButtonClick" data-cy="login-button">{{$t("common.forms.yes")}}</button>
        </div>
        <div class="form-line">
          <button id="login-close-button" class="button button-secondary" @click.prevent="onNoButtonClick" data-cy="login-close-button">{{$t("common.forms.no")}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./confirmation-dialog-component.ts" />