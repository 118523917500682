<template>
    <div class="main-section main-section-games" v-if="items.length > 0"
         :data-cy="`home-view-games-widget-${gameType}-${tab}`">
        <div class="main-section-head">
            <img :src="categoryImage" :alt="categoryName"/>
            <div class="title" :data-cy="`home-view-games-widget-title-${gameType}-${tab}`">
                {{ $t(categoryName) }}
            </div>
            <router-link :to="link" class="button button-secondary"
                         :data-cy="`home-view-games-widget-link-${gameType}-${tab}`">
                {{ $t("mobile.homeView.allGames") }}
            </router-link>
        </div>
        <div class="horizontal-scroll">
            <Flicking ref="flicker"
                      :options="{ align: 'prev', moveType: [MOVE_TYPE.FREE_SCROLL, { stopAtEdge: true }], circular: false, bound: true, bounce: 15}"
                      :plugins="plugins">
                <div class="event main-section-game" v-for="item of items" :key="item.id">
                    <game-widget :item="item" :showDemo="false" :showBacklight="true" @startGame="startGame"/>
                </div>
                <template #viewport>
                    <span class="flicking-arrow-prev"></span>
                    <span class="flicking-arrow-next"></span>
                </template>
            </Flicking>
        </div>
    </div>
</template>

<script src="./home-page-games-widget-component.ts"/>
