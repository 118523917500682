import {
    CommonWagerContainer,
    WagerContainer,
    WC_TYPE_COMMON
} from "@sportaq/model/cashier/wager-container";
import {
    getIntAttribute,
    getRequiredAttribute,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";

export function parseWagerMessage (wagerContainerListElement: Element): WagerContainer[] {
    const result: WagerContainer[] = [];
    for (let wcElement = wagerContainerListElement.firstElementChild; wcElement != null; wcElement = wcElement.nextElementSibling) {
        const date = new Date(getRequiredAttribute(wcElement, "initTime") + "+00:00");
        const gameMark = getRequiredAttribute(wcElement, "gameMark");
        const id = getRequiredIntAttribute(wcElement, "Id");
        const sumBet = getRequiredFloatAttribute(wcElement, "sumBet");
        const sumWin = getRequiredFloatAttribute(wcElement, "sumWin");
        const betplaceId = getIntAttribute(wcElement, "betplaceId") ?? 0;
        const wc: CommonWagerContainer = {
            id,
            pointId: betplaceId,
            wcType: WC_TYPE_COMMON,
            acceptServerTime: date,
            item: {
                gameMark,
                initTime: date,
                sumBet,
                sumWin
            }
        };
        result.push(wc);
    }
    return result;
}
