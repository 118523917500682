import { CLOSE_EVENT, EVENT_SUCCESS } from "@sportaq/vue/components/web/dialogs/events";
import { computed, ref, SetupContext } from "vue";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { getCurrencyName } from "@sportaq/model/common/point-settings";
import { PaymentSystemData, PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebSettings } from "@sportaq/services/web";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";

export function useAmountInputDialogCommon (context: SetupContext, depositMethod: PaymentSystems, currencyValues: Map<number, number>) {
    const amountRef = ref<number>();
    const amountConfirmed = ref(false);
    const i18n = useI18n();
    const balanceStore = useBalanceStore();
    const currency = ref(balanceStore.currencyId);
    const webStore = useWebStore();
    const settings = useWebSettings();
    const paymentSystem = findPaymentSystem(webStore.user ? webStore.user.paymentSystems : settings.pointSettings.paymentSystems);
    const isCurrencySelectEnabled = currency.value === 0;
    const minAmount = computed(() => {
        const value = currencyValues.get(currency.value);
        if (value === undefined) {
            return undefined;
        }
        return value * 5;
    });
    if (currency.value === 0) {
        if (paymentSystem) {
            currency.value = paymentSystem?.availableCurrencies[0];
        }
    }
    const currencyList = computed(() => {
        return paymentSystem?.availableCurrencies.map(c => {
            return {
                currencyId: c,
                currencyName: getCurrencyName(c)
            };
        });
    });
    const selectedCurrencyName = computed(() => {
        return currency.value === 0 ? "" : getCurrencyName(currency.value);
    });

    function close () {
        context.emit(CLOSE_EVENT);
    }
    function submit () {
        if (!minAmount.value) {
            return;
        }
        if (amountRef.value && amountRef.value < minAmount.value) {
            showMessageBox(i18n.t("webdesktop.deposit.amountInput.min", { amount: minAmount.value, currency: selectedCurrencyName.value }));
            return;
        }
        if (amountConfirmed.value) {
            context.emit(EVENT_SUCCESS, amountRef.value, currency.value);
            context.emit(CLOSE_EVENT);
        } else {
            amountConfirmed.value = true;
        }
    }

    function findPaymentSystem (paymentSystems: PaymentSystemData[]): PaymentSystemData | undefined {
        for (const data of paymentSystems) {
            if (data.paymentSystemType === depositMethod) {
                return data;
            }
        }
        return undefined;
    }

    return {
        amountRef,
        amountConfirmed,
        currency,
        isCurrencySelectEnabled,
        currencyList,
        currencyValues,
        selectedCurrencyName,
        minAmount,
        close,
        submit
    };
}
