const decoder = new TextDecoder("utf-8");

export function fromBinary (encoded: string): string {
    const binary = atob(encoded);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return decoder.decode(bytes);
}

export function stripDni (doc: string| undefined): string {
    if (doc === undefined) {
        return "";
    }
    return doc.startsWith("DNI:") ? doc.substring(4) : doc;
}

export function isValidEmail (email: string) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}