import { DirectiveBinding } from "vue";

interface ClickOutsideElement extends HTMLElement {
    __vueClickEventHandler__: (event: MouseEvent) => void;
}

export default {
    beforeMount: function (el: ClickOutsideElement, binding: DirectiveBinding) {
        const ourClickEventHandler = (event: MouseEvent) => {
            if (!el.contains(event.target as HTMLElement) && el !== event.target) {
                binding.value(event);
            }
        };

        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el: ClickOutsideElement) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
};
