<template>
    <div :class="theme">
        <router-view/>
      <information-box v-if="success && isMessageBoxActive" :message="message" :caption="caption" :success="success"
                       @close="messageBoxClose"/>
      <message-box v-if="!success && isMessageBoxActive" :message="message" :caption="caption" :success="success"
                   @close="messageBoxClose"/>
    </div>
</template>

<script src="./app-component.ts">

</script>

<style lang="scss">
@import "@scss/mobile/app.scss";
.__jivoMobileButton {
    bottom: 50px !important;
    z-index: 76 !important;
}
.popupBlock_c2e1.__short_d702 {
    min-width: 200px;
    bottom: 50px;
}
</style>
