import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatValue, getRequiredIntValue, getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export class QVc11FindVoucherMessageHandler extends AbstractMessageHandler<VoucherSellInfo> {
    protected readonly requestType: string = "Q.VC.1.1";
    constructor (readonly voucherCode: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const voucherElement = request.addChild(actionElement, "Voucher");
        request.addChildWithValue(voucherElement, "VoucherCode", this.voucherCode);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): VoucherSellInfo {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode !== "2300") {
            throw new LocalizedError(`errors.mtl.aVc18.code${serverCode}`);
        }
        const voucherElement = getRequiredChild(action, "Voucher");
        const currencyId = getRequiredIntValue(getRequiredChild(voucherElement, "VoucherCurrencyId"));
        return {
            amount: getRequiredFloatValue(getRequiredChild(voucherElement, "VoucherCost")),
            sellDate: new Date(Date.now()),
            voucherCode: getRequiredValue(getRequiredChild(voucherElement, "VoucherCode")),
            voucherNumber: getRequiredValue(getRequiredChild(voucherElement, "VoucherNumber")),
            name: "",
            currencyId,
            currency: getCurrencyName(currencyId)
        };
    }
}
