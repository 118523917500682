import CasinoTopMenu from "@sportaq/vue/components/games/casino/casino-top-menu/CasinoTopMenu.vue";
import GamePreview from "@/components/games/game-preview/GamePreview.vue";
import GamesBody from "@sportaq/vue/components/games/common/games-body/GamesBody.vue";
import GameFrame from "@sportaq/vue/components/games/common/game-frame/GameFrame.vue";
import { computed, onMounted, SetupContext } from "vue";
import { CasinoGamesComponent } from "@sportaq/vue/components/games/casino/common-casino-games-component";
import useCasinoGamesComponent = CasinoGamesComponent.useCasinoGamesComponent;
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import { useWebVirtualGamesLifecycle } from "@sportaq/vue/components/web/web-virtual-games-lifecycle";

export default {
    components: {
        CasinoTopMenu,
        GamesBody,
        GamePreview,
        GameFrame
    },
    setup (props: [], context: SetupContext) {
        const webStore = useWebStore();
        const {
            currentGame,
            forceOpenSelectedTabByClick,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        } = useCasinoGamesComponent(context);

        useWebVirtualGamesLifecycle();

        const isLoggedIn = computed(() => webStore.user !== undefined);

        onMounted(() => {
            trackEventFacebook("viewcontentcasino");
        });

        return {
            currentGame,
            forceOpenSelectedTabByClick,
            isLoggedIn,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        };
    }
};
