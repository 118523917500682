<template>
    <template v-if="selection">
        <div class="details" :class="'details-football'" :data-cy="`scoreboard-details-${selection.positionId}`">
            <mobile-scoreboard-details :selection="selection"/>
        </div>
    </template>

    <div class="details" v-else>
        <div class="preloader" data-cy="preloader">
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./mobile-event-details-page-component.ts"/>
