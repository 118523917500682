import { createStore, Store } from "vuex";
import {
    createRootStoreOptions,
    RootState,
    rootStoreByPackageHolder,
    RootStoreImpl
} from "@sportaq/vuex/index";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";
import { BetSlipVuex } from "@sportaq/vuex/modules/betting/bet-slip/internal/bet-slip-vuex";
import { BalanceModule, balanceModule } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { WebDesktopStoreModule, webDesktopStoreModule } from "@sportaq/vuex/modules/web/web-module";
import { CasinoGamesVuex } from "@sportaq/vuex/modules/games/internal/casino-games-vuex";
import { BannersModule, bannersModule } from "@sportaq/vuex/modules/banners/banners-module";

const storeOptions = createRootStoreOptions({
    [ScoreboardVuex.moduleName]: ScoreboardVuex.ScoreboardStoreModule,
    [BetSlipVuex.moduleName]: BetSlipVuex.BetSlipStoreModule,
    [CasinoGamesVuex.moduleName]: CasinoGamesVuex.CasinoGamesStoreModule,
    [balanceModule]: BalanceModule,
    [bannersModule]: BannersModule,
    [webDesktopStoreModule]: WebDesktopStoreModule
});

const store: Store<RootState> = createStore(storeOptions);

export default store;

rootStoreByPackageHolder.rootStoreByPackage = new RootStoreImpl(store);
