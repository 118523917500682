<template>
    <div :class="menuClass" :data-cy="dataCy">
        <a class="arrow arrow-left" @click.stop.prevent="scrollLeft"/>
        <div :class="`${menuClass}-wrapper`">
            <div :class="`${menuClass}-items`">
                <slot name="addMenuButton"></slot>
                <div class="item"
                     :class="{'has-subitems': item.subItems && item.subItems.length > 0, active: item.id === selectedId, disabled: item.count<=0, 'small': item.id === 15}"
                     v-for="item of sliderItems" :key="item.id" @click="setSelected(item, false)"
                     :data-cy="`item-${item.id}`">
                    <span class="icon"><img :src="item.image" alt=""/></span>
                    <span class="label">{{ $t(item.name) }}</span>
                    <span class="count" v-if="item.count !== undefined">{{ item.count }}</span>
                </div>
            </div>
        </div>
        <slot></slot>
        <a class="arrow arrow-right" @click.stop.prevent="scrollRight"/>
    </div>
    <div :class="[subMenuClass, 'active']" v-if="subItems && subItems.length > 0" :data-cy="`${dataCy}-sub-items`">
        <div class="item" :class="{ active: item.id === selectedSubId}" v-for="item of subItems" :key="item.id"
             @click="setSubItemSelected(item)" :data-cy="`sub-item-${selectedId}-${item.id}`">
            <div class="image"><img :src="item.image" alt=""/></div>
            <span class="label">{{ item.title ?? item.name }}</span>
        </div>
    </div>
    <slot name="search"></slot>
</template>

<script src="./constant-items-panel-component.ts"></script>
