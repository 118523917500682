import { PartitionResponse } from "@sportaq/model/types/responses";

export enum LerItemType {
    Header, Normal
}
export interface LiveEventResult {
    positionId: number;
    sportTypeId: number;
    result: string;
    currentPeriod: number;
    partition: PartitionResponse;
    countryId: number;
    itemType: LerItemType;
}
