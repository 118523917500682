<template>
    <div class="deposit-filter">
        <div class="deposit-filter-title">{{$t("webdesktop.allStakes.dateFilter")}}</div>
        <div class="deposit-filter-body">
            <div class="form-line">
                <input type="date" v-model="startDateComputed">
            </div>
            <div class="form-line">
                <input type="date" v-model="endDateComputed">
            </div>
        </div>
    </div>
    <div class="deposit-section">
        <div class="deposit-section-body">
            <template v-for="(operation) in stakes" :key="operation.id">
                <div class="line">
                    <div class="line-date">{{$d(operation.acceptServerTime, "long")}}</div>
                    <div class="line-total smaller">
                        <div class="name">{{getWagerTitle(operation)}}</div>
                        <div class="value" v-if="operation.wcType === WC_TYPE_COMMON">{{operation.item.sumBet > 0 ? '-' + operation.item.sumBet.toFixed(2) : '+' + operation.item.sumWin.toFixed(2)}}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
    <div class="preloader" v-if="requestPending">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./casino-stake-list-component.ts" />
