import { useWebRestService } from "@sportaq/services/web";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";
import { countries } from "@sportaq/model/consts/countries";

interface FormData {
    phone: string;
}

export function useChangePhoneDialogCommon (context: SetupContext) {
    const restService = useWebRestService();
    const store = useWebStore();
    const confirmDialogVisible = ref(false);
    const phone = ref("");
    const i18n = useI18n();
    let phonePlaceholder = "";
    if (store.user) {
        const country = countries.getById(store.user.countryId);
        phonePlaceholder = country !== undefined ? country.phoneCode + "123456789" : "";
    }
    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function changePhone (data: FormData) {
        restService.changeEmail(undefined, data.phone).then(() => {
            confirmDialogVisible.value = true;
        });
    }

    function onConfirmDialogClose () {
        confirmDialogVisible.value = false;
    }

    function onConfirmSuccess () {
        if (store.user) {
            store.updateUserPhone(phone.value);
            showMessageBoxEx(true, undefined, i18n.t("webdesktop.changePhoneDialog.success"));
        }
        onClose();
    }

    return {
        phonePlaceholder,
        confirmDialogVisible,
        phone,

        onClose,
        changePhone,
        onConfirmDialogClose,
        onConfirmSuccess
    };
}
