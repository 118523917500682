import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { PropType, SetupContext } from "vue";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { usePartnerWithdrawalDialogCommon } from "@sportaq/vue/views/web/withdrawal/partner-withdrawal-dialog-common";
import { Point } from "@sportaq/model/web/point";
import { Field, Form } from "vee-validate";

export default {
    name: "PartnerWithdrawalDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form,
        Field,
        ConfirmCodeDialog
    },
    props: {
        pointList: {
            required: false,
            type: Object as PropType<Point>
        },
        minAmount: {
            required: false,
            type: Number
        },
        paymentSystem: {
            type: String,
            required: false
        }
    },
    setup (props: { minAmount: number }, context: SetupContext) {
        return usePartnerWithdrawalDialogCommon(props.minAmount, context);
    }
};
