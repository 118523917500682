import { useWebRestService } from "@sportaq/services/web";
import { EmailRegistrationData } from "@sportaq/model/web/email-registration-data";
import { computed, onMounted, ref } from "vue";
import { bemobInstance, trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import Cookies from "js-cookie";
import { onDocumentKeyFilter, onNaturalNumberKeyFilter } from "@sportaq/vue/utils/form-validation";
import { formatForMaxDate } from "@sportaq/common/utils/time-utils";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { MtlError } from "@sportaq/common/exceptions/localized-errors";
import { useI18n } from "vue-i18n";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useRouter } from "vue-router";
import { countries } from "@sportaq/model/consts/countries";
import { Bonus, getBonusName, getBonusTypeByName, getTextIdByBonusType } from "@sportaq/model/web/bonus";
import { logAnalyticsEvent } from "@sportaq/common/utils/analytics/webview-analytics";
import { useRootStore } from "@sportaq/vuex/index";
import { useSettings } from "@sportaq/services/index";
import { useCasinoPageTitle } from "@sportaq/vue/components/web/casino-page-title";

interface RegistrationFormErrors {
    phone: string | undefined,
    email: string | undefined,
    document: string | undefined,
    login: string | undefined
}

export function useEmailRegistrationCommon (sb: string) {
    const selectedBonusType = sb ? getBonusTypeByName(sb) : undefined;
    const restService = useWebRestService();
    const rootStore = useRootStore();
    const store = useWebStore();
    const router = useRouter();
    const i18n = useI18n();
    const site = i18n.t("brands.site." + useSettings().brandType);
    const sendSuccess = ref(false);
    const agreement = ref(false);
    const countryId = ref<number>(rootStore.userSharedInfo.countryId);
    const customErrors = ref<RegistrationFormErrors>({
        phone: undefined,
        email: undefined,
        document: undefined,
        login: undefined
    });
    const isRequestPending = ref(false);
    const referralCode = Cookies.get("referral_code");
    const d = new Date();
    d.setFullYear(d.getFullYear() - 18);
    const maxDate = formatForMaxDate(d);
    const phoneElementRef = ref<HTMLElement>();
    const emailElementRef = ref<HTMLElement>();
    const documentElementRef = ref<HTMLElement>();
    const loginElementRef = ref<HTMLElement>();
    const pageTop = ref<HTMLElement>();
    const selectedBonus = ref<Bonus>();
    const bonusCheck = ref(false);
    const bonuses = ref<Bonus[]>();
    const lang = computed(() => {
        return i18n.locale.value.toString();
    });
    const previouslySelectedBonus = Cookies.get("selectedBonus");
    if (previouslySelectedBonus) {
        bonusCheck.value = true;
        Cookies.remove("selectedBonus");
    }
    const isBonusTerms = ref(false);
    const btnDisabled = ref(true);

    const phoneCode = computed(() => {
        return countryId.value ? countries.getById(countryId.value)?.phoneCode : "";
    });

    const { updateTitle } = useCasinoPageTitle("registration");
    onMounted(() => {
        updateTitle();
    });

    restService.getBonusList(countryId.value).then(b => {
        bonuses.value = b;
        if (previouslySelectedBonus) {
            const id = parseInt(previouslySelectedBonus);
            selectedBonus.value = b.find(i => i.bonusId === id);
        } else {
            selectedBonus.value = b.find(i => {
                return i.bonusTypeId === selectedBonusType;
            });
            if (selectedBonusType) {
                bonusCheck.value = true;
            }
        }
    });

    function getImageUrl (img: string): string {
        if (process.env.NODE_ENV !== "production") {
            return "https://tubetgol.com" + img;
        }
        return img;
    }

    function selectBonus () {
        router.push({
            name: "registration",
            params: {
                selectedBonusType: getTextIdByBonusType(selectedBonus.value?.bonusTypeId)
            }
        }).then();
    }

    function getBonusClass (bonusTypeId: number) {
        if (bonusTypeId === 1) {
            return "green";
        }
        if (bonusTypeId === 4) {
            return "blue";
        }
        return "orange";
    }

    function onSubmit (values: EmailRegistrationData) {
        if (!agreement.value) {
            return;
        }
        if (referralCode) {
            values.referralCode = referralCode;
        }
        const referralSystem = Cookies.get("referral_system");
        values.referralSystem = referralSystem ?? "";
        isRequestPending.value = true;
        values.phone = phoneCode.value + values.phone;
        customErrors.value = {
            phone: undefined,
            email: undefined,
            document: undefined,
            login: undefined
        };
        restService.emailRegistration(values, selectedBonus.value?.bonusId).then((u) => {
            sendSuccess.value = true;
            isRequestPending.value = false;
            store.user = u;
            logAnalyticsEvent("player_registration", {
                status: "success",
                bonus: selectedBonus.value?.bonusTypeId
            });
            restService.updateCountryFilterFavourites(u.countryId).then();
            bemobInstance.sendRegistration();
            Cookies.remove("referral_system");
            Cookies.remove("referral_code");
            trackEventFacebook("CompleteRegistration");
            router.push({ name: "deposit" }).then();
        }).catch((e) => {
            isRequestPending.value = false;
            if (e instanceof MtlError) {
                const serverCode = (e as MtlError).code;
                const message = i18n.t(`errors.mtl.aUs11.code${serverCode}`);
                switch (serverCode) {
                    case "1118":
                    case "1115": {
                        customErrors.value.phone = message;
                        document.getElementById("phone")?.focus({ preventScroll: false });
                        break;
                    }
                    case "1113": {
                        customErrors.value.document = message;
                        document.getElementById("document")?.focus({ preventScroll: false });
                        break;
                    }
                    case "1107": {
                        customErrors.value.login = message;
                        document.getElementById("login")?.focus({ preventScroll: false });
                        break;
                    }
                    case "1106": {
                        customErrors.value.email = message;
                        document.getElementById("email")?.focus({ preventScroll: false });
                        break;
                    }
                    default: {
                        showMessageBox(message);
                    }
                }
            } else {
                showMessageBox(e.toString());
            }
        });
    }

    return {
        btnDisabled,
        isBonusTerms,
        selectedBonus,
        bonusCheck,
        isRequestPending,
        sendSuccess,
        phoneCode,
        countryId,
        referralCode,
        maxDate,
        customErrors,
        phoneElementRef,
        emailElementRef,
        documentElementRef,
        loginElementRef,
        bonuses,
        pageTop,
        lang,
        site,
        agreement,
        getBonusName,
        selectBonus,
        getBonusClass,
        getImageUrl,
        onPhoneKeyDown: onNaturalNumberKeyFilter,
        onDocumentKeyFilter,
        onSubmit
    };
}
