const MATCH_RESULT_DECOMPOSITION_REGEXP = /\(*(\d+:\d+)\)*/g;
const PERIOD_INFO_REGEXP = /^(.*)\s+\d+:\d+.*$/;

export function matchScoreDecomposition (score: string, teamResultInRow: boolean): string[][] {
    const result: string[][] = teamResultInRow ? [[], []] : [];
    const resultText = score.trim();
    if (resultText) {
        let match: RegExpExecArray | null = null;
        do {
            match = MATCH_RESULT_DECOMPOSITION_REGEXP.exec(resultText);
            if (match) {
                const split = match[1].split(":");
                if (teamResultInRow) {
                    result[0].push(split[0]);
                    result[1].push(split[1]);
                } else {
                    result.push(split);
                }
            }
        } while (match);
    }
    if (result.length === 0) {
        result.push(["", ""]);
    }
    return result;
}

export function extractPeriodName (score: string): string | undefined {
    const resultText = score.trim();
    if (resultText) {
        const match = PERIOD_INFO_REGEXP.exec(resultText);
        if (match) {
            return match[1];
        }
    }
    return undefined;
}
