import { BettingEvent, DiffType } from "@sportaq/model/betting/events/event";
import { Quotation } from "@sportaq/model/betting/events/quotation";
import { PointSettings } from "@sportaq/model/common/point-settings";
import EventType from "@sportaq/common/enums/event-type";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { EventDiffsResponse, QuotationDiffResponse } from "@sportaq/model/types/responses";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { BetSlipVuex } from "@sportaq/vuex/modules/betting/bet-slip/internal/bet-slip-vuex";
import {
    PlaceBetDetailedError,
    PlaceBetResponseExceededMaxSumWin,
    PlaceBetResponseQuotation
} from "@sportaq/model/types/place-bet-response";
import { CombinedStake, EStakeStatus, SimpleStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { transformCoef } from "@sportaq/model/common/betting-calculations";

export namespace BetSlipVuexMutations {

    export function mutationSetShowed (state: BetSlipVuex.BetSlipStoreState, payload: boolean) {
        state.isShowed = payload;
    }

    export function mutationSetAcceptingWithChangedCoef (state: BetSlipVuex.BetSlipStoreState, payload: boolean) {
        state.fullChecking = payload;
        // AcceptingWithChangedCoef switch sets all simple stakes as confirmed
        for (const stake of state.simples) {
            stake.confirmed();
        }
        state.isConfirmed = true;
    }

    export function mutationAddSimple (state: BetSlipVuex.BetSlipStoreState, payload: { event: BettingEvent; quotation: Quotation; pointSettings: PointSettings }) {
        for (const quotation of state.simples) {
            if (quotation.equalsQuotation(payload.event.eventType, payload.quotation)) {
                return;
            }
        }
        state.simples.push(new SimpleStake(payload.event, payload.quotation, payload.pointSettings));
        refreshCombined(state, payload.pointSettings);
    }

    export function mutationSetSimpleAmount (state: BetSlipVuex.BetSlipStoreState, payload: { eventType: EventType; positionId: number; id: number; amount?: number }) {
        const stake = state.simples.find(findSimpleByKeyPredicate(payload));
        if (stake) {
            stake.amount = payload.amount;
        }
    }

    export function mutationSetCombinedAmount (state: BetSlipVuex.BetSlipStoreState, payload: { base: number; bonus: boolean; amount?: number }) {
        const stake = state.combined.find(value => payload.base > 0 ? value.base === payload.base : value.express);
        if (stake) {
            const bonus = stake.bonus;
            stake.bonus = payload.bonus;
            stake.amount = payload.amount;
            if (bonus !== stake.bonus) {
                stake.update(state.simples);
            }
        }
    }

    export function mutationConfirmSimple (state: BetSlipVuex.BetSlipStoreState, payload: { eventType: EventType; positionId: number; id: number }) {
        const stake = state.simples.find(findSimpleByKeyPredicate(payload));
        if (stake) {
            stake.confirmed();
            refreshConfirmed(state);
        }
    }

    export function mutationConfirmAllSimples (state: BetSlipVuex.BetSlipStoreState) {
        for (const stake of state.simples) {
            stake.confirmed();
        }
        state.isConfirmed = true;
    }

    export function mutationRemoveSimple (state: BetSlipVuex.BetSlipStoreState, payload: { eventType: EventType; positionId: number; id: number; pointSettings: PointSettings }) {
        const index = state.simples.findIndex(findSimpleByKeyPredicate(payload));
        if (index >= 0) {
            state.simples.splice(index, 1);
            refreshConfirmed(state);
            refreshCombined(state, payload.pointSettings);
        }
    }

    export function mutationClearStakes (state: BetSlipVuex.BetSlipStoreState) {
        state.simples = [];
        state.combined = [];
        state.isConfirmed = true;
        state.isShowed = false;
        state.topMessage = undefined;
    }

    export function mutationSyncSimplesWithStore (state: BetSlipVuex.BetSlipStoreState, payload: { eventSupplier: EventSupplier; eventType: EventType; list: EventDiffsResponse[]; nonConfirmedPositionIds: number[]; pointSettings: PointSettings }) {
        let updated = false;
        for (const stake of state.simples) {
            if (stake.event.eventType === payload.eventType) {
                const diff = payload.list.find(v => v.positionId === stake.event.positionId);
                if (diff) {
                    const coefsMultiplier = payload.eventType === EventType.LIVE ? payload.pointSettings.liveCoefMultiplier : payload.pointSettings.preMatchCoefMultiplier;
                    updateStake(state, stake, diff, coefsMultiplier);
                    updated = true;
                }
                const nonConfirmedIndex = payload.nonConfirmedPositionIds.findIndex(v => v === stake.event.positionId);
                if (nonConfirmedIndex > -1) {
                    setSimpleStakeStatus(state, stake, EStakeStatus.eventFullTime);
                    updated = true;
                }
            }
        }
        if (updated) {
            refreshCombined(state, payload.pointSettings);
        }
    }

    export function mutationHandlePlaceBetErrors (state: BetSlipVuex.BetSlipStoreState, payload: { causes: PlaceBetDetailedError[]; pointSettings: PointSettings }) {
        for (const cause of payload.causes) {
            switch (cause.type) {
                case "changedCoef":
                    for (const stake of state.simples) {
                        if (stakeEqQuotation(stake, cause.quotation)) {
                            if (stake.bgCoef.equalsNumber(cause.quotation.cardCoef)) {
                                const newCoef = BigDecimal.fromDTO(cause.quotation.newCoef);
                                stake.updateCoef(newCoef, stake.maxWin);
                                state.isConfirmed = false;
                            }
                            break;
                        }
                    }
                    refreshCombined(state, payload.pointSettings);
                    break;
                case "quotationBlocked":
                    for (const stake of state.simples) {
                        if (stakeEqQuotation(stake, cause.quotation)) {
                            setSimpleStakeStatus(state, stake, EStakeStatus.quotationBlocked);
                            break;
                        }
                    }
                    break;
                case "eventStarted": {
                    setSimpleStatusByPosition(state, cause.position, EStakeStatus.eventStarted);
                    break;
                }
                case "eventFullTime": {
                    setSimpleStatusByPosition(state, cause.position, EStakeStatus.eventFullTime);
                    break;
                }
                case "positionBlocked":
                    setSimpleStatusByPosition(state, cause.position, EStakeStatus.positionBlocked);
                    break;
                case "positionLiveServiceNotAvailable":
                    setSimpleStatusByPosition(state, cause.position, EStakeStatus.positionLiveServiceNotAvailable);
                    break;
                case "exceededMaxSumWinAllNetwork":
                case "exceededMaxSumWinStation": {
                    setQuotationMaxSumStake(state, cause.quotation, payload.pointSettings);
                    break;
                }
                case "exceededMaxSumStake": {
                    switch (cause.quotation.type) {
                        case "simple": {
                            setQuotationMaxSumStake(state, cause.quotation.position, payload.pointSettings);
                            break;
                        }
                        case "system": {
                            setSystemMaxSumStake(state, cause.quotation, payload.pointSettings);
                            break;
                        }
                    }
                    break;
                }
                case "incorrectNumberOfPositions":
                    break;
            }
        }
    }

// region private functions

    function updateStake (state: BetSlipVuex.BetSlipStoreState, stake: SimpleStake, diff: EventDiffsResponse, coefsMultiplier: number) {
        if (diff.blocked) {
            setSimpleStakeStatus(state, stake, EStakeStatus.positionBlocked);
            return;
        }
        if (diff.diffType === DiffType.DELETE) {
            setSimpleStakeStatus(state, stake, EStakeStatus.eventFullTime);
            return;
        }
        for (const quotationDiff of diff.quotationDiffList) {
            if (stake.key.id === quotationDiff.id) {
                updateStakeValues(state, stake, quotationDiff, coefsMultiplier);
                break;
            }
        }
    }

    function updateStakeValues (state: BetSlipVuex.BetSlipStoreState, stake: SimpleStake, quotationDiff: QuotationDiffResponse, coefsMultiplier: number) {
        if (quotationDiff.deleted) {
            setSimpleStakeStatus(state, stake, EStakeStatus.quotationBlocked);
            return;
        }
        setSimpleStakeStatus(state, stake, EStakeStatus.active);
        if (quotationDiff.coef && quotationDiff.maxWin) {
            const oldCoef = stake.bgCoef;
            const newCoef = transformCoef(quotationDiff.coef, coefsMultiplier);
            stake.updateCoef(newCoef!, quotationDiff.maxWin);
            if (state.fullChecking && oldCoef.compare(newCoef!) > 0) {
                state.isConfirmed = false;
            } else {
                stake.confirmed();
            }
        }
    }

    function refreshCombined (state: BetSlipVuex.BetSlipStoreState, pointSettings: PointSettings) {
        const activeStakes = state.simples.filter(value => value.status === EStakeStatus.active);
        if (checkDuplicateEvent(activeStakes)) {
            state.combined = [];
            state.topMessage = "errors.betSlip.duplicateEventWarning";
            return;
        }
        state.topMessage = "";
        // Max system's sum stake is min value of max sum stakes from all active ordinary stakes
        let maxSumStake = activeStakes.reduce((previousValue, currentValue) => Math.min(previousValue, currentValue.maxSumStake), Number.MAX_SAFE_INTEGER);
        if (maxSumStake === Number.MAX_SAFE_INTEGER) {
            maxSumStake = 0;
        }
        updateCombinedBlocked(state);
        updateExpress(state, pointSettings, maxSumStake);
        updateSystems(state, pointSettings, maxSumStake);
    }

    function updateCombinedBlocked (state: BetSlipVuex.BetSlipStoreState) {
        state.isCombinedBlocked = state.simples.some(value =>
            value.status === EStakeStatus.positionBlocked ||
            value.status === EStakeStatus.quotationBlocked ||
            value.status === EStakeStatus.eventFullTime ||
            value.status === EStakeStatus.eventStarted ||
            value.status === EStakeStatus.positionLiveServiceNotAvailable
        );
    }

    function updateExpress (state: BetSlipVuex.BetSlipStoreState, pointSettings: PointSettings, maxSumStake: number) {
        const express = state.combined[0];
        if (state.simples.length > 1) {
            if (state.combined.length > 0 && express.express) {
                express.update(state.simples, maxSumStake);
            } else {
                state.combined.unshift(CombinedStake.express(pointSettings, state.simples, maxSumStake));
            }
        } else if (state.combined.length > 0 && express.express) {
            state.combined.splice(0, 1);
        }
    }

    function updateSystems (state: BetSlipVuex.BetSlipStoreState, pointSettings: PointSettings, maxSumStake: number) {
        const limit = state.simples.length;
        if (limit > 2) {
            const maxSystemsLength = limit - 2 + 1;
            if (state.combined.length > maxSystemsLength) {
                state.combined.splice(-(state.combined.length - maxSystemsLength));
            }
            for (let i = 2; i < limit; i++) {
                const existSystem = state.combined.find(value => !value.express && value.base === i);
                if (existSystem) {
                    existSystem.update(state.simples, maxSumStake);
                } else {
                    state.combined.push(CombinedStake.system(pointSettings, i, state.simples, maxSumStake));
                }
            }
        } else {
            state.combined = state.combined.filter(value => value.express);
        }
    }

    function checkDuplicateEvent (activeStakes: SimpleStake[]): boolean {
        for (const i of activeStakes) {
            for (const j of activeStakes) {
                if (i.event.eventType === j.event.eventType && i.event.positionId === j.event.positionId && i.key.id !== j.key.id) {
                    return true;
                }
            }
        }
        return false;
    }

    function findSimpleByKeyPredicate (key: { eventType: EventType; positionId: number; id: number }): (value: SimpleStake) => boolean {
        return (value: SimpleStake) => value.event.eventType === key.eventType && value.event.positionId === key.positionId && value.key.id === key.id;
    }

    function refreshConfirmed (state: BetSlipVuex.BetSlipStoreState) {
        state.isConfirmed = state.simples.every(value => value.isConfirmed);
    }

    function setSimpleStatusByPosition (state: BetSlipVuex.BetSlipStoreState, position: number, status: EStakeStatus) {
        for (const stake of state.simples) {
            if (stake.event.positionId === position) {
                setSimpleStakeStatus(state, stake, status);
            }
        }
    }

    function stakeEqQuotation (stake: SimpleStake, quotation: PlaceBetResponseQuotation): boolean {
        return (stake.event.positionId === quotation.positionId) && (stake.key.quotationKey.equals(quotation.key));
    }

    function setQuotationMaxSumStake (state: BetSlipVuex.BetSlipStoreState, quotation: PlaceBetResponseExceededMaxSumWin, pointSettings: PointSettings) {
        for (const stake of state.simples) {
            if (stakeEqQuotation(stake, quotation)) {
                stake.maxSumStake = quotation.maxStake * pointSettings.currency.value;
                break;
            }
        }
    }

    function setSystemMaxSumStake (state: BetSlipVuex.BetSlipStoreState, quotation: { type: "system"; base: number; maxStake: number }, pointSettings: PointSettings) {
        for (const stake of state.combined) {
            if (stake.base === quotation.base) {
                stake.maxSumStake = quotation.maxStake * pointSettings.currency.value;
                break;
            }
        }
    }

    function setSimpleStakeStatus (state: BetSlipVuex.BetSlipStoreState, stake: SimpleStake, status: EStakeStatus) {
        stake.status = status;
        switch (status) {
            case EStakeStatus.active: {
                updateCombinedBlocked(state);
                break;
            }
            case EStakeStatus.positionBlocked:
            case EStakeStatus.quotationBlocked:
            case EStakeStatus.eventStarted:
            case EStakeStatus.eventFullTime:
            case EStakeStatus.positionLiveServiceNotAvailable: {
                state.isCombinedBlocked = true;
                break;
            }
        }
    }

// endregion
}
