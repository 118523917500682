
import { onMounted, onUnmounted, ref, SetupContext } from "vue";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { useKeyHandler } from "@sportaq/vue/components/common/dialog-key-handler/dialog-key-handler";

const EVENT = "close";
interface Props {
    message: string;
    isActive: boolean;
    success: boolean;
    caption: string;
}

export default {
    props: {
        message: { required: false, type: String },
        caption: { required: false, type: String },
        success: { required: false, type: Boolean }
    },
    emits: [EVENT],
    setup (props: Props, context: SetupContext) {
        function close () {
            context.emit(EVENT);
        }
        useKeyHandler(close, close);
        return {
            close
        };
    }
};
type Handle = () => void;

interface MessageBoxData {
    message: string;
    closeHandle?: Handle;
    success: boolean|undefined;
    caption: string| undefined;
}

export function useMessageBox (strictCaption: boolean = false) {
    const isMessageBoxActive = ref(false);
    const message = ref("");
    const caption = ref("");
    const success = ref(false);
    let closeHandler: Handle | undefined;

    function showMessageBox (event: unknown) {
        if (event !== undefined) {
            const msg = event as MessageBoxData;
            if (msg.caption || (strictCaption && msg.success)) {
                caption.value = msg.caption ?? "";
                message.value = msg.message;
            } else {
                message.value = "";
                caption.value = msg.message;
            }
            isMessageBoxActive.value = true;
            success.value = (msg.success === undefined || msg.success);
            closeHandler = msg.closeHandle;
        }
    }

    function messageBoxClose () {
        isMessageBoxActive.value = false;
        if (closeHandler) {
            closeHandler();
        }
        closeHandler = undefined;
    }

    onMounted(() => {
        eventBus.on(Events.MESSAGE_BOX, showMessageBox);
    });
    onUnmounted(() => {
        eventBus.off(Events.MESSAGE_BOX, showMessageBox);
    });

    return {
        isMessageBoxActive,
        message,
        success,
        caption,
        messageBoxClose
    };
}

export function showMessageBox (localizedMessage: string, closeHandle?: () => void) {
    eventBus.emit(Events.MESSAGE_BOX, { message: localizedMessage, success: false, closeHandle });
}

export function showMessageBoxEx (success: boolean, localizedCaption: string | undefined, localizedMessage: string, closeHandle?: () => void) {
    eventBus.emit(Events.MESSAGE_BOX, { message: localizedMessage, success, caption: localizedCaption, closeHandle });
}
