import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";

export class ABn11UseBonusMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.BN.1.1";
    constructor (readonly bonusId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChild(actionElement, "Bonus", { bonusId: this.bonusId.toString() });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "4230") {
            throw new LocalizedError(`errors.mtl.aBn11.code${serverCode}`);
        }
    }
}
