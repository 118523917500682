import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { HorseGameType, HorseItem, HorseItemStatus, HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import {
    getIntAttribute,
    getRequiredAttribute,
    getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";

export abstract class BaseRaceStakesHandler<T> extends AbstractMessageHandler<T> {
    protected readonly requestTypeCashier: string = "Q.RS.1.2";
    protected readonly requestType: string = "Q.RS.1.1";

    parseStatus (value: string): HorseItemStatus {
        switch (value) {
            case "G": return HorseItemStatus.Winner;
            case "J": return HorseItemStatus.InPlay;
            case "P": return HorseItemStatus.Lost;
            case "D": return HorseItemStatus.Return;
        }
        return HorseItemStatus.Void;
    }

    parseContainer (wcElement: Element): HorseWagerContainer {
        const rc: HorseWagerContainer = {
            serial: getRequiredIntAttribute(wcElement, "serial"),
            id: getRequiredIntAttribute(wcElement, "id"),
            isCalculated: true,
            acceptServerTime: new Date(getRequiredAttribute(wcElement, "serviceAcceptTime") + "+00:00"),
            pointId: getIntAttribute(wcElement, "pointId") ?? 0,
            sumWin: getRequiredFloatAttribute(wcElement, "sumwin"),
            sumBet: getRequiredFloatAttribute(wcElement, "sumstake"),
            isPaid: getRequiredIntAttribute(wcElement, "isPayed") === 1,
            expanded: false,
            items: []
        };
        for (let wcItemElement = wcElement.firstElementChild; wcItemElement != null; wcItemElement = wcItemElement.nextElementSibling) {
            const item: HorseItem = {
                horses: getRequiredAttribute(wcItemElement, "racerName"),
                trackName: getRequiredAttribute(wcItemElement, "track"),
                trackId: getRequiredIntAttribute(wcItemElement, "race"),
                sumWin: getRequiredFloatAttribute(wcItemElement, "sumwin"),
                sumBet: getRequiredFloatAttribute(wcItemElement, "sumstake"),
                gameType: getRequiredAttribute(wcItemElement, "game") as HorseGameType,
                status: this.parseStatus(getRequiredAttribute(wcItemElement, "status").toUpperCase())
            };
            rc.isCalculated = rc.isCalculated && item.status !== HorseItemStatus.InPlay;
            rc.items.push(item);
        }
        return rc;
    }
}
