import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { resolveLanguage } from "@sportaq/i18n/index";
import { dateToUtcMtlStr, parseDate } from "@sportaq/common/utils/time-utils";
import { Partition, Sport, SportEvent } from "@sportaq/model/results/results";
import { Participant } from "@sportaq/model/betting/events/event";
import { appLogger } from "@sportaq/common/utils/logger";

export class QRe11GetResults extends AbstractMessageHandler<Sport[]> {
    protected readonly requestType: string = "Q.RE.1.1";

    constructor (readonly startTime: Date, readonly finishTime: Date) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const eventFilterElement = request.addChild(filterElement, "EventFilter", {
            logic: "AND"
        });
        request.addChildWithValue(eventFilterElement, "EventStatus", "Complete");
        const periodFilterElement = request.addChild(eventFilterElement, "EventPeriod");
        request.addChildWithValue(periodFilterElement, "StartTime", dateToUtcMtlStr(this.startTime));
        request.addChildWithValue(periodFilterElement, "FinishTime", dateToUtcMtlStr(this.finishTime));
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.SportType",
            allFields: "true"
        });
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.Partition",
            allFields: "true"
        });
        const eventSelectorElement = request.addChild(selectorElement, "SelectObject", {
            class: "ps.event.Event"
        });
        request.addChild(eventSelectorElement, "SelectObject", {
            class: "ps.result.EventResult",
            allFields: "true"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "Id"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "GUID"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "status"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "startTime"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "participant1Id"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "participant2Id"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "partitionId"
        });
        request.addChild(eventSelectorElement, "SelectField", {
            name: "comments"
        });
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): Sport[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        const lang = resolveLanguage().toUpperCase();
        if (serverCode === "3325") {
            const sportTypeListElement = getChild(action, "SportTypeList");
            if (sportTypeListElement === undefined) {
                appLogger.logger.info("Empty results received for " + dateToUtcMtlStr(this.startTime) + " - " + dateToUtcMtlStr(this.finishTime));
                return [];
            }
            const sports: Sport[] = [];
            for (let sportElement = sportTypeListElement.firstElementChild; sportElement != null; sportElement = sportElement.nextElementSibling) {
                sports.push({
                    id: getRequiredIntAttribute(sportElement, "Id"),
                    name: this.getName(sportElement, lang),
                    partitions: []
                });
            }
            const partitionListElement = getRequiredChild(action, "PartitionList");
            const partitions = new Map<number, Partition>();
            for (let partitionElement = partitionListElement.firstElementChild; partitionElement != null; partitionElement = partitionElement.nextElementSibling) {
                const sport = this.getSportById(sports, getRequiredIntAttribute(partitionElement, "SportTypeId"));
                if (sport) {
                    const partition: Partition = {
                        id: getRequiredIntAttribute(partitionElement, "Id"),
                        name: this.getName(partitionElement, lang),
                        countryId: getRequiredIntAttribute(partitionElement, "CountryId"),
                        events: []
                    };
                    sport.partitions.push(partition);
                    partitions.set(partition.id, partition);
                }
            }
            const eventListElement = getRequiredChild(action, "EventList");
            for (let eventElement = eventListElement.firstElementChild; eventElement != null; eventElement = eventElement.nextElementSibling) {
                const partition = partitions.get(getRequiredIntAttribute(eventElement, "partitionId"));
                if (partition) {
                    const event: SportEvent = {
                        id: getRequiredIntAttribute(eventElement, "Id"),
                        comments: getRequiredAttribute(eventElement, "comments"),
                        startDate: parseDate(getRequiredAttribute(eventElement, "startTime")),
                        participants: [],
                        results: [],
                        expanded: false
                    };
                    partition.events.push(event);
                    for (let participantElement = getRequiredChild(eventElement, "ParticipantList").firstElementChild; participantElement != null; participantElement = participantElement.nextElementSibling) {
                        event.participants.push(new Participant(getRequiredIntAttribute(participantElement, "Id"), getRequiredAttribute(participantElement, "name")));
                    }
                    for (let resultElement = getRequiredChild(eventElement, "EventResultList").firstElementChild; resultElement != null; resultElement = resultElement.nextElementSibling) {
                        event.results.push({
                            eventParticipantIndex: resultElement.hasAttribute("EPIndex") ? getRequiredIntAttribute(resultElement, "EPIndex") : 0,
                            resultTypeId: getRequiredIntAttribute(resultElement, "RTId"),
                            value: getRequiredIntAttribute(resultElement, "RV"),
                            period: getRequiredAttribute(resultElement, "PD"),
                            resultIndex: resultElement.hasAttribute("RI") ? getRequiredIntAttribute(resultElement, "RI") : undefined,
                            resultTime: resultElement.hasAttribute("RT") ? getRequiredAttribute(resultElement, "RT") : undefined
                        });
                    }
                }
            }
            return sports;
        }
        throw new LocalizedError(`errors.mtl.qRe11.code${serverCode}`);
    }

    private getSportById (sports: Sport[], id: number): Sport | undefined {
        for (const sport of sports) {
            if (sport.id === id) {
                return sport;
            }
        }
        return undefined;
    }

    private getName (element: Element, lang: string) {
        if (element.hasAttribute("name" + lang)) {
            const name = getRequiredAttribute(element, "name" + lang);
            if (name) {
                return name;
            }
        }
        return getRequiredAttribute(element, "Name");
    }
}
