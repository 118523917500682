import { IdItem } from "@sportaq/common/types/types";
import { ScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { addDays, addHours, ClockProvider } from "@sportaq/common/utils/time-utils";

export namespace TimeFilter {
    export enum ETimeSelector {
        HOUR,
        DAY,
        WEEKEND
    }

    export interface Button extends IdItem {
        timeSelector: ETimeSelector;
        value: number;
        name: string;
    }

    export function setTimeFilter (scoreboardStore: ScoreboardStore, clockProvider: ClockProvider, button: Button) {
        const currentTime = clockProvider.getDate();
        let startDate;
        let endDate;
        switch (button.timeSelector) {
            case ETimeSelector.HOUR: {
                startDate = currentTime;
                endDate = addHours(startDate, button.value);
                break;
            }
            case ETimeSelector.DAY: {
                const day = addDays(currentTime, button.value);
                startDate = new Date(day);
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(day);
                endDate.setHours(23, 59, 59, 999);
                break;
            }
            case ETimeSelector.WEEKEND: {
                const dayOfWeek = currentTime.getDay();
                let startDays = 0;
                let endDays = 0;
                if (dayOfWeek) {
                    startDays = 7 - (dayOfWeek + 1);
                    endDays = 7 - dayOfWeek;
                }
                startDate = new Date(addDays(currentTime, startDays));
                startDate.setHours(0, 0, 0, 0);
                endDate = new Date(addDays(currentTime, endDays));
                endDate.setHours(23, 59, 59, 999);
                break;
            }
        }
        scoreboardStore.updateEventsFilter({
            timeInterval: {
                type: "set",
                value: {
                    buttonId: button.id,
                    start: startDate,
                    end: endDate
                }
            }
        });
    }
}
