import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { useEmailConfirmationCommon } from "@sportaq/vue/views/web/confirmation/email-confirmation";
import EmailDone from "@/views/registration-view/components/emaildone/EmailDone.vue";

export default {
    name: "EmailConfirmView",
    components: {
        EmailDone
    },
    setup () {
        const {
            user,
            withdrawalOrder,
            isCodePresent,
            onCloseClick,
            resetPasswordMessage,
            brandName
        } = useEmailConfirmationCommon();
        return {
            user,
            withdrawalOrder,
            isCodePresent,
            onCloseClick,
            resetPasswordMessage,
            brandName,
            formatVoucherNumber
        };
    }
};
