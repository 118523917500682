import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    forEach, getIntAttribute,
    getRequiredAttribute,
    getRequiredChild, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { PopularPartition } from "@sportaq/vuex/modules/betting/non-reactive-storage/dictionaries/item-order-supplier";
import { Partition } from "@sportaq/model/betting/events/event";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { countries } from "@sportaq/model/consts/countries";

export class QLe111GetFavouritesPartitionsCountryFilterMessageHandler extends AbstractMessageHandler<PopularPartition[]> {
    protected readonly requestType: string = "Q.LE.1.11";

    constructor (private countryId: number) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const countryCode = countries.getById(this.countryId)?.alpha2 ?? "";
        request.addChildWithValue(request.head, "country", countryCode);
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    protected parseMessageBody (body: Element): PopularPartition[] {
        const query = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(query, "servercode");
        if (serverCode === "100") {
            const result: PartitionOrder[] = [];
            const list = getRequiredChild(query, "PartitionList");
            forEach(list, "PR", el => {
                const id = getRequiredIntAttribute(el, "Id");
                const partition: Partition = new Partition(
                    id,
                    getRequiredAttribute(el, "name", id),
                    ESportType.Football,
                    getRequiredIntAttribute(el, "countryId")
                );
                result.push(
                    {
                        partition: new PopularPartition(partition, getIntAttribute(el, "isParticular", 0) === 1),
                        order: getRequiredIntAttribute(el, "orderNum")
                    }
                );
            });
            return result.sort((a, b) => {
                const result = a.order - b.order;
                return result === 0 ? a.partition.id - b.partition.id : result;
            }).map(value => value.partition);
        }
        throw new Error(`errors.mtl.qLe111.code${serverCode}`);
    }
}

interface PartitionOrder {
    partition: PopularPartition;
    order: number;
}
