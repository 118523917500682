import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { Ref } from "@sportaq/common/types/types";
import {
    ScoreboardHeadMenuItem,
    ScoreboardHeadQuotationTypesSelector
} from "@sportaq/model/betting/view/scoreboard-head-quotation-types-selector";
import { onMounted, ref, SetupContext, shallowRef, watch } from "vue";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import QuotationView from "@sportaq/common/enums/quotation-views";

const EVENT = "changed";

export default {
    emits: [EVENT],
    setup (_: unknown, context: SetupContext) {
        const scoreboardStore = useScoreboardStore();

        const menuItems: Ref<ScoreboardHeadMenuItem[]> = shallowRef([]);
        const selectedView: Ref<QuotationView | undefined> = ref();

        /*
        const sportTypeName = computed(() => {
            if (scoreboardStore.getEventsViewFilter().sportType) {
                const sport = sportTypes.getById(scoreboardStore.getEventsViewFilter().sportType!);
                return sport?.name;
            }
            return "";
        });
        */

        function refreshMenuItems (sportTypeId: number | undefined, favouriteMode: boolean) {
            let newItems: ScoreboardHeadMenuItem[] = [];
            if (favouriteMode) {
                newItems = ScoreboardHeadQuotationTypesSelector.getFavourites();
            } else if (sportTypeId) {
                newItems = ScoreboardHeadQuotationTypesSelector.get(sportTypeId) || [];
            }

            const index = newItems.findIndex(value => value.view === selectedView.value);
            if (index < 0) {
                const newItem = newItems.length > 0 ? newItems[0] : undefined;
                selectedView.value = newItem?.view;
                context.emit(EVENT, newItem);
            }
            menuItems.value = newItems;
        }

        function select (item: ScoreboardHeadMenuItem) {
            if (selectedView.value !== item.view) {
                selectedView.value = item.view;
                context.emit(EVENT, item);
            }
        }

        onMounted(() => {
            const eventsViewFilter = scoreboardStore.getEventsViewFilter();
            const sportTypeId = eventsViewFilter ? eventsViewFilter.sportType : undefined;
            refreshMenuItems(sportTypeId, scoreboardStore.scoreboardPageType === EScoreboardPageType.FAVOURITES);
        });

        watch(() => scoreboardStore.getEventsViewFilter().sportType, value => {
            refreshMenuItems(value, scoreboardStore.scoreboardPageType === EScoreboardPageType.FAVOURITES);
        });

        watch(() => scoreboardStore.scoreboardPageType, value => {
            const eventsViewFilter = scoreboardStore.getEventsViewFilter();
            const sportTypeId = eventsViewFilter ? eventsViewFilter.sportType : undefined;
            refreshMenuItems(sportTypeId, value === EScoreboardPageType.FAVOURITES);
        });

        return {
            menuItems,
            selectedView,
            // sportTypeName,

            select
        };
    }

};
