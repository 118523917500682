import {
    AbstractPointSettingsMessageHandler
} from "@sportaq/services/rest/messages/system/point-settings-message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { Region } from "@sportaq/model/web/region";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";

export class QGe11RegionMessageHandler extends AbstractPointSettingsMessageHandler<Region[]> {
    protected readonly requestType: string = "Q.GE.1.1";
    constructor (readonly countryId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const geoFilterElement = request.addChild(filterElement, "GeoFilter", {
            logic: "AND"
        });
        const cityElement = request.addChild(geoFilterElement, "CountryList");
        request.addChildWithValue(cityElement, "CountryId", this.countryId.toString());
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            class: "ps.geo.Region"
        });
    }

    parseMessageBody (body: Element): Region[] {
        const query = getRequiredChild(body, "query");
        const regionList = getRequiredChild(query, "RegionList");
        const result: Region[] = [];
        for (let regionElement = regionList.firstElementChild; regionElement != null; regionElement = regionElement.nextElementSibling) {
            result.push({
                id: getRequiredIntAttribute(regionElement, "Id"),
                name: getRequiredAttribute(regionElement, "Name")
            });
        }
        return result;
    }
}
