import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { useEmailDoneCommon } from "@sportaq/vue/components/web/registration/email-done-common";

export default {
    name: "EmailDone",
    components: {
        ConfirmCodeDialog
    },
    setup () {
        return useEmailDoneCommon();
    }
};
