import { CombinedStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { BetSlipRowCommon } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useSettings } from "@sportaq/services/index";
import { formatQuotationCoef } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { calculateCombinedMaxWin } from "@sportaq/model/common/combined-stakes-calculator";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import { computed, ref, SetupContext } from "vue";

export namespace CombinedStakeRowComponent {
    export interface Props {
        stake: CombinedStake;
    }

    export const UPDATE_AMOUNT_EVENT = "updateAmount";

    export function useCombinedStakeRowComponent (props: CombinedStakeRowComponent.Props, context: SetupContext) {
        const bonus = ref(props.stake.bonus);
        const {
            i18n,
            betSlipStore,
            amount,
            errorShowed,
            numberInputDialogShowed,
            stakeError,
            showErrorMessage,
            accept,
            quotationFormat,
            cancelAmountEditing,
            confirmAmountEditing,
            onInputFocus
        } = BetSlipRowCommon.useStakeCommons(props.stake, (store, newAmount) => store.setCombinedStakeAmount(props.stake.base, bonus.value, newAmount));
        const settingsService = useSettings();
        const label = computed(() => {
            if (props.stake.express) {
                return i18n.t("betting.betSlip.express");
            } else {
                return i18n.t("betting.betSlip.system", {
                    base: props.stake.base,
                    limit: props.stake.limit
                });
            }
        });
        const formattedQuotationCoef = computed(() => formatQuotationCoef(quotationFormat.value, props.stake.coef));
        const formattedQuotationCoefWithoutBonus = computed(() => formatQuotationCoef(quotationFormat.value, props.stake.expressWithoutBonus));

        const blocked = computed(() => betSlipStore.isCombinedBlocked);

        const maxWin = computed(() => {
            if (amount.value) {
                return calculateCombinedMaxWin(props.stake.express, props.stake.coef,
                    amount.value, settingsService.pointSettings.draftCoef).toFixed(BIG_DECIMAL_SCALES.MONEY);
            }
            return undefined;
        });

        function saveAmount () {
            betSlipStore.setCombinedStakeAmount(props.stake.base, bonus.value, amount.value);
            context.emit(UPDATE_AMOUNT_EVENT, amount.value);
        }

        function onChange () {
            saveAmount();
        }

        function confirmStakeAmountEditing (value: number) {
            confirmAmountEditing(value);
            saveAmount();
        }

        return {
            bonus,
            amount,
            errorShowed,
            numberInputDialogShowed,

            label,
            blocked,
            stakeError,
            maxWin,
            formattedQuotationCoef,
            formattedQuotationCoefWithoutBonus,

            onChange,
            showErrorMessage,
            accept,
            cancelAmountEditing,
            confirmStakeAmountEditing,
            onInputFocus
        };
    }

}
