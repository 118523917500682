import { onMounted, ref } from "vue";
import { useRestService } from "@sportaq/services/index";
import { appLogger } from "@sportaq/common/utils/logger";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";

interface Props {
    isDesktop: boolean;
    isDemo: boolean;
}

export default {
    name: "HorseGames",
    props: {
        isDesktop: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    setup (props: Props) {
        const currentGameUrl = ref<string>();
        const rest = useRestService();
        const i18n = useI18n();
        const router = useRouter();
        async function startGame () {
            const startGameResponse = await rest.startRaceGame(props.isDesktop);
            if (startGameResponse.type === "success") {
                currentGameUrl.value = startGameResponse.url;
            }
            if (startGameResponse.type === "error") {
                handleError(startGameResponse.code);
            }
        }

        function handleError (code: string) {
            appLogger.logger.error(`Error starting horse game. Code: ${code}`);
            showMessageBox(i18n.t("errors.games.errorStartingGame"), () => {
                router.push({ path: COMMON_ROUTES.SPORT.path }).then();
            });
        }

        onMounted(startGame);
        return {
            currentGameUrl
        };
    }
};
