<template>
    <div class="popup active" id="partner-withdrawal-dialog">
        <div class="overlay" @click="close"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.withdrawal.visaForm.equadorCaption")}}</div>
            <div class="popup-content">
            <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }" v-if="dialogState === 1">
                <div class="form-line">
                    <label for="bunkNumber">{{$t("webdesktop.withdrawal.visaForm.bank")}}</label>
                    <div class="select-wrapper">
                        <Field id="bunkNumber" name="bunkNumber" v-model="selectedBank" required as="select" >
                            <option  v-for="(bank) in banks" :key="bank"
                                     :selected="bank === selectedBank" :value="bank"> {{bank}}</option>
                        </Field>
                    </div>
                </div>
                <div class="form-line">
                    <label for="cardName"  :class="{'label-error': errors.cardName}">{{$t("webdesktop.withdrawal.visaForm.cardName")}}</label>
                    <Field type="text" id="cardName" name="cardName" rules="required" :value="withdrawalData.cardName"/>
                    <div class="field-error" v-if="errors.cardName">{{errors.cardName}}</div>
                </div>
                <div class="form-line">
                    <label for="email"  :class="{'label-error': errors.email}">{{$t("webdesktop.withdrawal.visaForm.email")}}</label>
                    <Field type="text" id="email" name="email" rules="required" :value="withdrawalData.email"/>
                    <div class="field-error" v-if="errors.email">{{errors.email}}</div>
                </div>
                <div class="form-line">
                    <label for="accountType">{{$t("webdesktop.withdrawal.visaForm.accountType")}}</label>
                    <div class="select-wrapper">
                        <Field id="accountType" name="accountType" v-model="selectedAccountType" required as="select" >
                            <option  v-for="(accountType) in accountTypes" :key="accountType"
                                     :selected="accountType === selectedAccountType" :value="accountType"> {{accountType}}</option>
                        </Field>
                    </div>
                </div>
                <div class="form-line">
                    <label for="fullNumber"  :class="{'label-error': errors.fullNumber}">{{$t("webdesktop.withdrawal.visaForm.fullNumber")}}</label>
                    <Field type="text" id="fullNumber" name="fullNumber" rules="required" list="cardNumbers" v-model="defaultAccountNumber"/>
                    <datalist id="cardNumbers">
                        <option v-for="account in bankAccounts" :key="account" :value="account"/>
                    </datalist>
                    <div class="field-error" v-if="errors.fullNumber">{{errors.fullNumber}}</div>
                </div>
                <div class="form-line">
                    <label for="orderedSum"  :class="{'label-error': errors.orderedSum}">{{$t("webdesktop.withdrawal.visaForm.orderedSum", { minAmount: minAmount + currency })}}</label>
                    <Field type="text" id="orderedSum" name="orderedSum" rules="required" />
                    <div class="field-error" v-if="errors.orderedSum">{{errors.orderedSum}}</div>
                </div>
                <div class="form-line">
                    <label for="dni">{{$t("webdesktop.withdrawal.visaForm.document")}}</label>
                    <input type="text" id="dni" name="orderedSum" readonly :value="dni"/>
                </div>
                <div class="form-line">
                    <button class="button button-general" :class="{disabled: !confirmationMethod}" @click.stop.prevent="handleSubmit(onSubmit)">{{$t("common.forms.continue")}}</button>
                </div>
                <div class="form-line">
                    <button class="button button-secondary" v-if="dialogState !== 4" @click.prevent="close">{{$t("common.forms.cancel")}}</button>
                </div>
            </Form>
            <div v-if="withdrawalOrder" >
                <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.title")}}{{withdrawalOrder.id}}</label>
                </div>
                <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.total")}}
                        {{withdrawalOrder.orderSum}}{{withdrawalOrder.currencyMark}}</label>
                </div>
                <div class="form-line">
                    <label>{{$t("webdesktop.withdrawalConfirm.expiration")}}{{$d(withdrawalOrder.expirationTime, 'long')}}</label>
                </div>
                <div class="form-line">
                    <button class="button button-general" @click.stop.prevent="onSubmit">{{$t("common.forms.continue")}}</button>
                </div>
            </div>
        </div>
    </div>
    </div>
    <confirm-code-dialog v-if="dialogState === 3" @success="onCodeSuccess" @close="onConfirmCodeClose" />
</template>

<script src="./equador-withdrawal-dialog-component.ts" />
