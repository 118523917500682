import { useI18n } from "vue-i18n";
import { useSettings } from "@sportaq/services/index";
import { setTitle } from "@sportaq/vue/components/web/page-title";

export function useCasinoPageTitle (keyRoot: string) {
    const i18n = useI18n();
    const settings = useSettings();
    const brandName = i18n.t("brands." + settings.brandType);
    function updateTitle () {
        setTitle(i18n.t("webdesktop.pageTitle." + keyRoot + ".title", { brand: brandName }),
            i18n.t("webdesktop.pageTitle." + keyRoot + ".description", { brand: brandName }));
    }

    return {
        updateTitle
    };
}
