enum EPeriodType {
    MAIN = 0,
    PERIOD_1 = 1,
    PERIOD_2 = 2,
    PERIOD_3 = 3,
    PERIOD_4 = 4,
    PERIOD_5 = 5,
    PERIOD_6 = 6,
    PERIOD_7 = 7,
    PERIOD_8 = 8,
    PERIOD_9 = 9,
    HALF_1 = 100,
    HALF_2 = 101,
    CATEGORY = 1001
}

export default EPeriodType;
