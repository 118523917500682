import BettingFilterMenuPartition
    from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-country/betting-filter-menu-partition/BettingFilterMenuPartition.vue";
import { computed, PropType, ref } from "vue";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import {
    CountryMenuItem
} from "@sportaq/vuex/modules/betting/scoreboard/periods/country-menu-resolver/country-menu-resolver";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { useI18n } from "vue-i18n";
import { HOT_PARTITIONS_COUNTRY_ID } from "@sportaq/common/consts/default-consts";

interface Props {
    country: CountryMenuItem;
    open: boolean;
}

export default {
    props: {
        country: {
            required: true,
            type: Object as PropType<CountryMenuItem>
        },
        open: {
            required: true,
            type: Boolean
        }
    },
    components: {
        BettingFilterMenuPartition
    },
    setup (props: Props) {
        const store = useScoreboardStore();
        const i18n = useI18n();
        const isOpened = ref(props.open);

        const isFavourite = computed(() => store.isCountryFavourite(props.country));
        const isSelected = computed(() => store.getEventsViewFilter().isCountrySelected(props.country.id));
        const favouriteMode = computed(() => store.scoreboardPageType === EScoreboardPageType.FAVOURITES);
        const countryName = computed(
            () => props.country.id === HOT_PARTITIONS_COUNTRY_ID ? i18n.t("betting.countryMenu.hot") : props.country.name
        );
        const isHotPartitionCountry = computed(() => props.country.id === HOT_PARTITIONS_COUNTRY_ID);

        function toggleFavourite () {
            store.toggleFavouriteCountry(props.country);
        }

        function toggleOpened () {
            isOpened.value = !isOpened.value;
        }

        function toggleCountry () {
            store.updateEventsFilter({
                changeCountry: {
                    type: "toggle",
                    value: props.country.id
                }
            });
        }

        return {
            favouriteMode,
            isFavourite,
            isOpened,
            isSelected,
            countryName,
            isHotPartitionCountry,

            toggleFavourite,
            toggleOpened,
            toggleCountry
        };
    }
};
