import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getBasketballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.popular",
        rows: [
            {
                id: EDetailRow.basketball_x1x2,
                title: "detailInfo.groups.basketball.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.basketball_total,
                title: "detailInfo.groups.basketball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.basketball_handicap,
                title: "detailInfo.groups.basketball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1,
                title: "detailInfo.groups.basketball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2,
                title: "detailInfo.groups.basketball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatch,
                title: "detailInfo.groups.basketball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2,
                title: "detailInfo.groups.basketball.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.basketball_total,
                title: "detailInfo.groups.basketball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.basketball_handicap,
                title: "detailInfo.groups.basketball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1,
                title: "detailInfo.groups.basketball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2,
                title: "detailInfo.groups.basketball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatch,
                title: "detailInfo.groups.basketball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter1,
                title: "detailInfo.groups.basketball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter1,
                title: "detailInfo.groups.basketball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter1,
                title: "detailInfo.groups.basketball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter1,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter1",
                quotationIds: [
                    [1014, 1015]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter2,
                title: "detailInfo.groups.basketball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter2,
                title: "detailInfo.groups.basketball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter2,
                title: "detailInfo.groups.basketball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter2,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter2",
                quotationIds: [
                    [1016, 1017]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter3,
                title: "detailInfo.groups.basketball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter3,
                title: "detailInfo.groups.basketball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter3,
                title: "detailInfo.groups.basketball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter3,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter3",
                quotationIds: [
                    [1018, 1019]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter4,
                title: "detailInfo.groups.basketball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter4,
                title: "detailInfo.groups.basketball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter4,
                title: "detailInfo.groups.basketball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter4,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter4",
                quotationIds: [
                    [1020, 1021]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Half1,
                title: "detailInfo.groups.basketball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf1,
                title: "detailInfo.groups.basketball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf1,
                title: "detailInfo.groups.basketball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchHalf1,
                title: "detailInfo.groups.basketball.evenOddMatchHalf1",
                quotationIds: [
                    [1012, 1013]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Half2,
                title: "detailInfo.groups.basketball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf2,
                title: "detailInfo.groups.basketball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf2,
                title: "detailInfo.groups.basketball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicap,
                title: "detailInfo.groups.basketball.handicap",
                quotationIds: [
                    [3, 4]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter1,
                title: "detailInfo.groups.basketball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter2,
                title: "detailInfo.groups.basketball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter3,
                title: "detailInfo.groups.basketball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter4,
                title: "detailInfo.groups.basketball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf1,
                title: "detailInfo.groups.basketball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf2,
                title: "detailInfo.groups.basketball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_total,
                title: "detailInfo.groups.basketball.total",
                quotationIds: [
                    [5, 6]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter1,
                title: "detailInfo.groups.basketball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter2,
                title: "detailInfo.groups.basketball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter3,
                title: "detailInfo.groups.basketball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter4,
                title: "detailInfo.groups.basketball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf1,
                title: "detailInfo.groups.basketball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf2,
                title: "detailInfo.groups.basketball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1,
                title: "detailInfo.groups.basketball.individualTotalTeam1",
                quotationIds: [
                    [27, 28]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2,
                title: "detailInfo.groups.basketball.individualTotalTeam2",
                quotationIds: [
                    [29, 30]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatch,
                title: "detailInfo.groups.basketball.evenOddMatch",
                quotationIds: [
                    [701, 702]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter1,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter1",
                quotationIds: [
                    [1014, 1015]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter2,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter2",
                quotationIds: [
                    [1016, 1017]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter3,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter3",
                quotationIds: [
                    [1018, 1019]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter4,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter4",
                quotationIds: [
                    [1021, 1022]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchHalf1,
                title: "detailInfo.groups.basketball.evenOddMatchHalf1",
                quotationIds: [
                    [1012, 1013]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.basketball.quarters",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Quarter1,
                title: "detailInfo.groups.basketball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter1,
                title: "detailInfo.groups.basketball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter1,
                title: "detailInfo.groups.basketball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter1,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter1",
                quotationIds: [
                    [1014, 1015]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter2,
                title: "detailInfo.groups.basketball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter2,
                title: "detailInfo.groups.basketball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter2,
                title: "detailInfo.groups.basketball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter2,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter2",
                quotationIds: [
                    [1016, 1017]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter3,
                title: "detailInfo.groups.basketball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter3,
                title: "detailInfo.groups.basketball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter3,
                title: "detailInfo.groups.basketball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter3,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter3",
                quotationIds: [
                    [1018, 1019]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Quarter4,
                title: "detailInfo.groups.basketball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter4,
                title: "detailInfo.groups.basketball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter4,
                title: "detailInfo.groups.basketball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter4,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter4",
                quotationIds: [
                    [1020, 1021]
                ]
            }
        ]
    });
    result.push({
        id: 6,
        title: "detailInfo.tabs.basketball.halves",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Half1,
                title: "detailInfo.groups.basketball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf1,
                title: "detailInfo.groups.basketball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf1,
                title: "detailInfo.groups.basketball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchHalf1,
                title: "detailInfo.groups.basketball.evenOddMatchHalf1",
                quotationIds: [
                    [1012, 1013]
                ]
            },
            {
                id: EDetailRow.basketball_x1x2Half2,
                title: "detailInfo.groups.basketball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf2,
                title: "detailInfo.groups.basketball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf2,
                title: "detailInfo.groups.basketball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Quarter1,
                title: "detailInfo.groups.basketball.x1x2Quarter1",
                quotationIds: [
                    [680, 681, 682]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter1,
                title: "detailInfo.groups.basketball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter1,
                title: "detailInfo.groups.basketball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter1,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter1",
                quotationIds: [
                    [1014, 1015]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapQuarter1,
                title: "detailInfo.groups.basketball.handicapQuarter1",
                quotationIds: [
                    [254, 255]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalQuarter1,
                title: "detailInfo.groups.basketball.totalQuarter1",
                quotationIds: [
                    [256, 257]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter1",
                quotationIds: [
                    [873, 874]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter1",
                quotationIds: [
                    [875, 876]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter1,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter1",
                quotationIds: [
                    [1014, 1015]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Quarter2,
                title: "detailInfo.groups.basketball.x1x2Quarter2",
                quotationIds: [
                    [683, 684, 685]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter2,
                title: "detailInfo.groups.basketball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter2,
                title: "detailInfo.groups.basketball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter2,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter2",
                quotationIds: [
                    [1016, 1017]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapQuarter2,
                title: "detailInfo.groups.basketball.handicapQuarter2",
                quotationIds: [
                    [258, 259]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalQuarter2,
                title: "detailInfo.groups.basketball.totalQuarter2",
                quotationIds: [
                    [260, 261]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter2",
                quotationIds: [
                    [877, 878]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter2",
                quotationIds: [
                    [879, 880]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter2,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter2",
                quotationIds: [
                    [1016, 1017]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Quarter3,
                title: "detailInfo.groups.basketball.x1x2Quarter3",
                quotationIds: [
                    [686, 687, 688]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter3,
                title: "detailInfo.groups.basketball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter3,
                title: "detailInfo.groups.basketball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter3,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter3",
                quotationIds: [
                    [1018, 1019]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapQuarter3,
                title: "detailInfo.groups.basketball.handicapQuarter3",
                quotationIds: [
                    [262, 263]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalQuarter3,
                title: "detailInfo.groups.basketball.totalQuarter3",
                quotationIds: [
                    [264, 265]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter3",
                quotationIds: [
                    [881, 882]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter3,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter3",
                quotationIds: [
                    [883, 884]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter3,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter3",
                quotationIds: [
                    [1018, 1019]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Quarter4,
                title: "detailInfo.groups.basketball.x1x2Quarter4",
                quotationIds: [
                    [689, 690, 691]
                ]
            },
            {
                id: EDetailRow.basketball_totalQuarter4,
                title: "detailInfo.groups.basketball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.basketball_handicapQuarter4,
                title: "detailInfo.groups.basketball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter4,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter4",
                quotationIds: [
                    [1020, 1021]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapQuarter4,
                title: "detailInfo.groups.basketball.handicapQuarter4",
                quotationIds: [
                    [266, 267]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalQuarter4,
                title: "detailInfo.groups.basketball.totalQuarter4",
                quotationIds: [
                    [268, 269]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam1Quarter4",
                quotationIds: [
                    [885, 886]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Quarter4,
                title: "detailInfo.groups.basketball.individualTotalTeam2Quarter4",
                quotationIds: [
                    [887, 888]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchQuarter4,
                title: "detailInfo.groups.basketball.evenOddMatchQuarter4",
                quotationIds: [
                    [1020, 1021]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballHalf1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Half1,
                title: "detailInfo.groups.basketball.x1x2Half1",
                quotationIds: [
                    [827, 828, 829]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf1,
                title: "detailInfo.groups.basketball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf1,
                title: "detailInfo.groups.basketball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchHalf1,
                title: "detailInfo.groups.basketball.evenOddMatchHalf1",
                quotationIds: [
                    [1012, 1013]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapHalf1,
                title: "detailInfo.groups.basketball.handicapHalf1",
                quotationIds: [
                    [833, 834]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalHalf1,
                title: "detailInfo.groups.basketball.totalHalf1",
                quotationIds: [
                    [837, 838]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half1",
                quotationIds: [
                    [865, 866]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half1,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half1",
                quotationIds: [
                    [867, 868]
                ]
            },
            {
                id: EDetailRow.basketball_evenOddMatchHalf1,
                title: "detailInfo.groups.basketball.evenOddMatchHalf1",
                quotationIds: [
                    [1012, 1013]
                ]
            }
        ]
    });
    return result;
}

export function getBasketballHalf2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.basketball.all",
        rows: [
            {
                id: EDetailRow.basketball_x1x2Half2,
                title: "detailInfo.groups.basketball.x1x2Half2",
                quotationIds: [
                    [830, 831, 832]
                ]
            },
            {
                id: EDetailRow.basketball_totalHalf2,
                title: "detailInfo.groups.basketball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.basketball_handicapHalf2,
                title: "detailInfo.groups.basketball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.basketball.handicaps",
        rows: [
            {
                id: EDetailRow.basketball_handicapHalf2,
                title: "detailInfo.groups.basketball.handicapHalf2",
                quotationIds: [
                    [835, 836]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.basketball.totals",
        rows: [
            {
                id: EDetailRow.basketball_totalHalf2,
                title: "detailInfo.groups.basketball.totalHalf2",
                quotationIds: [
                    [839, 840]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam1Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam1Half2",
                quotationIds: [
                    [869, 870]
                ]
            },
            {
                id: EDetailRow.basketball_individualTotalTeam2Half2,
                title: "detailInfo.groups.basketball.individualTotalTeam2Half2",
                quotationIds: [
                    [871, 872]
                ]
            }
        ]
    });
    return result;
}
