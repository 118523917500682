import { SetupContext } from "vue";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useVoucherDepositCommon } from "@sportaq/vue/components/web/dialogs/voucher-deposit-common";

export default {
    name: "VoucherDepositDialog",
    emits: [CLOSE_EVENT],
    setup (props:[], context: SetupContext) {
        return useVoucherDepositCommon(context);
    }
};
