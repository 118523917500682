import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";
import { UpdateProfileData } from "@sportaq/model/web/update-profile-data";

export class AUs14UpdateProfileMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.US.1.4";

    constructor (readonly data: UpdateProfileData) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        if (this.data.firstName) {
            request.addChildWithValue(actionElement, "FirstName", this.data.firstName);
        }
        if (this.data.lastName) {
            request.addChildWithValue(actionElement, "LastName", this.data.lastName);
        }
        if (this.data.city) {
            request.addChildWithValue(actionElement, "CityText", this.data.city);
        }
        if (this.data.identificationCode) {
            request.addChildWithValue(actionElement, "IdentificationCode", this.data.identificationCode);
        }
        if (this.data.birthday) {
            request.addChildWithValue(actionElement, "Birthday", this.data.birthday.toString());
        }
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode === "1150") {
            return;
        }
        throw new LocalizedError(`errors.mtl.aUs14.code${serverCode}`);
    }
}
