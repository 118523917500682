import { WebDesktopStore } from "@sportaq/vuex/modules/web/web-module";

export interface AuthorizationService {
    isUserAuthorize (): boolean;
}

export class NopAuthorizationService implements AuthorizationService {
    isUserAuthorize (): boolean {
        return true;
    }
}

export class WebAuthorizationService implements AuthorizationService {
    constructor (private readonly store: WebDesktopStore) {
    }

    isUserAuthorize (): boolean {
        if (!this.store.user) {
            this.store.loginDialogVisible = true;
            return false;
        }
        return true;
    }
}
