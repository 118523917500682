import { binarySearchIndex } from "@sportaq/common/utils/arrays";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";
import { CountryKey } from "@sportaq/model/types/types";
import { IdItem } from "@sportaq/common/types/types";
import { ESportType } from "@sportaq/common/enums/sport-type";
import EDetailRow from "@sportaq/common/enums/detail-row";

export namespace ScoreboardVuexGetters {
    export function isExpandedPositionId (state: ScoreboardVuex.ScoreboardStoreState): (positionId: number) => boolean {
        return (positionId) => {
            const index = binarySearchIndex(state.inverseExpandedPositionIds, positionId, (a, b) => a - b);
            return (index >= 0 ? !state.allExpanded : state.allExpanded);
        };
    }

    export function isFavourite (state: ScoreboardVuex.ScoreboardStoreState): (positionId: number) => boolean {
        return (positionId) => {
            return state.periodsController.isFavourite(positionId);
        };
    }

    export function isCountryFavourite (state: ScoreboardVuex.ScoreboardStoreState): (item: CountryKey) => boolean {
        return item => {
            return state.periodsController.isCountryFavourite(item);
        };
    }

    export function isPartitionFavourite (state: ScoreboardVuex.ScoreboardStoreState): (country: CountryKey, item: IdItem) => boolean {
        return (country, item) => {
            return state.periodsController.isPartitionFavourite(country, item);
        };
    }

    export function isDetailRowPinned (state: ScoreboardVuex.ScoreboardStoreState): (sportType: ESportType, id: EDetailRow) => boolean {
        return (sportType, id) => {
            const detailRows = state.pinnedDetailRows.get(sportType);
            if (detailRows) {
                return binarySearchIndex(detailRows, id, (a, b) => a - b) >= 0;
            }
            return false;
        };
    }

    export function getDetailRowPinned (state: ScoreboardVuex.ScoreboardStoreState): (sportType: ESportType) => EDetailRow[] {
        return sportType => state.pinnedDetailRows.get(sportType) ?? [];
    }
}
