<template>
    <casino-top-menu @select="selectTopMenu" :forceOpenSelectedTabByClick="forceOpenSelectedTabByClick"/>
    <div v-if="currentGame" class="main-window">
        <game-frame :game-info="currentGame" :is-desktop="false" @errorStartingGame="closeGame">
            <template v-slot:closeButton>
                <button id="closeBtn" @click.prevent="closeGame" class="frame-close"></button>
            </template>
        </game-frame>
    </div>
    <div v-else-if="!isDirectLink" class="main-window">
        <games-body :showDemo="false" :gamesInRow="3" :lineHeightInVW="39.5"
                    @startGame="startGame" @startDemo="startDemo"
                    dataCy="casino-games-pager" v-if="!currentGame">
            <template v-slot:preview="params">
                <game-preview :item="params.item" :close="params.close" :showDemo="true"
                              @startGame="startGame" @startDemo="startDemo"/>
            </template>
        </games-body>
    </div>
    <div v-else class="main-window">
        <div v-if="directLinkGame">
            <game-preview :item="directLinkGame"  :close="directLinkClose" :showDemo="true"
                          @startGame="startGame" @startDemo="startDemo"/>
        </div>
        <div class="preloader" v-else data-cy="preloader">
            <div class="preloader-el"></div>
        </div>
    </div>
</template>

<script src="./mobile-casino-games-component.ts"/>
