import EventType, { getEventTypeName } from "@sportaq/common/enums/event-type";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import {
    EventStorage,
    UpdateEventStorageResult
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-storage";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { EventListener } from "@sportaq/common/types/event-listener";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { CountryKey } from "@sportaq/model/types/types";
import { CountryCounter } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/misc/country-counter";
import { MiscellaneousParamsProvider } from "@sportaq/common/utils/miscellaneous-params";

type UpdateListener = (eventSupplier: EventSupplier, eventType: EventType, list: EventDiffsResponse[], nonConfirmedPositionIds: number[], settings: PointSettings) => Promise<void>;
type CountriesOrPartitionsRemovedListener = (countries: CountryKey[], partitionIds: number[]) => void;

export interface EventSupplier {
    getEvent (eventType: EventType, positionId: number): BettingEvent;

    getOptionalEvent (eventType: EventType, positionId: number): BettingEvent | undefined;

    forEach (eventType: EventType, callback: (event: BettingEvent) => boolean): void;

    forEachBySportType (eventType: EventType, sportType: ESportType, callback: (event: BettingEvent) => boolean): void;

    update (eventType: EventType, diffList: EventDiffsResponse[], clockProvider: ClockProvider, settings: PointSettings): Promise<void>;

    disconnect (): void;

    getCountBySportType (eventType: EventType): Map<number, number>;

    getCountByCountry (eventType: EventType, sportType: ESportType): CountryCounter[];

    addUpdateListener (listener: UpdateListener): void;

    removeUpdateListener (listener: UpdateListener): void;

    addCountriesOrPartitionsRemovedListener (listener: CountriesOrPartitionsRemovedListener): void;

    removeCountriesOrPartitionsRemovedListener (listener: CountriesOrPartitionsRemovedListener): void;

    isColdDataReceived (eventType: EventType): boolean;

    getTotalEvents (): TotalEvents;
}

class EventSupplierImpl implements EventSupplier {
    private readonly preMatch: EventStorage;
    private readonly live: EventStorage;
    private readonly updateListener: EventListener<UpdateListener> = new EventListener<UpdateListener>();
    private readonly countriesOrPartitionsRemovedListener: EventListener<CountriesOrPartitionsRemovedListener> = new EventListener();

    constructor (clockProvider: ClockProvider, miscellaneousParamsProvider: MiscellaneousParamsProvider) {
        this.preMatch = new EventStorage(EventType.PRE_MATCH, clockProvider, miscellaneousParamsProvider.dateTimeFormatter);
        this.live = new EventStorage(EventType.LIVE, clockProvider, miscellaneousParamsProvider.dateTimeFormatter);
    }

    getEvent (eventType: EventType, positionId: number): BettingEvent {
        const result = this.getOptionalEvent(eventType, positionId);
        if (result) {
            return result;
        } else {
            throw new Error(`${eventType === EventType.LIVE ? "Live" : "Prematch"} event with positionId: ${positionId} not found in ${getEventTypeName(eventType)} betting store`);
        }
    }

    getOptionalEvent (eventType: EventType, positionId: number): BettingEvent | undefined {
        return this.getProvider(eventType).getEvent(positionId);
    }

    forEach (eventType: EventType, callback: (event: BettingEvent) => boolean) {
        const provider = this.getProvider(eventType);
        for (const value of provider.iterator()) {
            if (!callback(value)) {
                break;
            }
        }
    }

    forEachBySportType (eventType: EventType, sportType: ESportType, callback: (event: BettingEvent) => boolean) {
        const provider = this.getProvider(eventType);
        for (const value of provider.sortedEventsIterator(sportType)) {
            if (!callback(value)) {
                break;
            }
        }
    }

    async update (eventType: EventType, diffList: EventDiffsResponse[], clockProvider: ClockProvider, settings: PointSettings): Promise<void> {
        let result: UpdateEventStorageResult;
        switch (eventType) {
            case EventType.PRE_MATCH:
                result = this.preMatch.updateEventStorage(diffList, settings);
                break;
            case EventType.LIVE:
                result = this.live.updateEventStorage(diffList, settings);
                break;
        }
        for (const handler of this.updateListener.handlers) {
            await handler(this, eventType, diffList, result.nonConfirmedPositionIds, settings);
        }
        if (result.removedCountries.length > 0 || result.removePartitions.length > 0) {
            for (const handler of this.countriesOrPartitionsRemovedListener.handlers) {
                handler(result.removedCountries, result.removePartitions);
            }
        }
    }

    getCountBySportType (eventType: EventType): Map<number, number> {
        return this.getProvider(eventType).getCountBySportType();
    }

    getCountByCountry (eventType: EventType, sportType: ESportType): CountryCounter[] {
        return this.getProvider(eventType).getCountByCountry(sportType);
    }

    private getProvider (eventType: EventType): EventStorage {
        switch (eventType) {
            case EventType.PRE_MATCH: {
                return this.preMatch;
            }
            case EventType.LIVE: {
                return this.live;
            }
        }
    }

    addUpdateListener (listener: UpdateListener): void {
        this.updateListener.addListener(listener);
    }

    removeUpdateListener (listener: UpdateListener): void {
        this.updateListener.removeListener(listener);
    }

    addCountriesOrPartitionsRemovedListener (listener: CountriesOrPartitionsRemovedListener): void {
        this.countriesOrPartitionsRemovedListener.addListener(listener);
    }

    removeCountriesOrPartitionsRemovedListener (listener: CountriesOrPartitionsRemovedListener): void {
        this.countriesOrPartitionsRemovedListener.removeListener(listener);
    }

    disconnect (): void {
        this.preMatch.disconnect();
        this.live.disconnect();
    }

    isColdDataReceived (eventType: EventType): boolean {
        const provider = this.getProvider(eventType);
        return provider.coldDataReceived;
    }

    getTotalEvents (): TotalEvents {
        const preMatchTotal = this.getProvider(EventType.PRE_MATCH).totalEvents;
        const liveTotal = this.getProvider(EventType.LIVE).totalEvents;
        return {
            preMatch: preMatchTotal,
            live: liveTotal
        };
    }
}

const eventSupplierSymbol = Symbol("Betting Event Supplier");

export function provideEventSupplier (clockProvider: ClockProvider, miscellaneousParamsProvider: MiscellaneousParamsProvider): EventSupplier {
    const eventSupplier = new EventSupplierImpl(clockProvider, miscellaneousParamsProvider);
    provide(eventSupplierSymbol, eventSupplier);
    return eventSupplier;
}

export function useEventSupplier (): EventSupplier {
    return use(eventSupplierSymbol);
}

export interface TotalEvents {
    preMatch: number;
    live: number;
}
