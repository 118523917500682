<template>
    <div class="popup active" id="popup6">
        <div class="overlay" @click="close"></div>
        <div class="popup-container" :class="{'confirmed__message' : amountConfirmed}">
            <div class="popup-title">{{$t("webdesktop.deposit.amountInput.caption", {type: $t("webdesktop.deposit."+depositMethod)})}}</div>
            <div class="popup-content">
                <div class="text" v-if="amountConfirmed">
                    {{$t("webdesktop.deposit.amountInput.dialogText")}}
                    <b>{{ amountRef +  selectedCurrencyName}}</b>.
                </div>
                <template v-else>
                  <div class="form-line">
                      <label>{{$t("webdesktop.deposit.amountInput.amount")}}</label>
                      <input type="number" v-model.number="amountRef" class="text-center">
                  </div>
                  <div class="form-line"  v-if="isCurrencySelectEnabled">
                    <label>{{$t("webdesktop.deposit.amountInput.currency")}}</label>
                    <div class="select-wrapper">
                      <select v-model="currency">
                        <option v-for="c of currencyList" :key="c.currencyId" :value="c.currencyId">{{c.currencyName}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-line display-flex">
                    <label>{{$t("mobile.depositView.min")}} {{minAmount}}{{selectedCurrencyName}}</label>
                  </div>
                </template>
                <div class="form-line">
                  <button class="button button-primary smaller" @click.prevent="submit" :class="{disabled: !amountRef || amountRef < 0}">{{$t("common.forms.continue")}}</button>
                </div>
                <div class="form-line">
                    <button class="button button-secondary smaller" @click.prevent="close">{{$t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./amount-input-dialog-component.ts" />
