<template>
    <DateFilter @dateChanged="dateChanged"
                :itemCount="cardsRef.length"
                @itemsPerPageChanged="itemsPerPageChanged"
                @currentPageChanged="currentPageChanged"
    ></DateFilter>
  <div class="deposit-block" v-for="card of cardsPaged" :key="card.id">
    <div class="deposit-block-head">
      <label class="label">{{ $t(card.isPaid ? 'cashier.monitoring.table.ispaid' : card.isCalculated ? 'cashier.monitoring.table.iscalculated' : 'cashier.monitoring.table.isaccepted')}}</label>
      <div class="info">
        <div class="date">{{$d(card.acceptServerTime, "long")}}</div>
        <div class="num">{{card.serial}} </div>
      </div>
    </div>
    <div class="deposit-block-body">
      <div class="info" v-for="(item) in card.items" :key="item">
        <div class="info-text">
          <div class="name">{{item.trackName}}</div>
          <div class="title">{{item.horses}}; {{item.gameType}}</div>
          <div class="line">{{$t("webdesktop.cardsTable.amountColumn")}} {{item.sumBet.toFixed(2)}}</div>
          <div class="line">{{$t("webdesktop.cardsTable.winColumn")}} {{item.sumWin.toFixed(2)}}</div>
          <div class="line" :class="{ 'color-success': item.status === HorseItemStatus.Winner, 'color-error': item.status === HorseItemStatus.Lost}">
            {{ $t("cashier.monitoring.table.horse.status"+item.status) }}
          </div>
        </div>
      </div>
    </div>
    <div class="deposit-totals">
      <div class="head">
        <div class="item">{{$t("webdesktop.cardsTable.amountColumn")}}</div>
        <div class="item">{{$t("webdesktop.cardsTable.winColumn")}}</div>
      </div>
      <div class="body">
        <div class="item">{{card.sumBet.toFixed(2)}}</div>
        <div class="item">{{card.sumWin.toFixed(2)}}</div>
      </div>
    </div>
  </div>
  <div class="line">
    <button class="button button-primary btn-load-more" @click.prevent="currentPage ++" v-if="currentPage < totalPages">
      {{$t("common.forms.loadMore")}}
    </button>
  </div>
  <div class="preloader" v-if="requestPending">
    <div class="preloader-el"></div>
  </div>
</template>

<script src="./race-stakes-component.ts" />
