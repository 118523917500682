import { useWebRestService } from "@sportaq/services/web";
import { Card } from "@sportaq/model/cashier/card";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Ref } from "@sportaq/common/types/types";
import { WebDesktopRestService } from "@sportaq/services/web/rest/web-desktop-rest-service";

type GetCardsCallback = (cardRef: Ref<Card[] | undefined>, restService: WebDesktopRestService) => void;

export function useStakesInPlayComponentCommon (callback: GetCardsCallback) {
    const restService = useWebRestService();
    const cardsRef = ref<Card[]>();
    const i18n = useI18n();
    const store = useBalanceStore();
    const totalStake = computed(() => {
        let result = 0;
        if (cardsRef.value) {
            for (const c of cardsRef.value) {
                result = result + c.sumStake;
            }
        }
        return result;
    });
    const currency = computed(() => {
        return store.currency;
    });

    onMounted(() => callback(cardsRef, restService));

    return {
        cardsRef,
        i18n,
        totalStake,
        currency
    };
}
