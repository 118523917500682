import { DiffType } from "@sportaq/model/betting/events/event";
import { appLogger } from "@sportaq/common/utils/logger";
import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

export class CasinoGamesStorage {
    private readonly virtualGames = new Map<string, CasinoGame>();
    private readonly casinoGames = new Map<string, CasinoGame>();
    private readonly providerCountMap = new Map<number, GameProviderCounter>();

    private confirmedAfterDisconnectIds: string[] = [];
    private needConfirmation = false;
    private _coldDataReceived = false;

    get (id: string): CasinoGame | undefined {
        let game = this.virtualGames.get(id);
        if (!game) {
            game = this.casinoGames.get(id);
        }
        return game;
    }

    getWithType (gameType: ECasinoGameType, id: string) {
        return this.resolveStorage(gameType).get(id);
    }

    // Return non confirmed after reconnect position ids
    updateStorage (list: CasinoGameDiffsResponse[]): UpdateCasinoGamesStorageResult {
        const updateCasinoGamesStorageResult = new UpdateCasinoGamesStorageResult();
        for (const diff of list) {
            if (diff.diffType === DiffType.COLD_HOT_DELIMITER) {
                this.handleColdHotDelimiter(updateCasinoGamesStorageResult);
                continue;
            }

            const map = this.resolveStorage(diff.virtualGame ? ECasinoGameType.Virtual : ECasinoGameType.Casino);

            const oldGame = map.get(diff.id);
            if (oldGame) {
                if (oldGame.version === diff.version) {
                    continue; // Game doesn't change after reconnect
                } else if (oldGame.version > diff.version) {
                    appLogger.logger.warn(`The stored game ${oldGame.id} has a newer version [${oldGame.version}] than the diff message [${diff.version}]. The diff message has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                    continue;
                }
            }
            try {
                switch (diff.diffType) {
                    case DiffType.NEW: {
                        if (oldGame) {
                            this.deleteEvent(map, oldGame);
                        }
                        const newGame = CasinoGame.newFromResponse(diff);
                        map.set(newGame.id, newGame);
                        this.incrementProviderCounter(newGame);

                        if (this.needConfirmation) {
                            this.confirmedAfterDisconnectIds.push(newGame.id);
                        }
                        break;
                    }
                    case DiffType.UPDATE: {
                        if (!oldGame) {
                            appLogger.logger.warn(`The game ${diff.id} doesn't exist in the games store. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                            continue;
                        }
                        oldGame.update(diff);
                        break;
                    }
                    case DiffType.DELETE: {
                        if (!oldGame) {
                            appLogger.logger.warn(`The game ${diff.id} doesn't exist in the games store. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                            continue;
                        }
                        this.deleteEvent(map, oldGame);
                        break;
                    }
                }
            } catch (e) {
                appLogger.logger.error(`Error update game ${diff.id}:\nDiff:\n${JSON.stringify(diff)}`, e as Error);
            }
        }
        return updateCasinoGamesStorageResult;
    }

    getProviderCounter (gameType: ECasinoGameType, provider: number): number {
        const counter = this.providerCountMap.get(provider);
        return counter ? (gameType === ECasinoGameType.Virtual ? counter.virtualGamesCount : counter.casinoGamesCount) : 0;
    }

    getItems (gameType: ECasinoGameType): IterableIterator<CasinoGame> {
        return this.resolveStorage(gameType).values();
    }

    private decrementProviderCounter (game: CasinoGame) {
        const counter = this.providerCountMap.get(game.provider);
        if (counter) {
            switch (game.gameType) {
                case ECasinoGameType.Virtual: {
                    counter.virtualGamesCount = Math.max(counter.virtualGamesCount - 1, 0);
                    break;
                }
                case ECasinoGameType.Casino: {
                    counter.casinoGamesCount = Math.max(counter.casinoGamesCount - 1, 0);
                    break;
                }
            }
        }
    }

    private incrementProviderCounter (game: CasinoGame) {
        let counter = this.providerCountMap.get(game.provider);
        if (!counter) {
            counter = {
                casinoGamesCount: 0,
                virtualGamesCount: 0
            };
            this.providerCountMap.set(game.provider, counter);
        }
        switch (game.gameType) {
            case ECasinoGameType.Virtual: {
                counter.virtualGamesCount = counter.virtualGamesCount + 1;
                break;
            }
            case ECasinoGameType.Casino: {
                counter.casinoGamesCount = counter.casinoGamesCount + 1;
                break;
            }
        }
    }

    disconnect () {
        this.confirmedAfterDisconnectIds = [];
        this.needConfirmation = true;
        this._coldDataReceived = false;
    }

    get coldDataReceived (): boolean {
        return this._coldDataReceived;
    }

    private handleColdHotDelimiter (updateCasinoGamesStorageResult: UpdateCasinoGamesStorageResult) {
        if (this.needConfirmation) {
            const maps = [this.virtualGames, this.casinoGames];
            const gamesForDelete: CasinoGame[] = [];
            for (const map of maps) {
                for (const item of map.values()) {
                    if (!this.confirmedAfterDisconnectIds.includes(item.id)) {
                        gamesForDelete.push(item);
                        updateCasinoGamesStorageResult.nonConfirmedIds.push(item.id);
                    }
                }
                for (const game of gamesForDelete) {
                    this.deleteEvent(map, game);
                }
            }
            this.needConfirmation = false;
            this.confirmedAfterDisconnectIds = [];
        }
        this._coldDataReceived = true;
        eventBus.emit(Events.COLD_HOT_DELIMITER_GAMES_RECEIVED);
    }

    private deleteEvent (map: Map<string, CasinoGame>, game: CasinoGame) {
        if (map.delete(game.id)) {
            this.decrementProviderCounter(game);
        }
    }

    private resolveStorage (gameType: ECasinoGameType): Map<string, CasinoGame> {
        switch (gameType) {
            case ECasinoGameType.Virtual: {
                return this.virtualGames;
            }
            case ECasinoGameType.Casino: {
                return this.casinoGames;
            }
            default : {
                throw new Error(`GameType ${gameType} isn't supported`);
            }
        }
    }
}

export class UpdateCasinoGamesStorageResult {
    readonly nonConfirmedIds: string[] = [];
}

interface GameProviderCounter {
    virtualGamesCount: number;
    casinoGamesCount: number;
}
