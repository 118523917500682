const forbiddenCountries = ["US", "AW", "BQ", "CW", "FR", "NL", "SX", "SG", "IR", "KP", "AL", "BB", "BW", "KH",
    "JM", "HT", "MU", "MM", "NI", "PK", "PA", "SS", "SY", "YE", "ZW"];
const googleAddresses = ["192.178.5", "34.100.182", "34.101.50", "34.118.254", "34.118.66", "34.126.178", "34.146.150", "34.147.110", "34.151.74", "34.152.50", "34.154.114", "34.155.98", "34.165.18", "34.175.160", "34.176.130", "34.22.85", "34.64.82", "34.65.242", "34.80.50", "34.88.194", "34.89.10", "34.89.198", "34.96.162", "35.247.243", "66.249.64", "66.249.65", "66.249.65", "66.249.66", "66.249.68", "66.249.69", "66.249.70", "66.249.71", "66.249.72", "66.249.73", "66.249.74", "66.249.75", "66.249.76", "66.249.77", "66.249.78", "66.249.79"];

export function isForbiddenCountry (country: string, address: string): boolean {
    const forbidden = forbiddenCountries.includes(country);
    if (forbidden) {
        for (const ga of googleAddresses) {
            if (address.startsWith(ga)) {
                return false;
            }
        }
        return true;
    }
    return false;
}
