import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";

export class ACs12CloseGameFrameRequestHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.CS.1.2";

    constructor (private gameId: string, private withTransferMoney: boolean) {
        super();
    }

    buildRequest (request: XmlRequest): void {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CasinoGameMark", this.gameId);
        if (this.withTransferMoney) {
            request.addChildWithValue(actionElement, "withTransferMoney", "1");
        }
    }

    protected parseMessageBody (body: Element) {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "8120") {
            throw new LocalizedError(`errors.mtl.aCs12.code${serverCode}`);
        }
    }
}
