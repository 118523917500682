<template>
    <div class="popup active" id="voucher-input-dialog">
        <div class="popup-close" @click.prevent="close"></div>
        <div class="popup-container">
            <div class="popup-title">{{$t("webdesktop.deposit.voucher.caption")}}</div>
            <div class="popup-content">
                <div class="text" v-if="dialogState === 4">
                    {{$t("webdesktop.deposit.voucher.success",
                    {
                        code: voucherNumber,
                        amount: voucherRef.amount +
                            (voucherRef.currencyId ? getCurrencyName(voucherRef.currencyId) : "")
                    })}}
                </div>
                <div class="text" v-if="dialogState === 2">
                    {{$t("webdesktop.deposit.voucher.found",
                    {
                        code: voucherNumber,
                        amount: voucherRef.amount +
                            (voucherRef.currencyId ? getCurrencyName(voucherRef.currencyId) : "")
                    })}}
                </div>
                <div class="form-line" v-if="dialogState === 1">
                    <label for="voucher-input" class="nowrap">{{$t("webdesktop.deposit.voucher.numberLabel")}}</label>
                    <input type="text" id="voucher-input" v-model="voucherNumber">
                </div>
                <div class="form-line">
                    <button href="" class="button button-primary" @click.prevent="submit" :class="{disabled: !voucherNumber || voucherNumber.toString().length !== 12 || requestPending}">{{$t("common.forms.continue")}}</button>
                </div>
                <div class="form-line">
                    <button class="button button-secondary" @click.prevent="close" v-if="dialogState === 1 || dialogState === 2">{{$t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./voucher-deposit-dialog-component.ts" />
