import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild, getValue
} from "@sportaq/common/utils/xml-helper-functions";
import { OneClickRegistrationData } from "@sportaq/model/web/one-click-registration-data";
import { AuthorizationData } from "@sportaq/model/web/authorization-data";

export class AUs110OneClickRegistrationMessageHandler extends AbstractMessageHandler<AuthorizationData> {
    protected readonly requestType: string = "A.US.1.10";
    constructor (readonly registrationData: OneClickRegistrationData) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "CountryId", this.registrationData.country.toString());
        request.addChildWithValue(actionElement, "ReferralCode", this.registrationData.referralCode);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): AuthorizationData {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1100") {
            const userElement = getRequiredChild(action, "User");
            return {
                login: getValue(getRequiredChild(userElement, "UserNumber")),
                password: getValue(getRequiredChild(userElement, "Password"))
            };
        }
        throw new LocalizedError(`errors.mtl.aUs11.code${serverCode}`);
    }
}
