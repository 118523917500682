import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { BaseCardRequest } from "@sportaq/services/rest/messages/bet-slip/base/base-card-request";
import { dateToMtlStr } from "@sportaq/common/utils/time-utils";

export class QSt100CardsByDate extends BaseCardRequest {
    protected readonly requestType: string = "Q.ST.10.0";

    constructor (
        readonly locale: string, readonly startDate: Date, readonly endDate: Date, readonly maxCoef: number, readonly onlyCalculated: boolean
    ) {
        super(locale, maxCoef);
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter");
        request.addChildWithValue(sessionFilterElement, "User", "true");
        const stakeFilterElement = request.addChild(filterElement, "StakeFilter", {
            logic: "AND"
        });
        if (this.onlyCalculated) {
            request.addChildWithValue(stakeFilterElement, "CalcStatusCard", "IsCalc");
        }
        const stakePeriodElement = request.addChildWithValue(stakeFilterElement, "StakePeriod");
        request.addChildWithValue(stakePeriodElement, "StartTime", dateToMtlStr(this.startDate));
        request.addChildWithValue(stakePeriodElement, "FinishTime", dateToMtlStr(this.endDate));
        this.appendSelector(request, actionElement);
    }
}
