enum EventType {
    PRE_MATCH = 1,
    LIVE = 2
}

export function getEventTypeName (eventType: EventType): string {
    switch (eventType) {
        case EventType.LIVE:
            return "live";
        case EventType.PRE_MATCH:
            return "sport";
    }
}

export function getEventTypeByName (eventType: string): EventType {
    switch (eventType) {
        case "sport":
            return EventType.PRE_MATCH;
        case "live":
            return EventType.LIVE;
    }
    return EventType.PRE_MATCH;
}

export default EventType;
