// Returns scoreboard title menu content by sport type
import { ESportType } from "@sportaq/common/enums/sport-type";
import QuotationView from "@sportaq/common/enums/quotation-views";
import { Equable } from "@sportaq/common/types/interfaces";

export class ScoreboardHeadMenuItem implements Equable<ScoreboardHeadMenuItem> {
    constructor (readonly localizationKey: string, readonly view: QuotationView) {
    }

    equals (o: ScoreboardHeadMenuItem): boolean {
        return this.localizationKey === o.localizationKey && this.view === o.view;
    }
}

const item1x2 = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.item1x2", QuotationView._1x2);
const item12 = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.item12", QuotationView._1x2);
const item1x12x2 = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.item1x12x2", QuotationView._1x12x2);
const itemHandicap = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemHandicap", QuotationView.HANDICAP);
const itemHandicapWIthOT = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemHandicap", QuotationView.HANDICAP);
const itemTotalGoals = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotalGoals", QuotationView.TOTAL);
const itemLocalTotalGoals = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemLocalTotalGoals", QuotationView.LOCAL_TOTAL);
const itemVisitTotalGoals = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemVisitTotalGoals", QuotationView.VISIT_TOTAL);
const itemBothWillScore = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemBothWillScore", QuotationView.BOTH_WILL_SCORE);
const itemTotalPoints = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotalPoints", QuotationView.TOTAL);
const itemLocalTotalPoints = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemLocalTotalPoints", QuotationView.LOCAL_TOTAL);
const itemVisitTotalPoints = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemVisitTotalPoints", QuotationView.VISIT_TOTAL);
const itemTotalGames = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotalGames", QuotationView.TOTAL);
const itemLocalTotalGames = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemLocalTotalGames", QuotationView.VISIT_TOTAL);
const itemVisitTotalGames = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemVisitTotalGames", QuotationView.LOCAL_TOTAL);
const itemTotalRuns = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotalRuns", QuotationView.TOTAL);
const itemTotal = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotal", QuotationView.TOTAL);
const itemMatchTotal = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemMatchTotal", QuotationView.TOTAL);

const item1x2xFavourites = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.item1x2xFavourites", QuotationView._1x2);
const itemTotalFavourites = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemTotalFavourites", QuotationView.TOTAL);
const itemHandicapFavourites = new ScoreboardHeadMenuItem("betting.event.scoreboardTitle.menu.itemHandicapFavourites", QuotationView.HANDICAP);

export class ScoreboardHeadQuotationTypesSelector {
    private static favourites = [item1x2xFavourites, itemTotalFavourites, itemHandicapFavourites];
    private static selector: Map<ESportType, ScoreboardHeadMenuItem[]> = new Map<ESportType, ScoreboardHeadMenuItem[]>(
        [
            [ESportType.Football, [item1x2, itemTotalGoals, itemBothWillScore, item1x12x2, itemHandicap, itemLocalTotalGoals, itemVisitTotalGoals]], // soccer
            [ESportType.Hockey, [item1x2, itemHandicap, itemTotalGoals, item1x12x2, itemLocalTotalGoals, itemVisitTotalGoals]], // hockey
            [ESportType.Volleyball, [item12, itemHandicap, itemTotalPoints, itemLocalTotalPoints, itemVisitTotalPoints]], // volleyball
            [ESportType.Basketball, [item1x2, itemHandicapWIthOT, itemTotalPoints, itemLocalTotalPoints, itemVisitTotalPoints]], // basketball
            [ESportType.Tennis, [item12, itemHandicap, itemTotalGames, itemLocalTotalGames, itemVisitTotalGames]], // tennis,
            [ESportType.TableTennis, [item12, itemHandicap, itemTotalGames, itemLocalTotalGames, itemVisitTotalGames]], // table tennis,
            [ESportType.Handball, [item1x2, itemHandicap, itemTotalGoals, item1x12x2, itemLocalTotalGoals, itemVisitTotalGoals]], // handball
            [ESportType.Baseball, [item12, itemHandicapWIthOT, itemTotalRuns]], // baseball
            [ESportType.AmericanFootball, [item12, itemHandicap, itemTotalPoints]], // american football
            [ESportType.Boxing, [item1x2, item1x12x2, itemTotal]], // boxing
            [ESportType.Rugby, [item1x2, itemHandicap, itemMatchTotal]] // rugby
        ]
    );

    static get (sportType: number): ScoreboardHeadMenuItem[] | undefined {
        return this.selector.get(sportType);
    }

    static getFavourites () {
        return this.favourites;
    }
}
