import { computed, PropType } from "vue";
import { SimpleStakeDTO } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import {
    SimpleStakeRowComponent
} from "@sportaq/vue/components/betting/bet-slip/dialogs/common/inner-stake-table/simple-stake-row/common-simple-stake-row-component";
import { BetSlipRowCommon } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useSettings } from "@sportaq/services/index";
import { getParticipantList } from "@sportaq/model/common/participants-functions";

export default {
    props: {
        stake: {
            required: true,
            type: Object as PropType<SimpleStakeDTO>
        },
        standalone: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    setup (props: SimpleStakeRowComponent.Props) {
        const settings = useSettings();
        const quotationFormat = settings.quotationFormat;
        const {
            quotationTitle,
            maxWin,
            isLive,
            eventDate,
            eventTime,
            formattedCoef
        } = SimpleStakeRowComponent.useSimpleStakeRowComponent(props);

        const fullQuotationLabel = computed(() => BetSlipRowCommon.getSimpleStakeDTOQuotationLabel(quotationFormat, props.stake));
        const participants = computed(() => getParticipantList(props.stake, props.stake.key.quotationKey));

        return {
            quotationTitle,
            fullQuotationLabel,
            maxWin,
            isLive,
            eventDate,
            eventTime,
            formattedCoef,
            participants
        };
    }
};
