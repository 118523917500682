<template>
    <div>
        <div class="deposit-payments" v-if="currencyValues">
            <deposit-view-item v-if="(isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET) ||
                            isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET_CASH)) && countryId === 62" :itemName="$t('webdesktop.deposit.pagoSeguroEfectivo')"
                               :depositButtonVisible="true"
                               :logo="'/img/payments/pago-seguro/pago-seguro.png'"
                               @openDialog="depositPagoSeguro" :itemType="ItemType.Deposit" itemId="PAGO_SEGURO">
                <div class="image">
                    <img src="/img/payments/pago-seguro/pago-seguro.png">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_MONNET, PaymentSystems.DEPOSIT_MONNET_CASH])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_TUPAY)" itemName="TUPAY"
                               :depositButtonVisible="true"
                               @openDialog="depositTupay" :itemType="ItemType.Deposit" itemId="DEPOSIT_TUPAY">
              <div class="image">
                <img src="/img/payments/tupay.svg">
              </div>
              <template v-slot:description>
                <div class="text">
                    <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_TUPAY])" :key="c.name">
                        Min: {{c}} <br>
                    </template>
                </div>
              </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET_CARD)" :itemName="$t('webdesktop.deposit.DEPOSIT_MONNET_CARD')"
                               :depositButtonVisible="true"
                               @openDialog="depositMonnetCard" :itemType="ItemType.Deposit" itemId="DEPOSIT_MONNET_CARD">
              <div class="image">
                <img src="/img/payments/visa-mastercard.png">
              </div>
              <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_MONNET_CARD])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="(isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET) ||
                            isPaymentSystemAvailable(PaymentSystems.DEPOSIT_MONNET_CASH)) && countryId !== 62" :itemName="$t('webdesktop.deposit.DEPOSIT_MONNET')"
                               :depositButtonVisible="true"
                               @openDialog="depositSafetyPay" :itemType="ItemType.Deposit" itemId="DEPOSIT_MONNET">
                <div class="image">
                    <img src="/img/payments/logo-safetypay.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_MONNET, PaymentSystems.DEPOSIT_MONNET_CASH])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.PAGOEFECTIVO_INVOICE)" :itemName="$t('webdesktop.deposit.pagoEfectivo')"
                               :depositButtonVisible="true"
                               :logo="'/img/logo-pagoefectivo.png'" @openDialog="depositPagoEfectivo" :itemType="ItemType.Deposit" itemId="PAGOEFECTIVO_INVOICE">
                <div class="image">
                    <img src="/img/payments/logo-pago.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.PAGOEFECTIVO_INVOICE])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_METHOD_YAPE)" :itemName="$t('webdesktop.deposit.yape')"
                            :depositButtonVisible="true"
                            @openDialog="openYape" :itemType="ItemType.Deposit" itemId="DEPOSIT_METHOD_YAPE">
                <div class="image">
                    <img src="/img/payments/logo-yape.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_METHOD_YAPE])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_METHOD_LUKITA)" :itemName="$t('webdesktop.deposit.lukita')"
                            :depositButtonVisible="true"
                            @openDialog="openLukita" :itemType="ItemType.Deposit" itemId="DEPOSIT_METHOD_LUKITA">
                <div class="image">
                    <img src="/img/payments/logo-lukita.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_METHOD_LUKITA])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_METHOD_TUNKI)" :itemName="$t('webdesktop.deposit.tunki')"
                            :depositButtonVisible="true"
                            @openDialog="openTunki" :itemType="ItemType.Deposit" itemId="DEPOSIT_METHOD_TUNKI">
                <div class="image">
                    <img src="/img/payments/logo-tunki.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_METHOD_TUNKI])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="partnerList.length > 0 &&
                                      isPaymentSystemAvailable(PaymentSystems.DEPOSIT_METHOD_SYSTEM_CASH)"
                               :itemName="$t('webdesktop.deposit.agents')"
                            :depositButtonVisible="false" :itemType="ItemType.Deposit"
                            itemId="DEPOSIT_METHOD_SYSTEM_CASH">
                <div class="image">
                    <img src="/img/payments/logo-betgol.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_METHOD_SYSTEM_CASH])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
            <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.DEPOSIT_METHOD_SYSTEM_CASH)" :itemName="$t('webdesktop.deposit.iPay')"
                            :depositButtonVisible="true" :itemType="ItemType.Deposit"
                            @openDialog="depositWithTicket" itemId="DEPOSIT_METHOD_SYSTEM_CASH">
                <div class="image">
                    <img src="/img/payments/logo-ipay.svg">
                </div>
                <template v-slot:description>
                    <div class="text">
                        <template v-for="c of getPaymentSystemMin([PaymentSystems.DEPOSIT_METHOD_SYSTEM_CASH])" :key="c.name">
                            Min: {{c}} <br>
                        </template>
                    </div>
                </template>
            </deposit-view-item>
        </div>
    </div>
    <amount-input-dialog v-if="amountInputDialogVisible" :currencyValues="currencyValues" :depositMethod="depositMethod" @close="onAmountInputClose" @success="onAmountConfirmed"/>
    <external-service-frame v-if="redirectUrl" @close="onExternalServiceFrameClose" :redirectUrl="redirectUrl"/>
    <voucher-deposit-dialog v-if="voucherDialogVisible" @close="onVoucherDepositDialogClose"/>
    <bonus-notification-dialog v-if="needShowBonusNotification" @close="needShowBonusNotification = false"></bonus-notification-dialog>
</template>

<script src="./deposit-view-component.ts" />
