<template>
    <div class="main-window scrollable">
        <div class="main-promo">
            <div class="main-promo-item" v-for="bonus of bonusList" :key="bonus.bonusId">
                <img :src="bonus.img" alt="" class="image" />
                <div class="info">
                    <div class="info-title">{{$t(getBonusName(bonus.bonusTypeId))}}</div>
                    <div class="info-progress-bar" v-if="bonus.status === 1">
                        <span class="info-progress-bar-inner" :style="{width: ((isNaN(bonus.sumStake) ? 0 : bonus.sumStake) * 100 / (bonus.countBeat * bonus.sumBonus)).toString() + '%'}"></span>
                    </div>
                    <div class="info-subtitle" v-if="bonus.status !== 1">
                        {{bonus.name.get(lang)}}
                    </div>
                    <div class="info-table" v-if="bonus.status === 1 && bonus.bonusTypeId !== 9">
                        <div class="info-table-cell">
                            <div class="name">{{$t("webdesktop.bonus.saldo")}}</div>
                            <div class="value">{{bonus.sumBonus.toFixed(2)}}</div>
                        </div>
                        <div class="info-table-cell">
                            <div class="name">{{$t("webdesktop.bonus.rest")}}</div>
                            <div class="value">{{(bonus.countBeat * bonus.sumBonus - (isNaN(bonus.sumStake) ? 0 : bonus.sumStake)).toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="info-table" v-if="bonus.status === 1 && bonus.bonusTypeId === 9">
                        <div class="info-table-cell">
                            <div class="name">{{$t("webdesktop.bonus.freeSpins")}}</div>
                            <div class="value">{{bonus.freeSpin.toFixed(0)}}</div>
                        </div>
                        <div class="info-table-cell">
                            <div class="name">{{$t("webdesktop.bonus.freeSpinsWinnings")}}</div>
                            <div class="value">{{bonus.sumWithdrawal.toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="info-small" v-if="bonus.status > 0">
                      {{$t("webdesktop.bonus.init")}}: {{i18n.d(bonus.initTime, 'long')}}
                    </div>
                    <div class="info-small" v-if="bonus.status === 3">
                      {{$t("webdesktop.bonus.cancelled")}}: {{i18n.d(bonus.endTime, 'long')}}
                    </div>
                    <div class="info-small" v-if="bonus.status === 2">
                      {{$t("webdesktop.bonus.expired")}}: {{i18n.d(bonus.endTime, 'long')}}
                    </div>
                    <div class="info-small" v-if="bonus.status === 1">
                      {{$t("webdesktop.bonus.endTime")}}: {{i18n.d(bonus.endTime, 'long')}}
                    </div>
                    <div class="info-foot">
                        <!-- two classes "activate" and "activated" -->
                        <a class="info-btn" href="#" v-if="bonus.gameMark" @click.prevent="openGame(bonus.gameMark)">{{$t("webdesktop.bonus.playGame")}}</a>&nbsp;
                        <a class="info-btn" href="#" v-if="(bonus.isSubscribe || bonus.canUse || !isLoggedIn) && bonus.status === 0 && !isExpressUser" :class="{ activate: bonus.isSubscribe === 0,  activated: bonus.isSubscribe === 1}" @click.prevent="subscribe(bonus)">
                            {{$t(bonus.isSubscribe === 0 ? "webdesktop.bonus.useBonusButton" : "webdesktop.bonus.cancelBonusButton")}}
                        </a>
                        <a class="info-btn" href="#" v-if="bonus.status === 1" @click.prevent="cancelBonus(bonus.bonusId)">{{$t("webdesktop.bonus.cancelBonusButton")}}</a>
                        <a class="info-link" href="#" @click.prevent="bonus.isConditionsOpen = !bonus.isConditionsOpen">{{$t("webdesktop.bonus.terms")}}</a>
                    </div>
                    <div class="terms" v-if="bonus.isConditionsOpen">
                        <div class="terms-text" v-html="bonus.description.get(lang)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <confirmation-dialog v-if="bonusToCancel" @close="cancelBonusConfirmationClosed" @submit="cancelBonusConfirmed"
                       :caption="$t('webdesktop.bonus.cancelBonusConfirmCaption')" :message="$t('webdesktop.bonus.cancelBonusConfirmMessage')">
  </confirmation-dialog>
    <login-popup-dialog v-if="isLoginDialogVisible" @close="onLoginClose" @openDialog="isResetPasswordDialogVisible = true"></login-popup-dialog>
    <reset-password-dialog v-if="isResetPasswordDialogVisible"  @close="isResetPasswordDialogVisible = false"></reset-password-dialog>
    <bonus-notification-dialog v-if="isNotificationVisible" @close="isNotificationVisible = false"></bonus-notification-dialog>
</template>

<script src="./promo-view-component.ts" />
