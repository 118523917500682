import { computed, PropType, SetupContext, watch } from "vue";

import { DetailTab, PINNED_DETAIL_TAB_ID } from "@sportaq/model/betting/view/event-details/event-details-info";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { ScoreboardSelection, EDetailsViewType } from "@sportaq/model/betting/view/scoreboard-selection";
import {
    ScoreboardDetailsComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-details/common-scoreboard-details-component";
import CompactQuotationGroup
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/compact/quotation-group/CompactQuotationGroup.vue";
import EventType from "@sportaq/common/enums/event-type";
import { ESportType, getNameBySportType } from "@sportaq/common/enums/sport-type";

const CLOSE_EVENT = "close";

interface Props {
    selection: ScoreboardSelection;
    standalone: boolean;
}

export default {
    components: {
        CompactQuotationGroup
    },
    props: {
        selection: {
            type: Object as PropType<ScoreboardSelection>,
            required: true
        },
        standalone: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: [CLOSE_EVENT],
    setup (props: Props, setupContext: SetupContext) {
        const scoreboardStore = useScoreboardStore();

        const {
            columns,
            event,
            infoLabel,
            periodInfo,
            participants,
            score,
            selectedTab,
            tabs,

            refreshBySelection,
            selectTab
        } = ScoreboardDetailsComponent.useCommonScoreboardDetailsComponent(props.selection, EDetailsViewType.Mobile, scoreboardStore.filterMenuActive, false, true);

        const isFavorite = computed(() => scoreboardStore.isFavourite(props.selection.positionId));
        const isLive = computed(() => props.selection.eventType === EventType.LIVE);
        const isBaseball = computed(() => event.value.sportTypeId === ESportType.Baseball);
        const sportName = computed(() => getNameBySportType(event.value.sportTypeId));

        watch(() => props.selection, value => {
            refreshBySelection(value, EDetailsViewType.Mobile, false);
        });

        function togglePinned () {
            refreshBySelection(props.selection, EDetailsViewType.Mobile, false);
        }

        function selectFilterTab (tab: DetailTab) {
            selectTab(tab, EDetailsViewType.Mobile, scoreboardStore.filterMenuActive, false);
        }

        function toggleFavorite () {
            scoreboardStore.toggleFavouriteItem(props.selection.eventType, props.selection.positionId);
        }

        function isPinnedRowTab (tab: DetailTab) {
            return tab.id === PINNED_DETAIL_TAB_ID;
        }

        function closeDetails () {
            setupContext.emit(CLOSE_EVENT);
        }

        return {
            event,
            tabs,
            selectedTab,
            columns,
            infoLabel,
            participants,
            score,
            periodInfo,
            isFavorite,
            isLive,
            isBaseball,
            sportName,

            selectFilterTab,
            toggleFavorite,
            isPinnedRowTab,
            togglePinned,
            closeDetails
        };
    }
};
