import { EGameService } from "@sportaq/model/common/point-settings";
import { BaseSettingsService } from "@sportaq/services/src/base-setting-service/base-settings-service";

export function isServiceEnabled (settings: BaseSettingsService, service: EGameService): boolean {
    return settings.disabledServices.find(s => s === service) === undefined;
}

const allServices = [EGameService.Betting, EGameService.Race, EGameService.Virtual, EGameService.Casino, EGameService.Aviator];

export function isSingleServiceVersion (settings: BaseSettingsService, service: EGameService.Aviator): boolean {
    const enableServices = allServices.filter(value => !settings.disabledServices.includes(value));
    return enableServices.length === 1 && enableServices[0] === service;
}
