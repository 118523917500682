import { ElectronCommand, sendElectronCommand } from "@sportaq/electron/utils/electron-utils";

export default {
    name: "WindowButtonsPanel",
    setup () {
        function minimize () {
            sendElectronCommand(ElectronCommand.MINIMIZE_APP);
        }

        function debug () {
            sendElectronCommand(ElectronCommand.TOGGLE_DEVTOOLS);
        }

        function close () {
            sendElectronCommand(ElectronCommand.EXIT_APP);
        }

        return {
            debug,
            minimize,
            close

        };
    }

};
