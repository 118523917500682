import { insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import { GeneralIdItem } from "@sportaq/common/types/types";

export class SortedIndexedArray<T extends GeneralIdItem<K>, K> {
    private index = new Map<K, T>();
    public items: T[] = [];

    constructor (private readonly comparator: (a: T, b: T) => number) {
    }

    push (item: T) {
        insertIntoSortedArray(this.items, item, this.comparator);
        this.index.set(item.id, item);
    }

    replace (item: T) {
        if (this.index.has(item.id)) {
            const index = this.items.findIndex(value => value.id === item.id);
            if (index >= 0) {
                this.items.splice(index, 1, item);
                this.index.set(item.id, item);
            }
        }
    }

    remove (id: K): boolean {
        const present = this.index.get(id);
        if (present) {
            const removed = this.index.delete(id);
            if (removed) {
                const index = this.items.findIndex(value => value.id === id);
                if (index >= 0) {
                    this.items.splice(index, 1);
                }
            }
            return removed;
        }
        return false;
    }

    contains (item: T): boolean {
        return this.index.has(item.id);
    }

    get (id: K): T | undefined {
        return this.index.get(id);
    }

    clone (): SortedIndexedArray<T, K> {
        const result: SortedIndexedArray<T, K> = new SortedIndexedArray(this.comparator);
        result.index = new Map(this.index);
        result.items = Array.from(this.items);
        return result;
    }
}
