import { WebSettings } from "@sportaq/services/web/settings/settings-service";
import { WebDesktopStore } from "@sportaq/vuex/modules/web/web-module";
import { StreamingService } from "@sportaq/services/streaming/streaming-service";
import { useI18n } from "vue-i18n";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { RootStore } from "@sportaq/vuex/index";

let isEnabled = true;
let elapsedTime = 0;

export function enableWebDisconnectTimer () {
    elapsedTime = 0;
    isEnabled = true;
}

export function disableWebDisconnectTimer () {
    isEnabled = false;
}

export function useWebDisconnectTimer (settingsService: WebSettings, rootStore: RootStore, store: WebDesktopStore, streamingService: StreamingService) {
    const i18n = useI18n();
    window.addEventListener("onfocus", () => {
        elapsedTime = 0;
    });

    window.addEventListener("onclick", () => {
        elapsedTime = 0;
    });

    const frequency = setInterval(() => {
        if (!isEnabled) {
            return;
        }
        elapsedTime++;
        if (elapsedTime > 60 * 30) {
            clearInterval(frequency);
            store.user = undefined;
            settingsService.clearSessionCookie();
            showMessageBox(i18n.t("webdesktop.idle.message"), () => {
                window.location.reload();
            });
            streamingService.disconnect();
        }
    }, 1000);
}
