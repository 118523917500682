import { EDetailsViewType, ScoreboardSelection } from "@sportaq/model/betting/view/scoreboard-selection";
import {
    DetailTab,
    eventDetailsInfoHolder,
    PINNED_DETAIL_TAB_ID,
    QuotationRow
} from "@sportaq/model/betting/view/event-details/event-details-info";
import {
    scoreBoardDetailsRecalculateQuotationColumns
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-details/utils/recalculate-quotation-row";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { Ref } from "@sportaq/common/types/types";
import { extractPeriodName, matchScoreDecomposition } from "@sportaq/vue/components/betting/utils/event-result-strings";
import { computed, ref, shallowRef } from "vue";
import { ESportType } from "@sportaq/common/enums/sport-type";
import EPeriodType from "@sportaq/common/enums/period-types";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { sportTypes } from "@sportaq/model/consts/sport-types";

export namespace ScoreboardDetailsComponent {
    export function useCommonScoreboardDetailsComponent (selection: ScoreboardSelection, viewType: EDetailsViewType, isBetSlipShowed: boolean, filterShowed: boolean, teamResultInRow: boolean) {
        const eventSupplier = useEventSupplier();
        const scoreboardStore = useScoreboardStore();

        const bettingEvent = eventSupplier.getEvent(selection.eventType, selection.positionId);
        const event = shallowRef(bettingEvent);
        const eventResult = ref(bettingEvent.resultText);

        const period = ref(selection.period);
        const detailRowPinned = scoreboardStore.getDetailRowPinned(event.value.sportTypeId);
        const plainTabs = eventDetailsInfoHolder.getCardInfo(event.value.sportTypeId, period.value, bettingEvent, detailRowPinned);
        const tabs = shallowRef(plainTabs);
        const columns = shallowRef([] as QuotationRow[][]);

        const selectedTab: Ref<DetailTab | undefined> = shallowRef(undefined);
        if (tabs.value.length > 0) {
            selectedTab.value = getSelectedTab(bettingEvent.sportTypeId, selection.period, plainTabs, selection.quotationId) || getDefaultTab(viewType, plainTabs);
            columns.value = scoreBoardDetailsRecalculateQuotationColumns(event.value, selectedTab.value, viewType, isBetSlipShowed, filterShowed);
        }
        const infoLabel = computed(() => {
            const partition = event.value.partition;
            const sportTypeName = sportTypes.getById(partition.sportTypeId)?.name ?? "";
            return sportTypeName + " / " + partition.name;
        });

        const participants = computed(() => getParticipantList(event.value));
        const score = computed(() => matchScoreDecomposition(eventResult.value, teamResultInRow));
        const periodInfo = computed(() => extractPeriodName(eventResult.value) ?? event.value.startTimeStr);

        function refreshBySelection (selection: ScoreboardSelection | undefined, viewType: EDetailsViewType, isBetSlipShowed: boolean) {
            if (selection && selection.positionId > 0) {
                const newEvent = eventSupplier.getEvent(selection.eventType, selection.positionId);
                event.value = newEvent;
                eventResult.value = newEvent.resultText;
                period.value = selection.period;
                const detailRowPinned = scoreboardStore.getDetailRowPinned(event.value.sportTypeId);
                const newTabs = eventDetailsInfoHolder.getCardInfo(newEvent.sportTypeId, selection.period, newEvent, detailRowPinned);
                if (newTabs.length > 0) {
                    const newTab = getSelectedTab(newEvent.sportTypeId, selection.period, newTabs, selection.quotationId);
                    if (newTab) {
                        selectedTab.value = newTab;
                    } else if (selectedTab.value) {
                        const newSelectedTab = newTabs.find(t => t.id === selectedTab.value?.id && t.rows.length > 0);
                        selectedTab.value = newSelectedTab ?? getDefaultTab(viewType, newTabs);
                    } else {
                        selectedTab.value = getDefaultTab(viewType, newTabs);
                    }
                } else {
                    selectedTab.value = undefined;
                }
                tabs.value = newTabs;
            }
            if (tabs.value.length > 0 && selectedTab.value) {
                columns.value = scoreBoardDetailsRecalculateQuotationColumns(event.value, selectedTab.value, viewType, isBetSlipShowed, filterShowed);
            } else {
                columns.value = [];
            }
        }

        function getDefaultTab (viewType: EDetailsViewType, tabs: DetailTab[]) {
            for (const tab of tabs) {
                if (viewType === EDetailsViewType.Mobile && tab.id === PINNED_DETAIL_TAB_ID && tab.rows.length > 0) {
                    return tab;
                }
                if (tab.id !== PINNED_DETAIL_TAB_ID) {
                    return tab;
                }
            }
            return tabs[0];
        }

        function selectTab (tab: DetailTab, viewType: EDetailsViewType, isBetSlipShowed: boolean, filterShowed: boolean) {
            if (tab.rows.length > 0) {
                selectedTab.value = tab;
                columns.value = scoreBoardDetailsRecalculateQuotationColumns(event.value, tab, viewType, isBetSlipShowed, filterShowed);
            }
        }

        return {
            event,
            tabs,
            selectedTab,
            columns,
            infoLabel,
            participants,
            score,
            periodInfo,
            refreshBySelection,
            selectTab
        };
    }

    function getSelectedTab (sportType: ESportType, period: EPeriodType, tabs: DetailTab[], quotationId?: number): DetailTab | undefined {
        if (quotationId) {
            const tabId = eventDetailsInfoHolder.getPeriodTabIdByQuotationId(sportType, period, quotationId);
            if (tabId) {
                const tab = tabs.find(value => value.id === tabId);
                if (tab) {
                    return tab;
                }
            }
        }
        return undefined;
    }
}
