import { ref, SetupContext } from "vue";
import { useWebRestService } from "@sportaq/services/web";
import { VoucherSellInfo } from "@sportaq/model/cashier/voucher";
import { DialogState } from "@sportaq/vue/views/web/withdrawal/dialog-state";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { getCurrencyName } from "@sportaq/model/common/point-settings";

export function useVoucherDepositCommon (context: SetupContext) {
    const voucherNumber = ref<string>();
    const restService = useWebRestService();
    const voucherRef = ref<VoucherSellInfo>();
    const dialogState = ref<DialogState>(DialogState.InputAmount);
    const requestPending = ref(false);

    function close () {
        context.emit(CLOSE_EVENT);
    }

    function submit () {
        if (dialogState.value === DialogState.Finish) {
            close();
        }
        if (dialogState.value === DialogState.InputAmount && voucherNumber.value) {
            restService.findVoucher(voucherNumber.value).then(voucher => {
                voucherRef.value = voucher;
                dialogState.value = DialogState.ConfirmationType;
            });
        }
        if (dialogState.value === DialogState.ConfirmationType && voucherNumber.value) {
            requestPending.value = true;
            restService.voucherDeposit(voucherNumber.value).then(() => {
                dialogState.value = DialogState.Finish;
                requestPending.value = false;
            }).catch(() => {
                requestPending.value = false;
            });
        }
    }
    return {
        voucherNumber,
        voucherRef,
        dialogState,
        requestPending,
        getCurrencyName,
        close,
        submit
    };
}