import { computed, onMounted } from "vue";
import BettingFilterMenuCountry
    from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-country/BettingFilterMenuCountry.vue";
import BettingFilterMenuDate
    from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-date/BettingFilterMenuDate.vue";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { useScrollButtons } from "@sportaq/vue/components/betting/scroll-buttons/scroll-buttons";
import ScrollButtons from "@sportaq/vue/components/betting/scroll-buttons/ScrollButtons.vue";

export default {
    components: {
        BettingFilterMenuCountry,
        BettingFilterMenuDate,
        ScrollButtons
    },
    props: {
        closeOnClickOutside: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    setup (props: { closeOnClickOutside: boolean }) {
        let isReady = false;
        const store = useScoreboardStore();
        const items = computed(() => {
            return store.getCountryMenuItems();
        });

        const {
            isScrollButtonsVisible,
            bodyElement
        } = useScrollButtons();

        function close () {
            store.filterMenuActive = false;
        }

        if (props.closeOnClickOutside) {
            onMounted(() => {
                setTimeout(() => {
                    isReady = true;
                }, 1500);
            });
        }

        function onClickOutside () {
            if (isReady) {
                close();
            }
        }

        return {
            items,
            bodyElement,
            isScrollButtonsVisible,
            close,
            onClickOutside
        };
    }
};
