import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { dateToMtlStr, parseDate } from "@sportaq/common/utils/time-utils";
import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { Operation } from "@sportaq/model/web/operation";
import {
    getRequiredAttribute,
    getRequiredChild,
    getRequiredFloatAttribute, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError, NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";

export class QPo11OperationsByDate extends AbstractMessageHandler<Operation[]> {
    protected readonly requestType: string = "Q.PO.1.1";

    constructor (
       readonly operations: string[], readonly startDate: Date, readonly endDate: Date
    ) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter");
        request.addChildWithValue(sessionFilterElement, "User", "true");
        const operationFilterElement = request.addChild(filterElement, "FinOperationFilter", {
            logic: "AND"
        });
        const operationTypeListElement = request.addChild(operationFilterElement, "FinOperationTypeList");
        for (const operation of this.operations) {
            request.addChildWithValue(operationTypeListElement, "FinOperationType", operation);
        }
        const stakePeriodElement = request.addChildWithValue(operationFilterElement, "FinOperationPeriod");
        request.addChildWithValue(stakePeriodElement, "StartTime", dateToMtlStr(this.startDate));
        request.addChildWithValue(stakePeriodElement, "FinishTime", dateToMtlStr(this.endDate));
        const selectorElement = request.addChild(actionElement, "Selector");
        request.addChild(selectorElement, "SelectObject", {
            allFields: "true",
            class: "ps.fin.FinOperation"
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, head: Element): Operation[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        const result: Operation[] = [];
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode === "4110") {
            const listElement = getRequiredChild(action, "FinOperationList");
            for (let operationElement = listElement.firstElementChild; operationElement != null; operationElement = operationElement.nextElementSibling) {
                result.push({
                    amount: getRequiredFloatAttribute(operationElement, "sumOper"),
                    operationDate: parseDate(getRequiredAttribute(operationElement, "acceptServerTime")),
                    operationId: getRequiredIntAttribute(operationElement, "Id"),
                    comment: getRequiredAttribute(operationElement, "promotionName"),
                    currency: getRequiredAttribute(operationElement, "currencyMark"),
                    operationType: getRequiredAttribute(operationElement, "finOperationType")
                });
            }
            return result;
        }
        throw new LocalizedError(`errors.mtl.qPo11.code${serverCode}`);
    }
}
