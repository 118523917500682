<template>
    <constant-items-panel :items="items"
                          menuClass="slider-events"
                          subMenuClass="virtual-submenu"
                          :defaultSelectedItemId="defaultSelectedItemId"
                          :forceOpenSelectedTabByClick="forceOpenSelectedTabByClick"
                          dataCy="virtual-games-top-panel"
                          @select="select">
        <template v-slot:addMenuButton>
            <slot name="addMenuButton"></slot>
        </template>
        <template v-slot:search>
            <slot name="searchPanel"></slot>
        </template>
        <slot></slot>
    </constant-items-panel>
</template>

<script src="./virtual-games-top-menu-component.ts"/>
