import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { getEventTypeByRoute } from "@sportaq/vue/components/betting/utils/route-utils";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { onMounted, ref, watch } from "vue";
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from "vue-router";
import {
    EventSupplier,
    useEventSupplier
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EPeriodType from "@sportaq/common/enums/period-types";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { EDetailsViewType, ScoreboardSelection } from "@sportaq/model/betting/view/scoreboard-selection";

export namespace EventPageComponent {
    const TIMEOUT = 100;

    export function useCommonEventDetailsPageComponent () {
        const router = useRouter();
        const route = useRoute();

        const eventSupplier = useEventSupplier();
        const scoreboardStore = useScoreboardStore();

        const selection = ref<ScoreboardSelection | undefined>();
        let interval: ReturnType<typeof setInterval>;

        function refreshPage () {
            scoreboardStore.scoreboardPageType = EScoreboardPageType.EVENT_DETAILS;
            const value = getSelection(router, route, eventSupplier);
            if (value) {
                selection.value = value;
            } else {
                interval = setInterval(() => {
                    const val = getSelection(router, route, eventSupplier);
                    if (val) {
                        selection.value = val;
                        clearInterval(interval);
                    } else {
                        const eventType = getEventTypeByRoute(route);
                        if (eventType && eventSupplier.isColdDataReceived(eventType)) {
                            clearInterval(interval);
                            router.push({ path: COMMON_ROUTES.SPORT.path }).then();
                        }
                    }
                }, TIMEOUT);
            }
        }

        onMounted(() => {
            refreshPage();
        });

        watch(() => route.params.positionId, (value) => {
            if (value) {
                refreshPage();
            }
        });

        return {
            selection
        };
    }

    function getSelection (router: Router, route: RouteLocationNormalizedLoaded, eventSupplier: EventSupplier): ScoreboardSelection | undefined {
        const eventType = getEventTypeByRoute(route);
        if (!eventType) {
            router.push({ name: COMMON_ROUTES.NOTFOUND.name }).then();
            return undefined;
        }
        const positionId = Number(route.params.positionId);
        if (isNaN(positionId)) {
            router.push({ name: COMMON_ROUTES.NOTFOUND.name }).then();
            return undefined;
        }

        const event = eventSupplier.getOptionalEvent(eventType, positionId);
        if (event) {
            const quotationId = Number(route.query.qid);
            return {
                eventType,
                positionId,
                period: EPeriodType.MAIN,
                quotationId: !isNaN(quotationId) ? quotationId : undefined,
                preferredView: EDetailsViewType.Standalone
            };
        }
        return undefined;
    }
}
