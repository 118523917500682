<template>
    <div class="main-window">
        <div class="game-frame" v-if="currentGameUrl" data-cy="game-frame">
            <iframe :src="currentGameUrl" id="gameContentWindow" />
        </div>
        <div class="preloader" v-else>
            <div class="preloader-el"></div>
        </div>
        <slot></slot>
    </div>
</template>

<script src="./horse-games-component.ts" />
