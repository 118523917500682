import {
    EPeriodListResolverType, EventListData,
    PeriodListRebuilder,
    PeriodListResolver,
    PeriodListUpdater
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/period-list-resolver";
import {
    AcceptResult,
    EventViewFilter
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { BettingEvent, Partition } from "@sportaq/model/betting/events/event";
import { binarySearchIndex, insertIntoSortedArray } from "@sportaq/common/utils/arrays";
import EPeriodType from "@sportaq/common/enums/period-types";
import {
    ScoreboardPeriodFunctions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/common/functions";
import { comparator } from "@sportaq/common/types/functions";
import {
    FavouritePeriodSortInfo
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/favourite-period-sort-info";
import PeriodSortInfoHelper = ScoreboardPeriodFunctions.PeriodSortInfoHelper;
import { DictionaryItem } from "@sportaq/common/types/types";
import { sportTypes } from "@sportaq/model/consts/sport-types";

const SPORT_TYPE_MULTIPLIER = 10000000;

export class FavouriteModePeriodListResolver implements PeriodListResolver<FavouritePeriodSortInfo> {
    private readonly helper;
    private categoryMap = new Map<number, number[]>();

    constructor (private leagueMode: boolean) {
        this.helper = new PeriodSortInfoHelper(
            (event, period, options) => {
                const sport = sportTypes.getById(event.sportTypeId);
                if (sport) {
                    return FavouritePeriodSortInfo.createFavouriteEvent(event, period, options,
                        createCategoryKey(this.leagueMode, event.sportTypeId, event.partition),
                        createCategoryName(this.leagueMode, sport, event),
                        sport.index);
                }
                throw new Error(`Sport type with id ${event.sportTypeId} isn't supported`);
            }
        );
    }

    get type () {
        return this.leagueMode ? EPeriodListResolverType.FAVOURITES_WITH_LEAGUES : EPeriodListResolverType.FAVOURITES;
    }

    get batchRebuilder (): PeriodListRebuilder<FavouritePeriodSortInfo> {
        return new InternalPeriodListRebuilder(this.helper, this.leagueMode, this.categoryMap);
    }

    get batchUpdater (): PeriodListUpdater<FavouritePeriodSortInfo> {
        return new InternalPeriodListUpdater(this.helper, this.leagueMode, this.categoryMap);
    }

    expandEvent (periods: FavouritePeriodSortInfo[], event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): FavouritePeriodSortInfo[] {
        const newViewList = Array.from(periods);
        this.helper.updateEvent(newViewList, event, expanded, {
            reverseTimeSorting
        });
        return newViewList;
    }
}

class InternalPeriodListRebuilder implements PeriodListRebuilder<FavouritePeriodSortInfo> {
    private periods: FavouritePeriodSortInfo[] = [];
    private groupEvents: FavouritePeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper, private leagueMode: boolean, private categoryMap: Map<number, number[]>) {
    }

    begin (): void {
        this.periods = [];
        this.groupEvents = [];
        this.categoryMap.clear();
    }

    addEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, true);
        if (acceptResult.accept) {
            const list = event.isGroupEvent ? this.groupEvents : this.periods;
            addNewGroupedEvent(this.helper, this.leagueMode, list, this.categoryMap, event, expanded, reverseTimeSorting);
        }
        return acceptResult;
    }

    end (): EventListData<FavouritePeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}

class InternalPeriodListUpdater implements PeriodListUpdater<FavouritePeriodSortInfo> {
    private periods: FavouritePeriodSortInfo[] = [];
    private groupEvents: FavouritePeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper, private leagueMode: boolean, private categoryMap: Map<number, number[]>) {
    }

    begin (data: EventListData<FavouritePeriodSortInfo>): void {
        this.periods = Array.from(data.periods);
        this.groupEvents = Array.from(data.groupEvents);
    }

    addOrUpdateEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, true);
        const list = event.isGroupEvent ? this.groupEvents : this.periods;
        const index = list.findIndex(value => value.positionId === event.positionId);
        if (acceptResult.accept && index >= 0) {
            this.helper.updateEvent(list, event, expanded, { reverseTimeSorting }, index);
        } else if (acceptResult.accept && index === -1) {
            addNewGroupedEvent(this.helper, this.leagueMode, list, this.categoryMap, event, expanded, reverseTimeSorting);
        } else if (!acceptResult.accept && index >= 0) {
            this.helper.deleteEvent(list, event.positionId, index);
        }
        return acceptResult;
    }

    deleteEvent (positionId: number): void {
        if (!this.helper.deleteEvent(this.groupEvents, positionId)) {
            this.helper.deleteEvent(this.periods, positionId);
        }
        let needDeleteCategory = false;
        let category: number | undefined;
        for (const categoryId of this.categoryMap.keys()) {
            const values = this.categoryMap.get(categoryId);
            if (values) {
                const index = binarySearchIndex(values, positionId, (a, b) => a - b);
                if (index >= 0) {
                    values.splice(index, 1);
                    category = categoryId;
                    needDeleteCategory = values.length === 0;
                    break;
                }
            }
        }
        if (needDeleteCategory && category) {
            const categoryIndex = this.periods.findIndex(value => value.period === EPeriodType.CATEGORY && value.categoryId === category);
            if (categoryIndex >= 0) {
                this.periods.splice(categoryIndex, 1);
            }
            this.categoryMap.delete(category);
        }
    }

    end (): EventListData<FavouritePeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}

function createCategoryKey (leagueMode: boolean, sportType: number, partition: Partition) {
    return leagueMode ? SPORT_TYPE_MULTIPLIER * sportType + partition.id : sportType;
}

function createCategoryName (leagueMode: boolean, sport: DictionaryItem, event: BettingEvent) {
    return leagueMode ? sport.name + ". " + event.partition.name : sport.name;
}

function addNewGroupedEvent (
    helper: PeriodSortInfoHelper,
    leagueMode: boolean,
    list: FavouritePeriodSortInfo[],
    categoryMap: Map<number, number[]>,
    event: BettingEvent, expanded: boolean,
    reverseTimeSorting: boolean
) {
    if (!event.isGroupEvent) {
        const categoryKey = createCategoryKey(leagueMode, event.sportTypeId, event.partition);
        const itemsInCategory = categoryMap.get(categoryKey);
        if (!itemsInCategory) {
            const sport = sportTypes.getById(event.sportTypeId);
            if (sport) {
                insertIntoSortedArray(list,
                    FavouritePeriodSortInfo.createFavouriteCategoryRow(
                        categoryKey,
                        createCategoryName(leagueMode, sport, event),
                        event.sportTypeId,
                        leagueMode ? event.partition.countryId : -1,
                        sport.index
                    ), comparator);
                categoryMap.set(categoryKey, [event.positionId]);
            }
        } else {
            insertIntoSortedArray(itemsInCategory, event.positionId, (a, b) => a - b);
        }
    }
    helper.insertNewEvent(list, event, expanded, { reverseTimeSorting });
}
