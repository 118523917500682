import { binarySearchIndex, insertOrReplaceIntoSortedSet } from "@sportaq/common/utils/arrays";
import { Quotation } from "@sportaq/model/betting/events/quotation";
import { comparator } from "@sportaq/common/types/functions";
import { EQuotation } from "@sportaq/common/consts/quotation-consts";

export class QuotationStore {
    private readonly storeByQuotationId = new Map<EQuotation, Quotation[]>();
    private readonly storeById = new Map<number, Quotation>();

    getQuotationById (id: number): Quotation | undefined {
        return this.storeById.get(id);
    }

    getQuotationsByQuotationId (quotationId: EQuotation): Quotation[] {
        return this.storeByQuotationId.get(quotationId) ?? [];
    }

    setQuotation (quotation: Quotation) {
        this.storeById.set(quotation.id, quotation);
        let quotations = this.storeByQuotationId.get(quotation.key.quotationId);
        if (!quotations) {
            quotations = [];
            this.storeByQuotationId.set(quotation.key.quotationId, quotations);
        }
        insertOrReplaceIntoSortedSet(quotations, quotation, (a, b) => comparator(a.key, b.key));
    }

    removeQuotation (id: number): Quotation | undefined {
        const quotation = this.storeById.get(id);
        if (quotation) {
            this.storeById.delete(id);
            const key = quotation.key;
            const quotations = this.storeByQuotationId.get(key.quotationId);
            if (quotations) {
                const index = binarySearchIndex(quotations, key, (a, b) => comparator(a.key, b));
                if (index >= 0) {
                    quotations.splice(index, 1);
                }
            }
        }
        return quotation;
    }
}
