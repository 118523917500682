import { useWebRestService } from "@sportaq/services/web";
import { Operation } from "@sportaq/model/web/operation";
import { computed, ref, watch } from "vue";

export const depositOperations: string[] = ["PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT", "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT", "PAY_OPERATION_INCREASE_ACCOUNT"];
export const bonusOperations: string[] = ["PAY_OPERATION_INCREASE_BONUS_ACCOUNT", "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT",
    "PAY_OPERATION_CANCEL_BONUS_ACCOUNT", "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT", "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT",
    "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT"];
export const allOperations: string[] = [
    "PAY_OPERATION_REFERRAL_ACCOUNT_WITHDRAWAL",
    "PAY_OPERATION_REFERRAL_ACCOUNT_DEPOSIT",
    "PAY_OPERATION_INCREASE_ACCOUNT",
    "PAY_OPERATION_WITHDRAWAL",
    "PAY_OPERATION_DEPOSIT_DIRECT_REFILL_ACCOUNT",
    "PAY_OPERATION_DEPOSIT_BONUS_REFILL",
    "PAY_OPERATION_WITHDRAWAL_VOUCHER_ACCOUNT",
    "PAY_OPERATION_INCREASE_BONUS_ACCOUNT",
    "PAY_OPERATION_INCREASE_BONUS_CASINO_ACCOUNT",
    "PAY_OPERATION_CANCEL_BONUS_ACCOUNT",
    "PAY_OPERATION_CANCEL_BONUS_CASINO_ACCOUNT",
    "PAY_OPERATION_WITHDRAWAL_CASINO_BONUS_ACCOUNT",
    "PAY_OPERATION_WITHDRAWAL_BONUS_ACCOUNT"
];

export function useDateFilter () {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const startDateRef = ref(startDate.toISOString().split("T")[0]);
    const endDateRef = ref(new Date().toISOString().split("T")[0]);
    return {
        startDateRef,
        endDateRef
    };
}

export function useOperationListCommon (operations: string[]) {
    const restService = useWebRestService();
    const operationsRef = ref<Operation[]>([]);
    const itemsPerPageRef = ref<number>(10);
    const currentPage = ref<number>(1);
    const totalPages = computed(() => Math.ceil(operationsRef.value.length / itemsPerPageRef.value));
    const requestPending = ref(false);
    const operationsPaged = computed(() => {
        const offset = (currentPage.value - 1) * itemsPerPageRef.value;
        return operationsRef.value.slice(offset).slice(0, itemsPerPageRef.value);
    });

    function itemsPerPageChanged (value: number) {
        itemsPerPageRef.value = value;
    }

    function currentPageChanged (value: number) {
        currentPage.value = value;
    }

    function dateChanged (startDate: Date, endDate: Date) {
        endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
        requestPending.value = true;
        restService.getOperations(operations, startDate, endDate)
            .then((c) => {
                operationsRef.value = c;
                requestPending.value = false;
            });
    }
    watch(itemsPerPageRef, () => {
        currentPage.value = 1;
    });

    return {
        operationsPaged,
        itemsPerPageRef,
        operationsRef,
        currentPage,
        totalPages,
        requestPending,
        itemsPerPageChanged,
        currentPageChanged,
        dateChanged
    };
}
