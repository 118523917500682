import Flicking, { MOVE_TYPE } from "@egjs/vue3-flicking";
import CompactScoreboardEventLine
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-event-line/compact/CompactScoreboardEventLine.vue";
import { ESportType, getNameBySportType } from "@sportaq/common/enums/sport-type";
import { sportTypes } from "@sportaq/model/consts/sport-types";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { nextTick, PropType, ref, watch } from "vue";
import {
    ScoreboardHeadQuotationTypesSelector
} from "@sportaq/model/betting/view/scoreboard-head-quotation-types-selector";
import { Arrow } from "@egjs/flicking-plugins";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EventType from "@sportaq/common/enums/event-type";

interface Props {
    sportType: ESportType;
    source: PeriodSortInfo[];
}

const PREVIEW_ITEM_COUNT = 10;
export default {
    props: {
        sportType: {
            required: true,
            type: Number
        },
        source: {
            required: true,
            type: Object as PropType<PeriodSortInfo[]>
        },
        isMobile: {
            required: true,
            type: Boolean
        }
    },
    components: {
        Flicking,
        CompactScoreboardEventLine
    },
    setup (props: Props) {
        const eventSupplier = useEventSupplier();
        const sport = sportTypes.getById(props.sportType);
        const linkNameBySportType = getNameBySportType(props.sportType);
        const scoreboardHeadMenuItems = ScoreboardHeadQuotationTypesSelector.get(props.sportType)!;
        const viewIndex = props.sportType === ESportType.Basketball || props.sportType === ESportType.Baseball ? 1 : 0;
        const scoreboardHeadMenuItem = scoreboardHeadMenuItems[viewIndex];
        const extraProps = {
            quotationViews: [scoreboardHeadMenuItem.view],
            columnsCount: 1,
            viewTitle: scoreboardHeadMenuItem.localizationKey
        };
        const plugins = [new Arrow()];

        const flicker = ref();
        const periods = ref<PeriodSortInfo[]>([]);

        watch(() => props.source, value => {
            periods.value = value.filter(value => value.sportType === props.sportType).slice(0, PREVIEW_ITEM_COUNT);
            if (flicker.value && !eventSupplier.isColdDataReceived(EventType.PRE_MATCH)) {
                nextTick(() => {
                    if (flicker.value && !flicker.value.animating && !eventSupplier.isColdDataReceived(EventType.PRE_MATCH)) {
                        flicker.value.moveTo(0, 0);
                    }
                }).then();
            }
        });

        return {
            sport,
            linkNameBySportType,
            extraProps,
            plugins,

            flicker,
            periods,

            MOVE_TYPE
        };
    }
};
