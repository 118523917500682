<template>
    <div class="details-accordeon" :class="{collapsed: collapsed}"
         :data-cy="`quotation-group-${id}${p1?'-'+p1.toString().replace('.','_'):''}`">
        <div class="title" @click="toggleCollapsed" data-cy="quotation-group-title">
            <div class="display-flex pin-wrap" @click.stop="togglePinned">
                <div v-if="allowBePinned" class="pin" :class="{'active': isPinned}"  data-cy="title-pin"></div>
                <div> {{ title }}</div>
            </div>
            <div class="switcher" v-if="allowCollapse"></div>
        </div>
        <template v-if="!collapsed">
            <template v-for="(row, rowIndex) of rows" :key="rowIndex">
                <div class="head" data-cy="head">
                    <div class="item" v-for="(header, index) of row.headers" :key="index" v-html="header"/>
                </div>
                <div class="body" data-cy="body">
                    <compact-scoreboard-line-quotation v-for="(item,index) of row.quotations" :key="index"
                                                      :participants="participants" :source="item"
                                                      :event-blocked="eventBlocked"/>
                </div>
            </template>
        </template>
    </div>
</template>

<script src="./compact-quotation-group-component.ts"/>
