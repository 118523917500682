import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { ConfirmationResult } from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { showMessageBox, showMessageBoxEx } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useWebRestService } from "@sportaq/services/web";
import { useGoogleTagService } from "@sportaq/services/index";

export function useEmailDoneCommon () {
    const confirmCodeDialogActive = ref(false);
    const store = useWebStore();
    const balanceStore = useBalanceStore();
    const router = useRouter();
    const restService = useWebRestService();
    const i18n = useI18n();
    const googleTagService = useGoogleTagService();

    function onEnterCodeClick () {
        confirmCodeDialogActive.value = true;
    }

    function onCodeSuccess (confirmation: ConfirmationResult) {
        if (confirmation.confirmationType === 1) {
            restService.requestUserInfo().then(user => {
                store.user = user;
                balanceStore.clearBalance();
                restService.updateCountryFilterFavourites(user.countryId).then();
                showMessageBox(i18n.t("webdesktop.registration.success"));
                googleTagService.sendSuccessRegistrationEvent();
                router.push({ path: "/deposit" }).then();
            });
        }
    }

    function onCodeError (message: string) {
        showMessageBoxEx(false, undefined, i18n.t(message));
    }

    return {
        confirmCodeDialogActive,
        onEnterCodeClick,
        onCodeSuccess,
        onCodeError
    };
}
