<template>
    <div class="coupon coupon-content-active coupon-dialog">
        <div class="coupon-content" data-cy="dialog-bet-verification">
            <div class="coupon-head">
                <div class="coupon-head-title">{{ $t("betting.betSlip.dialogBetVerification.caption") }}</div>
            </div>

            <div class="coupon-body" data-cy="dialog-bet-verification-body">
                <mobile-inner-stake-table :simpleStakes="simpleStakes" :combinedStakes="combinedStakes"/>
            </div>
            <div class="coupon-footer">
                <div class="name" data-cy="dialog-bet-verification-sum-amount">
                    {{ $t("betting.betSlip.common.total") }}<br/>
                    {{ $n(betTotal.total, "currency") }} {{ currency }}
                </div>
                <div class="name" data-cy="dialog-bet-verification-sum-max-win">
                    {{ $t("betting.betSlip.common.maxWin") }}<br/>
                    {{ $n(betTotal.maxWin, "currency") }} {{ currency }}
                </div>
            </div>
            <div class="coupon-buttons">
                <button class="button button-primary" @click.prevent="confirm" data-cy="confirm-button">
                    {{ $t("common.forms.ok") }}
                </button>
                <button class="button button-secondary" @click.prevent="cancel" data-cy="cancel-button">
                    {{ $t("common.forms.cancel") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-dialog-bet-prematch-verification-component.ts"/>
