/* eslint quote-props: ["error", "always"] */
export default {
    "detailInfo": {
        "groups": {
            "americanFootball": {
                "evenOddMatch": "Par / impar",
                "evenOddMatchHalf1": "Par / impar 1ra mitad",
                "evenOddMatchQuarter1": "Par / impar 1er cuarto",
                "evenOddMatchQuarter2": "Par / impar 2do cuarto",
                "evenOddMatchQuarter3": "Par / impar 3er cuarto",
                "evenOddMatchQuarter4": "Par / impar 4to cuarto",
                "handicap": "Hándicap",
                "handicapHalf1": "Hándicap 1er tiempo",
                "handicapHalf2": "Hándicap 2do tiempo",
                "handicapQuarter1": "Hándicap 1er cuarto",
                "handicapQuarter2": "Hándicap 2do cuarto",
                "handicapQuarter3": "Hándicap 3er cuarto",
                "handicapQuarter4": "Hándicap 4to cuarto",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1er tiempo",
                "individualTotalTeam1Half2": "Total {team1} 2do tiempo",
                "individualTotalTeam1Quarter1": "Total {team1} 1er cuarto",
                "individualTotalTeam1Quarter2": "Total {team1} 2do cuarto",
                "individualTotalTeam1Quarter3": "Total {team1} 3er cuarto",
                "individualTotalTeam1Quarter4": "Total {team1} 4to cuarto",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1er tiempo",
                "individualTotalTeam2Half2": "Total {team2} 2do tiempo",
                "individualTotalTeam2Quarter1": "Total {team2} 1er cuarto",
                "individualTotalTeam2Quarter2": "Total {team2} 2do cuarto",
                "individualTotalTeam2Quarter3": "Total {team2} 3er cuarto",
                "individualTotalTeam2Quarter4": "Total {team2} 4to cuarto",
                "total": "Total",
                "totalHalf1": "Total 1er tiempo",
                "totalHalf2": "Total 2do tiempo",
                "totalQuarter1": "Total 1er cuarto",
                "totalQuarter2": "Total 2do cuarto",
                "totalQuarter3": "Total 3er cuarto",
                "totalQuarter4": "Total 4to cuarto",
                "x1_2": "Resultado del partido",
                "x1x2Half1": "Resultado del 1er tiempo",
                "x1x2Half2": "Resultado del 2do tiempo",
                "x1x2Quarter1": "Resultado del 1er cuarto",
                "x1x2Quarter2": "Resultado del 2do cuarto",
                "x1x2Quarter3": "Resultado del 3er cuarto",
                "x1x2Quarter4": "Resultado del 4to cuarto"
            },
            "baseball": {
                "evenOddMatch": "Par / impar",
                "handicap": "Hándicap",
                "handicapHalf1": "Hándicap primeras 5 entradas",
                "handicapInning1": "Hándicap 1ª entrada",
                "handicapInning2": "Hándicap 2ª entrada",
                "handicapInning3": "Hándicap 3ª entrada",
                "handicapInning4": "Hándicap 4ª entrada",
                "handicapInning5": "Hándicap 5ª entrada",
                "handicapInning6": "Hándicap 6ª entrada",
                "handicapInning7": "Hándicap 7ª entrada",
                "handicapInning8": "Hándicap 8ª entrada",
                "handicapInning9": "Hándicap 9ª entrada",
                "handicaps": "Hándicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalHalf1": "Total primeras 5 entradas",
                "totalInning1": "Total 1ª entrada",
                "totalInning2": "Total 2ª entrada",
                "totalInning3": "Total 3ª entrada",
                "totalInning4": "Total 4ª entrada",
                "totalInning5": "Total 5ª entrada",
                "totalInning6": "Total 6ª entrada",
                "totalInning7": "Total 7ª entrada",
                "totalInning8": "Total 8ª entrada",
                "totalInning9": "Total 9ª entrada",
                "x1_2": "Resultado del partido",
                "x1x2Half1": "Resultado de primeras 5 entradas",
                "x1x2Inning1": "Resultado de 1ª entrada",
                "x1x2Inning2": "Resultado de 2ª entrada",
                "x1x2Inning3": "Resultado de 3ª entrada",
                "x1x2Inning4": "Resultado de 4ª entrada",
                "x1x2Inning5": "Resultado de 5ª entrada",
                "x1x2Inning6": "Resultado de 6ª entrada",
                "x1x2Inning7": "Resultado de 7ª entrada",
                "x1x2Inning8": "Resultado de 8ª entrada",
                "x1x2Inning9": "Resultado de 9ª entrada"
            },
            "basketball": {
                "evenOddMatch": "Par / impar",
                "evenOddMatchHalf1": "Par / impar 1ra mitad 1ra Mitad",
                "evenOddMatchQuarter1": "Par / impar 1er cuarto",
                "evenOddMatchQuarter2": "Par / impar 2do cuarto",
                "evenOddMatchQuarter3": "Par / impar 3er cuarto",
                "evenOddMatchQuarter4": "Par / impar 4to cuarto",
                "handicap": "Hándicap",
                "handicapHalf1": "Hándicap 1ra Mitad",
                "handicapHalf2": "Hándicap 2da Mitad",
                "handicapQuarter1": "Hándicap 1er cuarto",
                "handicapQuarter2": "Hándicap 2do cuarto",
                "handicapQuarter3": "Hándicap 3er cuarto",
                "handicapQuarter4": "Hándicap 4to cuarto",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1ra Mitad",
                "individualTotalTeam1Half2": "Total {team1} 2da Mitad",
                "individualTotalTeam1Quarter1": "Total {team1} 1er cuarto",
                "individualTotalTeam1Quarter2": "Total {team1} 2do cuarto",
                "individualTotalTeam1Quarter3": "Total {team1} 3er cuarto",
                "individualTotalTeam1Quarter4": "Total {team1} 4to cuarto",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1ra Mitad",
                "individualTotalTeam2Half2": "Total {team2} 2da Mitad",
                "individualTotalTeam2Quarter1": "Total {team2} 1er cuarto",
                "individualTotalTeam2Quarter2": "Total {team2} 2do cuarto",
                "individualTotalTeam2Quarter3": "Total {team2} 3er cuarto",
                "individualTotalTeam2Quarter4": "Total {team2} 4to cuarto",
                "total": "Total",
                "totalHalf1": "Total 1ra Mitad",
                "totalHalf2": "Total 2da Mitad",
                "totalQuarter1": "Total 1er cuarto",
                "totalQuarter2": "Total 2do cuarto",
                "totalQuarter3": "Total 3er cuarto",
                "totalQuarter4": "Total 4to cuarto",
                "x1x2": "Resultado del partido",
                "x1x2Half1": "Resultado de 1ra Mitad",
                "x1x2Half2": "Resultado de 2da Mitad",
                "x1x2Quarter1": "Resultado del 1er cuarto",
                "x1x2Quarter2": "Resultado del 2do cuarto",
                "x1x2Quarter3": "Resultado del 3er cuarto",
                "x1x2Quarter4": "Resultado del 4to cuarto"
            },
            "football": {
                "12TotalUnder": "12 + Total Menos de ({p1})",
                "DrawTotalOver": "Empate + Total Más de ({p1})",
                "DrawTotalUnder": "Empate + Total Menos de ({p1})",
                "atLeastOneWontScore12": "Al menos un equipo no marca + 12",
                "atLeastOneWontScore1X": "Al menos un equipo no marca + 1X",
                "atLeastOneWontScoreDraw": "Al menos un equipo no marca + Empate",
                "atLeastOneWontScoreTeam1": "Al menos un equipo no marca + {team1}",
                "atLeastOneWontScoreTeam2": "Al menos un equipo no marca + {team2}",
                "atLeastOneWontScoreTotalOver": "Al menos uno no marca + Total Más de ({p1})",
                "atLeastOneWontScoreTotalUnder": "Al menos uno no marca + Total Menos de ({p1})",
                "atLeastOneWontScoreX2": "Al menos un equipo no marca + X2",
                "bothScoreTotalOver": "Ambos equipos anotan + Total Más de ({p1})",
                "bothScoreTotalUnder": "Ambos equipos anotan + Total Menos de ({p1})",
                "dryWin": "Ganador a cero",
                "evenOddHalf1": "Par / impar 1er tiempo",
                "evenOddMatch": "Par / impar",
                "firstGoalTeam": "Primero marcará",
                "goalBothHalves": "Gol en ambos tiempos",
                "goalHalf1": "Gol en el 1er tiempo",
                "goalHalf2": "Gol en el 2do tiempo",
                "goalIntervalTeam1x16x30": "Gol {team1} 15:01 - 30:00 min.",
                "goalIntervalTeam1x1x15": "Gol {team1} 00:01 - 15:00 min.",
                "goalIntervalTeam1x1x30": "Gol {team1} 00:01 - 30:00 min.",
                "goalIntervalTeam1x1x45": "Gol {team1} 00:01 - 45:00 min.",
                "goalIntervalTeam1x1x60": "Gol {team1} 00:01 - 60:00 min.",
                "goalIntervalTeam1x1x75": "Gol {team1} 00:01 - 75:00 min.",
                "goalIntervalTeam1x31x45": "Gol {team1} 30:01 - 45:00 min.",
                "goalIntervalTeam1x46x60": "Gol {team1} 45:01 - 60:00 min.",
                "goalIntervalTeam1x61x75": "Gol {team1} 60:01 - 75:00 min.",
                "goalIntervalTeam1x76x90": "Gol {team1} 75:01 - 90:00 min.",
                "goalIntervalTeam2x16x30": "Gol {team2} 15:01 - 30:00 min.",
                "goalIntervalTeam2x1x15": "Gol {team2} 00:01 - 15:00 min.",
                "goalIntervalTeam2x1x30": "Gol {team2} 00:01 - 30:00 min.",
                "goalIntervalTeam2x1x45": "Gol {team2} 00:01 - 45:00 min.",
                "goalIntervalTeam2x1x60": "Gol {team2} 00:01 - 60:00 min.",
                "goalIntervalTeam2x1x75": "Gol {team2} 00:01 - 75:00 min.",
                "goalIntervalTeam2x31x45": "Gol {team2} 30:01 - 45:00 min.",
                "goalIntervalTeam2x46x60": "Gol {team2} 45:01 - 60:00 min.",
                "goalIntervalTeam2x61x75": "Gol {team2} 60:01 - 75:00 min.",
                "goalIntervalTeam2x76x90": "Gol {team2} 75:01 - 90:00 min.",
                "goalIntervalx16x30": "Gol 15:01 - 30:00 min.",
                "goalIntervalx1x15": "Gol 00:01 - 15:00 min.",
                "goalIntervalx1x30": "Gol 00:01 - 30:00 min.",
                "goalIntervalx1x45": "Gol 00:01 - 45:00 min.",
                "goalIntervalx1x60": "Gol 00:01 - 60:00 min.",
                "goalIntervalx1x75": "Gol 00:01 - 75:00 min.",
                "goalIntervalx31x45": "Gol 30:01 - 45:00 min.",
                "goalIntervalx46x60": "Gol 45:01 - 60:00 min.",
                "goalIntervalx61x75": "Gol 60:01 - 75:00 min.",
                "goalIntervalx76x90": "Gol 75:01 - 90:00 min.",
                "goalTeam1BothHalves": "{team1} marcará en ambos tiempos",
                "goalTeam1Half1": "{team1} marcará en el 1er tiempo",
                "goalTeam1Half2": "{team1} marcará en el 2do tiempo",
                "goalTeam2BothHalves": "{team2} marcará en ambos tiempos",
                "goalTeam2Half1": "{team2} marcará en el 1er tiempo",
                "goalTeam2Half2": "{team2} marcará en el 2do tiempo",
                "goalWillScore1": "1er gol anotará",
                "goalWillScore10": "10mo gol anotará",
                "goalWillScore2": "2do gol anotará",
                "goalWillScore3": "3er gol anotará",
                "goalWillScore4": "4to gol anotará",
                "goalWillScore5": "5to gol anotará",
                "goalWillScore6": "6to gol anotará",
                "goalWillScore7": "7mo gol anotará",
                "goalWillScore8": "8vo gol anotará",
                "goalWillScore9": "9no gol anotará",
                "halfMatch": "Primer Tiempo/Partido Completo",
                "halvesComparison": "Comparación de los tiempos",
                "handicap": "Hándicap",
                "handicapHalf1": "Hándicap 1er tiempo",
                "handicapHalf2": "Hándicap 2do tiempo",
                "handicapMatch": "Hándicap",
                "individualTotalTeam1": "{team1} Total de goles",
                "individualTotalTeam1Half1": "Total de goles {team1} 1er tiempo",
                "individualTotalTeam1Half2": "Total de goles {team1} 2do tiempo",
                "individualTotalTeam2": "{team2} Total de goles",
                "individualTotalTeam2Half1": "Total de goles {team2} 1er tiempo",
                "individualTotalTeam2Half2": "Total de goles {team2} 2do tiempo",
                "lastGoalTeam": "Último en marcar gol",
                "matchScore": "Marcador Exacto",
                "passage": "Se clasificará",
                "scoreBoth": "Ambos marcarán",
                "scoreBoth12": "Ambos equipos anotan + 12",
                "scoreBoth1X": "Ambos equipos anotan + 1X",
                "scoreBothDraw": "Ambos equipos anotan + Empate",
                "scoreBothHalf1": "Ambos marcarán en 1er tiempo",
                "scoreBothHalf2": "Ambos marcarán en 2do tiempo",
                "scoreBothTeam1": "Ambos equipos anotan + {team1}",
                "scoreBothTeam2": "Ambos equipos anotan + {team2}",
                "scoreBothX2": "Ambos equipos anotan + X2",
                "scoreTeam1": "{team1} marcará gol",
                "scoreTeam2": "{team2} marcará gol",
                "strongWilledWin": "Ganador después de ir perdiendo",
                "team1Goal112": "{team1} anotará el Primer Gol + 12",
                "team1Goal11X": "{team1} anotará el Primer Gol + 1X",
                "team1Goal1Draw": "{team1} anotará el Primer Gol + Empate",
                "team1Goal1Team1": "{team1} anotará el Primer Gol + {team1}",
                "team1Goal1Team2": "{team1} anotará el Primer Gol +{team2}",
                "team1Goal1X2": "{team1} anotará el Primer Gol + X2",
                "team1TotalOver": "{team1} + Total Más de ({p1})",
                "team1TotalUnder": "{team1} + Total Menos de ({p1})",
                "team2Goal112": "{team2} anotará el Primer Gol + 12",
                "team2Goal11X": "{team2} anotará el Primer Gol + 1X",
                "team2Goal1Draw": "{team2} anotará el Primer Gol + Empate",
                "team2Goal1Team1": "{team2} anotará el Primer Gol + {team1}",
                "team2Goal1Team2": "{team2} anotará el Primer Gol + {team2}",
                "team2Goal1X2": "Equipo 2 anotará el Primer Gol + X2",
                "team2TotalOver": "{team2} + Total Más de ({p1})",
                "team2TotalUnder": "{team2} + Total Menos de ({p1})",
                "total": "Total",
                "totalBalls": "Total de goles",
                "totalHalf1": "1er tiempo Total",
                "totalHalf2": "2do tiempo Total",
                "winBothHalves": "Ganará ambos tiempos",
                "x12TotalOver": "12 + Total Más de ({p1})",
                "x1TotalOver": "1X + Total Más de ({p1})",
                "x1TotalUnder": "1X + Total Menos de ({p1})",
                "x1x12x2": "Doble Oportunidad",
                "x1x12x2Half1": "Doble Oportunidad 1er tiempo",
                "x1x12x2Half2": "Doble Oportunidad 2do tiempo",
                "x1x2": "Resultado del Partido",
                "x1x2fHalf1": "Resultado del 1er tiempo",
                "x1x2fHalf2": "Resultado del 2do tiempo",
                "x2TotalOver": "X2 + Total Más de ({p1})",
                "x2TotalUnder": "X2 + Total Menos de ({p1})"
            },
            "handball": {
                "evenOddHalf1": "Par / impar 1er tiempo",
                "evenOddMatch": "Par / impar",
                "handicap": "Hándicap",
                "handicapHalf1": "Hándicap 1er tiempo",
                "handicapHalf2": "Hándicap 2do tiempo",
                "handicapMatch": "Hándicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1er tiempo",
                "individualTotalTeam1Half2": "Total {team1} 2do tiempo",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1er tiempo",
                "individualTotalTeam2Half2": "Total {team2} 2do tiempo",
                "passage": "Se clasificará",
                "total": "Total",
                "totalHalf1": "Total 1er tiempo",
                "totalHalf2": "Total 2do tiempo",
                "x1x12x2": "Doble oportunidad",
                "x1x12x2Half1": "Doble Oportunidad 1er tiempo",
                "x1x12x2Half2": "Doble Oportunidad 2do tiempo",
                "x1x2": "Resultado del partido",
                "x1x2fHalf1": "Resultado del 1er tiempo",
                "x1x2fHalf2": "Resultado del 2do tiempo"
            },
            "hockey": {
                "handicap": "Hándicap",
                "handicapPeriod1": "Hándicap 1er periodo",
                "handicapPeriod2": "Hándicap 2do periodo",
                "handicapPeriod3": "Hándicap 3er periodo",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalMatch": "Total",
                "totalPeriod1": "Total 1er periodo",
                "totalPeriod2": "Total 2do periodo",
                "totalPeriod3": "Total 3er periodo",
                "x1x12x2": "Doble Oportunidad",
                "x1x12x2Period1": "Doble Oportunidad 1er periodo",
                "x1x12x2Period2": "Doble Oportunidad 2do periodo",
                "x1x12x2Period3": "Doble Oportunidad 3er periodo",
                "x1x2": "Resultado del partido",
                "x1x2Period1": "Resultado del 1er periodo",
                "x1x2Period2": "Resultado del 2do periodo",
                "x1x2Period3": "Resultado del 3er periodo"
            },
            "tableTennis": {
                "evenOddMatch": "Par / impar",
                "evenOddSet1": "Par / impar Set 1",
                "evenOddSet2": "Par / impar Set 2",
                "evenOddSet3": "Par / impar Set 3",
                "evenOddSet4": "Par / impar Set 4",
                "evenOddSet5": "Par / impar Set 5",
                "handicap": "Hándicap",
                "handicapSet1": "Hándicap Set 1",
                "handicapSet2": "Hándicap Set 2",
                "handicapSet3": "Hándicap Set 3",
                "handicapSet4": "Hándicap Set 4",
                "handicapSet5": "Hándicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Marcador exacto",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado del partido",
                "x1_2Set1": "Resultado del Set 1",
                "x1_2Set2": "Resultado del Set 2",
                "x1_2Set3": "Resultado del Set 3",
                "x1_2Set4": "Resultado del et 4",
                "x1_2Set5": "Resultado del Set 5"
            },
            "tennis": {
                "evenOddMatch": "Par / impar",
                "evenOddSet1": "Par / impar Set 1",
                "evenOddSet2": "Par / impar Set 2",
                "evenOddSet3": "Par / impar Set 3",
                "evenOddSet4": "Par / impar Set 4",
                "evenOddSet5": "Par / impar Set 5",
                "handicap": "Hándicap",
                "handicapSet1": "Hándicap Set 1",
                "handicapSet2": "Hándicap Set 2",
                "handicapSet3": "Hándicap Set 3",
                "handicapSet4": "Hándicap Set 4",
                "handicapSet5": "Hándicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Marcador exacto (sets)",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado del partido",
                "x1_2Set1": "Resultado del Set 1",
                "x1_2Set2": "Resultado del Set 2",
                "x1_2Set3": "Resultado del Set 3",
                "x1_2Set4": "Resultado del Set 4",
                "x1_2Set5": "Resultado del Set 5"
            },
            "volleyball": {
                "evenOddMatch": "Par / impar",
                "evenOddSet1": "Par / impar Set 1",
                "evenOddSet2": "Par / impar Set 2",
                "evenOddSet3": "Par / impar Set 3",
                "evenOddSet4": "Par / impar Set 4",
                "evenOddSet5": "Par / impar Set 5",
                "handicap": "Hándicap",
                "handicapSet1": "Hándicap Set 1",
                "handicapSet2": "Hándicap Set 2",
                "handicapSet3": "Hándicap Set 3",
                "handicapSet4": "Hándicap Set 4",
                "handicapSet5": "Hándicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Marcador exacto (sets)",
                "scoreAfter2Games": "Resultado después de 2 sets",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Resultado del partido",
                "x1_2Set1": "Resultado del Set 1",
                "x1_2Set2": "Resultado del Set 2",
                "x1_2Set3": "Resultado del Set 3",
                "x1_2Set4": "Resultado del Set 4",
                "x1_2Set5": "Resultado del Set 5"
            }
        },
        "tabs": {
            "americanFootball": {
                "all": "Todos",
                "halves": "Mitades",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "quarters": "Cuartos",
                "totals": "Total"
            },
            "baseball": {
                "all": "Todos",
                "halves": "Mitades",
                "handicap": "Hándicap",
                "handicaps": "Hándicap",
                "innings": "Entrada",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "basketball": {
                "all": "Todos",
                "halves": "Mitades",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "quarters": "Cuartos",
                "totals": "Total"
            },
            "football": {
                "all": "Todos",
                "goals": "Goles",
                "goalsPlusResult": "Anotará + Resultado",
                "goalsPlusTotal": "Anotará + Total",
                "halves": "Tiempos",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "resultPlusTotal": "Resultado + Total",
                "totals": "Total"
            },
            "handball": {
                "all": "Todos",
                "half1": "1er periodo",
                "half2": "2do periodo",
                "handicap": "Hándicap",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "hockey": {
                "all": "Todos",
                "handicaps": "Hándicap",
                "periods": "Periodos",
                "popular": "Popular",
                "totals": "Total"
            },
            "tableTennis": {
                "all": "Todos",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "tennis": {
                "all": "Todos",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "volleyball": {
                "all": "Todos",
                "handicaps": "Hándicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            }
        }
    }
};