import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { computed, ref, SetupContext } from "vue";
import { ConfirmationMethod } from "@sportaq/services/web/rest/messages/account/a_po_6_8_withdrawal";
import { VisaWithdrawalParams } from "@sportaq/model/web/withdrawal-params";
import { WithdrawalOrder } from "@sportaq/model/web/withdrawal-order";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { DialogState } from "@sportaq/vue/views/web/withdrawal/dialog-state";
import { ConfirmationResult, ConfirmationType } from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import { WithdrawalData } from "@sportaq/services/web/settings/settings-service";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { EVerifyField } from "@sportaq/model/common/user-accounts";

export const commonBanks = ["BBVA", "BCP", "INTERBANK", "AZTECA DEL PERU", "BANCO INTERAMERICANO", "BANCO RIPLEY PERU SA",
    "COMERCIO", "FALABELLA PERÚ S A", "FINANCIERO", "MIBANCO", "SANTANDER PERU", "SCOTIABANK PERU"];
export const equadorBanks = ["Banco de Ecuador", "Banco Guayaquil", "Banco del Pacífico", "Banco Pichincha",
    "Banco Central de Ecuador", "Banco Amazonas", "Banco Internacional", "Banco Nacional de Fomento",
    "Banco de Machala", "Banco Sudamericano", "Produbanco", "Banco Internacional", "Financiera de la República"];

const accountTypes = ["Cuenta corriente", " Cuenta de ahorros"];

export function useBankWithdrawalCommon (minAmount: number, context: SetupContext, banks: string[]) {
    const store = useWebStore();
    const settings = useWebSettings();
    const i18n = useI18n();
    const withdrawalData: WithdrawalData = settings.getWithdrawalData(store.user?.userId) ??
        {
            withdrawalBank: banks[0],
            withdrawalAccountType: accountTypes[0],
            cCINumber: "",
            department: "",
            district: "",
            province: "",
            email: store.user?.email ?? "",
            cardName: store.user?.firstName + " " + store.user?.lastName
        };
    const dialogState = ref<DialogState>(DialogState.InputAmount);
    const selectedBank = ref(withdrawalData.withdrawalBank);
    const selectedAccountType = ref(withdrawalData.withdrawalAccountType);
    const dni = ref(store.user?.IdentificationCode);
    const confirmationMethod = ref<ConfirmationMethod>();
    const withdrawalParams = ref<VisaWithdrawalParams>();
    const withdrawalOrder = ref<WithdrawalOrder>();
    const account = store.user?.userAccounts.getPrimaryAccount();
    const currency = account ? account.currencyMark : "";
    const restService = useWebRestService();
    const bankAccounts = computed(() => {
        const bankName = selectedBank.value;
        return settings.getBankAccounts(store.user?.userId, bankName);
    });
    const defaultAccountNumber = computed(() => {
        const bankName = selectedBank.value;
        const accounts = settings.getBankAccounts(store.user?.userId, bankName);
        return accounts.length > 0 ? accounts[accounts.length - 1] : "";
    });

    const verifiedFields = store.user?.verifiedFields;
    confirmationMethod.value = verifiedFields?.has(EVerifyField.email) ? EVerifyField.email : EVerifyField.phone;

    function close () {
        context.emit(CLOSE_EVENT);
    }

    function sendWithdrawalRequest () {
        const formData = withdrawalParams.value;
        if (!formData) {
            return;
        }
        if (formData.orderedSum < minAmount) {
            showMessageBox(i18n.t("webdesktop.withdrawal.minAmountError", { minAmount: minAmount + currency }));
            return;
        }
        if (store.user) {
            settings.saveBankAccount(store.user.userId, selectedBank.value, formData.fullNumber);
        }
        withdrawalData.withdrawalBank = selectedBank.value;
        withdrawalData.withdrawalAccountType = selectedAccountType.value;
        withdrawalData.cardName = formData.cardName;
        withdrawalData.email = formData.email;
        if (formData.department) {
            withdrawalData.department = formData.department;
        }
        if (formData.district) {
            withdrawalData.district = formData.district;
        }
        if (formData.cCINumber) {
            withdrawalData.cCINumber = formData.cCINumber;
        }
        if (formData.province) {
            withdrawalData.province = formData.province;
        }
        if (store.user) {
            settings.setWithdrawalData(store.user.userId, withdrawalData);
        }
        if (withdrawalParams.value && account && confirmationMethod.value) {
            restService.visaWithdrawal(account.accountNumber, account.currencyId, withdrawalParams.value,
                confirmationMethod.value).then(() => {
                dialogState.value = DialogState.CodeInput;
            });
        }
    }

    function onSubmit (formData: VisaWithdrawalParams) {
        if (dialogState.value === DialogState.InputAmount && confirmationMethod.value) {
            withdrawalParams.value = formData;
            sendWithdrawalRequest();
        } else if (dialogState.value === DialogState.Finish) {
            close();
        }
    }
    function onConfirmCodeClose () {
        close();
    }
    function onCodeSuccess (result: ConfirmationResult) {
        if (result.confirmationType === ConfirmationType.Withdrawal) {
            dialogState.value = DialogState.Finish;
            withdrawalOrder.value = result.withdrawalOrder;
        }
    }
    return {
        dialogState,
        banks,
        selectedBank,
        selectedAccountType,
        accountTypes,
        dni,
        confirmationMethod,
        withdrawalOrder,
        bankAccounts,
        defaultAccountNumber,
        withdrawalData,
        currency,
        DialogState,
        close,
        onSubmit,
        onConfirmCodeClose,
        onCodeSuccess
    };
}
