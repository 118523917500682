import { BettingEvent, DiffType } from "@sportaq/model/betting/events/event";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { MapOfSortedList } from "@sportaq/common/types/classes/map-of-list";
import { EventDiffsResponse } from "@sportaq/model/types/responses";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { appLogger } from "@sportaq/common/utils/logger";
import { PointSettings } from "@sportaq/model/common/point-settings";
import EventType from "@sportaq/common/enums/event-type";
import {
    CountryCounter
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/misc/country-counter";
import {
    SortedEventsKeeper
} from "@sportaq/vuex/modules/betting/non-reactive-storage/events/misc/sorted-events-keeper";
import { CountryKey } from "@sportaq/model/types/types";
import { DateTimeFormatter } from "@sportaq/common/utils/miscellaneous-params";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";

export class EventStorage {
    get totalEvents (): number {
        return this._totalEvents;
    }

    private readonly events = new Map<number, BettingEvent>();
    private confirmedAfterDisconnectEventPositionIds: number[] = [];
    private needEventsConfirmation = false;
    private _coldDataReceived = false;

    private readonly sortedEventsBySportType = new SortedEventsKeeper();
    private readonly countryMenuBySportType = new MapOfSortedList<number, CountryCounter, number>(
        (a: CountryCounter, b: CountryCounter) => a.id - b.id,
        (a: CountryCounter, b: number) => a.id - b
    );

    private _totalEvents: number = 0;

    constructor (private readonly eventType: EventType,
                 private readonly clockProvider: ClockProvider,
                 private readonly dateTimeFormatter: DateTimeFormatter) {
    }

    getEvent (id: number): BettingEvent | undefined {
        return this.events.get(id);
    }

    // Return non confirmed after reconnect position ids
    updateEventStorage (list: EventDiffsResponse[], settings: PointSettings): UpdateEventStorageResult {
        const today = this.clockProvider.getDate();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();
        const todayDate = today.getDate();
        const coefsMultiplier = this.eventType === EventType.LIVE ? settings.liveCoefMultiplier : settings.preMatchCoefMultiplier;
        const updateEventStorageResult = new UpdateEventStorageResult();
        for (const diff of list) {
            // For debug purposes
            /*
            if (diff.id === 2092397) {
                console.og(JSON.stringify(diff));
            }
            if (diff.sportTypeId === ESportType.Football) {
                console.og(`EventType ${this.eventType}, value ${JSON.stringify(diff)}`);
            }
            */
            if (diff.diffType === DiffType.COLD_HOT_DELIMITER) {
                this.handleColdHotDelimiter(updateEventStorageResult);
                continue;
            }

            const oldEvent = this.events.get(diff.positionId);
            if (oldEvent) {
                if (oldEvent.version === diff.version) {
                    if (this.needEventsConfirmation) {
                        this.confirmedAfterDisconnectEventPositionIds.push(diff.positionId);
                    }
                    continue; // Event doesn't change after reconnect
                } else if (oldEvent.version > diff.version) {
                    appLogger.logger.warn(`The stored ${this.eventType === EventType.LIVE ? "live" : "prematch"} event ${oldEvent.eventId} [position: ${oldEvent.positionId}] has a newer version [${oldEvent.version}] than the diff message [${diff.version}]. The diff message has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                    continue;
                }
            }
            try {
                switch (diff.diffType) {
                    case DiffType.NEW: {
                        if (oldEvent) {
                            this.deleteEvent(oldEvent, updateEventStorageResult);
                        }
                        const newEvent = BettingEvent.newBettingEvent(this.eventType, diff, this.dateTimeFormatter, todayYear, todayMonth, todayDate, coefsMultiplier);
                        this._totalEvents = this._totalEvents + 1;
                        this.events.set(newEvent.positionId, newEvent);
                        this.sortedEventsBySportType.add(newEvent);
                        this.addEventToCountryMenu(newEvent);
                        if (this.needEventsConfirmation) {
                            this.confirmedAfterDisconnectEventPositionIds.push(newEvent.positionId);
                        }
                        break;
                    }
                    case DiffType.UPDATE: {
                        if (!oldEvent) {
                            appLogger.logger.warn(`The ${this.eventType === EventType.LIVE ? "live" : "prematch"} event ${diff.eventId} [position: ${diff.positionId}] doesn't exist in the live events store. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                            continue;
                        }
                        oldEvent.updatedBettingEvent(diff, this.dateTimeFormatter, todayYear, todayMonth, todayDate, coefsMultiplier);
                        if (diff.startTime) {
                            this.sortedEventsBySportType.update(oldEvent);
                        }
                        break;
                    }
                    case DiffType.DELETE: {
                        if (!oldEvent) {
                            appLogger.logger.warn(`The ${this.eventType === EventType.LIVE ? "live" : "prematch"} event ${diff.eventId} [position: ${diff.positionId}] doesn't exist in the live events store. Event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`, undefined);
                            continue;
                        }
                        this.deleteEvent(oldEvent, updateEventStorageResult);
                        break;
                    }
                }
            } catch (e) {
                appLogger.logger.error(`Error update ${this.eventType === EventType.LIVE ? "live" : "prematch"} event:\n`, e as Error);
            }
        }
        return updateEventStorageResult;
    }

    disconnect () {
        this.confirmedAfterDisconnectEventPositionIds = [];
        this.needEventsConfirmation = true;
        this._coldDataReceived = false;
    }

    iterator (): IterableIterator<BettingEvent> {
        return this.events.values();
    }

    sortedEventsIterator (sportType: ESportType): IterableIterator<BettingEvent> {
        const bettingEvents = this.sortedEventsBySportType.get(sportType);
        return bettingEvents[Symbol.iterator]();
    }

    getCountBySportType (): Map<number, number> {
        return this.sortedEventsBySportType.counts();
    }

    getCountByCountry (sportType: ESportType): CountryCounter[] {
        const result = this.countryMenuBySportType.get(sportType);
        return result ?? [];
    }

    get coldDataReceived (): boolean {
        return this._coldDataReceived;
    }

    private handleColdHotDelimiter (updateEventStorageResult: UpdateEventStorageResult) {
        if (this.needEventsConfirmation) {
            const eventsForDelete: BettingEvent[] = [];
            this.events.forEach((value, key) => {
                if (!this.confirmedAfterDisconnectEventPositionIds.includes(key)) {
                    eventsForDelete.push(value);
                    updateEventStorageResult.nonConfirmedPositionIds.push(value.positionId);
                }
            });
            for (const event of eventsForDelete) {
                this.deleteEvent(event, updateEventStorageResult);
            }
            this.needEventsConfirmation = false;
            this.confirmedAfterDisconnectEventPositionIds = [];
        }
        this._coldDataReceived = true;
        switch (this.eventType) {
            case EventType.PRE_MATCH: {
                eventBus.emit(Events.COLD_HOT_DELIMITER_PREMATCH_RECEIVED);
                break;
            }
            case EventType.LIVE: {
                eventBus.emit(Events.COLD_HOT_DELIMITER_LIVE_RECEIVED);
                break;
            }
        }
    }

    private deleteEvent (event: BettingEvent, updateEventStorageResult: UpdateEventStorageResult) {
        this.events.delete(event.positionId);
        this._totalEvents = this._totalEvents - 1;
        this.sortedEventsBySportType.remove(this.eventType, event.sportTypeId, event.positionId);
        this.removeEventFromCountryMenu(event, updateEventStorageResult);
    }

    private addEventToCountryMenu (event: BettingEvent) {
        let counter = this.countryMenuBySportType.getItem(event.sportTypeId, event.partition.countryId);
        if (!counter) {
            counter = new CountryCounter(event.partition.countryId);
            this.countryMenuBySportType.set(event.sportTypeId, counter);
        }
    }

    private removeEventFromCountryMenu (event: BettingEvent, updateEventStorageResult: UpdateEventStorageResult) {
        const counter = this.countryMenuBySportType.getItem(event.sportTypeId, event.partition.countryId);
        if (counter) {
            const removedId = counter.remove(event);
            if (removedId) {
                updateEventStorageResult.removePartitions.push(removedId);
            }
            if (counter.count <= 0) {
                this.countryMenuBySportType.delete(event.sportTypeId, counter.id);
                updateEventStorageResult.removedCountries.push(new CountryKey(this.eventType, event.sportTypeId, counter.id));
            }
        }
    }
}

export class UpdateEventStorageResult {
    readonly nonConfirmedPositionIds: number[] = [];
    readonly removedCountries: CountryKey[] = [];
    readonly removePartitions: number[] = [];
}
