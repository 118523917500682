import { Point } from "@sportaq/model/web/point";
import { ref } from "vue";
import { Ref } from "@sportaq/common/types/types";
import { WebDesktopRestService } from "@sportaq/services/web/rest/web-desktop-rest-service";
import { PointSettings } from "@sportaq/model/common/point-settings";

export function usePointList (restService: WebDesktopRestService, settings: PointSettings) : Ref<Point[]> {
    const result = ref<Point[]>([]);
    restService.getPointList(2, [settings.partnerId]).then(p => {
        result.value = p;
    });
    return result;
}
