import { Store } from "vuex";

export default abstract class AbstractStore<T, Getters, Mutations, Actions> {
    protected constructor (public readonly store: Store<T>, private moduleName: string) {
    }

    protected get (getter: Getters) {
        if (this.moduleName) {
            return this.store.getters[`${(this.moduleName)}/${getter}`];
        } else {
            return this.store.getters[`${getter}`];
        }
    }

    protected mutate (mutation: Mutations, payload?: unknown) {
        if (this.moduleName) {
            this.store.commit(`${(this.moduleName)}/${mutation}`, payload);
        } else {
            this.store.commit(`${mutation}`, payload);
        }
    }

    protected action<M> (action: Actions, payload?: unknown): Promise<M> {
        if (this.moduleName) {
            return this.store.dispatch(`${(this.moduleName)}/${action}`, payload);
        } else {
            return this.store.dispatch(`${action}`, payload);
        }
    }
}
