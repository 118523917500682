import { ESportType } from "@sportaq/common/enums/sport-type";
import { QLE12AbstractHandler } from "@sportaq/services/rest/messages/events/q_le_1_2/q_le_1_2-abstract-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import EventType from "@sportaq/common/enums/event-type";

export class QLE12PopularEventsMessageHandler extends QLE12AbstractHandler {
    constructor (readonly sportTypes: ESportType[], readonly count: number) {
        super(EventType.PRE_MATCH);
    }

    protected fillFilter (request: XmlRequest, filter: Element): void {
        const sportTypeList = request.addChild(filter, "SportTypeList");
        for (const sportType of this.sportTypes) {
            request.addChildWithValue(sportTypeList, "SportTypeId", sportType.toString());
        }
        request.addChildWithValue(filter, "LineUsageStatistics", "PopularBetting", { count: this.count.toString() });
    }
}
