import { useAuthorizationService, useSettings } from "@sportaq/services/index";
import {
    ConstantItemsPanelSelectedResult
} from "@sportaq/vue/components/common/constant-items-panel/constant-items-panel-component";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { computed, onMounted, ref, SetupContext } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { StartGameInfo } from "@sportaq/model/games/start-game-info";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { useCasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { ImageDictionaryItem, Ref } from "@sportaq/common/types/types";

const TIMEOUT = 100;

export function useGamesContent (gameType: ECasinoGameType, context: SetupContext) {
    const store = useCasinoGamesStore();
    const i18n = useI18n();
    const settings = useSettings();
    const router = useRouter();
    const route = useRoute();
    const supplier = useCasinoGamesSupplier();
    const balanceStore = useBalanceStore();
    const authorizationService = useAuthorizationService();

    const isDirectLink = ref(!!route.params.gameId);
    const directLinkGame: Ref<CasinoGame | undefined> = ref(undefined);
    const selectedItem: Ref<ImageDictionaryItem | undefined> = ref();
    const currentGame = ref<StartGameInfo>();

    const forceOpenSelectedTabByClick = computed(() => currentGame.value !== undefined);

    // It's just a common function
    function setMaskFilter (mask: number) {
        store.updateGameFilter({
            changeClassifierMask: {
                type: "set",
                value: mask
            }
        });
    }

    async function commonSelectTopMenu (result: ConstantItemsPanelSelectedResult, auto: boolean) {
        if (!auto) {
            clearDirectLink();
        }
        currentGame.value = undefined;
        selectedItem.value = result.selectedItem;
    }

    function clearDirectLink () {
        isDirectLink.value = false;
        directLinkGame.value = undefined;
    }

    function startGame (item: CasinoGame) {
        clearDirectLink();
        if (!authorizationService.isUserAuthorize()) {
            return;
        }
        if (balanceStore.balance.value === 0 && balanceStore.bonus.value === 0) {
            showMessageBox(i18n.t("common.games.zeroBalance"));
            return;
        }
        currentGame.value = {
            gameType: item.gameType,
            gameId: item.id,
            demo: false
        };
        context.emit("startGame");
    }

    function startDemo (item: CasinoGame) {
        clearDirectLink();
        currentGame.value = {
            gameType: item.gameType,
            gameId: item.id,
            demo: true
        };
        context.emit("startGame");
    }

    function closeGame () {
        clearDirectLink();
        currentGame.value = undefined;
    }

    function directLinkClose () {
        clearDirectLink();
    }

    function refreshPage () {
        if (route.params.gameId) {
            const gameId = decodeURIComponent(route.params.gameId as string);
            const interval = setInterval(() => {
                const casinoGame = supplier.getOptionalGame(gameId);
                if (casinoGame) {
                    directLinkGame.value = casinoGame;
                    clearInterval(interval);
                } else if (supplier.isColdDataReceived()) {
                    clearDirectLink();
                    clearInterval(interval);
                }
            }, TIMEOUT);
        }
    }

    onMounted(async () => {
        if (!isServiceEnabled(settings, EGameService.Virtual)) {
            await router.push({ path: "/" });
            return;
        }
        store.updateGameFilter({
            changeGameType: {
                type: "set",
                value: gameType
            }
        });
        refreshPage();
    });

    return {
        store,
        selectedItem,
        forceOpenSelectedTabByClick,
        currentGame,

        isDirectLink,
        directLinkGame,

        setMaskFilter,
        commonSelectTopMenu,
        startGame,
        startDemo,
        closeGame,
        directLinkClose
    };
}
