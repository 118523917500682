import { useWebRestService } from "@sportaq/services/web";
import { Card } from "@sportaq/model/cashier/card";
import { resolveLanguage } from "@sportaq/i18n/index";
import { computed, ref, watch } from "vue";

export function useAllStakesComponentCommon (props: {readonly onlyCalculated: boolean | undefined}) {
    const restService = useWebRestService();
    const cardsRef = ref<Card[]>([]);
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const startDateRef = ref(startDate.toISOString().split("T")[0]);
    const endDateRef = ref(new Date().toISOString().split("T")[0]);
    const itemsPerPageRef = ref<number>(10);
    const currentPage = ref<number>(1);
    const totalPages = computed(() => Math.ceil(cardsRef.value.length / itemsPerPageRef.value));
    const requestPending = ref(false);
    const cardsPaged = computed(() => {
        const offset = (currentPage.value - 1) * itemsPerPageRef.value;
        return cardsRef.value.slice(offset).slice(0, itemsPerPageRef.value);
    });
    updateCards();
    watch(itemsPerPageRef, () => {
        currentPage.value = 1;
    });
    function updateCards () {
        const endDate = new Date(endDateRef.value);
        endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
        const startDate = new Date(startDateRef.value);
        startDate.setHours(0, 0, 0, 0);
        requestPending.value = true;
        restService.getCardsByDate(resolveLanguage(), startDate, endDate, props.onlyCalculated !== undefined && props.onlyCalculated)
            .then((c) => {
                cardsRef.value = c;
                requestPending.value = false;
            });
    }
    watch(startDateRef, updateCards);
    watch(endDateRef, updateCards);
    watch(props, updateCards);
    return {
        cardsPaged,
        startDateRef,
        endDateRef,
        itemsPerPageRef,
        cardsRef,
        currentPage,
        totalPages,
        requestPending
    };
}
