import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredChild, getRequiredFloatAttribute,
    getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";

export class QCD10CurrencyInfo extends AbstractMessageHandler<Map<number, number>> {
    protected readonly requestType: string = "Q.CD.1.0";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "query", {
            type: this.requestType
        });
    }

    parseMessageBody (body: Element): Map<number, number> {
        const query = getRequiredChild(body, "query");
        const listElement = getRequiredChild(query, "CurrencyValueList");
        const result = new Map<number, number>();
        if (listElement) {
            for (let currencyElement: Element | null = listElement.firstElementChild; currencyElement !== null; currencyElement = currencyElement?.nextElementSibling) {
                result.set(getRequiredIntAttribute(currencyElement, "currencyId"), getRequiredFloatAttribute(currencyElement, "value"));
            }
        }
        return result;
    }
}
