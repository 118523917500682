import { RootStore } from "@sportaq/vuex/index";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import {
    EventViewFilter,
    FilterChanger
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { ScoreboardSelection } from "@sportaq/model/betting/view/scoreboard-selection";
import EventType from "@sportaq/common/enums/event-type";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { ScoreboardVuex } from "@sportaq/vuex/modules/betting/scoreboard/internal/scoreboard-vuex";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { IdCountItem, IdItem } from "@sportaq/common/types/types";
import { CountryKey } from "@sportaq/model/types/types";
import { CountryCounter } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/misc/country-counter";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { LiveEventResult } from "@sportaq/vuex/modules/betting/scoreboard/live-event-data";
import EDetailRow from "@sportaq/common/enums/detail-row";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { Route } from "@sportaq/common/types/interfaces";

export interface ScoreboardStore {
    leagueMode: boolean;
    liveReverseTimeSorting: boolean;
    scoreboardPageType: EScoreboardPageType;
    detailTitlesExpanded: boolean;

    filterMenuActive: boolean;

    totalPreMatchEvents: number;
    totalLiveEvents: number;

    getSportTypes (): IdCountItem[];

    getPeriodsView (): PeriodSortInfo[];

    getGroupEventsView (): PeriodSortInfo[];

    getCountryMenuItems (): CountryCounter[];

    updateEventsFilters (changers: FilterChanger[]): void;

    updateEventsFilter (changer: FilterChanger): void;

    clearEventsFilter(): void;

    getEventsViewFilter (): EventViewFilter;

    getScoreboardSelection (): ScoreboardSelection;

    setScoreboardSelection (route: Route, selection: ScoreboardSelection): void;

    isExpandedEventId (eventId: number): boolean;

    toggleAllExpanded (): void;

    toggleExpanded (eventType: EventType, eventId: number): void;

    isAllExpanded (): boolean;

    // Event details
    toggleDetailTitlesExpanded (): void;

    isDetailRowPinned (sportType: ESportType, id: EDetailRow): boolean;

    getDetailRowPinned (sportType: ESportType): EDetailRow[];

    toggleDetailRowPinned (sportType: ESportType, id: EDetailRow): void;

    // Favourites

    toggleFavouriteItem (eventType: EventType, positionId: number): void;

    isFavourite (positionId: number): void;

    getFavouritesCount (): number;

    toggleFavouriteCountry (item: CountryKey): void;

    isCountryFavourite (key: CountryKey): boolean;

    toggleFavouritePartition (country: CountryKey, item: IdItem): void;

    isPartitionFavourite (country: CountryKey, item: IdItem): boolean;

    getLiveResults (): LiveEventResult[];

    shiftLiveEvents (count: number): void;

    setNeedLiveResults (value: boolean): void;

    init (): void;

    destroy (): void;
}

export function provideScoreboardStore (rootStore: RootStore, eventSupplier: EventSupplier): ScoreboardStore {
    const bettingStore = new ScoreboardVuex.ScoreboardStoreImpl(rootStore.store, eventSupplier);
    provide(scoreboardStoreSymbol, bettingStore);
    return bettingStore;
}

export function provideInjectedScoreboardStore (bettingStore: ScoreboardStore) {
    provide(scoreboardStoreSymbol, bettingStore);
}

export function useScoreboardStore (): ScoreboardStore {
    return use(scoreboardStoreSymbol);
}

export const scoreboardStoreSymbol = Symbol("Betting Scoreboard Store");
