import { useWebRestService } from "@sportaq/services/web";
import { computed, ref, watch } from "vue";
import { useRootStore } from "@sportaq/vuex/index";
import { AccountCategory, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { useSettings } from "@sportaq/services/index";
import { resolveLanguage } from "@sportaq/i18n/index";

export function useNavigationViewCommon () {
    const rootStore = useRootStore();
    const restService = useWebRestService();
    const sportBalance = ref("");
    const sportBalanceAvailable = ref("");
    const theme = computed(() => rootStore.theme);
    const balanceStore = useBalanceStore();
    const settings = useSettings();

    const isRaceAvailable = computed(() => {
        const lang = resolveLanguage();
        return settings.brandType !== ECompileBrandType.certabet || lang !== "ru";
    });

    const activeAccount = computed(() => {
        return balanceStore.activeAccount;
    });

    if (balanceStore.activeAccount === AccountCategory.Main || balanceStore.activeAccount === AccountCategory.Other) {
        fetchBalance();
    } else {
        watch(activeAccount, () => {
            if (activeAccount.value === AccountCategory.Main) {
                fetchBalance();
            }
        });
    }

    function fetchBalance () {
        restService.getExtendedBalance().then(b => {
            let balance = 0;
            let currencyMark = "";
            if (b.mainBalance && b.mainBalance.primaryAccount) {
                balance = b.mainBalance.primaryAccount.sumAccount;
                sportBalanceAvailable.value = (balance > b.mainBalance.primaryAccount.sumCaptive
                    ? (balance - b.mainBalance.primaryAccount.sumCaptive)
                    : 0).toFixed(2) + " " + b.mainBalance.primaryAccount.currencyMark;
                if (b.mainBalance.bonusAccount) {
                    balance += b.mainBalance.bonusAccount.sumAccount;
                }
                currencyMark = b.mainBalance.primaryAccount.currencyMark;
            }
            if (b.casinoBalance && b.casinoBalance.primaryAccount) {
                balance += b.casinoBalance.primaryAccount.sumAccount;
                if (b.casinoBalance.bonusAccount) {
                    balance += b.casinoBalance.bonusAccount.sumAccount;
                }
            }
            sportBalance.value = balance.toFixed(2) + " " + currencyMark;
        });
    }
    return {
        isRaceAvailable,
        theme,
        sportBalance,
        sportBalanceAvailable
    };
}
