<template>
    <div class="navigation-arrows">
        <div class="shape"></div>
        <a href="" class="button button-general" @click.prevent @mousedown.prevent="scrollUp" data-cy="place-bet-button">
            <img src="@assets/img/nav-down.png" alt=""/>
        </a>
        <a href="" class="button button-general" @click.prevent @mousedown.prevent="scrollDown" data-cy="place-bet-button">
            <img src="@assets/img/nav-up.png" alt=""/>
        </a>
    </div>
</template>

<script src="./scroll-buttons-component.ts" />
