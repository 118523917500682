import { ESportType } from "@sportaq/common/enums/sport-type";
import EPeriodType from "@sportaq/common/enums/period-types";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import EDetailRow from "@sportaq/common/enums/detail-row";
import {
    getAmericanFootballHalf1Info,
    getAmericanFootballHalf2Info,
    getAmericanFootballMainInfo,
    getAmericanFootballPeriod1Info,
    getAmericanFootballPeriod2Info,
    getAmericanFootballPeriod3Info,
    getAmericanFootballPeriod4Info
} from "@sportaq/model/betting/view/event-details/periods/americanfootball-event-details-info";
import {
    getBaseballHalf1Info,
    getBaseballMainInfo,
    getBaseballPeriod1Info,
    getBaseballPeriod2Info,
    getBaseballPeriod3Info,
    getBaseballPeriod4Info,
    getBaseballPeriod5Info,
    getBaseballPeriod6Info,
    getBaseballPeriod7Info,
    getBaseballPeriod8Info,
    getBaseballPeriod9Info
} from "@sportaq/model/betting/view/event-details/periods/baseball-event-details-info";
import {
    getFootballMainInfo,
    getFootballPeriod1Info,
    getFootballPeriod2Info
} from "@sportaq/model/betting/view/event-details/periods/football-event-details-info";
import {
    getBasketballHalf1Info,
    getBasketballHalf2Info,
    getBasketballMainInfo,
    getBasketballPeriod1Info,
    getBasketballPeriod2Info,
    getBasketballPeriod3Info,
    getBasketballPeriod4Info
} from "@sportaq/model/betting/view/event-details/periods/basketball-event-details-info";
import {
    getHandballMainInfo,
    getHandballPeriod1Info,
    getHandballPeriod2Info
} from "@sportaq/model/betting/view/event-details/periods/handball-event-details-info";
import {
    getHockeyMainInfo,
    getHockeyPeriod1Info,
    getHockeyPeriod2Info,
    getHockeyPeriod3Info
} from "@sportaq/model/betting/view/event-details/periods/hockey-event-details-info";
import {
    getVolleyballMainInfo,
    getVolleyballPeriod1Info,
    getVolleyballPeriod2Info,
    getVolleyballPeriod3Info,
    getVolleyballPeriod4Info,
    getVolleyballPeriod5Info
} from "@sportaq/model/betting/view/event-details/periods/volleyball-event-details-info";
import {
    getTableTennisMainInfo,
    getTableTennisPeriod1Info,
    getTableTennisPeriod2Info,
    getTableTennisPeriod3Info,
    getTableTennisPeriod4Info,
    getTableTennisPeriod5Info
} from "@sportaq/model/betting/view/event-details/periods/tabletennis-event-details-info";
import {
    getTennisMainInfo,
    getTennisPeriod1Info,
    getTennisPeriod2Info,
    getTennisPeriod3Info,
    getTennisPeriod4Info,
    getTennisPeriod5Info
} from "@sportaq/model/betting/view/event-details/periods/tennis-event-details-info";
import { QuotationKey, QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { EQuotation } from "@sportaq/common/consts/quotation-consts";
import { binarySearchIndex, insertUniqueIntoSortedArray } from "@sportaq/common/utils/arrays";

export const PINNED_DETAIL_TAB_ID = -999;

export interface CardInfo {
    period: EPeriodType;
    tabs: DetailTab[];
}

export interface QuotationRow {
    id: EDetailRow;
    title: string;
    quotations: QuotationWrapper[][];
    p1: BigDecimal | undefined;
}

export interface DetailTab {
    id: number;
    title: string;
    rows: DetailRow[];
}

export interface DetailRow {
    id: EDetailRow;
    title: string;
    quotationIds: number[][];
}

class EventDetailsInfoHolder {
    private readonly map = new Map<ESportType, CardInfo[]>();
    private readonly quotationsByPeriods = new Map<ESportType, Map<number, EPeriodType[]>>();
    private readonly quotationTitleByQuotationId = new Map<ESportType, Map<number, string>>();

    getCardInfo (sportType: ESportType, period: EPeriodType, event: BettingEvent, pinnedDetailRows: EDetailRow[]): DetailTab[] {
        const infos = this.map.get(sportType);
        const result: DetailTab[] = [];
        const pinnedDetailTab: DetailTab = {
            id: PINNED_DETAIL_TAB_ID,
            title: "",
            rows: []
        };
        if (infos) {
            const info = infos.find(value => value.period === period);
            if (info) {
                for (const tab of info.tabs) {
                    let needPushTab = false;
                    for (const row of tab.rows) {
                        const isRowPinned = binarySearchIndex(pinnedDetailRows, row.id, (a, b) => a - b) >= 0;
                        if ((!needPushTab || isRowPinned) && EventDetailsInfoHolder.isEventContainsQuotations(event, row.quotationIds)) {
                            needPushTab = true;
                            if (isRowPinned) {
                                insertUniqueIntoSortedArray(pinnedDetailTab.rows, row, (a, b) => a.id - b.id);
                            }
                        }
                    }
                    if (needPushTab) {
                        result.push(tab);
                    }
                }
                // issue #55. If details is empty, all tabs should be shown
                if (result.length === 0) {
                    return [pinnedDetailTab, ...info.tabs];
                } else {
                    result.splice(0, 0, pinnedDetailTab);
                }
            }
        }
        return result;
    }

    getPeriodTabIdByQuotationId (sportType: ESportType, period: EPeriodType, quotationId: number): number | undefined {
        const infos = this.map.get(sportType);
        if (infos) {
            const info = infos.find(value => value.period === period);
            if (info) {
                for (let index = info.tabs.length - 1; index >= 0; index--) {
                    const tab = info.tabs[index];
                    for (const row of tab.rows) {
                        for (const arr of row.quotationIds) {
                            if (arr.some(q => q === quotationId)) {
                                return tab.id;
                            }
                        }
                    }
                }
            }
        }
        return undefined;
    }

    private static isEventContainsQuotations (event: BettingEvent, quotationIds: number[][]): boolean {
        for (const arr of quotationIds) {
            for (const id of arr) {
                const list = event.quotations.getQuotationsByQuotationId(id);
                if (list.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    constructor () {
        this.map.set(ESportType.AmericanFootball, [
            {
                period: EPeriodType.MAIN,
                tabs: getAmericanFootballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getAmericanFootballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getAmericanFootballPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getAmericanFootballPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getAmericanFootballPeriod4Info()
            },
            {
                period: EPeriodType.HALF_1,
                tabs: getAmericanFootballHalf1Info()
            },
            {
                period: EPeriodType.HALF_2,
                tabs: getAmericanFootballHalf2Info()
            }
        ]);
        this.map.set(ESportType.Baseball, [
            {
                period: EPeriodType.MAIN,
                tabs: getBaseballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getBaseballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getBaseballPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getBaseballPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getBaseballPeriod4Info()
            },
            {
                period: EPeriodType.PERIOD_5,
                tabs: getBaseballPeriod5Info()
            },
            {
                period: EPeriodType.PERIOD_6,
                tabs: getBaseballPeriod6Info()
            },
            {
                period: EPeriodType.PERIOD_7,
                tabs: getBaseballPeriod7Info()
            },
            {
                period: EPeriodType.PERIOD_8,
                tabs: getBaseballPeriod8Info()
            },
            {
                period: EPeriodType.PERIOD_9,
                tabs: getBaseballPeriod9Info()
            },
            {
                period: EPeriodType.HALF_1,
                tabs: getBaseballHalf1Info()
            }
        ]);
        this.map.set(ESportType.Football, [
            {
                period: EPeriodType.MAIN,
                tabs: getFootballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getFootballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getFootballPeriod2Info()
            }
        ]);
        this.map.set(ESportType.Basketball, [
            {
                period: EPeriodType.MAIN,
                tabs: getBasketballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getBasketballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getBasketballPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getBasketballPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getBasketballPeriod4Info()
            },
            {
                period: EPeriodType.HALF_1,
                tabs: getBasketballHalf1Info()
            },
            {
                period: EPeriodType.HALF_2,
                tabs: getBasketballHalf2Info()
            }
        ]);
        this.map.set(ESportType.Handball, [
            {
                period: EPeriodType.MAIN,
                tabs: getHandballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getHandballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getHandballPeriod2Info()
            }
        ]);
        this.map.set(ESportType.Hockey, [
            {
                period: EPeriodType.MAIN,
                tabs: getHockeyMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getHockeyPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getHockeyPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getHockeyPeriod3Info()
            }
        ]);
        this.map.set(ESportType.Volleyball, [
            {
                period: EPeriodType.MAIN,
                tabs: getVolleyballMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getVolleyballPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getVolleyballPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getVolleyballPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getVolleyballPeriod4Info()
            },
            {
                period: EPeriodType.PERIOD_5,
                tabs: getVolleyballPeriod5Info()
            }
        ]);
        this.map.set(ESportType.TableTennis, [
            {
                period: EPeriodType.MAIN,
                tabs: getTableTennisMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getTableTennisPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getTableTennisPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getTableTennisPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getTableTennisPeriod4Info()
            },
            {
                period: EPeriodType.PERIOD_5,
                tabs: getTableTennisPeriod5Info()
            }
        ]);
        this.map.set(ESportType.Tennis, [
            {
                period: EPeriodType.MAIN,
                tabs: getTennisMainInfo()
            },
            {
                period: EPeriodType.PERIOD_1,
                tabs: getTennisPeriod1Info()
            },
            {
                period: EPeriodType.PERIOD_2,
                tabs: getTennisPeriod2Info()
            },
            {
                period: EPeriodType.PERIOD_3,
                tabs: getTennisPeriod3Info()
            },
            {
                period: EPeriodType.PERIOD_4,
                tabs: getTennisPeriod4Info()
            },
            {
                period: EPeriodType.PERIOD_5,
                tabs: getTennisPeriod5Info()
            }
        ]);
        this.updateAggregations();
    }

    getQuotationsByPeriods (sportType: ESportType): Map<number, EPeriodType[]> | undefined {
        return this.quotationsByPeriods.get(sportType);
    }

    getQuotationTitle (sportType: ESportType, quotationKey: QuotationKey): string | undefined {
        switch (quotationKey.quotationId) {
            case EQuotation.FOOTBALL_MATCH_INTERVAL_YES:
            case EQuotation.FOOTBALL_MATCH_INTERVAL_NO: {
                return `detailInfo.groups.football.goalIntervalx${quotationKey.p1.toIntString()}x${quotationKey.p2.toIntString()}`;
            }
            case EQuotation.FOOTBALL_TEAM1_INTERVAL_YES:
            case EQuotation.FOOTBALL_TEAM1_INTERVAL_NO: {
                return `detailInfo.groups.football.goalIntervalTeam1x${quotationKey.p1.toIntString()}x${quotationKey.p2.toIntString()}`;
            }
            case EQuotation.FOOTBALL_TEAM2_INTERVAL_YES:
            case EQuotation.FOOTBALL_TEAM2_INTERVAL_NO: {
                return `detailInfo.groups.football.goalIntervalTeam2x${quotationKey.p1.toIntString()}x${quotationKey.p2.toIntString()}`;
            }
            case EQuotation.GROUP_EVENT_WINNER: {
                return "betting.event.groupEvents.winner";
            }
            case EQuotation.GROUP_EVENT_LEAVE_THE_GROUP: {
                return "betting.event.groupEvents.leaveTheGroup";
            }
            case EQuotation.GROUP_EVENT_STAY_THE_GROUP: {
                return "betting.event.groupEvents.stayTheGroup";
            }
            case EQuotation.GROUP_EVENT_PLACE_IN_GROUP: {
                return "betting.event.groupEvents.placeInGroup";
            }
        }

        return this.quotationTitleByQuotationId.get(sportType)?.get(quotationKey.quotationId);
    }

    // Mapping each quotation's id to list of periods by type of sports
    private updateAggregations () {
        this.map.forEach((cards, sport) => {
            this.fillQuotationsByPeriods(sport, cards);
            this.fillQuotationTitleByQuotationId(sport, cards);
        });
    }

    private fillQuotationsByPeriods (sport: ESportType, cards: CardInfo[]) {
        const quotationsByPeriods = new Map<number, EPeriodType[]>();
        this.quotationsByPeriods.set(sport, quotationsByPeriods);
        for (const card of cards) {
            const period = card.period;
            card.tabs.forEach(tab =>
                tab.rows.forEach(row =>
                    row.quotationIds.forEach(ids => ids.forEach(id => {
                        let list = quotationsByPeriods.get(id);
                        if (!list) {
                            list = [];
                            quotationsByPeriods.set(id, list);
                        }
                        const index = list.findIndex(value => value === period);
                        if (index === -1) {
                            list.push(period);
                        }
                    }))));
        }
    }

    private fillQuotationTitleByQuotationId (sport: ESportType, cards: CardInfo[]) {
        const quotationTitleByQuotationId = new Map<number, string>();
        this.quotationTitleByQuotationId.set(sport, quotationTitleByQuotationId);
        for (const card of cards) {
            card.tabs.forEach(tab =>
                tab.rows.forEach(row =>
                    row.quotationIds.forEach(ids => ids.forEach(id => {
                        if (!quotationTitleByQuotationId.get(id)) {
                            quotationTitleByQuotationId.set(id, row.title);
                        }
                    }))));
        }
    }
}

export const eventDetailsInfoHolder = new EventDetailsInfoHolder();
