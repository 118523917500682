import { ref, SetupContext } from "vue";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useKeyHandler } from "@sportaq/vue/components/common/dialog-key-handler/dialog-key-handler";
import { CLOSE_EVENT, EVENT_OPEN_DIALOG } from "@sportaq/vue/components/web/dialogs/events";
import { useGoogleTagService } from "@sportaq/services/index";
import { useRoute, useRouter } from "vue-router";
import { logAnalyticsEvent } from "@sportaq/common/utils/analytics/webview-analytics";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export function useLoginDialog (context: SetupContext) {
    const isPasswordVisible = ref(false);
    const loginRef = ref();
    const passwordRef = ref("");
    const saveSessionRef = ref(false);
    const isOpen = ref(false);
    const restService = useWebRestService();
    const settingsService = useWebSettings();
    const store = useWebStore();
    const googleTag = useGoogleTagService();
    const route = useRoute();
    const i18n = useI18n();
    const router = useRouter();

    useKeyHandler(close, doLogin);

    function switchPasswordVisible () {
        isPasswordVisible.value = !isPasswordVisible.value;
    }

    function close () {
        isOpen.value = false;
    }

    function onForgetPasswordClick () {
        isOpen.value = false;
        context.emit(EVENT_OPEN_DIALOG, "ResetPasswordDialog");
    }

    function onRegistrationClick () {
        context.emit(CLOSE_EVENT);
        router.push({ name: "registration" }).then();
    }

    function doLogin () {
        if (!loginRef.value || !passwordRef.value) {
            return;
        }
        restService.authorization({
            login: loginRef.value,
            password: passwordRef.value
        }).then(u => {
            if (passwordRef.value.length < 6) {
                showMessageBox(i18n.t("common.tempPassword"));
            }
            store.user = u;
            if (restService.session) {
                settingsService.storeSessionCode(restService.session, saveSessionRef.value);
            }
            restService.updateCountryFilterFavourites(u.countryId).then();
            logAnalyticsEvent("login_success", {
                userNumber: store.user.userNumber,
                date: new Date().toISOString()
            });
            googleTag.sendSuccessLoginEvent();
            if (route.name === "registration") {
                router.push({ path: "/" }).then();
            }
            context.emit(CLOSE_EVENT);
        });
    }

    return {
        isPasswordVisible,
        loginRef,
        passwordRef,
        saveSessionRef,
        isOpen,

        switchPasswordVisible,
        doLogin,
        onForgetPasswordClick,
        onRegistrationClick
    };
}
