import { useWebRestService } from "@sportaq/services/web";
import { WagerContainer, WC_TYPE_COMMON } from "@sportaq/model/cashier/wager-container";
import { computed, onMounted, ref, watch } from "vue";

export function useCasinoStakeListCommon () {
    const restService = useWebRestService();
    const stakes = ref<WagerContainer[]>([]);
    const requestPending = ref(false);
    const hasMoreElements = ref(true);
    const startDate = ref(new Date());
    startDate.value.setMonth(startDate.value.getMonth() - 1);
    startDate.value.setHours(0, 0, 0, 0);
    const endDate = ref(new Date());
    endDate.value.setHours(23, 59, 59, 0);
    const currentPage = ref<number>(1);
    const itemsPerPageRef = ref<number>(10);
    const totalPages = computed(() => Math.ceil(stakes.value.length / itemsPerPageRef.value));
    const operationsPaged = computed(() => {
        const offset = (currentPage.value - 1) * itemsPerPageRef.value;
        return stakes.value.slice(offset).slice(0, itemsPerPageRef.value);
    });
    watch(itemsPerPageRef, () => {
        currentPage.value = 1;
    });
    const startDateComputed = computed(
        {
            get: () => {
                return startDate.value.toISOString().split("T")[0];
            },
            set: (value) => {
                startDate.value = new Date(value);
            }
        }
    );
    const endDateComputed = computed(
        {
            get: () => {
                return endDate.value.toISOString().split("T")[0];
            },
            set: (value) => {
                endDate.value = new Date(value);
            }
        }
    );
    function loadMore () {
        loadStakes();
    }

    function reload () {
        stakes.value = [];
        loadStakes();
    }

    watch(startDate, reload);
    watch(endDate, reload);

    function loadStakes () {
        requestPending.value = true;
        const s = new Date(startDate.value);
        const e = new Date(endDate.value);
        restService.getWagerContainers(s, e).then(value => {
            requestPending.value = false;
            if (value.length === 0) {
                hasMoreElements.value = false;
                return;
            }
            const containers: WagerContainer[] = stakes.value;
            stakes.value = containers.concat(value);
        });
    }

    function getWagerTitle (wc: WagerContainer) {
        if (wc.wcType === WC_TYPE_COMMON) {
            return wc.item.gameMark;
        }
        return "";
    }
    onMounted(() => loadStakes());

    return {
        stakes,
        requestPending,
        hasMoreElements,
        WC_TYPE_COMMON,
        startDate,
        endDate,
        startDateComputed,
        endDateComputed,
        itemsPerPageRef,
        totalPages,
        operationsPaged,
        currentPage,
        loadStakes,
        loadMore,
        getWagerTitle
    };
}
