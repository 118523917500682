import { CasinoGamesVuex } from "@sportaq/vuex/modules/games/internal/casino-games-vuex";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { SortedArray } from "@sportaq/common/utils/sorted-data-types/sorted-array";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";

export namespace CasinoGamesVuexGetters {
    export function isFavourite (state: CasinoGamesVuex.CasinoGamesStoreState) {
        return (item: GameSortInfo) => getFavourites(state).contains(item.id);
    }

    export function getFavouritesCount (state: CasinoGamesVuex.CasinoGamesStoreState): number {
        return getFavourites(state).items.length;
    }

    export function getFavourites (state: CasinoGamesVuex.CasinoGamesStoreState): SortedArray<string> {
        if (!state.gameViewFilter.gameType) {
            return new SortedArray<string>((a, b) => a.localeCompare(b));
        }
        switch (state.gameViewFilter.gameType) {
            case ECasinoGameType.Virtual: {
                return state.virtualFavourites;
            }
            case ECasinoGameType.Casino : {
                return state.casinoFavourites;
            }
        }
    }
}
