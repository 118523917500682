import EmailRegistration from "@/views/registration-view/RegistrationView.vue";
import { useLandingCommon } from "@sportaq/vue/views/web/landings/landing-common";
export default {
    components: {
        EmailRegistration
    },
    name: "LandingSolo",
    setup () {
        return useLandingCommon(["/landings/landing-solo/css/style.css"]);
    }
};
