import EPeriodType from "@sportaq/common/enums/period-types";
import EventType from "@sportaq/common/enums/event-type";

export enum EDetailsViewType {
    Inline = 1, Standalone = 2, Mobile = 3, Popup
}

export function getDetailsViewTypeSuffix (type: EDetailsViewType) {
    switch (type) {
        case EDetailsViewType.Inline:
            return "-inline";
        case EDetailsViewType.Standalone:
            return "-standalone";
        case EDetailsViewType.Mobile:
            return "-mobile";
        case EDetailsViewType.Popup:
            return "-popup";
    }
}

export interface ScoreboardSelection {
    eventType: EventType,
    positionId: number,
    period: EPeriodType,
    quotationId?: number,
    // Desired view for details, could be overridden by component
    preferredView?: EDetailsViewType
}

export const UNSELECTED = {
    eventType: EventType.PRE_MATCH,
    positionId: -1,
    period: EPeriodType.MAIN
};
