export class EventListener<T> {
    public readonly handlers: T[] = [];

    addListener (listener: T) {
        this.handlers.push(listener);
    }

    removeListener (listener: T) {
        const index = this.handlers.indexOf(listener);
        if (index >= 0) {
            this.handlers.splice(index, 1);
        }
    }

    invoke (callback: (c: T) => void) {
        this.handlers.forEach(t => callback(t));
    }
}
