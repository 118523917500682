import { computed, ref, SetupContext, watch } from "vue";

export function useDateFilterCommon (props:{ itemCount: number }, context: SetupContext) {
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 1);
    const startDateRef = ref(startDate.toISOString().split("T")[0]);
    const endDateRef = ref(new Date().toISOString().split("T")[0]);
    const itemsPerPage = ref<number>(10);
    const currentPage = ref<number>(1);
    const maxPages = 6;
    const totalPages = computed(() => Math.ceil(props.itemCount / itemsPerPage.value));

    function emitDateChanged () {
        context.emit("dateChanged", new Date(startDateRef.value), new Date(endDateRef.value));
    }
    function emitItemsPerPageChanged () {
        context.emit("itemsPerPageChanged", itemsPerPage.value);
    }
    function emitCurrentPageChanged () {
        context.emit("currentPageChanged", currentPage.value);
    }
    emitItemsPerPageChanged();
    watch(itemsPerPage, () => {
        currentPage.value = 1;
        emitItemsPerPageChanged();
    });
    emitDateChanged();
    watch(startDateRef, emitDateChanged);
    watch(endDateRef, emitDateChanged);
    emitCurrentPageChanged();
    watch(currentPage, emitCurrentPageChanged);
    return {
        startDateRef,
        endDateRef,
        itemsPerPage,
        currentPage,
        totalPages,
        maxPages
    };
}