import { usePromoViewCommon } from "@sportaq/vue/views/web/promo-view-common";
import ConfirmationDialog from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.vue";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";
import BonusNotificationDialog from "@/views/deposit-view/components/bonus-notification-dialog/BonusNotificationDialog.vue";
export default {
    name: "PromoView",
    components: {
        ConfirmationDialog,
        LoginPopupDialog,
        ResetPasswordDialog,
        BonusNotificationDialog
    },
    setup () {
        return usePromoViewCommon();
    }
};
