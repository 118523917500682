import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { BaseAccountMessageHandler } from "@sportaq/services/rest/messages/account/base-account-message-handler";
import { WagerContainer } from "@sportaq/model/cashier/wager-container";
import { parseWagerMessage } from "@sportaq/services/rest/messages/account/wager-message-parser";

export class QCs34WagerStakesHandler extends BaseAccountMessageHandler<WagerContainer[]> {
    protected readonly requestType: string = "Q.CS.3.4";

    constructor (readonly startTime: string, readonly endTime?: string) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const filterElement = request.addChild(actionElement, "Filter", {
            logic: "AND"
        });
        const sessionFilterElement = request.addChild(filterElement, "SessionFilter", {
            logic: "AND"
        });
        request.addChildWithValue(sessionFilterElement, "User", "true");
        const wagerFilterFilterElement = request.addChild(filterElement, "WagerFilter", {
            logic: "AND"
        });
        request.addChildWithValue(wagerFilterFilterElement, "startDate", this.startTime);
        if (this.endTime) {
            request.addChildWithValue(wagerFilterFilterElement, "endDate", this.endTime);
        }
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): WagerContainer[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "8150") {
            throw new LocalizedError(`errors.mtl.qCs35.code${serverCode}`);
        }
        const wagerContainerListElement = getRequiredChild(action, "WagerContainerList");
        return parseWagerMessage(wagerContainerListElement);
    }
}
