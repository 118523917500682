import { useWebStore } from "@sportaq/vuex/modules/web/web-module";

export default {
    name: "SessionList",
    setup () {
        const store = useWebStore();
        const sessions = store.user?.sessions;

        return {
            sessions
        };
    }
};
