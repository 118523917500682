import AviatorGameFrame from "@sportaq/vue/views/web/games/aviator/AviatorGameFrame.vue";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";
import { useWebAviatorCommon } from "@sportaq/vue/components/web/aviator-common";

export default {
    name: "AviatorGameView",
    components: {
        LoginPopupDialog,
        ResetPasswordDialog,
        AviatorGameFrame
    },
    setup () {
        return useWebAviatorCommon();
    }
};
