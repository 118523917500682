<template>
    <div class="policy">
        <div class="part">
            {{$t("webdesktop.registration.email.policy1")}} <router-link :to="{name: 'privacy'}">{{$t("webdesktop.registration.email.policy2")}}</router-link><br>
            {{$t("webdesktop.registration.email.policy3")}} <router-link :to="{name: 'terms'}">{{$t("webdesktop.registration.email.policy4")}}</router-link>{{$t("webdesktop.registration.email.policy5")}}<br><br>
            {{$t("webdesktop.registration.email.policy6")}} <router-link :to="{name: 'terms'}">{{$t("webdesktop.registration.email.policy7")}}</router-link> {{$t("webdesktop.registration.email.policy8")}}
            <router-link :to="{name: 'privacy'}">{{$t("webdesktop.registration.email.policy9")}}</router-link> {{$t("webdesktop.registration.email.policy10")}}
        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy"
};
</script>
