import { SearchEventsResponse } from "@sportaq/model/types/responses";
import { IntStringMsg } from "@sportaq/services/streaming/streaming-network/messages/common/combine/IntString_pb";
import { StreamingNetworkRoutes } from "@sportaq/services/streaming/streaming-network/streaming-network-routes";
import {
    SearchEventsResponseDeserializer
} from "@sportaq/services/streaming/streaming-network/deserializers/search-events-response-deserializer";
import { StreamingNetwork } from "@sportaq/services/streaming/streaming-network/streaming-network";
import { GameProvider } from "@sportaq/model/consts/game-providers";
import {
    CasinoGameProvidersResponseDeserializer
} from "@sportaq/services/streaming/streaming-network/deserializers/casino-game-providers-response-deserializer";

export interface StreamingRequestService {
    searchEvents (messageId: number, text: string): Promise<SearchEventsResponse>;

    getProviders (brandId: number): Promise<GameProvider[]>
}

export class StreamingRequestServiceImpl implements StreamingRequestService {
    constructor (private readonly streamingNetwork: StreamingNetwork) {

    }

    async searchEvents (messageId: number, text: string): Promise<SearchEventsResponse> {
        const request = new IntStringMsg();
        request.setIntvalue(messageId);
        request.setStringvalue(text);
        return await this.streamingNetwork.requestResponse(StreamingNetworkRoutes.SEARCH_EVENTS,
            request,
            msg => SearchEventsResponseDeserializer.deserializeBinary(msg)
        );
    }

    async getProviders (brandId: number): Promise<GameProvider[]> {
        return await this.streamingNetwork.requestResponse(StreamingNetworkRoutes.GAME_PROVIDERS_REQUEST,
            undefined,
            msg => CasinoGameProvidersResponseDeserializer.deserializeBinary(brandId, msg));
    }
}
