<template>
    <div class="popup active" id="partner-withdrawal-dialog">
        <div class="overlay" @click="close"></div>
        <div class="popup-container" :class="{'confirmed__message' : withdrawalOrder}">
            <div class="popup-title">{{$t("webdesktop.withdrawal.partner.caption")}}</div>
            <div class="popup-content">
                <div v-if="dialogState === 1"> <!-- State 1 Amount input -->
                    <div class="form-line">
                        <label>{{$t("webdesktop.deposit.amountInput.amount")}}{{currency}}</label>
                        <input type="number" v-model.number="amountRef" class="text-center">
                    </div>
                    <div class="text" >
                        <ul>
                            <li v-for="point of pointList" :key="point.id"><b>{{point.name}}:</b> {{point.cityName}}, {{point.address}}</li>
                        </ul>
                    </div>
                </div>
                <div v-if="dialogState === 2"> <!-- State 2 Confirmation method -->
                    <div class="text" >
                        {{$t("webdesktop.withdrawal.partner.confirmationMethod")}}
                    </div>
                    <div class="form-line display-flex">
                      <label>
                        <input type="radio" id="phone" name="phone" value="phone" v-model="confirmationMethod">
                        {{$t("webdesktop.withdrawal.partner.confirmationPhone")}}
                      </label>
                    </div>
                    <div class="form-line display-flex">
                        <label>
                          <input type="radio" id="email" name="email" value="email" v-model="confirmationMethod">
                          {{$t("webdesktop.withdrawal.partner.confirmationEmail")}}
                        </label>
                    </div>
                </div>
                <div v-if="withdrawalOrder" >
                    <div class="form-line">
                        <label>{{$t("webdesktop.withdrawalConfirm.title")}}{{withdrawalOrder.id}}</label>
                    </div>
                    <div class="form-line">
                        <label>{{$t("webdesktop.withdrawalConfirm.total")}}
                            <b>{{withdrawalOrder.orderSum}}{{withdrawalOrder.currencyMark}}</b></label>
                    </div>
                    <div class="form-line">
                        <label>{{$t("webdesktop.withdrawalConfirm.withdrawalType")}}</label>
                    </div>
                    <div class="form-line">
                        <label>{{$t("webdesktop.withdrawalConfirm.expiration")}}{{$d(withdrawalOrder.expirationTime, 'long')}}</label>
                    </div>
                    <div class="form-line">
                        <div class="info-value">{{$t("webdesktop.withdrawalConfirm.payCode")}}<b>{{formatVoucherNumber(withdrawalOrder.payCode)}}</b></div>
                    </div>
                </div>
                <div class="form-line">
                    <button class="button button-primary" @click.prevent="submit" :class="{disabled: dialogState == 1 && (!amountRef || amountRef < 0)}">{{$t("common.forms.continue")}}</button>
                </div>
                <div class="form-line">
                    <button class="button button-secondary" v-if="dialogState !== 4" @click.prevent="close">{{$t("common.forms.cancel")}}</button>
                </div>
            </div>
        </div>
    </div>
    <confirm-code-dialog v-if="dialogState === 3" @success="onCodeSuccess" @close="onConfirmCodeClose" />
</template>

<script src="./partner-withdrawal-dialog-component.ts" />
