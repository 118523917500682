import { useWebRestService } from "@sportaq/services/web";
import { HorseItemStatus, HorseWagerContainer } from "@sportaq/model/cashier/wager-container";
import { computed, ref } from "vue";

export function useRaceStakeListCommon () {
    const restService = useWebRestService();
    const cardsRef = ref<HorseWagerContainer[]>([]);
    const requestPending = ref(false);
    const itemsPerPageRef = ref<number>(10);
    const currentPage = ref<number>(1);
    const cardsPaged = computed(() => {
        const offset = (currentPage.value - 1) * itemsPerPageRef.value;
        return cardsRef.value.slice(offset).slice(0, itemsPerPageRef.value);
    });

    function dateChanged (startDate: Date, endDate: Date) {
        endDate.setTime(endDate.getTime() + (23 * 60 * 60 + 59 * 60 + 59) * 1000);
        startDate.setHours(0, 0, 0, 0);
        requestPending.value = true;
        restService.getWebRaceStakes(startDate, endDate, 0)
            .then((c) => {
                cardsRef.value = c;
                requestPending.value = false;
            });
    }

    function itemsPerPageChanged (value: number) {
        itemsPerPageRef.value = value;
    }

    function currentPageChanged (value: number) {
        currentPage.value = value;
    }

    return {
        HorseItemStatus,
        cardsPaged,
        cardsRef,
        requestPending,
        itemsPerPageRef,
        currentPage,
        dateChanged,
        itemsPerPageChanged,
        currentPageChanged
    };
}
