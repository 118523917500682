import { SetupContext } from "vue";
import { CLOSE_EVENT, EVENT_SUCCESS } from "@sportaq/vue/components/web/dialogs/events";
import {
    CODE_ERROR_EVENT,
    useConfirmCodeDialogCommon
} from "@sportaq/vue/components/web/dialogs/confirm-code-dialog-common";

export default {
    name: "ConfirmCodeDialog",
    emits: [CLOSE_EVENT, EVENT_SUCCESS, CODE_ERROR_EVENT],
    setup (props: unknown, context: SetupContext) {
        return useConfirmCodeDialogCommon(context);
    }
};
