<template>
    <div class="details-wrapper">
        <div class="details" v-if="showList">
            <mobile-scoreboard-head @changed="quotationViewChanged"/>
            <mobile-scoreboard-body :quotationViews="quotationViews" :viewTitle="viewTitle"/>
        </div>
        <transition name="mobile-details-animation" @after-enter="hideList" @enter-cancelled="closeLine">
            <div v-if="showDetails" class="details-panel">
                <mobile-scoreboard-details :selection="selection" :standalone="false" @close="closeLine"/>
            </div>
        </transition>
    </div>
</template>

<script src="./mobile-betting-view-component.ts"/>
