<template>
    <div class="scoreboard-accordion" :class="{active: active}" :data-cy="`group-event-${event.positionId}`">
        <div class="scoreboard-accordion-head" @click="toggleActive">
            <span class="star" :class="{active: isFavorite}" @click.stop="toggleFavorite()" data-cy="favorite-star"/>
            <div class="datetime" @click.stop="toggleFavorite()">{{ event.startTimeStr }}</div>
            <div class="title" data-cy="title">
                <span class="flag badge"><img src="/img/flags/badge.png" alt=""></span>
                <span class="name">{{ event.partition.name }}</span>
            </div>
            <span class="arrow"><img src="@assets/img/icon-arrow-6.png" alt=""/></span>
        </div>
        <div class="scoreboard-accordion-body" v-if="active">
            <div class="inner-table">
                <div class="inner-table-head">
                    <div class="title">{{ $t("betting.event.groupEvents.participant") }}</div>
                    <div class="item" v-for="(header, index) of groupTable.headers" :key="index">
                        <div class="item-results">
                            <div class="label" :data-cy="`inner-table-header-${index}`">{{ header }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner-table-line" v-for="(row, index) of groupTable.rows" :key="row.participant.id">
                    <div class="name" :data-cy="`participant-${index+1}`">
                        {{ row.participant.name }}
                    </div>
                    <div class="item" v-for="(quotation, index) of row.quotations" :key="quotation.quotation.id"
                         :data-cy="`group-row-quotation-${event.positionId}-${row.participant.id}-${index}`">
                        <standard-scoreboard-line-quotation :participants="participants"
                                                   :source="quotation"
                                                   :event-blocked="event.blocked"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./scoreboard-group-event-component.ts"/>
