import { useRoute } from "vue-router";
import { computed, PropType } from "vue";
import { recognizeDefaultTabKey, Tab } from "@sportaq/vue/views/web/routing-tab";

interface Props {
    tabs: Tab[]
}

export default {
    name: "StakesMenu",
    props: {
        tabs: {
            required: true,
            type: Object as PropType<Tab[]>
        }
    },
    setup (props: Props) {
        const route = useRoute();
        // Computed for recognize route changing
        const selectedTab = computed(() => recognizeDefaultTabKey(route, props.tabs));

        return {
            selectedTab
        };
    }
};
