import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";

export class AUs17ChangeEmail extends AbstractMessageHandler<boolean> {
    protected readonly requestType: string = "A.US.1.7";

    constructor (readonly email: string | undefined, readonly phone: string | undefined) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        if (this.email) {
            request.addChildWithValue(actionElement, "Email", this.email);
        }
        if (this.phone) {
            request.addChildWithValue(actionElement, "Phone", this.phone);
        }
    }
    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): boolean {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1180") {
            return true;
        }
        throw new LocalizedError(`errors.mtl.aUs17.code${serverCode}`);
    }
}
