import { User, UserAccount } from "@sportaq/model/common/user-accounts";
import { Module, Store } from "vuex";
import {
    DEFAULT_USER_SHARED_INFO,
    ROOT_STORE_SET_USER_SHARED_INFO_MUTATION,
    RootState,
    RootStore,
    UserSharedInfo
} from "@sportaq/vuex/index";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { provide } from "vue";
import { use } from "@sportaq/common/utils/dependency-injection";
import { UpdateProfileData } from "@sportaq/model/web/update-profile-data";

export const webDesktopStoreModule = "webDesktopStore";

interface WebDesktopStoreState {
    user: User | undefined;
    loginDialogVisible: boolean;
    appInstallationAvailable: boolean;
}

enum Getters {
    GET_USER = "getUser",
    GET_BANNERS = "getBanners",
    GET_LOGIN_DIALOG_VISIBLE = "getLoginDialogVisible",
    GET_APP_INSTALLATION_AVAILABLE = "getAppInstallationAvailable"
}

enum Mutations {
    SET_USER = "setUser",
    SET_EMAIL = "setEmail",
    SET_PHONE = "setPhone",
    UPDATE_PROFILE = "updateProfile",
    UPDATE_PRIMARY_ACCOUNT = "updatePrimaryAccount",
    SET_BANNERS = "setBanners",
    SET_LOGIN_DIALOG_VISIBLE = "setLoginDialogVisible",
    SET_APP_INSTALLATION_AVAILABLE = "setAppInstallationAvailable"
}

enum Actions {
    DUMMY = "dummyAction"
}

export const WebDesktopStoreModule: Module<WebDesktopStoreState, RootState> = {
    namespaced: true,
    state: {
        user: undefined,
        loginDialogVisible: false,
        appInstallationAvailable: false
    },
    getters: {
        [Getters.GET_USER]: state => state.user,
        [Getters.GET_LOGIN_DIALOG_VISIBLE]: state => state.loginDialogVisible,
        [Getters.GET_APP_INSTALLATION_AVAILABLE]: state => state.appInstallationAvailable
    },
    mutations: {
        [Mutations.SET_USER]: (state, user) => {
            state.user = user;
        },
        [Mutations.SET_EMAIL]: (state, email) => {
            if (state.user) {
                state.user.email = email;
            }
        },
        [Mutations.SET_PHONE]: (state, phone) => {
            if (state.user) {
                state.user.phone = phone;
            }
        },
        [Mutations.SET_APP_INSTALLATION_AVAILABLE]: (state, value) => {
            state.appInstallationAvailable = value;
        },
        [Mutations.UPDATE_PROFILE]: updateProfile,
        [Mutations.UPDATE_PRIMARY_ACCOUNT]: updatePrimaryAccount,
        [Mutations.SET_LOGIN_DIALOG_VISIBLE]: (state, value) => {
            state.loginDialogVisible = value;
        }
    }
};

function updatePrimaryAccount (state: WebDesktopStoreState, data: UserAccount): void {
    if (state.user) {
        const primaryAccount = state.user.userAccounts.getPrimaryAccount();
        if (primaryAccount) {
            primaryAccount.sumAccount = data.sumAccount;
        } else {
            state.user.userAccounts.accounts.push(data);
        }
    }
}

function updateProfile (state: WebDesktopStoreState, data: UpdateProfileData): void {
    if (state.user) {
        if (data.lastName) {
            state.user.lastName = data.lastName;
        }
        if (data.firstName) {
            state.user.firstName = data.firstName;
        }
        if (data.birthday) {
            state.user.birthday = data.birthday.toString();
        }
        if (data.city) {
            state.user.city = data.city;
        }
        if (data.identificationCode) {
            state.user.IdentificationCode = data.identificationCode;
        }
    }
}

export interface WebDesktopStore {
    user: User | undefined;
    loginDialogVisible: boolean;
    isAppInstallationAvailable: boolean;

    updateUserEmail (value: string): void;

    updateUserPhone (value: string): void;

    updateUserProfile (value: UpdateProfileData): void;

    updatePrimaryAccount (b: UserAccount): void;
}

class WebDesktopStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements WebDesktopStore {
    constructor (store: Store<RootState>) {
        super(store, webDesktopStoreModule);
    }

    set isAppInstallationAvailable (value: boolean) {
        this.mutate(Mutations.SET_APP_INSTALLATION_AVAILABLE, value);
    }

    get isAppInstallationAvailable (): boolean {
        return this.get(Getters.GET_APP_INSTALLATION_AVAILABLE);
    }

    set user (user: User) {
        this.mutate(Mutations.SET_USER, user);
        if (user) {
            const userSharedInfo: UserSharedInfo = {
                userId: user.userId,
                countryId: user.countryId,
                primaryAccountCurrencyId: user.userAccounts.getPrimaryAccount()?.currencyId
            };
            this.store.commit(ROOT_STORE_SET_USER_SHARED_INFO_MUTATION, userSharedInfo);
        } else {
            this.store.commit(ROOT_STORE_SET_USER_SHARED_INFO_MUTATION, DEFAULT_USER_SHARED_INFO);
        }
    }

    get user (): User {
        return this.get(Getters.GET_USER);
    }

    get loginDialogVisible (): boolean {
        return this.get(Getters.GET_LOGIN_DIALOG_VISIBLE);
    }

    set loginDialogVisible (value: boolean) {
        this.mutate(Mutations.SET_LOGIN_DIALOG_VISIBLE, value);
    }

    updateUserEmail (value: string): void {
        this.mutate(Mutations.SET_EMAIL, value);
    }

    updateUserPhone (value: string): void {
        this.mutate(Mutations.SET_PHONE, value);
    }

    updateUserProfile (value: UpdateProfileData): void {
        this.mutate(Mutations.UPDATE_PROFILE, value);
    }

    updatePrimaryAccount (b: UserAccount): void {
        this.mutate(Mutations.UPDATE_PRIMARY_ACCOUNT, b);
    }
}

export const webStoreSymbol = Symbol("WebDesktop Store");

export function provideWebStore (rootStore: RootStore) {
    const webStore = new WebDesktopStoreImpl(rootStore.store);
    provide(webStoreSymbol, webStore);
    return webStore;
}

export function useWebStore (): WebDesktopStore {
    return use(webStoreSymbol);
}
