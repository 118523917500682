import { computed } from "vue";
import { useI18n } from "vue-i18n";

interface Code {
    code: string;
    title: string;
    description: string
}

export default {
    name: "NotFound",
    props: {
        code: {
            type: String,
            required: true
        }
    },
    setup (props: { readonly code: string }) {
        const i18n = useI18n();
        const result = computed<Code>(() => {
            switch (props.code) {
                case "ERR_NETWORK":
                    return {
                        code: "",
                        title: i18n.t("common.notFound.networkError.title"),
                        description: i18n.t("common.notFound.networkError.description")
                    };
                case "ERR_BAD_RESPONSE":
                    return {
                        code: "",
                        title: i18n.t("common.notFound.responseError.title"),
                        description: ""
                    };
                case "ECONNABORTED":
                    return {
                        code: "",
                        title: "ECONNABORTED",
                        description: ""
                    };
                case "404":
                    return {
                        code: "404",
                        title: i18n.t("common.notFound.code404.title"),
                        description: i18n.t("common.notFound.code404.description")
                    };
                case "500":
                    return {
                        code: "500",
                        title: i18n.t("common.notFound.code500.title"),
                        description: i18n.t("common.notFound.code500.description")
                    };
                case "502":
                    return {
                        code: "502",
                        title: i18n.t("common.notFound.code502.title"),
                        description: i18n.t("common.notFound.code500.description")
                    };
                case "503":
                    return {
                        code: "503",
                        title: i18n.t("common.notFound.code503.title"),
                        description: i18n.t("common.notFound.code500.description")
                    };
                case "504":
                    return {
                        code: "504",
                        title: i18n.t("common.notFound.code504.title"),
                        description: i18n.t("common.notFound.code500.description")
                    };
                default:
                    return {
                        code: props.code?.toString() ?? "",
                        title: "",
                        description: ""
                    };
            }
        });

        return {
            result
        };
    }
};
