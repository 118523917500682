import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { computed, ref, watch } from "vue";
import { EUserRole, hasRole } from "@sportaq/model/common/user-accounts";

export function useCommonAuthorization () {
    const resetPasswordDialogVisible = ref(false);
    const store = useWebStore();

    const isLoggedIn = computed(() => store.user !== undefined);
    const loginDialogVisible = computed(() => store.loginDialogVisible);
    const changePasswordDialogVisible = ref(false);
    const user = computed(() => store.user);
    watch(user, () => {
        if (user.value) {
            if (hasRole(user.value, EUserRole.EXPRESS) && (user.value.sessions === undefined || user.value.sessions.length <= 1)) {
                changePasswordDialogVisible.value = true;
            }
        }
    });
    function onLoginDialogClose () {
        store.loginDialogVisible = false;
    }

    function onChangePasswordDialogClose () {
        changePasswordDialogVisible.value = false;
    }

    function onResetPasswordDialogClose () {
        resetPasswordDialogVisible.value = false;
    }

    function openResetPasswordDialog () {
        store.loginDialogVisible = false;
        resetPasswordDialogVisible.value = true;
    }

    return {
        loginDialogVisible,
        resetPasswordDialogVisible,
        isLoggedIn,
        changePasswordDialogVisible,
        openResetPasswordDialog,
        onResetPasswordDialogClose,
        onChangePasswordDialogClose,
        onLoginDialogClose
    };
}
