import {
    CategoryPeriodSortInfo
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/category-period-sort-info";
import EventType from "@sportaq/common/enums/event-type";
import EPeriodType from "@sportaq/common/enums/period-types";
import {
    PeriodSortInfoOptions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { Cloneable, Comparable } from "@sportaq/common/types/interfaces";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { markRaw } from "vue";
import { ESportType } from "@sportaq/common/enums/sport-type";

export class FavouritePeriodSortInfo extends CategoryPeriodSortInfo implements Comparable<FavouritePeriodSortInfo>, Cloneable<FavouritePeriodSortInfo> {
    constructor (
        id: number,
        eventType: EventType,
        sportType: ESportType,
        positionId: number,
        eventId: number,
        groupEvent: boolean,
        period: EPeriodType,
        startTime: Date,
        options: PeriodSortInfoOptions,
        categoryId: number,
        categoryName: string,
        countryId: number,
        private sportIndex: number
    ) {
        super(id, eventType, sportType, positionId, eventId, groupEvent, period, startTime, options, categoryId, categoryName, countryId);
    }

    compare (o: FavouritePeriodSortInfo): number {
        const result = this.sportIndex - o.sportIndex;
        return result !== 0 ? result : super.compare(o);
    }

    clone (): FavouritePeriodSortInfo {
        return new FavouritePeriodSortInfo(this.id, this.eventType, this.sportType, this.positionId,
            this.eventId, this.groupEvent, this.period, this.startTime, this.options,
            this.categoryId, this.categoryName, this.countryId, this.sportIndex);
    }

    public static createFavouriteEvent (
        event: BettingEvent,
        period: EPeriodType,
        options: PeriodSortInfoOptions,
        categoryId: number,
        categoryName: string,
        sportIndex: number): FavouritePeriodSortInfo {
        return markRaw(
            new FavouritePeriodSortInfo(
                event.positionId * 100 + period * 10 + event.eventType,
                event.eventType,
                event.sportTypeId,
                event.positionId,
                event.eventId,
                event.isGroupEvent,
                period,
                event.startTime,
                options,
                categoryId,
                categoryName,
                event.partition.countryId,
                sportIndex
            ));
    }

    public static createFavouriteCategoryRow (id: number, name: string, sportTypeId: number, countryId: number, sportIndex: number): FavouritePeriodSortInfo {
        return markRaw(
            new FavouritePeriodSortInfo(
                -1 * id,
                EventType.PRE_MATCH,
                sportTypeId,
                0,
                0,
                false,
                EPeriodType.CATEGORY,
                this.CONSTANT_DATE,
                { reverseTimeSorting: false },
                id,
                name,
                countryId,
                sportIndex
            )
        );
    }
}
