<template>
    <home-page-sport-widget :sport-type="ESportType.Football" :source="periods" :is-mobile="isMobile" v-if="isBettingEnabled"/>

    <home-page-games-widget :source="games" :game-type="ECasinoGameType.Virtual" :tab="EGameTabs.Crash"
                            :classifier="ECasinoGameClassifier.CRASH" v-if="isVirtualEnabled"/>
    <home-page-games-widget :source="games" :game-type="ECasinoGameType.Casino" :tab="EGameTabs.Slots"
                            :classifier="ECasinoGameClassifier.SLOTS" v-if="isCasinoEnabled"/>
    <home-page-games-widget :source="games" :game-type="ECasinoGameType.Casino" :tab="EGameTabs.Roulette"
                            :classifier="ECasinoGameClassifier.ROULETTE" v-if="isCasinoEnabled"/>

    <home-page-sport-widget :sport-type="ESportType.Basketball" :source="periods" :is-mobile="isMobile" v-if="isBettingEnabled"/>
    <home-page-sport-widget :sport-type="ESportType.Baseball" :source="periods" :is-mobile="isMobile" v-if="isBettingEnabled"/>
    <home-page-sport-widget :sport-type="ESportType.Tennis" :source="periods" :is-mobile="isMobile" v-if="isBettingEnabled"/>
</template>

<script src="./home-page-widgets-component.ts"/>
