<template>
    <div class="home">
        <template v-if="!hidePage">
            <!-- Banner -->
            <div class="main-banner" data-cy="banners">
                <Flicking :options="{ circular: false, align: 'prev', bound: true}" :plugins="plugins">
                    <div class="main-banner-item flicking-panel" v-for="banner of banners" :key="banner.id"
                         :data-cy="`banner-${banner.id}`">
                        <router-link :to="{path: banner.onclickUrl}"><img :src="banner.image" :alt="banner.name"
                                                                          class="banner"></router-link>
                    </div>
                </Flicking>
            </div>
            <home-page-widgets :is-mobile="true"/>
        </template>

        <transition name="mobile-details-animation" @after-enter="detailsShowed" @enter-cancelled="closeLine">
            <div v-if="showDetails" class="details-panel">
                <mobile-scoreboard-details :selection="selection" :standalone="false" @close="closeLine"/>
            </div>
        </transition>
    </div>
</template>

<script src="./home-view-component.ts"></script>
