import { CommonSettings } from "@sportaq/services/base-setting-service/common-settings";

export class WebCommonSettings extends CommonSettings {
    private _useSSL: boolean;
    constructor (appCode: string, partnerCode: string, restServerAddress: string, useSSL: boolean, base64Logo: string) {
        super(appCode, partnerCode, restServerAddress, base64Logo);
        this._useSSL = useSSL;
    }

    get useSSL (): boolean {
        return this._useSSL;
    }
}
