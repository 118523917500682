import {
    StreamingNetwork,
    StreamingNetworkConnectResponse
} from "@sportaq/services/streaming/streaming-network/streaming-network";
import { wait } from "@sportaq/common/types/functions";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { StreamingUpdater } from "@sportaq/services/streaming/streaming-updater/streaming-updater";
import { ClockProvider } from "@sportaq/common/utils/time-utils";
import { EventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { ScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { appLogger } from "@sportaq/common/utils/logger";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { resolveLanguage } from "@sportaq/i18n/index";
import { STREAMING_NETWORK_PROTOCOL_VERSION } from "@sportaq/common/consts/streaming-network-protocol-version";
import { StreamingRequestService } from "@sportaq/services/streaming/streaming-request-service";
import { CasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";

export class FatalConnectionError extends Error {
    static readonly ERROR_MESSAGE = "Unsupported client protocol version.";

    constructor (connectionResponse: StreamingNetworkConnectResponse) {
        super(`Connected streaming server name: ${connectionResponse.serverName}:${connectionResponse.serverVersion}. Server protocol version: ${connectionResponse.protocolVersion}. Client protocol version: ${STREAMING_NETWORK_PROTOCOL_VERSION}`);
    }
}

export class StreamingService {
    private streamingUpdater: StreamingUpdater;
    private disconnected: boolean = false;

    constructor (
        private readonly streamingNetwork: StreamingNetwork,
        streamingRequestService: StreamingRequestService,
        private readonly settingsService: BaseSettingsService,
        clockProvider: ClockProvider,
        private readonly eventSupplier: EventSupplier,
        scoreboardStore: ScoreboardStore,
        casinoGamesSupplier: CasinoGamesSupplier,
        casinoGamesStore: CasinoGamesStore
    ) {
        this.streamingNetwork.setDisconnectCallback(this.onDisconnect.bind(this));
        this.streamingUpdater = new StreamingUpdater(
            streamingNetwork,
            streamingRequestService,
            settingsService,
            clockProvider,
            eventSupplier,
            scoreboardStore,
            casinoGamesSupplier,
            casinoGamesStore
        );
    }

    async connect () {
        this.disconnected = false;
        await this.retryConnect(TIMEOUTS.MAX_CONNECTION_RETRY_WAIT);
    }

    disconnect () {
        this.streamingUpdater.unSubscribeFromStreams();
        this.streamingNetwork.disconnect();
        this.disconnected = true;
    }

    private async retryConnect (maxTimeBetweenRetries: number) {
        let retryCount = 0;
        let connected = false;
        do {
            if (retryCount > 0) {
                const timeout = Math.min(Math.pow(2, retryCount) * 100, maxTimeBetweenRetries);
                await wait(timeout);
            }
            connected = await this.internalConnect(retryCount);
            retryCount++;
        } while (!connected && !this.disconnected);
    }

    private async internalConnect (retryCount: number): Promise<boolean> {
        let connectResponse;
        try {
            appLogger.logger.info(`Streaming server connection attempt ${retryCount + 1}`);
            connectResponse = await this.streamingNetwork.connect(
                this.settingsService.getStreamServerAddress(resolveLanguage()),
                this.settingsService.appCode
            );
        } catch (e) {
            appLogger.logger.warn(`Streaming server connection attempt ${retryCount + 1} has been failed: ${(e as Error).message}`);
            return false;
        }
        if (connectResponse && !connectResponse.isSupportedClientProtocolVersion) {
            throw new FatalConnectionError(connectResponse);
        }
        if (connectResponse) {
            appLogger.logger.info(`Success connection to stream server [${connectResponse.serverName}:${connectResponse.serverVersion}], protocol version: [${connectResponse.protocolVersion}], language: [${connectResponse.language}]`);
        }
        eventBus.emit(Events.DATA_STREAM_SERVER_CONNECTION_STATUS_CHANGED, { connected: true });
        await this.streamingUpdater.subscribeOnStreams();
        return true;
    }

    private onDisconnect (e: Error) {
        appLogger.logger.warn("Client disconnected from server by error:", e);
        this.eventSupplier.disconnect();
        eventBus.emit(Events.DATA_STREAM_SERVER_CONNECTION_STATUS_CHANGED, { connected: false });
        setTimeout(() => {
            this.retryConnect(TIMEOUTS.MAX_CONNECTION_RETRY_WAIT).then();
        }, 500);
    }
}
