import Axios, { AxiosRequestConfig } from "axios";
import { getRequiredAttribute, getRequiredChild, getRequiredValue } from "@sportaq/common/utils/xml-helper-functions";
import { APP_TYPE, EApplicationType } from "@sportaq/common/enums/application-type";
import { countries } from "@sportaq/model/consts/countries";

export interface HttpService {
    getDocument<T> (url: string): Promise<T>;

    getUserCountryId (): Promise<number>;

    getUserLocationInfo (): Promise<LocationInfo>;
}

export interface LocationInfo {
    country: string;
    ipAddress: string;
}

export class HttpServiceImpl implements HttpService {
    async getDocument<T> (url: string): Promise<T> {
        const config: AxiosRequestConfig = {
            baseURL: "/",
            headers: { "Content-Type": "text/html" }
        };
        return await Axios.get(url, config).then(value => value.data);
    }

    async getUserCountryId (): Promise<number> {
        const locationInfo = await this.getUserLocationInfo();
        return countries.getByCode(locationInfo.country)?.id ?? 0;
    }

    async getUserLocationInfo (): Promise<LocationInfo> {
        const url = (process.env.__PRODUCTION__ && (APP_TYPE.value === EApplicationType.webMobile ||
            APP_TYPE.value === EApplicationType.webDesktop))
            ? "/ip/location.php"
            : "https://www.dev.tubetgol.com/ip/location.php";
        const config: AxiosRequestConfig = {
            transformResponse: (res) => {
                return res;
            },
            responseType: "text"
        };
        return Axios.get(url, config)
            .then(resp => {
                const parser = new DOMParser();
                const document = parser.parseFromString(resp.data, "application/xml");
                const rootElement = document.documentElement;
                let country = "";
                let ipAddress = "";
                const locationElement = getRequiredChild(rootElement, "location");
                for (let e: Element | null = locationElement; e != null; e = e.nextElementSibling) {
                    const name = getRequiredAttribute(e, "name");
                    if (name === "COUNTRY_SHORT") {
                        country = getRequiredValue(e);
                    } else if (name === "REMOTE_ADDR") {
                        ipAddress = getRequiredValue(e);
                    }
                }
                return {
                    country,
                    ipAddress
                };
            });
    }
}
