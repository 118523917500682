import { useRestService } from "@sportaq/services/index";
import { showError } from "@sportaq/vue/utils/exceptions-functions";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";
import { onMounted, onUnmounted, PropType, ref, SetupContext, watch } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { AccountCategory, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { StartGameInfo } from "@sportaq/model/games/start-game-info";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { timeout } from "@sportaq/common/utils/time-utils";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";
import { updateGameUrl } from "@sportaq/vue/components/games/common/functions/common-games-functions";

interface Props {
    gameInfo: StartGameInfo,
    isDesktop: boolean;
    withTransferMoney: boolean;
}

const ERROR_STARTING_GAME_EVENT = "errorStartingGame";
export default {
    props: {
        gameInfo: {
            type: Object as PropType<StartGameInfo>,
            required: true
        },
        isDesktop: {
            required: false,
            type: Boolean,
            default: true
        },
        withTransferMoney: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    setup (props: Props, context: SetupContext) {
        const rest = useRestService();
        const i18n = useI18n();
        const balanceStore = useBalanceStore();
        const route = useRoute();

        let currentGame: StartGameInfo | undefined;
        const currentGameUrl = ref<string | undefined>();

        let gameInRestartingState = false;
        let applicationIsAboutToReload = false;

        function onApplicationIsAboutToReload () {
            closeGame().then(() => (applicationIsAboutToReload = true));
        }

        onMounted(async () => {
            eventBus.on(Events.APPLICATION_IS_ABOUT_TO_RELOAD, onApplicationIsAboutToReload);
            eventBus.on(Events.APPLICATION_IS_ABOUT_TO_SUSPEND, onApplicationIsAboutToReload);
            await startGame(props.gameInfo);
        });

        onUnmounted(async () => {
            eventBus.off(Events.APPLICATION_IS_ABOUT_TO_RELOAD, onApplicationIsAboutToReload);
            eventBus.off(Events.APPLICATION_IS_ABOUT_TO_SUSPEND, onApplicationIsAboutToReload);
            await closeGame();
        });

        watch(() => props.gameInfo, async (value) => {
            await closeGame();
            await startGame(value);
        });

        onBeforeRouteLeave(async () => {
            await closeGame();
        });

        watch(() => balanceStore.isBalanceChanging, async (value) => {
            if (value) {
                await closeGame();
            } else {
                await closeGame();
                await startGame(props.gameInfo);
            }
        });

        async function startGame (gameInfo: StartGameInfo) {
            if (applicationIsAboutToReload) {
                return;
            }
            if (props.withTransferMoney) {
                balanceStore.activeAccount = AccountCategory.Casino;
            }
            const hostUrl = window.location.href;
            let startGameResponse = await rest.startGameFrame(gameInfo.gameId, gameInfo.demo, props.isDesktop, props.withTransferMoney, process.env.IS_ELECTRON ? "app://./index.html" : hostUrl);
            if (startGameResponse.type === "error") {
                if (startGameResponse.code === "8132") {
                    try {
                        await rest.closeGameFrame("", props.withTransferMoney);
                    } catch (e) {
                        return;
                    }
                    startGameResponse = await rest.startGameFrame(gameInfo.gameId, gameInfo.demo, props.isDesktop, props.withTransferMoney, process.env.IS_ELECTRON ? "app://./index.html" : hostUrl);
                    eventBus.emit(Events.REFRESH_BALANCE);
                } else {
                    handleStartGameError(gameInfo, startGameResponse.code);
                    return;
                }
            }
            if (startGameResponse.type === "success") {
                currentGameUrl.value = startGameResponse.url;
                currentGame = gameInfo;
                updateGameUrl(route, gameInfo.gameType, gameInfo.gameId);
            } else if (startGameResponse.type === "error") {
                handleStartGameError(gameInfo, startGameResponse.code);
            }
        }

        watch(() => balanceStore.getMainBalance(), async (value, oldValue) => {
            if (gameInRestartingState || balanceStore.isBalanceChanging || value.equals(oldValue) || value.equalsNumber(0)) {
                return;
            }
            gameInRestartingState = true;
            await closeGame();
            setTimeout(async () => {
                await startGame(props.gameInfo);
                gameInRestartingState = false;
            }, 500);
        });

        async function closeGame () {
            if (applicationIsAboutToReload) {
                return;
            }
            const cg = currentGame;
            // Clear shared variables before due to async function
            currentGame = undefined;
            currentGameUrl.value = undefined;
            updateGameUrl(route, undefined, undefined);

            if (cg && !cg.demo) {
                try {
                    if (props.withTransferMoney) {
                        balanceStore.activeAccount = AccountCategory.Main;
                    }
                    await rest.closeGameFrame(cg.gameId, props.withTransferMoney);
                    await timeout(TIMEOUTS.LEAVE_CASINO_TIMEOUT);
                } catch (e) {
                    showError(i18n, e as Error, "Error closing game");
                }
            }
            setTimeout(() => (eventBus.emit(Events.REFRESH_BALANCE)), 500);
        }

        function handleStartGameError (game: StartGameInfo, code: string) {
            showMessageBox(i18n.t(`errors.mtl.aCs11.code${code}`), () => context.emit(ERROR_STARTING_GAME_EVENT, code));
        }

        return {
            currentGameUrl
        };
    }
};
