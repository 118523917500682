<template>
    <div class="buttons-panel">
        <div @click.stop="debug()">
            <svg viewBox="0 0 24 24" class="window-button">
                <path fill="currentColor" d="M14,12H10V10H14M14,16H10V14H14M20,8H17.19C16.74,7.22 16.12,6.55 15.37,6.04L17,4.41L15.59,3L13.42,5.17C12.96,5.06 12.5,5 12,5C11.5,5 11.04,5.06 10.59,5.17L8.41,3L7,4.41L8.62,6.04C7.88,6.55 7.26,7.22 6.81,8H4V10H6.09C6.04,10.33 6,10.66 6,11V12H4V14H6V15C6,15.34 6.04,15.67 6.09,16H4V18H6.81C7.85,19.79 9.78,21 12,21C14.22,21 16.15,19.79 17.19,18H20V16H17.91C17.96,15.67 18,15.34 18,15V14H20V12H18V11C18,10.66 17.96,10.33 17.91,10H20V8Z" />
            </svg>
        </div>
        <div @click.stop="minimize()">
            <svg viewBox="0 0 24 24" class="window-button">
                <path d="M20,14H4V10H20"/>
            </svg>
        </div>
        <div @click.stop="close()">
            <svg viewBox="0 0 24 24" class="window-button">
                <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"/>
            </svg>
        </div>
    </div>
</template>

<script src="./window-buttons-panel-component.ts"/>

<style lang="scss" scoped>
.window-button {
    fill: black;
    width: 24px;
    height: 24px;
    background-color: white;
    margin: 2px;
}

.window-button:active {
    background-color: gray;
}

.buttons-panel {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
}
</style>
