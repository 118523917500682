import { ClockProvider, dateToString } from "@sportaq/common/utils/time-utils";

export const ATTRIBUTE_WITHOUT_VALUE = "___ATTRIBUTE_WITHOUT_VALUE___";

export class XmlRequest {
    private readonly doc: Document;
    readonly body: Element;
    private _needDebugLog: boolean = false;
    private _mtlRequestType: string = "";
    readonly head;
    constructor (appCode: string, public readonly clockProvider: ClockProvider, session: string, language: string) {
        this.doc = new Document();
        const root = XmlRequest.addChild(this.doc, this.doc, "mtl", {
            version: "2.0"
        });
        this.head = XmlRequest.addChild(this.doc, root, "head");
        XmlRequest.addChild(this.doc, this.head, "appcode", undefined, appCode);
        XmlRequest.addChild(this.doc, this.head, "apptime", undefined, dateToString(clockProvider));
        XmlRequest.addChild(this.doc, this.head, "sessioncode", undefined, session);
        XmlRequest.addChild(this.doc, this.head, "lang", undefined, language);
        this.body = XmlRequest.addChild(this.doc, root, "body");
    }

    addChild (parent: Element, tag: string, attrs?: Record<string, string>): Element {
        return XmlRequest.addChild(this.doc, parent, tag, attrs);
    }

    addChildWithValue (parent: Element, tag: string, value?: string, attrs?: Record<string, string>): Element {
        return XmlRequest.addChild(this.doc, parent, tag, attrs, value);
    }

    toString (): string {
        const serializer = new XMLSerializer();
        return serializer.serializeToString(this.doc);
    }

    private static addChild (doc: Document, parent: XmlAppender, tag: string, attrs?: Record<string, string>, value?: string): Element {
        const element = doc.createElement(tag);
        parent.appendChild(element);
        if (attrs) {
            for (const key in attrs) {
                if (Object.prototype.hasOwnProperty.call(attrs, key)) {
                    const value = attrs[key];
                    if (value) {
                        if (value !== ATTRIBUTE_WITHOUT_VALUE) {
                            element.setAttribute(key, value);
                        } else {
                            element.setAttribute(key, "true");
                        }
                    }
                }
            }
        }
        if (value) {
            const textNode = doc.createTextNode(value);
            element.appendChild(textNode);
        }
        return element;
    }

    get needDebugLog (): boolean {
        return this._needDebugLog;
    }

    set needDebugLog (value: boolean) {
        this._needDebugLog = value;
    }

    get mtlRequestType (): string {
        return this._mtlRequestType;
    }

    set mtlRequestType (value: string) {
        this._mtlRequestType = value;
    }
}

interface XmlAppender {
    appendChild (element: Element): void;
}
