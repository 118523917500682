import { RootState } from "@sportaq/vuex/index";
import { Module, Store } from "vuex";
import { CasinoGamesVuexMutations } from "@sportaq/vuex/modules/games/internal/casino-games-vuex-mutations";
import { CasinoGamesVuexActions } from "@sportaq/vuex/modules/games/internal/casino-games-vuex-actions";
import AbstractStore from "@sportaq/vuex/abstract-store";
import { CasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { CasinoGamesSupplier } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-supplier";
import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import { CasinoGamesVuexGetters } from "@sportaq/vuex/modules/games/internal/casino-games-vuex-getters";
import { comparator } from "@sportaq/common/types/functions";
import { GameFilterChanger, GameViewFilter } from "@sportaq/vuex/modules/games/game-view-filter/GameViewFilter";
import { GameListResolver } from "@sportaq/vuex/modules/games/internal/game-list-resolvers/game-list-resolver";
import { SortedIndexedArray } from "@sportaq/common/utils/sorted-data-types/sorted-indexed-array";
import { SortedArray } from "@sportaq/common/utils/sorted-data-types/sorted-array";
import { markRaw } from "vue";
import {
    PlainGameListResolver
} from "@sportaq/vuex/modules/games/internal/game-list-resolvers/resolvers/plain-game-list-resolver";
import { BaseSettingsService } from "@sportaq/services/src/base-setting-service/base-settings-service";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import { CountGameProvider, GameProvider } from "@sportaq/model/consts/game-providers";

export namespace CasinoGamesVuex {
    export const moduleName = "casinoGamesStore";

    export interface CasinoGamesStoreState {
        providers: CountGameProvider[];
        gameViewFilter: GameViewFilter;
        gamesView: SortedIndexedArray<GameSortInfo, string>;

        gameListResolver: GameListResolver;

        virtualFavourites: SortedArray<string>;
        casinoFavourites: SortedArray<string>;

        favouriteMode: boolean;
        restrictedGameIds: string[];
    }

    enum Getters {
        GET_PROVIDERS = "getProviders",
        GET_GAMES_VIEW = "getGamesView",
        IS_FAVOURITE = "isFavourite",
        GET_FAVOURITES_COUNT = "getFavouritesCount",
        GET_FAVOURITE_MODE = "getFavouriteMode",
        GET_VIEW_FILTER = "getViewFilter",
    }

    export enum Mutations {
        SET_PROVIDERS = "setProviders",
        SYNC_GAMES_VIEW_WITH_STORE = "syncGamesViewWithStore",
        TOGGLE_FAVOURITE = "toggleFavourites",
        SET_FAVOURITE_MODE = "getFavouriteMode",
        UPDATE_GAME_FILTER = "updateGameFilter",
        CLEAR_GAME_FILTER = "clearGameFilter",
        ADD_RESTRICTED_GAME_ID = "addRestrictedGameId"
    }

    enum Actions {
        UPDATE_CASINO_GAMES = "updateCasinoGames"
    }

    export const CasinoGamesStoreModule: Module<CasinoGamesStoreState, RootState> = {
        namespaced: true,
        state: {
            providers: [],
            gameViewFilter: new GameViewFilter(),
            gamesView: new SortedIndexedArray(comparator),

            gameListResolver: markRaw(new PlainGameListResolver()),

            virtualFavourites: new SortedArray((a, b) => a.localeCompare(b)),
            casinoFavourites: new SortedArray((a, b) => a.localeCompare(b)),

            favouriteMode: false,
            restrictedGameIds: []
        },
        getters: {
            [Getters.GET_PROVIDERS]: state => state.providers,
            [Getters.GET_GAMES_VIEW]: state => state.gamesView.items,
            [Getters.IS_FAVOURITE]: CasinoGamesVuexGetters.isFavourite,
            [Getters.GET_FAVOURITE_MODE]: state => state.favouriteMode,
            [Getters.GET_FAVOURITES_COUNT]: CasinoGamesVuexGetters.getFavouritesCount,
            [Getters.GET_FAVOURITE_MODE]: state => state.favouriteMode,
            [Getters.GET_VIEW_FILTER]: state => state.gameViewFilter
        },
        mutations: {
            [Mutations.SET_PROVIDERS]: CasinoGamesVuexMutations.mutationSetProviders,
            [Mutations.SYNC_GAMES_VIEW_WITH_STORE]: CasinoGamesVuexMutations.mutationSyncGamesViewWithStore,
            [Mutations.TOGGLE_FAVOURITE]: CasinoGamesVuexMutations.mutationToggleFavourites,
            [Mutations.UPDATE_GAME_FILTER]: CasinoGamesVuexMutations.mutationUpdateGameFilter,
            [Mutations.CLEAR_GAME_FILTER]: CasinoGamesVuexMutations.mutationClearGameFilter,
            [Mutations.SET_FAVOURITE_MODE]: CasinoGamesVuexMutations.mutationSetFavouriteMode,
            [Mutations.ADD_RESTRICTED_GAME_ID]: CasinoGamesVuexMutations.addRestrictedGameId
        },
        actions: {
            [Actions.UPDATE_CASINO_GAMES]: CasinoGamesVuexActions.actionUpdateCasinoGames
        }
    };

    export class CasinoGamesStoreImpl extends AbstractStore<RootState, Getters, Mutations, Actions> implements CasinoGamesStore {
        constructor (store: Store<RootState>, private readonly casinoGamesSupplier: CasinoGamesSupplier, private readonly settingsService: BaseSettingsService) {
            super(store, moduleName);
        }

        getProviders (): CountGameProvider[] {
            return this.get(Getters.GET_PROVIDERS);
        }

        setProviders (value: GameProvider[]): void {
            this.mutate(Mutations.SET_PROVIDERS, {
                gameProviders: value
            });
        }

        getGamesView (): GameSortInfo[] {
            return this.get(Getters.GET_GAMES_VIEW);
        }

        isFavourite (item: GameSortInfo): boolean {
            const func = this.get(Getters.IS_FAVOURITE);
            return func(item);
        }

        toggleFavourite (item: GameSortInfo) {
            this.mutate(Mutations.TOGGLE_FAVOURITE, {
                value: item,
                casinoGamesSupplier: this.casinoGamesSupplier,
                pointSettings: this.settingsService.pointSettings
            });
        }

        getFavouritesCount (): number {
            return this.get(Getters.GET_FAVOURITES_COUNT);
        }

        get favouriteMode (): boolean {
            return this.get(Getters.GET_FAVOURITE_MODE);
        }

        set favouriteMode (value: boolean) {
            this.mutate(Mutations.SET_FAVOURITE_MODE, {
                value,
                casinoGamesSupplier: this.casinoGamesSupplier,
                pointSettings: this.settingsService.pointSettings
            });
        }

        getGameViewFilter (): GameViewFilter {
            return this.get(Getters.GET_VIEW_FILTER);
        }

        updateGameFilters (changers: GameFilterChanger[]): void {
            this.mutate(Mutations.UPDATE_GAME_FILTER, {
                casinoGamesSupplier: this.casinoGamesSupplier,
                changers,
                pointSettings: this.settingsService.pointSettings
            });
        }

        updateGameFilter (changer: GameFilterChanger): void {
            this.updateGameFilters([changer]);
        }

        clearGameFilter (): void {
            this.mutate(Mutations.CLEAR_GAME_FILTER);
        }

        addRestrictedGameId (id: string): void {
            this.mutate(Mutations.ADD_RESTRICTED_GAME_ID, {
                casinoGamesSupplier: this.casinoGamesSupplier,
                value: id,
                pointSettings: this.settingsService.pointSettings
            });
        }

        init () {
            this.casinoGamesSupplier.addUpdateListener(this.updateEventsHandle.bind(this));
        }

        destroy () {
            this.casinoGamesSupplier.removeUpdateListener(this.updateEventsHandle);
        }

        private updateEventsHandle (casinoGamesSupplier: CasinoGamesSupplier, list: CasinoGameDiffsResponse[], nonConfirmedPositionIds: string[]): Promise<void> {
            return this.action(Actions.UPDATE_CASINO_GAMES, {
                casinoGamesSupplier,
                list,
                nonConfirmedPositionIds,
                pointSettings: this.settingsService.pointSettings
            });
        }
    }
}
