import { DetailRow, QuotationRow } from "@sportaq/model/betting/view/event-details/event-details-info";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { appLogger } from "@sportaq/common/utils/logger";
import EDetailRow from "@sportaq/common/enums/detail-row";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { LOCKED_QUOTATION, Quotation } from "@sportaq/model/betting/events/quotation";
import { insertUniqueIntoSortedArray, nativeComparator } from "@sportaq/common/utils/arrays";
import EventType from "@sportaq/common/enums/event-type";

const TOTAL_GROUP_RELATIONS = new Map<EDetailRow, EDetailRow>(
    [
        [EDetailRow.football_team1TotalUnder, EDetailRow.football_team1TotalOver],
        [EDetailRow.football_team2TotalUnder, EDetailRow.football_team2TotalOver],
        [EDetailRow.football_DrawTotalUnder, EDetailRow.football_DrawTotalOver],
        [EDetailRow.football_x1TotalUnder, EDetailRow.football_x1TotalOver],
        [EDetailRow.football_12TotalUnder, EDetailRow.football_x12TotalOver],
        [EDetailRow.football_x2TotalUnder, EDetailRow.football_x2TotalOver],
        [EDetailRow.football_bothScoreTotalUnder, EDetailRow.football_bothScoreTotalOver],
        [EDetailRow.football_atLeastOneWontScoreTotalUnder, EDetailRow.football_atLeastOneWontScoreTotalOver]
    ]
);

export function rowHasBeenHandledAsTotalGroup (result: QuotationRow[], allRows: DetailRow[], event: BettingEvent, row: DetailRow): boolean {
    if (totalGroupRowMustBeSkippedDueToHandlingBefore(row)) {
        return true;
    }
    if (isTotalGroupRow(row)) {
        if (!checkValidTotalGroupRow(row)) {
            return true;
        }
        const relatedRowId = TOTAL_GROUP_RELATIONS.get(row.id);
        if (!relatedRowId) {
            appLogger.logger.warn(`Configuration error: total group row ${row.id} doesn't have related row`);
            return true;
        }
        const relatedDetailRow = allRows.find(value => value.id === relatedRowId);
        if (!relatedDetailRow) {
            appLogger.logger.warn(`Configuration error: total group row ${relatedRowId} wasn't founded in tab rows ${JSON.stringify(allRows)}`);
            return true;
        }
        if (!checkValidTotalGroupRow(relatedDetailRow)) {
            return true;
        }
        fillTotalGroupRows(result, event, row, relatedDetailRow);
        return true;
    }
    return false;
}

function fillTotalGroupRows (result: QuotationRow[], event: BettingEvent, row: DetailRow, relatedRow: DetailRow) {
    const p1SortedArr: BigDecimal[] = [];
    const rowLeftQuotations = event.quotations.getQuotationsByQuotationId(row.quotationIds[0][0]);
    const rowRightQuotations = event.quotations.getQuotationsByQuotationId(row.quotationIds[0][1]);

    const relatedRowLeftQuotations = event.quotations.getQuotationsByQuotationId(relatedRow.quotationIds[0][0]);
    const relatedRowRightQuotations = event.quotations.getQuotationsByQuotationId(relatedRow.quotationIds[0][1]);
    addAllToP1SortedArr(p1SortedArr, rowLeftQuotations);
    addAllToP1SortedArr(p1SortedArr, rowRightQuotations);
    addAllToP1SortedArr(p1SortedArr, relatedRowLeftQuotations);
    addAllToP1SortedArr(p1SortedArr, relatedRowRightQuotations);
    for (const p1 of p1SortedArr) {
        addRow(result, event.eventType, row, rowLeftQuotations, rowRightQuotations, p1);
        addRow(result, event.eventType, relatedRow, relatedRowLeftQuotations, relatedRowRightQuotations, p1);
    }
}

function addRow (result: QuotationRow[], eventType: EventType, row: DetailRow, leftQuotations: Quotation[], rightQuotations: Quotation[], p1: BigDecimal) {
    const leftQuotation = getQuotation(leftQuotations, p1);
    const rightQuotation = getQuotation(rightQuotations, p1);
    if (leftQuotation !== LOCKED_QUOTATION || rightQuotation !== LOCKED_QUOTATION) {
        result.push({
            id: row.id,
            title: row.title,
            quotations: [[{
                eventType,
                quotation: leftQuotation
            }, {
                eventType,
                quotation: rightQuotation
            }]],
            p1
        });
    }
}

function addAllToP1SortedArr (p1SortedArr: BigDecimal[], quotations: Quotation[]) {
    for (const quotation of quotations) {
        insertUniqueIntoSortedArray(p1SortedArr, quotation.key.p1, nativeComparator);
    }
}

function checkValidTotalGroupRow (row: DetailRow): boolean {
    if (row.quotationIds.length !== 1) {
        appLogger.logger.warn(`Row ${JSON.stringify(row)} isn't valid total group row due to its quotation rows count doesn't equal to 1`);
        return false;
    }
    if (row.quotationIds[0].length !== 2) {
        appLogger.logger.warn(`Row ${JSON.stringify(row)} isn't valid total group row due to its quotation columns count doesn't equal to 2`);
        return false;
    }
    return true;
}

function getQuotation (quotations: Quotation[], p1: BigDecimal): Quotation {
    return quotations.find(value => p1.equals(value.key.p1)) ?? LOCKED_QUOTATION;
}

function isTotalGroupRow (row: DetailRow) {
    switch (row.id) {
        case EDetailRow.football_team1TotalUnder:
        case EDetailRow.football_team2TotalUnder:
        case EDetailRow.football_DrawTotalUnder:
        case EDetailRow.football_x1TotalUnder:
        case EDetailRow.football_12TotalUnder:
        case EDetailRow.football_x2TotalUnder:
        case EDetailRow.football_bothScoreTotalUnder:
        case EDetailRow.football_atLeastOneWontScoreTotalUnder: {
            return true;
        }
        default: {
            return false;
        }
    }
}

function totalGroupRowMustBeSkippedDueToHandlingBefore (row: DetailRow) {
    switch (row.id) {
        case EDetailRow.football_team1TotalOver:
        case EDetailRow.football_team2TotalOver:
        case EDetailRow.football_DrawTotalOver:
        case EDetailRow.football_x1TotalOver:
        case EDetailRow.football_x12TotalOver:
        case EDetailRow.football_x2TotalOver:
        case EDetailRow.football_bothScoreTotalOver:
        case EDetailRow.football_atLeastOneWontScoreTotalOver: {
            return true;
        }
        default: {
            return false;
        }
    }
}
