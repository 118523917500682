import { useMiscellaneousParamsProvider } from "@sportaq/services/index";
import { watch } from "vue";
import { quotationBacklightOffTimeoutController } from "@sportaq/common/utils/time-utils";
import QuotationView from "@sportaq/common/enums/quotation-views";

export namespace ScoreboardBodyComponent {
    export interface Props {
        quotationViews: QuotationView[];
    }

    export function useScoreboardBodyComponent<T extends Props> (props: T) {
        const miscellaneousParamsProvider = useMiscellaneousParamsProvider();
        const buffer = miscellaneousParamsProvider.recycleScrollerBufferSize;

        watch(() => props.quotationViews, () => {
            quotationBacklightOffTimeoutController.delayedQuotationBacklightOff();
        });

        return {
            buffer
        };
    }

    export function calculateItemSize (scoreboardLineHeight: number) {
        const windowWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        return Math.ceil(windowWidth * scoreboardLineHeight / 100) + 1; // +1 just for minimal gap between lines
    }
}
