import { useGamesContent } from "@sportaq/vue/components/games/common/content-section/content-section-functions";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import {
    ConstantItemsPanelSelectedResult
} from "@sportaq/vue/components/common/constant-items-panel/constant-items-panel-component";
import { EGameTabs } from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { ECasinoGameClassifier } from "@sportaq/common/enums/games/casino-game-classifier";
import { SetupContext } from "vue";

export namespace CasinoGamesComponent {

    export function useCasinoGamesComponent (context: SetupContext) {
        const {
            store,
            selectedItem,
            forceOpenSelectedTabByClick,
            currentGame,

            isDirectLink,
            directLinkGame,

            setMaskFilter,
            commonSelectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        } = useGamesContent(ECasinoGameType.Casino, context);

        async function selectTopMenu (result: ConstantItemsPanelSelectedResult, auto: boolean) {
            await commonSelectTopMenu(result, auto);
            const tab: EGameTabs = result.selectedId;
            switch (tab) {
                case EGameTabs.All: {
                    store.updateGameFilter({
                        changeClassifierMask: {
                            type: "unset"
                        }
                    });
                    break;
                }
                case EGameTabs.Popular: {
                    setMaskFilter(ECasinoGameClassifier.POPULAR);
                    break;
                }
                case EGameTabs.Favourites: {
                    store.favouriteMode = true;
                    break;
                }
                case EGameTabs.New: {
                    setMaskFilter(ECasinoGameClassifier.NEW);
                    break;
                }
                case EGameTabs.Slots: {
                    setMaskFilter(ECasinoGameClassifier.SLOTS);
                    break;
                }
                case EGameTabs.Card: {
                    setMaskFilter(ECasinoGameClassifier.CARD);
                    break;
                }
                case EGameTabs.Lottery: {
                    setMaskFilter(ECasinoGameClassifier.LOTTERY);
                    break;
                }
                case EGameTabs.Roulette: {
                    setMaskFilter(ECasinoGameClassifier.ROULETTE);
                    break;
                }
                case EGameTabs.Poker: {
                    setMaskFilter(ECasinoGameClassifier.POKER);
                    break;
                }
                case EGameTabs.Jackpot: {
                    setMaskFilter(ECasinoGameClassifier.JACKPOT);
                    break;
                }
                case EGameTabs.Live: {
                    setMaskFilter(ECasinoGameClassifier.LIVE);
                    break;
                }
                case EGameTabs.Blackjack: {
                    setMaskFilter(ECasinoGameClassifier.BLACKJACK);
                    break;
                }
                case EGameTabs.Craps: {
                    setMaskFilter(ECasinoGameClassifier.CRAPS);
                    break;
                }
                case EGameTabs.BuyFeature: {
                    setMaskFilter(ECasinoGameClassifier.BUY_FEATURE);
                    break;
                }
                case EGameTabs.Megaways:{
                    setMaskFilter(ECasinoGameClassifier.MEGAWAYS);
                    break;
                }
                case EGameTabs.Providers: {
                    if (result.selectedSubId > 0) {
                        store.updateGameFilter({
                            changeProvider: {
                                type: "set",
                                value: result.selectedSubId
                            }
                        });
                    }
                }
            }
        }

        return {
            selectedItem,
            currentGame,
            forceOpenSelectedTabByClick,

            isDirectLink,
            directLinkGame,

            selectTopMenu,
            startGame,
            startDemo,
            closeGame,
            directLinkClose
        };
    }
}
