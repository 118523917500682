import CardPanel from "@sportaq/vue/components/web/mobile/card-panel/CardPanel.vue";
import { useStakesInPlayComponentCommon } from "@sportaq/vue/views/web/stakes/stakes-in-play-component-common";
import { resolveLanguage } from "@sportaq/i18n/index";

export default {
    name: "StakesInPlay",
    components: {
        CardPanel
    },
    setup () {
        return useStakesInPlayComponentCommon((cardsRef, restService) => {
            restService.getCardsInPlay(resolveLanguage()).then((cards) => {
                cardsRef.value = cards;
            });
        });
    }
};
