import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getFootballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.football.popular",
        rows: [
            {
                id: EDetailRow.football_x1x2,
                title: "detailInfo.groups.football.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2,
                title: "detailInfo.groups.football.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.football_total,
                title: "detailInfo.groups.football.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1,
                title: "detailInfo.groups.football.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2,
                title: "detailInfo.groups.football.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam1,
                title: "detailInfo.groups.football.scoreTeam1",
                quotationIds: [
                    [86, 87]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam2,
                title: "detailInfo.groups.football.scoreTeam2",
                quotationIds: [
                    [88, 89]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth,
                title: "detailInfo.groups.football.scoreBoth",
                quotationIds: [
                    [84, 85]
                ]
            },
            {
                id: EDetailRow.football_handicap,
                title: "detailInfo.groups.football.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore1,
                title: "detailInfo.groups.football.goalWillScore1",
                quotationIds: [
                    [1301, 1303, 1302]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore2,
                title: "detailInfo.groups.football.goalWillScore2",
                quotationIds: [
                    [1304, 1306, 1305]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore3,
                title: "detailInfo.groups.football.goalWillScore3",
                quotationIds: [
                    [1307, 1309, 1308]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore4,
                title: "detailInfo.groups.football.goalWillScore4",
                quotationIds: [
                    [1310, 1312, 1311]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore5,
                title: "detailInfo.groups.football.goalWillScore5",
                quotationIds: [
                    [1313, 1315, 1314]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore6,
                title: "detailInfo.groups.football.goalWillScore6",
                quotationIds: [
                    [1316, 1318, 1317]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore7,
                title: "detailInfo.groups.football.goalWillScore7",
                quotationIds: [
                    [1319, 1321, 1320]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore8,
                title: "detailInfo.groups.football.goalWillScore8",
                quotationIds: [
                    [1322, 1324, 1323]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore9,
                title: "detailInfo.groups.football.goalWillScore9",
                quotationIds: [
                    [1325, 1327, 1326]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore10,
                title: "detailInfo.groups.football.goalWillScore10",
                quotationIds: [
                    [1328, 1330, 1329]
                ]
            },
            {
                id: EDetailRow.football_halfMatch,
                title: "detailInfo.groups.football.halfMatch",
                quotationIds: [
                    [53, 55, 59],
                    [54, 61, 57],
                    [60, 58, 56]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.football.all",
        rows: [
            {
                id: EDetailRow.football_x1x2,
                title: "detailInfo.groups.football.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2,
                title: "detailInfo.groups.football.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.football_handicap,
                title: "detailInfo.groups.football.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.football_total,
                title: "detailInfo.groups.football.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1,
                title: "detailInfo.groups.football.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2,
                title: "detailInfo.groups.football.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore1,
                title: "detailInfo.groups.football.goalWillScore1",
                quotationIds: [
                    [1301, 1303, 1302]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore2,
                title: "detailInfo.groups.football.goalWillScore2",
                quotationIds: [
                    [1304, 1306, 1305]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore3,
                title: "detailInfo.groups.football.goalWillScore3",
                quotationIds: [
                    [1307, 1309, 1308]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore4,
                title: "detailInfo.groups.football.goalWillScore4",
                quotationIds: [
                    [1310, 1312, 1311]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore5,
                title: "detailInfo.groups.football.goalWillScore5",
                quotationIds: [
                    [1313, 1315, 1314]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore6,
                title: "detailInfo.groups.football.goalWillScore6",
                quotationIds: [
                    [1316, 1318, 1317]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore7,
                title: "detailInfo.groups.football.goalWillScore7",
                quotationIds: [
                    [1319, 1321, 1320]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore8,
                title: "detailInfo.groups.football.goalWillScore8",
                quotationIds: [
                    [1322, 1324, 1323]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore9,
                title: "detailInfo.groups.football.goalWillScore9",
                quotationIds: [
                    [1325, 1327, 1326]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore10,
                title: "detailInfo.groups.football.goalWillScore10",
                quotationIds: [
                    [1328, 1330, 1329]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam1,
                title: "detailInfo.groups.football.scoreTeam1",
                quotationIds: [
                    [86, 87]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam2,
                title: "detailInfo.groups.football.scoreTeam2",
                quotationIds: [
                    [88, 89]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth,
                title: "detailInfo.groups.football.scoreBoth",
                quotationIds: [
                    [84, 85]
                ]
            },
            {
                id: EDetailRow.football_goalHalf1,
                title: "detailInfo.groups.football.goalHalf1",
                quotationIds: [
                    [82, 83]
                ]
            },
            {
                id: EDetailRow.football_goalHalf2,
                title: "detailInfo.groups.football.goalHalf2",
                quotationIds: [
                    [900, 901]
                ]
            },
            {
                id: EDetailRow.football_goalBothHalves,
                title: "detailInfo.groups.football.goalBothHalves",
                quotationIds: [
                    [331, 332]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1BothHalves,
                title: "detailInfo.groups.football.goalTeam1BothHalves",
                quotationIds: [
                    [333, 937]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2BothHalves,
                title: "detailInfo.groups.football.goalTeam2BothHalves",
                quotationIds: [
                    [334, 938]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf1,
                title: "detailInfo.groups.football.scoreBothHalf1",
                quotationIds: [
                    [898, 899]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf2,
                title: "detailInfo.groups.football.scoreBothHalf2",
                quotationIds: [
                    [935, 936]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half1,
                title: "detailInfo.groups.football.goalTeam1Half1",
                quotationIds: [
                    [903, 904]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half1,
                title: "detailInfo.groups.football.goalTeam2Half1",
                quotationIds: [
                    [905, 906]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half2,
                title: "detailInfo.groups.football.goalTeam1Half2",
                quotationIds: [
                    [908, 909]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half2,
                title: "detailInfo.groups.football.goalTeam2Half2",
                quotationIds: [
                    [910, 911]
                ]
            },
            {
                id: EDetailRow.football_winBothHalves,
                title: "detailInfo.groups.football.winBothHalves",
                quotationIds: [
                    [341, 342]
                ]
            },
            {
                id: EDetailRow.football_firstGoalTeam,
                title: "detailInfo.groups.football.firstGoalTeam",
                quotationIds: [
                    [90, 91]
                ]
            },
            {
                id: EDetailRow.football_lastGoalTeam,
                title: "detailInfo.groups.football.lastGoalTeam",
                quotationIds: [
                    [136, 137]
                ]
            },
            {
                id: EDetailRow.football_scoreBothTeam1,
                title: "detailInfo.groups.football.scoreBothTeam1",
                quotationIds: [
                    [1438, 1482]
                ]
            },
            {
                id: EDetailRow.football_scoreBothTeam2,
                title: "detailInfo.groups.football.scoreBothTeam2",
                quotationIds: [
                    [1439, 1483]
                ]
            },
            {
                id: EDetailRow.football_scoreBothDraw,
                title: "detailInfo.groups.football.scoreBothDraw",
                quotationIds: [
                    [1440, 1484]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth1X,
                title: "detailInfo.groups.football.scoreBoth1X",
                quotationIds: [
                    [1441, 1485]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth12,
                title: "detailInfo.groups.football.scoreBoth12",
                quotationIds: [
                    [1442, 1486]
                ]
            },
            {
                id: EDetailRow.football_scoreBothX2,
                title: "detailInfo.groups.football.scoreBothX2",
                quotationIds: [
                    [1443, 1487]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTeam1,
                title: "detailInfo.groups.football.atLeastOneWontScoreTeam1",
                quotationIds: [
                    [1488, 1489]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreDraw,
                title: "detailInfo.groups.football.atLeastOneWontScoreDraw",
                quotationIds: [
                    [1490, 1491]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTeam2,
                title: "detailInfo.groups.football.atLeastOneWontScoreTeam2",
                quotationIds: [
                    [1492, 1493]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScore1X,
                title: "detailInfo.groups.football.atLeastOneWontScore1X",
                quotationIds: [
                    [1494, 1495]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScore12,
                title: "detailInfo.groups.football.atLeastOneWontScore12",
                quotationIds: [
                    [1496, 1497]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreX2,
                title: "detailInfo.groups.football.atLeastOneWontScoreX2",
                quotationIds: [
                    [1498, 1499]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Team1,
                title: "detailInfo.groups.football.team1Goal1Team1",
                quotationIds: [
                    [1460, 1500]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Team2,
                title: "detailInfo.groups.football.team1Goal1Team2",
                quotationIds: [
                    [1461, 1501]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Draw,
                title: "detailInfo.groups.football.team1Goal1Draw",
                quotationIds: [
                    [1462, 1502]
                ]
            },
            {
                id: EDetailRow.football_team1Goal11X,
                title: "detailInfo.groups.football.team1Goal11X",
                quotationIds: [
                    [1503, 1504]
                ]
            },
            {
                id: EDetailRow.football_team1Goal112,
                title: "detailInfo.groups.football.team1Goal112",
                quotationIds: [
                    [1505, 1506]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1X2,
                title: "detailInfo.groups.football.team1Goal1X2",
                quotationIds: [
                    [1507, 1508]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Team2,
                title: "detailInfo.groups.football.team2Goal1Team2",
                quotationIds: [
                    [1463, 1509]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Team1,
                title: "detailInfo.groups.football.team2Goal1Team1",
                quotationIds: [
                    [1464, 1510]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Draw,
                title: "detailInfo.groups.football.team2Goal1Draw",
                quotationIds: [
                    [1465, 1511]
                ]
            },
            {
                id: EDetailRow.football_team2Goal11X,
                title: "detailInfo.groups.football.team2Goal11X",
                quotationIds: [
                    [1512, 1513]
                ]
            },
            {
                id: EDetailRow.football_team2Goal112,
                title: "detailInfo.groups.football.team2Goal112",
                quotationIds: [
                    [1514, 1515]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1X2,
                title: "detailInfo.groups.football.team2Goal1X2",
                quotationIds: [
                    [1516, 1517]
                ]
            },
            {
                id: EDetailRow.football_team1TotalUnder,
                title: "detailInfo.groups.football.team1TotalUnder",
                quotationIds: [
                    [719, 1472]
                ]
            },
            {
                id: EDetailRow.football_team1TotalOver,
                title: "detailInfo.groups.football.team1TotalOver",
                quotationIds: [
                    [720, 1473]
                ]
            },
            {
                id: EDetailRow.football_team2TotalUnder,
                title: "detailInfo.groups.football.team2TotalUnder",
                quotationIds: [
                    [721, 1524]
                ]
            },
            {
                id: EDetailRow.football_team2TotalOver,
                title: "detailInfo.groups.football.team2TotalOver",
                quotationIds: [
                    [722, 1525]
                ]
            },
            {
                id: EDetailRow.football_DrawTotalUnder,
                title: "detailInfo.groups.football.DrawTotalUnder",
                quotationIds: [
                    [1448, 1474]
                ]
            },
            {
                id: EDetailRow.football_DrawTotalOver,
                title: "detailInfo.groups.football.DrawTotalOver",
                quotationIds: [
                    [1449, 1475]
                ]
            },
            {
                id: EDetailRow.football_x1TotalUnder,
                title: "detailInfo.groups.football.x1TotalUnder",
                quotationIds: [
                    [1450, 1476]
                ]
            },
            {
                id: EDetailRow.football_x1TotalOver,
                title: "detailInfo.groups.football.x1TotalOver",
                quotationIds: [
                    [1451, 1477]
                ]
            },
            {
                id: EDetailRow.football_12TotalUnder,
                title: "detailInfo.groups.football.12TotalUnder",
                quotationIds: [
                    [1452, 1478]
                ]
            },
            {
                id: EDetailRow.football_x12TotalOver,
                title: "detailInfo.groups.football.x12TotalOver",
                quotationIds: [
                    [1453, 1479]
                ]
            },
            {
                id: EDetailRow.football_x2TotalUnder,
                title: "detailInfo.groups.football.x2TotalUnder",
                quotationIds: [
                    [1454, 1480]
                ]
            },
            {
                id: EDetailRow.football_x2TotalOver,
                title: "detailInfo.groups.football.x2TotalOver",
                quotationIds: [
                    [1455, 1481]
                ]
            },
            {
                id: EDetailRow.football_bothScoreTotalUnder,
                title: "detailInfo.groups.football.bothScoreTotalUnder",
                quotationIds: [
                    [1456, 1518]
                ]
            },
            {
                id: EDetailRow.football_bothScoreTotalOver,
                title: "detailInfo.groups.football.bothScoreTotalOver",
                quotationIds: [
                    [1457, 1519]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTotalUnder,
                title: "detailInfo.groups.football.atLeastOneWontScoreTotalUnder",
                quotationIds: [
                    [1458, 1520]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTotalOver,
                title: "detailInfo.groups.football.atLeastOneWontScoreTotalOver",
                quotationIds: [
                    [1459, 1521]
                ]
            },
            {
                id: EDetailRow.football_matchScore,
                title: "detailInfo.groups.football.matchScore",
                quotationIds: [
                    [40, 37, 43],
                    [41, 38, 44],
                    [46, 39, 45],
                    [42, 1231, 1248],
                    [47, 1235, 1249],
                    [48, 1243, 1252],
                    [1250, 1258, 1259],
                    [1232, 1266, 1267],
                    [1233, 1275, 1276],
                    [1234, 1286, 1287],
                    [1251, 52, 49],
                    [1239, -2, 50],
                    [1240, -2, 1236],
                    [1241, -2, 1244],
                    [1242, -2, 1253],
                    [1298, -2, 1260],
                    [1299, -2, 1268],
                    [1400, -2, 1278],
                    [1401, -2, 1288],
                    [1402, -2, 51],
                    [1403, -2, 1237],
                    [1404, -2, 1245],
                    [1405, -2, 1254],
                    [1406, -2, 1261],
                    [1407, -2, 1269],
                    [1408, -2, 1279],
                    [1409, -2, 1289],
                    [1410, -2, 1238],
                    [1411, -2, 1246],
                    [1412, -2, 1255],
                    [1413, -2, 1262],
                    [1414, -2, 1270],
                    [1415, -2, 1280],
                    [1416, -2, 1290],
                    [1417, -2, 1247],
                    [1418, -2, 1256],
                    [1419, -2, 1263],
                    [1420, -2, 1271],
                    [1421, -2, 1281],
                    [1422, -2, 1291],
                    [1423, -2, 1257],
                    [1424, -2, 1264],
                    [1425, -2, 1272],
                    [1426, -2, 1282],
                    [1427, -2, 1292],
                    [1428, -2, 1265],
                    [1429, -2, 1273],
                    [1430, -2, 1283],
                    [1431, -2, 1293],
                    [1432, -2, 1274],
                    [1433, -2, 1284],
                    [1434, -2, 1294],
                    [1435, -2, 1285],
                    [1436, -2, 1295],
                    [1437, -2, 1296]
                ]
            },
            {
                id: EDetailRow.football_halfMatch,
                title: "detailInfo.groups.football.halfMatch",
                quotationIds: [
                    [53, 55, 59],
                    [54, 61, 57],
                    [60, 58, 56]
                ]
            },
            {
                id: EDetailRow.football_halvesComparison,
                title: "detailInfo.groups.football.halvesComparison",
                quotationIds: [
                    [25, 109, 26]
                ]
            },
            {
                id: EDetailRow.football_dryWin,
                title: "detailInfo.groups.football.dryWin",
                quotationIds: [
                    [359, 360]
                ]
            },
            {
                id: EDetailRow.football_strongWilledWin,
                title: "detailInfo.groups.football.strongWilledWin",
                quotationIds: [
                    [357, 358]
                ]
            },
            {
                id: EDetailRow.football_passage,
                title: "detailInfo.groups.football.passage",
                quotationIds: [
                    [23, 24]
                ]
            },
            {
                id: EDetailRow.football_evenOddMatch,
                title: "detailInfo.groups.football.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.football_evenOddHalf1,
                title: "detailInfo.groups.football.evenOddHalf1",
                quotationIds: [
                    [1041, 1042]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x15,
                title: "detailInfo.groups.football.goalIntervalx1x15",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x30,
                title: "detailInfo.groups.football.goalIntervalx1x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x45,
                title: "detailInfo.groups.football.goalIntervalx1x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x60,
                title: "detailInfo.groups.football.goalIntervalx1x60",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x75,
                title: "detailInfo.groups.football.goalIntervalx1x75",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx16x30,
                title: "detailInfo.groups.football.goalIntervalx16x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx31x45,
                title: "detailInfo.groups.football.goalIntervalx31x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx46x60,
                title: "detailInfo.groups.football.goalIntervalx46x60",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx61x75,
                title: "detailInfo.groups.football.goalIntervalx61x75",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx76x90,
                title: "detailInfo.groups.football.goalIntervalx76x90",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x15",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x60,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x60",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x75,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x75",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x16x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x31x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam1x46x60",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam1x61x75",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam1x76x90",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x15",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x45",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x60,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x60",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x75,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x75",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x16x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x31x45",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam2x46x60",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam2x61x75",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam2x76x90",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_x1x2fHalf1,
                title: "detailInfo.groups.football.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half1,
                title: "detailInfo.groups.football.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf1,
                title: "detailInfo.groups.football.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.football_totalHalf1,
                title: "detailInfo.groups.football.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half1,
                title: "detailInfo.groups.football.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half1,
                title: "detailInfo.groups.football.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.football_x1x2fHalf2,
                title: "detailInfo.groups.football.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half2,
                title: "detailInfo.groups.football.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf2,
                title: "detailInfo.groups.football.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.football_totalHalf2,
                title: "detailInfo.groups.football.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half2,
                title: "detailInfo.groups.football.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half2,
                title: "detailInfo.groups.football.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.football.goals",
        rows: [
            {
                id: EDetailRow.football_goalWillScore1,
                title: "detailInfo.groups.football.goalWillScore1",
                quotationIds: [
                    [1301, 1303, 1302]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore2,
                title: "detailInfo.groups.football.goalWillScore2",
                quotationIds: [
                    [1304, 1306, 1305]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore3,
                title: "detailInfo.groups.football.goalWillScore3",
                quotationIds: [
                    [1307, 1309, 1308]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore4,
                title: "detailInfo.groups.football.goalWillScore4",
                quotationIds: [
                    [1310, 1312, 1311]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore5,
                title: "detailInfo.groups.football.goalWillScore5",
                quotationIds: [
                    [1313, 1315, 1314]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore6,
                title: "detailInfo.groups.football.goalWillScore6",
                quotationIds: [
                    [1316, 1318, 1317]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore7,
                title: "detailInfo.groups.football.goalWillScore7",
                quotationIds: [
                    [1319, 1321, 1320]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore8,
                title: "detailInfo.groups.football.goalWillScore8",
                quotationIds: [
                    [1322, 1324, 1323]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore9,
                title: "detailInfo.groups.football.goalWillScore9",
                quotationIds: [
                    [1325, 1327, 1326]
                ]
            },
            {
                id: EDetailRow.football_goalWillScore10,
                title: "detailInfo.groups.football.goalWillScore10",
                quotationIds: [
                    [1328, 1330, 1329]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam1,
                title: "detailInfo.groups.football.scoreTeam1",
                quotationIds: [
                    [86, 87]
                ]
            },
            {
                id: EDetailRow.football_scoreTeam2,
                title: "detailInfo.groups.football.scoreTeam2",
                quotationIds: [
                    [88, 89]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth,
                title: "detailInfo.groups.football.scoreBoth",
                quotationIds: [
                    [84, 85]
                ]
            },
            {
                id: EDetailRow.football_goalHalf1,
                title: "detailInfo.groups.football.goalHalf1",
                quotationIds: [
                    [82, 83]
                ]
            },
            {
                id: EDetailRow.football_goalHalf2,
                title: "detailInfo.groups.football.goalHalf2",
                quotationIds: [
                    [900, 901]
                ]
            },
            {
                id: EDetailRow.football_goalBothHalves,
                title: "detailInfo.groups.football.goalBothHalves",
                quotationIds: [
                    [331, 332]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1BothHalves,
                title: "detailInfo.groups.football.goalTeam1BothHalves",
                quotationIds: [
                    [333, 937]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2BothHalves,
                title: "detailInfo.groups.football.goalTeam2BothHalves",
                quotationIds: [
                    [334, 938]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf1,
                title: "detailInfo.groups.football.scoreBothHalf1",
                quotationIds: [
                    [898, 899]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf2,
                title: "detailInfo.groups.football.scoreBothHalf2",
                quotationIds: [
                    [935, 936]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half1,
                title: "detailInfo.groups.football.goalTeam1Half1",
                quotationIds: [
                    [903, 904]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half1,
                title: "detailInfo.groups.football.goalTeam2Half1",
                quotationIds: [
                    [905, 906]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half2,
                title: "detailInfo.groups.football.goalTeam1Half2",
                quotationIds: [
                    [908, 909]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half2,
                title: "detailInfo.groups.football.goalTeam2Half2",
                quotationIds: [
                    [910, 911]
                ]
            },
            {
                id: EDetailRow.football_winBothHalves,
                title: "detailInfo.groups.football.winBothHalves",
                quotationIds: [
                    [341, 342]
                ]
            },
            {
                id: EDetailRow.football_firstGoalTeam,
                title: "detailInfo.groups.football.firstGoalTeam",
                quotationIds: [
                    [90, 91]
                ]
            },
            {
                id: EDetailRow.football_lastGoalTeam,
                title: "detailInfo.groups.football.lastGoalTeam",
                quotationIds: [
                    [136, 137]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x15,
                title: "detailInfo.groups.football.goalIntervalx1x15",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x30,
                title: "detailInfo.groups.football.goalIntervalx1x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x45,
                title: "detailInfo.groups.football.goalIntervalx1x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x60,
                title: "detailInfo.groups.football.goalIntervalx1x60",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x75,
                title: "detailInfo.groups.football.goalIntervalx1x75",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx16x30,
                title: "detailInfo.groups.football.goalIntervalx16x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx31x45,
                title: "detailInfo.groups.football.goalIntervalx31x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx46x60,
                title: "detailInfo.groups.football.goalIntervalx46x60",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx61x75,
                title: "detailInfo.groups.football.goalIntervalx61x75",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx76x90,
                title: "detailInfo.groups.football.goalIntervalx76x90",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x15",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x60,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x60",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x75,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x75",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x16x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x31x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam1x46x60",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam1x61x75",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam1x76x90",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x15",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x45",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x60,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x60",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x75,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x75",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x16x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x31x45",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam2x46x60",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam2x61x75",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam2x76x90",
                quotationIds: [
                    [914, 915]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.football.totals",
        rows: [
            {
                id: EDetailRow.football_total,
                title: "detailInfo.groups.football.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.football_totalBalls,
                title: "detailInfo.groups.football.totalBalls",
                quotationIds: [
                    [129],
                    [130],
                    [939],
                    [940]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1,
                title: "detailInfo.groups.football.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2,
                title: "detailInfo.groups.football.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.football_totalHalf1,
                title: "detailInfo.groups.football.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.football_totalHalf2,
                title: "detailInfo.groups.football.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half1,
                title: "detailInfo.groups.football.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half1,
                title: "detailInfo.groups.football.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half2,
                title: "detailInfo.groups.football.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half2,
                title: "detailInfo.groups.football.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.football.goalsPlusResult",
        rows: [
            {
                id: EDetailRow.football_scoreBothTeam1,
                title: "detailInfo.groups.football.scoreBothTeam1",
                quotationIds: [
                    [1438, 1482]
                ]
            },
            {
                id: EDetailRow.football_scoreBothTeam2,
                title: "detailInfo.groups.football.scoreBothTeam2",
                quotationIds: [
                    [1439, 1483]
                ]
            },
            {
                id: EDetailRow.football_scoreBothDraw,
                title: "detailInfo.groups.football.scoreBothDraw",
                quotationIds: [
                    [1440, 1484]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth1X,
                title: "detailInfo.groups.football.scoreBoth1X",
                quotationIds: [
                    [1441, 1485]
                ]
            },
            {
                id: EDetailRow.football_scoreBoth12,
                title: "detailInfo.groups.football.scoreBoth12",
                quotationIds: [
                    [1442, 1486]
                ]
            },
            {
                id: EDetailRow.football_scoreBothX2,
                title: "detailInfo.groups.football.scoreBothX2",
                quotationIds: [
                    [1443, 1487]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTeam1,
                title: "detailInfo.groups.football.atLeastOneWontScoreTeam1",
                quotationIds: [
                    [1488, 1489]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreDraw,
                title: "detailInfo.groups.football.atLeastOneWontScoreDraw",
                quotationIds: [
                    [1490, 1491]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTeam2,
                title: "detailInfo.groups.football.atLeastOneWontScoreTeam2",
                quotationIds: [
                    [1492, 1493]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScore1X,
                title: "detailInfo.groups.football.atLeastOneWontScore1X",
                quotationIds: [
                    [1494, 1495]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScore12,
                title: "detailInfo.groups.football.atLeastOneWontScore12",
                quotationIds: [
                    [1496, 1497]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreX2,
                title: "detailInfo.groups.football.atLeastOneWontScoreX2",
                quotationIds: [
                    [1498, 1499]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Team1,
                title: "detailInfo.groups.football.team1Goal1Team1",
                quotationIds: [
                    [1460, 1500]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Team2,
                title: "detailInfo.groups.football.team1Goal1Team2",
                quotationIds: [
                    [1461, 1501]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1Draw,
                title: "detailInfo.groups.football.team1Goal1Draw",
                quotationIds: [
                    [1462, 1502]
                ]
            },
            {
                id: EDetailRow.football_team1Goal11X,
                title: "detailInfo.groups.football.team1Goal11X",
                quotationIds: [
                    [1503, 1504]
                ]
            },
            {
                id: EDetailRow.football_team1Goal112,
                title: "detailInfo.groups.football.team1Goal112",
                quotationIds: [
                    [1505, 1506]
                ]
            },
            {
                id: EDetailRow.football_team1Goal1X2,
                title: "detailInfo.groups.football.team1Goal1X2",
                quotationIds: [
                    [1507, 1508]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Team2,
                title: "detailInfo.groups.football.team2Goal1Team2",
                quotationIds: [
                    [1463, 1509]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Team1,
                title: "detailInfo.groups.football.team2Goal1Team1",
                quotationIds: [
                    [1464, 1510]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1Draw,
                title: "detailInfo.groups.football.team2Goal1Draw",
                quotationIds: [
                    [1465, 1511]
                ]
            },
            {
                id: EDetailRow.football_team2Goal11X,
                title: "detailInfo.groups.football.team2Goal11X",
                quotationIds: [
                    [1512, 1513]
                ]
            },
            {
                id: EDetailRow.football_team2Goal112,
                title: "detailInfo.groups.football.team2Goal112",
                quotationIds: [
                    [1514, 1515]
                ]
            },
            {
                id: EDetailRow.football_team2Goal1X2,
                title: "detailInfo.groups.football.team2Goal1X2",
                quotationIds: [
                    [1516, 1517]
                ]
            }
        ]
    });
    result.push({
        id: 6,
        title: "detailInfo.tabs.football.resultPlusTotal",
        rows: [
            {
                id: EDetailRow.football_team1TotalUnder,
                title: "detailInfo.groups.football.team1TotalUnder",
                quotationIds: [
                    [719, 1472]
                ]
            },
            {
                id: EDetailRow.football_team1TotalOver,
                title: "detailInfo.groups.football.team1TotalOver",
                quotationIds: [
                    [720, 1473]
                ]
            },
            {
                id: EDetailRow.football_team2TotalUnder,
                title: "detailInfo.groups.football.team2TotalUnder",
                quotationIds: [
                    [721, 1524]
                ]
            },
            {
                id: EDetailRow.football_team2TotalOver,
                title: "detailInfo.groups.football.team2TotalOver",
                quotationIds: [
                    [722, 1525]
                ]
            },
            {
                id: EDetailRow.football_DrawTotalUnder,
                title: "detailInfo.groups.football.DrawTotalUnder",
                quotationIds: [
                    [1448, 1474]
                ]
            },
            {
                id: EDetailRow.football_DrawTotalOver,
                title: "detailInfo.groups.football.DrawTotalOver",
                quotationIds: [
                    [1449, 1475]
                ]
            },
            {
                id: EDetailRow.football_x1TotalUnder,
                title: "detailInfo.groups.football.x1TotalUnder",
                quotationIds: [
                    [1450, 1476]
                ]
            },
            {
                id: EDetailRow.football_x1TotalOver,
                title: "detailInfo.groups.football.x1TotalOver",
                quotationIds: [
                    [1451, 1477]
                ]
            },
            {
                id: EDetailRow.football_12TotalUnder,
                title: "detailInfo.groups.football.12TotalUnder",
                quotationIds: [
                    [1452, 1478]
                ]
            },
            {
                id: EDetailRow.football_x12TotalOver,
                title: "detailInfo.groups.football.x12TotalOver",
                quotationIds: [
                    [1453, 1479]
                ]
            },
            {
                id: EDetailRow.football_x2TotalUnder,
                title: "detailInfo.groups.football.x2TotalUnder",
                quotationIds: [
                    [1454, 1480]
                ]
            },
            {
                id: EDetailRow.football_x2TotalOver,
                title: "detailInfo.groups.football.x2TotalOver",
                quotationIds: [
                    [1455, 1481]
                ]
            }
        ]
    });
    result.push({
        id: 7,
        title: "detailInfo.tabs.football.goalsPlusTotal",
        rows: [
            {
                id: EDetailRow.football_bothScoreTotalUnder,
                title: "detailInfo.groups.football.bothScoreTotalUnder",
                quotationIds: [
                    [1456, 1518]
                ]
            },
            {
                id: EDetailRow.football_bothScoreTotalOver,
                title: "detailInfo.groups.football.bothScoreTotalOver",
                quotationIds: [
                    [1457, 1519]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTotalUnder,
                title: "detailInfo.groups.football.atLeastOneWontScoreTotalUnder",
                quotationIds: [
                    [1458, 1520]
                ]
            },
            {
                id: EDetailRow.football_atLeastOneWontScoreTotalOver,
                title: "detailInfo.groups.football.atLeastOneWontScoreTotalOver",
                quotationIds: [
                    [1459, 1521]
                ]
            }
        ]
    });
    result.push({
        id: 8,
        title: "detailInfo.tabs.football.handicaps",
        rows: [
            {
                id: EDetailRow.football_handicapMatch,
                title: "detailInfo.groups.football.handicapMatch",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf1,
                title: "detailInfo.groups.football.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf2,
                title: "detailInfo.groups.football.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            }
        ]
    });
    result.push({
        id: 9,
        title: "detailInfo.tabs.football.halves",
        rows: [
            {
                id: EDetailRow.football_x1x2fHalf1,
                title: "detailInfo.groups.football.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half1,
                title: "detailInfo.groups.football.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf1,
                title: "detailInfo.groups.football.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.football_totalHalf1,
                title: "detailInfo.groups.football.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half1,
                title: "detailInfo.groups.football.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half1,
                title: "detailInfo.groups.football.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.football_goalHalf1,
                title: "detailInfo.groups.football.goalHalf1",
                quotationIds: [
                    [82, 83]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf1,
                title: "detailInfo.groups.football.scoreBothHalf1",
                quotationIds: [
                    [898, 899]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half1,
                title: "detailInfo.groups.football.goalTeam1Half1",
                quotationIds: [
                    [903, 904]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half1,
                title: "detailInfo.groups.football.goalTeam2Half1",
                quotationIds: [
                    [905, 906]
                ]
            },
            {
                id: EDetailRow.football_x1x2fHalf2,
                title: "detailInfo.groups.football.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half2,
                title: "detailInfo.groups.football.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf2,
                title: "detailInfo.groups.football.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.football_totalHalf2,
                title: "detailInfo.groups.football.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half2,
                title: "detailInfo.groups.football.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half2,
                title: "detailInfo.groups.football.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            },
            {
                id: EDetailRow.football_goalHalf2,
                title: "detailInfo.groups.football.goalHalf2",
                quotationIds: [
                    [900, 901]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf2,
                title: "detailInfo.groups.football.scoreBothHalf2",
                quotationIds: [
                    [935, 936]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half2,
                title: "detailInfo.groups.football.goalTeam1Half2",
                quotationIds: [
                    [908, 909]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half2,
                title: "detailInfo.groups.football.goalTeam2Half2",
                quotationIds: [
                    [910, 911]
                ]
            }
        ]
    });
    return result;
}

export function getFootballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.football.all",
        rows: [
            {
                id: EDetailRow.football_x1x2fHalf1,
                title: "detailInfo.groups.football.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half1,
                title: "detailInfo.groups.football.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf1,
                title: "detailInfo.groups.football.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.football_totalHalf1,
                title: "detailInfo.groups.football.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half1,
                title: "detailInfo.groups.football.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half1,
                title: "detailInfo.groups.football.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.football_goalHalf1,
                title: "detailInfo.groups.football.goalHalf1",
                quotationIds: [
                    [82, 83]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf1,
                title: "detailInfo.groups.football.scoreBothHalf1",
                quotationIds: [
                    [898, 899]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half1,
                title: "detailInfo.groups.football.goalTeam1Half1",
                quotationIds: [
                    [903, 904]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half1,
                title: "detailInfo.groups.football.goalTeam2Half1",
                quotationIds: [
                    [905, 906]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.football.handicaps",
        rows: [
            {
                id: EDetailRow.football_handicapHalf1,
                title: "detailInfo.groups.football.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.football.totals",
        rows: [
            {
                id: EDetailRow.football_totalHalf1,
                title: "detailInfo.groups.football.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half1,
                title: "detailInfo.groups.football.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half1,
                title: "detailInfo.groups.football.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.football.goals",
        rows: [
            {
                id: EDetailRow.football_goalHalf1,
                title: "detailInfo.groups.football.goalHalf1",
                quotationIds: [
                    [82, 83]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf1,
                title: "detailInfo.groups.football.scoreBothHalf1",
                quotationIds: [
                    [898, 899]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half1,
                title: "detailInfo.groups.football.goalTeam1Half1",
                quotationIds: [
                    [903, 904]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half1,
                title: "detailInfo.groups.football.goalTeam2Half1",
                quotationIds: [
                    [905, 906]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x15,
                title: "detailInfo.groups.football.goalIntervalx1x15",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x30,
                title: "detailInfo.groups.football.goalIntervalx1x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx1x45,
                title: "detailInfo.groups.football.goalIntervalx1x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx16x30,
                title: "detailInfo.groups.football.goalIntervalx16x30",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx31x45,
                title: "detailInfo.groups.football.goalIntervalx31x45",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x15",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x1x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam1x16x30",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam1x31x45",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x15,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x15",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x1x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x1x45",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x16x30,
                title: "detailInfo.groups.football.goalIntervalTeam2x16x30",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x31x45,
                title: "detailInfo.groups.football.goalIntervalTeam2x31x45",
                quotationIds: [
                    [914, 915]
                ]
            }
        ]
    });
    return result;
}

export function getFootballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.football.all",
        rows: [
            {
                id: EDetailRow.football_x1x2fHalf2,
                title: "detailInfo.groups.football.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.football_x1x12x2Half2,
                title: "detailInfo.groups.football.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.football_handicapHalf2,
                title: "detailInfo.groups.football.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.football_totalHalf2,
                title: "detailInfo.groups.football.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half2,
                title: "detailInfo.groups.football.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2Half2,
                title: "detailInfo.groups.football.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            },
            {
                id: EDetailRow.football_goalHalf2,
                title: "detailInfo.groups.football.goalHalf2",
                quotationIds: [
                    [900, 901]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf2,
                title: "detailInfo.groups.football.scoreBothHalf2",
                quotationIds: [
                    [935, 936]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half2,
                title: "detailInfo.groups.football.goalTeam1Half2",
                quotationIds: [
                    [908, 909]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half2,
                title: "detailInfo.groups.football.goalTeam2Half2",
                quotationIds: [
                    [910, 911]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.football.handicaps",
        rows: [
            {
                id: EDetailRow.football_handicapHalf2,
                title: "detailInfo.groups.football.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.football.totals",
        rows: [
            {
                id: EDetailRow.football_totalHalf2,
                title: "detailInfo.groups.football.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam1Half2,
                title: "detailInfo.groups.football.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.football_individualTotalTeam2,
                title: "detailInfo.groups.football.individualTotalTeam2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.football.goals",
        rows: [
            {
                id: EDetailRow.football_goalHalf2,
                title: "detailInfo.groups.football.goalHalf2",
                quotationIds: [
                    [900, 901]
                ]
            },
            {
                id: EDetailRow.football_scoreBothHalf2,
                title: "detailInfo.groups.football.scoreBothHalf2",
                quotationIds: [
                    [935, 936]
                ]
            },
            {
                id: EDetailRow.football_goalTeam1Half2,
                title: "detailInfo.groups.football.goalTeam1Half2",
                quotationIds: [
                    [908, 909]
                ]
            },
            {
                id: EDetailRow.football_goalTeam2Half2,
                title: "detailInfo.groups.football.goalTeam2Half2",
                quotationIds: [
                    [910, 911]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx46x60,
                title: "detailInfo.groups.football.goalIntervalx46x60",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx61x75,
                title: "detailInfo.groups.football.goalIntervalx61x75",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalx76x90,
                title: "detailInfo.groups.football.goalIntervalx76x90",
                quotationIds: [
                    [902, 907]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam1x46x60",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam1x61x75",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam1x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam1x76x90",
                quotationIds: [
                    [912, 913]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x46x60,
                title: "detailInfo.groups.football.goalIntervalTeam2x46x60",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x61x75,
                title: "detailInfo.groups.football.goalIntervalTeam2x61x75",
                quotationIds: [
                    [914, 915]
                ]
            },
            {
                id: EDetailRow.football_goalIntervalTeam2x76x90,
                title: "detailInfo.groups.football.goalIntervalTeam2x76x90",
                quotationIds: [
                    [914, 915]
                ]
            }
        ]
    });
    return result;
}
