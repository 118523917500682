import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { getRequiredAttribute, getRequiredChild } from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";

export class AUs113SwitchAccountMessageHandler extends AbstractMessageHandler<void> {
    protected readonly requestType: string = "A.US.1.13";

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "action", {
            type: this.requestType
        });
    }

    parseMessageBody (body: Element): void {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode !== "1193") {
            throw new LocalizedError(`errors.mtl.aus113.code${serverCode}`);
        }
    }
}
