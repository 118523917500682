<template>
    <div class="popup active" id="message-box" data-cy="message-box" v-if="isActive">
        <div class="overlay" @click="close"></div>
        <div class="popup-container">
            <div class="popup-title" v-if="caption">{{caption}}</div>
            <div class="popup-content">
                <div class="form-line">
                    <label>
                        {{message}}
                    </label>
                </div>
                <div class="form-line">
                    <button class="button button-primary" @click.prevent="close">{{$t("common.forms.ok")}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./information-box-component.ts" />
