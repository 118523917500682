import { useWebRestService } from "@sportaq/services/web";
import { CLOSE_EVENT, EVENT_SUCCESS } from "@sportaq/vue/components/web/dialogs/events";
import { ref, SetupContext } from "vue";

export const CODE_ERROR_EVENT = "onCodeError";

export function useConfirmCodeDialogCommon (context: SetupContext) {
    const confirmCodeRef = ref();
    const restService = useWebRestService();
    function onClose () {
        context.emit(CLOSE_EVENT);
    }

    function onSubmit () {
        restService.emailConfirmation(confirmCodeRef.value).then((result) => {
            context.emit(EVENT_SUCCESS, result);
        }).catch(e => context.emit(CODE_ERROR_EVENT, e.message));
    }

    return {
        confirmCodeRef,

        onSubmit,
        onClose
    };
}
