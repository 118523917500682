import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatValue, getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { DepositData } from "@sportaq/model/web/deposit-data";

export class APo51DepositMessageHandler extends AbstractMessageHandler<DepositData> {
    protected readonly requestType: string = "A.PO.5.1";
    constructor (readonly depositMethod: string, readonly orderSum: number, readonly currencyId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "DepositMethod", this.depositMethod);
        request.addChildWithValue(actionElement, "OrderedSum", this.orderSum.toString());
        request.addChildWithValue(actionElement, "CurrencyId", this.currencyId.toString());
        request.addChildWithValue(actionElement, "RequireConfirmation", "true");
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): DepositData {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");

        if (serverCode !== "5101") {
            throw new LocalizedError(`errors.mtl.aPo51.code${serverCode}`);
        }
        let urlElement = getChild(action, "RedirectURL");
        if (!urlElement) {
            urlElement = getRequiredChild(action, "Transaction");
        }
        const url = unescape(getRequiredValue(urlElement));
        return {
            orderGuid: getRequiredValue(getRequiredChild(action, "orderGUID")),
            orderId: getRequiredFloatValue(getRequiredChild(action, "orderId")),
            redirectUrl: url
        };
    }
}
