import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { Field, Form } from "vee-validate";
import { SetupContext } from "vue";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { useChangePhoneDialogCommon } from "@sportaq/vue/views/web/profile/change-phone-dialog-common";

export default {
    name: "ChangePhoneDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field, ConfirmCodeDialog
    },
    setup (props: unknown, context: SetupContext) {
        return useChangePhoneDialogCommon(context);
    }
};
