import { onBeforeRouteUpdate, RouteLocationNormalizedLoaded } from "vue-router";
import { useI18n } from "vue-i18n";
import { useSettings } from "@sportaq/services/index";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { resolveLanguage } from "@sportaq/i18n/index";
import { Localization } from "@sportaq/i18n/interfaces/interfaces";
enum PROPS { PROP_TYPE = "og:type",
PROP_TITLE = "og:title",
PROP_URL = "og:url",
PROP_IMAGE = "og:image",
PROP_LOCALE = "og:locale",
PROP_DESCRIPTION = "og:description"}

export function setTitle (title: string, description: string) {
    const titleElement = getOrCreateElement("title");
    if (titleElement) {
        titleElement.textContent = title;
    }
    setOgMeta(PROPS.PROP_TITLE, title);
    setOgMeta(PROPS.PROP_DESCRIPTION, description);
    const descriptionElement = getOrCreateMeta("description");
    if (descriptionElement) {
        descriptionElement.setAttribute("content", description);
    }
}

function getOrCreateElement (tagName: string) {
    const e = document.head.getElementsByTagName(tagName);
    if (e.length > 0) {
        return e.item(0);
    }
    const newElement = document.createElement(tagName);
    document.head.appendChild(newElement);
    return newElement;
}

function getOrCreateMeta (name: string) {
    const e = document.head.getElementsByTagName("meta");
    for (const t of e) {
        if (t.getAttribute("name") === name) {
            return t;
        }
    }
    const newElement = document.createElement("meta");
    document.head.appendChild(newElement);
    newElement.setAttribute("name", name);
    return newElement;
}

function setOgMeta (property: PROPS, content: string) {
    const meta = findOgMeta(property) ?? document.createElement("meta");
    meta.setAttribute("property", property);
    meta.setAttribute("content", content);
    document.head.appendChild(meta);
}

function findOgMeta (property: PROPS) {
    const metas = document.head.getElementsByTagName("meta");
    for (const meta of metas) {
        const propAttr = meta.getAttribute("property");
        if (propAttr && propAttr === property) {
            return meta;
        }
    }
    return undefined;
}

export function trySetDefaultTitle (i18n: Localization, brandType: ECompileBrandType, route: RouteLocationNormalizedLoaded) {
    if (!route.meta.hasTitle) {
        setDefaultTitle(i18n, brandType);
    }
}

function setDefaultTitle (i18n: Localization, brandType: ECompileBrandType) {
    const brandName = i18n.t("brands." + brandType);
    setTitle(i18n.t("webdesktop.pageTitle.default.title", { brand: brandName }), i18n.t("webdesktop.pageTitle.default.description", { brand: brandName }));
}

export function usePageTitleRouteGuard () {
    const i18n = useI18n();
    const settings = useSettings();
    setOgMeta(PROPS.PROP_TYPE, "website");
    setOgMeta(PROPS.PROP_IMAGE, "https://" + window.location.host + "/img/logo-betgol-1x1.png");
    setOgMeta(PROPS.PROP_LOCALE, resolveLanguage());
    onBeforeRouteUpdate((to) => {
        setOgMeta(PROPS.PROP_URL, window.location.toString());
        if (!to.meta.hasTitle) {
            setDefaultTitle(i18n, settings.brandType);
        }
    });
}
