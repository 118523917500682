import { Comparable, Equable } from "@sportaq/common/types/interfaces";

export function binarySearchIndex<T, K> (sortedArr: T[], value: K, comparator: (a: T, b: K) => number, start?: number, end?: number): number {
    const fromIndex = start ?? 0;
    const toIndex = end ?? sortedArr.length;

    let low = fromIndex;
    let high = toIndex - 1;

    while (low <= high) {
        const mid = (low + high) >>> 1;
        const compare = comparator(sortedArr[mid], value);

        if (compare < 0) {
            low = mid + 1;
        } else {
            if (compare > 0) {
                high = mid - 1;
            } else {
                // key found
                return mid;
            }
        }
    }
    // key not found.
    return -(low + 1);
}

export function insertIntoSortedArrayAtIndex<T> (sortedArr: T[], index: number, value: T) {
    if (index < 0) {
        index = -(index + 1);
    }
    sortedArr.splice(index, 0, value);
}

export function insertIntoSortedArray<T> (sortedArr: T[], value: T, comparator: (a: T, b: T) => number) {
    const index = binarySearchIndex(sortedArr, value, comparator);
    insertIntoSortedArrayAtIndex(sortedArr, index, value);
}

export function insertUniqueIntoSortedArray<T> (sortedArr: T[], value: T, comparator: (a: T, b: T) => number): boolean {
    const index = binarySearchIndex(sortedArr, value, comparator);
    if (index < 0) {
        insertIntoSortedArrayAtIndex(sortedArr, index, value);
        return true;
    }
    return false;
}

export function insertOrReplaceIntoSortedSet<T> (sortedArr: T[], value: T, comparator: (a: T, b: T) => number) {
    let index = binarySearchIndex(sortedArr, value, comparator);
    if (index < 0) {
        index = -(index + 1);
        sortedArr.splice(index, 0, value);
    } else {
        sortedArr[index] = value;
    }
}

export function insertOrUpdateIntoSortedSet<T, K> (sortedArr: T[], value: K, comparator: (a: T, b: K) => number, create: (a: K) => T, update: (a: T) => void): boolean {
    let index = binarySearchIndex(sortedArr, value, comparator);
    if (index < 0) {
        index = -(index + 1);
        sortedArr.splice(index, 0, create(value));
        return true;
    } else {
        update(sortedArr[index]);
        return false;
    }
}

export function arrayContains<T extends Equable<T>> (arr: T[], value: T): boolean {
    for (const item of arr) {
        if (item.equals(value)) {
            return true;
        }
    }
    return false;
}

export function nativeComparator<T extends Comparable<T>> (a: T, b: T): number {
    return a.compare(b);
}

export function nativeNumberComparator (a: number, b: number): number {
    return a - b;
}

export function deleteFromSortedArray<T> (sortedArr: T[], value: T, comparator: (a: T, b: T) => number): boolean {
    const index = binarySearchIndex(sortedArr, value, comparator);
    const result = index >= 0;
    if (result) {
        sortedArr.splice(index, 1);
    }
    return result;
}

export function deleteFromArray<T> (a: T[], predicate: (value: T) => boolean, singleItem: boolean): boolean {
    let result = false;
    let index = a.length - 1;
    while (index >= 0) {
        if (predicate(a[index])) {
            a.splice(index, 1);
            if (singleItem) {
                return true;
            }
            result = true;
        }
        index--;
    }
    return result;
}

export function listToRowMatrix<T> (list: T[], elementsPerSubArray: number): T[][] {
    const matrix: T[][] = [];
    for (let i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }
        matrix[k].push(list[i]);
    }
    return matrix;
}

export function listToColumnMatrix<T> (list: T[], columnsCount: number): T[][] {
    const matrix: T[][] = new Array<T[]>(columnsCount);
    for (let i = 0; i < list.length; i++) {
        const column = i % columnsCount;
        if (!matrix[column]) {
            matrix[column] = [];
        }
        matrix[column].push(list[i]);
    }
    return matrix;
}
