import AmountInputDialog from "@/views/deposit-view/components/amount-input-dialog/AmountInputDialog.vue";
import ExternalServiceFrame from "@/views/deposit-view/components/external-service-frame/ExternalServiceFrame.vue";
import VoucherDepositDialog from "@/views/deposit-view/components/voucher-deposit-dialog/VoucherDepositDialog.vue";
import { useDepositViewCommon } from "@sportaq/vue/views/web/deposit/deposit-view-common";
import { ItemType } from "@/views/deposit-view/components/deposit-view-item/deposit-view-item-component";
import DepositViewItem from "@/views/deposit-view/components/deposit-view-item/DepositViewItem.vue";
import { usePointList } from "@sportaq/vue/views/web/withdrawal/pount-list";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";
import BonusNotificationDialog from "@/views/deposit-view/components/bonus-notification-dialog/BonusNotificationDialog.vue";

export default {
    name: "DepositView",
    components: {
        AmountInputDialog,
        ExternalServiceFrame,
        VoucherDepositDialog,
        DepositViewItem,
        BonusNotificationDialog
    },
    setup () {
        const {
            isLoggedId,
            amountInputDialogVisible,
            depositMethod,
            redirectUrl,
            voucherDialogVisible,
            countryId,
            needShowBonusNotification,
            currencyValues,
            getPaymentSystemMin,
            onAmountInputClose,
            onAmountConfirmed,
            depositSafetyPay,
            depositPagoEfectivo,
            depositMonnetCard,
            depositTupay,
            depositWithTicket,
            onExternalServiceFrameClose,
            onVoucherDepositDialogClose,
            openYape,
            openLukita,
            openTunki,
            depositPagoSeguro,
            isPaymentSystemAvailable
        } = useDepositViewCommon();
        const partnerList = usePointList(useWebRestService(), useWebSettings().pointSettings);
        return {
            PaymentSystems,
            isLoggedId,
            amountInputDialogVisible,
            depositMethod,
            redirectUrl,
            voucherDialogVisible,
            ItemType,
            partnerList,
            countryId,
            currencyValues,
            getPaymentSystemMin,
            isPaymentSystemAvailable,
            needShowBonusNotification,
            onAmountInputClose,
            onAmountConfirmed,
            depositSafetyPay,
            depositPagoEfectivo,
            depositWithTicket,
            depositPagoSeguro,
            depositMonnetCard,
            depositTupay,
            onExternalServiceFrameClose,
            onVoucherDepositDialogClose,
            openYape,
            openLukita,
            openTunki
        };
    }
};
