import { BalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { WebDesktopStore } from "@sportaq/vuex/modules/web/web-module";
import { WebSettings } from "@sportaq/services/web/settings/settings-service";
import { WebDesktopRestService } from "@sportaq/services/web/rest/web-desktop-rest-service";
import { Router } from "vue-router";
import { RootStore } from "@sportaq/vuex/index";
import { resolveLanguage } from "@sportaq/i18n/index";

export async function userLogout (balanceStore: BalanceStore, rootStore: RootStore, store: WebDesktopStore, settings: WebSettings, restService: WebDesktopRestService, router: Router) {
    settings.storeSessionCode(undefined, true);
    store.user = undefined;
    await restService.startApplication(rootStore.userSharedInfo.countryId, resolveLanguage());
    balanceStore.clearBalance();
    router.push("/").then();
}
