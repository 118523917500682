<template>
    <div class="preloader" v-if="isRequestPending">
        <div class="preloader-el"></div>
    </div>
    <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }">
        <div class="registration-content registration-content-email" ref="pageTop">
            <div class="registration-bonus" v-if="!bonusCheck && bonuses !== undefined">
                <div class="registration-bonus-tagline">
                    <h1>{{ $t("bonusRegistration.title") }}</h1>
                    <p>{{ $t("bonusRegistration.subtitle") }}</p>
                </div>
                <label class="registration-bonus-item" v-for="bonus of bonuses" :key="bonus.bonusId">
                    <input type="radio" name="bonus" :value="bonus.bonusId"  :checked="bonus === selectedBonus" @click="bonusClick(bonus)">
                    <div class="registration-bonus-item-toggle" :class="getBonusClass(bonus.bonusTypeId)">
                        <img class="logo" :src="getImageUrl(bonus.miniImg)" alt="">
                        <div class="info">
                            <span class="label">{{$t(getBonusName(bonus.bonusTypeId))}}</span>
                            <div class="text">
                                <div class="description" v-html="bonus.name.get(lang)"></div>
                                <img src="/img/icon-info.svg" class="icon-info" @click.stop.prevent="[selectedBonus = bonus, showDescription = true]">
                            </div>
                        </div>
                    </div>
                </label>
                <label class="registration-bonus-item">
                    <input type="radio" name="bonus" value="-1" @click="bonusClick(undefined)">
                    <div class="registration-bonus-item-toggle orange">
                        <img class="logo" src="@assets/img/registration-bonus/logo/logo-4.png" alt="">
                        <div class="info">
                            <span class="label">{{ $t("bonusRegistration.noBonus.title") }}</span>
                            <div class="text">
                                <div class="description" v-html="$t('bonusRegistration.noBonus.description', {site})"></div>
                                <img src="/img/icon-info.svg" class="icon-info" @click.stop.prevent="[selectedBonus = undefined, showDescription = true]">
                            </div>
                        </div>
                    </div>
                </label>
            </div>
            <div v-if="bonusCheck" class="registration-content-wrap">
                <div class="title">{{ $t("routes.registration") }}</div>
                <div class="form-line">
                    <label >{{$t("webdesktop.registration.email.countryLabel")}}</label>
                    <country-select v-model="countryId"/>
                </div>
                <div class="form-line mobile">
                    <label for="phone" :class="{'label-error': errors.phone || customErrors.phone}">{{$t("webdesktop.registration.email.phoneLabel")}}</label>
                    <div class="icon">
                        <div class="num">{{phoneCode}}</div>
                    </div>
                    <Field type="tel" id="phone" name="phone" rules="required" @keydown="onPhoneKeyDown"/>
                    <div class="field-error" v-if="errors.phone || customErrors.phone">{{errors.phone}}{{customErrors.phone}}</div>
                </div>
                <div class="form-line">
                    <label for="email"  :class="{'label-error': errors.email || customErrors.email}">{{$t("webdesktop.registration.email.emailLabel")}}</label>
                    <Field type="email" id="email" name="email" rules="required|email|invalidCharacters" />
                    <div class="field-error" v-if="errors.email || customErrors.email">{{errors.email}}{{customErrors.email}}</div>
                </div>
                <div class="form-line">
                    <label for="firstName" :class="{'label-error': errors.firstName}">{{$t("webdesktop.registration.email.firstNameLabel")}}</label>
                    <Field type="text" rules="required|invalidCharacters" id="firstName" name="firstName"/>
                    <div class="field-error" v-if="errors.firstName">{{errors.firstName}}</div>
                </div>
                <div class="form-line">
                    <label for="lastName" :class="{'label-error': errors.lastName}">{{$t("webdesktop.registration.email.lastNameLabel")}}</label>
                    <Field type="text" rules="required|invalidCharacters" id="lastName" name="lastName"/>
                    <div class="field-error" v-if="errors.lastName">{{errors.lastName}}</div>
                </div>
                <div class="form-line">
                    <label for="birthday" :class="{'label-error': errors.birthday}">{{$t("webdesktop.registration.email.birthdayLabel")}}</label>
                    <Field type="date" :max="maxDate" rules="required|birthday" id="birthday" name="birthday"/>
                    <div class="field-error" v-if="errors.birthday">{{errors.birthday}}</div>
                </div>
                <div class="form-line">
                    <label for="document" :class="{'label-error': errors.document || customErrors.document}">{{$t("webdesktop.registration.email.documentLabel")}}</label>
                    <Field id="document" name="document" type="text" rules="required|invalidCharacters"/>
                    <div class="field-error" v-if="errors.document || customErrors.document">{{errors.document}}{{customErrors.document}}</div>
                </div>
                <div class="form-line">
                    <label for="login" :class="{'label-error': errors.login || customErrors.login}">{{$t("webdesktop.registration.email.loginLabel")}}</label>
                    <Field id="login" name="login" type="text" rules="required|min:8|invalidCharacters"/>
                    <div class="field-error" v-if="errors.login || customErrors.login">{{errors.login}}{{customErrors.login}}</div>
                </div>
                <div class="form-line password">
                    <password-field :isConfirm="false" :errors="errors" ></password-field>
                </div>
                <div class="form-line password-repeat">
                    <password-field :isConfirm="true" :errors="errors"></password-field>
                </div>
                <!-- <div class="form-line" v-if="!referralCode">
                    <label for="referralCode">{{$t("webdesktop.registration.email.promoLabel")}}</label>
                    <Field id="referralCode" name="referralCode" type="text" :placeholder="$t('webdesktop.registration.email.promoPlaceholder')" />
                </div> -->
                <div class="form-line width-100 form-checkbox form-bonus">
                    <div v-for="bonus of bonuses" :key="bonus.bonusId">
                        <input type="radio" name="bonus" :value="bonus.bonusId" :checked="selectedBonus === bonus" @click="selectedBonus = bonus" :id="`bonus-${bonus.bonusId}`">
                        <label :for="`bonus-${bonus.bonusId}`">{{$t(getBonusName(bonus.bonusTypeId))}}</label>
                    </div>
                    <div>
                        <input type="radio" name="bonus" id="no-bonus" value="-1" :checked="!selectedBonus" @click="selectedBonus = undefined">
                        <label for="no-bonus">{{ $t("bonusRegistration.noBonus.title") }}</label>
                    </div>
                </div>
                <div class="form-line width-100 form-checkbox agreement" :class="{unchecked: !agreement}">
                  <input type="checkbox" name="ageAgree" id="age-agree" v-model="agreement"/>
                    <label for="age-agree">{{$t("landing.agreement")}}</label>
                </div>
                <div class="form-line">
                    <button id="register-button" class="button button-general" @click.stop.prevent="handleSubmit(onSubmit)">{{$t("webdesktop.registration.email.registerLabel")}}</button>
                </div>
                <policy />
            </div>
        </div>
    </Form>
    <!--  BONUS DESCRIPTION  -->
    <div class="registration-bonus-popup" v-if="showDescription">
        <div class="registration-bonus-popup-content">
            <h2>{{$t(selectedBonus ? getBonusName(selectedBonus.bonusTypeId) : "bonusRegistration.noBonus.title")}}</h2>
            <div name="description" v-if="selectedBonus" v-html="selectedBonus.description.get(lang)">
            </div>
            <template v-else>
                <div v-html="$t('bonusRegistration.noBonus.description', {site})">
                </div>
                <div>
                    <ul v-html="$t('bonusRegistration.noBonus.list', {site})">
                    </ul>
                </div>
            </template>
            <div class="form-line registration-buttons">
                <button type="button" class="button" @click.stop.prevent="showDescription = false">
                    {{$t("common.forms.close")}}
                </button>
            </div>
        </div>
    </div>
    <email-done v-if="sendSuccess"/>
</template>

<script src="./email-registration-component.ts"/>
