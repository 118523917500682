import QuotationView from "@sportaq/common/enums/quotation-views";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import { LOCKED_QUOTATION, QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { useI18n } from "vue-i18n";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { computed, markRaw, onMounted, ref, shallowRef, UnwrapRef, watch } from "vue";
import { Ref } from "@sportaq/common/types/types";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import { Period } from "@sportaq/model/betting/events/period-store";
import EPeriodType from "@sportaq/common/enums/period-types";
import { countries } from "@sportaq/model/consts/countries";

export namespace ScoreboardEventLineComponent {
    export interface ExtraProps {
        quotationViews: QuotationView[];
        columnsCount: number;
    }

    export interface Props {
        source: PeriodSortInfo;
        extraProps: ExtraProps;
    }

    export type UndefinedQuotation = QuotationWrapper | undefined;

    type UpdateQuotationGroups = (e: UnwrapRef<BettingEvent>, newViews: QuotationView[], oldViews: QuotationView[]) => void;

    export function useScoreboardEventLineComponent (props: Props, updateQuotationGroups: UpdateQuotationGroups,
        needReactiveScore: boolean) {
        const i18n = useI18n();
        const eventSupplier = useEventSupplier();
        const scoreboardStore = useScoreboardStore();

        const nonReactiveEvent = eventSupplier.getEvent(props.source.eventType, props.source.positionId);
        const event = shallowRef(nonReactiveEvent);
        const reactiveScore = needReactiveScore ? ref(nonReactiveEvent.resultText) : undefined;
        const period = shallowRef(event.value.periods.getPeriod(props.source.period));
        // allCount can't be computed field because event reference maybe doesn't change (we aren't cloning result of scoreboardStore.getEvent(value.eventId) in watch section)
        const allCount = ref(event.value.getCounterByPeriod(period.value.type));

        const isMainPeriod = computed(() => props.source.period === EPeriodType.MAIN);
        const flag = computed(() => isMainPeriod.value ? countries.getFlag(event.value.partition.countryId) : undefined);
        const isLeagueMode = computed(() => scoreboardStore.leagueMode);
        const isFavorite = computed(() => scoreboardStore.isFavourite(props.source.positionId));

        function toggleFavorite () {
            scoreboardStore.toggleFavouriteItem(props.source.eventType, props.source.positionId);
        }

        onMounted(() => {
            updateQuotationGroups(event.value, props.extraProps.quotationViews, []);
        });

        watch(() => props.source, (value) => {
            event.value = eventSupplier.getEvent(value.eventType, value.positionId);
            if (needReactiveScore && reactiveScore) {
                reactiveScore.value = event.value.resultText;
            }
            period.value = event.value.periods.getPeriod(value.period);
            updateQuotationGroups(event.value, props.extraProps.quotationViews, []);
            allCount.value = event.value.getCounterByPeriod(period.value.type);
        });

        watch(() => props.extraProps.quotationViews, (value, oldValue) => {
            updateQuotationGroups(event.value, value, oldValue);
        });

        return {
            i18n,
            scoreboardStore,

            event,
            reactiveScore,
            period,
            flag,
            isMainPeriod,
            allCount,
            isLeagueMode,
            isFavorite,

            toggleFavorite
        };
    }

    function isChanged (newViews: QuotationView[], oldViews: QuotationView[], index: number) {
        if ((newViews.length > index) && (oldViews.length > index)) {
            return oldViews[index] !== newViews[index];
        }
        return true;
    }

    export function updateQuotationGroup (group: Ref<UndefinedQuotation[]>, event: UnwrapRef<BettingEvent>, period: Period, newViews: QuotationView[], oldViews: QuotationView[], index: number, condition: boolean) {
        if (condition && newViews) {
            if (isChanged(newViews, oldViews, index)) {
                const quotationView = newViews[index];
                const ids = period.getView(quotationView);
                group.value = ids.map(id => {
                    const quotation = event.quotations.getQuotationById(id);
                    if (quotation) {
                        return markRaw({
                            eventType: event.eventType,
                            quotation
                        });
                    }
                    return markRaw({
                        eventType: event.eventType,
                        quotation: LOCKED_QUOTATION
                    });
                });
            }
        } else {
            group.value = [];
        }
    }
}
