<template>
    <div class="coupon-block" data-cy="simple-bet-block" v-for="stake of simpleStakes" :key="stake.templateKey">
        <div class="coupon-block-title">{{ $t("betting.betSlip.simple") }}</div>
        <mobile-simple-stake-row :stake="stake" :data-cy="'table-standalone-'+stake.templateKey"/>
    </div>
    <div class="coupon-block" :data-cy="'table-'+stake.templateKey" v-for="(stake, index) of combinedStakes"
         :key="'table-'+stake.templateKey">
        <div class="coupon-block-title" data-cy="name" v-if="stake.express">
            {{ $t("betting.betSlip.express") }}
        </div>
        <div class="coupon-block-title" data-cy="name" v-else>{{
                $t("betting.betSlip.system", {
                    limit: stake.limit,
                    base: stake.base
                })
            }}
        </div>

        <div class="coupon-block-head">
            <div class="name width-33" data-cy="coef">
                <template v-if="stake.express">
                    {{ $t("betting.betSlip.dialogBetAccepted.columns.coef") }} : {{ formatCoef(stake.coef) }}
                </template>
                <template v-else>
                    {{ $t("betting.betSlip.dialogBetAccepted.columns.variants", { variants: stake.variants }) }}
                </template>
            </div>
            <div class="name width-33" data-cy="amount">
                {{ $t("betting.betSlip.dialogBetAccepted.columns.amount") }} :
                {{ $n(stake.amount, "currency") }}
            </div>
            <div class="name width-33" data-cy="maxWin">
                {{ $t("betting.betSlip.dialogBetAccepted.columns.maxWin") }} :
                {{ $n(stake.maxWin, "currency") }}
            </div>
        </div>

        <mobile-simple-stake-row v-for="(entry) of stake.simples" :key="'table-combined-'+index+'-'+entry.templateKey"
                                 :data-cy="'table-combined-'+index+'-'+entry.templateKey"
                                 :stake="entry"
                                 :standalone="false"/>
    </div>
</template>

<script src="./mobile-inner-stake-table-component.ts"/>
