import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import { AccountCategory, AccountType, BalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { BalanceUpdateParams } from "@sportaq/vue/components/common/balance-component/web-balance-update";
import { RestService } from "@sportaq/services/rest/rest-service";

export namespace BalanceUpdate{
    export async function refreshBalance (restService: RestService, balanceStore: BalanceStore, settingsService: BaseSettingsService, params: BalanceUpdateParams) {
        if (params.isLoggedIn && !params.isLoggedIn()) {
            return;
        }
        const extendedBalance = await restService.getExtendedBalance();
        if (extendedBalance.mainBalance) {
            const b = extendedBalance.mainBalance;
            if (b.primaryAccount) {
                balanceStore.setMainAccountBalance(BigDecimal.fromNumber(b.primaryAccount.sumAccount, BIG_DECIMAL_SCALES.MONEY));
                balanceStore.currency = b.primaryAccount.currencyMark;
                balanceStore.currencyId = b.primaryAccount.currencyId;
                if (b.primaryAccount.isUsed && balanceStore.activeAccount === AccountCategory.Main) {
                    balanceStore.setMainAccountType(AccountType.USER_ACCOUNT_PRIMARY);
                }
            }
            if (b.bonusAccount) {
                balanceStore.setMainAccountBonus(BigDecimal.fromNumber(b.bonusAccount.sumAccount, BIG_DECIMAL_SCALES.MONEY));
                if (b.bonusAccount.isUsed && balanceStore.activeAccount === AccountCategory.Main) {
                    balanceStore.setMainAccountType(AccountType.USER_ACCOUNT_BONUS);
                }
            }
            if (b.primaryAccount && params.canSwitchAccounts && settingsService.pointSettings) {
                const minStakeSum = Math.max(settingsService.pointSettings.liveSumStakeMin, settingsService.pointSettings.preMatchSumStakeMin);
                if (b.primaryAccount.sumAccount < minStakeSum && b.primaryAccount.isUsed) {
                    if (b.bonusAccount && b.bonusAccount.sumAccount > minStakeSum && balanceStore.activeAccount === AccountCategory.Main) {
                        params.canSwitchAccounts = false;
                        restService.switchToBonusAccount().then(() => {
                            balanceStore.setMainAccountType(AccountType.USER_ACCOUNT_BONUS);
                        });
                    }
                }
            }
            if (params.balanceUpdateCallBack) {
                params.balanceUpdateCallBack(b);
            }
        }
        if (extendedBalance.casinoBalance) {
            if (extendedBalance.casinoBalance.primaryAccount) {
                balanceStore.setCasinoAccountBalance(BigDecimal.fromNumber(extendedBalance.casinoBalance.primaryAccount.sumAccount, BIG_DECIMAL_SCALES.MONEY));
                if (extendedBalance.casinoBalance.primaryAccount.isUsed && balanceStore.activeAccount === AccountCategory.Casino) {
                    balanceStore.setCasinoAccountType(AccountType.USER_ACCOUNT_PRIMARY);
                }
            }
            if (extendedBalance.casinoBalance.bonusAccount) {
                balanceStore.setCasinoAccountBonus(BigDecimal.fromNumber(extendedBalance.casinoBalance.bonusAccount.sumAccount, BIG_DECIMAL_SCALES.MONEY));
                if (extendedBalance.casinoBalance.bonusAccount.isUsed && balanceStore.activeAccount === AccountCategory.Casino) {
                    balanceStore.setCasinoAccountType(AccountType.USER_ACCOUNT_BONUS);
                }
            }
        }
    }

}
