<template>
    <template v-if="isCategoryLine">
        <compact-scoreboard-category-line :source="source"/>
    </template>
    <template v-else>
        <compact-scoreboard-event-line :source="source" :extraProps="extraProps"/>
    </template>
</template>

<script src="./mobile-scoreboard-line-component.ts"/>
