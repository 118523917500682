import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { SetupContext } from "vue";
import { useExternalFrameCommon } from "@sportaq/vue/views/web/deposit/external-frame-common";

export default {
    name: "ExternalServiceFrame",
    props: {
        redirectUrl: {
            type: String,
            required: true
        }
    },
    emits: [CLOSE_EVENT],
    setup (props: [], context: SetupContext) {
        return useExternalFrameCommon(context);
    }
};
