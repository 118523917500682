import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { Field, Form } from "vee-validate";
import { SetupContext } from "vue";
import { useGeneralWithdrawalDialogCommon } from "@sportaq/vue/views/web/withdrawal/general-withdrawal-dialog-common";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { PaymentSystems } from "@sportaq/common/enums/payment-systems";
export default {
    name: "VisaWithdrawalDialog",
    emits: [CLOSE_EVENT],
    components: {
        Form, Field, ConfirmCodeDialog
    },
    props: {
        minAmount: {
            type: Number,
            required: true
        },
        paymentSystem: {
            type: String,
            required: true
        }
    },
    setup (props: { minAmount: number, paymentSystem: PaymentSystems }, context: SetupContext) {
        return useGeneralWithdrawalDialogCommon(props.minAmount, props.paymentSystem, context);
    }
};
