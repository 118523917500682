import { ElectronRendererProvider } from "@sportaq/electron/types/electron-types";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { TIMEOUTS } from "@sportaq/common/consts/default-consts";

export enum ElectronEvents {
    POWER_MANAGEMENT_SUSPEND = "suspend",
    POWER_MANAGEMENT_RESUME = "resume",
    POWER_MANAGEMENT_LOCK_SCREEN = "lock-screen",
    POWER_MANAGEMENT_UNLOCK_SCREEN = "unlock-screen",
    POWER_MANAGEMENT_USER_BECOME_INACTIVE = "user-become-inactive",
    POWER_MANAGEMENT_USER_BECOME_ACTIVE = "user-become-active",
    AUTO_UPDATER_ERROR = "auto-updater-error",
    CHECKING_FOR_UPDATE = "checking-for-update",
    UPDATE_AVAILABLE = "update-available",
    UPDATE_NOT_AVAILABLE = "update-not-available",
    UPDATE_DOWNLOADED = "update-downloaded",
    DOWNLOAD_PROGRESS = "download-progress",
    APP_VERSION_RESPONSE = "app-version-response",
    SESSION_KEY_RESPONSE = "session-key-response"
}

export enum ElectronCommand {
    MINIMIZE_APP = "minimize-app",
    TOGGLE_DEVTOOLS = "toggle-devtools",
    EXIT_APP = "exit-app",
    APP_RELOAD = "app-reload",
    PRINT = "print",
    CHECK_UPDATES = "check-updates",
    UPDATES_QUIT_AND_INSTALL = "quit-and-install",
    LOG = "electron-log",
    APP_VERSION = "app-version",
    SESSION_KEY = "session-key",
    FULL_SCREEN = "fullscreen"
}

export function sendElectronCommand (command: ElectronCommand, data: unknown = "") {
    if (process.env.IS_ELECTRON) {
        const renderer = (window as unknown as ElectronRendererProvider).ipcRenderer;
        if (renderer) {
            if (command === ElectronCommand.APP_RELOAD) {
                eventBus.emit(Events.APPLICATION_IS_ABOUT_TO_RELOAD);
                setTimeout(() => (renderer.send(command, data)), TIMEOUTS.BEFORE_APPLICATION_RELOAD_GAP);
            } else {
                renderer.send(command, data);
            }
        }
    }
}
