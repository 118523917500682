import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { useClockProvider } from "@sportaq/services/index";
import { computed } from "vue";
import EventType from "@sportaq/common/enums/event-type";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import {
    TimeFilter
} from "@sportaq/vue/components/betting/betting-filter-menu/betting-filter-menu-date/time-filter-functions";
import Button = TimeFilter.Button;
import setTimeFilter = TimeFilter.setTimeFilter;

export namespace BettingNavigationSidebarDateFilterComponent {
    export function useBettingNavigationSidebarDateFilter (dateButtons: Button[]) {
        const clockProvider = useClockProvider();
        const store = useScoreboardStore();

        const selectedId = computed(() => store.getEventsViewFilter().timeInterval?.buttonId ?? -1);

        const allButtonActive = computed(() => !store.getEventsViewFilter().timeInterval &&
            store.getEventsViewFilter().countryFilter.isEmpty());

        const showDateFilter = computed(() =>
            (store.getEventsViewFilter().eventType === EventType.PRE_MATCH &&
                store.scoreboardPageType === EScoreboardPageType.EVENT_LIST) ||
            store.scoreboardPageType === EScoreboardPageType.FAVOURITES);

        function filterSelected (selectedId: number) {
            if (selectedId > 0) {
                const button = dateButtons.find(value => value.id === selectedId);
                if (button) {
                    setTimeFilter(store, clockProvider, button);
                }
            } else {
                store.updateEventsFilter({ timeInterval: { type: "unset" } });
            }
        }

        function clearFilters () {
            store.updateEventsFilter({ clearCountryAndTimeInterval: { type: "unset" } });
        }

        function select (button: Button) {
            const value = (button.id !== selectedId.value) ? button.id : -1;
            filterSelected(value);
        }

        return {
            selectedId,

            allButtonActive,
            showDateFilter,

            select,
            clearFilters
        };
    }
}
