import {
    AcceptResult,
    EventViewFilter
} from "@sportaq/vuex/modules/betting/scoreboard/event-view-filter/event-view-filter";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import {
    EPeriodListResolverType, EventListData,
    PeriodListRebuilder,
    PeriodListResolver, PeriodListUpdater
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/period-list-resolver";
import {
    PeriodSortInfo,
    PeriodSortInfoOptions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import {
    ScoreboardPeriodFunctions
} from "@sportaq/vuex/modules/betting/scoreboard/periods/period-list-resolvers/common/functions";
import PeriodSortInfoHelper = ScoreboardPeriodFunctions.PeriodSortInfoHelper;

export class PlainPeriodListResolver implements PeriodListResolver<PeriodSortInfo> {
    private readonly helper;
    readonly type = EPeriodListResolverType.PLAIN;

    constructor () {
        this.helper = new PeriodSortInfoHelper(
            (event, period, options) => PeriodSortInfo.createEvent(event, period, options)
        );
    }

    get batchRebuilder (): PeriodListRebuilder<PeriodSortInfo> {
        return new InternalPeriodListRebuilder(this.helper);
    }

    get batchUpdater (): PeriodListUpdater<PeriodSortInfo> {
        return new InternalPeriodListUpdater(this.helper);
    }

    expandEvent (periods: PeriodSortInfo[], event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): PeriodSortInfo[] {
        const newViewList = Array.from(periods);
        this.helper.updateEvent(newViewList, event, expanded, {
            reverseTimeSorting
        });
        return newViewList;
    }
}

class InternalPeriodListRebuilder implements PeriodListRebuilder<PeriodSortInfo> {
    private periods: PeriodSortInfo[] = [];
    private groupEvents: PeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper) {
    }

    begin (): void {
        this.periods = [];
        this.groupEvents = [];
    }

    addEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, false);
        if (acceptResult.accept) {
            const list = event.isGroupEvent ? this.groupEvents : this.periods;
            this.helper.insertNewEvent(list, event, expanded, {
                reverseTimeSorting
            });
        }
        return acceptResult;
    }

    end (): EventListData<PeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}

class InternalPeriodListUpdater implements PeriodListUpdater<PeriodSortInfo> {
    private periods: PeriodSortInfo[] = [];
    private groupEvents: PeriodSortInfo[] = [];

    constructor (private readonly helper: PeriodSortInfoHelper) {
    }

    begin (data: EventListData<PeriodSortInfo>): void {
        this.periods = Array.from(data.periods);
        this.groupEvents = Array.from(data.groupEvents);
    }

    addOrUpdateEvent (filter: EventViewFilter, event: BettingEvent, expanded: boolean, reverseTimeSorting: boolean): AcceptResult {
        const acceptResult = filter.tryAccept(event, false);
        const options: PeriodSortInfoOptions = {
            reverseTimeSorting
        };
        const list = event.isGroupEvent ? this.groupEvents : this.periods;
        const index = list.findIndex(value => value.positionId === event.positionId);
        if (acceptResult.accept && index >= 0) {
            this.helper.updateEvent(list, event, expanded, options, index);
        } else if (acceptResult.accept && index === -1) {
            this.helper.insertNewEvent(list, event, expanded, options);
        } else if (!acceptResult.accept && index >= 0) {
            this.helper.deleteEvent(list, event.positionId, index);
        }
        return acceptResult;
    }

    deleteEvent (positionId: number): void {
        if (!this.helper.deleteEvent(this.groupEvents, positionId)) {
            this.helper.deleteEvent(this.periods, positionId);
        }
    }

    end (): EventListData<PeriodSortInfo> {
        return {
            periods: this.periods,
            groupEvents: this.groupEvents
        };
    }
}
