import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { useWebRestService } from "@sportaq/services/web";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { ConfirmationType } from "@sportaq/services/rest/messages/system/a_cn_1_1_confirmation";
import { formatVoucherNumber } from "@sportaq/common/utils/number-utils";
import { ref, SetupContext } from "vue";

export function useExpressWithdrawalDialogCommon (context: SetupContext) {
    const store = useWebStore();
    const account = store.user?.userAccounts.getPrimaryAccount();
    const accountNumber = store.user?.userNumber;
    const currency = account?.currencyMark;
    const amountRef = ref<number>();
    const restService = useWebRestService();
    const codeRef = ref();
    function close () {
        context.emit(CLOSE_EVENT);
    }
    function submit () {
        if (account && amountRef.value) {
            restService.withdrawal(account.accountNumber, amountRef.value,
                account.currencyId, "instant").then((code) => {
                restService.emailConfirmation(code).then(result => {
                    if (result.confirmationType === ConfirmationType.Withdrawal) {
                        codeRef.value = result.withdrawalOrder.payCode;
                    }
                });
            });
        }
    }
    return {
        currency,
        amountRef,
        codeRef,
        accountNumber,
        formatVoucherNumber,
        close,
        submit
    };
}
