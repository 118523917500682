<template>
    <nav class="main-menu">
        <ul class="main-menu-list">
            <template v-for="item of menuItems" :key="item.id">
                <li :class="['main-menu-item', selectedTab && item.id === selectedTab.id ? 'active':'',
                    item.id === EMenuItem.BetSlip && quotationCount <= 0 ?'disabled': '']"
                    :data-cy="`${item.dataCy}-route`" @click="select(item)">
                    <img :src="require(`@assets/img/mobile/${imageThemePath}/${item.iconName}`)" alt="">
                    <div class="text">{{ $t(item.i18nKey) }}</div>
                    <div class="count" data-cy="coupon-button-count" v-if="item.id === EMenuItem.BetSlip">
                        {{ quotationCount }}
                    </div>
                </li>
            </template>
            <li class="main-menu-item" @click="showMenu">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-menu.svg`)" alt="">
                <div class="text">{{ $t("mobile.bottomBar.menuButton") }}</div>
            </li>
        </ul>
    </nav>
    <game-search-dialog v-if="isSearchDialogVisible" @close="onSearchDialogClose"></game-search-dialog>
</template>

<script src="./app-bottom-bar-component.ts"/>
