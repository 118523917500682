<template>
    <div class="item" @click="selectQuotation" :class="[diffsClass, {'active': isSelected}]"
         :data-cy="`line-quotation-${source.quotation.positionId}-${source.quotation.key.quotationId}-${source.quotation.id}`">
        <span v-if="quotationPresent && !eventBlocked">{{ quotationFormattedCoef }}</span>
        <span v-else-if="isLocked || (quotationPresent && eventBlocked)">
            <img src="@assets/img/icon-lock.svg" alt=""/>
        </span>
    </div>
</template>

<script src="./compact-scoreboard-line-quotation-component.ts"/>
