<template>
    <div id="safetyPay" style="z-index: 1000; position: fixed; background: rgb(250, 250, 250); display: block; width: 100%; top: 0; bottom: 0; left: 0;right: 0; min-height: auto;">
        <button id="closeBtn" style="top: 5px; left: 5px;" @click.prevent="close" class="frame-close">Close</button>
        <iframe id="ourframe" class="" name="fr1" style="top: 60%; text-align: center; display: block; background: rgb(250, 250, 250);
         left: 30%; width: 100%; height: calc(100vh - 5.867vw); margin-top: 10px; margin-left: 0px; padding-bottom: 15.167vw;"
                :src="redirectUrl"
                width="100%" height="100%" frameborder="0" scrolling="auto" marginheight="20" marginwidth="30">
        </iframe>
    </div>
</template>

<script src="./external-service-frame.ts" />
