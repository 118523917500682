import { onMounted, onUnmounted, SetupContext } from "vue";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";

export function useExternalFrameCommon (context: SetupContext) {
    function close () {
        context.emit(CLOSE_EVENT);
    }

    function onMessage (e: MessageEvent) {
        if (e.data === "closeiframe") {
            context.emit(CLOSE_EVENT);
        }
    }
    onMounted(() => {
        window.addEventListener("message", onMessage, false);
    });

    onUnmounted(() => {
        window.removeEventListener("message", onMessage);
    });

    return {
        close
    };
}
