import { computed, ref, watch } from "vue";
import { ROUTES } from "@/router/routes-consts";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { useRoute, useRouter } from "vue-router";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";
import BalanceComponent from "@sportaq/vue/components/common/balance-component/BalanceComponent.vue";
import { userLogout } from "@sportaq/vue/views/web/user-logout";
import { AccountType, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useWebRestService, useWebSettings } from "@sportaq/services/web";
import { EMenuItem, MenuItem } from "@/components/layout/app-bottom-bar/app-bottom-bar-component";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import {
    usePreferencesDialogCommon
} from "@sportaq/vue/components/common/preferences-dialog/preferences-dialog-common";
import { EQuotationFormat } from "@sportaq/common/enums/quotation-format";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { isServiceEnabled } from "@sportaq/model/games/functions";
import { EGameService } from "@sportaq/model/common/point-settings";
import { BaseSettingsService } from "@sportaq/services/base-setting-service/base-settings-service";
import { ECompileBrandType } from "@sportaq/common/enums/compile-time-vars";
import { useRootStore } from "@sportaq/vuex/index";
import { useWebEnvironment } from "@sportaq/services/web/environment/web-environment";

enum ModalDialogs {
    Deposit = "Deposit",
    Withdrawal = "Withdrawal",
    CouponVerification = "CouponVerification",
    ResetPassword = "ResetPasswordDialog",
    ConfirmCodeDialog = "ConfirmCodeDialog",
    SearchEventsDialog = "EventSearchDialog",
    LoginDialog = "LoginPopupDialog",
    None = "None"
}

export default {
    components: {
        LoginPopupDialog,
        ResetPasswordDialog,
        BalanceComponent
    },
    setup () {
        const rootStore = useRootStore();
        const store = useWebStore();
        const route = useRoute();
        const betSlipStore = useBetSlipStore();
        const balanceStore = useBalanceStore();
        const settings = useWebSettings();
        const environment = useWebEnvironment();
        const router = useRouter();
        const restService = useWebRestService();
        const activeDialog = ref<ModalDialogs>(ModalDialogs.None);
        const themes = computed(() => settings.pointSettings.themes);
        const isRegistrationEnabled = environment.isRegistrationEnabled;
        const isInstallationAvailable = computed(() => store.isAppInstallationAvailable);
        const menuItems = computed(() => {
            const lang = currentLang.value;
            return getMenuItems(settings, lang, isLoggedIn.value);
        });
        const user = computed(() => [store.user?.firstName, store.user?.lastName]);
        const bonus = computed(() => balanceStore.bonus);
        const accountType = computed(() => balanceStore.accountType);
        const activeAccount = ref(0);

        const {
            currentLang,
            changeLang,
            quotationFormat,
            supportedLocales,
            onQuotationFormatChange,
            theme,
            onThemeChange
        } = usePreferencesDialogCommon(settings, () => {
            window.location.replace(`/${currentLang.value}${route.path.substring(3)}`);
        });

        const isLoggedIn = computed(() => {
            return store.user !== undefined;
        });

        const logo = environment.logo === "" ? "/img/logo.svg" : environment.logo;

        watch(() => route.path, () => {
            hideMenu();
        });

        function hideMenu () {
            document.body.classList.remove("drop-menu-active");
        }

        async function select (item: MenuItem) {
            if (item.path) {
                betSlipStore.isShowed = false;
                await router.push({ path: item.path });
            }
        }

        function onModalDialogClose () {
            activeDialog.value = ModalDialogs.None;
        }

        function onModalDialogOpen (dialog: ModalDialogs) {
            activeDialog.value = dialog;
        }

        function installApplication () {
            hideMenu();
            eventBus.emit(Events.INSTALL_MOBILE_APPLICATION);
        }

        async function logout () {
            hideMenu();
            await userLogout(balanceStore, rootStore, store, settings, restService, router);
        }

        return {
            bonus,
            accountType,
            activeAccount,
            AccountType,
            logo,
            isLoggedIn,
            ROUTES,
            COMMON_ROUTES,
            ModalDialogs,
            activeDialog,
            theme,
            themes,
            currentLang,
            supportedLocales,
            menuItems,
            EMenuItem,
            EQuotationFormat,
            isInstallationAvailable,
            quotationFormat,
            isRegistrationEnabled,
            user,
            select,
            hideMenu,
            onModalDialogOpen,
            onModalDialogClose,
            logout,
            onThemeChange,
            changeLang,
            installApplication,
            onQuotationFormatChange
        };
    }
};

/* eslint-disable @typescript-eslint/no-unused-vars */
function getMenuItems (settings: BaseSettingsService, lang: string, isLoggedIn: boolean): MenuItem[] {
    const result: MenuItem[] = [];
    if (isServiceEnabled(settings, EGameService.Betting)) {
        result.push({
            id: EMenuItem.Sport,
            path: COMMON_ROUTES.SPORT.path,
            i18nKey: "mobile.routes.sport",
            iconName: "icon-deportes.svg",
            dataCy: "sport"
        });
        result.push({
            id: EMenuItem.Live,
            path: COMMON_ROUTES.LIVE.path,
            i18nKey: "mobile.routes.live",
            iconName: "icon-envivo.svg",
            dataCy: "live"
        });
    }
    if (isServiceEnabled(settings, EGameService.Virtual)) {
        result.push({
            id: EMenuItem.Virtual,
            path: COMMON_ROUTES.VIRTUAL.path,
            i18nKey: "routes.virtual",
            iconName: "icon-virtual.svg",
            dataCy: "virtual"
        });
    }
    if (isServiceEnabled(settings, EGameService.Casino)) {
        result.push({
            id: EMenuItem.Casino,
            path: COMMON_ROUTES.CASINO.path,
            i18nKey: "mobile.routes.casino",
            iconName: "icon-casino2.svg",
            dataCy: "casino"
        });
    }
    if (isServiceEnabled(settings, EGameService.Race)) {
        if (settings.brandType !== ECompileBrandType.certabet || lang !== "ru") {
            result.push({
                id: EMenuItem.Horses,
                path: COMMON_ROUTES.HORSE.path,
                i18nKey: "routes.horse",
                iconName: "icon-horses.svg",
                dataCy: "horses"
            });
        }
    }
    return result;
}
