import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { MtlError } from "@sportaq/common/exceptions/localized-errors";
import {
    getRequiredAttribute,
    getRequiredChild, getRequiredValue
} from "@sportaq/common/utils/xml-helper-functions";
import { EmailRegistrationData } from "@sportaq/model/web/email-registration-data";
import Cookies from "js-cookie";
import { User } from "@sportaq/model/common/user-accounts";
import { LoginResult, parseUser } from "@sportaq/services/web/rest/messages/registration/login-result";

export class AUs11EmailRegistrationMessageHandler extends AbstractMessageHandler<LoginResult> {
    protected readonly requestType: string = "A.US.1.1";
    constructor (readonly registrationData: EmailRegistrationData, readonly bonusId: number | undefined) {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "action", {
            type: this.requestType
        });
        request.addChildWithValue(actionElement, "FirstName", this.registrationData.firstName);
        request.addChildWithValue(actionElement, "LastName", this.registrationData.lastName);
        // request.addChildWithValue(actionElement, "CityText", this.registrationData.cityText);
        request.addChildWithValue(actionElement, "CountryId", this.registrationData.country.toString());
        // request.addChildWithValue(actionElement, "Address", this.registrationData.address);
        request.addChildWithValue(actionElement, "Phone", this.registrationData.phone);
        request.addChildWithValue(actionElement, "Email", this.registrationData.email);
        request.addChildWithValue(actionElement, "Birthday", this.registrationData.birthday.toString());
        request.addChildWithValue(actionElement, "IdentificationCode", "DNI:" + this.registrationData.document);
        request.addChildWithValue(actionElement, "ConfirmationMethod", "email");
        request.addChildWithValue(actionElement, "Login", this.registrationData.login);
        if (this.bonusId) {
            request.addChildWithValue(actionElement, "BonusId", this.bonusId.toString());
        }
        request.addChildWithValue(actionElement, "Password", this.registrationData.password);
        request.addChildWithValue(actionElement, "ReferralCode", this.registrationData.referralCode);
        const gaClientId = Cookies.get("_ga");
        if (gaClientId) {
            request.addChildWithValue(actionElement, "GA_CLIENTID", gaClientId);
        }
        if (this.registrationData.referralSystem) {
            request.addChildWithValue(actionElement, "ReferralSystem", this.registrationData.referralSystem);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parseMessageBody (body: Element, _head: Element): LoginResult {
        const action = getRequiredChild(body, "action");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "1100") {
            const sessionCode = getRequiredValue(getRequiredChild(_head, "sessioncode"));
            const userElement = getRequiredChild(action, "User");
            const user: User = parseUser(userElement);
            return {
                sessionCode,
                user
            };
        }
        throw new MtlError(serverCode);
    }
}
