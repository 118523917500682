import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getBaseballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.popular",
        rows: [
            {
                id: EDetailRow.baseball_x1_2,
                title: "detailInfo.groups.baseball.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.baseball_total,
                title: "detailInfo.groups.baseball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.baseball_handicaps,
                title: "detailInfo.groups.baseball.handicaps",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam1,
                title: "detailInfo.groups.baseball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam2,
                title: "detailInfo.groups.baseball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1_2,
                title: "detailInfo.groups.baseball.x1_2",
                quotationIds: [
                    [171, 173]
                ]
            },
            {
                id: EDetailRow.baseball_total,
                title: "detailInfo.groups.baseball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.baseball_handicaps,
                title: "detailInfo.groups.baseball.handicaps",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam1,
                title: "detailInfo.groups.baseball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam2,
                title: "detailInfo.groups.baseball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.baseball_evenOddMatch,
                title: "detailInfo.groups.baseball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning1,
                title: "detailInfo.groups.baseball.x1x2Inning1",
                quotationIds: [
                    [762, 763, 764]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning1,
                title: "detailInfo.groups.baseball.totalInning1",
                quotationIds: [
                    [807, 808]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning1,
                title: "detailInfo.groups.baseball.handicapInning1",
                quotationIds: [
                    [789, 790]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning2,
                title: "detailInfo.groups.baseball.x1x2Inning2",
                quotationIds: [
                    [765, 766, 767]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning2,
                title: "detailInfo.groups.baseball.totalInning2",
                quotationIds: [
                    [809, 810]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning2,
                title: "detailInfo.groups.baseball.handicapInning2",
                quotationIds: [
                    [791, 792]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning3,
                title: "detailInfo.groups.baseball.x1x2Inning3",
                quotationIds: [
                    [768, 769, 770]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning3,
                title: "detailInfo.groups.baseball.totalInning3",
                quotationIds: [
                    [811, 812]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning3,
                title: "detailInfo.groups.baseball.handicapInning3",
                quotationIds: [
                    [793, 794]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning4,
                title: "detailInfo.groups.baseball.x1x2Inning4",
                quotationIds: [
                    [771, 772, 773]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning4,
                title: "detailInfo.groups.baseball.totalInning4",
                quotationIds: [
                    [813, 814]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning4,
                title: "detailInfo.groups.baseball.handicapInning4",
                quotationIds: [
                    [795, 796]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning5,
                title: "detailInfo.groups.baseball.x1x2Inning5",
                quotationIds: [
                    [774, 775, 776]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning5,
                title: "detailInfo.groups.baseball.totalInning5",
                quotationIds: [
                    [815, 816]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning5,
                title: "detailInfo.groups.baseball.handicapInning5",
                quotationIds: [
                    [797, 798]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning6,
                title: "detailInfo.groups.baseball.x1x2Inning6",
                quotationIds: [
                    [777, 778, 779]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning6,
                title: "detailInfo.groups.baseball.totalInning6",
                quotationIds: [
                    [817, 818]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning6,
                title: "detailInfo.groups.baseball.handicapInning6",
                quotationIds: [
                    [799, 800]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning7,
                title: "detailInfo.groups.baseball.x1x2Inning7",
                quotationIds: [
                    [780, 781, 782]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning7,
                title: "detailInfo.groups.baseball.totalInning7",
                quotationIds: [
                    [819, 820]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning7,
                title: "detailInfo.groups.baseball.handicapInning7",
                quotationIds: [
                    [801, 802]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning8,
                title: "detailInfo.groups.baseball.x1x2Inning8",
                quotationIds: [
                    [783, 784, 785]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning8,
                title: "detailInfo.groups.baseball.totalInning8",
                quotationIds: [
                    [821, 822]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning8,
                title: "detailInfo.groups.baseball.handicapInning8",
                quotationIds: [
                    [803, 804]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning9,
                title: "detailInfo.groups.baseball.x1x2Inning9",
                quotationIds: [
                    [786, 787, 788]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning9,
                title: "detailInfo.groups.baseball.totalInning9",
                quotationIds: [
                    [823, 824]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning9,
                title: "detailInfo.groups.baseball.handicapInning9",
                quotationIds: [
                    [805, 806]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Half1,
                title: "detailInfo.groups.baseball.x1x2Half1",
                quotationIds: [
                    [759, 760, 761]
                ]
            },
            {
                id: EDetailRow.baseball_totalHalf1,
                title: "detailInfo.groups.baseball.totalHalf1",
                quotationIds: [
                    [474, 475]
                ]
            },
            {
                id: EDetailRow.baseball_handicapHalf1,
                title: "detailInfo.groups.baseball.handicapHalf1",
                quotationIds: [
                    [472, 473]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.handicaps",
        rows: [
            {
                id: EDetailRow.baseball_handicap,
                title: "detailInfo.groups.baseball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning1,
                title: "detailInfo.groups.baseball.handicapInning1",
                quotationIds: [
                    [789, 790]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning2,
                title: "detailInfo.groups.baseball.handicapInning2",
                quotationIds: [
                    [791, 792]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning3,
                title: "detailInfo.groups.baseball.handicapInning3",
                quotationIds: [
                    [793, 794]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning4,
                title: "detailInfo.groups.baseball.handicapInning4",
                quotationIds: [
                    [795, 796]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning5,
                title: "detailInfo.groups.baseball.handicapInning5",
                quotationIds: [
                    [797, 798]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning6,
                title: "detailInfo.groups.baseball.handicapInning6",
                quotationIds: [
                    [799, 800]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning7,
                title: "detailInfo.groups.baseball.handicapInning7",
                quotationIds: [
                    [801, 802]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning8,
                title: "detailInfo.groups.baseball.handicapInning8",
                quotationIds: [
                    [803, 804]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning9,
                title: "detailInfo.groups.baseball.handicapInning9",
                quotationIds: [
                    [805, 806]
                ]
            },
            {
                id: EDetailRow.baseball_handicapHalf1,
                title: "detailInfo.groups.baseball.handicapHalf1",
                quotationIds: [
                    [472, 473]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.baseball.totals",
        rows: [
            {
                id: EDetailRow.baseball_total,
                title: "detailInfo.groups.baseball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning1,
                title: "detailInfo.groups.baseball.totalInning1",
                quotationIds: [
                    [807, 808]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning2,
                title: "detailInfo.groups.baseball.totalInning2",
                quotationIds: [
                    [809, 810]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning3,
                title: "detailInfo.groups.baseball.totalInning3",
                quotationIds: [
                    [811, 812]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning4,
                title: "detailInfo.groups.baseball.totalInning4",
                quotationIds: [
                    [813, 814]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning5,
                title: "detailInfo.groups.baseball.totalInning5",
                quotationIds: [
                    [815, 816]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning6,
                title: "detailInfo.groups.baseball.totalInning6",
                quotationIds: [
                    [817, 818]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning7,
                title: "detailInfo.groups.baseball.totalInning7",
                quotationIds: [
                    [819, 820]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning8,
                title: "detailInfo.groups.baseball.totalInning8",
                quotationIds: [
                    [821, 822]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning9,
                title: "detailInfo.groups.baseball.totalInning9",
                quotationIds: [
                    [823, 824]
                ]
            },
            {
                id: EDetailRow.baseball_totalHalf1,
                title: "detailInfo.groups.baseball.totalHalf1",
                quotationIds: [
                    [474, 475]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam1,
                title: "detailInfo.groups.baseball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.baseball_individualTotalTeam2,
                title: "detailInfo.groups.baseball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.baseball_evenOddMatch,
                title: "detailInfo.groups.baseball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.baseball.innings",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning1,
                title: "detailInfo.groups.baseball.x1x2Inning1",
                quotationIds: [
                    [762, 763, 764]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning1,
                title: "detailInfo.groups.baseball.totalInning1",
                quotationIds: [
                    [807, 808]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning1,
                title: "detailInfo.groups.baseball.handicapInning1",
                quotationIds: [
                    [789, 790]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning2,
                title: "detailInfo.groups.baseball.x1x2Inning2",
                quotationIds: [
                    [765, 766, 767]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning2,
                title: "detailInfo.groups.baseball.totalInning2",
                quotationIds: [
                    [809, 810]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning2,
                title: "detailInfo.groups.baseball.handicapInning2",
                quotationIds: [
                    [791, 792]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning3,
                title: "detailInfo.groups.baseball.x1x2Inning3",
                quotationIds: [
                    [768, 769, 770]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning3,
                title: "detailInfo.groups.baseball.totalInning3",
                quotationIds: [
                    [811, 812]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning3,
                title: "detailInfo.groups.baseball.handicapInning3",
                quotationIds: [
                    [793, 794]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning4,
                title: "detailInfo.groups.baseball.x1x2Inning4",
                quotationIds: [
                    [771, 772, 773]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning4,
                title: "detailInfo.groups.baseball.totalInning4",
                quotationIds: [
                    [813, 814]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning4,
                title: "detailInfo.groups.baseball.handicapInning4",
                quotationIds: [
                    [795, 796]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning5,
                title: "detailInfo.groups.baseball.x1x2Inning5",
                quotationIds: [
                    [774, 775, 776]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning5,
                title: "detailInfo.groups.baseball.totalInning5",
                quotationIds: [
                    [815, 816]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning5,
                title: "detailInfo.groups.baseball.handicapInning5",
                quotationIds: [
                    [797, 798]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning6,
                title: "detailInfo.groups.baseball.x1x2Inning6",
                quotationIds: [
                    [777, 778, 779]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning6,
                title: "detailInfo.groups.baseball.totalInning6",
                quotationIds: [
                    [817, 818]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning6,
                title: "detailInfo.groups.baseball.handicapInning6",
                quotationIds: [
                    [799, 800]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning7,
                title: "detailInfo.groups.baseball.x1x2Inning7",
                quotationIds: [
                    [780, 781, 782]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning7,
                title: "detailInfo.groups.baseball.totalInning7",
                quotationIds: [
                    [819, 820]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning7,
                title: "detailInfo.groups.baseball.handicapInning7",
                quotationIds: [
                    [801, 802]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning8,
                title: "detailInfo.groups.baseball.x1x2Inning8",
                quotationIds: [
                    [783, 784, 785]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning8,
                title: "detailInfo.groups.baseball.totalInning8",
                quotationIds: [
                    [821, 822]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning8,
                title: "detailInfo.groups.baseball.handicapInning8",
                quotationIds: [
                    [803, 804]
                ]
            },
            {
                id: EDetailRow.baseball_x1x2Inning9,
                title: "detailInfo.groups.baseball.x1x2Inning9",
                quotationIds: [
                    [786, 787, 788]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning9,
                title: "detailInfo.groups.baseball.totalInning9",
                quotationIds: [
                    [823, 824]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning9,
                title: "detailInfo.groups.baseball.handicapInning9",
                quotationIds: [
                    [805, 806]
                ]
            }
        ]
    });
    result.push({
        id: 6,
        title: "detailInfo.tabs.baseball.halves",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Half1,
                title: "detailInfo.groups.baseball.x1x2Half1",
                quotationIds: [
                    [759, 760, 761]
                ]
            },
            {
                id: EDetailRow.baseball_totalHalf1,
                title: "detailInfo.groups.baseball.totalHalf1",
                quotationIds: [
                    [474, 475]
                ]
            },
            {
                id: EDetailRow.baseball_handicapHalf1,
                title: "detailInfo.groups.baseball.handicapHalf1",
                quotationIds: [
                    [472, 473]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning1,
                title: "detailInfo.groups.baseball.x1x2Inning1",
                quotationIds: [
                    [762, 763, 764]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning1,
                title: "detailInfo.groups.baseball.totalInning1",
                quotationIds: [
                    [807, 808]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning1,
                title: "detailInfo.groups.baseball.handicapInning1",
                quotationIds: [
                    [789, 790]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning1,
                title: "detailInfo.groups.baseball.handicapInning1",
                quotationIds: [
                    [789, 790]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning1,
                title: "detailInfo.groups.baseball.totalInning1",
                quotationIds: [
                    [807, 808]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning2,
                title: "detailInfo.groups.baseball.x1x2Inning2",
                quotationIds: [
                    [765, 766, 767]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning2,
                title: "detailInfo.groups.baseball.totalInning2",
                quotationIds: [
                    [809, 810]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning2,
                title: "detailInfo.groups.baseball.handicapInning2",
                quotationIds: [
                    [791, 792]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning2,
                title: "detailInfo.groups.baseball.handicapInning2",
                quotationIds: [
                    [791, 792]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning2,
                title: "detailInfo.groups.baseball.totalInning2",
                quotationIds: [
                    [809, 810]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod3Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning3,
                title: "detailInfo.groups.baseball.x1x2Inning3",
                quotationIds: [
                    [768, 769, 770]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning3,
                title: "detailInfo.groups.baseball.totalInning3",
                quotationIds: [
                    [811, 812]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning3,
                title: "detailInfo.groups.baseball.handicapInning3",
                quotationIds: [
                    [793, 794]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning3,
                title: "detailInfo.groups.baseball.handicapInning3",
                quotationIds: [
                    [793, 794]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning3,
                title: "detailInfo.groups.baseball.totalInning3",
                quotationIds: [
                    [811, 812]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod4Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning4,
                title: "detailInfo.groups.baseball.x1x2Inning4",
                quotationIds: [
                    [771, 772, 773]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning4,
                title: "detailInfo.groups.baseball.totalInning4",
                quotationIds: [
                    [813, 814]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning4,
                title: "detailInfo.groups.baseball.handicapInning4",
                quotationIds: [
                    [795, 796]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning4,
                title: "detailInfo.groups.baseball.handicapInning4",
                quotationIds: [
                    [795, 796]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning4,
                title: "detailInfo.groups.baseball.totalInning4",
                quotationIds: [
                    [813, 814]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod5Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning5,
                title: "detailInfo.groups.baseball.x1x2Inning5",
                quotationIds: [
                    [774, 775, 776]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning5,
                title: "detailInfo.groups.baseball.totalInning5",
                quotationIds: [
                    [815, 816]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning5,
                title: "detailInfo.groups.baseball.handicapInning5",
                quotationIds: [
                    [797, 798]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning5,
                title: "detailInfo.groups.baseball.handicapInning5",
                quotationIds: [
                    [797, 798]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning5,
                title: "detailInfo.groups.baseball.totalInning5",
                quotationIds: [
                    [815, 816]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod6Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning6,
                title: "detailInfo.groups.baseball.x1x2Inning6",
                quotationIds: [
                    [777, 778, 779]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning6,
                title: "detailInfo.groups.baseball.totalInning6",
                quotationIds: [
                    [817, 818]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning6,
                title: "detailInfo.groups.baseball.handicapInning6",
                quotationIds: [
                    [799, 800]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning6,
                title: "detailInfo.groups.baseball.handicapInning6",
                quotationIds: [
                    [799, 800]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning6,
                title: "detailInfo.groups.baseball.totalInning6",
                quotationIds: [
                    [817, 818]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod7Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning7,
                title: "detailInfo.groups.baseball.x1x2Inning7",
                quotationIds: [
                    [780, 781, 782]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning7,
                title: "detailInfo.groups.baseball.totalInning7",
                quotationIds: [
                    [819, 820]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning7,
                title: "detailInfo.groups.baseball.handicapInning7",
                quotationIds: [
                    [801, 802]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning7,
                title: "detailInfo.groups.baseball.handicapInning7",
                quotationIds: [
                    [801, 802]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning7,
                title: "detailInfo.groups.baseball.totalInning7",
                quotationIds: [
                    [819, 820]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod8Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning8,
                title: "detailInfo.groups.baseball.x1x2Inning8",
                quotationIds: [
                    [783, 784, 785]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning8,
                title: "detailInfo.groups.baseball.totalInning8",
                quotationIds: [
                    [821, 822]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning8,
                title: "detailInfo.groups.baseball.handicapInning8",
                quotationIds: [
                    [803, 804]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning8,
                title: "detailInfo.groups.baseball.handicapInning8",
                quotationIds: [
                    [803, 804]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning8,
                title: "detailInfo.groups.baseball.totalInning8",
                quotationIds: [
                    [821, 822]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballPeriod9Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Inning9,
                title: "detailInfo.groups.baseball.x1x2Inning9",
                quotationIds: [
                    [786, 787, 788]
                ]
            },
            {
                id: EDetailRow.baseball_totalInning9,
                title: "detailInfo.groups.baseball.totalInning9",
                quotationIds: [
                    [823, 824]
                ]
            },
            {
                id: EDetailRow.baseball_handicapInning9,
                title: "detailInfo.groups.baseball.handicapInning9",
                quotationIds: [
                    [805, 806]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapInning9,
                title: "detailInfo.groups.baseball.handicapInning9",
                quotationIds: [
                    [805, 806]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalInning9,
                title: "detailInfo.groups.baseball.totalInning9",
                quotationIds: [
                    [823, 824]
                ]
            }
        ]
    });
    return result;
}

export function getBaseballHalf1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.baseball.all",
        rows: [
            {
                id: EDetailRow.baseball_x1x2Half1,
                title: "detailInfo.groups.baseball.x1x2Half1",
                quotationIds: [
                    [759, 760, 761]
                ]
            },
            {
                id: EDetailRow.baseball_totalHalf1,
                title: "detailInfo.groups.baseball.totalHalf1",
                quotationIds: [
                    [474, 475]
                ]
            },
            {
                id: EDetailRow.baseball_handicapHalf1,
                title: "detailInfo.groups.baseball.handicapHalf1",
                quotationIds: [
                    [472, 473]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.baseball.handicap",
        rows: [
            {
                id: EDetailRow.baseball_handicapHalf1,
                title: "detailInfo.groups.baseball.handicapHalf1",
                quotationIds: [
                    [472, 473]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.baseball.total",
        rows: [
            {
                id: EDetailRow.baseball_totalHalf1,
                title: "detailInfo.groups.baseball.totalHalf1",
                quotationIds: [
                    [474, 475]
                ]
            }
        ]
    });
    return result;
}
