import { computed } from "vue";
import { AccountType, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";

export default {
    name: "BalanceComponent",
    props: {
        showCurrency: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    setup () {
        const balanceStore = useBalanceStore();
        const balance = computed(() => balanceStore.balance);
        const bonus = computed(() => balanceStore.bonus);
        const activeAccount = computed(() => balanceStore.accountType);
        const currency = computed(() => { return balanceStore.currency; });
        return {
            balance,
            bonus,
            activeAccount,
            AccountType,
            currency
        };
    }

};
