import { DetailTab } from "@sportaq/model/betting/view/event-details/event-details-info";
import EDetailRow from "@sportaq/common/enums/detail-row";

export function getHandballMainInfo (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.handball.popular",
        rows: [
            {
                id: EDetailRow.handball_x1x2,
                title: "detailInfo.groups.handball.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2,
                title: "detailInfo.groups.handball.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.handball_total,
                title: "detailInfo.groups.handball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.handball_handicap,
                title: "detailInfo.groups.handball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1,
                title: "detailInfo.groups.handball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2,
                title: "detailInfo.groups.handball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.handball.all",
        rows: [
            {
                id: EDetailRow.handball_x1x2,
                title: "detailInfo.groups.handball.x1x2",
                quotationIds: [
                    [171, 172, 173]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2,
                title: "detailInfo.groups.handball.x1x12x2",
                quotationIds: [
                    [178, 179, 180]
                ]
            },
            {
                id: EDetailRow.handball_handicap,
                title: "detailInfo.groups.handball.handicap",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.handball_total,
                title: "detailInfo.groups.handball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1,
                title: "detailInfo.groups.handball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2,
                title: "detailInfo.groups.handball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.handball_x1x2fHalf1,
                title: "detailInfo.groups.handball.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half1,
                title: "detailInfo.groups.handball.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf1,
                title: "detailInfo.groups.handball.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf1,
                title: "detailInfo.groups.handball.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half1,
                title: "detailInfo.groups.handball.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half1,
                title: "detailInfo.groups.handball.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.handball_x1x2fHalf2,
                title: "detailInfo.groups.handball.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half2,
                title: "detailInfo.groups.handball.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf2,
                title: "detailInfo.groups.handball.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf2,
                title: "detailInfo.groups.handball.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half2,
                title: "detailInfo.groups.handball.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half2,
                title: "detailInfo.groups.handball.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            },
            {
                id: EDetailRow.handball_passage,
                title: "detailInfo.groups.handball.passage",
                quotationIds: [
                    [23, 24]
                ]
            },
            {
                id: EDetailRow.handball_evenOddMatch,
                title: "detailInfo.groups.handball.evenOddMatch",
                quotationIds: [
                    [335, 336]
                ]
            },
            {
                id: EDetailRow.handball_evenOddHalf1,
                title: "detailInfo.groups.handball.evenOddHalf1",
                quotationIds: [
                    [1041, 1042]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.handball.half1",
        rows: [
            {
                id: EDetailRow.handball_x1x2fHalf1,
                title: "detailInfo.groups.handball.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half1,
                title: "detailInfo.groups.handball.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf1,
                title: "detailInfo.groups.handball.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf1,
                title: "detailInfo.groups.handball.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half1,
                title: "detailInfo.groups.handball.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half1,
                title: "detailInfo.groups.handball.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.handball_evenOddHalf1,
                title: "detailInfo.groups.handball.evenOddHalf1",
                quotationIds: [
                    [1041, 1042]
                ]
            }
        ]
    });
    result.push({
        id: 4,
        title: "detailInfo.tabs.handball.half2",
        rows: [
            {
                id: EDetailRow.handball_x1x2fHalf2,
                title: "detailInfo.groups.handball.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half2,
                title: "detailInfo.groups.handball.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf2,
                title: "detailInfo.groups.handball.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf2,
                title: "detailInfo.groups.handball.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half2,
                title: "detailInfo.groups.handball.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half2,
                title: "detailInfo.groups.handball.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 5,
        title: "detailInfo.tabs.handball.totals",
        rows: [
            {
                id: EDetailRow.handball_total,
                title: "detailInfo.groups.handball.total",
                quotationIds: [
                    [169, 170]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1,
                title: "detailInfo.groups.handball.individualTotalTeam1",
                quotationIds: [
                    [174, 175]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2,
                title: "detailInfo.groups.handball.individualTotalTeam2",
                quotationIds: [
                    [176, 177]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf1,
                title: "detailInfo.groups.handball.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf2,
                title: "detailInfo.groups.handball.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half1,
                title: "detailInfo.groups.handball.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half1,
                title: "detailInfo.groups.handball.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half2,
                title: "detailInfo.groups.handball.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half2,
                title: "detailInfo.groups.handball.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 6,
        title: "detailInfo.tabs.handball.handicaps",
        rows: [
            {
                id: EDetailRow.handball_handicapMatch,
                title: "detailInfo.groups.handball.handicapMatch",
                quotationIds: [
                    [167, 168]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf1,
                title: "detailInfo.groups.handball.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf2,
                title: "detailInfo.groups.handball.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            }
        ]
    });
    return result;
}

export function getHandballPeriod1Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.handball.all",
        rows: [
            {
                id: EDetailRow.handball_x1x2fHalf1,
                title: "detailInfo.groups.handball.x1x2fHalf1",
                quotationIds: [
                    [280, 281, 282]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half1,
                title: "detailInfo.groups.handball.x1x12x2Half1",
                quotationIds: [
                    [703, 704, 705]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf1,
                title: "detailInfo.groups.handball.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf1,
                title: "detailInfo.groups.handball.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half1,
                title: "detailInfo.groups.handball.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half1,
                title: "detailInfo.groups.handball.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.handball.handicap",
        rows: [
            {
                id: EDetailRow.handball_handicapHalf1,
                title: "detailInfo.groups.handball.handicapHalf1",
                quotationIds: [
                    [276, 277]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.handball.total",
        rows: [
            {
                id: EDetailRow.handball_totalHalf1,
                title: "detailInfo.groups.handball.totalHalf1",
                quotationIds: [
                    [70, 71]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half1,
                title: "detailInfo.groups.handball.individualTotalTeam1Half1",
                quotationIds: [
                    [415, 416]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half1,
                title: "detailInfo.groups.handball.individualTotalTeam2Half1",
                quotationIds: [
                    [419, 420]
                ]
            }
        ]
    });
    return result;
}

export function getHandballPeriod2Info (): DetailTab[] {
    const result: DetailTab[] = [];
    result.push({
        id: 1,
        title: "detailInfo.tabs.handball.all",
        rows: [
            {
                id: EDetailRow.handball_x1x2fHalf2,
                title: "detailInfo.groups.handball.x1x2fHalf2",
                quotationIds: [
                    [287, 288, 289]
                ]
            },
            {
                id: EDetailRow.handball_x1x12x2Half2,
                title: "detailInfo.groups.handball.x1x12x2Half2",
                quotationIds: [
                    [706, 707, 708]
                ]
            },
            {
                id: EDetailRow.handball_handicapHalf2,
                title: "detailInfo.groups.handball.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            },
            {
                id: EDetailRow.handball_totalHalf2,
                title: "detailInfo.groups.handball.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half2,
                title: "detailInfo.groups.handball.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half2,
                title: "detailInfo.groups.handball.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    result.push({
        id: 2,
        title: "detailInfo.tabs.handball.handicap",
        rows: [
            {
                id: EDetailRow.handball_handicapHalf2,
                title: "detailInfo.groups.handball.handicapHalf2",
                quotationIds: [
                    [283, 284]
                ]
            }
        ]
    });
    result.push({
        id: 3,
        title: "detailInfo.tabs.handball.total",
        rows: [
            {
                id: EDetailRow.handball_totalHalf2,
                title: "detailInfo.groups.handball.totalHalf2",
                quotationIds: [
                    [72, 73]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam1Half2,
                title: "detailInfo.groups.handball.individualTotalTeam1Half2",
                quotationIds: [
                    [417, 418]
                ]
            },
            {
                id: EDetailRow.handball_individualTotalTeam2Half2,
                title: "detailInfo.groups.handball.individualTotalTeam2Half2",
                quotationIds: [
                    [421, 422]
                ]
            }
        ]
    });
    return result;
}
