import { useHorseGameViewCommon } from "@sportaq/vue/views/web/horse-game-view/horse-game-view-common";
import { useWebVirtualGamesLifecycle } from "@sportaq/vue/components/web/web-virtual-games-lifecycle";
import { AccountCategory, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { computed, onMounted, onUnmounted } from "vue";
import {
    disableWebDisconnectTimer,
    enableWebDisconnectTimer
} from "@sportaq/vue/components/web/disconnect-worker/idle-disconnect";

export function useWebAviatorCommon () {
    const {
        isLoggedIn,
        isResetPasswordDialogVisible,
        onLoginClose
    } = useHorseGameViewCommon("viewcontentaviator");
    useWebVirtualGamesLifecycle();
    const store = useBalanceStore();
    const activeAccount = computed(() => {
        return store.activeAccount;
    });

    onMounted(() => {
        disableWebDisconnectTimer();
    });

    onUnmounted(() => {
        enableWebDisconnectTimer();
    });

    return {
        isLoggedIn,
        isResetPasswordDialogVisible,
        activeAccount,
        AccountCategory,
        onLoginClose
    };
}
