import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainView from "@/views/main-view/MainView.vue";
import RegistrationView from "@/views/registration-view/RegistrationView.vue";
import { ROUTES } from "@/router/routes-consts";
import EmailConfirmView from "@/views/email-confirm/EmailConfirmView.vue";
import ProfileView from "@/views/profile-view/ProfileView.vue";
import NavigationView from "@/views/navigation-view/NavigationView.vue";
import FinanceNavigationView from "@/views/navigation-view/FinanceNavigationView.vue";
import DepositView from "@/views/deposit-view/DepositView.vue";
import HomeView from "@/views/home-view/HomeView.vue";
import WithdrawalView from "@/views/withdrawal-view/WithdrawalView.vue";
import PromoView from "@/views/promo-view/PromoView.vue";
import RulesView from "@/views/rules-view/RulesView.vue";
import MobileBettingViewLayout from "@/views/betting/mobile-betting-view-layout/MobileBettingViewLayout.vue";
import MobileBettingView from "@/views/betting/mobile-betting-view-layout/mobile-betting-view/MobileBettingView.vue";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import MobileEventDetailsPage from "@/components/betting/event-details/MobileEventDetailsPage.vue";
import StakesView from "@/views/stakes-view/StakesView.vue";
import FinancesView from "@/views/finances-view/FinancesView.vue";
import HistoryView from "@/views/history-view/HistoryView.vue";
import AllStakes from "@/views/stakes-view/all-stakes/AllStakes.vue";
import StakesInPlay from "@/views/stakes-view/in-play/StakesInPlay.vue";
import RaceStakes from "@/views/stakes-view/race-stakes/RaceStakes.vue";
import WithdrawalOrders from "@/views/stakes-view/withdrawal-orders/WithdrawalOrders.vue";
import SessionList from "@/views/history-view/session/SessionList.vue";
import OperationList from "@/views/history-view/operation/OperationList.vue";
import CasinoStakeList from "@/views/stakes-view/casino/CasinoStakeList.vue";
import TermsAndConditions from "@sportaq/vue/components/web/text-view/TermsAndConditions.vue";
import AmlPolicy from "@sportaq/vue/components/web/text-view/AmlPolicy.vue";
import PrivacyPolicy from "@sportaq/vue/components/web/text-view/PrivacyPolicy.vue";
import ResponsibleGaming from "@sportaq/vue/components/web/text-view/ResponsibleGaming.vue";
import HorseGameView from "@/views/games/horse/HorseGameView.vue";
import CasinoView from "@/views/games/casino/CasinoView.vue";
import VirtualGamesView from "@/views/games/virtual/VirtualGamesView.vue";
import WebResultsView from "@sportaq/vue/views/web/results/WebResultsView.vue";
import LandingDeportes from "@/views/landings/landing-deportes/LandingDeportes.vue";
import LandingCasino from "@/views/landings/landing-casino/LandingCasino.vue";
import LandingSolo from "@/views/landings/landing-solo/LandingSolo.vue";
import AviatorGameView from "@/views/games/aviator/AviatorGameView.vue";
import NotFound from "@sportaq/vue/views/not-found/NotFound.vue";
import { localeNavigationGuard } from "@sportaq/vue/views/web/locale-navigation-guard";
import { bonusOperations, depositOperations } from "@sportaq/vue/views/web/history/operation-list-common";
import { getFirebaseAnalytics } from "@/firebase-config";
import { logEvent } from "@firebase/analytics";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/landing/deportes",
        component: LandingDeportes
    },
    {
        path: "/landing/casino",
        component: LandingCasino
    },
    {
        path: "/landing/solo",
        component: LandingSolo
    },
    {
        path: "/index.html",
        redirect: { name: ROUTES.HOME.name }
    },
    {
        path: "/:locale?/:pathMatch(.*)*",
        name: "not-found",
        component: NotFound,
        props: {
            code: 404
        }
    },
    {
        path: "/:locale?/" + ROUTES.NETWORK_ERROR.path + "/:code?",
        name: ROUTES.NETWORK_ERROR.name,
        component: NotFound,
        props: true
    },
    {
        path: "/:locale?",
        component: MainView,
        children: [
            {
                path: ROUTES.HOME.path,
                name: ROUTES.HOME.name,
                component: HomeView
            },
            {
                path: "/:locale?" + COMMON_ROUTES.HORSE.path,
                name: COMMON_ROUTES.HORSE.name,
                component: HorseGameView
            },
            {
                path: "/:locale?" + COMMON_ROUTES.AVIATOR.path,
                name: COMMON_ROUTES.AVIATOR.name,
                component: AviatorGameView
            },
            {
                path: `/:locale?/:eventType(${COMMON_ROUTES.SPORT.name}|${COMMON_ROUTES.LIVE.name})/:sportType?`,
                component: MobileBettingViewLayout,
                children: [
                    {
                        path: "",
                        component: MobileBettingView
                    },
                    {
                        path: ":positionId(\\d+)",
                        component: MobileEventDetailsPage
                    }
                ],
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.FAVOURITES.path,
                component: MobileBettingViewLayout,
                children: [
                    {
                        path: "",
                        name: COMMON_ROUTES.FAVOURITES.name,
                        component: MobileBettingView
                    }
                ]
            },
            {
                path: "/:locale?" + COMMON_ROUTES.RESULTS.path,
                component: WebResultsView,
                name: COMMON_ROUTES.RESULTS.name
            },
            {
                path: "/:locale?" + ROUTES.REGISTRATION.path + "/:selectedBonusType?",
                component: RegistrationView,
                name: ROUTES.REGISTRATION.name,
                props: true,
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + ROUTES.EMAILCONFIRM.path,
                component: EmailConfirmView,
                name: ROUTES.EMAILCONFIRM.name
            },
            {
                path: "/:locale?" + ROUTES.PROFILE.path,
                component: ProfileView,
                name: ROUTES.PROFILE.name,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.DEPOSIT.path,
                component: DepositView,
                name: ROUTES.DEPOSIT.name
            },
            {
                path: "/:locale?" + ROUTES.WITHDRAWAL.path,
                component: WithdrawalView,
                name: ROUTES.WITHDRAWAL.name
            },
            {
                path: "/:locale?" + ROUTES.BONUSES.path,
                component: PromoView,
                name: ROUTES.BONUSES.name
            },
            {
                path: "/:locale?" + COMMON_ROUTES.CASINO.path,
                name: COMMON_ROUTES.CASINO.name,
                component: CasinoView,
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.CASINO.path + "/:gameId",
                name: COMMON_ROUTES.CASINO.name + "_game",
                component: CasinoView,
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.VIRTUAL.path,
                name: COMMON_ROUTES.VIRTUAL.name,
                component: VirtualGamesView,
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.VIRTUAL.path + "/:gameId",
                name: COMMON_ROUTES.VIRTUAL.name + "_game",
                component: VirtualGamesView,
                meta: {
                    hasTitle: true
                }
            },
            {
                path: "/:locale?" + ROUTES.WHATSAPP.path,
                name: ROUTES.WHATSAPP.name,
                redirect: { path: COMMON_ROUTES.SPORT.path }
            },
            {
                path: "/:locale?" + COMMON_ROUTES.TERMS.path,
                component: TermsAndConditions,
                name: COMMON_ROUTES.TERMS.name
            },
            {
                path: "/:locale?" + COMMON_ROUTES.AML.path,
                component: AmlPolicy,
                name: COMMON_ROUTES.AML.name
            },
            {
                path: "/:locale?" + COMMON_ROUTES.PRIVACY_POLICY.path,
                component: PrivacyPolicy,
                name: COMMON_ROUTES.PRIVACY_POLICY.name
            },
            {
                path: "/:locale?" + COMMON_ROUTES.RESPONSIBLE_GAMING.path,
                component: ResponsibleGaming,
                name: COMMON_ROUTES.RESPONSIBLE_GAMING.name
            },
            {
                path: "/:locale?" + ROUTES.RULES.path,
                component: RulesView,
                name: ROUTES.RULES.name
            },
            {
                path: "/:locale?" + ROUTES.RULES_GENERAL.path,
                component: RulesView,
                name: ROUTES.RULES_GENERAL.name
            },
            {
                path: "/:locale?" + ROUTES.RULES_SPECIAL.path,
                component: RulesView,
                name: ROUTES.RULES_SPECIAL.name
            },
            {
                path: "/:locale?" + ROUTES.RULES_RESULTS.path,
                component: RulesView,
                name: ROUTES.RULES_RESULTS.name
            },
            {
                path: "/:locale?" + ROUTES.RULES_FINANCE.path,
                component: RulesView,
                name: ROUTES.RULES_FINANCE.name
            },
            {
                path: "/:locale?" + ROUTES.RULES_STAKE_TYPES.path,
                component: RulesView,
                name: ROUTES.RULES_STAKE_TYPES.name
            },
            {
                path: "/:locale?" + ROUTES.DEPOSIT_NAV.path,
                name: ROUTES.DEPOSIT_NAV.name,
                component: NavigationView,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.FINANCES.path,
                name: ROUTES.FINANCES.name,
                component: FinanceNavigationView,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.WITHDRAWAL_ORDERS.path,
                component: FinancesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.WITHDRAWAL_ORDERS.name,
                        component: WithdrawalOrders
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.DEPOSIT_OPERATIONS.path,
                component: FinancesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.DEPOSIT_OPERATIONS.name,
                        component: OperationList,
                        props: { operations: depositOperations }
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.BONUS_OPERATIONS.path,
                component: FinancesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.BONUS_OPERATIONS.name,
                        component: OperationList,
                        props: { operations: bonusOperations }
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.IN_PLAY.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.IN_PLAY.name,
                        component: StakesInPlay
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.WIN_STAKES.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.WIN_STAKES.name,
                        component: AllStakes,
                        props: { onlyCalculated: true }
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.CASINO_STAKES.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.CASINO_STAKES.name,
                        component: CasinoStakeList,
                        props: { onlyCalculated: true }
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.ALL_STAKES.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.ALL_STAKES.name,
                        component: AllStakes
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.RACE_STAKES.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.RACE_STAKES.name,
                        component: RaceStakes
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.OPERATIONS.path,
                component: StakesView,
                children: [
                    {
                        path: "",
                        name: ROUTES.HISTORY_OPERATIONS.name,
                        component: OperationList
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.HISTORY_SESSIONS.path,
                component: HistoryView,
                children: [
                    {
                        path: "",
                        name: ROUTES.HISTORY_SESSIONS.name,
                        component: SessionList
                    }
                ],
                meta: {
                    requireAuth: true
                }
            },
            {
                path: "/:locale?" + ROUTES.HISTORY_BALANCE.path,
                component: HistoryView,
                meta: {
                    requireAuth: true
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

function isPWA () {
    return window && window.matchMedia("(display-mode: standalone)").matches;
}

router.beforeEach(localeNavigationGuard);
router.afterEach((to) => {
    const analytics = getFirebaseAnalytics();
    if (analytics) {
        console.log("log page_view", to.path);
        logEvent(analytics, "page_view", {
            page_location: to.path,
            app: isPWA() ? "pwa" : "web"
        });
    }
    return true;
});

export default router;
