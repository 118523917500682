export enum ECasinoGameClassifier {
    NEW = 1 << 0,
    POPULAR = 1 << 1,
    SLOTS = 1 << 2,
    CARD = 1 << 3,
    CASUAL = 1 << 4,
    LOTTERY = 1 << 5,
    ROULETTE = 1 << 6,
    POKER = 1 << 7,
    JACKPOT = 1 << 8,
    LIVE = 1 << 9,
    BLACKJACK = 1 << 10,
    CRAPS = 1 << 11,
    CRASH = 1 << 12,
    FISHING = 1 << 13,
    VIRTUAL_SPORTS = 1 << 14,
    BUY_FEATURE = 1 << 15,
    MEGAWAYS = 1 << 16,
    SCRATCH = 1 << 17,
    FOOTBALL = 1 << 18,
    BASKETBALL = 1 << 19,
    HOCKEY = 1 << 20,
    TENNIS = 1 << 21,
    HORSE_RACING = 1 << 22,
    DOG_RACING = 1 << 23,
    MOTOS = 1 << 24,
    FIGHTING = 1 << 25,
    KENO = 1 << 26,
    BINGO = 1 << 27,
    VIRTUAL_GAME = 1 << 30
}
