import { GameProvider } from "@sportaq/model/consts/game-providers";
import {
    GameProviderListMsg, GameProviderMsg
} from "@sportaq/services/streaming/streaming-network/messages/responses/casino/GameProviderListMessage_pb";

export class CasinoGameProvidersResponseDeserializer {
    static deserializeBinary (brandId: number, message: Uint8Array): GameProvider[] {
        const msg = GameProviderListMsg.deserializeBinary(message);
        const result: GameProvider[] = [];
        for (const item of msg.getProviderList()) {
            result.push({
                id: item.getId(),
                name: item.getName(),
                title: item.getTitle(),
                image: item.getImage(),
                order: this.getOrder(brandId, item),
                excludedCountries: item.getRestrictedcountryList(),
                excludedCurrencies: item.getRestrictedcurrencyList()
            });
        }
        return result;
    }

    private static getOrder (brandId: number, provider: GameProviderMsg): number {
        const orderList = provider.getOrderList();
        if (orderList) {
            const exactBrand = orderList.find(value => value.getBrandid() === brandId);
            if (exactBrand) {
                return exactBrand.getOrder();
            }
        }
        return 0;
    }
}
