import EventType from "@sportaq/common/enums/event-type";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { PointSettings } from "@sportaq/model/common/point-settings";
import { BigDecimalResponse } from "@sportaq/model/types/responses";

function calculateQuotationMaxSumWin (eventType: EventType, coef: BigDecimal, quotationMaxWin: number, pointSettings: PointSettings) {
    let result = quotationMaxWin * pointSettings.currency.value;
    const multiplier = eventType === EventType.LIVE ? pointSettings.liveSumStakeMax : pointSettings.preMatchSumStakeMax;
    if (multiplier && multiplier > 0) {
        const up = multiplier * (coef.numberValue - 1);
        if (up < result) {
            result = up;
        }
    }
    return result;
}

export function calculateMaxStake (eventType: EventType, coef: BigDecimal, quotationMaxWin: number, pointSettings: PointSettings) {
    const maxSumWin = calculateQuotationMaxSumWin(eventType, coef, quotationMaxWin, pointSettings);
    return Math.floor(maxSumWin / (coef.numberValue - 1));
}

export function transformCoef (coef: BigDecimalResponse | undefined, coefsMultiplier: number): BigDecimal | undefined {
    if (coefsMultiplier !== 1) {
        if (coef) {
            const transformCoefOne = Math.pow(10, coef?.scale);
            return new BigDecimal(transformCoefOne + Math.trunc(coefsMultiplier * (coef.value - transformCoefOne)), coef.scale);
        }
    }
    return createBigDecimalByBigDecimalResponse(coef);
}

export function createBigDecimalByBigDecimalResponse (value?: BigDecimalResponse): BigDecimal | undefined {
    return value ? new BigDecimal(value.value, value.scale) : undefined;
}
