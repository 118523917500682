import { Comparable } from "@sportaq/common/types/interfaces";
import { StringIdItem } from "@sportaq/common/types/types";
import { CasinoGame, CasinoGameCompareKey, compareGames } from "@sportaq/model/games/casino-game";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";

export class GameSortInfo implements Comparable<GameSortInfo>, StringIdItem, CasinoGameCompareKey {
    readonly id: string;
    readonly provider: number;
    readonly gameType: ECasinoGameType;
    readonly title: string;
    readonly releaseDate: number;
    readonly classifiers: number;
    readonly order: number;

    constructor (game: CasinoGame) {
        this.id = game.id;
        this.provider = game.provider;
        this.gameType = game.gameType;
        this.title = game.title;
        this.releaseDate = game.releaseDate;
        this.classifiers = game.classifiers;
        this.order = game.order;
    }

    compare (o: GameSortInfo): number {
        return compareGames(this, o);
    }
}
