import { use } from "@sportaq/common/utils/dependency-injection";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { CasinoGameDiffsResponse } from "@sportaq/model/games/casino-game-diffs-response";
import { provide } from "vue";
import { EventListener } from "@sportaq/common/types/event-listener";
import { CasinoGamesStorage } from "@sportaq/vuex/modules/games/non-reactive-storage/casino-games-storage";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";

type UpdateListener = (casinoGamesSupplier: CasinoGamesSupplier, list: CasinoGameDiffsResponse[], nonConfirmedPositionIds: string[]) => Promise<void>;
type ClearListener = () => void;

export interface CasinoGamesSupplier {
    getGame (id: string): CasinoGame;

    getOptionalGame (id: string): CasinoGame | undefined;

    getGameWithType (gameType: ECasinoGameType, id: string): CasinoGame;

    getItems (gameType: ECasinoGameType): IterableIterator<CasinoGame>;

    getProviderCounter (gameType: ECasinoGameType, provider: number): number;

    update (diffList: CasinoGameDiffsResponse[]): Promise<void>;

    disconnect (): void;

    addUpdateListener (listener: UpdateListener): void;

    removeUpdateListener (listener: UpdateListener): void;

    isColdDataReceived (): boolean;
}

class CasinoGamesSupplierImpl implements CasinoGamesSupplier {
    private readonly storage = new CasinoGamesStorage();

    private readonly updateListener: EventListener<UpdateListener> = new EventListener<UpdateListener>();
    private readonly clearListener: EventListener<ClearListener> = new EventListener<ClearListener>();

    getGame (id: string): CasinoGame {
        const result = this.getOptionalGame(id);
        if (result) {
            return result;
        } else {
            throw new Error(`Game ${id} not found in game storage`);
        }
    }

    getOptionalGame (id: string): CasinoGame | undefined {
        return this.storage.get(id);
    }

    getGameWithType (gameType: ECasinoGameType, id: string): CasinoGame {
        const result = this.storage.getWithType(gameType, id);
        if (result) {
            return result;
        } else {
            throw new Error(`Game ${id} not found in game storage`);
        }
    }

    getProviderCounter (gameType: ECasinoGameType, provider: number): number {
        return this.storage.getProviderCounter(gameType, provider);
    }

    async update (diffList: CasinoGameDiffsResponse[]): Promise<void> {
        const result = this.storage.updateStorage(diffList);
        for (const handler of this.updateListener.handlers) {
            await handler(this, diffList, result.nonConfirmedIds);
        }
    }

    addUpdateListener (listener: UpdateListener): void {
        this.updateListener.addListener(listener);
    }

    removeUpdateListener (listener: UpdateListener): void {
        this.updateListener.removeListener(listener);
    }

    addClearListener (listener: ClearListener): void {
        this.clearListener.addListener(listener);
    }

    removeClearListener (listener: ClearListener): void {
        this.clearListener.removeListener(listener);
    }

    disconnect (): void {
        this.storage.disconnect();
    }

    isColdDataReceived (): boolean {
        return this.storage.coldDataReceived;
    }

    getItems (gameType: ECasinoGameType): IterableIterator<CasinoGame> {
        return this.storage.getItems(gameType);
    }
}

const casinoGamesSupplierSymbol = Symbol("Casino Games Supplier");

export function provideCasinoGamesSupplier (): CasinoGamesSupplier {
    const casinoGamesSupplier = new CasinoGamesSupplierImpl();
    provide(casinoGamesSupplierSymbol, casinoGamesSupplier);
    return casinoGamesSupplier;
}

export function useCasinoGamesSupplier (): CasinoGamesSupplier {
    return use(casinoGamesSupplierSymbol);
}
