<template>
    <div class="coupon-block-item" :class="{live: isLive}" :data-cy="stake.templateKey">
        <div class="cancelled" v-if="isBlocked">
            <div class="cancelled-icon">
                <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="17.5" cy="17.5" r="16.5" fill="white" stroke="#D32D23"
                            stroke-width="2"/>
                    <rect x="28.9179" y="5.16791" width="2" height="33.9403"
                          transform="rotate(45 28.9179 5.16791)" fill="#D32D23"/>
                </svg>
            </div>
        </div>
        <div class="info" data-cy="info">
            <div class="info-subtitle">
                <div class="live" v-if="isLive" data-cy="live">LIVE</div>
                <div class="date" data-cy="date" v-else>{{ stake.event.startTimeStr }}</div>
                <div class="name" data-cy="partitionName">{{ stake.event.partitionName }}</div>
            </div>
            <div class="info-title">
                <a href="#" class="name"
                   v-for="(participant, index) of participants" :key="participant.id"
                   @click.stop.prevent="mobileShowDetails" :data-cy="`participant-${index+1}`">
                    {{ participant.name }}
                </a>
            </div>
            <div class="info-line">
                <div class="info-line-name" data-cy="quotationTitle"><span>{{ quotationTitle }}</span></div>
                <div class="info-line-rate" :class="diffsClass" v-html="quotationLabel" data-cy="quotation-label"></div>
            </div>
            <div class="info-line" v-if="maxWin">
                <div class="info-line-name" data-cy="max-win-amount">
                    <span>
                        {{ $t("betting.betSlip.maxWin") }} {{ maxWin }}
                    </span>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="actions-remove" @click.prevent.stop="removeStake" data-cy="remove-button"/>
            <div class="actions-name" data-cy="currency">{{ currencyName }}</div>
            <div class="form-line small">
                <input type="number" title="" v-model.number="amount" @keydown="onAmountKeyDown" @blur="saveAmount"
                       data-cy="amount-input"/>
            </div>
            <div class="actions-score" data-cy="max-sum-stake">max: {{ stake.maxSumStake.toFixed() }}</div>
            <div class="actions-error-icon" v-if="stakeError" @click.stop="showErrorMessage">
                <img :src="require(`@assets/img/${ stakeError.icon }.png`)" alt="" data-cy="error-icon"/>
            </div>
        </div>
        <div class="coupon-error" v-if="stakeError && errorShowed" data-cy="error-message-box">
            <img src="@assets/img/icon-error.png" alt="" class="coupon-error-icon"/>
            <div class="coupon-error-info">
                <div class="message"> {{ stakeError.message }}</div>
                <a href="" class="button" @click.prevent.stop="accept(stakeError.cause)"
                   data-cy="accept-button">{{ $t("betting.betSlip.buttons.accept") }}</a>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-bet-slip-stake-row-component.ts"/>
