import { DictionaryItem } from "@sportaq/common/types/types";
import { QuotationStore } from "@sportaq/model/betting/events/quotation-store";
import { Quotation, QuotationKey } from "@sportaq/model/betting/events/quotation";
import { i18nCache } from "@sportaq/i18n/index";
import { PeriodStore } from "@sportaq/model/betting/events/period-store";
import EPeriodType from "@sportaq/common/enums/period-types";
import { Cloneable, Comparable } from "@sportaq/common/types/interfaces";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import EventType from "@sportaq/common/enums/event-type";
import { BigDecimalResponse, EventDiffsResponse } from "@sportaq/model/types/responses";
import { eventDetailsInfoHolder } from "@sportaq/model/betting/view/event-details/event-details-info";
import { createBigDecimalByBigDecimalResponse, transformCoef } from "@sportaq/model/common/betting-calculations";
import { DateTimeFormatter } from "@sportaq/common/utils/miscellaneous-params";
import { ESportType } from "@sportaq/common/enums/sport-type";

export enum DiffType {
    NEW = 1, UPDATE = 2, DELETE = 3, COLD_HOT_DELIMITER = 4
}

export class Partition implements DictionaryItem, Cloneable<Partition> {
    constructor (readonly id: number, readonly name: string, readonly sportTypeId: ESportType, readonly countryId: number) {
    }

    clone (): Partition {
        return new Partition(this.id, this.name, this.sportTypeId, this.countryId);
    }
}

export function calculateStartTimeStr (dateTimeFormatter: DateTimeFormatter, eventTime: Date, todayYear: number, todayMonth: number, todayDate: number): string {
    let dateStr;
    if (eventTime.getDate() === todayDate &&
        eventTime.getMonth() === todayMonth &&
        eventTime.getFullYear() === todayYear) {
        dateStr = i18nCache.today;
    } else {
        dateStr = dateTimeFormatter.formatDate(eventTime, false);
    }
    return dateStr + " " + dateTimeFormatter.formatTime(eventTime);
}

export class Participant implements DictionaryItem, Cloneable<Participant> {
    constructor (readonly id: number, readonly name: string) {
    }

    clone (): Participant {
        return new Participant(this.id, this.name);
    }
}

export interface ParticipantsSupplier {
    participants: Participant[];
}

export class BettingEvent implements ParticipantsSupplier, Comparable<BettingEvent> {
    constructor (
        readonly eventType: EventType,
        public positionId: number,
        public eventId: number,
        public version: number,
        public partition: Partition,
        public participants: Participant[],
        public startTime: Date,
        public startTimeStr: string,
        public resultText: string,
        public currentPeriod: number,
        public blocked: boolean,
        public statusCode: number,
        public statusMark: string,
        readonly quotations: QuotationStore,
        readonly periods: PeriodStore,
        private readonly quotationCountByPeriod: Map<EPeriodType, number>
    ) {
    }

    public get sportTypeId () {
        return this.partition.sportTypeId;
    }

    public getCounterByPeriod (period: EPeriodType): number {
        return this.quotationCountByPeriod.get(period) ?? 0;
    }

    public get liveBetting (): boolean {
        return this.eventType === EventType.LIVE;
    }

    public get isGroupEvent (): boolean {
        return this.participants.length > 2;
    }

    static newBettingEvent (type: EventType, diff: EventDiffsResponse, dateTimeFormatter: DateTimeFormatter, todayYear: number, todayMonth: number, todayDate: number, coefsMultiplier: number): BettingEvent {
        BettingEvent.checkRequiredDictionaryItem(diff, diff.partition, "partition");
        // BettingEvent.checkRequiredParticipants(diff);

        if (!diff.startTime) {
            throw new Error(`The ${type === EventType.LIVE ? "live" : "prematch"} event diff doesn't have startTime. This event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`);
        }

        const partition = new Partition(diff.partition!.id, diff.partition!.name, diff.sportTypeId, diff.countryId);

        const quotationsByPeriods = eventDetailsInfoHolder.getQuotationsByPeriods(partition.sportTypeId);
        const quotations = new QuotationStore();
        const quotationsByPeriodsCounters = new Map<EPeriodType, number>();
        for (const quotationDiff of diff.quotationDiffList) {
            const maxWin: number = quotationDiff.maxWin!;
            const quotationKey = new QuotationKey(
                quotationDiff.quotationId!,
                this.getBigDecimal(quotationDiff.p1),
                this.getBigDecimal(quotationDiff.p2),
                this.getBigDecimal(quotationDiff.po),
                quotationDiff.p1Id,
                quotationDiff.p2Id
            );
            const quotation = new Quotation(
                quotationDiff.id,
                diff.positionId,
                quotationKey,
                transformCoef(quotationDiff.coef, coefsMultiplier)!,
                maxWin
            );
            quotations.setQuotation(quotation);
            this.updateQuotationCountByPeriod(quotationsByPeriods, quotation, quotationsByPeriodsCounters, false);
        }
        const periodStore = new PeriodStore();
        this.updatePeriods(diff, periodStore);

        const startTime = new Date(diff.startTime * 1000);
        return new BettingEvent(
            type,
            diff.positionId,
            diff.eventId,
            diff.version,
            partition,
            [...diff.participantList.map((value: DictionaryItem) => new Participant(value.id, value.name))],
            startTime,
            calculateStartTimeStr(dateTimeFormatter, startTime, todayYear, todayMonth, todayDate),
            diff.resultText ? diff.resultText : "",
            diff.currentPeriod ? diff.currentPeriod : -1,
            diff.blocked ? diff.blocked : false,
            diff.statusCode ? diff.statusCode : 0,
            diff.statusMark ? diff.statusMark : "",
            quotations,
            periodStore,
            quotationsByPeriodsCounters
        );
    }

    updatedBettingEvent (diff: EventDiffsResponse, dateTimeFormatter: DateTimeFormatter, todayYear: number, todayMonth: number, todayDate: number, coefsMultiplier: number) {
        this.version = diff.version;

        if (diff.partition) {
            BettingEvent.checkRequiredDictionaryItem(diff, diff.partition as DictionaryItem | undefined, "partition");
            this.partition = new Partition(diff.partition.id, diff.partition.name, diff.sportTypeId, diff.countryId);
        }
        if (diff.participantList && diff.participantList.length > 0) {
            // BettingEvent.checkRequiredParticipants(diff);
            this.participants = [...diff.participantList.map((value: DictionaryItem) => new Participant(value.id, value.name))];
        }
        if (diff.startTime) {
            this.startTime = new Date(diff.startTime * 1000);
            this.startTimeStr = calculateStartTimeStr(dateTimeFormatter, this.startTime, todayYear, todayMonth, todayDate);
        }
        if (diff.resultText) {
            this.resultText = diff.resultText;
        }
        if (diff.currentPeriod) {
            this.currentPeriod = diff.currentPeriod;
        }
        if (diff.blocked !== undefined) {
            this.blocked = diff.blocked;
        }
        if (diff.statusCode) {
            this.statusCode = diff.statusCode;
        }
        if (diff.statusMark) {
            this.statusMark = diff.statusMark;
        }
        this.updateQuotations(diff, coefsMultiplier);
        BettingEvent.updatePeriods(diff, this.periods);
    }

    private static updateQuotationCountByPeriod (quotationsByPeriods: Map<number, EPeriodType[]> | undefined, quotation: Quotation, quotationsByPeriodsCounters: Map<EPeriodType, number>, deleted: boolean) {
        if (quotationsByPeriods) {
            const periods = quotationsByPeriods.get(quotation.key.quotationId);
            if (periods) {
                periods.forEach(period => {
                    let counter = quotationsByPeriodsCounters.get(period) ?? 0;
                    if (deleted) {
                        counter = counter > 0 ? counter - 1 : 0;
                    } else {
                        counter++;
                    }
                    quotationsByPeriodsCounters.set(period, counter);
                });
            }
        }
    }

    compare (o: BettingEvent): number {
        let timeCompare = this.eventType - o.eventType;
        if (timeCompare === 0) {
            timeCompare = this.startTime.getTime() - o.startTime.getTime();
        }
        if (timeCompare === 0) {
            return this.positionId - o.positionId;
        }
        return timeCompare;
    }

    private updateQuotations (diff: EventDiffsResponse, coefsMultiplier: number) {
        const quotationDiffList = diff.quotationDiffList;
        if (quotationDiffList) {
            const quotationsByPeriods = eventDetailsInfoHolder.getQuotationsByPeriods(this.partition.sportTypeId);
            for (const diffItem of quotationDiffList) {
                if (diffItem.deleted) {
                    const quotation = this.quotations.removeQuotation(diffItem.id);
                    if (quotation) {
                        BettingEvent.updateQuotationCountByPeriod(quotationsByPeriods, quotation, this.quotationCountByPeriod, true);
                    }
                } else {
                    const quotation = this.quotations.getQuotationById(diffItem.id);
                    let newQuotation;
                    if (quotation) {
                        if (diffItem.coef) {
                            quotation.coef = transformCoef(diffItem.coef, coefsMultiplier)!;
                        }
                        if (diffItem.maxWin) {
                            quotation.maxWin = diffItem.maxWin;
                        }
                    } else {
                        const quotationKey = new QuotationKey(
                            diffItem.quotationId!,
                            BettingEvent.getBigDecimal(diffItem.p1),
                            BettingEvent.getBigDecimal(diffItem.p2),
                            BettingEvent.getBigDecimal(diffItem.po),
                            diffItem.p1Id,
                            diffItem.p2Id
                        );
                        newQuotation = new Quotation(
                            diffItem.id,
                            diff.positionId,
                            quotationKey,
                            transformCoef(diffItem.coef, coefsMultiplier)!,
                            diffItem.maxWin!);
                        this.quotations.setQuotation(newQuotation);
                        BettingEvent.updateQuotationCountByPeriod(quotationsByPeriods, newQuotation, this.quotationCountByPeriod, false);
                    }
                }
            }
        }
    }

    private static updatePeriods (diff: EventDiffsResponse, periodStore: PeriodStore) {
        if (diff.periodList) {
            for (const periodDiff of diff.periodList) {
                if (!periodDiff.deleted) {
                    const period = periodStore.getPeriod(periodDiff.type);
                    for (const view of periodDiff.contentList) {
                        period.addView(view.view, view.qidList);
                    }
                } else {
                    periodStore.removePeriod(periodDiff.type);
                }
            }
        }
    }

    private static checkRequiredDictionaryItem (diff: EventDiffsResponse, item: DictionaryItem | undefined, fieldName: string) {
        if (!item) {
            throw new Error(`The event diff doesn't have ${fieldName} info. This event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`);
        }
        if (!item.id) {
            throw new Error(`The event diff doesn't have ${fieldName} id info. This event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`);
        }
        if (!item.name) {
            throw new Error(`The event diff doesn't have ${fieldName} name info. This event diff has been omitted.\nDiff:\n${JSON.stringify(diff)}`);
        }
    }

    private static getBigDecimal (value?: BigDecimalResponse): BigDecimal | undefined {
        return createBigDecimalByBigDecimalResponse(value);
    }
}
