import { CasinoGame } from "@sportaq/model/games/casino-game";
import { GameViewFilter } from "@sportaq/vuex/modules/games/game-view-filter/GameViewFilter";
import { SortedIndexedArray } from "@sportaq/common/utils/sorted-data-types/sorted-indexed-array";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";

export enum EGameListResolverType {
    PLAIN
}

export interface GameListResolver {
    readonly type: EGameListResolverType;

    get batchRebuilder (): GameListRebuilder;

    get batchUpdater (): GameListUpdater;
}

export interface GameListRebuilder {
    begin (): void;

    addEvent (filter: GameViewFilter, game: CasinoGame, favouriteMode: boolean, restrictedProviderIds: number[], restrictedGameIds: string[]): boolean;

    end (): SortedIndexedArray<GameSortInfo, string>;
}

export interface GameListUpdater {
    begin (games: SortedIndexedArray<GameSortInfo, string>): void;

    addOrUpdateEvent (filter: GameViewFilter, game: CasinoGame, favouriteMode: boolean, restrictedProviderIds: number[], restrictedGameIds: string[]): boolean;

    delete (gameId: string): void;

    end (): SortedIndexedArray<GameSortInfo, string>;
}
