import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { getEventTypeByName } from "@sportaq/common/enums/event-type";
import { onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
    getEventTypeByRoute,
    getEventTypeNameByRoute,
    getSportTypeByRoute
} from "@sportaq/vue/components/betting/utils/route-utils";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";

const TIMEOUT = 100;

export function useBettingView () {
    const scoreboardStore = useScoreboardStore();

    const router = useRouter();
    const route = useRoute();

    const forcedPartition: number | undefined = route.query.partition ? parseInt(route.query.partition as string) : undefined;
    const eventSupplier = useEventSupplier();

    updateEventFilter(route.params.eventType as string | undefined, forcedPartition);

    function updateEventFilter (eventTypeStr: string | undefined, forcedPartition: number | undefined) {
        if (eventTypeStr) { // Could be undefined if path is changed to non-betting routes
            const eventType = getEventTypeByName(eventTypeStr);
            scoreboardStore.updateEventsFilter({
                changeEventType: {
                    type: "set",
                    value: eventType
                }
            });
            if (forcedPartition) {
                scoreboardStore.updateEventsFilter({
                    forcePartition: {
                        type: "set",
                        value: forcedPartition
                    }
                });
            }
        }
    }

    onMounted(() => {
        refreshPage();
        if (route.query.partition) {
            scoreboardStore.leagueMode = true;
        }
    });

    watch(() => route.params.eventType, value => {
        updateEventFilter(value as string | undefined, undefined);
    });

    function refreshPage () {
        if (forcedPartition) {
            const interval = setInterval(
                () => {
                    const eventType = getEventTypeByRoute(route);
                    if (eventType && eventSupplier.isColdDataReceived(eventType)) {
                        clearInterval(interval);
                        setTimeout(() => checkEventsCount(), TIMEOUT);
                    }
                },
                TIMEOUT);
        }
    }

    function checkEventsCount () {
        scoreboardStore.updateEventsFilter({
            forcePartition: {
                type: "toggle",
                value: 0
            }
        });
        if (scoreboardStore.getPeriodsView().length === 0 && scoreboardStore.getGroupEventsView().length === 0) {
            scoreboardStore.updateEventsFilter({
                forcePartition: {
                    type: "unset"
                }
            });
            const eventTypeName = getEventTypeNameByRoute(route);
            const sportTypeName = getSportTypeByRoute(route);
            if (eventTypeName && sportTypeName) {
                router.push({ path: `/${eventTypeName}/${sportTypeName}` }).then();
            } else {
                router.push({ path: COMMON_ROUTES.SPORT.path }).then();
            }
        }
    }
}
