import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getChild,
    getRequiredAttribute,
    getRequiredChild
} from "@sportaq/common/utils/xml-helper-functions";
import { ExtendedBalance } from "@sportaq/model/common/user-balance";
import { BaseAccountMessageHandler } from "@sportaq/services/rest/messages/account/base-account-message-handler";
import { NotAuthorizedError } from "@sportaq/common/exceptions/localized-errors";

export class QUs112ExtendedBalance extends BaseAccountMessageHandler<ExtendedBalance> {
    protected readonly requestType: string = "Q.US.1.12";

    constructor () {
        super();
    }

    buildRequest (request: XmlRequest) {
        const actionElement = request.addChild(request.body, "query", {
            type: this.requestType
        });
        const selectorElement = request.addChild(actionElement, "Selector");
        const userFilter = request.addChild(selectorElement, "SelectObject", {
            class: "ps.user.User"
        });
        request.addChild(userFilter, "SelectObject", {
            class: "ps.user.UserAccount",
            allFields: "true"
        });
        request.addChild(userFilter, "SelectObject", {
            class: "ps.user.UserCasinoAccount",
            allFields: "true"
        });
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): ExtendedBalance {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        let mainBalance;
        let casinoBalance;
        if (serverCode === "1001" || serverCode === "1313") {
            throw new NotAuthorizedError();
        }
        if (serverCode === "1190") {
            const userElement = getChild(action, "US");
            if (userElement) {
                const accountListElement = getRequiredChild(userElement, "UserAccountList");
                mainBalance = this.parseAccounts(accountListElement);
                const casinoAccountListElement = getChild(userElement, "UserCasinoAccountList");
                casinoBalance = casinoAccountListElement
                    ? this.parseAccounts(casinoAccountListElement)
                    : {
                        primaryAccount: undefined,
                        bonusAccount: undefined
                    };
            }
        }
        return {
            mainBalance,
            casinoBalance
        };
    }
}
