import { computed, PropType, ref, SetupContext } from "vue";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import Events from "@sportaq/common/enums/events";
import GameWidget from "@sportaq/vue/components/games/common/games-body/game-widget/GameWidget.vue";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { RecycleScroller } from "vue-virtual-scroller";
import {
    ScoreboardBodyComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/common-scoreboard-body-component";
import { useMiscellaneousParamsProvider } from "@sportaq/services/index";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";
import { ImageDictionaryItem, Ref } from "@sportaq/common/types/types";
import eventBus from "@sportaq/common/utils/event-bus";
import { useRootStore } from "@sportaq/vuex/index";
import { gameProviders } from "@sportaq/model/consts/game-providers";

const START_GAME_EVENT = "startGame";
const START_DEMO_EVENT = "startDemo";

interface Props {
    labelInfo?: ImageDictionaryItem;
    showDemo: boolean;
    gamesInRow: number;
    lineHeightInVW: number;
    dataCy: string;
}

export default {
    props: {
        labelInfo: {
            required: false,
            type: Object as PropType<ImageDictionaryItem>
        },
        showDemo: {
            required: false,
            type: Boolean,
            default: false
        },
        gamesInRow: {
            required: false,
            type: Number,
            default: 7
        },
        lineHeightInVW: {
            required: true,
            type: Number
        },
        dataCy: {
            required: true,
            type: String
        }
    },
    components: {
        GameWidget,
        RecycleScroller
    },
    emits: [START_GAME_EVENT, START_DEMO_EVENT],
    setup (props: Props, context: SetupContext) {
        const rootStore = useRootStore();
        const casinoGamesStore = useCasinoGamesStore();
        const miscellaneousParamsProvider = useMiscellaneousParamsProvider();
        const buffer = miscellaneousParamsProvider.recycleScrollerBufferSize;
        const itemSize = ref(ScoreboardBodyComponent.calculateItemSize(props.lineHeightInVW));
        const selectedItem: Ref<CasinoGame | undefined> = ref();
        const rows = computed(() => {
            const userSharedInfo = rootStore.userSharedInfo;
            const result: GameRow[] = [];
            let currentRow: GameRow = new GameRow(1, []);
            const games = casinoGamesStore.getGamesView()
                .filter(value => {
                    const provider = gameProviders.getById(value.provider);
                    return (!provider.excludedCountries || provider.excludedCountries.indexOf(userSharedInfo.countryId) < 0) &&
                        (!provider.excludedCurrencies || !userSharedInfo.primaryAccountCurrencyId || provider.excludedCurrencies.indexOf(userSharedInfo.primaryAccountCurrencyId) < 0);
                });
            for (let i = 0; i < games.length; i++) {
                if (i > 0 && i % props.gamesInRow === 0) {
                    result.push(currentRow);
                    currentRow = new GameRow(currentRow.id + 1, []);
                }
                currentRow.items.push(games[i]);
            }
            if (currentRow.items.length > 0) {
                result.push(currentRow);
            }
            return result;
        });

        function selectItem (event: string, item: CasinoGame) {
            if (context.slots.preview) {
                eventBus.emit(Events.VIRTUAL_GAME_SELECTED);
                selectedItem.value = item;
            } else {
                context.emit(event, item);
            }
        }

        function startGame (item: CasinoGame) {
            selectItem(START_GAME_EVENT, item);
        }

        function startDemo (item: CasinoGame) {
            selectItem(START_DEMO_EVENT, item);
        }

        function clearSelectedItem () {
            selectedItem.value = undefined;
        }

        function onResize () {
            itemSize.value = ScoreboardBodyComponent.calculateItemSize(props.lineHeightInVW);
        }

        return {
            rows,
            itemSize,
            buffer,
            selectedItem,

            onResize,
            startGame,
            startDemo,
            clearSelectedItem
        };
    }
};

class GameRow {
    constructor (readonly id: number, readonly items: GameSortInfo[]) {
    }
}
