import { useRoute, useRouter } from "vue-router";
import { AccountType, useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { onMounted, onUnmounted } from "vue";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { showMessageBox } from "@sportaq/vue/components/common/message-box/message-box-component";
import { useI18n } from "vue-i18n";

export function useHorseEnterGuard () {
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    const store = useBalanceStore();

    function isBonusActive () {
        if (store.mainAccountType === AccountType.USER_ACCOUNT_BONUS) {
            return true;
        }
    }

    if (route.name === COMMON_ROUTES.HORSE.name) {
        if (isBonusActive()) {
            router.push({ path: "/" }).then();
        }
    }

    let remove: () => void;

    onMounted(() => {
        remove = router.beforeEach((to, from, next) => {
            if (to.name === COMMON_ROUTES.HORSE.name && isBonusActive()) {
                showMessageBox(i18n.t("webdesktop.race.bonusActive"));
            } else {
                next();
            }
        });
    });

    onUnmounted(() => {
        remove();
    });
}