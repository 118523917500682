import {
    ConstantItemsPanelSelectedResult,
    ImageDictionaryItemWithSubItems
} from "@sportaq/vue/components/common/constant-items-panel/constant-items-panel-component";
import { useCasinoGamesStore } from "@sportaq/vuex/modules/games/casino-games-module";
import { IdItem, ImageDictionaryItem } from "@sportaq/common/types/types";
import { computed, SetupContext } from "vue";
import { useSettings } from "@sportaq/services/index";
import { ECasinoGameType } from "@sportaq/common/enums/games/casino-game-type";
import { useRootStore } from "@sportaq/vuex/index";

export enum EGameTabs {
    Favourites = 1,
    All = 2,
    Popular = 3,
    Football = 4,
    Tennis = 5,
    Horse = 6,
    Dogs = 7,
    Moto = 8,
    Lottery = 9,
    Fighting = 10,
    Casual = 11,
    Providers = 12,
    New = 13,
    Slots = 14,
    Card = 15,
    Roulette = 16,
    Poker = 17,
    Jackpot = 18,
    Live = 19,
    Blackjack = 20,
    Craps = 21,
    BuyFeature = 22,
    Megaways = 23,
    Crash = 24,
    Fishing = 25,
    Scratch = 26,
    Basketball = 27,
    Keno = 28,
    Bingo = 29,
    Hockey = 30
}

export function getCasinoGameTabName (tab: EGameTabs): string {
    switch (tab) {
        case EGameTabs.All:
            return "common.games.tabs.all";
        case EGameTabs.Popular:
            return "common.games.tabs.popular";
        case EGameTabs.Favourites:
            return "common.games.tabs.favourites";
        case EGameTabs.Football:
            return "common.games.tabs.football";
        case EGameTabs.Tennis:
            return "common.games.tabs.tennis";
        case EGameTabs.Horse:
            return "common.games.tabs.horse";
        case EGameTabs.Dogs:
            return "common.games.tabs.dog";
        case EGameTabs.Moto:
            return "common.games.tabs.moto";
        case EGameTabs.Fighting:
            return "common.games.tabs.fighting";
        case EGameTabs.Casual:
            return "common.games.tabs.casual";
        case EGameTabs.Providers:
            return "common.games.tabs.providers";
        case EGameTabs.New:
            return "common.games.tabs.new";
        case EGameTabs.Slots:
            return "common.games.tabs.slots";
        case EGameTabs.Card:
            return "common.games.tabs.card";
        case EGameTabs.Lottery:
            return "common.games.tabs.lottery";
        case EGameTabs.Roulette:
            return "common.games.tabs.roulette";
        case EGameTabs.Poker:
            return "common.games.tabs.poker";
        case EGameTabs.Jackpot:
            return "common.games.tabs.jackpot";
        case EGameTabs.Live:
            return "common.games.tabs.live";
        case EGameTabs.Blackjack:
            return "common.games.tabs.blackjack";
        case EGameTabs.Craps:
            return "common.games.tabs.craps";
        case EGameTabs.BuyFeature:
            return "common.games.tabs.buyfeature";
        case EGameTabs.Megaways:
            return "common.games.tabs.megaways";
        case EGameTabs.Crash:
            return "common.games.tabs.crash";
        case EGameTabs.Fishing:
            return "common.games.tabs.fishing";
        case EGameTabs.Scratch:
            return "common.games.tabs.scratch";
        case EGameTabs.Basketball:
            return "common.games.tabs.basketball";
        case EGameTabs.Keno:
            return "common.games.tabs.keno";
        case EGameTabs.Bingo:
            return "common.games.tabs.bingo";
        case EGameTabs.Hockey:
            return "common.games.tabs.hockey";
    }
}

export function getCasinoGameTabImage (type: ECasinoGameType, tab: EGameTabs): string {
    const path = "/img/games/tabs/" + (type === ECasinoGameType.Casino ? "casino" : "virtual") + "/";
    switch (tab) {
        case EGameTabs.All:
            return path + "todos.png";
        case EGameTabs.Popular:
            return path + "populares.png";
        case EGameTabs.Favourites:
            return path + "favoritos.png";
        case EGameTabs.Football:
            return path + "soccer_vr.png";
        case EGameTabs.Tennis:
            return path + "tennis_vr.png";
        case EGameTabs.Horse:
            return path + "carreras_de_caballos.png";
        case EGameTabs.Dogs:
            return path + "carreras_de_perros.png";
        case EGameTabs.Moto:
            return path + "motos.png";
        case EGameTabs.Fighting:
            return path + "fight.png";
        case EGameTabs.Casual:
            return path + "casual.png";
        case EGameTabs.Providers:
            return path + "providers.png";
        case EGameTabs.New:
            return path + "nuevos.png";
        case EGameTabs.Slots:
            return path + "tragamonedas.png";
        case EGameTabs.Card:
            return path + "juegos-de-mesa.png";
        case EGameTabs.Lottery:
            return path + "lottery.png";
        case EGameTabs.Roulette:
            return path + "ruleta.png";
        case EGameTabs.Poker:
            return path + "video-poker.png";
        case EGameTabs.Jackpot:
            return path + "jackpot.png";
        case EGameTabs.Live:
            return path + "casino-en-vivo.png";
        case EGameTabs.Blackjack:
            return path + "blackjack.png";
        case EGameTabs.Craps:
            return path + "craps.png";
        case EGameTabs.BuyFeature:
            return path + "buy_feature.png";
        case EGameTabs.Megaways:
            return path + "megaways.png";
        case EGameTabs.Crash:
            return path + "crash.png";
        case EGameTabs.Fishing:
            return path + "fishing.png";
        case EGameTabs.Scratch:
            return path + "scratch.png";
        case EGameTabs.Basketball:
            return path + "basketball.png";
        case EGameTabs.Keno:
            return path + "keno.png";
        case EGameTabs.Bingo:
            return path + "bingo.png";
        case EGameTabs.Hockey:
            return path + "hockey.png";
    }
}

export function useGamesTopMenu (staticItems: ImageDictionaryItemWithSubItems[], type: ECasinoGameType, context: SetupContext, event: string) {
    const rootStore = useRootStore();
    const store = useCasinoGamesStore();
    const settings = useSettings();

    const items = computed(() => {
        const userSharedInfo = rootStore.userSharedInfo;
        const providers: ImageDictionaryItem[] = store.getProviders()
            .filter(value => value.count > 0)
            .filter(value => settings.pointSettings.restrictionGameProviderList.indexOf(value.name) < 0)
            .filter(value => !value.excludedCountries || value.excludedCountries.indexOf(userSharedInfo.countryId) < 0)
            .filter(value => !value.excludedCurrencies || !userSharedInfo.primaryAccountCurrencyId || value.excludedCurrencies.indexOf(userSharedInfo.primaryAccountCurrencyId) < 0)
            .map(value => ({
                id: value.id,
                name: value.name,
                title: value.title,
                image: value.image
            }));
        const result = [...staticItems];
        const favoritesItem = {
            id: EGameTabs.Favourites,
            name: getCasinoGameTabName(EGameTabs.Favourites),
            image: getCasinoGameTabImage(type, EGameTabs.Favourites),
            count: store.getFavouritesCount()
        };
        let newIndex = findItemIndex(result, EGameTabs.New);
        if (newIndex === -1) {
            newIndex = findItemIndex(result, EGameTabs.Popular);
        }
        if (newIndex !== -1) {
            result.splice(newIndex + 1, 0, favoritesItem);
        } else {
            result.unshift(favoritesItem);
        }
        const allIndex = findItemIndex(result, EGameTabs.All);
        result.splice(allIndex + 1, 0, {
            id: EGameTabs.Providers,
            name: getCasinoGameTabName(EGameTabs.Providers),
            image: getCasinoGameTabImage(type, EGameTabs.Providers),
            subItems: providers
        });
        return result;
    });

    function findItemIndex (items: IdItem[], tab: EGameTabs): number {
        for (let i = 0; i < items.length; i++) {
            if (items[i].id === tab) {
                return i;
            }
        }
        return -1;
    }

    function select (result: ConstantItemsPanelSelectedResult, auto: boolean) {
        context.emit(event, result, auto);
    }

    return {
        items,
        select
    };
}
