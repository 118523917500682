import ChangePasswordDialog from "@/views/profile-view/components/change-password/ChangePasswordDialog.vue";
import ChangePhoneDialog from "@/views/profile-view/components/change-phone/ChangePhoneDialog.vue";
import ChangeEmailDialog from "@/views/profile-view/components/change-email/ChangeEmailDialog.vue";
import VerificationDialog from "@/views/profile-view/components/verification-dialog/VerificationDialog.vue";
import { Field, Form } from "vee-validate";
import { useProfileViewCommon } from "@sportaq/vue/views/web/profile-view-common";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useRootStore } from "@sportaq/vuex/index";

export default {
    name: "ProfileView",
    components: {
        ChangePasswordDialog,
        ChangePhoneDialog,
        ChangeEmailDialog,
        VerificationDialog,
        Form,
        Field
    },
    setup () {
        const {
            user,
            countryName,
            currentDialog,
            maxDate,
            isPhoneVerified,
            isEmailVerified,
            EVerifyField,
            showVerification,
            showPhoneVerifiedMessage,
            showEmailVerifiedMessage,
            onNaturalNumberKeyFilter,
            onDialogClose,
            onSubmit
        } = useProfileViewCommon();

        const router = useRouter();
        const store = useRootStore();
        const theme = computed(() => store.theme);

        function back () {
            router.back();
        }
        return {
            user,
            countryName,
            currentDialog,
            theme,
            maxDate,
            isPhoneVerified,
            isEmailVerified,
            EVerifyField,
            onDialogClose,
            showVerification,
            showPhoneVerifiedMessage,
            showEmailVerifiedMessage,
            onNaturalNumberKeyFilter,
            onSubmit,
            back
        };
    }
};
