import { SimpleStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { PropType, SetupContext } from "vue";

import {
    BetSlipStakeRowComponent
} from "@sportaq/vue/components/betting/bet-slip/rows/stake-row/common-bet-slip-stake-row-component";
import { useBetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { EDetailsViewType } from "@sportaq/model/betting/view/scoreboard-selection";

export default {
    props: {
        stake: {
            required: true,
            type: Object as PropType<SimpleStake>
        }
    },
    emits: [BetSlipStakeRowComponent.UPDATE_AMOUNT_EVENT],
    setup (props: BetSlipStakeRowComponent.Props, context: SetupContext) {
        const betSlipStore = useBetSlipStore();
        const {
            amount,
            currencyName,
            diffsClass,
            errorShowed,
            numberInputDialogShowed,

            isLive,
            isBlocked,
            quotationTitle,
            quotationLabel,
            maxWin,
            stakeError,
            participants,

            saveAmount,
            removeStake,
            showErrorMessage,
            accept,

            cancelAmountEditing,
            confirmStakeAmountEditing,
            onInputFocus,
            showDetails,
            onAmountKeyDown
        } = BetSlipStakeRowComponent.useBetSlipStakeRowComponent(props, context);

        async function mobileShowDetails () {
            const result = showDetails(EDetailsViewType.Mobile);
            if (result) {
                betSlipStore.isShowed = false;
            }
        }

        return {
            amount,
            currencyName,
            diffsClass,
            errorShowed,
            numberInputDialogShowed,

            isLive,
            isBlocked,
            quotationTitle,
            quotationLabel,
            maxWin,
            stakeError,
            participants,

            saveAmount,
            removeStake,
            showErrorMessage,
            accept,

            cancelAmountEditing,
            confirmStakeAmountEditing,
            onInputFocus,
            onAmountKeyDown,
            mobileShowDetails
        };
    }
};
