<template>
    <div class="item quotation" @click="selectQuotation" :class="[diffsClass, {'active': isSelected, 'locked': isLocked}]" :data-cy="`line-quotation-${source.quotation.positionId}-${source.quotation.key.quotationId}-${source.quotation.id}`">
        <div class="name">
            <span v-html="quotationLabel" />
        </div>
        <div class="rate" v-if="quotationPresent && !eventBlocked">{{ quotationFormattedCoef }}</div>
        <div class="rate" v-else-if="isLocked || (quotationPresent && eventBlocked)"><img
            src="@assets/img/icon-lock.svg" alt=""/></div>
    </div>
</template>

<script src="./standard-scoreboard-line-quotation-component.ts"/>
