import { isSupportedLocale, resolveLanguage } from "@sportaq/i18n/index";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";

export function localeNavigationGuard (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const paramLocale = to.params.locale;

    function internalResolveLanguage (): string {
        const queryIndex = window.location.href.indexOf("?");
        if (queryIndex > 0) {
            const params = new URLSearchParams(window.location.href.substring(queryIndex, window.location.href.length));
            const lang = params.get("lang");
            if (lang && isSupportedLocale(lang)) {
                return lang;
            }
        }
        return resolveLanguage();
    }

    if (to.fullPath.endsWith("//")) {
        next(to.fullPath.substring(0, to.fullPath.length - 1));
        return;
    }
    if (!to.path.startsWith("/landing")) {
        if (paramLocale === ":locale") {
            const path = to.fullPath.replace(":locale", internalResolveLanguage());
            next(path);
            return;
        } else if (!paramLocale || !isSupportedLocale(paramLocale.toString())) {
            const lang = internalResolveLanguage();
            if (!to.fullPath.startsWith("/" + lang + "/") && to.fullPath !== "/" + lang) {
                next("/" + lang + to.fullPath);
                return;
            }
        }
    }
    next();
}
