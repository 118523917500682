export enum PaymentSystems {
    WITHDRAWAL_METHOD_VOUCHER = "WITHDRAWAL_METHOD_VOUCHER",
    WITHDRAWAL_METHOD_VOUCHER_INSTANT = "WITHDRAWAL_METHOD_VOUCHER_INSTANT",
    WITHDRAWAL_METHOD_BANK_CARD = "WITHDRAWAL_METHOD_BANK_CARD",
    WITHDRAWAL_METHOD_YAPE = "WITHDRAWAL_METHOD_YAPE",
    WITHDRAWAL_METHOD_LUKITA = "WITHDRAWAL_METHOD_LUKITA",
    WITHDRAWAL_METHOD_TUNKI = "WITHDRAWAL_METHOD_TUNKI",

    DEPOSIT_METHOD_SYSTEM_CASH = "DEPOSIT_METHOD_SYSTEM_CASH",
    DEPOSIT_METHOD_YAPE = "DEPOSIT_METHOD_YAPE",
    DEPOSIT_METHOD_LUKITA = "DEPOSIT_METHOD_LUKITA",
    DEPOSIT_METHOD_TUNKI = "DEPOSIT_METHOD_TUNKI",
    PAGOEFECTIVO_INVOICE = "PAGOEFECTIVO_INVOICE",
    DEPOSIT_IPAY = "DEPOSIT_IPAY",
    DEPOSIT_MONNET = "DEPOSIT_MONNET",
    DEPOSIT_MONNET_CASH = "DEPOSIT_MONNET_CASH",
    DEPOSIT_MONNET_CARD = "DEPOSIT_MONNET_CARD",
    DEPOSIT_TUPAY = "DEPOSIT_TUPAY"
}

export interface PaymentSystemData {
    paymentSystemType: PaymentSystems,
    availableCurrencies: number[]
}

export function isPaymentSystemPresent (paymentSystemList: PaymentSystemData[], paymentSystem: PaymentSystems): boolean {
    return paymentSystemList.find(s => s.paymentSystemType === paymentSystem) !== undefined;
}