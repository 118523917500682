import { ceil, floor } from "@sportaq/common/utils/number-utils";
import { ThemeClass } from "@sportaq/common/enums/theme";
import { isPaymentSystemPresent, PaymentSystemData, PaymentSystems } from "@sportaq/common/enums/payment-systems";
import { DEFAULT_BRAND_ID } from "@sportaq/common/consts/default-consts";

export interface Currency {
    rateId: number;
    typeId: number;
    value: number;
}

export enum EGameService { Betting, Race, Virtual, Casino, Aviator}

export function getCurrencyName (id: number): string {
    switch (id) {
        case 1:
            return "UAH";
        case 2:
            return "USD";
        case 3:
            return "RUR";
        case 4:
            return "EEK";
        case 5:
            return "BYR";
        case 6:
            return "";
        case 7:
            return "";
        case 8:
            return "EUR";
        case 9:
            return "GEL";
        case 12:
            return "AZN";
        case 14:
            return "COP";
        case 16:
            return "MDL";
        case 15:
            return "PEN";
        case 34:
            return "HTG";
        case 35:
            return "NHL";
        case 36:
            return "GBP";
        case 33:
            return "UYU";
        case 32:
            return "XAF";
        case 31:
            return "BOB";
        case 30:
            return "PYG";
        case 37:
            return "ZWL";
        case 38:
            return "TDD";
        case 39:
            return "ZAR";
        case 40:
            return "CLP";
        case 41:
            return "LSL";
        case 42:
            return "NAD";
        case 43:
            return "DOP";
        case 44:
            return "ZMW";
        default:
            return "UNK";
    }
}

export interface PointSettings {
    readonly id: number;
    readonly name: string;
    readonly address: string;
    readonly stationId: number;
    readonly partnerId: number;
    readonly brandId: number;
    readonly preMatchCoefMultiplier: number;
    readonly liveCoefMultiplier: number;
    readonly preMatchSumStakeMin: number;
    readonly liveSumStakeMin: number;
    readonly liveSumStakeMax: number;
    readonly preMatchSumStakeMax: number;
    readonly expressSumStakeMin: number;
    readonly systemSumStakeMin: number;
    readonly isBonusActive: boolean;
    readonly expressBonusMultiplier: number;
    readonly expressBonusMaxSumStake: number;
    readonly currency: Currency;
    readonly currencyName: string;
    readonly draftCoef: number;
    readonly restrictionGameProviderList: string[];
    readonly restrictionGameMarkList: string[];
    readonly logoVisible?: boolean;
    readonly logoSrc?: string;
    readonly logoPrintSrc?: string;
    readonly isScrollButtonsUsed?: boolean;
    readonly themes: ThemeClass[];
    readonly caption?: string;
    readonly keyboardVisible?: boolean;
    readonly liveResultsVisible : boolean;
    readonly disabledServices: EGameService[];
    readonly fullscreen: boolean;
    readonly paymentSystems: PaymentSystemData[];
    isPaymentSystemAvailable (paymentSystem: PaymentSystems): boolean;
}

export const defaultPointSettings: PointSettings = {
    id: 0,
    name: "",
    address: "",
    stationId: 0,
    currencyName: "",
    preMatchSumStakeMin: 0,
    liveSumStakeMin: 0,
    currency: {
        typeId: 0,
        rateId: 0,
        value: 0
    },
    draftCoef: 0,
    expressBonusMaxSumStake: 0,
    expressBonusMultiplier: 0,
    expressSumStakeMin: 0,
    isBonusActive: false,
    liveCoefMultiplier: 0,
    liveSumStakeMax: 0,
    preMatchCoefMultiplier: 0,
    preMatchSumStakeMax: 0,
    restrictionGameMarkList: [],
    restrictionGameProviderList: [],
    systemSumStakeMin: 0,
    themes: [],
    liveResultsVisible: false,
    disabledServices: [],
    partnerId: 0,
    brandId: DEFAULT_BRAND_ID,
    fullscreen: false,
    paymentSystems: [],
    isPaymentSystemAvailable (): boolean {
        return true;
    }
};

export class PointSettingsImpl implements PointSettings {
    private readonly SCALE = 2;
    readonly paymentSystems: PaymentSystemData[];
    constructor (readonly id: number,
        readonly pointGUID: string,
        readonly name: string,
        readonly cityText: string,
        readonly address: string,
        readonly phone: string,
        readonly email: string,
        readonly isRated: boolean,
        readonly isDemo: boolean,
        readonly isPartner: boolean,
        readonly currency: Currency,
        readonly config: Map<string, string>,
        readonly restrictionGameProviderList: string[],
        readonly restrictionGameMarkList: string[],
        readonly stationId: number,
        readonly partnerId: number,
        readonly brandId: number,
        paymentSystems: PaymentSystemData[]
    ) {
        this.disabledServices = [];
        this.paymentSystems = paymentSystems;
    }

    disabledServices: EGameService[];
    isPaymentSystemAvailable (paymentSystem: PaymentSystems): boolean {
        return isPaymentSystemPresent(this.paymentSystems, paymentSystem);
    }

    get carouselUrl (): string | undefined {
        return this.config.get("ps.point.Interface.Carousel.url");
    }

    get caption (): string | undefined {
        return this.getProperty("ps.point.Interface.caption");
    }

    get liveResultsVisible (): boolean {
        return this.getBooleanProperty("ps.point.Interface.LiveResults.visible");
    }

    get fullscreen (): boolean {
        return this.getBooleanProperty("ps.point.Interface.fullscreen");
    }

    get themes (): ThemeClass[] {
        const result = this.getProperty("ps.point.Interface.Themes");
        if (!result) {
            return [ThemeClass.Default];
        }
        return result.split(",").map(v => v as ThemeClass);
    }

    get isScrollButtonsUsed (): boolean | undefined {
        const isUsed = this.getProperty("ps.point.Interface.ScrollButtons.isUsed");
        if (isUsed) {
            return isUsed === "true";
        }
        return false;
    }

    get keyboardVisible (): boolean | undefined {
        const isVisible = this.getProperty("ps.point.Interface.Keyboard.visible");
        if (isVisible) {
            return isVisible === "true";
        }
    }

    get logoVisible (): boolean | undefined {
        const logoVisible = this.getProperty("ps.point.Interface.Logo.visible");
        if (logoVisible) {
            return logoVisible === "true";
        }
    }

    get logoSrc (): string | undefined {
        return this.getProperty("ps.point.Interface.Logo.src");
    }

    get logoPrintSrc (): string | undefined {
        return this.getProperty("ps.point.Interface.PrintLogo.src");
    }

    get preMatchCoefMultiplier (): number {
        return this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.CoefWin.Multiplier");
    }

    get liveCoefMultiplier (): number {
        return this.getFloatProperty("ps.point.Bet.GameScheme.Live.CoefWin.Multiplier");
    }

    get preMatchSumStakeMin (): number {
        return ceil(this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.Straight.SumStake.Min") * this.currency.value, this.SCALE);
    }

    get preMatchSumStakeMax (): number {
        return floor(this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.SumStake.Max") * this.currency.value, this.SCALE);
    }

    get liveSumStakeMin (): number {
        return ceil(this.getFloatProperty("ps.point.Bet.GameScheme.Live.SumStake.Min") * this.currency.value, this.SCALE);
    }

    get liveSumStakeMax (): number {
        return floor(this.getFloatProperty("ps.point.Bet.GameScheme.Live.SumStake.Max") * this.currency.value, this.SCALE);
    }

    get expressSumStakeMin (): number {
        return ceil(this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.Parlay.SumStake.Min") * this.currency.value, this.SCALE);
    }

    get systemSumStakeMin (): number {
        return ceil(this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.System.SumStake.Min") * this.currency.value, this.SCALE);
    }

    get isBonusActive (): boolean {
        return this.getProperty("ps.point.Bet.Bonus.BonusPlus2.isActive") === "true";
    }

    get expressBonusMultiplier (): number {
        if (this.isBonusActive) {
            const mul = this.getFloatProperty("ps.point.Bet.Bonus.BonusPlus2.ExpressItemCoef.Multiplier");
            return mul || 1;
        }
        return 1;
    }

    get expressBonusMaxSumStake (): number {
        return floor(this.getFloatProperty("ps.point.Bet.Bonus.BonusPlus2.SumStake.Max") * this.currency.value, this.SCALE);
    }

    get currencyName (): string {
        return getCurrencyName(this.currency.typeId);
    }

    get draftCoef (): number {
        return this.getFloatProperty("ps.point.Bet.GameScheme.GeneralGame.DraftCoef");
    }

    private getProperty (name: string): string | undefined {
        return this.config.get(name);
    }

    private getFloatProperty (name: string): number {
        const val = this.getProperty(name);
        return val ? parseFloat(val) : 0;
    }

    private getBooleanProperty (name: string): boolean {
        const val = this.getProperty(name);
        return val === "true";
    }
}
