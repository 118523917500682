import { RouteLocationNormalizedLoaded } from "vue-router";
import EventType, { getEventTypeByName } from "@sportaq/common/enums/event-type";

// Check if route is event details page's route (like /live/tennis/12345)
export function isEventPageDetailsRoute (route: RouteLocationNormalizedLoaded): boolean {
    return !!route.params.eventType && !!route.params.sportType && !!route.params.positionId;
}

export function getEventTypeNameByRoute (route: RouteLocationNormalizedLoaded) {
    return route.params.eventType as string | undefined;
}

export function getEventTypeByRoute (route: RouteLocationNormalizedLoaded): EventType | undefined {
    const eventTypeStr = getEventTypeNameByRoute(route);
    return eventTypeStr ? getEventTypeByName(eventTypeStr) : undefined;
}

export function getSportTypeByRoute (route: RouteLocationNormalizedLoaded): string | undefined {
    return route.params.sportType as string | undefined;
}
