import HorseGames from "@sportaq/vue/components/games/horse/HorseGames.vue";
import { useHorseGameViewCommon } from "@sportaq/vue/views/web/horse-game-view/horse-game-view-common";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";

export default {
    name: "HorseGameView",
    components: {
        HorseGames,
        LoginPopupDialog,
        ResetPasswordDialog
    },
    setup () {
        return useHorseGameViewCommon("viewcontentracing");
    }
};
