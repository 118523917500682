export interface PrintService {
    print (htmlContent: string | undefined): void;

    printPreview (htmlContent: string | undefined): void;
}

export class StubPrintServiceImpl implements PrintService {
    print (): void {
        // empty
    }

    printPreview (): void {
        // empty
    }
}
