import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import { useRestService } from "@sportaq/services/index";
import { useRoute, useRouter } from "vue-router";
import { computed, watch } from "vue";
import EventType from "@sportaq/common/enums/event-type";
import { EScoreboardPageType } from "@sportaq/vuex/modules/betting/scoreboard/periods/periods-controller";
import { MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW } from "@sportaq/common/consts/default-consts";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";

export namespace BettingNavigationSidebarComponent {

    export function useBettingNavigationSidebarComponent () {
        const scoreboardStore = useScoreboardStore();
        const restService = useRestService();
        const router = useRouter();
        const route = useRoute();

        const favouriteCount = computed(() => scoreboardStore.getFavouritesCount());
        const leagueMode = computed(() => scoreboardStore.leagueMode);
        const liveReverseTimeSorting = computed(() => scoreboardStore.liveReverseTimeSorting);
        const isPopular = computed(() => {
            const filter = scoreboardStore.getEventsViewFilter();
            return filter.eventType === EventType.PRE_MATCH && !!filter.popular;
        });
        const favouriteMode = computed(() => scoreboardStore.scoreboardPageType === EScoreboardPageType.FAVOURITES);
        const showPopular = computed(() => scoreboardStore.getEventsViewFilter().eventType === EventType.PRE_MATCH &&
            scoreboardStore.scoreboardPageType === EScoreboardPageType.EVENT_LIST);
        const showReverseTimeSorting = computed(() => scoreboardStore.getEventsViewFilter().eventType !== EventType.PRE_MATCH &&
            scoreboardStore.scoreboardPageType === EScoreboardPageType.EVENT_LIST);
        const isFilterActive = computed(() => scoreboardStore.filterMenuActive);
        const isFilterVisible = computed(() => scoreboardStore.scoreboardPageType !== EScoreboardPageType.EVENT_DETAILS);

        const showLeague = computed(() => scoreboardStore.scoreboardPageType !== EScoreboardPageType.EVENT_DETAILS);

        function toggleLeagueMode () {
            scoreboardStore.leagueMode = !scoreboardStore.leagueMode;
        }

        function toggleReverseTimeSorting () {
            scoreboardStore.liveReverseTimeSorting = !scoreboardStore.liveReverseTimeSorting;
        }

        function toggleFilterVisible () {
            scoreboardStore.filterMenuActive = !scoreboardStore.filterMenuActive;
        }

        async function togglePopularMode () {
            const filter = scoreboardStore.getEventsViewFilter();
            if (filter.eventType === EventType.PRE_MATCH) {
                if (filter.popular === undefined) { // force check for undefined due to empty array could be false
                    const sportType = scoreboardStore.getEventsViewFilter().sportType;
                    if (sportType) {
                        const positions = await restService.getPopularPrematchPositions([sportType], MIN_COUNT_SCOREBOARD_LINES_FOR_PRETTY_VIEW);
                        scoreboardStore.updateEventsFilter({
                            popular: {
                                type: "set",
                                value: positions
                            }
                        });
                    }
                } else {
                    scoreboardStore.updateEventsFilter({ popular: { type: "unset" } });
                }
            }
        }

        let lastEventType: string | undefined;
        let lastSportType: string | undefined;
        if (route.params.eventType) {
            lastEventType = route.params.eventType as string;
        }
        if (route.params.sportType) {
            lastSportType = route.params.sportType as string;
        }

        watch(() => route.params.eventType, (value) => {
            if (value) {
                lastEventType = value as string;
            }
        });

        watch(() => route.params.sportType, (value) => {
            if (value) {
                lastSportType = value as string;
            }
        });

        watch(() => scoreboardStore.getFavouritesCount(), async value => {
            if (value === 0) {
                await getOutFromFavourites();
            }
        });

        async function toggleFavouriteMode () {
            if (scoreboardStore.scoreboardPageType !== EScoreboardPageType.FAVOURITES) {
                if (favouriteCount.value > 0) {
                    scoreboardStore.scoreboardPageType = EScoreboardPageType.FAVOURITES;
                    await router.push({ name: COMMON_ROUTES.FAVOURITES.name });
                }
            } else {
                await getOutFromFavourites();
            }
        }

        async function getOutFromFavourites () {
            scoreboardStore.scoreboardPageType = EScoreboardPageType.EVENT_LIST;
            if (lastEventType && lastSportType) {
                await router.push({ path: `/${lastEventType}/${lastSportType}` });
            } else {
                await router.push({ path: COMMON_ROUTES.SPORT.path });
            }
        }

        return {
            isFilterActive,
            isFilterVisible,
            favouriteMode,
            favouriteCount,
            leagueMode,
            liveReverseTimeSorting,
            isPopular,
            showPopular,
            showReverseTimeSorting,
            showLeague,

            toggleFavouriteMode,
            togglePopularMode,
            toggleLeagueMode,
            toggleReverseTimeSorting,
            toggleFilterVisible
        };
    }
}
