<template>
    <div class="code-page">
        <div class="code-page-content">
            <div class="code">
                <span v-for="(digit, index) in result.code" :key="index">
                    {{ digit }}
                </span>
            </div>
            <div class="title">
                {{ result.title }}
            </div>
            <div class="description">
                {{ result.description }}
            </div>
            <div class="buttons">
                <router-link :to="{name: 'home'}" class="btn">
                    {{$t("common.notFound.homeButton")}}
                </router-link>
                <button v-if="result.code === '404'" @click="$router.back()" class="btn">
                    {{$t("common.notFound.backButton")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script src="./not-found-component.ts">

</script>

<style scoped>

</style>
