<template>
    <a href="" class="deposit-back" @click.prevent="back()">{{$t("webdesktop.profileView.title")}}</a>
    <div class="deposit-tabs" v-if="user">
        <div class="deposit-tabs-body">
            <div class="personal">
                <div class="personal-block">
                    <div class="title-line">{{$t("webdesktop.profileView.titleLine")}}</div>
                    <div class="form-line locked" v-if="user.login">
                        <label for="user-number">{{$t("mobile.profileView.loginLabel")}}</label>
                        <input type="text" readonly :value="user.login" id="user-login">
                        <div class="icon">
                            <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                        </div>
                    </div>
                    <div class="form-line locked">
                        <label for="user-number">{{$t("webdesktop.profileView.accountNumber")}}</label>
                        <input type="text" readonly :value="user.userNumber" id="user-number">
                        <div class="icon">
                            <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                        </div>
                    </div>
                    <div class="form-line">
                        <label for="password-input">{{$t("webdesktop.profileView.passwordLabel")}}</label>
                        <input type="password" readonly value="267879757" id="password-input">
                        <div class="icon" @click.prevent="currentDialog = 'ChangePasswordDialog'" id="change-password-button">
                            <img :src="require(`@assets/img/mobile/${theme}/icon-edit.svg`)" alt="">
                        </div>
                    </div>
                    <div class="form-line locked">
                        <label>{{$t("webdesktop.profileView.registrationDate")}}</label>
                        <input type="text" readonly :value="user.registrationTime ? $d(user.registrationTime, 'long') : ''">
                        <div class="icon">
                            <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                        </div>
                    </div>
                </div>
                <Form @submit="onSubmit" v-slot="{ errors, handleSubmit }">
                    <div class="personal-block">
                        <div class="title-line">{{$t("webdesktop.profileView.information")}}</div>
                        <div class="form-line">
                            <label for="phone-input">{{$t("webdesktop.registration.email.phoneLabel")}}</label>
                            <input type="text" readonly v-model="user.phone" id="phone-input">
                            <div id="phone-verified" class="icon" @click="showPhoneVerifiedMessage" v-if="isPhoneVerified()">
                              <img id="phone-verified-icon" src="/img/icon-check-mark.gif" alt="" >
                            </div>
                            <div id="phone-verification" class="icon" @click="showVerification" v-else>
                              <img id="phone-not-verified-icon" src="/img/icon-exclamation.png" alt="">
                            </div>
                            <div class="icon" @click.prevent="currentDialog = 'ChangePhoneDialog'" id="change-phone-button">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-edit.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line">
                            <label>{{$t("webdesktop.registration.email.emailLabel")}}</label>
                            <input type="text" readonly v-model="user.email" id="email-input">
                            <div id="email-verified" class="icon" @click="showEmailVerifiedMessage" v-if="isEmailVerified()">
                              <img id="email-verified-icon" src="/img/icon-check-mark.gif" alt="" >
                            </div>
                            <div id="email-verification" class="icon" @click="showVerification" v-else>
                              <img id="email-not-verified-icon" src="/img/icon-exclamation.png" alt="">
                            </div>
                            <div class="icon" @click.prevent="currentDialog = 'ChangeEmailDialog'" id="change-email-button">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-edit.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line">
                            <label for="firstName">{{$t("webdesktop.registration.email.firstNameLabel")}}</label>
                            <Field type="text" rules="required" id="firstName" name="firstName" :value="user.firstName" :readonly="user.firstName"/>
                            <div class="field-error" v-if="errors.firstName">{{errors.firstName}}</div>
                            <div class="icon" v-if="user.firstName">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line">
                            <label for="lastName">{{$t("webdesktop.registration.email.lastNameLabel")}}</label>
                            <Field type="text" rules="required" id="lastName" name="lastName" :value="user.lastName" :readonly="user.lastName"/>
                            <div class="field-error" v-if="errors.lastName">{{errors.lastName}}</div>
                            <div class="icon" v-if="user.lastName">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line birthday">
                            <label for="birthday" :class="{'label-error': errors.birthday}">{{$t("webdesktop.registration.email.birthdayLabel")}}</label>
                            <Field v-if="user.birthday.length === 0" type="date" rules="required|birthday" id="birthday" name="birthday" :value="user.birthday" :max="maxDate"/>
                            <Field v-else type="text" id="birthday" name="birthday" :value="user.birthday.split('-').reverse().join('.')" :readonly="user.birthday.length > 0"/>
                            <div class="field-error" v-if="errors.birthday">{{errors.birthday}}</div>
                            <div class="icon" v-if="user.birthday">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line locked">
                            <label>{{$t("webdesktop.registration.email.countryLabel")}}</label>
                            <input type="text" readonly :value="countryName">
                            <div class="icon">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                            </div>
                        </div>
                        <div class="form-line">
                            <label for="city">{{$t("webdesktop.registration.email.cityLabel")}}</label>
                            <Field id="city" name="city" type="text" :value="user.city"/>
                            <div class="icon"></div>
                        </div>
                        <div class="form-line">
                            <label for="document" :class="{'label-error': errors.document}">{{$t("webdesktop.registration.email.documentLabel")}}</label>
                            <Field id="document" name="document" type="text" rules="required" :value="user.IdentificationCode"
                                   :readonly="user.IdentificationCode !== undefined && user.IdentificationCode.trim().length > 0" />
                            <div class="field-error" v-if="errors.document">{{errors.document}}</div>
                            <div class="icon" v-if="user.IdentificationCode !== undefined && user.IdentificationCode.trim().length > 0">
                                <img :src="require(`@assets/img/mobile/${theme}/icon-lock.svg`)" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="personal-buttons">
                        <button class="button button-primary" @click.stop.prevent="handleSubmit(onSubmit)">{{$t("webdesktop.profileView.submitButton")}}</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>

    <component :is="currentDialog" @close="onDialogClose" />
</template>

<script src="./profile-view-component.ts" />
