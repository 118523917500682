import { EStakeStatus, SimpleStake } from "@sportaq/model/betting/bet-slip/stakes/stakes";
import { computed, onMounted, onUnmounted, ref, SetupContext, watch } from "vue";
import { useSettings } from "@sportaq/services/index";
import { BetSlipRowCommon } from "@sportaq/vue/components/betting/bet-slip/common/bet-slip-functions";
import { useEventSupplier } from "@sportaq/vuex/modules/betting/non-reactive-storage/events/event-supplier";
import EventType from "@sportaq/common/enums/event-type";
import { createQuotationTitleByQuotationId } from "@sportaq/vue/components/betting/utils/quotation-strings";
import { BIG_DECIMAL_SCALES } from "@sportaq/common/consts/default-consts";
import eventBus from "@sportaq/common/utils/event-bus";
import Events from "@sportaq/common/enums/events";
import { BetSlipStore } from "@sportaq/vuex/modules/betting/bet-slip/bet-slip-module";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { EDetailsViewType } from "@sportaq/model/betting/view/scoreboard-selection";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";
import EPeriodType from "@sportaq/common/enums/period-types";
import { useBalanceStore } from "@sportaq/vuex/modules/betting/balance/balance-module";
import { useRoute } from "vue-router";

export namespace BetSlipStakeRowComponent {
    export const UPDATE_AMOUNT_EVENT = "updateAmount";

    export interface Props {
        stake: SimpleStake;
    }

    export function useBetSlipStakeRowComponent (props: Props, context: SetupContext) {
        const settings = useSettings();
        const store = useBalanceStore();
        const scoreboardStore = useScoreboardStore();
        const route = useRoute();
        const {
            i18n,
            betSlipStore,
            amount,
            errorShowed,
            numberInputDialogShowed,
            stakeError,
            showErrorMessage,
            accept,
            cancelAmountEditing,
            confirmAmountEditing,
            onInputFocus,
            onAmountKeyDown,
            quotationFormat
        } = BetSlipRowCommon.useStakeCommons(props.stake, (store, newAmount) => updateAmount(store, props.stake, newAmount));
        const eventSupplier = useEventSupplier();
        const currencyName = computed(() => {
            return store.currency ? store.currency : settings.pointSettings.currencyName;
        });
        const diffsClass = ref("");
        const isLive = computed(() => props.stake.event.eventType === EventType.LIVE);
        const quotationTitle = computed(() => {
            const event = props.stake.event;
            return createQuotationTitleByQuotationId(i18n, event.sportType, props.stake, props.stake.key.quotationKey);
        });

        const maxWin = computed(() => {
            if (amount.value) {
                return (amount.value * props.stake.coef).toFixed(BIG_DECIMAL_SCALES.MONEY);
            }
            return undefined;
        });

        const isBlocked = computed(() => {
            if (stakeError.value?.cause) {
                switch (stakeError.value?.cause) {
                    case EStakeStatus.quotationBlocked:
                    case EStakeStatus.positionBlocked:
                    case EStakeStatus.eventFullTime:
                    case EStakeStatus.eventStarted:
                    case EStakeStatus.positionLiveServiceNotAvailable: {
                        return true;
                    }
                }
            }
            return false;
        });

        const participants = computed(() => getParticipantList(props.stake, props.stake.key.quotationKey));
        const quotationLabel = computed(() => BetSlipRowCommon.getSimpleStakeQuotationLabel(quotationFormat.value, props.stake));

        function removeStake () {
            const event = props.stake.event;
            const key = props.stake.key;
            betSlipStore.removeSimpleStake(event.eventType, event.positionId, key.id, settings.pointSettings);
        }

        function saveAmount () {
            updateAmount(betSlipStore, props.stake, amount.value);
            context.emit(UPDATE_AMOUNT_EVENT, amount.value);
        }

        function confirmStakeAmountEditing (value: number) {
            confirmAmountEditing(value);
            saveAmount();
        }

        function backlightOff () {
            diffsClass.value = "";
        }

        function showDetails (viewType: EDetailsViewType): boolean {
            const event = props.stake.event;
            const bettingEvent = eventSupplier.getEvent(event.eventType, event.positionId);
            if (bettingEvent && !bettingEvent.isGroupEvent) {
                scoreboardStore.setScoreboardSelection(route, {
                    eventType: event.eventType,
                    positionId: event.positionId,
                    period: EPeriodType.MAIN,
                    quotationId: props.stake.key.quotationKey.quotationId,
                    preferredView: viewType
                });
                return true;
            }
            return false;
        }

        onMounted(() => {
            eventBus.on(Events.QUOTATION_BACKLIGHT_OFF, backlightOff);
        });
        onUnmounted(() => {
            eventBus.off(Events.QUOTATION_BACKLIGHT_OFF, backlightOff);
        });

        watch(() => props.stake.bgCoef, (value, oldValue) => {
            const compare = value.compare(oldValue);
            if (compare) {
                diffsClass.value = compare > 0 ? "up" : "down";
            }
        });
        return {
            amount,
            currencyName,
            diffsClass,
            errorShowed,
            numberInputDialogShowed,

            isLive,
            isBlocked,
            quotationTitle,
            quotationLabel,
            maxWin,
            stakeError,
            participants,

            saveAmount,
            removeStake,
            showErrorMessage,
            accept,

            cancelAmountEditing,
            confirmStakeAmountEditing,
            onInputFocus,
            showDetails,
            onAmountKeyDown
        };
    }

    function updateAmount (store: BetSlipStore, stake: SimpleStake, amount: number | undefined) {
        const event = stake.event;
        const key = stake.key;
        store.setSimpleStakeAmount(event.eventType, event.positionId, key.id, amount);
    }
}
