// source: responses/casino/GameProviderListMessage.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.GameProviderListMsg', null, global);
goog.exportSymbol('proto.GameProviderMsg', null, global);
goog.exportSymbol('proto.GameProviderOrderMsg', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GameProviderListMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GameProviderListMsg.repeatedFields_, null);
};
goog.inherits(proto.GameProviderListMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GameProviderListMsg.displayName = 'proto.GameProviderListMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GameProviderMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GameProviderMsg.repeatedFields_, null);
};
goog.inherits(proto.GameProviderMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GameProviderMsg.displayName = 'proto.GameProviderMsg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GameProviderOrderMsg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GameProviderOrderMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GameProviderOrderMsg.displayName = 'proto.GameProviderOrderMsg';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GameProviderListMsg.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GameProviderListMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.GameProviderListMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GameProviderListMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderListMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerList: jspb.Message.toObjectList(msg.getProviderList(),
    proto.GameProviderMsg.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GameProviderListMsg}
 */
proto.GameProviderListMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GameProviderListMsg;
  return proto.GameProviderListMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GameProviderListMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GameProviderListMsg}
 */
proto.GameProviderListMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GameProviderMsg;
      reader.readMessage(value,proto.GameProviderMsg.deserializeBinaryFromReader);
      msg.addProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GameProviderListMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GameProviderListMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GameProviderListMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderListMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GameProviderMsg.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GameProviderMsg provider = 1;
 * @return {!Array<!proto.GameProviderMsg>}
 */
proto.GameProviderListMsg.prototype.getProviderList = function() {
  return /** @type{!Array<!proto.GameProviderMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GameProviderMsg, 1));
};


/**
 * @param {!Array<!proto.GameProviderMsg>} value
 * @return {!proto.GameProviderListMsg} returns this
*/
proto.GameProviderListMsg.prototype.setProviderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GameProviderMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GameProviderMsg}
 */
proto.GameProviderListMsg.prototype.addProvider = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GameProviderMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GameProviderListMsg} returns this
 */
proto.GameProviderListMsg.prototype.clearProviderList = function() {
  return this.setProviderList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GameProviderMsg.repeatedFields_ = [5,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GameProviderMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.GameProviderMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GameProviderMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    image: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderList: jspb.Message.toObjectList(msg.getOrderList(),
    proto.GameProviderOrderMsg.toObject, includeInstance),
    restrictedcountryList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    restrictedcurrencyList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GameProviderMsg}
 */
proto.GameProviderMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GameProviderMsg;
  return proto.GameProviderMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GameProviderMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GameProviderMsg}
 */
proto.GameProviderMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setImage(value);
      break;
    case 5:
      var value = new proto.GameProviderOrderMsg;
      reader.readMessage(value,proto.GameProviderOrderMsg.deserializeBinaryFromReader);
      msg.addOrder(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRestrictedcountry(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRestrictedcurrency(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GameProviderMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GameProviderMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GameProviderMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getImage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.GameProviderOrderMsg.serializeBinaryToWriter
    );
  }
  f = message.getRestrictedcountryList();
  if (f.length > 0) {
    writer.writePackedInt32(
      6,
      f
    );
  }
  f = message.getRestrictedcurrencyList();
  if (f.length > 0) {
    writer.writePackedInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.GameProviderMsg.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.GameProviderMsg.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.GameProviderMsg.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string image = 4;
 * @return {string}
 */
proto.GameProviderMsg.prototype.getImage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setImage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated GameProviderOrderMsg order = 5;
 * @return {!Array<!proto.GameProviderOrderMsg>}
 */
proto.GameProviderMsg.prototype.getOrderList = function() {
  return /** @type{!Array<!proto.GameProviderOrderMsg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GameProviderOrderMsg, 5));
};


/**
 * @param {!Array<!proto.GameProviderOrderMsg>} value
 * @return {!proto.GameProviderMsg} returns this
*/
proto.GameProviderMsg.prototype.setOrderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.GameProviderOrderMsg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GameProviderOrderMsg}
 */
proto.GameProviderMsg.prototype.addOrder = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.GameProviderOrderMsg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.clearOrderList = function() {
  return this.setOrderList([]);
};


/**
 * repeated int32 restrictedCountry = 6;
 * @return {!Array<number>}
 */
proto.GameProviderMsg.prototype.getRestrictedcountryList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setRestrictedcountryList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.addRestrictedcountry = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.clearRestrictedcountryList = function() {
  return this.setRestrictedcountryList([]);
};


/**
 * repeated int32 restrictedCurrency = 7;
 * @return {!Array<number>}
 */
proto.GameProviderMsg.prototype.getRestrictedcurrencyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.setRestrictedcurrencyList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.addRestrictedcurrency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GameProviderMsg} returns this
 */
proto.GameProviderMsg.prototype.clearRestrictedcurrencyList = function() {
  return this.setRestrictedcurrencyList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GameProviderOrderMsg.prototype.toObject = function(opt_includeInstance) {
  return proto.GameProviderOrderMsg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GameProviderOrderMsg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderOrderMsg.toObject = function(includeInstance, msg) {
  var f, obj = {
    brandid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GameProviderOrderMsg}
 */
proto.GameProviderOrderMsg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GameProviderOrderMsg;
  return proto.GameProviderOrderMsg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GameProviderOrderMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GameProviderOrderMsg}
 */
proto.GameProviderOrderMsg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBrandid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GameProviderOrderMsg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GameProviderOrderMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GameProviderOrderMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GameProviderOrderMsg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBrandid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 brandId = 1;
 * @return {number}
 */
proto.GameProviderOrderMsg.prototype.getBrandid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GameProviderOrderMsg} returns this
 */
proto.GameProviderOrderMsg.prototype.setBrandid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.GameProviderOrderMsg.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GameProviderOrderMsg} returns this
 */
proto.GameProviderOrderMsg.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto);
