import {
    EGameListResolverType, GameListRebuilder,
    GameListResolver, GameListUpdater
} from "@sportaq/vuex/modules/games/internal/game-list-resolvers/game-list-resolver";
import { CasinoGame } from "@sportaq/model/games/casino-game";
import { GameViewFilter } from "@sportaq/vuex/modules/games/game-view-filter/GameViewFilter";
import { comparator } from "@sportaq/common/types/functions";
import { SortedIndexedArray } from "@sportaq/common/utils/sorted-data-types/sorted-indexed-array";
import { GameSortInfo } from "@sportaq/vuex/modules/games/game-sort-info/game-sort-info";

export class PlainGameListResolver implements GameListResolver {
    readonly type = EGameListResolverType.PLAIN;

    get batchRebuilder (): GameListRebuilder {
        return new InternalGameListRebuilder();
    }

    get batchUpdater (): GameListUpdater {
        return new InternalGameListUpdater();
    }
}

class InternalGameListRebuilder implements GameListRebuilder {
    private games!: SortedIndexedArray<GameSortInfo, string>;

    begin (): void {
        this.games = new SortedIndexedArray<GameSortInfo, string>(comparator);
    }

    addEvent (filter: GameViewFilter, game: CasinoGame, favouriteMode: boolean, restrictedProviderIds: number[], restrictedGameIds: string[]): boolean {
        const accept = filter.tryAccept(game, favouriteMode, restrictedProviderIds, restrictedGameIds);
        if (accept) {
            this.games.push(new GameSortInfo(game));
        }
        return accept;
    }

    end (): SortedIndexedArray<GameSortInfo, string> {
        return this.games;
    }
}

class InternalGameListUpdater implements GameListUpdater {
    private games!: SortedIndexedArray<GameSortInfo, string>;

    begin (games: SortedIndexedArray<GameSortInfo, string>): void {
        this.games = games.clone();
    }

    addOrUpdateEvent (filter: GameViewFilter, game: CasinoGame, favouriteMode: boolean, restrictedProviderIds: number[], restrictedGameIds: string[]): boolean {
        const accept = filter.tryAccept(game, favouriteMode, restrictedProviderIds, restrictedGameIds);
        const existed = this.games.get(game.id);
        if (accept && existed) {
            this.games.replace(new GameSortInfo(game));
        } else if (accept && !existed) {
            this.games.push(new GameSortInfo(game));
        } else if (!accept && existed) {
            this.games.remove(game.id);
        }
        return accept;
    }

    delete (gameId: string): void {
        this.games.remove(gameId);
    }

    end (): SortedIndexedArray<GameSortInfo, string> {
        return this.games;
    }
}
