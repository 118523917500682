import { Participant } from "@sportaq/model/betting/events/event";
import { PropType } from "vue";
import { QuotationWrapper } from "@sportaq/model/betting/events/quotation";

import {
    ScoreboardLineQuotationComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/common-scoreboard-line-quotation-component";

export default {
    props: {
        source: {
            required: false,
            type: Object as PropType<QuotationWrapper>
        },
        participants: {
            required: true,
            type: Object as PropType<Participant[]>
        },
        eventBlocked: {
            required: true,
            type: Boolean
        }
    },
    setup (props: ScoreboardLineQuotationComponent.Props) {
        const {
            diffsClass,
            isLocked,
            isSelected,
            quotationPresent,
            selectQuotation,
            quotationFormattedCoef
        } = ScoreboardLineQuotationComponent.useScoreboardLineQuotationComponent(props);

        return {
            diffsClass,

            quotationPresent,
            isSelected,
            isLocked,
            quotationFormattedCoef,
            selectQuotation
        };
    }
};
