import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import { computed, watch } from "vue";
import { useRouter } from "vue-router";

export function useAuthChecker (appStarted: ReturnType<typeof computed>) {
    const store = useWebStore();
    const router = useRouter();
    function checkAuth () {
        if (!appStarted.value) {
            return;
        }
        if (router.currentRoute.value.meta.requireAuth && store.user === undefined) {
            router.push({ name: "home" }).then();
        }
    }

    watch(router.currentRoute, checkAuth);
    watch(appStarted, checkAuth);
}