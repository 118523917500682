import { isCypressTest } from "@sportaq/vue/utils/CypressUtil";
import Cookies from "js-cookie";

export class PromoIntegration {
    constructor () {
        const cid = this.findGetParameter("idCliente");
        if (cid) {
            Cookies.set("idCliente", cid, { expires: 7, path: "/" });
        }
        const origin = this.findGetParameter("o");
        if (origin) {
            Cookies.set("referral_system", origin, { expires: 7, path: "/" });
        }
        let referralCode = this.findGetParameter("r");
        if (!referralCode) {
            referralCode = this.findGetParameter("ref");
        }
        if (referralCode) {
            Cookies.set("referral_code", referralCode, { expires: 7, path: "/" });
        }
    }

    findGetParameter (parameterName: string): string | undefined {
        let result;
        let tmp = [];
        const items = location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    public sendRegistration () {
        const cid = Cookies.get("idCliente");
        if (!isCypressTest() && cid !== undefined) {
            const url = "https://go.tubetgol.com/postback?cet=Registro&cid=";
            fetch(url + cid).then();
        }
    }
}

export const bemobInstance = new PromoIntegration();

export function trackEventFacebook (eventName: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof fbq !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fbq("track", eventName);
    }
}
