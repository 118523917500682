import { computed, PropType } from "vue";
import { CategoryPeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/category-period-sort-info";
import { Participant } from "@sportaq/model/src/betting/events/event";
import { countries } from "@sportaq/model/consts/countries";
import { CountryKey } from "@sportaq/model/types/types";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";

interface Props {
    source: CategoryPeriodSortInfo;
    participants: Participant[];
    eventBlocked: boolean;
}

export default {
    props: {
        source: {
            required: true,
            type: Object as PropType<CategoryPeriodSortInfo>
        }
    },
    setup (props: Props) {
        const scoreboardStore = useScoreboardStore();
        const flag = computed(() => countries.getFlag(props.source.countryId));
        const isFavorite = computed(() => {
            const source = props.source;
            const countryKey = new CountryKey(source.eventType, source.sportType, source.countryId);
            const partitionId = { id: -source.id };
            return scoreboardStore.isPartitionFavourite(countryKey, partitionId);
        });

        function togglePartitionFavorite () {
            const source = props.source;
            const countryKey = new CountryKey(source.eventType, source.sportType, source.countryId);
            const partitionId = { id: -source.id };
            scoreboardStore.toggleFavouritePartition(countryKey, partitionId);
        }
        return {
            flag,
            isFavorite,
            togglePartitionFavorite
        };
    }
};
