import { useOperationListCommon } from "@sportaq/vue/views/web/history/operation-list-common";
import { computed, PropType } from "vue";
import DateFilter from "@/views/stakes-view/date-filter/DateFilter.vue";

export default {
    name: "OperationList",
    props: {
        operations: {
            type: Array as PropType<string[]>,
            default: () => []
        }
    },
    components: {
        DateFilter
    },
    setup (props: {readonly operations: string[]}) {
        const {
            operationsPaged,
            itemsPerPageRef,
            operationsRef,
            currentPage,
            requestPending,
            itemsPerPageChanged,
            currentPageChanged,
            dateChanged
        } = useOperationListCommon(props.operations);
        const totalPages = computed(() => Math.ceil(operationsRef.value.length / itemsPerPageRef.value));
        return {
            operationsPaged,
            itemsPerPageRef,
            operationsRef,
            currentPage,
            requestPending,
            totalPages,
            itemsPerPageChanged,
            currentPageChanged,
            dateChanged
        };
    }
};
