import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import { ROUTES } from "@/router/routes-consts";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";
import { useHomeViewCommon } from "@sportaq/vue/views/web/home/home-view-common";
import { EGameTabs } from "@sportaq/vue/components/games/common/top-menu/top-menu-functions";
import { computed, ref } from "vue";

import MobileScoreboardDetails
    from "@/components/betting/betting-scoreboard/scoreboard-details/MobileScoreboardDetails.vue";
import HomePageWidgets from "@sportaq/vue/components/web/home-page/HomePageWidgets.vue";
import { UNSELECTED } from "@sportaq/model/betting/view/scoreboard-selection";
import { useRoute } from "vue-router";
import { useScoreboardStore } from "@sportaq/vuex/modules/betting/scoreboard/scoreboard-module";

export default {
    name: "HomeView",
    components: {
        HomePageWidgets,
        MobileScoreboardDetails,
        Flicking
    },
    setup () {
        const route = useRoute();
        const scoreboardStore = useScoreboardStore();

        const { banners } = useHomeViewCommon();

        const hidePage = ref(false);

        const selection = computed(() => scoreboardStore.getScoreboardSelection());
        const showDetails = computed(() => scoreboardStore.getScoreboardSelection().positionId > 0);

        function detailsShowed () {
            hidePage.value = true;
        }

        function closeLine () {
            scoreboardStore.setScoreboardSelection(route, UNSELECTED);
            hidePage.value = false;
        }

        return {
            hidePage,
            banners,
            COMMON_ROUTES,
            ROUTES,
            EGameTabs,
            plugins: [
                new AutoPlay({
                    duration: 5000,
                    stopOnHover: true
                })
            ],
            selection,
            showDetails,
            detailsShowed,
            closeLine
        };
    }
};
