import { AbstractMessageHandler } from "@sportaq/services/rest/messages/message-handler";
import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import {
    getAttribute,
    getRequiredAttribute,
    getRequiredChild, getRequiredFloatAttribute, getRequiredIntAttribute
} from "@sportaq/common/utils/xml-helper-functions";
import { LocalizedError } from "@sportaq/common/exceptions/localized-errors";
import { Bonus } from "@sportaq/model/web/bonus";
import { fromBinary } from "@sportaq/common/utils/string-utils";
import { toLocalTime } from "@sportaq/common/utils/time-utils";
import { ESupportedLocale } from "@sportaq/i18n/interfaces/interfaces";
import { countries } from "@sportaq/model/consts/countries";

export class QBn11BonusList extends AbstractMessageHandler<Bonus[]> {
    protected readonly requestType: string = "Q.BN.1.1";

    constructor (readonly countryId: number) {
        super();
    }

    buildRequest (request: XmlRequest) {
        request.addChild(request.body, "query", {
            type: this.requestType
        });
        const countryCode = countries.getById(this.countryId)?.alpha2 ?? "";
        request.addChildWithValue(request.head, "country", countryCode);
    }

    parseText (element: Element, attr: string, isEncoded: boolean): Map<string, string> {
        const result = new Map();
        for (const lang of Object.values(ESupportedLocale)) {
            const value = getAttribute(element, attr + lang);
            result.set(lang, isEncoded && value ? fromBinary(value) : value);
        }
        return result;
    }

    // eslint-disable-next-line
    parseMessageBody (body: Element, _head: Element): Bonus[] {
        const action = getRequiredChild(body, "query");
        const serverCode = getRequiredAttribute(action, "servercode");
        if (serverCode === "4600") {
            const bonusListElement = getRequiredChild(action, "BonusList");
            const bonusList: Bonus[] = [];
            for (let bonusElement = bonusListElement.firstElementChild; bonusElement != null; bonusElement = bonusElement.nextElementSibling) {
                const endTime = getAttribute(bonusElement, "endTime");
                const bonusTypeId = getRequiredIntAttribute(bonusElement, "bonusTypeId");
                bonusList.push({
                    bonusId: getRequiredIntAttribute(bonusElement, "bonusId"),
                    canUse: getRequiredIntAttribute(bonusElement, "canUse") === 1,
                    countBeat: getRequiredIntAttribute(bonusElement, "countBeat"),
                    description: this.parseText(bonusElement, "description_", true),
                    title: this.parseText(bonusElement, "title_", true),
                    name: this.parseText(bonusElement, "name_", false),
                    img: getRequiredAttribute(bonusElement, "img"),
                    isSubscribe: getRequiredIntAttribute(bonusElement, "isSubscribe"),
                    expirationTime: getAttribute(bonusElement, "expirationTime")
                        ? getRequiredIntAttribute(bonusElement, "expirationTime")
                        : undefined,
                    initTime: toLocalTime(new Date(getRequiredAttribute(bonusElement, "initTime"))),
                    endTime: endTime && endTime !== "0000-00-00 00:00:00"
                        ? toLocalTime(new Date(getRequiredAttribute(bonusElement, "endTime")))
                        : this.makeEndTime(bonusElement),
                    sumBonus: bonusElement.hasAttribute("sumBonus")
                        ? getRequiredFloatAttribute(bonusElement, "sumBonus")
                        : undefined,
                    sumStake: bonusElement.hasAttribute("sumStake")
                        ? getRequiredIntAttribute(bonusElement, "sumStake")
                        : undefined,
                    sumWithdrawal: getRequiredFloatAttribute(bonusElement, "sumWithdrawal"),
                    status: getRequiredIntAttribute(bonusElement, "status"),
                    bonusTypeId,
                    miniImg: getRequiredAttribute(bonusElement, "miniimg"),
                    isConditionsOpen: false,
                    freeSpin: bonusTypeId === 9 ? getRequiredIntAttribute(bonusElement, "freespin") : 0,
                    gameMark: bonusTypeId === 9 ? getRequiredAttribute(bonusElement, "game_mark") : ""
                });
            }
            return bonusList;
        }
        throw new LocalizedError(`errors.mtl.qBn11.code${serverCode}`);
    }

    private makeEndTime (bonusElement: Element): Date | undefined {
        if (!getRequiredAttribute(bonusElement, "expirationTime")) {
            return undefined;
        }
        const result = toLocalTime(new Date(getRequiredAttribute(bonusElement, "initTime")));
        const resultSeconds = result.getSeconds() + getRequiredIntAttribute(bonusElement, "expirationTime");
        result.setSeconds(resultSeconds);
        return result;
    }
}
