import MobileSportTypeFilter
    from "@/components/betting/betting-top-panel/mobile-sport-type-filter/MobileSportTypeFilter.vue";
import { useBettingView } from "@sportaq/vue/views/betting/betting-layout/betting-view-layout-common";
import LoginPopupDialog from "@/components/dialogs/login-popup-dialog/LoginPopupDialog.vue";
import ResetPasswordDialog from "@/components/dialogs/reset-password-dialog/ResetPasswordDialog.vue";
import MobileBettingNavigationBar from "@/components/betting/betting-navigation-bar/MobileBettingNavigationBar.vue";
import { onMounted } from "vue";
import { trackEventFacebook } from "@sportaq/vue/views/web/external/promo-integration";
import { useRoute } from "vue-router";
import { useBettingPageTitle } from "@sportaq/vue/components/web/betting-page-title";

export default {
    components: {
        MobileSportTypeFilter,
        LoginPopupDialog,
        ResetPasswordDialog,
        MobileBettingNavigationBar
    },
    setup () {
        const route = useRoute();
        const { updateTitle } = useBettingPageTitle();
        useBettingView();
        onMounted(() => {
            updateTitle(route.params.eventType.toString(), route.params.sportType.toString());
            trackEventFacebook("viewcontent" + route.params.eventType as string);
        });
    }
};
