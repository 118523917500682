import { register } from "register-service-worker";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig, setFirebaseAnalytics } from "@/firebase-config";

declare global {
    interface Window {
        AnalyticsWebInterface: unknown,
        webkit: {
            messageHandlers: {
                firebase: unknown
            }
        }
    }
}

const isFirebaseInitialized = window.AnalyticsWebInterface || (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firebase);

// Initialize Firebase
const app = isFirebaseInitialized ? undefined : initializeApp(firebaseConfig);
if (app) {
    setFirebaseAnalytics(getAnalytics(app));
}

register("/service-worker.js", {
    registrationOptions: { scope: "./" },
    // eslint-disable-next-line
    ready (registration) {
        // console.lo("Service worker is active.");
        if (app) {
            requestPermission(registration);
        }
    },
    // eslint-disable-next-line
    registered (registration) {
        // console.lo("Service worker has been registered.");
    },
    // eslint-disable-next-line
    cached (registration) {
        // console.lo("Content has been cached for offline use.");
    },
    // eslint-disable-next-line
    updatefound (registration) {
        // console.lo("New content is downloading.");
    },
    updated (registration) {
        // console.lo("New content is available; please refresh.");
        if (registration.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
    },
    // eslint-disable-next-line
    offline () {
        // console.lo("No internet connection found. App is running in offline mode.");
    },
    // eslint-disable-next-line
    error (error) {
        console.error("Error during service worker registration:", error);
    }
});

// Initialize Firebase Cloud Messaging and get a reference to the service

function requestPermission (registration: ServiceWorkerRegistration) {
    if (typeof Notification === "undefined") {
        return;
    }
    Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
            const messaging = getMessaging(app);
            getToken(messaging, {
                vapidKey: "BJ8ijsCsgjaIkoeEEXKLJlV-LUP3un4-b9VmPbaEJvwib_7dTivzdCOh3h8J1GQq1JN2FJ0QtoHy0APMCU5TwhI",
                serviceWorkerRegistration: registration
            })
                .then((currentToken: string) => {
                    console.log("Token: " + currentToken);
                }).catch((err: Error) => {
                    console.log("An error occurred while retrieving token. ", err);
                // ...
                });
        }
    });
}
