import EmailRegistration from "@/views/registration-view/RegistrationView.vue";
import { useLandingCommon } from "@sportaq/vue/views/web/landings/landing-common";
export default {
    components: {
        EmailRegistration
    },
    name: "LandingDeportes",
    setup () {
        const {
            isDevelopment,
            initialized
        } = useLandingCommon(["/landings/landing-deportes/css/style.css?v=2", "/landings/landing-deportes/css/fire.css"]);
        return {
            isDevelopment,
            initialized
        };
    }
};
