import {
    GAP_QUOTATION,
    isQuotationPresent,
    Quotation,
    QuotationWrapper
} from "@sportaq/model/betting/events/quotation";
import { ESportType } from "@sportaq/common/enums/sport-type";
import { HashMap } from "@sportaq/common/types/classes/hashmap";
import { BigDecimal } from "@sportaq/common/types/classes/bigdecimal";
import { arrayContains } from "@sportaq/common/utils/arrays";
import { comparator } from "@sportaq/common/types/functions";
import { DecimalInfo, getBaseQuotationP1 } from "@sportaq/model/betting/events/pairing/pairing-utils";
import EventType from "@sportaq/common/enums/event-type";
import { QuotationRow } from "@sportaq/model/betting/view/event-details/event-details-info";
import { appLogger } from "@sportaq/common/utils/logger";

export function pairingHandicaps (eventType: EventType, positionId: number, eventId: number, sportType: ESportType, columns: QuotationWrapper[][], currentRow: QuotationRow) {
    if (columns.length === 2) {
        const left = columns[0].filter(value => isQuotationPresent(value.quotation));
        const right = columns[1].filter(value => isQuotationPresent(value.quotation));
        if ((left.length === 0) && (right.length === 0)) {
            return;
        }
        switch (eventType) {
            case EventType.PRE_MATCH: {
                switch (sportType) {
                    case ESportType.Basketball: {
                        coefEqualityParring(eventType, positionId, eventId, left, right, currentRow);
                        break;
                    }
                    case ESportType.Tennis:
                    case ESportType.Volleyball: {
                        deltaEqualityParring(eventType, positionId, eventId, sportType, left, right, currentRow);
                        break;
                    }
                    default: {
                        pointSymmetryParring(eventType, positionId, eventId, sportType, left, right, currentRow);
                        break;
                    }
                }
                break;
            }
            case EventType.LIVE: {
                pointSymmetryParring(eventType, positionId, eventId, sportType, left, right, currentRow);
                break;
            }
        }
    } else {
        appLogger.logger.warn(`Error recalculate quotation rows for event ${eventId} [position: ${positionId}, sportType: ${sportType}] detailing: handicaps column count doesn't equal 2`, undefined, columns);
    }
}

function pointSymmetryParring (eventType: EventType, positionId: number, eventId: number, sportType: ESportType, left: QuotationWrapper[], right: QuotationWrapper[], currentRow: QuotationRow) {
    const leftPointMap = new HashMap<BigDecimal, Quotation>();
    const rightPointMap = new HashMap<BigDecimal, Quotation>();
    const points: DecimalInfo[] = [];
    for (const item of left) {
        const p1 = item.quotation.key.p1;
        const isMain = item.quotation.key.po === BigDecimal.ZERO;
        leftPointMap.set(p1, item.quotation);
        points.push(new DecimalInfo(p1, isMain));
    }
    for (const item of right) {
        const p1 = item.quotation.key.p1;
        const isMain = item.quotation.key.po === BigDecimal.ZERO;
        rightPointMap.set(p1, item.quotation);
        const invertPoint = new DecimalInfo(p1.negate(), isMain);
        if (!arrayContains(points, invertPoint)) {
            points.push(invertPoint);
        }
    }
    points.sort(comparator);

    for (const point of points) {
        const invertPoint = point.value.negate();
        const q1 = leftPointMap.get(point.value) ?? GAP_QUOTATION;
        const q2 = rightPointMap.get(invertPoint) ?? GAP_QUOTATION;
        if (isQuotationPresent(q1) || isQuotationPresent(q2)) {
            currentRow.quotations.push([{
                eventType,
                quotation: q1
            }, {
                eventType,
                quotation: q2
            }]);
        }
    }
}

function coefEqualityParring (eventType: EventType, positionId: number, eventId: number, left: QuotationWrapper[], right: QuotationWrapper[], currentRow: QuotationRow) {
    const leftPointMap = new HashMap<BigDecimal, Quotation>();
    const rightPointMap = new HashMap<BigDecimal, Quotation>();
    const coefs: DecimalInfo[] = [];
    for (const item of left) {
        const isMain = item.quotation.key.po === BigDecimal.ZERO;
        leftPointMap.set(item.quotation.coef, item.quotation);
        coefs.push(new DecimalInfo(item.quotation.coef, isMain));
    }
    for (const item of right) {
        const isMain = item.quotation.key.po === BigDecimal.ZERO;
        rightPointMap.set(item.quotation.coef, item.quotation);
        const coef = new DecimalInfo(item.quotation.coef, isMain);
        if (!arrayContains(coefs, coef)) {
            coefs.push(coef);
        }
    }
    coefs.sort((a, b) => {
        const result = (b.isMain ? 1 : 0) - (a.isMain ? 1 : 0);
        if (result) {
            return result;
        }
        return -a.value.compare(b.value);
    });

    for (const coef of coefs) {
        const q1 = leftPointMap.get(coef.value) ?? GAP_QUOTATION;
        const q2 = rightPointMap.get(coef.value) ?? GAP_QUOTATION;
        if (isQuotationPresent(q1) || isQuotationPresent(q2)) {
            currentRow.quotations.push([{
                eventType,
                quotation: q1
            }, {
                eventType,
                quotation: q2
            }]);
        }
    }
}

function deltaEqualityParring (eventType: EventType, positionId: number, eventId: number, sportType: ESportType, left: QuotationWrapper[], right: QuotationWrapper[], currentRow: QuotationRow) {
    try {
        const leftBaseQuotationP1 = getBaseQuotationP1(eventId, left);
        const rightBaseQuotationP1 = getBaseQuotationP1(eventId, right);

        const leftPointMap = new HashMap<BigDecimal, Quotation>();
        const rightPointMap = new HashMap<BigDecimal, Quotation>();
        const deltas: DecimalInfo[] = [];
        for (const item of left) {
            const p1 = item.quotation.key.p1;
            const isMain = item.quotation.key.po === BigDecimal.ZERO;
            const delta = leftBaseQuotationP1.subtract(p1);
            leftPointMap.set(delta, item.quotation);
            deltas.push(new DecimalInfo(delta, isMain));
        }
        for (const item of right) {
            const p1 = item.quotation.key.p1;
            const isMain = item.quotation.key.po === BigDecimal.ZERO;
            const delta = rightBaseQuotationP1.subtract(p1);
            rightPointMap.set(delta, item.quotation);
            const info = new DecimalInfo(delta, isMain);
            if (!arrayContains(deltas, info)) {
                deltas.push(info);
            }
        }
        deltas.sort((a, b) => {
            const result = (b.isMain ? 1 : 0) - (a.isMain ? 1 : 0);
            if (result) {
                return result;
            }
            return -a.value.compare(b.value);
        });

        for (const delta of deltas) {
            const q1 = leftPointMap.get(delta.value) ?? GAP_QUOTATION;
            const q2 = rightPointMap.get(delta.value) ?? GAP_QUOTATION;
            if (isQuotationPresent(q1) || isQuotationPresent(q2)) {
                currentRow.quotations.push([{
                    eventType,
                    quotation: q1
                }, {
                    eventType,
                    quotation: q2
                }]);
            }
        }
    } catch (e) {
        appLogger.logger.error(`Error parring handicap quotation for event ${eventId} [position: ${positionId}, sportType: ${sportType}] due to error`, e as Error, true);
    }
}
