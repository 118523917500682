<template>
    <div class="preview-frame" data-cy="preview-frame">
        <button @click.prevent="close" class="frame-close" data-cy="close-btn"/>
        <img :src="game.image" alt="" class="preview-frame-image"/>
        <div class="preview-frame-title">
            {{ game.title }}
        </div>
        <div class="preview-frame-provider">
            {{ provider }}
        </div>
        <div class="preview-frame-actions">
            <button data-cy="start-game-btn" @click="startGame" class="button button-general">
                {{ $t("common.games.buttons.play") }}
            </button>
            <button data-cy="start-demo-btn" @click="startDemo" v-if="showDemoSelector"  class="button button-primary">
                {{ $t("common.games.buttons.demo") }}
            </button>
        </div>
    </div>
</template>

<script src="./game-preview-component.ts"/>
