<template>
    <div class="filters" :class="{ 'filter-menu-active': isFilterActive}">
        <transition name="filter-menu-animation">
            <betting-filter-menu v-if="isFilterActive" :closeOnClickOutside="true"/>
        </transition>
        <transition name="filter-menu-animation">
            <mobile-event-search-dialog v-if="isSearchDialogOpened" @close="closeSearchDialog"/>
        </transition>
        <div class="filters-inner">
            <div class="item" :class="{active: favouriteMode, disabled: favouriteCount === 0}"
                 data-cy="navigation-favorites"
                 @click.stop="toggleFavouriteMode">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-favourites.svg`)" alt="">
                <span class="count" data-cy="count">{{ favouriteCount }}</span>
            </div>
            <div class="item" :class="{active: isPopular}" @click="togglePopularMode" v-if="showPopular"
                 data-cy="navigation-sidebar-popular">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-popular.svg`)" alt="">
            </div>
            <div class="item" :class="{active: leagueMode}" @click="toggleLeagueMode" v-if="showLeague"
                 data-cy="navigation-sidebar-league">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-cup.svg`)" alt="">
            </div>
            <div class="item" :class="{active: liveReverseTimeSorting}" v-if="showReverseTimeSorting"
                 @click="toggleReverseTimeSorting"
                 data-cy="navigation-sidebar-reverse-time-sorting">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-time.svg`)" alt="">
            </div>
            <div class="item" @click="openSearchDialog" data-cy="search-dialog-btn">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-loop-plus.svg`)" alt=""/>
            </div>
            <!-- Settings with country filter -->
            <div class="item" :class="{active: isFilterActive}" @click="toggleFilterVisible" v-if="isFilterVisible"
                 data-cy="filter-menu-btn">
                <img :src="require(`@assets/img/mobile/${imageThemePath}/icon-additional-filters.svg`)" alt=""/>
            </div>
        </div>
    </div>
</template>

<script src="./mobile-betting-navigation-bar-component.ts"/>
