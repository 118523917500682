import { Bonus, getBonusName } from "@sportaq/model/web/bonus";
import { useWebRestService } from "@sportaq/services/web";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useWebStore } from "@sportaq/vuex/modules/web/web-module";
import Cookies from "js-cookie";
import { EUserRole, hasRole } from "@sportaq/model/common/user-accounts";
import { useRootStore } from "@sportaq/vuex/index";
import { resolveLanguage } from "@sportaq/i18n/index";
import { useRouter } from "vue-router";
import { COMMON_ROUTES } from "@sportaq/common/consts/common-routes";

export function usePromoViewCommon () {
    const bonusList = ref<Bonus[]>([]);
    const restService = useWebRestService();
    const i18n = useI18n();
    const rootStore = useRootStore();
    const store = useWebStore();
    const router = useRouter();

    const bonusToCancel = ref<number>();
    const bonusUsed = ref(false);
    const isLoggedIn = computed(() => store.user !== undefined);
    const isLoginDialogVisible = ref(false);
    const isResetPasswordDialogVisible = ref(false);
    const isNotificationVisible = ref(false);

    watch(isLoggedIn, refreshBonusList);
    const isExpressUser = computed(() => {
        if (!store.user) {
            return false;
        }

        return hasRole(store.user, EUserRole.EXPRESS);
    });

    const lang = computed(() => {
        return i18n.locale.value.toString();
    });

    function onLoginClose () {
        isLoginDialogVisible.value = false;
    }

    function subscribe (bonus: Bonus) {
        if (!isLoggedIn.value) {
            isLoginDialogVisible.value = true;
            Cookies.set("selectedBonus", bonus.bonusId.toString());
            return;
        }
        if (bonus.isSubscribe) {
            restService.cancelBonusSubscription(bonus.bonusId).then(refreshBonusList);
        } else {
            restService.subscribeBonus(bonus.bonusId).then(refreshBonusList);
        }
    }

    function useBonus (bonusId: number) {
        restService.useBonus(bonusId).then(refreshBonusList);
    }

    function cancelBonus (bonusId: number) {
        bonusToCancel.value = bonusId;
    }

    function cancelBonusConfirmed () {
        if (bonusToCancel.value) {
            const bonusId = bonusToCancel.value;
            bonusToCancel.value = undefined;
            restService.cancelBonus(bonusId).then(refreshBonusList);
        }
    }

    function cancelBonusConfirmationClosed () {
        bonusToCancel.value = undefined;
    }

    function refreshBonusList () {
        restService.getBonusList(rootStore.userSharedInfo.countryId)
            .then(b => {
                bonusList.value = b;
                let isUsed = false;
                let isSubscribed = false;
                for (const bonus of b) {
                    if (bonus.status > 0) {
                        isUsed = true;
                    }
                    if (bonus.isSubscribe === 1) {
                        isSubscribed = true;
                    }
                }
                if (!isUsed && isSubscribed) {
                    isNotificationVisible.value = true;
                }
                bonusUsed.value = isUsed;
            });
    }
    function openGame (gameMark: string) {
        router.push({ path: COMMON_ROUTES.CASINO.path + "/" + encodeURIComponent(gameMark) }).then();
    }
    onMounted(() => {
        refreshBonusList();
    });

    return {
        bonusList,
        i18n,
        lang,
        bonusUsed,
        bonusToCancel,
        isLoginDialogVisible,
        isResetPasswordDialogVisible,
        isLoggedIn,
        isNotificationVisible,
        isExpressUser,
        getBonusName,
        onLoginClose,
        useBonus,
        subscribe,
        cancelBonus,
        cancelBonusConfirmed,
        cancelBonusConfirmationClosed,
        isNaN: Number.isNaN,
        openGame
    };
}

export function useFreeSpinsCommon () {
    const lang = computed(() => resolveLanguage());
    return {
        lang
    };
}
