/* eslint quote-props: ["error", "always"] */
export default {
    "detailInfo": {
        "groups": {
            "americanFootball": {
                "evenOddMatch": "Even / odd",
                "evenOddMatchHalf1": "Even / odd 1st half",
                "evenOddMatchQuarter1": "Even / odd 1st Quarter",
                "evenOddMatchQuarter2": "Even / odd 2nd Quarter",
                "evenOddMatchQuarter3": "Even / odd 3rd Quarter",
                "evenOddMatchQuarter4": "Even / odd 4th Quarter",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1st half",
                "handicapHalf2": "Handicap 2nd half",
                "handicapQuarter1": "Handicap 1st Quarter",
                "handicapQuarter2": "Handicap 2nd Quarter",
                "handicapQuarter3": "Handicap 3rd Quarter",
                "handicapQuarter4": "Handicap 4th Quarter",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1st half",
                "individualTotalTeam1Half2": "Total {team1} 2nd half",
                "individualTotalTeam1Quarter1": "Total {team1} 1st Quarter",
                "individualTotalTeam1Quarter2": "Total {team1} 2nd Quarter",
                "individualTotalTeam1Quarter3": "Total {team1} 3rd Quarter",
                "individualTotalTeam1Quarter4": "Total {team1} 4th Quarter",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1st half",
                "individualTotalTeam2Half2": "Total {team2} 2nd half",
                "individualTotalTeam2Quarter1": "Total {team2} 1st Quarter",
                "individualTotalTeam2Quarter2": "Total {team2} 2nd Quarter",
                "individualTotalTeam2Quarter3": "Total {team2} 3rd Quarter",
                "individualTotalTeam2Quarter4": "Total {team2} 4th Quarter",
                "total": "Total",
                "totalHalf1": "Total 1st half",
                "totalHalf2": "Total 2nd half",
                "totalQuarter1": "Total 1st Quarter",
                "totalQuarter2": "Total 2nd Quarter",
                "totalQuarter3": "Total 3rd Quarter",
                "totalQuarter4": "Total 4th Quarter",
                "x1_2": "Mutch result",
                "x1x2Half1": "1st half result",
                "x1x2Half2": "2nd half result",
                "x1x2Quarter1": "1st Quarter result",
                "x1x2Quarter2": "2nd Quarter result",
                "x1x2Quarter3": "3rd Quarter result",
                "x1x2Quarter4": "4th Quarter result"
            },
            "baseball": {
                "evenOddMatch": "Even / odd",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1st half",
                "handicapInning1": "1st inning Handicap",
                "handicapInning2": "2nd inning Handicap",
                "handicapInning3": "3rd inning Handicap",
                "handicapInning4": "4th inning Handicap",
                "handicapInning5": "5th inning Handicap",
                "handicapInning6": "6th inning Handicap",
                "handicapInning7": "7th inning Handicap",
                "handicapInning8": "8th inning Handicap",
                "handicapInning9": "9th inning Handicap",
                "handicaps": "Handicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalHalf1": "Total 1st half",
                "totalInning1": "1st inning Total",
                "totalInning2": "2nd inning Total",
                "totalInning3": "3rd inning Total",
                "totalInning4": "4th inning Total",
                "totalInning5": "5th inning Total",
                "totalInning6": "6th inning Total",
                "totalInning7": "7th inning Total",
                "totalInning8": "8th inning Total",
                "totalInning9": "9th inning Total",
                "x1_2": "Match result",
                "x1x2Half1": "1st half result",
                "x1x2Inning1": "1st inning result",
                "x1x2Inning2": "2nd inning result",
                "x1x2Inning3": "3rd inning result",
                "x1x2Inning4": "4th inning result",
                "x1x2Inning5": "5th inning result",
                "x1x2Inning6": "6th inning result",
                "x1x2Inning7": "7th inning result",
                "x1x2Inning8": "8th inning result",
                "x1x2Inning9": "9th inning result"
            },
            "basketball": {
                "evenOddMatch": "Even / odd",
                "evenOddMatchHalf1": "1st half Even / odd",
                "evenOddMatchQuarter1": "Even / odd 1st Quarter",
                "evenOddMatchQuarter2": "Even / odd 2nd Quarter",
                "evenOddMatchQuarter3": "Even / odd 3rd Quarter",
                "evenOddMatchQuarter4": "Even / odd 4th Quarter",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1st half",
                "handicapHalf2": "Handicap 2nd half",
                "handicapQuarter1": "Handicap 1st Quarter",
                "handicapQuarter2": "Handicap 2nd Quarter",
                "handicapQuarter3": "Handicap 3rd Quarter",
                "handicapQuarter4": "Handicap 4th Quarter",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1st half",
                "individualTotalTeam1Half2": "Total {team1} 2nd half",
                "individualTotalTeam1Quarter1": "Total {team1} 1st Quarter",
                "individualTotalTeam1Quarter2": "Total {team1} 2nd Quarter",
                "individualTotalTeam1Quarter3": "Total {team1} 3rd Quarter",
                "individualTotalTeam1Quarter4": "Total {team1} 4th Quarter",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1st half",
                "individualTotalTeam2Half2": "Total {team2} 2nd half",
                "individualTotalTeam2Quarter1": "Total {team2} 1st Quarter",
                "individualTotalTeam2Quarter2": "Total {team2} 2nd Quarter",
                "individualTotalTeam2Quarter3": "Total {team2} 3rd Quarter",
                "individualTotalTeam2Quarter4": "Total {team2} 4th Quarter",
                "total": "Total",
                "totalHalf1": "Total 1st half",
                "totalHalf2": "Total 2nd half",
                "totalQuarter1": "Total 1st Quarter",
                "totalQuarter2": "Total 2nd Quarter",
                "totalQuarter3": "Total 3rd Quarter",
                "totalQuarter4": "Total 4th Quarter",
                "x1x2": "Mutch result",
                "x1x2Half1": "1st half result",
                "x1x2Half2": "2nd half result",
                "x1x2Quarter1": "1st Quarter result",
                "x1x2Quarter2": "2nd Quarter result",
                "x1x2Quarter3": "3rd Quarter result",
                "x1x2Quarter4": "4th Quarter result"
            },
            "football": {
                "12TotalUnder": "12 + Total Under ({p1})",
                "DrawTotalOver": "Draw + Total Over ({p1})",
                "DrawTotalUnder": "Draw + Total Under ({p1})",
                "atLeastOneWontScore12": "At Least One Team Not To Score + 12",
                "atLeastOneWontScore1X": "At Least One Team Not To Score + 1X",
                "atLeastOneWontScoreDraw": "At Least One Team Not To Score + Draw",
                "atLeastOneWontScoreTeam1": "At Least One Team Not To Score + {team1}",
                "atLeastOneWontScoreTeam2": "At Least One Team Not To Score + {team2}",
                "atLeastOneWontScoreTotalOver": "At Least One Team Not To Score + Total Over ({p1})",
                "atLeastOneWontScoreTotalUnder": "At Least One Team Not To Score + Total Under ({p1})",
                "atLeastOneWontScoreX2": "At Least One Team Not To Score + X2",
                "bothScoreTotalOver": "Both Teams To Score + Total Over ({p1})",
                "bothScoreTotalUnder": "Both Teams To Score + Total Under ({p1})",
                "dryWin": "To Win To Nil",
                "evenOddHalf1": "Even / odd 1st half",
                "evenOddMatch": "Even / odd",
                "firstGoalTeam": "First Goal",
                "goalBothHalves": "A goal in both halves",
                "goalHalf1": "Goal in 1st half",
                "goalHalf2": "Goal in 2nd half",
                "goalIntervalTeam1x16x30": "Goal {team1} 15:01 - 30:00 min.",
                "goalIntervalTeam1x1x15": "Goal {team1} 00:01 - 15:00 min.",
                "goalIntervalTeam1x1x30": "Goal {team1} 00:01 - 30:00 min.",
                "goalIntervalTeam1x1x45": "Goal {team1} 00:01 - 45:00 min.",
                "goalIntervalTeam1x1x60": "Goal {team1} 00:01 - 60:00 min.",
                "goalIntervalTeam1x1x75": "Goal {team1} 00:01 - 75:00 min.",
                "goalIntervalTeam1x31x45": "Goal {team1} 30:01 - 45:00 min.",
                "goalIntervalTeam1x46x60": "Goal {team1} 45:01 - 60:00 min.",
                "goalIntervalTeam1x61x75": "Goal {team1} 60:01 - 75:00 min.",
                "goalIntervalTeam1x76x90": "Goal {team1} 75:01 - 90:00 min.",
                "goalIntervalTeam2x16x30": "Goal {team2} 15:01 - 30:00 min.",
                "goalIntervalTeam2x1x15": "Goal {team2} 00:01 - 15:00 min.",
                "goalIntervalTeam2x1x30": "Goal {team2} 00:01 - 30:00 min.",
                "goalIntervalTeam2x1x45": "Goal {team2} 00:01 - 45:00 min.",
                "goalIntervalTeam2x1x60": "Gol {team2} 00:01 - 60:00 min.",
                "goalIntervalTeam2x1x75": "Goal {team2} 00:01 - 75:00 min.",
                "goalIntervalTeam2x31x45": "Goal {team2} 30:01 - 45:00 min.",
                "goalIntervalTeam2x46x60": "Goal {team2} 45:01 - 60:00 min.",
                "goalIntervalTeam2x61x75": "Goal {team2} 60:01 - 75:00 min.",
                "goalIntervalTeam2x76x90": "Goal {team2} 75:01 - 90:00 min.",
                "goalIntervalx16x30": "Goal 15:01 - 30:00 min.",
                "goalIntervalx1x15": "Goal 00:01 - 15:00 min.",
                "goalIntervalx1x30": "Goal 00:01 - 30:00 min.",
                "goalIntervalx1x45": "Goal 00:01 - 45:00 min.",
                "goalIntervalx1x60": "Goal 00:01 - 60:00 min.",
                "goalIntervalx1x75": "Goal 00:01 - 75:00 min.",
                "goalIntervalx31x45": "Gol 30:01 - 45:00 min.",
                "goalIntervalx46x60": "Gol 45:01 - 60:00 min.",
                "goalIntervalx61x75": "Goal 60:01 - 75:00 min.",
                "goalIntervalx76x90": "Gol 75:01 - 90:00 min.",
                "goalTeam1BothHalves": "{team1} to score in both halves",
                "goalTeam1Half1": "{team1} to score in the 1st half",
                "goalTeam1Half2": "{team1} to score in the 2nd half",
                "goalTeam2BothHalves": "{team2} to score in both halves",
                "goalTeam2Half1": "{team2} to score in the 1st half",
                "goalTeam2Half2": "{team2} to score in the 2nd half",
                "goalWillScore1": "1st goal will score",
                "goalWillScore10": "10th goal will score",
                "goalWillScore2": "2nd goal will score",
                "goalWillScore3": "3rd goal will score",
                "goalWillScore4": "4th goal will score",
                "goalWillScore5": "5th goal will score",
                "goalWillScore6": "6th goal will score",
                "goalWillScore7": "7th goal will score",
                "goalWillScore8": "8th goal will score",
                "goalWillScore9": "9th goal will score",
                "halfMatch": "First half/Match",
                "halvesComparison": "HT comparison",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1st half",
                "handicapHalf2": "Handicap 2nd half",
                "handicapMatch": "Handicap",
                "individualTotalTeam1": "{team1} Total Goals",
                "individualTotalTeam1Half1": "Total Goals {team1} 1st half",
                "individualTotalTeam1Half2": "Total Goals {team1} 2nd half",
                "individualTotalTeam2": "{team2} Total Goals",
                "individualTotalTeam2Half1": "Total Goals {team2} 1st half",
                "individualTotalTeam2Half2": "Total Goals {team2} 2nd half",
                "lastGoalTeam": "Last goal",
                "matchScore": "Correct Score",
                "passage": "To Advance",
                "scoreBoth": "Both to score",
                "scoreBoth12": "Both Teams To Score + 12",
                "scoreBoth1X": "Both Teams To Score + 1X",
                "scoreBothDraw": "Both Teams To Score + Draw",
                "scoreBothHalf1": "Both to score en 1st half",
                "scoreBothHalf2": "Both to score en 2nd half",
                "scoreBothTeam1": "Both Teams To Score + {team1}",
                "scoreBothTeam2": "Both Teams To Score + {team2}",
                "scoreBothX2": "Both Teams To Score + X2",
                "scoreTeam1": "{team1} To Score",
                "scoreTeam2": "{team2} To Score",
                "strongWilledWin": "To Win From Behind",
                "team1Goal112": "First Goal -{team1} + 12",
                "team1Goal11X": "First Goal - {team1} + 1X",
                "team1Goal1Draw": "First Goal - {team1} + Draw",
                "team1Goal1Team1": "First Goal - {team1} + {team1}",
                "team1Goal1Team2": "First Goal - {team1} + {team2}",
                "team1Goal1X2": "First Goal -{team1} + X2",
                "team1TotalOver": "{team1} + Total Over ({p1})",
                "team1TotalUnder": "{team1} + Total Under ({p1})",
                "team2Goal112": "First Goal - {team2} + 12",
                "team2Goal11X": "First Goal - {team2} + 1X",
                "team2Goal1Draw": "First Goal - {team2} + Draw",
                "team2Goal1Team1": "First Goal -{team2} + {team1}",
                "team2Goal1Team2": "First Goal - {team2} + {team2}",
                "team2Goal1X2": "First Goal - {team2} + X2",
                "team2TotalOver": "{team2} + Total Over ({p1})",
                "team2TotalUnder": "{team2} + Total Under ({p1})",
                "total": "Total",
                "totalBalls": "Goal total",
                "totalHalf1": "1st half Total",
                "totalHalf2": "2nd half Total",
                "winBothHalves": "To win both halves",
                "x12TotalOver": "12 + Total Over ({p1})",
                "x1TotalOver": "1X + Total Over ({p1})",
                "x1TotalUnder": "1X + Total Under ({p1})",
                "x1x12x2": "Double chance",
                "x1x12x2Half1": "Double chance 1st half",
                "x1x12x2Half2": "Double chance 2nd half",
                "x1x2": "Match result",
                "x1x2fHalf1": "1st half result",
                "x1x2fHalf2": "2nd half result",
                "x2TotalOver": "X2 + Total Over ({p1})",
                "x2TotalUnder": "X2 + Total Under ({p1})"
            },
            "handball": {
                "evenOddHalf1": "Even / odd 1st half",
                "evenOddMatch": "Even / odd",
                "handicap": "Handicap",
                "handicapHalf1": "Handicap 1st half",
                "handicapHalf2": "Handicap 2nd half",
                "handicapMatch": "Handicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam1Half1": "Total {team1} 1st half",
                "individualTotalTeam1Half2": "Total {team1} 2nd half",
                "individualTotalTeam2": "Total {team2}",
                "individualTotalTeam2Half1": "Total {team2} 1st half",
                "individualTotalTeam2Half2": "Total {team2} 2nd half",
                "passage": "To Advance",
                "total": "Total",
                "totalHalf1": "Total 1st half",
                "totalHalf2": "Total 2nd half",
                "x1x12x2": "Double chance",
                "x1x12x2Half1": "Double chance 1st half",
                "x1x12x2Half2": "Double chance 2nd half",
                "x1x2": "Match result",
                "x1x2fHalf1": "1st half result",
                "x1x2fHalf2": "2nd half result"
            },
            "hockey": {
                "handicap": "Handicap",
                "handicapPeriod1": "1st period Handicap",
                "handicapPeriod2": "2nd period Handicap",
                "handicapPeriod3": "3rd period Handicap",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "total": "Total",
                "totalMatch": "Total",
                "totalPeriod1": "1st period Total",
                "totalPeriod2": "2nd period Total",
                "totalPeriod3": "3rd period Total",
                "x1x12x2": "Double chance",
                "x1x12x2Period1": "1st period Double chance",
                "x1x12x2Period2": "2nd period Double chance",
                "x1x12x2Period3": "3rd period Double chance",
                "x1x2": "Match result",
                "x1x2Period1": "1st period result",
                "x1x2Period2": "2nd period result",
                "x1x2Period3": "3rd period result"
            },
            "tableTennis": {
                "evenOddMatch": "Even / odd",
                "evenOddSet1": "Even / odd Set 1",
                "evenOddSet2": "Even / odd Set 2",
                "evenOddSet3": "Even / odd Set 3",
                "evenOddSet4": "Even / odd Set 4",
                "evenOddSet5": "Even / odd Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Final result",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Match result",
                "x1_2Set1": "Set 1 result",
                "x1_2Set2": "Set 2 result",
                "x1_2Set3": "Set 3 result",
                "x1_2Set4": "Set 4 result",
                "x1_2Set5": "Set 5 result"
            },
            "tennis": {
                "evenOddMatch": "Even / odd",
                "evenOddSet1": "Even / odd Set 1",
                "evenOddSet2": "Even / odd Set 2",
                "evenOddSet3": "Even / odd Set 3",
                "evenOddSet4": "Even / odd Set 4",
                "evenOddSet5": "Even / odd Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Set Betting",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Match result",
                "x1_2Set1": "Set 1 result",
                "x1_2Set2": "Set 2 result",
                "x1_2Set3": "Set 3 result",
                "x1_2Set4": "Set 4 result",
                "x1_2Set5": "Set 5 result"
            },
            "volleyball": {
                "evenOddMatch": "Even / odd",
                "evenOddSet1": "Even / odd Set 1",
                "evenOddSet2": "Even / odd Set 2",
                "evenOddSet3": "Even / odd Set 3",
                "evenOddSet4": "Even / odd Set 4",
                "evenOddSet5": "Even / odd Set 5",
                "handicap": "Handicap",
                "handicapSet1": "Handicap Set 1",
                "handicapSet2": "Handicap Set 2",
                "handicapSet3": "Handicap Set 3",
                "handicapSet4": "Handicap Set 4",
                "handicapSet5": "Handicap Set 5",
                "individualTotalTeam1": "Total {team1}",
                "individualTotalTeam2": "Total {team2}",
                "matchScore": "Set Betting",
                "scoreAfter2Games": "After 2 Sets Result",
                "total": "Total",
                "totalMatch": "Total",
                "totalSet1": "Total Set 1",
                "totalSet2": "Total Set 2",
                "totalSet3": "Total Set 3",
                "totalSet4": "Total Set 4",
                "totalSet5": "Total Set 5",
                "x1_2": "Match result",
                "x1_2Set1": "Set 1 result",
                "x1_2Set2": "Set 2 result",
                "x1_2Set3": "Set 3 result",
                "x1_2Set4": "Set 4 result",
                "x1_2Set5": "Set 5 result"
            }
        },
        "tabs": {
            "americanFootball": {
                "all": "All",
                "halves": "Halves",
                "handicaps": "Handicap",
                "popular": "Popular",
                "quarters": "Quarters",
                "totals": "Total"
            },
            "baseball": {
                "all": "All",
                "halves": "Halves",
                "handicap": "Handicap",
                "handicaps": "Handicap",
                "innings": "Innings",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "basketball": {
                "all": "All",
                "halves": "Halves",
                "handicaps": "Handicap",
                "popular": "Popular",
                "quarters": "Quarters",
                "totals": "Total"
            },
            "football": {
                "all": "All",
                "goals": "Gols",
                "goalsPlusResult": "To Score + Result",
                "goalsPlusTotal": "To Score + Total",
                "halves": "Halves",
                "handicaps": "Handicap",
                "popular": "Popular",
                "resultPlusTotal": "FT Result + Total",
                "totals": "Total"
            },
            "handball": {
                "all": "All",
                "half1": "1st Half",
                "half2": "2nd Half",
                "handicap": "Handicap",
                "handicaps": "Handicap",
                "popular": "Popular",
                "total": "Total",
                "totals": "Total"
            },
            "hockey": {
                "all": "All",
                "handicaps": "Handicap",
                "periods": "Periods",
                "popular": "Popular",
                "totals": "Total"
            },
            "tableTennis": {
                "all": "All",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "tennis": {
                "all": "All",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            },
            "volleyball": {
                "all": "All",
                "handicaps": "Handicap",
                "popular": "Popular",
                "sets": "Sets",
                "totals": "Total"
            }
        }
    }
};