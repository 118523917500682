import { XmlRequest } from "@sportaq/services/rest/utils/xml-request";
import { LocalizedError, UnsuccessfulRestResponse, XmlParserError } from "@sportaq/common/exceptions/localized-errors";
import { getRequiredChild, getValue } from "@sportaq/common/utils/xml-helper-functions";
import { appLogger } from "@sportaq/common/utils/logger";

export interface MessageHandler<T> {
    get mtlRequestType (): string;

    buildRequest (request: XmlRequest): void;

    parseResponse (response: string): Promise<T>;
}

export abstract class AbstractMessageHandler<T> implements MessageHandler<T> {
    protected abstract readonly requestType: string;

    abstract buildRequest (request: XmlRequest): void;

    protected abstract parseMessageBody (body: Element, head: Element): T;

    parseResponse (response: string): Promise<T> {
        return new Promise<T>((resolve) => {
            const parser = new DOMParser();
            const document = parser.parseFromString(response, "application/xml");
            const rootElement = document.documentElement;
            try {
                const {
                    head,
                    body
                } = AbstractMessageHandler.validateResponse(rootElement);
                const result = this.parseMessageBody(body, head);
                resolve(result);
            } catch (e) {
                let err = e as Error;
                if (err instanceof XmlParserError) {
                    const causeMsg = `Error parsing response for ${this.requestType}: ${err.message}. Response: [${response}]`;
                    err = new LocalizedError("errors.mtl.serverError", causeMsg);
                    appLogger.logger.error(`Error parsing response: ${causeMsg}`);
                }
                throw err;
            }
        });
    }

    private static validateResponse (root: Element) {
        if (root.nodeName === "parsererror") {
            throw new XmlParserError("invalid root node");
        }
        const head = getRequiredChild(root, "head");
        const status = getRequiredChild(head, "status");
        const statusCode = getValue(status);
        if (statusCode !== "100") {
            throw new UnsuccessfulRestResponse(statusCode);
        }
        return {
            head,
            body: getRequiredChild(root, "body")
        };
    }

    get mtlRequestType (): string {
        return this.requestType;
    }
}
