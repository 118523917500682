<template>
    <div :class="`details-panel-${sportName}`">
    <div class="details-head" v-if="true" :class="{live: isLive, longer: isBaseball}"
         :data-cy="`scoreboard-details-${event.positionId}-mobile`" @click="closeDetails">
        <div class="filter-menu-close" v-if="!standalone" data-cy="details-close-btn"/>
        <div class="subtitle" data-cy="partition">
            <div class="live" v-if="isLive">LIVE</div>
            {{ infoLabel }}
            <div v-if="isLive" class="date-live">
                {{ event.startTimeStr }}
            </div>
        </div>
        <div class="title">
            <div class="title-left">
                <div class="name" v-for="(participant, index) of participants" :key="participant.id"
                     :data-cy="`participant-${index+1}`">
                    {{ participant.name }}
                </div>
            </div>
            <div class="title-right" v-if="isLive">
                <div class="counts" v-for="(row, rowIndex) of score" :key="rowIndex"
                     :data-cy="`score-line-${rowIndex}`">
                    <div :class="['item', index===0 ? 'active' : '']" v-for="(item, index) of row" :key="index">
                        {{ item }}
                    </div>
                </div>
            </div>
        </div>
        <div class="date" data-cy="periodInfo">{{ periodInfo }}</div>
        <div class="info">
            <div :class="['star', isFavorite? 'active':'']" @click.stop="toggleFavorite" data-cy="favorite-star">
                <svg viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10 0.363037L12.2451 7.27287H19.5106L13.6327 11.5434L15.8779 18.4532L10 14.1827L4.12215 18.4532L6.36729 11.5434L0.489435 7.27287H7.75486L10 0.363037Z"
                        fill="#EA5D24"/>
                </svg>
            </div>
        </div>
    </div>
    <div class="details-head details-head-football" v-if="false" :class="{live: isLive, longer: isBaseball}"
         :data-cy="`scoreboard-details-${event.positionId}-mobile`" @click="closeDetails">
        <div class="details-head-football-tagline display-flex">
            <div class="subtitle">
                <div class="live" v-if="isLive">LIVE</div>
                <!--img :src="`/img/flags/${flag}.png`" alt="" class="flag"/-->
                <img :src="`/img/flags/jp.png`" alt="" class="flag"/>
                <span>
                    {{ infoLabel }}
                </span>
            </div>
            <div class="info display-flex">
                <div class="count">+{{ '99' }}</div>
                <div :class="['star', isFavorite? 'active':'']" @click.stop="toggleFavorite" data-cy="favorite-star">
                    <svg viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 0.363037L12.2451 7.27287H19.5106L13.6327 11.5434L15.8779 18.4532L10 14.1827L4.12215 18.4532L6.36729 11.5434L0.489435 7.27287H7.75486L10 0.363037Z"
                            fill="#EA5D24"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="title">
            <div class="filter-menu-close" v-if="!standalone" data-cy="details-close-btn"/>
            <div class="name" v-for="(participant, index) of participants" :key="participant.id" :data-cy="`participant-${index+1}`" :class="`name-${index+1}`">
                {{ participant.name }}
            </div>
            <div class="score">
                <div class="date" data-cy="periodInfo">{{ periodInfo }}</div>
                <div class="score-details display-flex">
                    <span>0</span>
                    <span>:</span>
                    <span>0</span>
                </div>

            </div>
            <!--div class="title-right" v-if="isLive">
                <div class="counts" v-for="(row, rowIndex) of score" :key="rowIndex"
                     :data-cy="`score-line-${rowIndex}`">
                    <div :class="['item', index===0 ? 'active' : '']" v-for="(item, index) of row" :key="index">
                        {{ item }}
                    </div>
                </div>
            </div-->
        </div>
        <div class="details-table details-table-small">
            <table>
                <thead>
                <tr class="details-table-head">
                    <th colspan="1"></th>
                    <th><span class="icon icon-HT"></span></th>
                    <th><span class="icon icon-yellow"></span></th>
                    <th><span class="icon icon-red"></span></th>
                    <th><span class="icon icon-corner"></span></th>
                    <th><span class="icon icon-penalty"></span></th>
                    <th><span class="icon icon-offside"></span></th>
                    <th><span class="icon icon-substitute"></span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="participant of participants" :key="participant.id">
                    <td>{{ participant.name }}</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                    <td>2</td>
                    <td>0</td>
                    <td>0</td>
                    <td>2</td>
                </tr>
                <tr class="details-table-footer">
                    <td>
                        <div class="display-flex">
                            0:0, 0:0, (0:0) <span class="time">63'</span>
                        </div>
                    </td>
                    <td colspan="7"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="details-filters">
        <div class="wrapper">
            <div class="item" v-for="tab of tabs" :key="tab.id"
                 :class="{active: selectedTab && tab.id === selectedTab.id, disabled: tab.rows.length === 0}"
                 @click="selectFilterTab(tab)" :data-cy="`detail-tab-${tab.id}`">
                <template v-if="isPinnedRowTab(tab)">
                    <span class="pin"></span>
                    <span class="count" data-cy="count">
                        {{ tab.rows.length }}
                    </span>
                </template>
                <template v-else>
                    {{ $t(tab.title) }}
                </template>
            </div>
        </div>
    </div>
    <div class="column" v-for="(column, index) of columns" :key="index">
        <template v-for="row of column" :key="row.title+(row.p1?.toIntString()??'')">
            <compact-quotation-group
                :sport-type="event.sportTypeId"
                :quotations="row.quotations"
                :participants="participants"
                :titleTemplate="row.title"
                :id="row.id"
                :p1="row.p1"
                :event-blocked="event.blocked"
                :allowCollapse="true"
                :allow-be-pinned="true"
                @togglePinned="togglePinned"
            />
        </template>
    </div>
    </div>
</template>

<script src="./mobile-scoreboard-details-component.ts"/>
