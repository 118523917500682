import { computed, PropType, shallowRef, UnwrapRef } from "vue";
import { BettingEvent } from "@sportaq/model/betting/events/event";
import QuotationView from "@sportaq/common/enums/quotation-views";
import { QuotationWrapper } from "@sportaq/model/betting/events/quotation";
import { Ref } from "@sportaq/common/types/types";
import { PeriodSortInfo } from "@sportaq/vuex/modules/betting/scoreboard/periods/period-sort-info/period-sort-info";
import CompactQuotationGroup
    from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-line-quotation/compact/quotation-group/CompactQuotationGroup.vue";
import {
    ScoreboardEventLineComponent
} from "@sportaq/vue/components/betting/betting-scoreboard/scoreboard-body/scoreboard-line/scoreboard-event-line/common-scoreboard-event-line-component";
import { extractPeriodName, matchScoreDecomposition } from "@sportaq/vue/components/betting/utils/event-result-strings";
import EventType, { getEventTypeName } from "@sportaq/common/enums/event-type";
import { ESportType, getNameBySportType } from "@sportaq/common/enums/sport-type";
import { getParticipantList } from "@sportaq/model/common/participants-functions";
import { sportTypes } from "@sportaq/model/consts/sport-types";
import { useRoute, useRouter } from "vue-router";

interface ExtraProps extends ScoreboardEventLineComponent.ExtraProps {
    viewTitle: string;
}

export interface Props {
    source: PeriodSortInfo;
    extraProps: ExtraProps;
    isMobile: boolean
}

export default {
    props: {
        source: {
            required: true,
            type: Object as PropType<PeriodSortInfo>
        },
        extraProps: {
            required: true,
            type: Object as PropType<ExtraProps>
        },
        isMobile: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    components: {
        CompactQuotationGroup
    },
    setup (props: Props) {
        const route = useRoute();
        const router = useRouter();
        const quotations: Ref<QuotationWrapper[]> = shallowRef([]);

        const {
            event,
            scoreboardStore,
            reactiveScore,
            period,
            flag,
            allCount,
            isLeagueMode,
            isFavorite,
            toggleFavorite
        } = ScoreboardEventLineComponent.useScoreboardEventLineComponent(props, updateQuotationGroups, true);
        const partitionLabel = computed(() => sportTypes.getById(event.value.partition.sportTypeId)?.name + " / " + event.value.partition.name);
        const score = computed(() => event.value.liveBetting ? matchScoreDecomposition(reactiveScore!.value, true) : "");
        const periodInfo = computed(() => event.value.liveBetting ? extractPeriodName(reactiveScore!.value) ?? "" : "");
        const isLive = computed(() => event.value.eventType === EventType.LIVE);
        const isBaseball = computed(() => event.value.sportTypeId === ESportType.Baseball);
        const quotationArr = computed(() => [quotations.value]);
        const participants = computed(() => getParticipantList(event.value));

        function updateQuotationGroups (e: UnwrapRef<BettingEvent>, newViews: QuotationView[], oldViews: QuotationView[]) {
            ScoreboardEventLineComponent.updateQuotationGroup(quotations, e, period.value, newViews, oldViews, 0, true);
        }

        async function showDetails () {
            if (event.value) {
                if (props.isMobile) {
                    scoreboardStore.setScoreboardSelection(route,
                        {
                            eventType: event.value.eventType,
                            positionId: event.value.positionId,
                            period: period.value.type
                        });
                } else {
                    const eventTypeName = getEventTypeName(event.value.eventType);
                    const sportTypeName = getNameBySportType(event.value.sportTypeId);
                    if (sportTypeName) {
                        await router.push({ path: `/${eventTypeName}/${sportTypeName}/${event.value.positionId}` });
                    }
                }
            }
        }

        return {
            event,
            quotationArr,

            participants,
            partitionLabel,
            score,
            periodInfo,
            isLive,
            isBaseball,

            allCount,
            flag,
            isLeagueMode,
            isFavorite,

            toggleFavorite,
            showDetails
        };
    }
};
