<template>
    <div class="deposit-payments-item">
        <slot></slot>
        <div class="info">
            <div class="info-title">
                <div class="name">{{itemName}}</div>
                <img v-if="hasDescription" src="/img/icon-info.svg" @click="infoDialogVisible = true">
            </div>
            <button class="button button-general" :id="itemId" @click.prevent="openDialog"
                    v-if="depositButtonVisible">{{$t(itemType === ItemType.Deposit ? "mobile.depositView.depositButton" : "mobile.withdrawalView.withdrawalButton")}}</button>
        </div>
    </div>
    <div class="popup active" v-if="infoDialogVisible">
        <div class="overlay" @click="infoDialogVisible = false"></div>
        <div class="popup-container">
            <div class="popup-title">{{itemName}}</div>
            <div class="popup-content">
                <slot name="description"></slot>
                <div class="form-line">
                    <button class="button button-secondary"
                       @click.stop.prevent="infoDialogVisible = false">{{ $t("common.forms.close") }}</button>
                </div>
            </div>
        </div>
    </div>
    <login-popup-dialog v-if="isLoginDialogVisible" @close="onLoginClose" @openDialog="isResetPasswordDialogVisible = true"></login-popup-dialog>
    <reset-password-dialog v-if="isResetPasswordDialogVisible"  @close="isResetPasswordDialogVisible = false"></reset-password-dialog>
</template>

<script src="./deposit-view-item-component.ts" />
