import { SetupContext } from "vue";
import ConfirmCodeDialog from "@/components/dialogs/confirm-code-dialog/ConfirmCodeDialog.vue";
import { CLOSE_EVENT } from "@sportaq/vue/components/web/dialogs/events";
import { useResetPasswordDialogCommon } from "@sportaq/vue/components/web/dialogs/reset-password-dialog-common";

export default {
    name: "ResetPasswordDialog",
    emits: [CLOSE_EVENT],
    components: {
        ConfirmCodeDialog
    },
    setup (props: unknown, context: SetupContext) {
        return useResetPasswordDialogCommon(context);
    }
};
