import { v4 as uuidv4 } from "uuid";

export interface UUIDProvider {
    getUUID (): string;
}

export class DefaultUUIDProvider implements UUIDProvider {
    getUUID (): string {
        return uuidv4();
    }
}
