<template>
    <div class="deposit-tabs smaller">
        <stakes-menu :tabs="tabs"/>
        <div class="personal-wrapper">
          <component :is="textComponent"></component>
        </div>
    </div>
    <div class="preloader" v-if="loading" data-cy="preloader">
        <div class="preloader-el"></div>
    </div>
</template>

<script src="./rules-view-component.ts" />
