
import { computed, onMounted, defineAsyncComponent } from "vue";
import { resolveLanguage } from "@sportaq/i18n/index";
export default {
    name: "AmlPolicy",
    props: {
        needDownload: {
            default: 1,
            type: Number
        }
    },
    components: {
        es: defineAsyncComponent(() => import("@sportaq/vue/components/web/text-view/es/AmlPolicyInternal.vue")),
        en: defineAsyncComponent(() => import("@sportaq/vue/components/web/text-view/en/AmlPolicyInternal.vue")),
        ru: defineAsyncComponent(() => import("@sportaq/vue/components/web/text-view/ru/AmlPolicyInternal.vue"))
    },
    setup () {
        const textComponent = computed(() => {
            const lang = resolveLanguage();
            return lang;
        });
        onMounted(() => {
            window.scrollTo(0, 0);
        });
        return {
            textComponent
        };
    }
};
