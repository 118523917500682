import { ImageDictionaryItem } from "@sportaq/common/types/types";
import { onMounted, PropType, ref, SetupContext, shallowRef, watch } from "vue";
import { useRoute } from "vue-router";

const EVENT = "select";

export interface ImageDictionaryItemWithSubItems extends ImageDictionaryItem {
    subItems?: ImageDictionaryItem[];
    count?: number;
}

export interface ConstantItemsPanelSelectedResult {
    selectedId: number;
    selectedSubId: number;
    selectedItem: ImageDictionaryItem;
}

interface Props {
    items: ImageDictionaryItemWithSubItems[];
    menuClass: string;
    subMenuClass: string;
    defaultSelectedItemId?: number;
    forceOpenSelectedTabByClick?: boolean;
    dataCy: string;
}

export default {
    props: {
        items: {
            required: true,
            type: Object as PropType<ImageDictionaryItemWithSubItems[]>
        },
        menuClass: {
            required: true,
            type: String
        },
        subMenuClass: {
            required: true,
            type: String
        },
        defaultSelectedItemId: {
            required: false,
            type: Number
        },
        forceOpenSelectedTabByClick: {
            required: false,
            type: Boolean,
            default: false
        },
        dataCy: {
            required: true,
            type: String
        }
    },
    emits: [EVENT],
    setup (props: Props, context: SetupContext) {
        const selectedId = ref<number>(-1);
        const selectedSubId = ref<number>(-1);
        const sliderItems = ref([...props.items]);
        const subItems = shallowRef<ImageDictionaryItem[] | undefined>();
        const route = useRoute();

        onMounted(() => {
            const items = sliderItems.value;
            if (items.length > 0) {
                if (props.defaultSelectedItemId || route.query.itemId) {
                    const itemId = route.query.itemId ? parseInt(route.query.itemId.toString()) : props.defaultSelectedItemId;
                    const selectedItem = items.find(value => value.id === itemId);
                    if (selectedItem) {
                        setSelected(selectedItem, true);
                        return;
                    }
                }
                setSelected(items[0], true);
            }
        });

        function setSelected (item: ImageDictionaryItemWithSubItems, auto: boolean) {
            if ((item.count !== undefined) && (item.count <= 0)) {
                return;
            }
            if (!item.subItems) {
                subItems.value = undefined;
                if (selectedId.value !== item.id || props.forceOpenSelectedTabByClick) {
                    selectedId.value = item.id;
                    selectedSubId.value = -1;
                    emitEvent(item.id, -1, item, auto);
                }
            } else {
                selectedId.value = item.id;
                if (!subItems.value) {
                    subItems.value = [...item.subItems];
                } else {
                    subItems.value = undefined;
                }
            }
        }

        function setSubItemSelected (item: ImageDictionaryItem) {
            if (props.forceOpenSelectedTabByClick || selectedSubId.value !== item.id) {
                selectedSubId.value = item.id;
                subItems.value = undefined;
                emitEvent(selectedId.value, item.id, item, false);
            }
        }

        function emitEvent (itemId: number, subItemId: number, selectedItem: ImageDictionaryItem, auto: boolean) {
            const eventData: ConstantItemsPanelSelectedResult = {
                selectedId: itemId,
                selectedSubId: subItemId,
                selectedItem: {
                    // Clone new item
                    id: selectedItem.id,
                    name: selectedItem.name,
                    title: selectedItem.title,
                    image: selectedItem.image
                }
            };
            context.emit(EVENT, eventData, auto);
        }

        function scrollLeft () {
            const arr = sliderItems.value;
            if (arr.length > 1) {
                const right = arr.pop();
                if (right) {
                    arr.unshift(right);
                }
            }
        }

        function scrollRight () {
            const arr = sliderItems.value;
            if (arr.length > 1) {
                const left = arr.shift();
                if (left) {
                    arr.push(left);
                }
            }
        }

        watch(() => props.items, value => {
            sliderItems.value = [...props.items];
            if ((selectedId.value >= 0) && (subItems.value)) {
                const selectedItem = value.find(v => v.id === selectedId.value);
                if (selectedItem && selectedItem.subItems) {
                    subItems.value = [...selectedItem.subItems];
                }
            }
        });

        return {
            sliderItems,
            selectedId,
            selectedSubId,
            subItems,

            setSelected,
            setSubItemSelected,
            scrollLeft,
            scrollRight
        };
    }
};
