<template>
    <div class="deposit-payments">
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_YAPE)" :itemName="$t('webdesktop.deposit.yape')" :minAmount="20"
                           :depositButtonVisible="true"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_YAPE)" :itemType="ItemType.Withdrawal">
          <div class="image">
            <img src="/img/payments/logo-yape.svg">
          </div>
        </deposit-view-item>
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_LUKITA)" :itemName="$t('webdesktop.deposit.lukita')" :minAmount="20"
                           :depositButtonVisible="true"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_LUKITA)" :itemType="ItemType.Withdrawal">
          <div class="image">
            <img src="/img/payments/lukita.png">
          </div>
        </deposit-view-item>
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_TUNKI)" :itemName="$t('webdesktop.deposit.tunki')" :minAmount="20"
                           :depositButtonVisible="true"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_TUNKI)" :itemType="ItemType.Withdrawal">
          <div class="image">
            <img src="/img/payments/logo-tunki.svg">
          </div>
        </deposit-view-item>
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_VOUCHER) ||
                                  isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_VOUCHER_INSTANT)" :itemName="'IPAY'" :minAmount="20"
                           :depositButtonVisible="true" :hasDescription="true"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_VOUCHER)" :itemType="ItemType.Withdrawal">
        <div class="image">
          <img src="/img/payments/logo-ipay.svg">
        </div>
        <template v-slot:description>
        <div class="text">
            {{$t("webdesktop.withdrawal.ipay.description")}}
        </div>
        </template>
        </deposit-view-item>
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_BANK_CARD)
                              && countryId !== 62" :itemName="$t('webdesktop.withdrawal.visaForm.caption')" :minAmount="20"
                           :depositButtonVisible="true" :currency="'PEN'"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_BANK_CARD)" :itemType="ItemType.Withdrawal">
            <div class="image">
                <img src="/img/new-payments/Transferencia.svg">
            </div>
        </deposit-view-item>
        <deposit-view-item v-if="isPaymentSystemAvailable(PaymentSystems.WITHDRAWAL_METHOD_BANK_CARD) &&
                     countryId === 62" :itemName="$t('webdesktop.withdrawal.visaForm.caption')" :minAmount="5"
                           :depositButtonVisible="true" :hasDescription="true" :currency="'USD'"
                           :maxAmount="0" @openDialog="openWithdrawalDialog(PaymentSystems.WITHDRAWAL_METHOD_BANK_CARD)" :itemType="ItemType.Withdrawal">
            <div class="image">
                <img src="/img/equador-banks/Banco-Guayaquil.png">
            </div>
            <template v-slot:description>
                <div class="image-block">
                    <img src="/img/equador-banks/Banco-Guayaquil.png">
                    <img src="/img/equador-banks/Banco-Pichincha-nuevo.png">
                    <img src="/img/equador-banks/boliviariano.png">
                </div>
                <div class="image-block">
                    <img src="/img/equador-banks/Internacional.png">
                    <img src="/img/equador-banks/Pacifico.png">
                    <img src="/img/equador-banks/Produbanco.png">
                </div>
            </template>
        </deposit-view-item>
    </div>
    <component :minAmount="minAmount" v-if="currentDialog" :is="currentDialog" :paymentSystem="selectedPaymentSystem" :pointList="pointList" @close="onDialogClose"/>
    <verification-dialog v-if="isVerificationDialogVisible" @close="onVerificationDialogClose"></verification-dialog>
</template>
<script src="./withdrawal-view-component.ts"></script>
