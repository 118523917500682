<template>
    <div class="balance" data-cy="balance">
        <span id="main-account-balance" :class="{active: activeAccount === AccountType.USER_ACCOUNT_PRIMARY}" data-cy="main-account-balance">
            {{balance.toString()}}
        </span>
        <template v-if="bonus && bonus > 0">
            <span id="bonus-account-balance" :class="{active: activeAccount === AccountType.USER_ACCOUNT_BONUS}" data-cy="bonus-account-balance">
               / {{bonus.toString()}}
            </span>
        </template>
        <span class="active" v-if="showCurrency">&nbsp;{{currency}}</span>
    </div>
</template>
<script src="./balance-component.ts" />
